import React, { useEffect, useState } from 'react'
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { filterObjFromList } from '../../../../Js/generalFunctions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { StockMoveSingleAPIcall } from './ReportAPI';
import { getCurrentTime } from '../../../../Js/Date';

export const StockMoveSingleView = () => {
  let navigate = useNavigate();
  const [symbol, setSymbol] = useState(null);

  // const singleView = useSelector(
  //   (state) => state?.Report?.StockMoveSingle
  // );
  // console.log(singleView);

  const singleView = useSelector((state) => state.ReportSlice.StockMoveSingle)
  console.log("singleView", singleView)

  const decimalPosition = localStorage.getItem("decimalPosition");
  const decimalPositionStock = localStorage.getItem("decimalPositionStock");

  const currencyListUpdated = useSelector(
    (state) => state?.viewUpdatedCurrency?.value
  );

  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const branch = localStorage.getItem("branch")
  const location = localStorage.getItem("location")
  const FromDate = localStorage.getItem("fromDate")
  const ToDate = localStorage.getItem("ToDate")
  const Stock_id = localStorage.getItem("stock_id")
  const vendor = localStorage.getItem("vendor")
  const handleback = () => {

    navigate("/userdashboard/inventory/reports/stockMove")
  }
  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  useEffect(() => {
    StockMoveSingleAPIcall(
      {
        fromDate: FromDate===''?'':`${FromDate} 00:00:00`,
        endDate: ToDate===''?'':`${ToDate} 23:59:59`,
        branchId: branch,
        location: location,
        productId: Stock_id
      }
    )
  }, [FromDate, ToDate, branch, location, Stock_id])

  return (
    <div className="global-page-parent-container" style={{ margin: "0" }}>
      <div className="global-white-bg-container">
        <div >
          <div className="sharp-arrow" style={{ width: "136px",marginBottom:"10px" }} onClick={handleback}> <p>Stock Move Report</p></div>
          <div className="sharp-arrow" style={{ marginLeft: "18px", width: "auto" }}> <p>{vendor}</p></div>
        </div>
        {/* <h4 style={{ margin: "32px 0px 4px 0px" }}>Stock Move</h4> */}
        <div className='global-report-table-container' style={{ maxHeight: "80vh" }}>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Particular</th>
                <th>In</th>
                <th>Out</th>
                <th>Closing</th>
                <th>Unit Cost</th>
                <th>Value</th>
              </tr>
              
            </thead>
            <tbody>
              {singleView[0]?.stockMoves?.slice()?.reverse()?.map((item, index) => (
                <tr style={{ height: "38px" }} key={index}>
                   <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.convertedDate}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.reference}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.inQuantity?.toFixed(decimalPositionStock)}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.outQuantity?.toFixed(decimalPositionStock)}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.closingBalance?.toFixed(decimalPosition)}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7",textAlign:"end",marginLeft:"10%" }}>{item.unitCost?.toFixed(decimalPosition)||"0.00"}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7",textAlign:"end" }}>{item.value?.toFixed(decimalPosition)||"0.00"}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
  <tr>
    <th colSpan={2}>Total</th>
    <th>{singleView[0]?.stockMoves?.reduce((acc, row) => acc + row.inQuantity, 0)?.toFixed(decimalPositionStock)}</th>
    <th>{singleView[0]?.stockMoves?.reduce((acc, row) => acc + row.outQuantity, 0)?.toFixed(decimalPositionStock)}</th>
    <th>{(singleView[0]?.stockMoves?.reduce((acc, row) => acc + row.inQuantity, 0) - singleView[0]?.stockMoves?.reduce((acc, row) => acc + row.outQuantity, 0))?.toFixed(decimalPosition)}</th>
    <th>{symbol}&nbsp;{singleView[0]?.stockMoves?.reduce((acc, row) => acc + row.unitCost, 0)?.toFixed(decimalPosition)}</th>
    <th>{symbol}&nbsp;{singleView[0]?.stockMoves?.reduce((acc, row) => acc + row.value, 0)?.toFixed(decimalPosition)}</th>
  </tr>
</tfoot>


          </table>

        </div>
      </div>

    </div>

  )
}
