import React, { useState } from "react";
import "../../../../css/Staff/HRM/addDocument.css";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import { FilePicker } from "../../../Single Components/filePicker";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllActiveEmployeesAPICall } from "../../../../API/Staff/HRM/employeeAPI";
import documents from "../../../../Assets/JSON/documentType.json";
import {
  addDocTypesAPICall,
  addEmployeeDocumentByAdminAPICall,
  delDocTypeAPICall,
  deleteSingledocumentAPICall,
  editDocTypeAPICall,
  getAllDocumentTypesAPICall,
  viewDocumentProofsAPICall,
  viewDocumentsOfAllEmployeeAPICall,
} from "../../../../API/Staff/HRM/addDocumentsAPI";

import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { extractObjectForSingleView } from "../../../../Js/generalFunctions";
import pdfPng from "../../../../Assets/Images/pdfPNG.png";
import ReactToPrint from "react-to-print";
import { useRef } from "react";

export const AddDocument = () => {
  //*Redux state
  const userRole = useSelector((state) => state.userRoleSlice.value);
  console.log(userRole);
  //Active Employee List

  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );

  //All Employee Documents
  const allEmployeeDocumentList = useSelector(
    (state) => state.allEmployeeDocSlice.value
  );
  // employeeDOc list
  const singleEmpDocList = useSelector(
    (state) => state.employeeDocViewSlice.value
  );
  // docTypeList
  const docTypeList = useSelector((state) => state.docTypeSlice.value);

  let imagePrint = useRef();
  //update listener state
  const [isModify, setIsModify] = useState(false);
  //Snackbar States
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");
  const [addDocument, setAddDocument] = useState(false);

  const fileValidation = new RegExp("(.pdf|.PDF)$");

  const [printingTypeObj, setPrintingTypeObj] = useState(null);
  //Document File (image || file)
  const [addDocImg, setAddDocImg] = useState(null);
  //Filtered Document State
  const [filteredDocList, setFilteredDocList] = useState([]);
  const [singleDocView, setSingleDocView] = useState([]);
  //Search keyword
  const [searchKeyword, setSearchKeyword] = useState("");

  // table documents list states
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // add doc type states
  const [openDocAdd, setOpenDocAdd] = useState(false);
  const [docName, setDocName] = useState("");
  const [editDocTypesField, setEditDocTypeField] = useState(false);
  const [editDocTypeId, setEditDocTypeId] = useState("");

  const handleClickAnchorOpen = (event) => {
    setAnchorEl(event.currentTarget);
    // setOpenDocAnchor(true)
  };
  const handleCloseAnchor = () => {
    setAnchorEl(null);
    setPrintingTypeObj(null);
    // setOpenDocAnchor(false)
  };
  //Selected employee object states
  const [selectedEmployeeObj, setSelectedEmployeeObj] = useState(null);
  const addEmployeeFormInitialState = {
    empId: null,
    empCode: "",
    docType: "",
    docNo: "",
    expDate: "",
  };
  const [addEmployeeForm, setAddEmployeeForm] = useState(
    addEmployeeFormInitialState
  );
  //Single Emp object State
  const [singleEmpObj, setSingleEmpObj] = useState([]);
  // Document View state
  const [documentsView, setDocumentsView] = useState(false);

  // validation states
  const [employeeAlert, setEmployeeAlert] = useState(false);
  const [docTypeAlert, setDocTypeAlert] = useState(false);
  const [docNumberAlert, setDocNumberAlert] = useState(false);
  const [docExpAlert, setDocExpAlert] = useState(false);
  const [docFileAlert, setDocFileAlert] = useState(false);
  //  loading states
  const [isLoading, setIsLoading] = useState(false);
  //*Fns
  const clickAddDocument = () => {
    setAddDocument(true);
  };
  //update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };
  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnack(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };
  //clear Add Document Form State
  const clearAddDocStates = () => {
    setAddDocImg(null);
    setSelectedEmployeeObj(null);
    setAddEmployeeForm(addEmployeeFormInitialState);
  };
  //Close Add Document Dialog
  const closeDialog = () => {
    setAddDocument(false);
  };

  const getSingleDocumentid = (id) => {
    if (filteredDocList !== undefined) {
      const filteredSingleDocView = filteredDocList?.filter((obj) => {
        if (obj?._id === id) {
          return obj;
        }
      });
      setSingleDocView(filteredSingleDocView);
    }
  };
  const empId = singleDocView[0] === undefined ? null : singleDocView[0]._id;

  const empDocFormData = new FormData();
  empDocFormData.append("documentType", addEmployeeForm?.docType);
  empDocFormData.append("documentNumber", addEmployeeForm?.docNo);
  empDocFormData.append("doc", addDocImg);
  empDocFormData.append("id", addEmployeeForm?.empId);
  empDocFormData.append("expiryDate", addEmployeeForm?.expDate);
  //Submit Add File
  const submitAddDocument = () => {
    if (addEmployeeForm?.empId === null) {
      setEmployeeAlert(true);
    } else if (addEmployeeForm?.docType === "") {
      setDocTypeAlert(true);
    } else if (addEmployeeForm?.docNo === "") {
      setDocNumberAlert(true);
    } else if (addEmployeeForm.expDate === "") {
      setDocExpAlert(true);
    } else if (addDocImg === null) {
      setDocFileAlert(true);
    } else {
      addEmployeeDocumentByAdminAPICall(
        empDocFormData,
        setOpenSuccessSnack,
        setOpenErrorSnack,
        setSnackMsg,
        updateListener,
        clearAddDocStates,
        closeDialog
      );
    }
  };

  //Search function
  const searchEmpDocs = (keyword, completeList) => {
    let filteredArray = [];
    if (completeList !== undefined) {
      filteredArray = completeList?.filter(
        (obj) =>
          obj?.staff_name?.toLowerCase()?.includes(keyword?.toLowerCase()) ||
          obj?.empId?.toLowerCase()?.includes(keyword?.toLowerCase())
      );
      setFilteredDocList([...filteredArray]);
    }
  };

  //Extract Single Object onClick of view Docs
  const extractSingleEmpObject = (id) => () => {
    let extractedObjArray = [];
    extractedObjArray = extractObjectForSingleView(id, filteredDocList);
    setSingleEmpObj(extractedObjArray);
  };
  const validatePdfRegex = (url) => {
    let result = fileValidation.test(url);
    return result;
  };
  const deleteSingleDoc = (uuid, id) => () => {
    deleteSingledocumentAPICall({ id, uuid }, updateListener);
    handleCloseAnchor();
  };

  const createNewDocType = () => {
    if (docName !== "") {
      addDocTypesAPICall(
        { documentTypeName: docName },
        setOpenSuccessSnack,
        setOpenErrorSnack,
        setSnackMsg
      );
      updateListener();
      setDocName("");
    } else {
      console.log("try");
    }
  };
  const editNewDocType = () => {
    if (docName !== "") {
      editDocTypeAPICall(
        {
          _id: editDocTypeId,
          documentTypeName: docName,
        },
        setOpenSuccessSnack,
        setOpenErrorSnack,
        setSnackMsg
      );
      updateListener();
      setDocName("");
      setEditDocTypeField(false);
    }
  };
  const clickEditDocType = (docTypeBody) => () => {
    setEditDocTypeField(true);
    setDocName(docTypeBody?.documentTypeName);
    setEditDocTypeId(docTypeBody?._id);
    // assignDocType(docTypeBody)
  };
  const clickDelDocType = (id) => () => {
    delDocTypeAPICall({ _id: id }, setOpenErrorSnack, setSnackMsg);
    updateListener();
  };
  //*useEffects
  //Initial API Calls
  useEffect(() => {
    getAllActiveEmployeesAPICall();
    viewDocumentsOfAllEmployeeAPICall(
      setIsLoading,
      setOpenErrorSnack,
      setSnackMsg
    );
  }, [isModify]);
  //Set employee Code
  useEffect(() => {
    if (selectedEmployeeObj !== null && selectedEmployeeObj !== undefined) {
      const { emp_id, _id } = selectedEmployeeObj;
      setAddEmployeeForm({ ...addEmployeeForm, empCode: emp_id, empId: _id });
    }
  }, [selectedEmployeeObj]);

  //Set Response to front end state
  useEffect(() => {
    if (allEmployeeDocumentList !== undefined) {
      setFilteredDocList(allEmployeeDocumentList);
    } else {
      setFilteredDocList([]);
    }
  }, [allEmployeeDocumentList]);

  //Call Search fn
  useEffect(() => {
    searchEmpDocs(searchKeyword, allEmployeeDocumentList);
  }, [searchKeyword]);
  useEffect(() => {
    viewDocumentProofsAPICall({ id: empId });
  }, [empId, isModify]);

  useEffect(() => {
    getAllDocumentTypesAPICall();
  }, [isModify]);

  return (
    <>
      <div className="global-page-parent-container">
        <p className="breadcrumb">Staff &gt; HRM &gt; Documents </p>
        <div className="department-container">
          <div className="department-top-container">
            <h3>Documents</h3>
            <button
              onClick={clickAddDocument}
              style={{ backgroundColor: "#1100ff" }}
            >
              Add Documents
            </button>
          </div>
          <div className="add-document-container">
            <div className="add-document-search-container">
              <input
                type="text"
                placeholder="Search Employee"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              <IconButton disabled className="add-document-search-icon">
                <SearchIcon />
              </IconButton>
            </div>
            <div className="global-table-container">
              <table className="global-table">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>No</th>
                    <th>Employee</th>
                    <th>Employee ID</th>
                    <th>Documents</th>
                    <th>View</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <tbody>
                    <tr>
                      <td colSpan={9}>
                        {/* <Loader /> */}
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9}>
                        {/* <Loader /> */}
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9}>
                        {/* <Loader /> */}
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9}>
                        {/* <Loader /> */}
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9}>
                        {/* <Loader /> */}
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {(filteredDocList?.length > 0 &&
                      filteredDocList
                        ?.slice(0)
                        ?.reverse()
                        ?.map((r, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{r?.staff_name || "-NIL-"}</td>
                            <td>{r?.empId}</td>
                            <td>
                              <div className="add-document-documents-btn">
                                <button
                                  onMouseDown={extractSingleEmpObject(r?._id)}
                                  onClick={handleClickAnchorOpen}
                                >
                                  {r?.documents === null
                                    ? "-NIL-"
                                    : "view documents"}
                                  <span style={{ marginTop: "4px" }}>
                                    {r?.documents === null ? undefined : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </span>
                                </button>
                              </div>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {r?.documents === null ? (
                                <IconButton disabled>
                                  <i class="bi bi-eye edit-icon-disabled"></i>
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={() => {
                                    setDocumentsView(true);
                                  }}
                                  onMouseDown={() =>
                                    getSingleDocumentid(r?._id)
                                  }
                                >
                                  <i class="bi bi-eye visibility-icon"></i>
                                </IconButton>
                              )}
                            </td>
                          </tr>
                        ))) || (
                      <tr>
                        <td colSpan={5}>No data</td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>

        {/* Add document  */}
        <Dialog
          open={addDocument}
          maxWidth="lg"
          onKeyDown={(e) => e.key === "Escape" && setAddDocument(false)}
        >
          <div className="add-document-dialog-container">
            <h3>Add Document </h3>
            <hr />
            <div className="add-document-dialog-input-container">
              <div className="global-single-input add-document-input auto-complete">
                <p>Employee</p>
                {/* <Select></Select> */}
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={allActiveEmpList || ["No"]}
                  getOptionLabel={(option) =>
                    `${option?.emp_id}-${option?.staff_name}`
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select employee..." />
                  )}
                  // value={(option)=>option?._id}
                  onChange={(e, newValue) => {
                    setSelectedEmployeeObj(newValue);
                    setEmployeeAlert(false);
                    if (newValue === null) {
                      setEmployeeAlert(true);
                    }
                  }}
                />
                {employeeAlert ? (
                  <p className="doc-validation-alert">Select an employee!!</p>
                ) : (
                  ""
                )}
              </div>
              <div className="global-single-input add-document-input">
                <p>Employee ID</p>
                <input type="text" value={addEmployeeForm?.empCode} readOnly />
              </div>
              <div className="global-single-input add-document-input auto-complete">
                <p>Document Type</p>
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={docTypeList || [""]}
                  getOptionLabel={(option) => option?.documentTypeName}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Document Type" />
                  )}
                  onChange={(e, newValue) => {
                    setAddEmployeeForm({
                      ...addEmployeeForm,
                      docType: newValue.documentTypeName,
                    });
                    setDocTypeAlert(false);
                    if (newValue === null) {
                      setDocTypeAlert(true);
                    }
                  }}
                />
                {docTypeAlert ? (
                  <p className="doc-validation-alert" style={{ top: "10%" }}>
                    Select a Document Type!!
                  </p>
                ) : (
                  ""
                )}
                {userRole === "admin" && (
                  <p
                    className="document-add-doc-dialog"
                    onClick={() => setOpenDocAdd(true)}
                  >
                    Add document
                  </p>
                )}
              </div>
              <div className="global-single-input add-document-input">
                <p>Document Number</p>
                <input
                  type="text"
                  placeholder="Enter document number..."
                  value={addEmployeeForm?.docNo}
                  onChange={(e) => {
                    setAddEmployeeForm({
                      ...addEmployeeForm,
                      docNo: e.target.value,
                    });
                    setDocNumberAlert(false);
                    if (e.target.value === "") {
                      setDocNumberAlert(true);
                    }
                  }}
                />
                {docNumberAlert ? (
                  <p className="doc-validation-alert" style={{ top: "80%" }}>
                    Fill this field!!
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="global-single-input add-document-input">
                <p>Expiry Date</p>
                <input
                  type="date"
                  value={addEmployeeForm?.expDate}
                  onChange={(e) => {
                    setAddEmployeeForm({
                      ...addEmployeeForm,
                      expDate: e.target.value,
                    });
                    setDocExpAlert(false);
                  }}
                />
                {docExpAlert ? (
                  <p className="doc-validation-alert" style={{ top: "65%" }}>
                    Fill this field!!
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="global-single-input add-document-input">
                <p>Image / File Upload</p>
                <FilePicker
                  id="addDocImage"
                  uploadImageUrl={addDocImg}
                  setImage={setAddDocImg}
                  onChange={(e) => {
                    setAddDocImg(e.target.files[0]);
                    setDocFileAlert(false);
                  }}
                ></FilePicker>
                {docFileAlert ? (
                  <p
                    className="doc-validation-alert"
                    style={{ left: "0%", textAlign: "start" }}
                  >
                    Upload a file!!
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="add-document-dialog-btn-container">
              <button
                onClick={() => setAddDocument(false)}
                className="btn btn-secondary-outlined"
              >
                Cancel
              </button>
              <button className="btn btn-secondary" onClick={submitAddDocument}>
                Submit
              </button>
            </div>
          </div>
        </Dialog>
      </div>

      {/* Single EMP doc list  */}
      <Menu
        anchorEl={anchorEl}
        id="status-menu"
        open={open}
        // open={openDocAnchor}
        onClose={handleCloseAnchor}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {(singleEmpObj[0]?.documents !== null &&
          singleEmpObj[0]?.documents?.map((item, j) => (
            <ul className="menu-item-document-ul">
              <li className="menu-item-document-li">
                <Typography sx={{ fontSize: "15px", marginRight: "14px" }}>
                  {item?.documentType}
                </Typography>
              </li>
              <li className="menu-item-document-li">
                <IconButton size="small" color="primary">
                  <EditOutlined />
                </IconButton>
              </li>
              <li className="menu-item-document-li">
                <IconButton
                  size="small"
                  color="error"
                  onClick={deleteSingleDoc(item?.uuid, singleEmpObj[0]?._id)}
                >
                  <DeleteOutlined />
                </IconButton>
              </li>
              <li className="menu-item-document-li">
                {validatePdfRegex(singleEmpObj[0]?.documents[j].imageUrl) ? (
                  <button
                    style={{
                      alignSelf: "flex-end",
                      background: "transparent",
                      border: "none",
                      padding: "0",
                    }}
                    onClick={() => {
                      window.open(
                        singleEmpObj[0]?.documents[j].imageUrl,
                        "_blank"
                      );
                    }}
                  >
                    <IconButton size="small">
                      <i class="bi bi-printer printer-icon"></i>
                    </IconButton>
                  </button>
                ) : (
                  <ReactToPrint
                    trigger={() => (
                      <IconButton size="small">
                        <i class="bi bi-printer printer-icon"></i>
                      </IconButton>
                    )}
                    onBeforeGetContent={() => {
                      setPrintingTypeObj(singleEmpObj[0]?.documents[j]);
                      return Promise.resolve();
                    }}
                    content={() => imagePrint}
                  />
                )}
              </li>
            </ul>
          ))) || (
          <ul>
            <li>No Documents added</li>
          </ul>
        )}
      </Menu>

      <Dialog
        open={documentsView}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setDocumentsView(false)}
      >
        <div className="document-view-container">
          <h3>Documents</h3>
          <hr className="global-hr" style={{ width: "100%" }} />

          <div className="document-view-container-body">
            {singleEmpDocList !== undefined ? (
              singleEmpDocList?.map((r, i) => (
                <div className="document-view-body-section" key={i}>
                  <h4>{r?.documentType}</h4>
                  <div className="document-view-img-container">
                    {validatePdfRegex(r?.imageUrl) ? (
                      <img src={pdfPng} alt="In PDF Format" />
                    ) : (
                      <img src={r?.imageUrl} alt="Document Proof" />
                    )}
                  </div>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    {validatePdfRegex(r?.imageUrl) ? (
                      <button
                        className="btn"
                        style={{ alignSelf: "flex-end" }}
                        onClick={() => {
                          window.open(r?.imageUrl, "_blank");
                        }}
                      >
                        <IconButton>
                          <i class="bi bi-printer printer-icon"></i>
                        </IconButton>
                      </button>
                    ) : (
                      <ReactToPrint
                        trigger={() => (
                          <button
                            className="btn"
                            style={{ alignSelf: "flex-end" }}
                          >
                            <IconButton>
                              <i class="bi bi-printer printer-icon"></i>
                            </IconButton>
                          </button>
                        )}
                        onBeforeGetContent={() => {
                          setPrintingTypeObj(r);
                          return Promise.resolve();
                        }}
                        content={() => imagePrint}
                      />
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div>No Documents</div>
            )}
          </div>
          <div className="document-view-container-button">
            <button
              className="btn btn-secondary-outlined"
              onClick={() => {
                {
                  setDocumentsView(false);
                }
                setPrintingTypeObj(null);
              }}
            >
              Cancel
            </button>
          </div>
        </div>

      </Dialog>
      
      {printingTypeObj !== null && (
        <div
          ref={(el) => (imagePrint = el)}
          className="document-view-img-container document-print-view"
        >
          <img src={printingTypeObj?.imageUrl} alt="In PDF Format" />
        </div>
      )}

      <Dialog
        open={openDocAdd}
        maxWidth="md"
        onKeyDown={(e) => e.key === "Escape" && setOpenDocAdd(false)}
      >
        <div className="add-rack-dialog-parent" style={{ width: "45vw" }}>
          <h3>Add Document Type</h3>
          <div className="add-rack-form-container">
            <div className="global-single-input rack-description-input">
              <p>Document Name</p>
              <input
                placeholder="Adhar,Passport....etc"
                value={docName}
                onChange={(e) => {
                  setDocName(e.target.value);
                }}
                onKeyDown={(e) => e.key === "Enter" && createNewDocType()}
              />
            </div>
            <button
              className="btn btn-secondary add-rack-btn"
              onClick={editDocTypesField ? editNewDocType : createNewDocType}
            >
              {editDocTypesField ? (
                <p style={{ margin: "0", fontSize: "1rem" }}>Edit</p>
              ) : (
                "+"
              )}
            </button>
          </div>
          <div className="rack-table-container global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Document Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {docTypeList ? (
                  docTypeList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((r, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{r?.documentTypeName.toUpperCase()}</td>
                        <td>
                          <IconButton onClick={clickEditDocType(r)}>
                            <i class="bi bi-pencil-square edit-icon"></i>
                          </IconButton>
                          <IconButton onClick={clickDelDocType(r._id)}>
                            <i class="bi bi-trash3 delete-icon"></i>
                          </IconButton>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan={3}>No data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="btn-parent">
            <button
              className="btn btn-secondary rack-close-btn"
              onClick={() => {
                setOpenDocAdd(false);
                setEditDocTypeField(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>

      <SuccessSnackbar
        open={openSuccessSnack}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnack}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
