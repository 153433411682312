import React, { useState } from "react";
import {
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  IconButton,
} from "@mui/material";
import "../../../css/GenerateQrBarcode/generateQrBarcode.css";
import kurti from "../../../Assets/Images/kurti.jpg";
import qrcode from "../../../Assets/Images/qrCodeImage.png";
import barcode from "../../../Assets/Images/barcode.png";
import { useEffect, useRef } from "react";
import {
  viewAllAccessoryCategoryAPICall,
  viewAllAccessorySubCategoryAPICall,
  viewAllMaterialCategoryAPICall,
  viewAllReadymadeCategoriesAPICall,
  viewAllReadymadeSubCategoryAPICall,
} from "../../../API/Product Master/ProductMasterAPI";
import { useSelector } from "react-redux";
import { logDOM } from "@testing-library/react";
import {
  accessoryProductSingleViewAPICall,
  materialProductSingleViewAPICall,
  readymadeProductSingleViewAPICall,
  viewAllProductsAPICall,
} from "../../../API/Product List/productListAPI";
import Barcode from "react-barcode";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";
import { update_product_singleView } from "../../../Redux/Product List/productSingleViewSlice";
import store from "../../../Redux/store";
import { viewAllBranchesAPICall } from "../../../API/Settings/Company Settings/companySettingsAPI";
import { categoryListAPICall } from "../Inventory/Configuration/Category/CategoryAPI";
import { posCategoryListAPICall } from "../Inventory/Configuration/POSCategory/posCategoryAPI";
import { getAllSubCatForBilling } from "../Sales/Billing/newBillingAPI";
import { newProdListForBarcode, newProdListSingleViewForBarcode } from "./GenearteQrBarcodeAPI";
export const GenerateQrOrBarcode = () => {

  const loginDetails = JSON.parse(localStorage.getItem("login_meta"))
  //  qr code print
  let qrAndBarCodePrint = useRef()

  //Redux States
  const userRole = useSelector(
    (state) => state.userRoleSlice.value
  );
  //Material Category
  const materialCategory = useSelector(
    (state) => state.allMaterialCategorySlice.value
  );
  //Readymade Category
  const readymadeCategory = useSelector(
    (state) => state.allReadymadeCategories.value
  );
  //Accessory category
  const accessoryCategory = useSelector(
    (state) => state.allAccessoryCategorySlice.value
  );
  //Readymade Sub Category
  const readymadeSubCategory = useSelector(
    (state) => state.allReadymadeSubCategorySlice.value
  );
  //Accessory SubCategory
  const accessorySubCategory = useSelector(
    (state) => state.allAccessorySubCategorySlice.value
  );
  //Product List
  const allProductList = useSelector(
    (state) => state.allProductsListSlice.value
  );

  //Store code
  const storeCode = localStorage.getItem('branchId')

  const allBranchesList = useSelector(
    (state) => state.allBranchesSlice.value
  );
  //////  12-12-2023
  const CategoryList = useSelector((state) => state.categorySlice.value);
  const posCategoryList = useSelector((state) => state.newBillingSlice.subCategoryList);
  // all product list
  const allProductsList = useSelector(
    (state) => state.GenerateQrBarcodeSlice.apiResList
  );
  // Product Single View
  const productSingleView = useSelector(
    (state) => state.GenerateQrBarcodeSlice.singleView
  );


  const [mainCategory, setMainCategory] = useState(null);
  const [categoryObj, setCategoryObj] = useState(null);
  const [subCategoryObj, setSubCategoryObj] = useState(null);
  const [productObj, setProductObj] = useState(null);
  const [buttonClick, setButtonClick] = useState(false);
  const [qrBarcode, setQrBarcode] = useState("qrcode");
  //Product Name for qr code
  const [qrProdName, setQrProdName] = useState("");
  const [companyNameqr, setCompanyNameQr] = useState("")
  const [nativeNameqr, setNativeNameQr] = useState("")
  const [salesPriceQr, setSalesPriceQr] = useState("")
  const [expDateQr, setExpDateQr] = useState("")
  //Determine whether show name on qr or not
  const [isShowProdName, setIsShowProdName] = useState(false);
  const [isShowCompanyName, setIsShowCompanyName] = useState(false)
  const [isShowNativeName, setIsShowNativeName] = useState(false)
  const [isShowSalesPrice, setIsShowSalesPrice] = useState(false)
  const [isShowExpDate, setIsShowExpDate] = useState(false)
  //QR code encoded value
  const [qrCodeValue, setQrCodeValue] = useState("");
  //Barcode encoded value
  const [barcodeValue, setBarcodeValue] = useState('')
  //Selected Size to generate Qr Code and Barcode
  const [sizeSelectedForCodes, setSizeSelectedForCodes] = useState(null)
  // no of copies state
  const [noOfCopies, setNoOfCopies] = useState(1)
  const [countAlert, setCountAlert] = useState(false)
  const [qrCodeArray, setQrCodeArray] = useState([])
  //sku code state
  const [skuCode, setSkuCode] = useState(null)

  const [printMultiple, setPrintMultiple] = useState(false)

  //  validationstate
  const [productAlert, setProductAlert] = useState(false)
  const [branchAlert, setBranchAlert] = useState(false)

  const [branchId, setBranchId] = useState(null)



  useEffect(() => {
    categoryListAPICall();

  }, [])



  // print multiple qr code
  useEffect(() => {
    let n = noOfCopies;
    let string = "";
    for (let i = 0; i < n; i++)
      string += "*"
    setQrCodeArray([...string])
    //  console.log(string);
  }, [noOfCopies])


  const getMainCategory = (e) => {
    setMainCategory(e.target.value);
  };

  //Click Filter Submit btn
  const clickFilterSubmit = () => {
    // if(branchId===null){
    //   setBranchAlert(true)
    // }
    // else if (productObj === null) {
    //    setProductAlert(true)
    // } else {
    //   if (mainCategory == 2) {
    //     //Material Product
    //     materialProductSingleViewAPICall({ _id: productObj?._id, branchId:branchId });
    //   }
    //   if (mainCategory == 0) {
    //     //Readymade Product
    //     readymadeProductSingleViewAPICall({ _id: productObj?._id, branchId:branchId });
    //   }
    //   if (mainCategory == 1) {
    //     //Accessory product
    //     accessoryProductSingleViewAPICall({
    //       accessoryProductId: productObj?._id,
    //       branchId:branchId
    //     });
    //   }
    //   setButtonClick(false);
    // }
    newProdListSingleViewForBarcode({ _id: productObj?._id })
  };


  //get skuCode from response to encode 
  const getSkuCode = (res = {}
    // ,prodType=2,selectedDimension
  ) => {
    if (res !== undefined) {
      let skuCodeFinal = null
      // if(prodType==2) //in case of material
      // {
      // change on 13-12-2023
      const { barcode } = res
      skuCodeFinal = barcode
      // }
      // else if(prodType==0 || prodType==1)
      // {
      //   const {size}= res
      //  let sizeObj= size?.find((obj=>obj.brandUnit===selectedDimension))
      //  if(sizeObj!==undefined) skuCodeFinal=sizeObj?.skuCode
      //  else skuCodeFinal=null
      // }
      console.log(res);
      console.log(skuCodeFinal);
      return skuCodeFinal
    }
  }



  // generate qr code button function
  const generateButtonChangeFn = () => {
    let skuCode = getSkuCode(productSingleView
      // ,mainCategory,sizeSelectedForCodes
    )
    console.log(skuCode);
    setSkuCode(skuCode)

    setButtonClick(true);
  };

  const generateQrBarcode = (e) => {
    setQrBarcode(e.target.value);
  };

  const changeInputPrintCount = (e) => {
    if (e.target.value > 300) {
      setNoOfCopies(300)
      setCountAlert(true)
    }
    else {
      setNoOfCopies(e.target.value)
      setCountAlert(false)
    }
  }
  //Set QrCode Value
  const setQrCodeEncodedValue = (singleViewRes) => {
    if (singleViewRes !== undefined) {
      // if(mainCategory===2)
      // {
      const { _id, prodId } = singleViewRes
      setQrCodeValue(prodId + '@' + 'NIL')
      // }
      // if(mainCategory===0)
      // {

      //   const {_id,prodId,size}=singleViewRes
      //   setQrCodeValue(prodId+'@'+sizeSelectedForCodes)
      // }
      // if(mainCategory===1)
      // {

      //   const {_id,productId,size}=singleViewRes
      //   setQrCodeValue(productId+'@'+sizeSelectedForCodes)
      // }
    }
  }





  //* Use Effects
  //Call View Material,Readymade,accessory Category API
  // useEffect(() => {
  //   viewAllMaterialCategoryAPICall();
  //   viewAllReadymadeCategoriesAPICall();
  //   viewAllAccessoryCategoryAPICall();
  //   viewAllBranchesAPICall()
  //   if (mainCategory === 2) setSubCategoryObj(null);
  //   setProductObj(null);
  //   setSizeSelectedForCodes('null')
  // }, [mainCategory]);

  //Call Sub Category API
  // useEffect(() => {
  //   viewAllReadymadeSubCategoryAPICall();
  //   viewAllAccessorySubCategoryAPICall({
  //     accessoryCategoryId: categoryObj?._id,
  //   });
  //   store.dispatch(
  //     update_product_singleView({ productSingleView: undefined })
  //   );
  // }, [categoryObj]);

  //Call View Products API
  // useEffect(() => {
  //   const viewProductsBody = {
  //     type: mainCategory,
  //     categoryId: categoryObj?._id,
  //     subcategoryId: subCategoryObj?._id,
  //     branchId:branchId
  //   };
  //   viewAllProductsAPICall(viewProductsBody);
  // }, [mainCategory, categoryObj, subCategoryObj]);

  // filter poscategory
  useEffect(() => {
    getAllSubCatForBilling({ catId: mainCategory?._id })
  }, [mainCategory])

  /// filter product
  useEffect(() => {
    newProdListForBarcode({ poscat: categoryObj?._id },)
  }, [categoryObj])

  //Set Qr product name on change of single view response
  useEffect(() => {
    if (productSingleView !== undefined) {
      setQrProdName(productSingleView?.name);
      setCompanyNameQr(loginDetails?.storeName)
      setNativeNameQr(productSingleView?.nativeName)
      setSalesPriceQr(productSingleView?.salesPrice)
      setExpDateQr(productSingleView?.nativename)

      // if(mainCategory!==2) setSizeSelectedForCodes(productSingleView?.size[0]?.brandUnit)
    }
  }, [productSingleView]);

  useEffect(() => {
    setQrCodeEncodedValue(productSingleView)
  }, [productSingleView])

  useEffect(() => {
    let skuCode = getSkuCode(productSingleView
      // ,mainCategory,sizeSelectedForCodes
    )
    console.log(skuCode);
    setSkuCode(skuCode)
  }, [productSingleView])


  useEffect(() => {
    if (userRole === "user") {
      setBranchId(storeCode)
    }
  }, [storeCode])




  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">QR / Barcode</p>
      <div className="generate-qr-barcode-top-container">
        {userRole === "admin" &&
          <div className="global-single-input auto-complete">
            <Autocomplete
              options={allBranchesList || [""]}
              getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select a branch" />
              )}
              onChange={(e, newValue) => {
                newValue === null ? setBranchId(null) : setBranchId(newValue?.storeCode)
                setBranchAlert(false)
              }}
            />
            {branchAlert ?
              <p className="doc-validation-alert">Select a branch !!</p>
              : ""}
          </div>
        }
        <div className="generate-qr-barcode-top-container-input-section">
          <div className="global-single-input auto-complete">
            <Autocomplete
              sx={{ width: "100%" }}
              options={CategoryList || []}
              getOptionLabel={(option) => option?.categoryName}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select category.." />
              )}
              value={mainCategory}
              onChange={(e, newValue) => setMainCategory(newValue)}
            />
          </div>
          {/* <div className="global-single-input auto-complete">
            <Autocomplete
              sx={{ width: "100%" }}
              value={categoryObj}
              options={
                mainCategory === 2
                  ? materialCategory || [{ materialCategoryName: "No Data" }]
                  : mainCategory === 0
                  ? readymadeCategory || [{ categoryName: "No Data" }]
                  : mainCategory === 1
                  ? accessoryCategory || [{ accessoryCategory: "No Data" }]
                  : [{ noData: "No Data" }]
              }
              getOptionLabel={(option) =>
                mainCategory === 2
                  ? option.materialCategoryName
                  : mainCategory === 0
                  ? option.categoryName
                  : mainCategory === 1
                  ? option.accessoryCategory
                  : option.noData
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="Select category.." />
              )}
              onChange={(e, newValue) => setCategoryObj(newValue)}
            />
          </div> */}
          <div className="global-single-input auto-complete">
            <Autocomplete
              sx={{ width: "100%" }}
              options={posCategoryList || []}
              getOptionLabel={(option) => option?.CATNAME}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select pos category.." />
              )}
              value={categoryObj}
              onChange={(e, newValue) => setCategoryObj(newValue)}
            />
          </div>
          {/* {mainCategory !== 2 && (
            <div className="global-single-input auto-complete">
              <Autocomplete
                sx={{ width: "100%" }}
                value={subCategoryObj}
                options={
                  mainCategory == 0
                    ? readymadeSubCategory || [{ subCategoryName: "No Data" }]
                    : mainCategory === 1
                    ? accessorySubCategory || [
                        { accessorySubCategory: "No Data" },
                      ]
                    : [{ noData: "No Data" }]
                }
                getOptionLabel={(option) =>
                  mainCategory === 0
                    ? option.subCategoryName
                    : mainCategory === 1
                    ? option.accessorySubCategory
                    : option.noData
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select sub category.." />
                )}
                onChange={(e, newValue) => setSubCategoryObj(newValue)}
              />
            
            </div>
          )} */}
          <div className="global-single-input auto-complete" style={{ position: "relative" }}>

            <Autocomplete
              sx={{ width: "100%" }}
              value={productObj}
              options={allProductsList || []}
              getOptionLabel={(option) => option?.productName}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select product.." />
              )}
              onChange={(e, newValue) => {
                setProductObj(newValue)
                setProductAlert(false)
                if (newValue === null) {
                  setProductAlert(true)
                }
              }}
            />
            {productAlert ?
              <p className="doc-validation-alert">Select a product !!</p>
              : ""}
          </div>
          <button className="btn btn-primary" onClick={clickFilterSubmit}>
            Submit
          </button>
        </div>
      </div>


      <div className="generate-qr-barcode-second-container">
        <div className="generate-qr-barcode-second-left">
          <div className="generate-qr-barcode-second-left-single-container">
            <h5>Product ID</h5>
            <span>:</span>
            <p>
              {productSingleView?.prodId ||
                productSingleView?.productId ||
                "##"}
            </p>
          </div>
          <div className="generate-qr-barcode-second-left-single-container">
            <h5>Product Name</h5>
            <span>:</span>
            <p>{productSingleView?.name || "##"}</p>
          </div>
          <div className="generate-qr-barcode-second-left-single-container">
            <h5>Image</h5>
            <span>:</span>
            {
              productSingleView?.imageUrl?.map((r) => (
                <img
                  src={r}
                  alt=""
                />
              ))
            }

          </div>
          <div className="generate-qr-barcode-second-left-single-container">
            <h5>Varient</h5>
            <span>:</span>
            <p>{productSingleView?.varient || "##"}</p>
          </div>
          <div className="generate-qr-barcode-second-left-single-container">
            <h5>UOM</h5>
            <span>:</span>
            <p>{productSingleView?.uomName || "##"}</p>
          </div>
          <div className="generate-qr-barcode-second-left-single-container">
            <h5>Sales Price</h5>
            <span>:</span>
            <p>{productSingleView?.salesPrice || "##"}</p>
          </div>
          {/* <div className="generate-qr-barcode-second-left-single-dimension-container">
              <h5>{mainCategory !== 2 ? "Size" : "Details"}</h5>
              <hr className="global-hr" />
              {(mainCategory !== 2 && (
                <table className="global-table">
                  <thead>
                    <tr>
                      <th>Brand Size</th>
                      <th>Size</th>
                      <th>MRP</th>
                      <th>Price</th>
                      <th>Calculated Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productSingleView?.size?.map((size, i) => (
                      <tr key={i}>
                        <td>{size?.brandUnit}</td>
                        <td>{size?.size}</td>
                        <td>{size?.mrp}</td>
                        <td>{size?.price}</td>
                        <td>{size?.calculatedPrice}</td>
                      </tr>
                    )) || (
                      <tr>
                        <td colSpan={5}>No size created</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )) || (
                <table className="global-table">
                  <thead>
                    <tr>
                      <th>Unit</th>
                      <th>MRP</th>
                      <th>Price</th>
                      <th>Calculated Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{productSingleView?.unitName}</td>
                      <td>{productSingleView?.mrp}</td>
                      <td>{productSingleView?.price}</td>
                      <td>{productSingleView?.calculatedPrice?.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
              )}
             
            </div>  */}
        </div>
        <div className="generate-qr-barcode-second-right">
          {buttonClick === true ? (
            <>
              <div className="generate-qr-barcode-second-right-top-container">
                {/* {mainCategory !== 2 ? (
                    <div className="global-single-input generate-qr-dimension-container">
                      <p>Size</p>
                      <Select value={sizeSelectedForCodes} onChange={(e)=>setSizeSelectedForCodes(e.target.value)}>
                        {productSingleView?.size?.map(((size,i)=>(

                          <MenuItem value={size.brandUnit} key={i}>{size.brandUnit}</MenuItem>

                        )))||''}
                      </Select>
                    </div>
                  ) : (
                    <div className="global-single-input generate-qr-dimension-container"></div>
                  )} */}
                <div className="generate-qr-barcode-second-right-top-radio-container">
                  <div className="generate-qr-barcode-second-right-single-radio-container">
                    <input
                      defaultChecked
                      type="radio"
                      id="generateQrcode"
                      name="generateQrBrcode"
                      value="qrcode"
                      onChange={generateQrBarcode}
                    />
                    <label htmlFor="generateQrcode">QR code</label>
                  </div>
                  <div className="generate-qr-barcode-second-right-single-radio-container">
                    <input
                      type="radio"
                      id="generateBarcode"
                      name="generateQrBrcode"
                      value="barcode"
                      onChange={generateQrBarcode}
                    />
                    <label htmlFor="generateBarcode">Barcode</label>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", }}>
                <div className="generate-qr-code-image-container">
                  {qrBarcode === "qrcode" ? (
                    // <img src={qrcode} alt="" />
                    <>
                      {isShowCompanyName && <span className="qr-prod-name" style={{ fontWeight: 600 }}>{companyNameqr}</span>}
                      <QRCode
                        size={256}
                        style={{
                          height: "auto",
                          width: "100px",
                        }}
                        value={JSON.stringify(skuCode) || 'null'}
                        viewBox={`0 0 256 256`}
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {isShowProdName && <span className="qr-prod-name" style={{ fontWeight: 600 }}>{qrProdName}</span>}
                        {isShowNativeName && <span className="qr-prod-name" style={{ fontWeight: 600 }}>{nativeNameqr}</span>}
                      </div>
                      <div>
                        {isShowSalesPrice && <span className="qr-prod-name" style={{ fontWeight: 600 }}>Price : {salesPriceQr}</span>}
                        {isShowExpDate && <span className="qr-prod-name" >Exp : {expDateQr}</span>}
                      </div>

                    </>
                  ) : qrBarcode === "barcode" ? (
                    <>
                      {isShowCompanyName && <span className="qr-prod-name" style={{ fontWeight: 600 }}>{companyNameqr}</span>}
                      <Barcode
                        format="CODE128"
                        value={skuCode || "null"}
                        width={1}
                        fontSize={16}
                        height={60}
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {isShowProdName && <span className="qr-prod-name" style={{ fontWeight: 600 }}>{qrProdName}</span>}
                        {isShowNativeName && <span className="qr-prod-name" style={{ fontWeight: 600 }}>{nativeNameqr}</span>}
                      </div>
                      <div>
                        {isShowSalesPrice && <span className="qr-prod-name" style={{ fontWeight: 600 }}>Price : {salesPriceQr}</span>}
                        {isShowExpDate && <span className="qr-prod-name" >Exp : {expDateQr}</span>}
                      </div>
                    </>

                  ) : undefined}
                </div>
              </div>

              <div className="generate-qr-barcode-second-right-bottom-container">
                <div className="generate-qr-barcode-second-right-bottom-left-container">
                  <div className="global-single-input generate-qr-dimension-container">
                    <p>Print Quantity </p>
                    <input type="number" placeholder="No of copies..." value={noOfCopies} onChange={changeInputPrintCount} />
                    {
                      countAlert ?
                        <p className="doc-validation-alert">Max :300</p> : ""
                    }
                  </div>
                  <div className="generate-qr-dimension-container-print-btn">
                    <span>
                      <input id="qrPrintIcon"
                        type="checkbox"
                        style={{ width: "12px" }}
                        onChange={(e) => setPrintMultiple(e.target.checked)}
                      /> <label htmlFor="qrPrintIcon">Multiple</label></span>
                    <ReactToPrint
                      trigger={() => (
                        <IconButton style={{ width: "80%" }} className="print-icon-container gr-bar-code-print">
                          <i class="bi bi-printer printer-icon"></i>
                        </IconButton>
                      )}
                      content={() => qrAndBarCodePrint}
                      pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
                    />
                  </div>

                </div>

                {/* <div className="global-single-input print-qr-container">
                   <IconButton><i class="bi bi-printer print-icon"></i></IconButton>
                  </div> */}
                <div className="generate-qr-barcode-bottom-checkbox-container">
                  <div className="generate-qr-barcode-bottom-checkbox-single-container">
                    <input
                      type="checkbox"
                      checked={isShowCompanyName}
                      onClick={(e) => setIsShowCompanyName(e.target.checked)}
                    />
                    <p style={{ margin: "0", width: "61%" }}>Company Name</p>
                    <input
                      type="text"
                      className="generate-qrcode-checkbox-input"
                      value={companyNameqr}
                      onChange={(e) => setCompanyNameQr(e.target.value)}
                    />
                  </div>
                  <div className="generate-qr-barcode-bottom-checkbox-single-container">
                    <input
                      type="checkbox"
                      checked={isShowNativeName}
                      onClick={(e) => setIsShowNativeName(e.target.checked)}
                    />
                    <p style={{ margin: "0", width: "61%" }}>Native Name</p>
                    <input
                      type="text"
                      className="generate-qrcode-checkbox-input"
                      value={nativeNameqr}
                      onChange={(e) => setNativeNameQr(e.target.value)}
                    />
                  </div>
                  <div className="generate-qr-barcode-bottom-checkbox-single-container">
                    <input
                      type="checkbox"
                      checked={isShowProdName}
                      onClick={(e) => setIsShowProdName(!isShowProdName)}
                    />
                    <p style={{ margin: "0", width: "61%" }}>Product Name</p>
                    <input
                      type="text"
                      className="generate-qrcode-checkbox-input"
                      value={qrProdName}
                      onChange={(e) => setQrProdName(e.target.value)}
                    />
                  </div>
                  <div className="generate-qr-barcode-bottom-checkbox-single-container">
                    <input
                      type="checkbox"
                      checked={isShowSalesPrice}
                      onClick={(e) => setIsShowSalesPrice(e.target.checked)}
                    />
                    <p style={{ margin: "0", width: "61%" }}>Sales Price</p>
                    <input
                      type="text"
                      className="generate-qrcode-checkbox-input"
                      value={salesPriceQr}
                      onChange={(e) => setSalesPriceQr(e.target.value)}
                    />
                  </div>
                  <div className="generate-qr-barcode-bottom-checkbox-single-container">
                    <input
                      type="checkbox"
                      checked={isShowExpDate}
                      onClick={(e) => setIsShowExpDate(e.target.checked)}
                    />
                    <p style={{ margin: "0", width: "61%" }}>Exp Date</p>
                    <input
                      type="date"
                      className="generate-qrcode-checkbox-input"
                      value={expDateQr}
                      onChange={(e) => setExpDateQr(e.target.value)}
                    />
                  </div>
                  {/* <div className="generate-qr-barcode-bottom-checkbox-single-container">
                      <input type="checkbox" />
                      <p>Expiry</p>
                      <input
                        type="date"
                        className="generate-qrcode-checkbox-input"
                      />
                    </div> */}
                </div>
              </div>
            </>
          ) : (
            <button onClick={generateButtonChangeFn}>
              Generate QR / Barcode
            </button>
          )}
        </div>
      </div>


      {/* qr and barcode print */}
      <div
        style={{ display: "none" }}
      >
        <div
        //  ref={(el) => (qrAndBarCodePrint = el)}
        >
          <div className="generate-qr-code-image-print-container">
            {
              qrCodeArray?.map((r, i) => (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", margin: "8px" }}>
                  {qrBarcode === "qrcode" ? (
                    // <img src={qrcode} alt="" />
                    <>
                      <div className={isShowProdName ? "generate-bar-code-image-print-remove-bottom-margin" : "generate-qr-code-image-print"}>
                        {isShowCompanyName && <span className="qr-prod-name" style={{ fontWeight: 600 }}>{companyNameqr}</span>}

                        <QRCode
                          size={256}
                          style={{
                            height: "auto",
                            width: "100%",
                          }}
                          value={JSON.stringify(skuCode) || 'null'}
                          viewBox={`0 0 256 256`}
                        />
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {isShowProdName && <span className="qr-prod-name" style={{ fontWeight: 600, fontSize: "0.8rem" }}>{qrProdName}</span>}
                        {isShowNativeName && <span className="qr-prod-name" style={{ fontWeight: 600, fontSize: "0.8rem" }}>{nativeNameqr}</span>}
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {isShowSalesPrice && <span className="qr-prod-name" style={{ fontWeight: 600, fontSize: "0.8rem" }}>Price : {salesPriceQr}</span>}
                        {isShowExpDate && <span className="qr-prod-name" style={{ fontWeight: 600, fontSize: "0.8rem" }}>Exp : {expDateQr}</span>}
                      </div>
                    </>
                  ) : qrBarcode === "barcode" ? (
                    // <img
                    //   src={barcode}
                    //   alt=""
                    //   className="generate-qr-barcode-image-container"
                    // />
                    <div className="generate-bar-code-image-print" >
                      {isShowCompanyName && <span className="qr-prod-name" style={{ fontWeight: 600 }}>{companyNameqr}</span>}

                      <Barcode

                        format="CODE128"
                        value={skuCode || "null"}
                        width={1}
                        fontSize={16}
                        height={60}
                        margin={"2px"}
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {isShowProdName && <span className="qr-prod-name" style={{ fontWeight: 600, fontSize: "0.8rem" }}>{qrProdName}</span>}
                        {isShowNativeName && <span className="qr-prod-name" style={{ fontWeight: 600, fontSize: "0.8rem" }}>{nativeNameqr}</span>}
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {isShowSalesPrice && <span className="qr-prod-name" style={{ fontWeight: 600, fontSize: "0.8rem" }}>Price : {salesPriceQr}</span>}
                        {isShowExpDate && <span className="qr-prod-name" style={{ fontWeight: 600, fontSize: "0.8rem" }} >Exp : {expDateQr}</span>}
                      </div>
                    </div>

                  ) : undefined}
                </div>
              ))
            }

          </div>


        </div>
      </div>


      {/* qr code print view */}
      <div
        style={{ display: "none" }}
      >
        <div ref={(el) => (qrAndBarCodePrint = el)} className={`generate-qr-code-print-new-container ${!printMultiple && "print-single-code"}`}>
          {
            qrCodeArray?.map((r, i) => {
              if (qrBarcode === "qrcode") {
                return (
                  <div className="qr-bar-code-single" key={i}>
                    {isShowCompanyName && <p className="qr-prod-name-print" style={{ fontWeight: 600 }}>{companyNameqr}</p>}

                    <QRCode
                      size={256}
                      style={{
                        height: "auto",
                        width: "100px",
                      }}
                      value={JSON.stringify(skuCode) || 'null'}
                      viewBox={`0 0 256 256`}
                    />
                    {isShowProdName && <p className="qr-prod-name-print">{qrProdName}</p>}
                    {isShowNativeName && <p className="qr-prod-name-print">{nativeNameqr}</p>}
                    {isShowSalesPrice && <p className="qr-prod-name-print">Price : {salesPriceQr}</p>}
                    {isShowExpDate && <p className="qr-prod-name-print">Exp : {expDateQr}</p>}
                  </div>

                )
              }
              else {
                return (
                  <div className="qr-bar-code-single" key={i}>
                    {isShowCompanyName && <p className="qr-prod-name-print" style={{ fontWeight: 600 }}>{companyNameqr}</p>}

                    <Barcode
                      format="CODE128"
                      value={skuCode || "null"}
                      width={2}
                      fontSize={16}
                      height={60}
                      margin={0}
                    />
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                      {isShowProdName && <p className="qr-prod-name-print">{qrProdName}</p>}
                      <p className="qr-prod-name-print" style={{ margin: "0 0.5em" }}>-</p>
                      {isShowNativeName && <p className="qr-prod-name-print">{nativeNameqr}</p>}

                    </div>
                    {isShowSalesPrice && <p className="qr-prod-name-print">Price : {salesPriceQr}</p>}
                    {isShowExpDate && <p className="qr-prod-name-print">Exp : {expDateQr}</p>}

                  </div>

                )
              }
            })
          }

        </div>
      </div>





    </div>
  );
};
