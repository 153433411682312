import axios from "axios";
import { HEADERS } from "../../../../../API/UrlAndPaths";
import store from "../../../../../Redux/store";
import { AccountPayable_single_list, Account_Recievable, Account_Recievable_single_view, payable_due_report_single_view_list, receivable_due_report_single_view_list, setAccountPayable, setAccountPayableAgingReport, setAccountPayableAgingReportSingleView, setAccountReceivableAgingReport, setAccountReceivableAgingReportSingleView, view_payable_due_report_list, view_receivable_due_report_list } from "./partnerReportSlice";

//account payable report
export const AccountPayableAPIcall=async(body)=>{
    await axios
    .post("account/accountPayableReport",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(setAccountPayable({accountPayableList:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(setAccountPayable({accountPayableList:undefined}))
        console.error(err.response);
    })
}

export const AccountPayableSingleAPIcall=async(body)=>{
    await axios
    .post("account/accountPayableReportByVendor",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(AccountPayable_single_list({accountPayableSingleList:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(AccountPayable_single_list({accountPayableSingleList:undefined}))
        console.error(err.response);
    })
}

export const AccountRecievableAPIcall=async(body)=>{
    await axios
    .post("account/accountReceivableReport",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(Account_Recievable({accountRecievableList:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(Account_Recievable({accountRecievableList:undefined}))
        console.error(err.response);
    })
}

export const AccountRecievableSingleAPIcall=async(body)=>{
    await axios
    .post("account/accountReceivableReportById",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(Account_Recievable_single_view({accountRecievableSingleList:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(Account_Recievable_single_view({accountRecievableSingleList:undefined}))
        console.error(err.response);
    })
}
export const accountRecievableAgingReport = async (body,setIsLoading)=>{
    try {
        const response = await axios.post("account/accountReceivableAgingReport",body,HEADERS)
        if(response.status === 200){
         store.dispatch(setAccountReceivableAgingReport(response.data))
         setIsLoading(false)
        }    
    } catch (error) {
        store.dispatch(setAccountReceivableAgingReport([]))
        setIsLoading(false)
        
    }
    
}
export const accountRecievableAgingReportSingleViewApi = async (body,setIsLoading)=>{
    try {
        const response = await axios.post("account/singleCustomerARAR",body,HEADERS)
        if(response.status === 200){
         store.dispatch(setAccountReceivableAgingReportSingleView(response.data))
         setIsLoading(false)
         
        }    
    } catch (error) {
        store.dispatch(setAccountReceivableAgingReportSingleView([]))
        setIsLoading(false)
        
    }
    
}

export const accountPayableAgingReportApi = async (body,setIsLoading)=>{
    try {
        const response = await axios.post("account/accountPayableAgingReport",body,HEADERS)
        if(response.status === 200){
         store.dispatch(setAccountPayableAgingReport(response.data))
         setIsLoading(false)
        }    
    } catch (error) {
        store.dispatch(setAccountPayableAgingReport([]))
        setIsLoading(false)
        
    }
    
}

export const accountPayableAgingReportSingleViewApi = async (body,setIsLoading)=>{
    try {
        const response = await axios.post("account/singleVendorAPAR",body,HEADERS)
        if(response.status === 200){
         store.dispatch(setAccountPayableAgingReportSingleView(response.data))
         setIsLoading(false)
        }    
    } catch (error) {
        store.dispatch(setAccountPayableAgingReportSingleView([]))
        setIsLoading(false)

        
    }
    
}
export const ReceivableDueReportAPIcall=async(body)=>{
    await axios
    .post("account/receivableDueReport",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(view_receivable_due_report_list({ReceivableDueReportData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(view_receivable_due_report_list({ReceivableDueReportData:undefined}))
        console.error(err.response);
    })
}
export const ReceivableDueReportSingleViewAPIcall=async(body)=>{
    await axios
    .post("account/receivableDueReportSingleView",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(receivable_due_report_single_view_list({ReceivableDueReportSingleViewData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(receivable_due_report_single_view_list({ReceivableDueReportSingleViewData:undefined}))
        console.error(err.response);
    })
}
export const PayableDueReportAPIcall=async(body)=>{
    await axios
    .post("account/payableDueReport",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(view_payable_due_report_list({PayableDueReportData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(view_payable_due_report_list({PayableDueReportData:undefined}))
        console.error(err.response);
    })
}
export const PayableDueReportSingleViewAPIcall=async(body)=>{
    await axios
    .post("account/payableDueReportSingleView",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(payable_due_report_single_view_list({PayableDueReportSingleViewData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(payable_due_report_single_view_list({PayableDueReportSingleViewData:undefined}))
        console.error(err.response);
    })
}