import axios from "axios";
import store from "../../../../Redux/store";
import { get_barcode_product_list_add_billing, get_payment_types_list_for_billing, get_products_list_for_billing, get_sub_category_list_for_billing } from "./newBillingSlice";
import { HEADERS, HEADER_FORM_DATA } from "../../../../API/UrlAndPaths";

// api for listing products

export const getAllProductsListForBilling=async(body,setIsLoading)=>{
    setIsLoading!==undefined && setIsLoading(true)
    await axios.post("readymade/viewproductsforpos",body,HEADER_FORM_DATA)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(get_products_list_for_billing({billingProductsList:res.data}))
                setIsLoading!==undefined && setIsLoading(false)
            }
        })
        .catch((err)=>{
            
            store.dispatch(get_products_list_for_billing({billingProductsList:undefined}))
            setIsLoading!==undefined && setIsLoading(false)
        })
}
// api for listing subcategories

export const getAllSubCatForBilling=async(body,setIsLoading)=>{
    setIsLoading!==undefined && setIsLoading(true)
    await axios.post("readymade/categoryfilter",body,HEADER_FORM_DATA)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(get_sub_category_list_for_billing({billingSubCategories:res.data}))
                setIsLoading!==undefined && setIsLoading(false)
            }
        })
        .catch((err)=>{
            
            store.dispatch(get_sub_category_list_for_billing({billingSubCategories:undefined}))
            setIsLoading!==undefined && setIsLoading(false)
        })
}

// api to search products from billing
export const searhProductsBilling=async(body,barCodeFn)=>{
    // setIsLoading!==undefined && setIsLoading(true)
    await axios.post("readymade/productSearchByNBA",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(get_products_list_for_billing({billingProductsList:res.data}))
                // setIsLoading!==undefined && setIsLoading(false)
                barCodeFn!==undefined && barCodeFn()
            }
        })
        .catch((err)=>{
            store.dispatch(get_products_list_for_billing({billingProductsList:undefined}))
            // setIsLoading!==undefined && setIsLoading(false)
            barCodeFn!==undefined && barCodeFn()

        })
}
// api to barcode cart add products from billing
export const barCodeAddProductsBilling=async(body,barCodeFn)=>{
    // setIsLoading!==undefined && setIsLoading(true)
    await axios.post("readymade/productSearchByNBA",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(get_barcode_product_list_add_billing({barCodeItems:res.data}))
                // setIsLoading!==undefined && setIsLoading(false)
                barCodeFn!==undefined && barCodeFn()
            }
        })
        .catch((err)=>{
            store.dispatch(get_barcode_product_list_add_billing({barCodeItems:undefined}))
            // setIsLoading!==undefined && setIsLoading(false)
            barCodeFn!==undefined && barCodeFn()

        })
}

// api to add customer
export const addNewCustomerBillingAPICall = async (
    body,
    updateListener,setState,state,setSelectedCustomerObj
  ) => {
    await axios
      .post("customer/addCustomer", body, HEADERS)
      .then((res) => {
        if (res.status === 200) {
          updateListener();
          setSelectedCustomerObj((prev)=>({...prev,customer:res.data}))
          setState({...state,message:"New Customer Added",success:true})
        }
      })
      .catch((err) => {
        let message="oops!!"
        if (err.response.status === undefined) {
            message="Network error!!"
          }
          if (err.response.status === 401) {
             message="Unauthorized!!"
          }
          if (err.response.status === 403) {
             message="Forbidden!!"
          }
          if (err.response.status === 409) {
             message="Customer already registered!!"
          }
          if (err.response.status === 500) {
             message="Server error!!"
          }
        setState({...state,message:message,error:true})
      });
  };
export const addNewCustomerPopupAPICall = async (
    body,
    updateListener,setState,state,setSelectedCustomerObj,setLoadingForm
  ) => {
    setLoadingForm!==undefined && setLoadingForm(false)

    await axios
      .post("customer/addCustomer", body, HEADERS)
      .then((res) => {
        if (res.status === 200) {
          setLoadingForm!==undefined && setLoadingForm(false)
          updateListener();
          setSelectedCustomerObj(res.data)
          setState({...state,message:"New Customer Added",success:true})
        }
      })
      .catch((err) => {
        setLoadingForm!==undefined && setLoadingForm(false)

        let message="oops!!"
        if (err.response.status === undefined) {
            message="Network error!!"
          }
          if (err.response.status === 401) {
             message="Unauthorized!!"
          }
          if (err.response.status === 403) {
             message="Forbidden!!"
          }
          if (err.response.status === 409) {
             message="Customer already registered!!"
          }
          if (err.response.status === 500) {
             message="Server error!!"
          }
        setState({...state,message:message,error:true})
      });
  };

//   api to get payment types of the branch
export const paymentTypesForBilling=async(body)=>{
    await axios.post("settings/fetchpaymentsforposbybranch",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(get_payment_types_list_for_billing({billingPaymentTypes:res.data}))
            }
        })
        .catch((err)=>{
            store.dispatch(get_payment_types_list_for_billing({billingPaymentTypes:undefined}))
        })
}
