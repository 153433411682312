import React, { useEffect, useRef, useState } from "react";
import "../../../../css/Orders/viewOrder.css";
import { Datepicker } from "../../../Single Components/Datepicker";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  IconButton,
  Dialog,
  Select,
  Tooltip,
  MenuItem,
  Menu,
  Typography,
  Skeleton,
  Autocomplete,
  TextField,
} from "@mui/material";
import logoImage from "../../../../Assets/Images/Icon ionic-logo-buffer.png";
import SearchIcon from "@mui/icons-material/Search";
import CuttingSlipImg from "../../../../Assets/SVG/cutting slip.svg";
import jobCompletionImg from "../../../../Assets/SVG/job completion.svg";
import deliveryImg from "../../../../Assets/SVG/Delivery.svg";
import CuttingSlipImgDisabled from "../../../../Assets/SVG/OrderIconsDisabled/cutting slip.svg";
import jobCompletionImgDisabled from "../../../../Assets/SVG/OrderIconsDisabled/job completion.svg";
import deliveryImgDisabled from "../../../../Assets/SVG/OrderIconsDisabled/Delivery.svg";
import { convertDateFormat, previousDate, today } from "../../../../Js/Date";
import {
  viewWorkOrdersByUserAPICall,
  workOrderSingleViewAPICall,
  searchWorkOrderListUserAPICall,
  updateMeasurementAPICall,
  getAllWorkOrdersAPIcall,
  viewOrderByRelationSearchAPICall,
  viewOrderSingleTableInfo,
} from "../../../../API/Orders/viewOrdersAPI";
import { useSelector } from "react-redux";
import { PrintCuttingSlip } from "../PrintCuttingSlip/PrintCuttingSlip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { PaymentsWO } from "../WorkOrder/PaymentsWO";
import { useNavigate } from "react-router-dom";
import {
  updateOrderPaymentAPICall,
  viewBottomModelAPICall,
  viewCollarAPICall,
  viewCollarClothAPICall,
  viewCollarModelAPICall,
  viewCuffAPICall,
  viewCuffClothAPICall,
  viewCuffModelAPICall,
  viewCupAPICall,
  viewElasticWidthAPICall,
  viewFpModelAPICall,
  viewJalabiaModelAPICall,
  viewMarModelAPICall,
  viewModelAPICall,
  viewNeckModelAPICall,
  viewPenAPICall,
  viewSidePtModelAPICall,
  viewStitchingAPICall,
  viewThobModelAPICall,
} from "../../../../API/Orders/workOrderAPI";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import {
  makeCuttingSlipSingleViewExtensible,
  splitRelationsOrCustomer,
} from "../../../../Js/newModelCuttingSlip";
import ReactToPrint from "react-to-print";
import { SafariCuttingSlip } from "../PrintCuttingSlip/SafariCuttingSlip";
import { SharwaniCuttingSlip } from "../PrintCuttingSlip/SharwaniCuttingSlip";
import { CoatCuttingSlip } from "../PrintCuttingSlip/CoatCuttingSlip";
import { PantCuttingSlip } from "../PrintCuttingSlip/PantCuttingSlip";
import { ShirtCuttingSlip } from "../PrintCuttingSlip/ShirtCuttingSlip";
import { ChuridharCuttingSlip } from "../PrintCuttingSlip/ChuridharCuttingSlip";
import { AbayaCuttingSlip } from "../PrintCuttingSlip/AbayaCuttingSlip";
import { SalwarCuttingSlip } from "../PrintCuttingSlip/SalwarCuttingSlip";
import { JalabiaCuttingSlip } from "../PrintCuttingSlip/JalabiaCuttingSlip";
import { ThobCuttingSlip } from "../PrintCuttingSlip/ThobCuttingSlip";
import { generateQRCodeForPCSAPICall } from "../../../../API/Orders/printCuttingSlipAPI";
import { convertRelationToCustomerAPICall } from "../../../../API/Customer/customerAPI";
import { createMeasurementUpdateBackedCompatibleObject } from "../../../../Js/viewOrderUserFunctions";
import { ThobCuttingSlip1 } from "../PrintCuttingSlip/ThobCuttingSlip1";
import { viewCuttingSlipModelAPICall } from "../../../../API/Settings/CuttingSlipModel/cuttingSlipMoldelAPI";
import { JalabiaCuttingSlip1 } from "../PrintCuttingSlip/JalabiaCuttingSlip1";
import { SalwarCuttingSlip1 } from "../PrintCuttingSlip/SalwarCuttingSlip1";
import axios from "axios";
import { HEADERS } from "../../../../API/UrlAndPaths";
import store from "../../../../Redux/store";
import {
  decrementIndex,
  incrementIndex,
  resetIndex,
} from "../../../../Redux/Orders/View Orders/workOrdersListSlice";
import { viewAllAllowedBranchesAPICall, viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { filterObjFromList } from "../../../../Js/generalFunctions";
import { LoadingForm } from "../../../Single Components/LoadingForm";

export const ViewOrderUser = () => {
  //Permission State from LS
  const loginMeta = JSON.parse(localStorage.getItem("login_meta"));
  const { permission } = loginMeta;
  const { woEdit } = permission?.pointOfSale?.orders || {woEdit:false};

  
  const storeCode = localStorage.getItem("branchId");
  const branchPk = loginMeta?.branchPk
  const decimalPosition = localStorage.getItem("decimalPosition");
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // all allowed branches list
  const allowedBranchList = useSelector(
    (state) => state.allAllowedBranchesSlice.value
  );


  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // console.log(woEdit);
  //*Redux States
  //Thob data
  const thobModelData = useSelector((state) => state.thobModelDataSlice.value);
  const marModelData = useSelector((state) => state.marModelDataSlice.value);
  const cuffData = useSelector((state) => state.cuffDataSlice.value);
  const cuffClothData = useSelector((state) => state.cuffClothSlice.value);
  const cuffModelData = useSelector((state) => state.cuffModelSlice.value);
  const collarData = useSelector((state) => state.collarSlice.value);
 
  const collarClothData = useSelector((state) => state.collarClothSlice.value);
  const collarModelData = useSelector((state) => state.collarModelSlice.value);
  const penData = useSelector((state) => state.penSlice.value);
  const fpModelData = useSelector((state) => state.fpModelSlice.value);
  const sidePtModelData = useSelector((state) => state.sidePTModelSlice.value);
  const viewOrderListByRelationSearch = useSelector((state) => state.viewOrderSearchRelationSlice.value)
  //Jalabia Data
  const jalabiaModelData = useSelector(
    (state) => state.jalabiaModelSlice.value
  );
  const neckModelData = useSelector((state) => state.neckModelSlice.value);
  const stitchingData = useSelector((state) => state.stitchingSlice.value);
  const modelData = useSelector((state) => state.modelSlice.value);
  // const fpModelData = useSelector((state) => state.fpModelSlice.value);

  //Salwar Data
  const elasticWidthData = useSelector(
    (state) => state.elasticWidthSlice.value
  );

  //shirt,safari,sharvani data
  const cupData = useSelector((state) => state.cupSlice.value);
  const bottomModelData = useSelector((state) => state.bottomModelSlice.value);
  // const stitchingData = useSelector((state) => state.stitchingSlice.value);
  // const marModelData = useSelector((state) => state.marModelDataSlice.value);

  // work order list
  const workOrderList = useSelector((state) => state.workOrdersListSlice.value);

  // console.log("WorkOrder List---->",workOrderList)

  const allworkOrderListdata = useSelector((state) => state.allWorkOrderListSlice.value);

  // console.log("allwordOrderList data--->",allworkOrderListdata)

  // console.log("Alldata workorder",allworkOrderListdata)

  // indexvalue

  const indexvalue = useSelector((state) => state.workOrdersListSlice.index);
  // console.log("index value---><<",indexvalue)



  // work order Single View
  const workOrderSingle = useSelector(
    (state) => state.workOrderSingleDataSlice.value
  );
  const viewOrderSingleTableInfoList = useSelector((state) => state.workOrderSingleDataSlice.viewOrderSingleInfo)


  //Generated qr code
  const generatedQrCode = useSelector((state) => state.qrForPcsSlice.value);
  // cutting slip template
  const cuttingSlipTemplate = useSelector(
    (state) => state.cuttingSlipSlice.value
  );
  const navigate = useNavigate();
  const [log, setLog] = useState([]);
  // snakcbar states
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");
  // console.log(selectedRowRelation);
  //Multiple Payment or not
  const [isMultiplePayment, setIsMultiplePayment] = useState(false);
  const [viewOrderView, setViewOrderView] = useState(false);
  const [printCuttingSlipDialog, setPrintCuttingSlipDialog] = useState(false);
  const [printCuttingSlipSingleView, setPrintCuttingSlipSingleView] =
    useState(false);
  const [measurementUpdation, setMeasurementUpdation] = useState(false);
  //Loading state
  const [isLoading, setIsLoading] = useState(false);
  const [logView, setLogView] = useState(false);
  //Date Form
  const dateFormInitialState = {
    fromDate: `${today} 00:00:00`,
    toDate: `${today} 23:59:59`,
  };
  const [dateForm, setDateForm] = useState(dateFormInitialState);
  // console.log("data form state ",dateForm)
  //Search Keyword
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchKeywordRelation, setSearchKeywordRelation] = useState("");
  const [searchKeywordMobile, setSearchKeywordMobile] = useState("");

  //Filtered Order List for search
  const [scrollIndex, setScrollIndex] = useState(0);

  const [orderType,setOrderType]=useState("WO")
  //Print cutting slip shortcut parent dialog state of current file
  const [printCSShortcut, setPrintCSShortcut] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  //Print cutting slip shortcut dialog open state
  const [printCuttingSlipShortCut, setPrintCuttingSlipShortCut] =
    useState(false);
  //Update Listener state
  const [isModify, setIsModify] = useState(false);
  //Product types of clicked Order
  const [prodTypesOfClickedOrder, setProdTypesOfClickedOrder] = useState([]);
  const [dateOfClickedOrder, setDateOfClickedOrder] = useState([]);
  // table prod types list states
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorMob, setAnchorMob] = useState(false);
  const [anchorDate, setAnchorDate] = useState(false);
  const openAnchorDate = Boolean(anchorDate);
  const open = Boolean(anchorEl);
  // relation list states
  const [anchorE2, setAnchorE2] = useState(null);
  const openRelation = Boolean(anchorE2);
  //Open Payment State
  const [openPayment, setOpenPayment] = useState(false);
  //currently paying work order object
  const [currentPayingObj, setCurrentPayingObj] = useState(null);
  //Item Type with Count object
  const [itemTypeCountObj, setItemTypeCountObj] = useState({});
  //Extracted relation array on click of open relation icon
  const [selectedRowRelation, setSelectedRowRelation] = useState([]);
  //Relation opened WO ID
  const [selectedRowOrdId, setSelectedRowOrdId] = useState(null);
  const [selectedRowCusId, setSelectedRowCusId] = useState(null);
  //Cutting Slip NewModel State
  const [cuttingSlipNewModel, setCuttingSlipNewModel] = useState([]);
  //Hold Printing Object
  const [printingTypeObject, setPrintingTypeObject] = useState(null);

  const [branchId, setBranchId] = useState(null);
  // filter status
  const [viewOrderStatus, setViewOrderStatus] = useState("select");
  const [searchDataByRelation, setSearchDataByRelation] = useState(false);
  const [searchRelationToggl, setSearchRelationToggl] = useState(false);
  // console.log("123",viewOrderStatus)
  const [filteredViewOrderList, setFilteredViewOrderList] = useState([]);

  const [isPopupOpen, setPopupOpen] = useState(false);

  // onScroll states
  // const [currPage, setCurrPage] = useState(0); // storing current page number

  let thobCuttingSlip = useRef();
  let jalabiaCuttingSlip = useRef();
  let salwarCuttingSlip = useRef();
  let abayaCuttingSlip = useRef();
  let churidharCuttingSlip = useRef();
  let shirtCuttingSlip = useRef();
  let pantCuttingSlip = useRef();
  let coatCuttingSlip = useRef();
  let sharwaniCuttingSlip = useRef();
  let safariCuttingSlip = useRef();

  const listInnerRef = useRef();

  const handleClickAnchorOpen = (event) => {
    setAnchorEl(event.currentTarget);
    // setOpenDocAnchor(true)
  };

  const clickLogView = () => {
    setLogView(!logView);
  };


  const orderTypeChange=(e)=>{
    setScrollIndex(0)
    setOrderType(e.target.value)
  }
  const handleClickAnchorDateOpen = (event) => {
    setAnchorDate(event.currentTarget);
  };
  const handleCloseAnchor = () => {
    setAnchorEl(null);
    setAnchorMob(false);
    // setOpenDocAnchor(false)
  };
  const handleCloseAnchorDate = () => {
    setAnchorDate(null);
    setDateOfClickedOrder([]);
  };
  const handleClickAnchorRelationOpen = (event) => {
    setAnchorE2(event.currentTarget);
    // setOpenDocAnchor(true)
  };
  const handleCloseAnchorRelation = () => {
    setAnchorE2(null);
    // setOpenDocAnchor(false)
  };
  const handleTrClick = (row) => () => {
    setPopupOpen(false);
    viewOrderSingleTableInfo({ _id: row?._id })
  };
  const viewOrderSingleViewData = [
    {
      name: "jalabia",
      status: "delivered",
      details: [
        {
          sleeve: "12",
          shoulder: "10",
          length: "10",
          chest: "10",
          neck: "10",
        },
      ],
      cuttingmaster: "aswathi",
      tailor: "aswathi",
    },
  ];

  //*Fns
  //Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };
  //Close Success Snackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnack(false);
  };
  //Close Error Snackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };

  //Open Payment click
  const openPaymentScreen = (woObj) => () => {
    setCurrentPayingObj(woObj);
    setOpenPayment(true);
  };
  //Close Payment screen
  const closePaymentScreen = () => {
    setOpenPayment(false);
    setCurrentPayingObj(null);
  };

  //Click Job completion Icon
  const clickJobCompletionIcon = (woObj) => () => {
    localStorage.setItem("JC_WO_ID", woObj?._id);
    navigate("/userdashboard/orders/jobCompletion");
  };

  //Click Delivery Icon
  const clickDeliveryIcon = (woObj) => () => {
    localStorage.setItem("DELRY_CUS_ID", woObj?.cusId);
    navigate("/userdashboard/orders/delivery");
  };

  //Clear View orders state
  const clearViewOrderUser = () => {
    // console.log("Cleared");
    updateListener();
  };

  //Filter Product List
  const filterProductList = (chars, prodList) => {
    let searchResult = [];
    let filteredArray = [];
    if (chars !== "") {
      searchResult = prodList?.filter(
        (obj) =>
          obj?.customer?.toLowerCase()?.includes(chars?.toLowerCase()) ||
          obj?.orderNo?.toLowerCase()?.includes(chars?.toLowerCase()) ||
          obj?.mobile?.toLowerCase()?.includes(chars?.toLowerCase())
      );
      filteredArray = searchResult;
    } else {
      filteredArray = prodList;
    }
    return filteredArray;
  };

  //Open Cutting Slip Single View
  const openCuttingSlipSingleView = () => {
    setPrintCuttingSlipDialog(false);
    setPrintCuttingSlipSingleView(true);
  };
  //Close Cutting Slip Single View
  const closeCuttingSlipSingleView = () => {
    setPrintCuttingSlipSingleView(false);
    setPrintCuttingSlipDialog(true);
  };
  //Click Single View Icon in Orders Table
  const clickSingleViewIcon = (woId) => () => {
    // () => setViewOrderView(true)
    //thob
    viewThobModelAPICall();
    viewMarModelAPICall();
    viewCuffAPICall();
    viewCuffClothAPICall();
    viewCuffModelAPICall();
    viewCollarAPICall();
    viewCollarClothAPICall();
    viewCollarModelAPICall();
    viewPenAPICall();
    viewFpModelAPICall();
    //jalabia
    viewJalabiaModelAPICall();
    viewNeckModelAPICall();
    viewStitchingAPICall();
    viewModelAPICall();
    //Salwar
    viewElasticWidthAPICall();
    //shirt, safari,sharvani
    viewCupAPICall();
    viewBottomModelAPICall();
    // cutting slip temp
    viewCuttingSlipModelAPICall();
    viewSidePtModelAPICall();
    setViewOrderView(true);
    if (woId !== undefined) {
      workOrderSingleViewAPICall({ orderId: woId },setLoadingForm);
    }
  };

  //Click Cutting slip initial icon
  const clickCuttingSlipInitialIcon = (orderId) => () => {
    workOrderSingleViewAPICall({ orderId },setLoadingForm);
    setPrintCSShortcut(true); //change to new state
    setPrintCuttingSlipShortCut(true);
  };
  //Close Print Cutting slip Shortcut dialog
  const closeCuttingSlipShortCutDialog = () => {
    setPrintCSShortcut(false);
    setPrintCuttingSlipShortCut(false);
    updateListener();
  };
  //Generate qr code for print cutting slip
  const generatePrintCuttingSlipQr = () => {
    // console.log("clicked");
    if (workOrderSingle !== undefined) {
      generateQRCodeForPCSAPICall({
        _id: workOrderSingle?._id,
      });
    }
  };
  //Find Item type count to display in view order list
  const findItemTypeCount = (completeArray) => {
    const count = {};
    completeArray?.forEach((el) => {
      count[el] = (count[el] || 0) + 1;
    });

    setItemTypeCountObj(count);
    // console.log(count);
  };

  //Click New Orders Icon (+ icon button)
  const clickNewOrderIconBtn = (orderId) => () => {
    localStorage.setItem("ORD_ID_NEW_ORD", orderId);
    localStorage.setItem("IS_REL", false);
    localStorage.removeItem("REL_ID");
    navigate("/userdashboard/orders/workOrder");
  };

  //*onChange
  const getDateForm = (key) => (e) => {
    const { value } = e.target;
    switch (key) {
      case "fromDate":
        setDateForm({ ...dateForm, fromDate: `${value} 00:00:00` });
        break;
      case "toDate":
        setDateForm({ ...dateForm, toDate: `${value} 23:59:59` });
        break;

      default:
        break;
    }
  };
  //Save cred to LS
  const saveRelationCredsToLS = (orderId, relationCusId) => {
    localStorage.setItem("ORD_ID_NEW_ORD", orderId);
    localStorage.setItem("REL_ID", relationCusId);
    localStorage.setItem("IS_REL", true);

    navigate("/userdashboard/orders/workOrder");
  };

  //Click relation new Order (+ button)
  const clickRelationNewOrder = (relationId, orderId, customerId) => () => {
    let res = convertRelationToCustomerAPICall({
      relationId,
      branchId: storeCode,
      customerId,
    });
    res
      .then((resp) => saveRelationCredsToLS(orderId, resp.data?._id))
      .catch(
        (err) =>
          err.response.status === 409
            ? saveRelationCredsToLS(orderId, err.response.data?._id)
            : setSnackMsg(err.response.status)
        // setOpenErrorSnack(true)
      );

    // console.log(relationId);
  };

  //Click Measurement update icon button
  const clickMeasurementUpdateBtn = (item, woId) => {
    item.isEdit = !item.isEdit;
    setCuttingSlipNewModel({ ...cuttingSlipNewModel });

    //todo
    if (item.isEdit === false) {
      let items = createMeasurementUpdateBackedCompatibleObject(
        item,
        item?.type
      );

      const measurementUpdateBody = {
        id: woId,
        items,
      };
      //todo call measurement updation api
      updateMeasurementAPICall(measurementUpdateBody);
    }
  };
  //Measurement Update onChange functions
  const getMeasurementUpdateValues = (item, key, index) => (e) => {
    const { value } = e.target;
    switch (key) {
      case "thobModel":
        item.materials[index].thobModel = value;
        break;
      case "marModel":
        item.materials[index].marModel = value;
        break;
      case "cuff":
        item.materials[index].cuff = value;
        break;
      case "cuffSize1":
        item.materials[index].cuffSize1 = value;
        break;
      case "cuffSize2":
        item.materials[index].cuffSize2 = value;
        break;
      case "cuffCloth":
        item.materials[index].cuffCloth = value;
        break;
      case "cuffModel":
        item.materials[index].cuffModel = value;
        break;
      case "collar":
        item.materials[index].collar = value;
        break;
      case "collarCloth":
        item.materials[index].collarCloth = value;
        break;
      case "collarClothName":
        item.materials[index].collarCloth = value;
        break;
      case "collarModel":
        item.materials[index].collarModel = value;
        break;

      case "fpModel":
        item.materials[index].fpModel = value;
        break;
      case "penData":
        item.materials[index].pen = value;
        break;
      case "jalabiaModel":
        item.materials[index].jalabiaModel = value;
        break;
      case "neckModel":
        item.materials[index].neckModel = value;
        break;
      case "stitching":
        item.materials[index].stitching = value;
        break;
      case "model":
        item.materials[index].model = value;
        break;
      case "fpModel":
        item.materials[index].fpModel = value;
        break;
      case "elasticWidth":
        item.materials[index].elasticWidth = value;
        break;
      case "cup":
        item.materials[index].cup = value;
        break;
      case "marModel":
        item.materials[index].marModel = value;
        break;
      case "bottomModel":
        item.materials[index].bottomModel = value;
        break;
      case "remark":
        item.materials[index].remark = value;
        break;
      case "remarks":
        item.materials[index].remarks = value;
        break;
      case "penData":
        item.materials[index].penData = value;
        break;
      case "sidePtName":
        item.materials[index].sidePtModel = value;
      case "sidePTSize":
        item.materials[index].sidePTSize = value;
        break;
      case "collarSize":
        item.materials[index].collarSize = value;
        break;
      case "length":
        item.length = value;
        break;
      case "shoulder":
        item.shoulder = value;
        break;
      case "sleeve":
        item.sleeve = value;
        break;
      case "chest":
        item.chest = value;
        break;
      case "stomach":
        item.stomach = value;
        break;
      case "slChest":
        item.slChest = value;
        break;
      case "slSo":
        item.slSo = value;
        break;
      case "neck":
        item.neck = value;
        break;
      case "bottom":
        item.bottom = value;
        break;
      case "marSize":
        item.marSize = value;
        break;

      case "regalSize":
        item.regalSize = value;
        break;
      case "kneeLoose":
        item.kneeLoose = value;
        break;
      case "fpDown":
        item.fpDown = value;
        break;
      case "fpSize1":
        item.fpSize1 = value;
        break;
      case "fpSize2":
        item.fpSize2 = value;
        break;
      case "down":
        item.down = value;
        break;
      case "veedi":
        item.veedi = value;
        break;
      case "bag":
        item.bag = value;
        break;
      case "open":
        item.open = value;
        break;
      //Jalabia
      case "slSto":
        item.slSto = value;
        break;
      case "sleeveSize":
        item.sleeveSize = value;
        break;
      case "regalSize":
        item.regalSize = value;
        break;
      case "neckDButton":
        item.neckDButton = value;
        break;
      case "mar":
        item.mar = value;
        break;
      //Salwar
      case "waist":
        item.waist = value;
        break;
      case "elasticSize":
        item.elasticSize = value;
        break;
      //abaya
      case "sleeveFromNeck":
        item.sleeveFromNeck = value;
        break;
      case "sleeveFromShoulder":
        item.sleeveFromShoulder = value;
        break;
      case "sleeveWidth":
        item.sleeveWidth = value;
        break;
      case "arms":
        item.arms = value;
        break;
      case "bust":
        item.bust = value;
        break;
      case "waist":
        item.waist = value;
        break;
      case "hip":
        item.hip = value;
        break;
      //Churidhar
      case "frontNeck":
        item.frontNeck = value;
        break;
      case "backNeck":
        item.backNeck = value;
        break;
      case "fullLength":
        item.fullLength = value;
        break;
      case "armRound":
        item.armRound = value;
        break;
      case "armHole":
        item.armHole = value;
        break;
      case "sleeveLength":
        item.sleeveLength = value;
        break;
      //Shirt
      case "sleeveFull":
        item.sleeveFull = value;
        break;
      case "body":
        item.body = value;
        break;
      case "regal":
        item.regal = value;
        break;
      case "kneeLose":
        item.kneeLose = value;
        break;
      case "disco":
        item.disco = value;
        break;
      case "canvas":
        item.canvas = value;
        break;
      case "seat1":
        item.seat1 = value;
        break;
      case "seat2":
        item.seat2 = value;
        break;
      case "seat3":
        item.seat3 = value;
        break;
      //Pant
      case "inside":
        item.inside = value;
        break;
      case "seat":
        item.seat = value;
        break;
      case "thigh":
        item.thigh = value;
        break;
      case "ply":
        item.ply = value;
        break;
      case "belt":
        item.belt = value;
        break;
      case "sidePocket":
        item.sidePocket = value;
        break;
      case "backPocket":
        item.backPocket = value;
        break;
      case "pleat":
        item.pleat = value;
        break;
      case "loops":
        item.loops = value;
        break;
      //Coat
      case "front":
        item.front = value;
        break;
      case "back":
        item.back = value;
        break;
      case "sleeveLoose":
        item.sleeveLoose = value;
        break;
      case "fpButton":
        item.fpButton = value;
        break;
      case "backOpen":
        item.backOpen = value;
        break;

      default:
        item[key] = value;
        break;
    }
    setCuttingSlipNewModel({ ...cuttingSlipNewModel });
    // console.log(item);

  };


  //Click Edit Order Icon Button
  const clickEditOrderIconBtn = (r) => () => {
    // console.log("Edit Icon Button Clicked");
    // console.log(r);
    localStorage.setItem("IS_REL", false);
    localStorage.removeItem("REL_ID");
    localStorage.removeItem("ORD_ID_NEW_ORD");
    localStorage.setItem("WO_EDIT_ID", r?._id);
    localStorage.setItem("IS_WO_EDIT", true);
    navigate("/userdashboard/orders/workOrder");
  };

  //get selected Product from SingleView drop down
  const getSelectedProductFromDropdown = (object) => (e) => {
    const { value } = e.target;

    const [mName, index] = value.split(":");

    object.selectedProduct = value;
    object.selectedProductIndex = index;
    setCuttingSlipNewModel({ ...cuttingSlipNewModel });

    // console.log(cuttingSlipNewModel);
  };

  //Make First product Selectable in single view
  const makeFirstProductSelectable = (cuttingSlipNewModel = {}) => {
    cuttingSlipNewModel?.items?.forEach((item) => {
      item.selectedProduct = `${item?.materials[0]?.mName}:0`;
      item.selectedProductIndex = 0;
    });

    setCuttingSlipNewModel({ ...cuttingSlipNewModel });
  };

  //Call Search Fn (Backend API)
  const searchViewOrderClick = () => {
    if (searchKeyword !== "")
      searchWorkOrderListUserAPICall(
        { search: searchKeyword, branchId: storeCode },
        setIsLoading
      );
    else
      viewWorkOrdersByUserAPICall(
        {
          fromDate:
            dateForm?.fromDate?.split(" ")[0] !== ""
              ? dateForm?.fromDate
              : null,
          toDate:
            dateForm?.toDate?.split(" ")[0] !== "" ? dateForm?.toDate : null,
          branchId: userRole === "user" ? storeCode : null,
          type:orderType
        },
        setIsLoading
      );
  };
  const searchViewOrderRelationClick = () => {
    searchWorkOrderListUserAPICall({ search: searchKeywordRelation, branchId: branchId?._id }, setIsLoading)
    // viewOrderByRelationSearchAPICall({search:searchKeywordRelation,branchId:branchPk})
    setSearchDataByRelation(true)
    setSearchRelationToggl(!searchRelationToggl)
  }
  const searchViewOrderMobile = () => {
    viewOrderByRelationSearchAPICall({ search: searchKeywordMobile }, setIsLoading)
  }

  //  branchOnChange function
  const onChangeBranch = (e, newValue) => {
    setSearchKeywordRelation("")
    if (userRole === "user") {
      newValue === null ? setBranchId(filterObjFromList("storeCode", allowedBranchList, "branchId", { branchId: storeCode })) : setBranchId(newValue)
    } else {
      setBranchId(newValue)
    }
  }
  useEffect(() => {
    if (viewOrderSingleTableInfoList !== undefined) {
      setPopupOpen(true);
      setLog(viewOrderSingleTableInfoList?.log || [])
    }
  }, [viewOrderSingleTableInfoList])
  //*useEffects
  useEffect(() => {
    if (viewOrderListByRelationSearch !== undefined) {
      setFilteredViewOrderList(viewOrderListByRelationSearch?.orderList)
    }
    else {
      setFilteredViewOrderList([])
    }
  }, [viewOrderListByRelationSearch, searchRelationToggl])

  //Initial API Calls
  useEffect(() => {
    // viewWorkOrdersByUserAPICall(dateForm, setIsLoading);

    if(userRole==="user" && branchId!==null){
      viewWorkOrdersByUserAPICall(
        {
          fromDate:
            dateForm?.fromDate?.split(" ")[0] !== "" ? dateForm?.fromDate : null,
          toDate:
            dateForm?.toDate?.split(" ")[0] !== "" ? dateForm?.toDate : null,
          branchId: branchId?._id || null,
          index: 0,
          limit: "10",
          type:orderType
        },
        setIsLoading
      );
    }
    else if(userRole==="admin"){
      viewWorkOrdersByUserAPICall(
        {
          fromDate:
            dateForm?.fromDate?.split(" ")[0] !== "" ? dateForm?.fromDate : null,
          toDate:
            dateForm?.toDate?.split(" ")[0] !== "" ? dateForm?.toDate : null,
          branchId: branchId?._id || null,
          index: 0,
          limit: "10",
          type:orderType
        },
        setIsLoading
      );
    }
    localStorage.setItem("IS_WO_EDIT", false);
  }, [dateForm,isModify,orderType]);

  //Call Filter(Search) fn (front end)
  // useEffect(() => {
  //   if (workOrderList !== undefined) {
  //     setFilteredWorkOrderList(workOrderList?.orderList);
  //     let filteredArray = filterProductList(
  //       searchKeyword,
  //       workOrderList?.orderList
  //     );
  //     setFilteredWorkOrderList(filteredArray);
  //   } else {
  //     setFilteredWorkOrderList([]);
  //   }
  // }, [searchKeyword, workOrderList?.orderList]);

  // useEffect(() => {
  //   if (searchKeyword !== "")
  //     searchWorkOrderListUserAPICall({ search: searchKeyword,branchId:storeCode }, setIsLoading);
  //   else
  //     viewWorkOrdersByUserAPICall(
  //       {
  //         fromDate:
  //           dateForm?.fromDate?.split(" ")[0] !== ""
  //             ? dateForm?.fromDate
  //             : null,
  //         toDate:
  //           dateForm?.toDate?.split(" ")[0] !== "" ? dateForm?.toDate : null,
  //         branchId:storeCode
  //       },
  //       setIsLoading
  //     );
  // }, [searchKeyword]);


  // useEffect(() => {
  //   if (searchKeyword === "") {
  //     setSearchDataByRelation(false)
  //     viewWorkOrdersByUserAPICall(
  //       {
  //         fromDate:
  //           dateForm?.fromDate?.split(" ")[0] !== ""
  //             ? dateForm?.fromDate
  //             : null,
  //         toDate:
  //           dateForm?.toDate?.split(" ")[0] !== "" ? dateForm?.toDate : null,
  //         branchId: branchId?.storeCode || null,
  //         index: currPage,
  //         limit: "10",
  //       },
  //       setIsLoading
  //     );
  //   }
  // }, [searchKeyword]);
  //Find count of Item Types
  useEffect(() => {
    findItemTypeCount(prodTypesOfClickedOrder);
  }, [prodTypesOfClickedOrder]);

  // call create cutting slip new model fn (from external js  file[newModelCuttingSlip.js] created)
  useEffect(() => {
    if (workOrderSingle !== undefined) {
      generatePrintCuttingSlipQr();
      let singleViewCopy = makeCuttingSlipSingleViewExtensible(workOrderSingle);
      let newModel = splitRelationsOrCustomer(singleViewCopy);
      setCuttingSlipNewModel(newModel);
      // console.log(newModel);
      //make First item selectable in single view
      makeFirstProductSelectable(newModel);
    }
  }, [workOrderSingle]);

  // console.log(filteredWorkOrderList);

  // console.log(workOrderList);
  // console.log(cuttingSlipNewModel);

  const filterStatusSelect = (status, ListToFilter) => {
    // console.log("status--->",status)
    // console.log("ListToFilter--->11",ListToFilter)
    // console.log("allworkOrderListdata",allworkOrderListdata?.orderList)
    let allOrderList = allworkOrderListdata?.orderList;
    setSearchDataByRelation(false)
    let statusCheckArray = [];
    let filteredArray = [];
    if (status === "delivered") {
      statusCheckArray = allOrderList?.filter(
        (obj) => obj?.delivered === obj?.totalItems
      );
      filteredArray = statusCheckArray;
    } else if (status === "delivery") {
      statusCheckArray = allOrderList?.filter(
        (obj) => !(obj?.delivered === obj?.totalItems) && obj?.delivered > 0
      );
      filteredArray = statusCheckArray;
    } else if (status === "finished") {
      statusCheckArray = allOrderList?.filter(
        (obj) =>
          !(obj?.delivered === obj?.totalItems) &&
          !(obj?.delivered > 0) &&
          obj?.completed === obj?.totalItems
      );
      filteredArray = statusCheckArray;
    } else if (status === "finish") {
      statusCheckArray = allOrderList?.filter(
        (obj) =>
          !(obj?.delivered === obj?.totalItems) &&
          !(obj?.delivered > 0) &&
          !(obj?.completed === obj?.totalItems) &&
          obj?.completed > 0
      );
      filteredArray = statusCheckArray;
    } else if (status === "printing") {
      statusCheckArray = allOrderList?.filter(
        (obj) =>
          !(obj?.delivered === obj?.totalItems) &&
          !(obj?.delivered > 0) &&
          !(obj?.completed === obj?.totalItems) &&
          !(obj?.completed > 0) &&
          obj?.printing > 0
      );
      filteredArray = statusCheckArray;
    } else if (status === "booked") {
      statusCheckArray = allOrderList?.filter(
        (obj) =>
          !(obj?.delivered === obj?.totalItems) &&
          !(obj?.delivered > 0) &&
          !(obj?.completed === obj?.totalItems) &&
          !(obj?.completed > 0) &&
          !(obj?.printing > 0) &&
          obj?.status?.includes("pending")
      );
      filteredArray = statusCheckArray;
    } else if (status === "return") {
      statusCheckArray = allOrderList?.filter(
        (obj) =>
          !(obj?.delivered === obj?.totalItems) &&
          !(obj?.delivered > 0) &&
          !(obj?.completed === obj?.totalItems) &&
          !(obj?.completed > 0) &&
          !(obj?.printing > 0) &&
          !obj?.status?.includes("pending") &&
          obj?.status?.includes("orderReturned")
      );
      filteredArray = statusCheckArray;
    } else if (status === "d-return") {
      statusCheckArray = allOrderList?.filter(
        (obj) =>
          !(obj?.delivered === obj?.totalItems) &&
          !(obj?.delivered > 0) &&
          !(obj?.completed === obj?.totalItems) &&
          !(obj?.completed > 0) &&
          !(obj?.printing > 0) &&
          !obj?.status?.includes("pending") &&
          !obj?.status?.includes("orderReturned") &&
          obj?.status?.includes("deliveryReturned")
      );
      filteredArray = statusCheckArray;
    } else {
      filteredArray = ListToFilter;
    }
    return filteredArray;
  };

  useEffect(() => {
    if (workOrderList !== undefined) {

      if (viewOrderStatus == "all" && searchKeyword === "") {

        setFilteredViewOrderList(allworkOrderListdata?.orderList)
        return

      }

      let filteredArray = [];
      setFilteredViewOrderList(workOrderList);
      filteredArray = filterStatusSelect(
        viewOrderStatus,
        workOrderList?.orderList
      );

      // console.log("FileredArrayyyy---->",filteredArray)

      setFilteredViewOrderList(filteredArray);
    } else {
      setFilteredViewOrderList([]);
    }
  }, [viewOrderStatus, workOrderList]);

  // console.log(filteredViewOrderList);

  // On scroll
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      // console.log("scrollTop",scrollTop)
      // console.log("scrollHeight",scrollHeight)
      // console.log("clientHeight",clientHeight)
      // console.log(Math.round(1017.5))
      if (Math.round(scrollTop + clientHeight) === scrollHeight) {
        // This will be triggered after hitting the last element.
        // API call should be made here while implementing pagination.
        // console.log("******>>>>", scrollTop);
        // setCurrPage(currPage+1)
        if(!isLoading && workOrderList.orderList.length >=29){
          setScrollIndex((prevState)=>prevState+1)
        }
        // store.dispatch(incrementIndex({ workOrderList: workOrderList }));
      }
      if (!isLoading && scrollTop === 0) {
        if(scrollIndex > 0){
          setScrollIndex((prevState)=>prevState-1)
        }

        // store.dispatch(decrementIndex());
      }
    }
  };
  // On scroll useEffect

  useEffect(() => {

    if (viewOrderStatus == "select") {
      setSearchDataByRelation(false)
      if (searchKeyword == "") {

        if(userRole==="user" && branchId!==null){
          viewWorkOrdersByUserAPICall(
            {
              fromDate:
                dateForm?.fromDate?.split(" ")[0] !== "" ? dateForm?.fromDate : null,
              toDate:
                dateForm?.toDate?.split(" ")[0] !== "" ? dateForm?.toDate : null,
              branchId: branchId?._id || null,
              index: scrollIndex,
              limit: "10",
              type:orderType
            },
            setIsLoading
          );
          listInnerRef.current.scrollTop = 0;
            
        }
        else if(userRole==="admin"){
          viewWorkOrdersByUserAPICall(
            {
              fromDate:
                dateForm?.fromDate?.split(" ")[0] !== "" ? dateForm?.fromDate : null,
              toDate:
                dateForm?.toDate?.split(" ")[0] !== "" ? dateForm?.toDate : null,
              branchId: branchId?._id || null,
              index: scrollIndex,
              limit: "10",
              type:orderType
            },
            setIsLoading
          );
          listInnerRef.current.scrollTop = 0;
  
        }
     
      }

    }
  }, [scrollIndex, viewOrderStatus]);


  //preves page function....

  const prevPage = () => {

    // setScrollIndex((prevState))
    if(scrollIndex>0){
      setScrollIndex((prevState)=>prevState-1)
    }

    // store.dispatch(decrementIndex())
  }

  // console.log(selectedRowRelation);

  //  reset index value





  // getAllWorkOrdersAPIcall
  // useEffect(() => {
  //   getAllWorkOrdersAPIcall(
  //     {
  //       fromDate:
  //         dateForm?.fromDate?.split(" ")[0] !== "" ? dateForm?.fromDate : null,
  //       toDate:
  //         dateForm?.toDate?.split(" ")[0] !== "" ? dateForm?.toDate : null,
  //       branchId: branchId?.storeCode || null,
  //     },
  //     setIsLoading
  //   );
  //   setSearchDataByRelation(false)
  // }, [isModify, dateForm])
  ////

  useEffect(() => {
    viewAllBranchesAPICall()
    viewAllAllowedBranchesAPICall({ employeeId: loginResponse?.data?._id });
  }, [])

  useEffect(() => {
    // if (searchKeywordRelation === "") {
      setSearchDataByRelation(false)
      setViewOrderStatus("select")
      if(userRole==="user" && branchId!==null){
        viewWorkOrdersByUserAPICall(
          {
            fromDate:
              dateForm?.fromDate?.split(" ")[0] !== "" ? dateForm?.fromDate : null,
            toDate:
              dateForm?.toDate?.split(" ")[0] !== "" ? dateForm?.toDate : null,
            branchId: branchId?._id || null,
            index: scrollIndex,
            limit: "10",
            type:orderType
          },
          setIsLoading
        );
      }
      else if(userRole==="admin"){
        viewWorkOrdersByUserAPICall(
          {
            fromDate:
              dateForm?.fromDate?.split(" ")[0] !== "" ? dateForm?.fromDate : null,
            toDate:
              dateForm?.toDate?.split(" ")[0] !== "" ? dateForm?.toDate : null,
            branchId: branchId?._id || null,
            index: scrollIndex,
            limit: "10",
            type:orderType
          },
          setIsLoading
        );
      }
      
    // }
    // else {
    //   searchWorkOrderListUserAPICall({ search: searchKeywordRelation }, setIsLoading)
    // }
  }, [ branchId])
  // useEffect(() => {
  //   // if (searchKeywordMobile === "") {
  //     setSearchDataByRelation(false)
  //     setViewOrderStatus("select")
  //     viewWorkOrdersByUserAPICall(
  //       {
  //         fromDate:
  //           dateForm?.fromDate?.split(" ")[0] !== "" ? dateForm?.fromDate : null,
  //         toDate:
  //           dateForm?.toDate?.split(" ")[0] !== "" ? dateForm?.toDate : null,
  //         branchId: branchId?.storeCode || null,
  //         index: indexvalue,
  //         limit: "10",
  //       },
  //       setIsLoading
  //     );
  //   // }
  //   // else {
  //   //   viewOrderByRelationSearchAPICall({ search: searchKeywordMobile }, setIsLoading)
  //   // }
  // }, [ branchId])

  // setting default branch in user Case
  useEffect(() => {
    let userBranch = {
      branchId: storeCode
    }
    allowedBranchList !== undefined && userRole === "user" && setBranchId(filterObjFromList("storeCode", allowedBranchList, "branchId", userBranch))

  }, [userRole, allowedBranchList])




  return (
    <div className="global-page-parent-container"
      style={{ display: "flex", height: "90vh", flexDirection: "column", justifyContent: "space-between" }}
    >
      <div className="view-order-container">
        <div className="view-order-top-main-container">
          <div className="view-order-top-container">
            <div className="view-order-top-left-container" style={{ width: "50%" }}>
              <div className="date-picker-container new-date-picker-style">
                <Datepicker
                  type="date"
                  color="#be45f9"
                  backgroundColor="#f1f2ff"
                  onChange={getDateForm("fromDate")}
                  value={dateForm?.fromDate?.split(" ")[0]}
                />
              </div>
              <div className="date-picker-container">
                <Datepicker
                  type="date"
                  color="#be45f9"
                  backgroundColor="#f1f2ff"
                  onChange={getDateForm("toDate")}
                  value={dateForm?.toDate?.split(" ")[0]}
                />
              </div>
              <div className="global-single-input auto-complete" style={{ width: "33%" }}>
                <Autocomplete
                  options={
                    (userRole === "user" ? allowedBranchList : allBranchesList) || []}
                  getOptionLabel={(option) =>
                    `${option?.storeCode}-${option?.branchName}`
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="All Branches" />
                  )}
                  onChange={onChangeBranch}
                  value={branchId}

                />
              </div>
            </div>
            <div className="right-side-search-div">

              <div className="global-single-input select-div" style={{ width: "25%" }}>
                {/* <p>Filter Status</p> */}
                <Select
                  style={{ backgroundColor: "white" }}
                  value={viewOrderStatus}
                  onChange={(e) => {setViewOrderStatus(e.target.value)
                                    setScrollIndex(0)}}
                >
                  <MenuItem value="select"><i class="bi bi-funnel" style={{ color: " #5c5ce5" }}></i></MenuItem>
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="delivered">Delivered</MenuItem>
                  <MenuItem value="delivery">Delivery</MenuItem>
                  <MenuItem value="finished">Finished</MenuItem>
                  <MenuItem value="finish">Finish</MenuItem>
                  <MenuItem value="printing">Printing</MenuItem>
                  <MenuItem value="booked">Booking</MenuItem>
                  <MenuItem value="return">O.return</MenuItem>
                  <MenuItem value="d-return">D.return</MenuItem>
                </Select>
              </div>
              <div className="view-order-search-container" style={{ width: "35%", position: "relative" }}>
                <p className="search-head">Search</p>
                <input
                  placeholder="Name,Order No,Mobile..."
                  value={searchKeywordRelation}
                  onChange={(e) => setSearchKeywordRelation(e.target.value)}
                  onKeyDown={(e) => e.key === "Enter" && searchViewOrderRelationClick()}
                />
                <SearchIcon className="billing-search-icon" style={{ color: "#736ff3" }} onClick={searchViewOrderRelationClick} />

              </div>
              <div className="view-order-search-container" style={{ width: "35%", position: "relative" }}>
                <p className="search-head">Mobile Relation</p>
                <input
                  placeholder="Search by relation..."
                  value={searchKeywordMobile}
                  onChange={(e) => setSearchKeywordMobile(e.target.value)}
                  onClick={searchViewOrderMobile}
                  onKeyDown={(e) => e.key === "Enter" && searchViewOrderMobile()}
                />
                <SearchIcon className="billing-search-icon" style={{ color: "#736ff3" }} />

              </div>
            </div>
          </div>
          <div className="view-order-top-container">
            <div className="view-order-top-left-container">
              <div className="os-balance-container" style={{ width: "22%" }}>
                <p>O/S Balance</p>
                {
                  searchDataByRelation ?
                    <h5>{viewOrderListByRelationSearch?.osBalance || "0.00"}</h5>
                    :
                    <h5>{workOrderList?.osBalance || "0.00"}</h5>
                }
              </div>
              <div className="radio-groups-container">
                <div
                  className="global-radio-button"
                  style={{ width: "100px", display: "flex", alignItems: "center" }}
                >
                  <input
                    type="radio"
                    id="orderWorkOrder"
                    value={"WO"}
                    onChange={orderTypeChange}
                    checked={orderType === "WO"}

                  />
                  <label htmlFor="orderWorkOrder">Work Orders</label>
                </div>
                <div
                  className="global-radio-button"
                  style={{ width: "85px", display: "flex", alignItems: "center" }}
                >
                  <input
                    type="radio"
                    id="orderAlteration"
                    value={"ALT"}
                    onChange={orderTypeChange}
                    checked={orderType === "ALT"}
                  />
                  <label htmlFor="orderAlteration">Alteration</label>
                </div>
                <div
                  className="global-radio-button"
                  style={{ width: "65px", display: "flex", alignItems: "center" }}
                >
                  <input
                    type="radio"
                    id="orderBilling"
                    value={"ORD"}
                    onChange={orderTypeChange}
                    checked={orderType === "ORD"}
                  />
                  <label htmlFor="orderBilling">Billing</label>
                </div>
              </div>
            </div>
            <div className="view-order-top-right-container">
              <div className="os-balance-container" >
                <p>Total Paid</p>
                {
                  searchDataByRelation ?
                    <h5>{viewOrderListByRelationSearch?.totalPaid || "0.00"}</h5>
                    :
                    <h5>{workOrderList?.totalPaid || "0.00"}</h5>
                }
              </div>

              <div
                className="os-balance-container-d"
                style={{ backgroundColor: "#rgb(194 235 208)" }}
              >
                <p style={{ color: "rgb(18 119 59)", fontSize: "0.7rem" }}>Total Disc</p>
                {
                  searchDataByRelation ?
                    <h5 style={{ color: "rgb(18 119 59)" }}>
                      {viewOrderListByRelationSearch?.totalDiscount || "0.00"}
                    </h5> :
                    <h5 style={{ color: "rgb(18 119 59)" }}>
                      {workOrderList?.totalDiscount || "0.00"}
                    </h5>
                }
              </div>
              <Tooltip title="Cutting Slip">
                <button
                  onClick={() => setPrintCuttingSlipDialog(true)}
                  className="btn btn-primary cutting-slip-button"
                  style={{width:"14%"}}
                >
                  <IconButton>
                    <i class="bi bi-printer printer-icon cutting-slip-print-icon"></i>
                    {/* <img src={CuttingSlipImg} className="view-order-cutting-slip-img top-btn-cutting-slip-image"/> */}
                  </IconButton>
                  {/* Cutting Slip */}
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
        <div
          className="global-table-container view-order-table-container vieworderdummy view-order-containerNew"
          onScroll={onScroll}
          ref={listInnerRef}
          style={{ height: "86%" }}
        // style={{height:"32vh"}}
        >
          <table className="global-table first-table">
            <thead>
              <tr>
                <th>Order</th>
                {
                  userRole === "admin" &&
                  <>
                    <th>B.Code</th>
                    <th>B.Name</th>
                  </>
                }
                <th>Customer</th>
                <th>Mobile</th>
                {
                  userRole === "user" &&
                  <th>Reln.</th>
                }
                <th>Date</th>
                {/* <th>D.Date</th> */}
                <th>Rack</th>
                <th>Types</th>
                <th>Status</th>
                {
                  userRole === "user" &&
                  <>
                    {woEdit && <th>Edit</th>}
                    <th style={{ width: "4%" }}>New</th>
                    <th>C.Slip</th>
                    <th>Job</th>
                    <th>Delivery</th>
                  </>}
                <th>View</th>
                {/* <th>Print</th> */}
                <th>Total</th>
                <th>Paid</th>
                <th>Return</th>
                <th>Discount</th>
                <th>Balance</th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={19}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={38}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={19}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={38}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={19}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={38}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={19}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={38}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={19}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={38}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={19}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={38}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={19}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={38}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={19}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={38}
                    />
                  </td>
                </tr>

              </tbody>
            ) : (
              <tbody>
                {(filteredViewOrderList?.length > 0 &&
                  filteredViewOrderList?.slice(0)?.reverse()?.map((r, i) => (
                    <tr key={i} onClick={handleTrClick(r)}>
                      <td>{r.orderNo}</td>
                      {
                        userRole == "admin" &&
                        <>
                          <td>{r.branchCode}</td>
                          <td>{r.branchName}</td>
                        </>
                      }
                      <td>{r.customer}</td>
                      <td>
                        <div className="add-document-documents-btn">
                          <button
                            onMouseDown={() => {
                              setProdTypesOfClickedOrder(r?.mobile);
                              setAnchorMob(true);
                            }}
                            onClick={handleClickAnchorOpen}
                          >
                            {r?.mobile.length === 0 ? "-NIL-" : r?.mobile[0]}
                            <span style={{ marginTop: "4px" }}>
                              {r?.mobile?.length > 1 ? (
                                <KeyboardArrowDownIcon
                                  sx={{ fontSize: "1rem" }}
                                />
                              ) : undefined}
                            </span>
                          </button>
                        </div>
                      </td>
                      {/* <td>{r.mobile[0]}</td> */}
                      {
                        userRole === "user" &&
                        <td>
                          <Tooltip
                            title="Click to view relation"
                            placement="top"
                            arrow
                          >
                            <IconButton
                              disabled={r?.relation?.length === 0}
                              onMouseDown={() => {
                                setSelectedRowRelation(r?.relation);
                                setSelectedRowOrdId(r?._id);
                                setSelectedRowCusId(r?.cusId);
                              }}
                              onClick={handleClickAnchorRelationOpen}
                            >
                              <i
                                class={
                                  r?.relation?.length === 0
                                    ? "bi bi-people relation-icon-disabled"
                                    : "bi bi-people relation-icon"
                                }
                              ></i>
                            </IconButton>
                          </Tooltip>
                        </td>}
                      {/* <td>{convertDateFormat(r.date)}</td> */}
                      <td>
                        <div className="add-document-documents-btn">
                          <button
                            onMouseDown={() => {
                              if (r.delivered > 0) {
                                setDateOfClickedOrder([
                                  ...dateOfClickedOrder,
                                  `O.date : ${convertDateFormat(r.date)}`,
                                  `D.date : ${convertDateFormat(
                                    r.deliveryDate
                                  )}`,
                                ]);
                              }
                            }}
                            onClick={handleClickAnchorDateOpen}
                          >
                            {convertDateFormat(r?.date)}
                            <span style={{ marginTop: "4px" }}>
                              {r?.delivered > 0 ? (
                                <KeyboardArrowDownIcon
                                  sx={{ fontSize: "1rem" }}
                                />
                              ) : undefined}
                            </span>
                          </button>
                        </div>
                      </td>

                      {/* <td>{r.deliveryDate || "-NIL-"}</td> */}
                      <td>{r.rack === null ? "No rack" : r.rack}</td>
                      <td>
                        <div className="add-document-documents-btn">
                          <button
                            onMouseDown={() =>
                              setProdTypesOfClickedOrder(r?.types)
                            }
                            onClick={handleClickAnchorOpen}
                          >
                            {r?.types?.length === 0
                              ? "-NIL-"
                              : r?.types[0]?.toUpperCase()}
                            <span style={{ marginTop: "4px" }}>
                              {r?.types?.length > 1 ? (
                                <KeyboardArrowDownIcon
                                  sx={{ fontSize: "1rem" }}
                                />
                              ) : undefined}
                            </span>
                          </button>
                        </div>
                        {/* {r?.types?.map((type)=>(<span>{type+' '}</span>))} */}
                      </td>
                      <td>
                        <span
                          className={
                            r?.totalItems == 0
                              ? "status pending view-order-status-booking"
                              : r?.delivered === r?.totalItems
                                ? "status delivered view-order-status-delivery-full"
                                : r?.delivered > 0
                                  ? "status delivered view-order-status-delivered"
                                  : r?.completed > 0
                                    ? "status cancelled view-order-status-finish"
                                    : r?.printing > 0
                                      ? "status cancelled view-order-status-printing"
                                      : r?.status === "pending"
                                        ? "status pending view-order-status-booking"
                                        : r.status === "orderReturned"
                                          ? "status pending view-order-status-order-return"
                                          : r.status === "deliveryReturned"
                                            ? "status pending view-order-status-order-return"
                                            : undefined
                          }
                        >
                          {r?.totalItems == 0
                            ? "Booking"
                            : r?.delivered === r?.totalItems
                              ? "Delivered"
                              : r?.delivered > 0
                                ? `Delivery ${r?.delivered}/${r?.totalItems}`
                                : r?.completed === r?.totalItems
                                  ? "Finished"
                                  : r?.completed > 0
                                    ? `Finish ${r?.completed}/${r?.totalItems}`
                                    : r?.printing > 0
                                      ? `printing ${r?.printing}/${r?.totalItems}`
                                      : r?.status === "pending"
                                        ? "Booking"
                                        : r.status === "orderReturned"
                                          ? "O.Return"
                                          : r.status === "deliveryReturned"
                                            ? "Delivery.R"
                                            : undefined}
                        </span>

                        {/* <span
                          className={
                            r.status?.substring(0, 9) === "Delivered"
                              ? "status delivered view-order-status-delivered"
                              : r.status === "printing"
                              ? "status cancelled view-order-status-booking"
                              : r.status === "pending"
                              ? "status pending view-order-status-printing"
                              : r.status === "D.Return"
                              ? "status paid view-order-status-delivery-return"
                              : r.status === "O.Return"
                              ? "status paid view-order-status-order-return"
                              : undefined
                          }
                        >
                          {r.status === "pending" ? "Booking" : r.status}
                        </span> */}
                      </td>

                      {
                        userRole === "user" && <>
                          {woEdit && (
                            <td>
                              <Tooltip title="Edit Order">
                                <IconButton
                                  className="work-order-action-btn"
                                  onClick={clickEditOrderIconBtn(r)}
                                  disabled={
                                    r?.totalItems == 0
                                      ? false
                                      : r?.delivered === r?.totalItems
                                        ? true
                                        : r?.delivered > 0
                                          ? true
                                          : r?.completed === r?.totalItems
                                            ? true
                                            : r?.completed > 0
                                              ? false
                                              : r?.printing > 0
                                                ? false
                                                : r?.status === "pending"
                                                  ? false
                                                  : r?.status === "returned"
                                                    ? true
                                                    : true
                                  }
                                >
                                  <i class="bi bi-pencil-square view-order-edit-icon"></i>
                                </IconButton>
                              </Tooltip>
                            </td>
                          )}
                          <td>
                            <button
                              className="view-order-plus-button"
                              onClick={clickNewOrderIconBtn(r?._id)}
                              disabled={r?.ordertype !== "WO"}
                              style={{ cursor: r?.ordertype !== "WO" && "not-allowed" }}
                            >
                              +
                            </button>
                          </td>
                          <td>
                            <Tooltip title="Cutting Slip">
                              <IconButton
                                disabled={
                                  r?.pending > 0 ? false : true
                                }
                                onClick={clickCuttingSlipInitialIcon(r?._id)}
                              >
                                <img
                                  src={
                                    r?.pending > 0
                                      ? CuttingSlipImg
                                      : CuttingSlipImgDisabled
                                  }
                                  className="view-order-cutting-slip-img"
                                />
                              </IconButton>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title="Job Completion">
                              <IconButton
                                disabled={
                                  r?.printing > 0 ? false : true
                                }
                                onClick={clickJobCompletionIcon(r)}
                              >
                                <img
                                  src={

                                    r?.printing > 0
                                      ? jobCompletionImg
                                      : jobCompletionImgDisabled
                                  }
                                  className="view-order-cutting-slip-img"
                                />
                              </IconButton>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title="Delivery">
                              <IconButton
                                disabled={
                                  r?.ordertype === "WO" &&
                                  (r?.completed > 0 ? false : true)

                                }
                                onClick={clickDeliveryIcon(r)}
                              >
                                <img
                                  src={

                                    (r?.ordertype === "WO" && r?.completed > 0)
                                      ? deliveryImg
                                      : deliveryImgDisabled
                                  }
                                  className="view-order-cutting-slip-img"
                                />
                              </IconButton>
                            </Tooltip>
                          </td>
                        </>
                      }

                      <td style={{ cursor: r?.ordertype !== "WO" && "not-allowed" }}>
                        <Tooltip title="View">
                          <IconButton onClick={clickSingleViewIcon(r?._id)}
                            disabled={r?.ordertype !== "WO"}
                          >
                            <i class="bi bi-eye visibility-icon view-order-visibility-icon"></i>
                          </IconButton>
                        </Tooltip>
                      </td>
                      {/* <td>
                          <Tooltip title="Print">
                            <IconButton
                              disabled={
                                r?.delivered > 0
                                  ? true
                                  : r?.completed === r?.totalItems
                                  ? true
                                  : r?.completed > 0
                                  ? false
                                  : r?.printing > 0
                                  ? false
                                  : r?.status === "pending"
                                  ? true
                                  : undefined
                              }
                              className="print-cutting-slip-copy"
                            >
                              <i class="bi bi-printer printer-icon view-order-visibility-icon"></i>
                            </IconButton>
                          </Tooltip>
                        </td> */}
                      <td className="tot-amt-col">
                        {r?.totalAmount?.toFixed(2)}
                      </td>
                      {/* <td>
                        <Tooltip title="Measurement Updation">
                          <IconButton
                            onClick={() => setMeasurementUpdation(true)}
                          >
                            <i class="bi bi-pencil-square edit-icon view-order-edit-icon"></i>
                          </IconButton>
                        </Tooltip>
                      </td> */}
                      <td>{r.paidAmount?.toFixed(2)}</td>
                      <td>{r?.returnAmount?.toFixed(2)}</td>
                      <td>{r.discount?.toFixed(2) || "0.00"}</td>
                      {
                        userRole === "user" ?
                          <td>
                            {r.balance?.toFixed(2) > 0 && r?.ordertype === "WO" ? (
                              <Tooltip
                                title="Pay balance"
                                placement="top-start"
                                arrow
                              >
                                <button
                                  className="view-order-balance-pay-btn"
                                  onClick={openPaymentScreen(r)}
                                >
                                  {r.balance?.toFixed(2)}
                                </button>
                              </Tooltip>
                            ) : (
                              r.balance?.toFixed(2)
                            )}
                          </td>
                          :
                          <td>
                            {r?.balance?.toFixed(2)}
                          </td>
                      }
                    </tr>
                  ))) || (
                    <tr>
                      <td colSpan={19}>No data</td>
                    </tr>
                  )}

              </tbody>
            )}
          </table>


          <div style={{
            width: "100%",
            textAlign: "right"
          }}>
            {/* <Tooltip title="Go to prev page"> */}
              <IconButton onClick={prevPage}>
                <ExpandLessIcon />
              </IconButton>
            {/* </Tooltip> */}
          </div>
        </div>


        {openPayment && (
          <PaymentsWO
            closePayments={closePaymentScreen}
            parentWidth="95%"
            right="17%"
            subTotal={currentPayingObj?.balance} //imp
            bodyWOPayment={{}}
            finalAPI={updateOrderPaymentAPICall} //API call
            setOpenSuccessSnack={setOpenSuccessSnack}
            setOpenErrorSnack={setOpenErrorSnack}
            setSnackMsg={setSnackMsg}
            clearWOState={clearViewOrderUser} //Clear fn
            updateListenerProp={updateListener}
            cusId={currentPayingObj?.cusId} // imp
            // placeOrderClick={undefined}
            isMultiplePayment={setIsMultiplePayment}
            // paidAmount={undefined}
            // shipmentCharge={undefined}
            branchId={storeCode}
            paymentSection="viewOrders"
            woId={currentPayingObj?._id}
          />
        )}
      </div>

      {/* Payment Screen  */}

      {/* view order user single view */}
      <Dialog
        open={viewOrderView}
        onClose={() => setViewOrderView(false)}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setViewOrderView(false)}
      >
        <div className="view-order-single-view-container">
          <h3>View</h3>
          <hr className="global-hr" />
          <div className="view-order-input-container">
            <div className="global-single-input">
              <p>Customer Name</p>
              <input disabled value={cuttingSlipNewModel?.cusName} />
            </div>
            <div className="global-single-input">
              <p>Mobile</p>
              <input disabled value={cuttingSlipNewModel?.cusMobile} />
            </div>
            <div className="global-single-input">
              <p>Order Date</p>
              <input
                type="date"
                disabled
                value={cuttingSlipNewModel?.ordDate}
              />
            </div>
            <div className="global-single-input">
              <p>Delivery Date</p>
              <input
                type="date"
                disabled
                value={cuttingSlipNewModel?.delDate}
              />
            </div>
            <div className="global-single-input">
              <p>Rack</p>
              <input disabled value={cuttingSlipNewModel?.rack || "-NIL-"} />
            </div>
            <div className="global-single-input">
              <p>Last Paid Amount</p>
              <input disabled value="---" />
            </div>
          </div>
          <div className="view-order-status-container">
            <p>Types</p>
            {/* Single type map*/}
            {cuttingSlipNewModel?.items?.map((r, i) => {
              return (
                <div className="view-order-status-content" key={i}>
                  <div className="view-order-status-top-content">
                    <h4>
                      {r?.type?.toUpperCase()} × {r?.materials?.length} -
                      {r?.relationName !== "null"
                        ? r?.relationName
                        : cuttingSlipNewModel?.cusName}
                    </h4>
                    <div className="print-measurement-container">
                      <Tooltip
                        title="Measurement Updation"
                        arrow
                        placement="left"
                      >
                        <IconButton
                          // onClick={() => setMeasurementUpdation(true)}
                          onClick={() =>
                            clickMeasurementUpdateBtn(
                              r,
                              cuttingSlipNewModel?.woId
                            )
                          }
                        >
                          {r?.isEdit ? (
                            <i class="bi bi-check2-square edit-icon"></i>
                          ) : (
                            <i class="bi bi-pencil-square edit-icon view-order-edit-icon"></i>
                          )}
                        </IconButton>
                      </Tooltip>

                      <ReactToPrint
                        trigger={() => (
                          <Tooltip title="Print cutting slip copy" arrow>
                            <IconButton
                              // disabled={
                              //   r?.delivered > 0
                              //     ? true
                              //     : r?.completed === r?.totalItems
                              //     ? true
                              //     : r?.completed > 0
                              //     ? false
                              //     : r?.printing > 0
                              //     ? false
                              //     : r?.status === "pending"
                              //     ? true
                              //     : undefined
                              // }
                              className="print-cutting-slip-copy"
                            >
                              <i class="bi bi-printer printer-icon view-order-visibility-icon"></i>
                            </IconButton>
                          </Tooltip>
                        )}
                        onBeforeGetContent={() => {
                          setPrintingTypeObject(r);
                          return Promise.resolve();
                        }}
                        content={() =>
                          r.type === "thob"
                            ? thobCuttingSlip
                            : r.type === "jalabia"
                              ? jalabiaCuttingSlip
                              : r.type === "salwar"
                                ? salwarCuttingSlip
                                : r.type === "abaya"
                                  ? abayaCuttingSlip
                                  : r.type === "churidhar"
                                    ? churidharCuttingSlip
                                    : r.type === "shirt"
                                      ? shirtCuttingSlip
                                      : r.type === "pant"
                                        ? pantCuttingSlip
                                        : r.type === "coat"
                                          ? coatCuttingSlip
                                          : r.type === "sharvani"
                                            ? sharwaniCuttingSlip
                                            : r.type === "safari"
                                              ? safariCuttingSlip
                                              : undefined
                        }
                      />
                    </div>
                  </div>

                  {r?.type === "thob" ? (
                    <div className="view-order-status-content-input-container">
                      <div className="global-single-input view-order-single-view-status">
                        <p>Thob Model</p>
                        <Select
                          disabled={!r?.isEdit}
                          // value={"63c668d64857d7d566ca3b42"}
                          value={
                            r?.materials[r?.selectedProductIndex]?.thobModel
                          }
                          onChange={getMeasurementUpdateValues(
                            r,
                            "thobModel",
                            r?.selectedProductIndex
                          )}
                        >
                          {thobModelData !== undefined && thobModelData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.thobName}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input
                        disabled={!r?.isEdit}
                        value={r?.materials[0]?.thobModelName || "-NIL-"}
                      /> */}
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Length</p>

                        <input
                          disabled={!r?.isEdit}
                          value={r?.length}
                          onChange={getMeasurementUpdateValues(r, "length")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>{cuttingSlipTemplate?.template == "template2" ? "Sleeve" : "Shoulder"}</p>

                        <input
                          disabled={!r?.isEdit}
                          value={r?.shoulder}
                          onChange={getMeasurementUpdateValues(r, "shoulder")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>{cuttingSlipTemplate?.template == "template2" ? "Sada" : "Sleeve"}</p>

                        <input
                          disabled={!r?.isEdit}
                          value={r?.sleeve}
                          onChange={getMeasurementUpdateValues(r, "sleeve")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>{cuttingSlipTemplate?.template == "template2" ? "Regal" : "Chest"}</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.chest}
                          onChange={getMeasurementUpdateValues(r, "chest")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>{cuttingSlipTemplate?.template == "template2" ? "Body" : "Stomach"}</p>

                        <input
                          disabled={!r?.isEdit}
                          value={r?.stomach}
                          onChange={getMeasurementUpdateValues(r, "stomach")}
                        />
                      </div>
                      {
                        cuttingSlipTemplate?.template !== "template2" &&
                        //   <div className="global-single-input view-order-single-view-status">
                        //   <p>Mar Model</p>
                        //   <Select
                        //     disabled={!r?.isEdit}
                        //     value={r?.materials[r?.selectedProductIndex]?.cuff}
                        //     onChange={getMeasurementUpdateValues(
                        //       r,
                        //       "cuff",
                        //       r?.selectedProductIndex
                        //     )}
                        //   >
                        //     {cuffData
                        //       ?.slice(0)
                        //       ?.reverse()
                        //       ?.map((item, i) => (
                        //         <MenuItem key={i} value={item?._id}>
                        //           {item?.cuff}
                        //         </MenuItem>
                        //       )) || <MenuItem>No data</MenuItem>}
                        //   </Select>
                        //   {/* <input
                        //   disabled={!r?.isEdit}
                        //   value={r?.materials[0]?.cuffName || "-NIL-"}
                        // /> */}
                        // </div>
                        //   :
                        <div className="global-single-input view-order-single-view-status">
                          <p>S.L Chest</p>

                          <input
                            disabled={!r?.isEdit}
                            value={r?.slChest}
                            onChange={getMeasurementUpdateValues(r, "slChest")}
                          />
                        </div>
                      }

                      <div className="global-single-input view-order-single-view-status">
                        <p>{cuttingSlipTemplate?.template == "template2" ? "Notes" : "S.L So"}</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.slSo}
                          onChange={getMeasurementUpdateValues(r, "slSo")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Neck</p>

                        <input
                          disabled={!r?.isEdit}
                          value={r?.neck}
                          onChange={getMeasurementUpdateValues(r, "neck")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>{cuttingSlipTemplate?.template == "template2" ? "PC" : "Bottom"}</p>

                        <input
                          disabled={!r?.isEdit}
                          value={r?.bottom}
                          onChange={getMeasurementUpdateValues(r, "bottom")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>{cuttingSlipTemplate?.template == "template2" ? "Bottom" : "Mar Size"}</p>

                        <input
                          disabled={!r?.isEdit}
                          value={r?.marSize}
                          onChange={getMeasurementUpdateValues(r, "marSize")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Mar Model</p>
                        <Select
                          disabled={!r?.isEdit}
                          value={
                            r?.materials[r?.selectedProductIndex]?.marModel
                          }
                          onChange={getMeasurementUpdateValues(
                            r,
                            "marModel",
                            r?.selectedProductIndex
                          )}
                        >
                          {marModelData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.marName}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input
                        disabled={!r?.isEdit}
                        value={r?.materials[0]?.marModelName || "-NIL-"}
                      /> */}
                      </div>
                      {
                        cuttingSlipTemplate?.template !== "template2" &&
                        <>

                          <div className="global-single-input view-order-single-view-status">
                            <p>Cuff</p>
                            <Select
                              disabled={!r?.isEdit}
                              value={r?.materials[r?.selectedProductIndex]?.cuff}
                              onChange={getMeasurementUpdateValues(
                                r,
                                "cuff",
                                r?.selectedProductIndex
                              )}
                            >
                              {cuffData
                                ?.slice(0)
                                ?.reverse()
                                ?.map((item, i) => (
                                  <MenuItem key={i} value={item?._id}>
                                    {item?.cuff}
                                  </MenuItem>
                                )) || <MenuItem>No data</MenuItem>}
                            </Select>
                            {/* <input
                        disabled={!r?.isEdit}
                        value={r?.materials[0]?.cuffName || "-NIL-"}
                      /> */}
                          </div>
                          <div className="global-single-input view-order-single-view-status">
                            <p>Cuff Size 1</p>

                            <input
                              disabled={!r?.isEdit}
                              value={
                                r?.materials[r?.selectedProductIndex]?.cuffSize1
                              }
                              onChange={getMeasurementUpdateValues(
                                r,
                                "cuffSize1",
                                r?.selectedProductIndex
                              )}
                            />
                          </div>
                          <div className="global-single-input view-order-single-view-status">
                            <p>Cuff Size 2</p>

                            <input
                              disabled={!r?.isEdit}
                              value={
                                r?.materials[r?.selectedProductIndex]?.cuffSize2
                              }
                              onChange={getMeasurementUpdateValues(
                                r,
                                "cuffSize2",
                                r?.selectedProductIndex
                              )}
                            />
                          </div>
                        </>
                      }

                      <div className="global-single-input view-order-single-view-status">
                        <p>{cuttingSlipTemplate?.template == "template2" ? "Stitching Model" : "Cuff Cloth"}</p>
                        <Select
                          disabled={!r?.isEdit}
                          value={
                            r?.materials[r?.selectedProductIndex]?.cuffCloth
                          }
                          onChange={getMeasurementUpdateValues(
                            r,
                            "cuffCloth",
                            r?.selectedProductIndex
                          )}
                        >
                          {cuffClothData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.cuffCloth}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input
                        disabled={!r?.isEdit}
                        value={r?.materials[0]?.cuffClothName || "-NIL-"}
                      /> */}
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Remark</p>

                        <input
                          disabled={!r?.isEdit}
                          value={r?.materials[r?.selectedProductIndex]?.remark}
                          onChange={getMeasurementUpdateValues(
                            r,
                            "remark",
                            r?.selectedProductIndex
                          )}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>{cuttingSlipTemplate?.template == "template2" ? "Button" : "Cuff Model"}</p>
                        <Select
                          disabled={!r?.isEdit}
                          value={
                            r?.materials[r?.selectedProductIndex]?.cuffModel
                          }
                          onChange={getMeasurementUpdateValues(
                            r,
                            "cuffModel",
                            r?.selectedProductIndex
                          )}
                        >
                          {cuffModelData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.cuffModel}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input
                        disabled={!r?.isEdit}
                        value={r?.materials[0]?.cuffModelName || "-NIL-"}
                      /> */}
                      </div>
                      {
                        cuttingSlipTemplate?.template == "template2" &&
                         <div className="global-single-input view-order-single-view-status">
                        <p>Type</p>
                        <Select
                        disabled={!r?.isEdit}
                        value={
                          r?.materials[r?.selectedProductIndex]?.collar
                        }
                        onChange={getMeasurementUpdateValues(
                          r,
                          "collar",
                          r?.selectedProductIndex
                        )}
                      >
                          {collarData
                          ?.slice(0)
                          ?.reverse()
                          ?.map((item, i) => (
                           <MenuItem key={i} value={item?._id}>
                                {item?.collar}
                           </MenuItem>
                             )) || <MenuItem>No data</MenuItem>}
                       </Select>
                      </div>
                      }
                      {
                         cuttingSlipTemplate?.template == "template2" &&
                         <div className="global-single-input view-order-single-view-status">
                         <p>FB</p>

                         <input
                           disabled={!r?.isEdit}
                           value={
                            r?.materials[r?.selectedProductIndex]?.sidePTSize
                          }
                          onChange={getMeasurementUpdateValues(
                            r,
                            "sidePTSize",
                            r?.selectedProductIndex
                          )}
                         />
                       </div>
                      }

                      {
                        cuttingSlipTemplate?.template == "template2" ?
                          <div className="global-single-input view-order-single-view-status">
                            <p>Shoulder</p>

                            <input
                              disabled={!r?.isEdit}
                              value={r?.neckDButton}
                              onChange={getMeasurementUpdateValues(r, "neckDButton")}
                            />
                          </div>
                          :
                          <div className="global-single-input view-order-single-view-status">
                            <p>Collar</p>
                            <Select
                              disabled={!r?.isEdit}
                              value={r?.materials[r?.selectedProductIndex]?.collar}
                              onChange={getMeasurementUpdateValues(
                                r,
                                "collar",
                                r?.selectedProductIndex
                              )}
                            >
                              {collarData
                                ?.slice(0)
                                ?.reverse()
                                ?.map((item, i) => (
                                  <MenuItem key={i} value={item?._id}>
                                    {item?.collar}
                                  </MenuItem>
                                )) || <MenuItem>No data</MenuItem>}
                            </Select>
                            {/* <input
                        disabled={!r?.isEdit}
                        value={r?.collarName || "-NIL-"}
                      /> */}
                          </div>
                      }

                      <div className="global-single-input view-order-single-view-status">
                        <p>{cuttingSlipTemplate?.template == "template2" ? "L Shape" : "Collar Size"}</p>

                        <input
                          disabled={!r?.isEdit}
                          value={r?.materials[r?.selectedProductIndex]?.collarSize}
                          onChange={getMeasurementUpdateValues(r, 'collarSize', r?.selectedProductIndex)}
                        />
                      </div>
                      {
                        cuttingSlipTemplate?.template == "template2" ?
                          <div className="global-single-input view-order-single-view-status">
                            <p>Sleeve</p>

                            <input
                              disabled={!r?.isEdit}
                              value={r?.materials[r?.selectedProductIndex]?.collarCloth}
                              onChange={getMeasurementUpdateValues(r, 'collarCloth', r?.selectedProductIndex)}
                            />
                          </div>
                          :
                          <div className="global-single-input view-order-single-view-status">
                            <p>Collar Cloth</p>
                            <Select
                              disabled={!r?.isEdit}
                              value={
                                r?.materials[r?.selectedProductIndex]?.collarCloth
                              }
                              onChange={getMeasurementUpdateValues(
                                r,
                                "collarCloth",
                                r?.selectedProductIndex
                              )}
                            >
                              {collarClothData
                                ?.slice(0)
                                ?.reverse()
                                ?.map((item, i) => (
                                  <MenuItem key={i} value={item?._id}>
                                    {item?.collarCloth}
                                  </MenuItem>
                                )) || <MenuItem>No data</MenuItem>}
                            </Select>
                            {/* <input
                        disabled={!r?.isEdit}
                        value={r?.collarClothName || "-NIL-"}
                      /> */}
                          </div>
                      }
                      {
                        cuttingSlipTemplate?.template == "template2" ?
                          <div className="global-single-input view-order-single-view-status">
                            <p>Sleeve Model</p>

                            <input
                              disabled={!r?.isEdit}
                              value={r?.materials[r?.selectedProductIndex]?.collarModel}
                              onChange={getMeasurementUpdateValues(r, 'collarModel', r?.selectedProductIndex)}
                            />
                          </div>
                          :
                          <div className="global-single-input view-order-single-view-status">
                            <p>Collar Model</p>
                            <Select
                              disabled={!r?.isEdit}
                              value={
                                r?.materials[r?.selectedProductIndex]?.collarModel
                              }
                              onChange={getMeasurementUpdateValues(
                                r,
                                "collarModel",
                                r?.selectedProductIndex
                              )}
                            >
                              {collarModelData
                                ?.slice(0)
                                ?.reverse()
                                ?.map((item, i) => (
                                  <MenuItem key={i} value={item?._id}>
                                    {item?.collarModel}
                                  </MenuItem>
                                )) || <MenuItem>No data</MenuItem>}
                            </Select>
                            {/* <input
                        disabled={!r?.isEdit}
                        value={r?.collarModelName || "-NIL-"}
                      /> */}
                          </div>

                      }


                      <div className="global-single-input view-order-single-view-status">
                        <p>{cuttingSlipTemplate?.template == "template2" ? "Side" : "Regal Size"}</p>

                        <input
                          disabled={!r?.isEdit}
                          value={r?.regalSize}
                          onChange={getMeasurementUpdateValues(r, "regalSize")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>{cuttingSlipTemplate?.template == "template2" ? "Centre" : "Knee Loose"}</p>

                        <input
                          disabled={!r?.isEdit}
                          value={r?.kneeLoose}
                          onChange={getMeasurementUpdateValues(r, "kneeLoose")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>{cuttingSlipTemplate?.template == "template2" ? "Mar" : "FP Down"}</p>

                        <input
                          disabled={!r?.isEdit}
                          value={r?.fpDown}
                          onChange={getMeasurementUpdateValues(r, "fpDown")}
                        />
                      </div>



                      {
                        cuttingSlipTemplate?.template == "template2" ?
                          <div className="global-single-input view-order-single-view-status">
                            <p>PT Down</p>

                            <input
                              disabled={!r?.isEdit}
                              value={r?.materials[r?.selectedProductIndex]?.cuffSize1}
                              onChange={getMeasurementUpdateValues(r, 'cuffSize1', r?.selectedProductIndex)}
                            />
                          </div>
                          :
                          <div className="global-single-input view-order-single-view-status">

                            <p>FP Model</p>
                            <Select
                              disabled={!r?.isEdit}
                              value={r?.materials[r?.selectedProductIndex]?.fpModel}
                              onChange={getMeasurementUpdateValues(
                                r,
                                "fpModel",
                                r?.selectedProductIndex
                              )}
                            >
                              {fpModelData
                                ?.slice(0)
                                ?.reverse()
                                ?.map((item, i) => (
                                  <MenuItem key={i} value={item?._id}>
                                    {item?.fpModel}
                                  </MenuItem>
                                )) || <MenuItem>No data</MenuItem>}
                            </Select>
                            {/* <input
                        disabled={!r?.isEdit}
                        value={r?.fpName || "-NIL-"}
                      /> */}
                          </div>



                      }


                      <div className="global-single-input view-order-single-view-status">
                        <p>{cuttingSlipTemplate?.template == "template2" ? "PT Size" : "FP Size"}</p>


                        <input
                          disabled={!r?.isEdit}
                          value={r?.fpSize1}
                          onChange={getMeasurementUpdateValues(r, "fpSize1")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Pen</p>
                        <Select
                          disabled={!r?.isEdit}
                          value={r?.materials[r?.selectedProductIndex]?.pen}
                          onChange={getMeasurementUpdateValues(
                            r,
                            "penData",
                            r?.selectedProductIndex
                          )}
                        >
                          {penData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.pen}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input
                        disabled={!r?.isEdit}
                        value={r?.penName || "-NIL-"}
                      /> */}
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Side PT Model</p>

                        {/* <input
                          disabled={!r?.isEdit}
                          value={r?.sidePTName}
                          onChange={getMeasurementUpdateValues(r, "sidePtName")}
                        /> */}
                        <Select
                          disabled={!r?.isEdit}
                          value={
                            r?.materials[r?.selectedProductIndex]?.sidePtModel
                          }
                          onChange={getMeasurementUpdateValues(
                            r,
                            "sidePtName",
                            r?.selectedProductIndex
                          )}
                        >
                          {sidePtModelData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.sidePT}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                      </div>
                      {
                        cuttingSlipTemplate?.template == "template2" &&
                        <div className="global-single-input view-order-single-view-status">
                          <p>Down</p>
                          <input 
                            type="text" 
                            disabled={!r?.isEdit}
                            value={r?.down}
                            onChange={getMeasurementUpdateValues(r, "down")}
                            />
                        </div>
                      }
                       {
                        cuttingSlipTemplate?.template == "template2" &&
                        <div className="global-single-input view-order-single-view-status">
                          <p>Veedi</p>
                          <input  
                            type="text"
                            disabled={!r?.isEdit}
                            value={r?.veedi}
                            onChange={getMeasurementUpdateValues(r, "veedi")}
                            />
                        </div>
                      }
                       {
                        cuttingSlipTemplate?.template == "template2" &&
                        <div className="global-single-input view-order-single-view-status">
                          <p>Bag</p>
                          <input 
                            type="text"
                            disabled={!r?.isEdit}
                            value={r?.bag}
                            onChange={getMeasurementUpdateValues(r, "bag")}
                            />
                        </div>
                      }
                       {
                        cuttingSlipTemplate?.template == "template2" &&
                        <div className="global-single-input view-order-single-view-status">
                          <p>Open</p>
                          <input 
                            type="text"
                            disabled={!r?.isEdit}
                            value={r?.open}
                            onChange={getMeasurementUpdateValues(r, "open")}
                            />
                        </div>
                      }
                    </div>
                  ) : r?.type === "jalabia" ? (
                    <div className="view-order-status-content-input-container">
                      <div className="global-single-input view-order-single-view-status">
                        <p>Jalabia Model</p>
                        <Select
                          disabled={!r?.isEdit}
                          value={
                            r?.materials[r?.selectedProductIndex]?.jalabiaModel
                          }
                          onChange={getMeasurementUpdateValues(
                            r,
                            "jalabiaModel",
                            r?.selectedProductIndex
                          )}
                        >
                          {jalabiaModelData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.jalabiaName}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input
                        disabled={!r?.isEdit}
                        value={r?.jalabiaModelName || "-NIL-"}
                      /> */}
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Length</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.length}
                          onChange={getMeasurementUpdateValues(r, "length")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>
                          {cuttingSlipTemplate?.template == "template2"
                            ? "Sleeve"
                            : "Shoulder"}
                        </p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.shoulder}
                          onChange={getMeasurementUpdateValues(r, "shoulder")}
                        />
                      </div>
                      {cuttingSlipTemplate?.template == "template2" ? (
                        <div className="global-single-input view-order-single-view-status">
                          <p>Loose</p>
                          <Select
                            disabled={!r?.isEdit}
                            value={
                              r?.materials[r?.selectedProductIndex]?.neckModel
                            }
                            onChange={getMeasurementUpdateValues(
                              r,
                              "neckModel",
                              r?.selectedProductIndex
                            )}
                          >
                            {neckModelData
                              ?.slice(0)
                              ?.reverse()
                              ?.map((item, i) => (
                                <MenuItem key={i} value={item?._id}>
                                  {item?.neckName}
                                </MenuItem>
                              )) || <MenuItem>No data</MenuItem>}
                          </Select>
                          {/* <input
                        disabled={!r?.isEdit}
                        value={r?.neckName || "-NIL-"}
                      /> */}
                        </div>
                      ) : (
                        <div className="global-single-input view-order-single-view-status">
                          <p>Sleeve</p>
                          <input
                            disabled={!r?.isEdit}
                            value={r?.sleeve}
                            onChange={getMeasurementUpdateValues(r, "sleeve")}
                          />
                        </div>
                      )}

                      <div className="global-single-input view-order-single-view-status">
                        <p>
                          {cuttingSlipTemplate?.template == "template2"
                            ? "Regal"
                            : "Chest"}
                        </p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.chest}
                          onChange={getMeasurementUpdateValues(r, "chest")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>
                          {cuttingSlipTemplate?.template == "template2"
                            ? "Body"
                            : "Stomach"}
                        </p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.stomach}
                          onChange={getMeasurementUpdateValues(r, "stomach")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>
                          {cuttingSlipTemplate?.template == "template2"
                            ? "Mar"
                            : "S.L Chest"}
                        </p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.slChest}
                          onChange={getMeasurementUpdateValues(r, "slChest")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>
                          {cuttingSlipTemplate?.template == "template2"
                            ? "PT"
                            : "S.L Sto"}
                        </p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.slSto}
                          onChange={getMeasurementUpdateValues(r, "slSto")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Neck</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.neck}
                          onChange={getMeasurementUpdateValues(r, "neck")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>
                          {cuttingSlipTemplate?.template == "template2"
                            ? "PT"
                            : "Bottom"}
                        </p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.bottom}
                          onChange={getMeasurementUpdateValues(r, "bottom")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>
                          {cuttingSlipTemplate?.template == "template2"
                            ? "Bottom"
                            : "Sleeve Size"}
                        </p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.sleeveSize}
                          onChange={getMeasurementUpdateValues(r, "sleeveSize")}
                        />
                      </div>
                      {cuttingSlipTemplate?.template == "template2" ? (
                        <div className="global-single-input view-order-single-view-status">
                          <p>Notes</p>
                          <input
                            disabled={!r?.isEdit}
                            value={r?.sleeve}
                            onChange={getMeasurementUpdateValues(r, "sleeve")}
                          />
                        </div>
                      ) : (
                        <div className="global-single-input view-order-single-view-status">
                          <p>Neck Model</p>
                          <Select
                            disabled={!r?.isEdit}
                            value={
                              r?.materials[r?.selectedProductIndex]?.neckModel
                            }
                            onChange={getMeasurementUpdateValues(
                              r,
                              "neckModel",
                              r?.selectedProductIndex
                            )}
                          >
                            {neckModelData
                              ?.slice(0)
                              ?.reverse()
                              ?.map((item, i) => (
                                <MenuItem key={i} value={item?._id}>
                                  {item?.neckName}
                                </MenuItem>
                              )) || <MenuItem>No data</MenuItem>}
                          </Select>
                          {/* <input
                        disabled={!r?.isEdit}
                        value={r?.neckName || "-NIL-"}
                      /> */}
                        </div>
                      )}
                      {cuttingSlipTemplate?.template == "template2" ? (
                        <div className="global-single-input view-order-single-view-status">
                          <p>Stitching</p>
                          <input
                            disabled={!r?.isEdit}
                            value={
                              r?.materials[r?.selectedProductIndex]?.remark
                            }
                            onChange={getMeasurementUpdateValues(
                              r,
                              "remark",
                              r?.selectedProductIndex
                            )}
                          />
                        </div>
                      ) : (
                        <div className="global-single-input view-order-single-view-status">
                          <p>Stitching</p>
                          <Select
                            disabled={!r?.isEdit}
                            value={
                              r?.materials[r?.selectedProductIndex]?.stitching
                            }
                            onChange={getMeasurementUpdateValues(
                              r,
                              "stitching",
                              r?.selectedProductIndex
                            )}
                          >
                            {stitchingData
                              ?.slice(0)
                              ?.reverse()
                              ?.map((item, i) => (
                                <MenuItem key={i} value={item?._id}>
                                  {item?.stitching}
                                </MenuItem>
                              )) || <MenuItem>No data</MenuItem>}
                          </Select>
                          {/* <input
                       disabled={!r?.isEdit}
                       value={r?.stitchingName || "-NIL-"}
                     /> */}
                        </div>
                      )}
                      {cuttingSlipTemplate?.template == "template2" ? (
                        <div className="global-single-input view-order-single-view-status">
                          <p>Button</p>
                          <Select
                            disabled={!r?.isEdit}
                            value={
                              r?.materials[r?.selectedProductIndex]?.stitching
                            }
                            onChange={getMeasurementUpdateValues(
                              r,
                              "stitching",
                              r?.selectedProductIndex
                            )}
                          >
                            {stitchingData
                              ?.slice(0)
                              ?.reverse()
                              ?.map((item, i) => (
                                <MenuItem key={i} value={item?._id}>
                                  {item?.stitching}
                                </MenuItem>
                              )) || <MenuItem>No data</MenuItem>}
                          </Select>
                          {/* <input
                       disabled={!r?.isEdit}
                       value={r?.stitchingName || "-NIL-"}
                     /> */}
                        </div>
                      ) : (
                        <div className="global-single-input view-order-single-view-status">
                          <p>Remark</p>
                          <input
                            disabled={!r?.isEdit}
                            value={
                              r?.materials[r?.selectedProductIndex]?.remark
                            }
                            onChange={getMeasurementUpdateValues(
                              r,
                              "remark",
                              r?.selectedProductIndex
                            )}
                          />
                        </div>
                      )}

                      <div className="global-single-input view-order-single-view-status">
                        <p>
                          {cuttingSlipTemplate?.template == "template2"
                            ? "Shoulder"
                            : "Regal Size"}
                        </p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.regalSize}
                          onChange={getMeasurementUpdateValues(r, "regalSize")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Neck down button</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.neckDownButton}
                          onChange={getMeasurementUpdateValues(
                            r,
                            "neckDownButton"
                          )}
                        />
                      </div>
                      {cuttingSlipTemplate?.template !== "template2" && (
                        <div className="global-single-input view-order-single-view-status">
                          <p>Mar</p>
                          <input
                            disabled={!r?.isEdit}
                            value={r?.mar}
                            onChange={getMeasurementUpdateValues(r, "mar")}
                          />
                        </div>
                      )}
                      {cuttingSlipTemplate?.template == "template2" ? (
                        <div className="global-single-input view-order-single-view-status">
                          <p>Centre</p>
                          <input
                            disabled={!r?.isEdit}
                            value={r?.fpSize1}
                            onChange={getMeasurementUpdateValues(r, "fpSize1")}
                          />
                        </div>
                      ) : (
                        <div className="global-single-input view-order-single-view-status">
                          <p>model</p>
                          <Select
                            disabled={!r?.isEdit}
                            value={r?.materials[r?.selectedProductIndex]?.model}
                            onChange={getMeasurementUpdateValues(
                              r,
                              "model",
                              r?.selectedProductIndex
                            )}
                          >
                            {modelData
                              ?.slice(0)
                              ?.reverse()
                              ?.map((item, i) => (
                                <MenuItem key={i} value={item?._id}>
                                  {item?.model}
                                </MenuItem>
                              )) || <MenuItem>No data</MenuItem>}
                          </Select>
                          {/* <input
                        disabled={!r?.isEdit}
                        value={r?.ModelName || "-NIL-"}
                      /> */}
                        </div>
                      )}

                      <div className="global-single-input view-order-single-view-status">
                        <p>
                          {cuttingSlipTemplate?.template == "template2"
                            ? "Side"
                            : "FP Model"}
                        </p>
                        <Select
                          disabled={!r?.isEdit}
                          value={r?.materials[r?.selectedProductIndex]?.fpModel}
                          onChange={getMeasurementUpdateValues(
                            r,
                            "fpModel",
                            r?.selectedProductIndex
                          )}
                        >
                          {fpModelData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.fpModel}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input
                        disabled={!r?.isEdit}
                        value={r?.fpName || "-NIL-"}
                      /> */}
                      </div>
                      {cuttingSlipTemplate?.template == "template2" ? (
                        <div className="global-single-input view-order-single-view-status">
                          <p>mar Type</p>
                          <Select
                            disabled={!r?.isEdit}
                            value={r?.materials[r?.selectedProductIndex]?.model}
                            onChange={getMeasurementUpdateValues(
                              r,
                              "model",
                              r?.selectedProductIndex
                            )}
                          >
                            {modelData
                              ?.slice(0)
                              ?.reverse()
                              ?.map((item, i) => (
                                <MenuItem key={i} value={item?._id}>
                                  {item?.model}
                                </MenuItem>
                              )) || <MenuItem>No data</MenuItem>}
                          </Select>
                          {/* <input
                        disabled={!r?.isEdit}
                        value={r?.ModelName || "-NIL-"}
                      /> */}
                        </div>
                      ) : (
                        <>
                          <div className="global-single-input view-order-single-view-status">
                            <p>FP Size 1</p>
                            <input
                              disabled={!r?.isEdit}
                              value={r?.fpSize1}
                              onChange={getMeasurementUpdateValues(
                                r,
                                "fpSize1"
                              )}
                            />
                          </div>
                          <div className="global-single-input view-order-single-view-status">
                            <p>FP Size 2</p>
                            <input
                              disabled={!r?.isEdit}
                              value={r?.fpSize2}
                              onChange={getMeasurementUpdateValues(
                                r,
                                "fpSize2"
                              )}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  ) : r?.type === "salwar" ? (
                    <div className="view-order-status-content-input-container">
                      <div className="global-single-input view-order-single-view-status">
                        <p>Length</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.length}
                          onChange={getMeasurementUpdateValues(r, "length")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Waist</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.waist}
                          onChange={getMeasurementUpdateValues(r, "waist")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Bottom</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.bottom}
                          onChange={getMeasurementUpdateValues(r, "bottom")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Elastic size</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.elasticSize}
                          onChange={getMeasurementUpdateValues(
                            r,
                            "elasticSize"
                          )}
                        />
                      </div>
                      {cuttingSlipTemplate?.template == "template2" ? (
                        <div className="global-single-input view-order-single-view-status">
                          <p>Inside Loose</p>
                          <input
                            disabled={!r?.isEdit}
                            value={
                              r?.materials[r?.selectedProductIndex]?.remarks
                            }
                            onChange={getMeasurementUpdateValues(
                              r,
                              "remarks",
                              r?.selectedProductIndex
                            )}
                          />
                        </div>
                      ) : (
                        <div className="global-single-input view-order-single-view-status">
                          <p>Elastic width</p>
                          <Select
                            disabled={!r?.isEdit}
                            value={
                              r?.materials[r?.selectedProductIndex]
                                ?.elasticWidth
                            }
                            onChange={getMeasurementUpdateValues(
                              r,
                              "elasticWidth",
                              r?.selectedProductIndex
                            )}
                          >
                            {elasticWidthData
                              ?.slice(0)
                              ?.reverse()
                              ?.map((item, i) => (
                                <MenuItem key={i} value={item?._id}>
                                  {item?.elasticWidth}
                                </MenuItem>
                              )) || <MenuItem>No data</MenuItem>}
                          </Select>
                          {/* <input disabled={!r?.isEdit}  value={r?.elasticWidthNumber || "-NIL-"} /> */}
                        </div>
                      )}

                      <div className="global-single-input view-order-single-view-status">
                        <p>
                          {cuttingSlipTemplate?.template == "template2"
                            ? "Thigh Loose"
                            : "Ply"}
                        </p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.ply}
                          onChange={getMeasurementUpdateValues(r, "ply")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>
                          {cuttingSlipTemplate?.template == "template2"
                            ? "Notes"
                            : "Thigh Loose"}
                        </p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.thighLoose}
                          onChange={getMeasurementUpdateValues(r, "thighLoose")}
                        />
                      </div>
                      {cuttingSlipTemplate?.template == "template2" ? (
                        <div className="global-single-input view-order-single-view-status">
                          <p>Type</p>
                          <Select
                            disabled={!r?.isEdit}
                            value={
                              r?.materials[r?.selectedProductIndex]
                                ?.elasticWidth
                            }
                            onChange={getMeasurementUpdateValues(
                              r,
                              "elasticWidth",
                              r?.selectedProductIndex
                            )}
                          >
                            {elasticWidthData
                              ?.slice(0)
                              ?.reverse()
                              ?.map((item, i) => (
                                <MenuItem key={i} value={item?._id}>
                                  {item?.elasticWidth}
                                </MenuItem>
                              )) || <MenuItem>No data</MenuItem>}
                          </Select>
                        </div>
                      ) : (
                        <div className="global-single-input view-order-single-view-status">
                          <p>Remarks</p>
                          <input
                            disabled={!r?.isEdit}
                            value={
                              r?.materials[r?.selectedProductIndex]?.remarks
                            }
                            onChange={getMeasurementUpdateValues(
                              r,
                              "remarks",
                              r?.selectedProductIndex
                            )}
                          />
                        </div>
                      )}
                    </div>
                  ) : r?.type === "abaya" ? (
                    <div className="view-order-status-content-input-container">
                      <div className="global-single-input view-order-single-view-status">
                        <p>Sleeve From Neck</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.sleeveFromNeck}
                          onChange={getMeasurementUpdateValues(
                            r,
                            "sleeveFromNeck"
                          )}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Sleeve From Shoulder</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.sleeveFromShoulder}
                          onChange={getMeasurementUpdateValues(
                            r,
                            "sleeveFromShoulder"
                          )}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Sleeve Width</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.sleeveWidth}
                          onChange={getMeasurementUpdateValues(
                            r,
                            "sleeveWidth"
                          )}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Arms</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.arms}
                          onChange={getMeasurementUpdateValues(r, "arms")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Bust</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.bust}
                          onChange={getMeasurementUpdateValues(r, "bust")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Waist</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.waist}
                          onChange={getMeasurementUpdateValues(r, "waist")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Hip</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.hip}
                          onChange={getMeasurementUpdateValues(r, "hip")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Length</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.length}
                          onChange={getMeasurementUpdateValues(r, "length")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Remarks</p>
                        <input disabled={!r?.isEdit} value={r?.remarks} />
                      </div>
                    </div>
                  ) : r?.type === "churidhar" ? (
                    <div className="view-order-status-content-input-container">
                      <div className="global-single-input view-order-single-view-status">
                        <p>Shoulder</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.shoulder}
                          onChange={getMeasurementUpdateValues(r, "shoulder")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Front Neck</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.frontNeck}
                          onChange={getMeasurementUpdateValues(r, "frontNeck")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Back Neck</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.backNeck}
                          onChange={getMeasurementUpdateValues(r, "backNeck")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Waist</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.waist}
                          onChange={getMeasurementUpdateValues(r, "waist")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Hip</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.hip}
                          onChange={getMeasurementUpdateValues(r, "hip")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Full Length</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.fullLength}
                          onChange={getMeasurementUpdateValues(r, "fullLength")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Arm Round</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.armRound}
                          onChange={getMeasurementUpdateValues(r, "armRound")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Arm Hole</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.armHole}
                          onChange={getMeasurementUpdateValues(r, "armHole")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Sleeve Length</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.sleeveLength}
                          onChange={getMeasurementUpdateValues(
                            r,
                            "sleeveLength"
                          )}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Remarks</p>
                        <input disabled={!r?.isEdit} value={r?.remarks} />
                      </div>
                    </div>
                  ) : r?.type === "shirt" ? (
                    <div className="view-order-status-content-input-container">
                      <div className="global-single-input view-order-single-view-status">
                        <p>Length</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.length}
                          onChange={getMeasurementUpdateValues(r, "length")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Shoulder</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.shoulder}
                          onChange={getMeasurementUpdateValues(r, "shoulder")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Sleeve Full</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.sleeveFull}
                          onChange={getMeasurementUpdateValues(r, "sleeveFull")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Chest</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.chest}
                          onChange={getMeasurementUpdateValues(r, "chest")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Waist</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.waist}
                          onChange={getMeasurementUpdateValues(r, "waist")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Body</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.body}
                          onChange={getMeasurementUpdateValues(r, "body")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Neck</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.neck}
                          onChange={getMeasurementUpdateValues(r, "neck")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Cup</p>
                        <Select
                          disabled={!r?.isEdit}
                          value={r?.materials[r?.selectedProductIndex]?.cup}
                          onChange={getMeasurementUpdateValues(
                            r,
                            "cup",
                            r?.selectedProductIndex
                          )}
                        >
                          {cupData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.cup}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input disabled={!r?.isEdit} value={r?.cupName || "-NIL-"} /> */}
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Regal</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.regal}
                          onChange={getMeasurementUpdateValues(r, "regal")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Knee Loose</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.kneeLose}
                          onChange={getMeasurementUpdateValues(r, "kneeLose")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Disco</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.disco}
                          onChange={getMeasurementUpdateValues(r, "disco")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Canvas</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.canvas}
                          onChange={getMeasurementUpdateValues(r, "canvas")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Remarks</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.materials[r?.selectedProductIndex]?.remarks}
                          onChange={getMeasurementUpdateValues(
                            r,
                            "remarks",
                            r?.selectedProductIndex
                          )}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Stitching</p>
                        <Select
                          disabled={!r?.isEdit}
                          value={
                            r?.materials[r?.selectedProductIndex]?.stitching
                          }
                          onChange={getMeasurementUpdateValues(
                            r,
                            "stitching",
                            r?.selectedProductIndex
                          )}
                        >
                          {stitchingData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.stitching}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input disabled={!r?.isEdit} value={r?.stitchingName || "-NIL-"} /> */}
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Mar Model</p>
                        <Select
                          disabled={!r?.isEdit}
                          value={
                            r?.materials[r?.selectedProductIndex]?.marModel
                          }
                          onChange={getMeasurementUpdateValues(
                            r,
                            "marModel",
                            r?.selectedProductIndex
                          )}
                        >
                          {marModelData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.marName}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input disabled={!r?.isEdit} value={r?.marModelName || "-NIL-"} /> */}
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Bottom Model</p>
                        <Select
                          disabled={!r?.isEdit}
                          value={
                            r?.materials[r?.selectedProductIndex]?.bottomModel
                          }
                          onChange={getMeasurementUpdateValues(
                            r,
                            "bottomModel",
                            r?.selectedProductIndex
                          )}
                        >
                          {bottomModelData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.name}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input disabled={!r?.isEdit} value={r?.bottomModelName || "-NIL-"} /> */}
                      </div>
                      {/* <div className="global-single-input view-order-single-view-status">
                        <p>Colour</p>
                        <Select disabled={!r?.isEdit}>
                          <MenuItem></MenuItem>
                        </Select>
                        <input disabled={!r?.isEdit} value={r?.colourName || "-NIL-"} />
                      </div> */}
                      <div className="global-single-input view-order-single-view-status">
                        <p>Seat 1</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.seat1}
                          onChange={getMeasurementUpdateValues(r, "seat1")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Seat 2</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.seat2}
                          onChange={getMeasurementUpdateValues(r, "seat2")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Seat 3</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.seat3}
                          onChange={getMeasurementUpdateValues(r, "seat3")}
                        />
                      </div>
                    </div>
                  ) : r?.type === "pant" ? (
                    <div className="view-order-status-content-input-container">
                      <div className="global-single-input view-order-single-view-status">
                        <p>Length</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.length}
                          onChange={getMeasurementUpdateValues(r, "length")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Inside</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.inside}
                          onChange={getMeasurementUpdateValues(r, "inside")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Waist</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.waist}
                          onChange={getMeasurementUpdateValues(r, "waist")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Seat</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.seat}
                          onChange={getMeasurementUpdateValues(r, "seat")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Thigh</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.thigh}
                          onChange={getMeasurementUpdateValues(r, "thigh")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Bottom</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.bottom}
                          onChange={getMeasurementUpdateValues(r, "bottom")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Ply</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.ply}
                          onChange={getMeasurementUpdateValues(r, "ply")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Belt</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.belt}
                          onChange={getMeasurementUpdateValues(r, "belt")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Side Pocket</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.sidePocket}
                          onChange={getMeasurementUpdateValues(r, "sidePocket")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Back Pocket</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.backPocket}
                          onChange={getMeasurementUpdateValues(r, "backPocket")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Pleat</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.pleat}
                          onChange={getMeasurementUpdateValues(r, "pleat")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Loops</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.loops}
                          onChange={getMeasurementUpdateValues(r, "loops")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Remarks</p>
                        <input disabled={!r?.isEdit} value={r?.remarks} />
                      </div>
                    </div>
                  ) : r?.type === "coat" ? (
                    <div className="view-order-status-content-input-container">
                      <div className="global-single-input view-order-single-view-status">
                        <p>Length</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.length}
                          onChange={getMeasurementUpdateValues(r, "length")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Shoulder</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.shoulder}
                          onChange={getMeasurementUpdateValues(r, "shoulder")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Sleeve</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.sleeve}
                          onChange={getMeasurementUpdateValues(r, "sleeve")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Body</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.body}
                          onChange={getMeasurementUpdateValues(r, "body")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Waist</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.waist}
                          onChange={getMeasurementUpdateValues(r, "waist")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Seat</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.seat}
                          onChange={getMeasurementUpdateValues(r, "seat")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Front</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.front}
                          onChange={getMeasurementUpdateValues(r, "front")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Back</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.back}
                          onChange={getMeasurementUpdateValues(r, "back")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Sleeve Loose</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.sleeveLoose}
                          onChange={getMeasurementUpdateValues(
                            r,
                            "sleeveLoose"
                          )}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>FP Button</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.fpButton}
                          onChange={getMeasurementUpdateValues(r, "fpButton")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Back Open</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.backOpen}
                          onChange={getMeasurementUpdateValues(r, "backOpen")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Remarks</p>
                        <input disabled={!r?.isEdit} value={r?.remarks} />
                      </div>
                    </div>
                  ) : r?.type === "sharvani" ? (
                    <div className="view-order-status-content-input-container">
                      <div className="global-single-input view-order-single-view-status">
                        <p>Length</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.length}
                          onChange={getMeasurementUpdateValues(r, "length")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Shoulder</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.shoulder}
                          onChange={getMeasurementUpdateValues(r, "shoulder")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Sleeve Full</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.sleeveFull}
                          onChange={getMeasurementUpdateValues(r, "sleeveFull")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Chest</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.chest}
                          onChange={getMeasurementUpdateValues(r, "chest")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>waist</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.waist}
                          onChange={getMeasurementUpdateValues(r, "waist")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Body</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.body}
                          onChange={getMeasurementUpdateValues(r, "body")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Neck</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.neck}
                          onChange={getMeasurementUpdateValues(r, "neck")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Cup</p>
                        <Select
                          disabled={!r?.isEdit}
                          value={r?.materials[r?.selectedProductIndex]?.cup}
                          onChange={getMeasurementUpdateValues(
                            r,
                            "cup",
                            r?.selectedProductIndex
                          )}
                        >
                          {cupData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.cup}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input disabled={!r?.isEdit} value={r?.cupName || "-NIL-"} /> */}
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Regal</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.regal}
                          onChange={getMeasurementUpdateValues(r, "regal")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Knee Loose</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.kneeLoose}
                          onChange={getMeasurementUpdateValues(r, "kneeLoose")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Disco</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.disco}
                          onChange={getMeasurementUpdateValues(r, "disco")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Canvas</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.canvas}
                          onChange={getMeasurementUpdateValues(r, "canvas")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Remarks</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.materials[r?.selectedProductIndex]?.remark}
                          onChange={getMeasurementUpdateValues(
                            r,
                            "remark",
                            r?.selectedProductIndex
                          )}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Stitching</p>
                        <Select
                          disabled={!r?.isEdit}
                          value={
                            r?.materials[r?.selectedProductIndex]?.stitching
                          }
                          onChange={getMeasurementUpdateValues(
                            r,
                            "stitching",
                            r?.selectedProductIndex
                          )}
                        >
                          {stitchingData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.stitching}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input disabled={!r?.isEdit} value={r?.stitchingName || "-NIL-"} /> */}
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Mar Model</p>
                        <Select
                          disabled={!r?.isEdit}
                          value={
                            r?.materials[r?.selectedProductIndex]?.marModel
                          }
                          onChange={getMeasurementUpdateValues(
                            r,
                            "marModel",
                            r?.selectedProductIndex
                          )}
                        >
                          {marModelData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.marName}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input disabled={!r?.isEdit} value={r?.marModelName || "-NIL-"} /> */}
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Bottom Model</p>
                        <Select
                          disabled={!r?.isEdit}
                          value={
                            r?.materials[r?.selectedProductIndex]?.bottomModel
                          }
                          onChange={getMeasurementUpdateValues(
                            r,
                            "bottomModel",
                            r?.selectedProductIndex
                          )}
                        >
                          {bottomModelData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.name}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input disabled={!r?.isEdit} value={r?.bottomModelName || "-NIL-"} /> */}
                      </div>
                      {/* <div className="global-single-input view-order-single-view-status">
                        <p>Colour</p>
                        <Select disabled={!r?.isEdit}>
                          <MenuItem></MenuItem>
                        </Select>
                        <input disabled={!r?.isEdit} value={r?.colourName || "-NIL-"} />
                      </div> */}
                      <div className="global-single-input view-order-single-view-status">
                        <p>Seat 1</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.seat1}
                          onChange={getMeasurementUpdateValues(r, "seat1")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Seat 2</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.seat2}
                          onChange={getMeasurementUpdateValues(r, "seat2")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Seat 3</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.seat3}
                          onChange={getMeasurementUpdateValues(r, "seat3")}
                        />
                      </div>
                    </div>
                  ) : r?.type === "safari" ? (
                    <div className="view-order-status-content-input-container">
                      <div className="global-single-input view-order-single-view-status">
                        <p>Length</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.length}
                          onChange={getMeasurementUpdateValues(r, "length")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Shoulder</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.shoulder}
                          onChange={getMeasurementUpdateValues(r, "shoulder")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Sleeve Full</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.sleeveFull}
                          onChange={getMeasurementUpdateValues(r, "sleeveFull")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Chest</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.chest}
                          onChange={getMeasurementUpdateValues(r, "chest")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>waist</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.waist}
                          onChange={getMeasurementUpdateValues(r, "waist")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Body</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.body}
                          onChange={getMeasurementUpdateValues(r, "body")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Neck</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.neck}
                          onChange={getMeasurementUpdateValues(r, "neck")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Cup</p>
                        <Select
                          disabled={!r?.isEdit}
                          value={r?.materials[r?.selectedProductIndex]?.cup}
                          onChange={getMeasurementUpdateValues(
                            r,
                            "cup",
                            r?.selectedProductIndex
                          )}
                        >
                          {cupData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.cup}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input disabled={!r?.isEdit} value={r?.cupName || "-NIL-"} /> */}
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Regal</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.regal}
                          onChange={getMeasurementUpdateValues(r, "regal")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Knee Loose</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.kneeLoose}
                          onChange={getMeasurementUpdateValues(r, "kneeLoose")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Disco</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.disco}
                          onChange={getMeasurementUpdateValues(r, "disco")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Canvas</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.canvas}
                          onChange={getMeasurementUpdateValues(r, "canvas")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Remarks</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.materials[r?.selectedProductIndex]?.remark}
                          onChange={getMeasurementUpdateValues(
                            r,
                            "remark",
                            r?.selectedProductIndex
                          )}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Stitching</p>
                        <Select
                          disabled={!r?.isEdit}
                          value={
                            r?.materials[r?.selectedProductIndex]?.stitching
                          }
                          onChange={getMeasurementUpdateValues(
                            r,
                            "stitching",
                            r?.selectedProductIndex
                          )}
                        >
                          {stitchingData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.stitching}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input disabled={!r?.isEdit} value={r?.stitchingName || "-NIL-"} /> */}
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Mar Model</p>
                        <Select
                          disabled={!r?.isEdit}
                          value={
                            r?.materials[r?.selectedProductIndex]?.marModel
                          }
                          onChange={getMeasurementUpdateValues(
                            r,
                            "marModel",
                            r?.selectedProductIndex
                          )}
                        >
                          {marModelData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.marName}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input disabled={!r?.isEdit} value={r?.marModelName || "-NIL-"} /> */}
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Bottom Model</p>
                        <Select
                          disabled={!r?.isEdit}
                          value={
                            r?.materials[r?.selectedProductIndex]?.bottomModel
                          }
                          onChange={getMeasurementUpdateValues(
                            r,
                            "bottomModel",
                            r?.selectedProductIndex
                          )}
                        >
                          {bottomModelData
                            ?.slice(0)
                            ?.reverse()
                            ?.map((item, i) => (
                              <MenuItem key={i} value={item?._id}>
                                {item?.name}
                              </MenuItem>
                            )) || <MenuItem>No data</MenuItem>}
                        </Select>
                        {/* <input disabled={!r?.isEdit} value={r?.bottomModelName || "-NIL-"} /> */}
                      </div>
                      {/* <div className="global-single-input view-order-single-view-status">
                        <p>Colour</p>
                        <Select disabled={!r?.isEdit}>
                          <MenuItem></MenuItem>
                        </Select>
                        <input disabled={!r?.isEdit} value={r?.colourName || "-NIL-"} />
                      </div> */}
                      <div className="global-single-input view-order-single-view-status">
                        <p>Seat 1</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.seat1}
                          onChange={getMeasurementUpdateValues(r, "seat1")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Seat 2</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.seat2}
                          onChange={getMeasurementUpdateValues(r, "seat2")}
                        />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Seat 3</p>
                        <input
                          disabled={!r?.isEdit}
                          value={r?.seat3}
                          onChange={getMeasurementUpdateValues(r, "seat3")}
                        />
                      </div>
                    </div>
                  ) : undefined}

                  {/*cutting master tailor and product .. */}
                  <div className="view-order-status-content-input-container">
                    <div className="global-single-input view-order-single-view-status">
                      <p>Cutting Master</p>
                      <input />
                    </div>
                    <div className="global-single-input view-order-single-view-status">
                      <p>Tailor</p>
                      <input />
                    </div>
                    <div className="global-single-input view-order-single-view-status-select">
                      <p>Product</p>
                      <Select
                        value={r.selectedProduct}
                        onChange={getSelectedProductFromDropdown(r)}
                      >
                        {r?.materials?.map((item, i) => (
                          <MenuItem key={i} value={`${item?.mName}:${i}`}>
                            {item?.mName}
                          </MenuItem>
                        )) || <MenuItem>No Products</MenuItem>}
                      </Select>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* .. */}
          </div>
          <div className="view-order-single-view-btn">
            <button
              onClick={() => {
                setViewOrderView(false);
                updateListener();
              }}
              className="btn btn-secondary"
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>

      {/* print cutting slip dialog  */}
      <Dialog
        open={printCuttingSlipDialog}
        maxWidth="lg"
        onClose={() => setPrintCuttingSlipDialog(false)}
        onKeyDown={(e) =>
          e.key === "Escape" && setPrintCuttingSlipDialog(false)
        }
      >
        {/* <div className="view-order-print-cuttingSlip">
          <div className="view-order-print-cuttingSlip-top-container">
            <h3>Print Cutting Slip</h3>
            <hr className="global-hr" />
            <div className="view-order-print-cuttingSlip-input-container">
              <div className="global-single-input print-cutting-slip-input">
                <p>Order No</p>
                <Select></Select>
              </div>
              <div className="global-single-input print-cutting-slip-input">
                <p>Mobile No</p>
                <Select></Select>
              </div>
              <div className="global-single-input print-cutting-slip-input">
                <p>Name</p>
                <Select></Select>
              </div>
              <div className="global-single-input print-cutting-slip-input">
                <p>Relationship</p>
                <Select></Select>
              </div>
              <button className="btn btn-primary print-cuttingSlip-submit-btn">
                Submit
              </button>
            </div>
          </div>
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Order No</th>
                  <th>Customer</th>
                  <th>Mobile</th>
                  <th>Date</th>
                  <th>Delivery Date</th>
                  <th>Net Amount</th>
                  <th>View</th>
                  <th>Print</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>01</td>
                  <td>ORD1</td>
                  <td>Sibin</td>
                  <td>123456789</td>
                  <td>12-02-2022</td>
                  <td>12-02-2022</td>
                  <td>2000</td>
                  <td>
                    {" "}
                    <IconButton onClick={openCuttingSlipSingleView}>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <IconButton>
                      <i class="bi bi-printer printer-icon"></i>
                    </IconButton>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="cutting-slip-cancel-btn">
            <button
              onClick={() => setPrintCuttingSlipDialog(false)}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
          </div>
        </div> */}
        <div className="view-order-print-cuttingSlip">
          <PrintCuttingSlip isDialog={true} />
        </div>
      </Dialog>

      {/* //Print cutting slip shortcut icon  */}
      <Dialog
        open={printCSShortcut}
        maxWidth="lg"
        onClose={closeCuttingSlipShortCutDialog}
        onKeyDown={(e) => e.key === "Escape" && setPrintCSShortcut(false)}
      >
        <div className="view-order-print-cuttingSlip">
          <PrintCuttingSlip
            isDialog={true}
            isShortCut={true}
            closeShortCut={closeCuttingSlipShortCutDialog}
            singleViewDialogOpen={printCuttingSlipShortCut}
          />
        </div>
      </Dialog>

      {/* print cuttingSlip single view */}
      <Dialog
        open={printCuttingSlipSingleView}
        onClose={closeCuttingSlipSingleView}
        maxWidth="lg"
        onKeyDown={(e) =>
          e.key === "Escape" && setPrintCuttingSlipSingleView(false)
        }
      >
        <div className="view-order-print-cuttingSlip">
          <div className="view-order-print-cuttingSlip-top-container">
            <h3>Print Cutting Slip View</h3>
            <hr className="global-hr" />
            <div className="view-order-print-cuttingSlip-input-container">
              <div className="global-single-input print-cutting-slip-input">
                <p>Order No</p>
                <Select></Select>
              </div>
              <div className="global-single-input print-cutting-slip-input">
                <p>Mobile No</p>
                <Select></Select>
              </div>
              <div className="global-single-input print-cutting-slip-input">
                <p>Name</p>
                <Select></Select>
              </div>
              <div className="global-single-input print-cutting-slip-input">
                <p>Relationship</p>
                <Select></Select>
              </div>
            </div>
          </div>

          <div className="cutting-slip-single-view-print-all-container">
            <label htmlFor="">Print All</label>
            <input type="checkbox" />
          </div>

          <div className="view-order-status-container">
            {/* map */}
            {viewOrderSingleViewData.map((r) => (
              <div className="view-order-status-content">
                <div className="view-order-status-top-content">
                  <h4>{r.name}</h4>
                  <input
                    type="checkbox"
                    style={{ width: "16px", height: "16px" }}
                  />
                </div>

                {/* map */}
                {r.details.map((k) => {
                  if (r.name === "jalabia") {
                    return (
                      <div className="view-order-status-content-input-container">
                        <div className="global-single-input view-order-single-view-status">
                          <p>Thob Model</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Length</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Shoulder</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Sleeve</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Chest</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Stomach</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>S.L Chest</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>S.L So</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Neck</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Bottom</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Mar Size</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Mar Model</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Cuff</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Cuff Size</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Cuff Cloth</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Remark</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Cuff Model</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Collar</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Collar Size</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Collar Cloth</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Collar Model</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Regal Size</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Knee Loose</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>FP Down</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>FP Model</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>FP Size</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Pen</p>
                          <input />
                        </div>
                        <div className="global-single-input view-order-single-view-status">
                          <p>Side PT Model</p>
                          <input />
                        </div>
                      </div>
                    );
                  }
                })}
                {/* .. */}
                <div className="view-order-status-content-input-container">
                  <div className="global-single-input view-order-single-view-status">
                    <p>Cutting Master</p>
                    <input />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Tailor</p>
                    <input />
                  </div>
                  {/* <div className='global-single-input view-order-single-view-status'>
              <p>Product</p>
               <Select></Select>
             </div> */}
                </div>
              </div>
            ))}
            {/* .. */}
          </div>
          <div className="cutting-slip-cancel-btn">
            <button
              onClick={closeCuttingSlipSingleView}
              className="btn btn-secondary-outlined "
            >
              Cancel
            </button>
            <button className="btn btn-primary">Print</button>
          </div>
        </div>
      </Dialog>

      {/* measurement updation dialog */}
      <Dialog
        open={measurementUpdation}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setMeasurementUpdation(false)}
      >
        <div className="view-order-single-view-container">
          <h3>Measurement Updation</h3>
          {viewOrderSingleViewData.map((r) => (
            <div className="view-order-status-content">
              <div className="view-order-status-top-content">
                <h4>{r.name}</h4>
              </div>
              {r.details.map((k) => {
                if (r.name === "jalabia") {
                  return (
                    <div className="view-order-status-content-input-container">
                      <div className="global-single-input view-order-single-view-status">
                        <p>Thob Model</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Length</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Shoulder</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Sleeve</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Chest</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Stomach</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>S.L Chest</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>S.L So</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Neck</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Bottom</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Mar Size</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Mar Model</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Cuff</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Cuff Size</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Cuff Cloth</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Remark</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Cuff Model</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Collar</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Collar Size</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Collar Cloth</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Collar Model</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Regal Size</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Knee Loose</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>FP Down</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>FP Model</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>FP Size</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Pen</p>
                        <input />
                      </div>
                      <div className="global-single-input view-order-single-view-status">
                        <p>Side PT Model</p>
                        <input />
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          ))}
          <div className="cutting-slip-cancel-btn">
            <button
              onClick={() => setMeasurementUpdation(false)}
              className="btn btn-secondary-outlined "
            >
              Cancel
            </button>
            <button className="btn btn-primary">Submit</button>
          </div>
        </div>
      </Dialog>

      {/* Single Order types list  */}
      <Menu
        anchorEl={anchorEl}
        id="status-menu"
        open={open}
        // open={openDocAnchor}
        onClose={handleCloseAnchor}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {Object.keys(itemTypeCountObj)?.map((item, j) => (
          <MenuItem>
            {anchorMob ? (
              <Typography sx={{ fontSize: "15px", marginRight: "14px" }}>
                {item?.toUpperCase()}
              </Typography>
            ) : (
              <Typography sx={{ fontSize: "15px", marginRight: "14px" }}>
                {`${item?.toUpperCase()} × ${itemTypeCountObj[item]}`}
              </Typography>
            )}
          </MenuItem>
        ))}
        {/* {(prodTypesOfClickedOrder !== null &&
          prodTypesOfClickedOrder?.map((item, j) => (
            <MenuItem>
              <Typography sx={{ fontSize: "15px", marginRight: "14px" }}>
                {item}
              </Typography>
              
            </MenuItem>
          ))) || <MenuItem>No documents added</MenuItem>} */}
      </Menu>

      {/* Single Order date list  */}
      <Menu
        anchorEl={anchorDate}
        id="status-menu"
        open={openAnchorDate}
        onClose={handleCloseAnchorDate}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {dateOfClickedOrder?.map((item) => (
          <MenuItem>
            {
              <Typography sx={{ fontSize: "15px", marginRight: "14px" }}>
                {item}
              </Typography>
            }
          </MenuItem>
        ))}
        {/* {(prodTypesOfClickedOrder !== null &&
          prodTypesOfClickedOrder?.map((item, j) => (
            <MenuItem>
              <Typography sx={{ fontSize: "15px", marginRight: "14px" }}>
                {item}
              </Typography>
              
            </MenuItem>
          ))) || <MenuItem>No documents added</MenuItem>} */}
      </Menu>

      {/* //Relation Menu list  */}
      <Menu
        anchorEl={anchorE2}
        id="status-menu"
        open={openRelation}
        // open={openDocAnchor}
        onClose={handleCloseAnchorRelation}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <ul className="relation-ul">
          {selectedRowRelation?.map((rel, i) => (
            <li key={i}>
              {rel?.name}
              <Tooltip title="Create New Order">
                {/* <i class="bi bi-plus-square-fill relation-add-btn"></i> */}
                <button
                  className="view-order-plus-button relation-add-btn"
                  onClick={clickRelationNewOrder(
                    rel?._id,
                    selectedRowOrdId,
                    selectedRowCusId
                  )}
                >
                  +
                </button>
              </Tooltip>
            </li>
          ))}
        </ul>
      </Menu>

      <SuccessSnackbar
        open={openSuccessSnack}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnack}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />

  <LoadingForm loading={loadingForm}/>
      {/* thob cutting slip */}
      <div style={{ display: "none" }}>
        {/* <style type="text/css" media="print">
          {
            "\
           @page { size: landscape; }\
          "
          }
        </style> */}
        <div ref={(el) => (thobCuttingSlip = el)}>
          {cuttingSlipTemplate?.template === "template1" ? (
            <ThobCuttingSlip
              qr={generatedQrCode?.qrcode}
              cuttingSlipData={cuttingSlipNewModel}
              printingObject={printingTypeObject}
              copy={true}
            />
          ) : cuttingSlipTemplate?.template === "template2" ? (
            <ThobCuttingSlip1
              qr={generatedQrCode?.qrcode}
              cuttingSlipData={cuttingSlipNewModel}
              printingObject={printingTypeObject}
              copy={true}
            />
          ) : undefined}

          {/* <ThobCuttingSlip
            qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
            printingObject={printingTypeObject}
            copy={true}
          /> */}
        </div>
      </div>

      {/* jalabia cutting slip */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (jalabiaCuttingSlip = el)}>
          {cuttingSlipTemplate?.template === "template1" ? (
            <JalabiaCuttingSlip
              qr={generatedQrCode?.qrcode}
              cuttingSlipData={cuttingSlipNewModel}
              printingObject={printingTypeObject}
              copy={true}
            />
          ) : cuttingSlipTemplate?.template === "template2" ? (
            <JalabiaCuttingSlip1
              qr={generatedQrCode?.qrcode}
              cuttingSlipData={cuttingSlipNewModel}
              printingObject={printingTypeObject}
              copy={true}
            />
          ) : undefined}
        </div>
      </div>

      {/* salwar cutting slip */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (salwarCuttingSlip = el)}>
          {cuttingSlipTemplate?.template === "template1" ? (
            <SalwarCuttingSlip
              qr={generatedQrCode?.qrcode}
              cuttingSlipData={cuttingSlipNewModel}
              printingObject={printingTypeObject}
              copy={true}
            />
          ) : cuttingSlipTemplate?.template === "template2" ? (
            <SalwarCuttingSlip1
              qr={generatedQrCode?.qrcode}
              cuttingSlipData={cuttingSlipNewModel}
              printingObject={printingTypeObject}
              copy={true}
            />
          ) : undefined}
        </div>
      </div>

      {/* abaya cutting slip */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (abayaCuttingSlip = el)}>
          <AbayaCuttingSlip
            qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
            printingObject={printingTypeObject}
            copy={true}
          />
        </div>
      </div>

      {/* churidhar cutting slip */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (churidharCuttingSlip = el)}>
          <ChuridharCuttingSlip
            qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
            printingObject={printingTypeObject}
            copy={true}
          />
        </div>
      </div>

      {/* shirt cutting slip */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (shirtCuttingSlip = el)}>
          <ShirtCuttingSlip
            qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
            printingObject={printingTypeObject}
            copy={true}
          />
        </div>
      </div>

      {/* pant cutting slip */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (pantCuttingSlip = el)}>
          <PantCuttingSlip
            qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
            printingObject={printingTypeObject}
            copy={true}
          />
        </div>
      </div>

      {/*coat cutting slip */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (coatCuttingSlip = el)}>
          <CoatCuttingSlip
            qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
            printingObject={printingTypeObject}
            copy={true}
          />
        </div>
      </div>

      {/*sharvani cutting slip */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (sharwaniCuttingSlip = el)}>
          <SharwaniCuttingSlip
            qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
            printingObject={printingTypeObject}
            copy={true}
          />
        </div>
      </div>

      {/* safari cutting slip */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (safariCuttingSlip = el)}>
          <SafariCuttingSlip
            qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
            printingObject={printingTypeObject}
            copy={true}
          />
        </div>
      </div>

      {isPopupOpen && (
        //RETURN DESIGN
        <div>


          <div className="popup">
            <h1>{viewOrderSingleTableInfoList?.type || "---"}</h1>
            <table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Customer Name</th>
                  <th>Model</th>
                  <th>Product Name</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Stiching Rate</th>

                  {
                    viewOrderSingleTableInfoList?.taxTotal && viewOrderSingleTableInfoList?.taxTotal !== 0 ?
                      <th>Tax%</th> : ""}
                  <th>Total</th>

                </tr>
              </thead>
              <tbody>

                {
                  viewOrderSingleTableInfoList?.list?.map((r, i) => (
                    <tr key={i} style={{ backgroundColor: i % 2 === 0 ? 'rgb(251 246 249)' : 'white', height: "37px" }}>
                      <td>{r?.type?.toUpperCase()}</td>
                      <td>{r?.customerName}</td>
                      <td>{r?.model}</td>
                      <td>{r?.productName}</td>
                      <td>{r?.qty}</td>
                      <td>{r?.rate?.toFixed(decimalPosition)}</td>
                      <td>{r?.stitchRate}</td>
                      {
                        viewOrderSingleTableInfoList?.taxTotal && viewOrderSingleTableInfoList?.taxTotal !== 0 ?
                          <td>{r?.tax}</td> : ""}
                      <td>{r?.total?.toFixed(decimalPosition)}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            <div className='last-total-div' style={{ backgroundColor: "rgb(244 233 245)", margin: "0px 22px" }}>
              <div className='sub-total' style={{ marginRight: "43px" }}>
                <p>Sub Total</p>
                {viewOrderSingleTableInfoList?.taxTotal && viewOrderSingleTableInfoList?.taxTotal !== 0 ?
                  <p>Tax</p> : ""}
                <hr className='line-2' />
                <h1>Total</h1>
              </div>
              <div className='sub-total-amount'>
                <h1 style={{ padding: "0px" }}>{viewOrderSingleTableInfoList?.subTotal?.toFixed(decimalPosition) || ""}</h1>

                {
                  viewOrderSingleTableInfoList?.taxTotal && viewOrderSingleTableInfoList?.taxTotal !== 0 ?
                    <h1 style={{ padding: "0px" }}>{viewOrderSingleTableInfoList?.taxTotal?.toFixed(decimalPosition) || ""}</h1> : ""
                }

                <hr className='line-3' />
                <h1>{viewOrderSingleTableInfoList?.grandTotal?.toFixed(decimalPosition) || ""}</h1>
              </div>
            </div>
          </div>

          {
            // log?.length!==0 &&  
            <div
              className="new-global-white-bg-container new-log-container"
              style={{ padding: "4px" }}
            >
              <div
                style={{ padding: "8px", cursor: "pointer" }}
                onClick={clickLogView}
              ></div>
              <div
                className={logView ? "pointer-up" : "pointer"}
                onClick={clickLogView}
                style={{ cursor: "pointer" }}
              ></div>
              {logView && (
                <div className="single-log-container" style={{ padding: "2px" }}>
                  {log?.map((r, i) => (
                    <div className="single-log-item">
                      <div className="single-log-item-line-image">
                        <hr className="single-log-item-line" />
                        <div className="single-log-item-image">
                          <img src={logoImage} alt="" />
                        </div>
                        <hr className="single-log-item-line" />
                      </div>
                      <p className="heading">{r?.status}</p>
                      <p className="name">{r?.empName}</p>
                      <p className="date-time">{r?.date}</p>
                    </div>
                  ))}
                </div>
              )}

              {/* <div className='single-log-container-scroll-line'></div> */}
            </div>}


        </div>
      )}

    </div>
  );
};
{
  /* <div className="view-order-single-view-container">
          <h3>View</h3>
          <hr className="global-hr" />
          <div className="view-order-input-container">
            <div className="global-single-input">
              <p>Customer Name</p>
              <input />
            </div>
            <div className="global-single-input">
              <p>Mobile</p>
              <input />
            </div>
            <div className="global-single-input">
              <p>Order Date</p>
              <input />
            </div>
            <div className="global-single-input">
              <p>Delivery Date</p>
              <input />
            </div>
            <div className="global-single-input">
              <p>Rack</p>
              <input />
            </div>
          </div>
          <div className="view-order-status-container">
            <p>Status</p>
            {/* map */
}
// {viewOrderSingleViewData.map((r) => (
//   <div className="view-order-status-content">
//     <div className="view-order-status-top-content">
//       <h4>{r.name}</h4>
//       <span
//         className={
//           r.status === "delivered"
//             ? "status paid single-view-deliver"
//             : r.status === "pending"
//             ? "status cancelled view-order-status-booking"
//             : undefined
//         }
//       >
//         {r.status}
//       </span>
//     </div>

{
  /* map */
}

//   {r.details.map((k) => {
//     if (r.name === "jalabia") {
//       return (
//         <div className="view-order-status-content-input-container">
//           <div className="global-single-input view-order-single-view-status">
//             <p>Thob Model</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Length</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Shoulder</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Sleeve</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Chest</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Stomach</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>S.L Chest</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>S.L So</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Neck</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Bottom</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Mar Size</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Mar Model</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Cuff</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Cuff Size</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Cuff Cloth</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Remark</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Cuff Model</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Collar</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Collar Size</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Collar Cloth</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Collar Model</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Regal Size</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Muttu Loose</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>FP Down</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>FP Model</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>FP Size</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Pen</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Side PT Model</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Stitching</p>
//             <input />
//           </div>
//           <div className="global-single-input view-order-single-view-status">
//             <p>Button</p>
//             <input />
//           </div>
//         </div>
//       );
//     }
//   })}
//   {/* .. */}
//   <div className="view-order-status-content-input-container">
//     <div className="global-single-input view-order-single-view-status">
//       <p>Cutting Master</p>
//       <input />
//     </div>
//     <div className="global-single-input view-order-single-view-status">
//       <p>Tailor</p>
//       <input />
//     </div>
//     <div className="global-single-input view-order-single-view-status-select">
//       <p>Product</p>
//       <Select></Select>
//     </div>
//   </div>
// </div>
// ))}

//   </div>
//   <div className="view-order-single-view-btn">
//     <button
//       onClick={() => setViewOrderView(false)}
//       className="btn btn-secondary"
//     >
//       Close
//     </button>
//   </div>
// </div> */}
