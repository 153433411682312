import React from 'react'
//import "../../../../css/Orders/CuttingSlip/viewOrderNew.css";

export const ViewOrdersUserNew = () => {
  return (
    <div className='viewOrder-full-container'>
       <div className='viewOrder-first-container-div'>
        <h1>helloooo</h1>
       </div>

    </div>
  )
}
