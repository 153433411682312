import { Autocomplete, Dialog, IconButton, MenuItem, Select, TextField, createFilterOptions } from "@mui/material";
import { findNonSerializableValue } from "@reduxjs/toolkit";
import React, { useEffect, useState ,useRef} from "react";
import { useSelector } from "react-redux";
import { viewAllProductsAPICall } from "../../../../API/Product List/productListAPI";
import { generateGrnTransactionIdAPICall } from "../../../../API/Purchase Manager/grnAPI";
import { generateIdPurchaseWOPOAPICall,addPurchaseWOPOAPICall } from "../../../../API/Purchase Manager/purchaseWOPOAPI";
import { viewAllSuppliersAPICall } from "../../../../API/Purchase Manager/supplierAPI";
import { viewCardManualAPICall } from "../../../../API/Settings/CardAndUpiType/cardManualAPI";
import { viewUpiManualAPICall } from "../../../../API/Settings/CardAndUpiType/UpiManualAPI";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { getAllActiveEmployeesAPICall } from "../../../../API/Staff/HRM/employeeAPI";
import "../../../../css/Purchase Manager/purchaseWoPo.css";
import { getCurrentTime, today } from "../../../../Js/Date";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import Voucher from "../../../Single Components/Voucher";
import { SupplierAdd } from "./SupplierAdd";
import jwtDecode from "jwt-decode";
export const PurchaseWoPo = () => {
  const initialCustomerRender = useRef(true)
  const userMeta=localStorage.getItem("login_meta")
  const userBranchId=JSON.parse(userMeta)?.branchId

  const TOKEN = localStorage.getItem("userToken");
  const isTax=jwtDecode(TOKEN)?.isTax

  const storeCode = localStorage.getItem('branchId')
  const purchseWoPoId=useSelector((state)=>state.generatePurchsewopoId.value)
  // generate grn id
  const voucherTransactionId=useSelector((state)=>state.generateGrnTransactionIdSlice.value)
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
   //List of all Branches
   const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // all supplier list
  const allSuppliersList = useSelector(
    (state) => state.allSupplierListSlice.value
  );
   //Active Employee List
   const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );
   //List of Products
   const listOfProducts = useSelector(
    (state) => state.allProductsListSlice.value
  );

  const todayDate={today}
  const currentTime=getCurrentTime()
  const upiManual=useSelector((state)=>state.upiManualSlice.value)
  const cardManual=useSelector((state)=>state.cardManualSlice.value)
  //Update Listener States
  const [isModify, setIsModify] = useState(false);

  // voucher Dialog Open 
const [isOpenVoucher,setIsOpenVoucher]=useState(false)
const [addSupplierDialog,setAddSupplierDialog]=useState(false)
const [branch,setBranch]=useState(null)
const [supplier,setSupplier]=useState(null)
const [receivedBy,setReceivedBy]=useState(null)
const [productCategory,setProductCategory]=useState(2)
const [product,setProduct]=useState(null)
const [size,setSize]=useState(null)
const [selectedSize,setSelectedSize]=useState([])

const purchaseWoPoFormInitialState={
  supplier:null,
  invoiceDate:today,
  remarks:'',
  productId:null,
  productCode:null,
  productName:'',
  size:null,
  unit:'',
  qty:'',
  rate:'',
  amount:'',
  brand:null,
  igst:0,
  gst:0,
  total:0,

  tansactionId:'',
  lastPaidAmount:'',
  paymentMethod:'cash',
  account:'',
  voucherDate:'',
  cardVendor:'',
  upiId:'',
  upiVendor:'',
  balanceAmt:''
}
const [purchaseWoPoForm,setPurchaseWoPoForm]=useState(purchaseWoPoFormInitialState)
const [tableDataForFrontEnd,setTableDataForFrontEnd]=useState([])
const [tableDataForBackEnd,setTableDataForBackEnd]=useState([])
const totalFormInitialState={
   totalAmount:null,
   discount:0,
   netAmount:null
}

const [totalForm,setTotalForm]=useState(totalFormInitialState)

 //Snackbar States
const [successSnackOpen, setSuccessSnackOpen] = useState(false);
const [errorSnackOpen, setErrorSnackOpen] = useState(false);
const [snackMsg, setSnackMsg] = useState("Error!!");


//  validation states
const [branchAlert,setBranchAlert]=useState(false)
const [supplierAlert,setSupplierAlert]=useState(false)
const [recieverAlert,setRecieverAlert]=useState(false)
const [addProductAlert,setAddProductAlert]=useState(false)
const [productAlert,setProductAlert]=useState(false)
const [quantityAlert,setQuantityAlert]=useState(false)
const [sizeAlert,setSizeAlert]=useState(false)
const [voucherAlert,setVoucherAlert]=useState(false)
const [isEdited,setIsEdited]=useState(false)

//Close SuccessSnackbar
 const closeSuccessSnackbar = () => {
   setSuccessSnackOpen(false);
 };
 //Close ErrorSnackbar
 const closeErrorSnackbar = () => {
   setErrorSnackOpen(false);
 };

 //*Update Listener
 const updateListener = () => {
  setIsModify(!isModify);
};

// close Voucher function 
  const closeVoucherDialog=()=>{
    setIsOpenVoucher(false)
  }

  //Open Voucher Function
  const openVoucherDialog=()=>{
    if(branch===null||branch===undefined){
      setBranchAlert(true)
      setVoucherAlert(true)
    }
    else if(supplier === null){
      setSupplierAlert(true)
      setVoucherAlert(true)
    }
    else if(receivedBy===null){
      setRecieverAlert(true)
      setVoucherAlert(true)
    }
    else if(tableDataForFrontEnd.length===0){
      setAddProductAlert(true)
      setVoucherAlert(true)
    }
    else{
    setIsOpenVoucher(true)
    setVoucherAlert(false)
    }
  }

// add supplier dialog close function
const closeSupplierDialog=()=>{
  setAddSupplierDialog(false)
}

// onChange function
const getProductCategory=(e)=>{
  setProductCategory(Number(e.target.value))
 
}
const getDiscount =(e)=>{
  setTotalForm({...totalForm,discount:e.target.value})
}
const getRemarks=(e)=>{
  setPurchaseWoPoForm({...purchaseWoPoForm,remarks:e.target.value})
}
const getQty=(e)=>{
  setPurchaseWoPoForm({...purchaseWoPoForm,qty:e.target.value})
  setQuantityAlert(false)
  if(e.target.value===""){
    setQuantityAlert(true)  
  }
}
const getRate=(e)=>{
  setPurchaseWoPoForm({...purchaseWoPoForm,rate:e.target.value})
  
}
const getIgst=(e)=>{
  setPurchaseWoPoForm({...purchaseWoPoForm,igst:e.target.value,gst:0})
}
const getGst=(e)=>{
  setPurchaseWoPoForm({...purchaseWoPoForm,gst:e.target.value,igst:0})
}
const getVoucherChange=(key)=>(e)=>{
  const{value}=e.target
  if(key==="lastPaidAmt"){
    setPurchaseWoPoForm({...purchaseWoPoForm,lastPaidAmount:value})
  }
  if(key ==="paymentMethod"){
    setPurchaseWoPoForm({...purchaseWoPoForm,paymentMethod:value})
  }
  if(key ==="date"){
    setPurchaseWoPoForm({...purchaseWoPoForm,voucherDate:value})
  }
  if(key==="cardType"){
    setPurchaseWoPoForm({...purchaseWoPoForm,cardVendor:value})
  }
  if(key==="accountNo"){
    setPurchaseWoPoForm({...purchaseWoPoForm,account:value})
  }
  if(key==="upiType"){
    setPurchaseWoPoForm({...purchaseWoPoForm,upiVendor:value})
  }
  if(key==="upiId"){
    setPurchaseWoPoForm({...purchaseWoPoForm,upiId:value})
  }
}
useEffect(()=>{
  generateIdPurchaseWOPOAPICall({branchId:loginResponse?.branchId})
  viewAllBranchesAPICall();
  viewAllSuppliersAPICall();
  getAllActiveEmployeesAPICall();
},[isModify])

  // extract current branch from list
useEffect(()=>{
  let currentBranch=[]
  currentBranch=allBranchesList?.filter((obj)=>{
  if(obj._id===loginResponse?.branchPk){
    return obj
    }
   })
    if(currentBranch !== undefined) setBranch(currentBranch[0])
  },[allBranchesList,loginResponse?.branchPk])

  // product 
  useEffect(() => {
    viewAllProductsAPICall({ type: productCategory,branchId:storeCode });
  }, [productCategory]);

  // extract product details
  useEffect(()=>{
    setPurchaseWoPoForm({...purchaseWoPoForm,
     productName:product?.productName,
     unit:product?.unitName,
     rate:product?.calculatedPrice,
     size:product?.size,
     productCode:product?.productId,
     productId:product?._id,
     brand:size?.brandUnit
    })
  },[product,size])

  // extract supplier id
  useEffect(()=>{
    setPurchaseWoPoForm({...purchaseWoPoForm,supplier:supplier?._id,balanceAmt:supplier?.openingBalance})
  },[supplier])
  
  // size filter 
  useEffect(()=>{
    if(size !== undefined && size !== null){
      let sizeFilter=purchaseWoPoForm?.size?.filter((obj)=>{
        if(obj.brandUnit===size.brandUnit){
          return obj
        }
       })
       setSelectedSize(sizeFilter)
    }
  
  },[size])

  useEffect(()=>{
  setPurchaseWoPoForm({...purchaseWoPoForm,rate:selectedSize !== undefined ? selectedSize[0]?.calculatedPrice:""})
  },[selectedSize])

// calculate amount

useEffect(()=>{
  let amountTot=0
  amountTot=parseFloat(purchaseWoPoForm?.qty)*parseFloat(purchaseWoPoForm?.rate)
  setPurchaseWoPoForm({...purchaseWoPoForm,amount:isNaN(amountTot)?'':amountTot.toFixed(2)})
},[purchaseWoPoForm?.qty,purchaseWoPoForm?.rate])

useEffect(()=>{
  if(purchseWoPoId!==undefined){
    setPurchaseWoPoForm({...purchaseWoPoForm,tansactionId:`${purchseWoPoId?.prefix}${purchseWoPoId?.data}`})
  }
},[purchseWoPoId])
// calculate total with gst
const calculateTotalIncludeTax=()=>{
  let taxAmount=0;
  let productIncludeTax=0;
  
  if(purchaseWoPoForm?.gst===0){
    taxAmount=((parseFloat(purchaseWoPoForm?.amount)*parseFloat(purchaseWoPoForm?.igst)))/100
    productIncludeTax=parseFloat(purchaseWoPoForm?.amount)+parseFloat(taxAmount)
  }
  else if(purchaseWoPoForm?.igst===0){
    taxAmount=((parseFloat(purchaseWoPoForm?.amount)*parseFloat(purchaseWoPoForm?.gst)))/100
    productIncludeTax=parseFloat(purchaseWoPoForm?.amount)+parseFloat(taxAmount)
  }
  setPurchaseWoPoForm({...purchaseWoPoForm,total:productIncludeTax?.toFixed(2)||purchaseWoPoForm?.amount.toFixed(2)})
}

useEffect(()=>{
  calculateTotalIncludeTax()
},[purchaseWoPoForm?.amount,purchaseWoPoForm?.igst,purchaseWoPoForm?.gst])




// console.log(size);
// submit button function
const purchaseWoPoSubmitBtnFn=()=>{
  if(product===null){
    setProductAlert(true)
  }
  else if(productCategory!==2 && size===null){
      setSizeAlert(true)
  }
  else if(purchaseWoPoForm?.qty===''){
    setQuantityAlert(true)
  }
  else{
    setTableDataForFrontEnd([...tableDataForFrontEnd,
      {
         productCode:purchaseWoPoForm?.productCode,
         productName:purchaseWoPoForm?.productName,
         unit:purchaseWoPoForm?.unit,
         qty:parseFloat(purchaseWoPoForm?.qty),
         rate:purchaseWoPoForm?.rate,
         amount:purchaseWoPoForm?.amount,
         igst:purchaseWoPoForm?.igst,
         gst:purchaseWoPoForm?.gst,
         sgst:(purchaseWoPoForm?.gst)/2,
         cgst:(purchaseWoPoForm?.gst)/2,
         amtWithGst:purchaseWoPoForm?.total
    
      }
      ])
      setTableDataForBackEnd([...tableDataForBackEnd,
      {
        type:parseFloat(productCategory),
        itemInfo:purchaseWoPoForm?.productId,
        quantity:parseFloat(purchaseWoPoForm?.qty),
        rate:purchaseWoPoForm?.rate,
        unit:purchaseWoPoForm?.unit,
        dimension:purchaseWoPoForm?.brand
      }
      ])
      setAddProductAlert(false)
      setVoucherAlert(false)
      productClearState()
  }
 
}

const productClearState=()=>{
  setProduct(null)
  setPurchaseWoPoForm({...purchaseWoPoForm,
    productName:'',
    unit:'',
    qty:'',
    rate:'',
    amount:'',
    igst:0,
    gst:0,
   
  })
   setSize(null)
}

// edit
const editProductInfoTable=(index)=>{
  // console.log(tableDataForFrontEnd[index]);
  // console.log(tableDataForBackEnd[index]);
  setProductCategory(tableDataForBackEnd[index].type)
  setPurchaseWoPoForm({...purchaseWoPoForm,
                      productName:tableDataForFrontEnd[index]?.productName,
                      qty:tableDataForFrontEnd[index]?.qty,
                      rate:tableDataForFrontEnd[index]?.rate,
                      amount:tableDataForFrontEnd[index]?.amount,
                      igst:tableDataForFrontEnd[index]?.igst,
                      gst:tableDataForFrontEnd[index]?.gst,
                      unit:tableDataForBackEnd[index]?.unit,
                      productCode:tableDataForFrontEnd[index]?.productCode
  })
  setIsEdited(!isEdited)
  tableDataForFrontEnd.splice(index,1)
  tableDataForBackEnd.splice(index,1)
  setTableDataForFrontEnd([...tableDataForFrontEnd])
  setTableDataForBackEnd([...tableDataForBackEnd])
}

useEffect(()=>{
  let filteredProduct=listOfProducts?.filter((obj)=>
      obj?.productId===purchaseWoPoForm?.productCode
    )
  setProduct(filteredProduct!==undefined?filteredProduct[0] :null)
},[listOfProducts,isEdited])

// delete table row
const deleteTableRow=(index)=>{
  tableDataForFrontEnd.splice(index,1)
  tableDataForBackEnd.splice(index,1)
  setTableDataForFrontEnd([...tableDataForFrontEnd])
  setTableDataForBackEnd([...tableDataForBackEnd])
}
const calculateTotal=()=>{
  let totalAmount=0;
  tableDataForFrontEnd.forEach((r,i)=>{
    totalAmount+=parseFloat(r?.amtWithGst)
  })
  setTotalForm({...totalForm,totalAmount:isNaN(totalAmount)?'':totalAmount})
}
useEffect(()=>{
  calculateTotal()
},[tableDataForFrontEnd])

// calculate net amount
useEffect(()=>{
  let netAmount=0
  netAmount=parseFloat(totalForm?.totalAmount)-((parseFloat(totalForm?.totalAmount)*parseFloat(totalForm?.discount))/100)
  setTotalForm({...totalForm,netAmount:isNaN(netAmount)?totalForm?.totalAmount:netAmount})
},[totalForm?.totalAmount,totalForm?.discount])


// purchase wopo final submit function
const bodyToApi={
  supplierId:purchaseWoPoForm?.supplier,
  invoiceDate:`${purchaseWoPoForm?.invoiceDate} ${getCurrentTime()}`,
  remarks:purchaseWoPoForm?.remarks,
  purchaseInfo:tableDataForBackEnd,
  branchId:branch?.storeCode,
  recievedBy:receivedBy?._id,
  percentage:parseFloat(totalForm?.discount),
  amount:totalForm?.totalAmount,
  netAmount:totalForm?.netAmount
}
const bodyToApiWithVoucher={
  supplierId:purchaseWoPoForm?.supplier,
  invoiceDate:`${purchaseWoPoForm?.invoiceDate} ${getCurrentTime()}`,
  remarks:purchaseWoPoForm?.remarks,
  purchaseInfo:tableDataForBackEnd,
  branchId:branch?.storeCode,
  recievedBy:receivedBy?._id,
  percentage:parseFloat(totalForm?.discount),
  amount:totalForm?.totalAmount,
  netAmount:totalForm?.netAmount,

  lastPaidAmount:Number(purchaseWoPoForm?.lastPaidAmount),
  paymentMethod:{
    type:purchaseWoPoForm?.paymentMethod,
    date:`${purchaseWoPoForm?.voucherDate} ${getCurrentTime()}`,
    paidAmount:Number(purchaseWoPoForm?.lastPaidAmount),
    account:
    purchaseWoPoForm.paymentMethod==="cash"?
      null
      :purchaseWoPoForm.paymentMethod==="card"?
      purchaseWoPoForm?.account
      :purchaseWoPoForm?.upiId,
    vendor:
    purchaseWoPoForm.paymentMethod==="cash"?
      null
      :purchaseWoPoForm.paymentMethod==="card"?
      purchaseWoPoForm?.cardVendor
      :purchaseWoPoForm?.upiVendor
  },
  branchId:userRole==='user' ?userBranchId:branch!==null && branch!==undefined && branch.storeCode,
  date:`${todayDate.today} ${currentTime}`
}
// console.log(branch);
const purchaseWOPOFinalSubmitFn=()=>{
  if(branch===null||branch===undefined){
    setBranchAlert(true)
  }
  else if(supplier === null){
    setSupplierAlert(true)
  }
  else if(receivedBy===null){
    setRecieverAlert(true)
  }
  else if(tableDataForFrontEnd.length===0){
    setAddProductAlert(true)
  }
  else{
  addPurchaseWOPOAPICall(
    bodyToApi,
    finishclearState,
    setSuccessSnackOpen,
    setErrorSnackOpen,
    setSnackMsg,
    updateListener,
    closeVoucher
    )
  // console.log(bodyToApi);
  }

}

//Close Voucher
const closeVoucher=()=>{
  setIsOpenVoucher(false)
}

const finishclearState=()=>{
  setSupplier(null)
  setReceivedBy(null)
  setTableDataForFrontEnd([])
  setTableDataForBackEnd([])
  setPurchaseWoPoForm(purchaseWoPoFormInitialState)
  setTotalForm(totalFormInitialState)
}
const purchaseWOPOPostWithVoucher=()=>{
  addPurchaseWOPOAPICall(
    bodyToApiWithVoucher,
    finishclearState,
    setSuccessSnackOpen,
    setErrorSnackOpen,
    setSnackMsg,
    updateListener,
    closeVoucher
    )
  // console.log(bodyToApiWithVoucher);
}



useEffect(()=>{
  viewCardManualAPICall()
  viewUpiManualAPICall()
},[])

// useEffect(()=>{
//   setPurchaseWoPoForm({...purchaseWoPoForm,balanceAmt:supplier?.openingBalance})
//  },[supplier])

useEffect(()=>{
  generateGrnTransactionIdAPICall({
    branchId:userRole==='user' ?userBranchId:branch!==null && branch!==undefined && branch.storeCode
  })
},[branch,isModify])



  return (
    <>
      <div className="global-page-parent-container purchase-wo-po-parent-container">
        <p className="breadcrumb">Purchase Manage &gt; Purchase W/O PO</p>
        <div className="first-row">
          <div className="fr-left-container">
            <h3>Purchase W/O PO</h3>
            <div className="fr-left-form-container">
              <div className="global-single-input pWopO-input">
                <p>Transaction ID</p>
                <input value={purchseWoPoId!==undefined ?`${purchseWoPoId?.prefix}${purchseWoPoId?.data}`:"---"} type="text" name="" id="" />
              </div>
              {/* <div className="global-single-input pWopO-input">
                <p>Sales Invoice No</p>
                <input type="text" name="" id="" />
              </div> */}
              <div className="global-single-input pWopO-input auto-complete">
                <p>Branch</p>
                <Autocomplete
                disabled={userRole === 'user'}
                sx={{ width: "100%" }}
                options={ allBranchesList|| [""]}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                renderInput={(params) => <TextField {...params} />}
                value={branch}
                onChange={(e, newValue) =>{
                  setBranch(newValue)
                  setBranchAlert(false)
                  setVoucherAlert(false)
                  if(newValue===null){
                    setBranchAlert(true)
                    
                  }
                }
                }
              />
                {branchAlert?
                <p className="doc-validation-alert">Select a branch</p>
                :""
                }
              </div>
              <div className="global-single-input pWopO-input">
                <p>Invoice Date</p>
                <input value={purchaseWoPoForm?.invoiceDate} type="date" name="" id="" />
              </div>
              <div className="global-single-input pWopO-input auto-complete" >
                <p>Supplier</p>
                <Autocomplete
                sx={{ width: "100%" }}
                options={ allSuppliersList|| [""]}
                getOptionLabel={(option) =>
                  option?.supplierName
                }
                renderInput={(params) => <TextField {...params} />}
                value={supplier}
                onChange={(e, newValue) =>{
                  setSupplier(newValue)
                  setSupplierAlert(false)
                  setVoucherAlert(false)
                  if(newValue===null){
                    setSupplierAlert(true)
                  }
                }
                }
              />
                {supplierAlert?
                  <p className="doc-validation-alert">Select a supplier</p>
                :""
                }
                <p onClick={()=>setAddSupplierDialog(true)} className="suffix-text-blue">Add Supplier</p>
              </div>
              <div className="global-single-input pWopO-input auto-complete" >
                <p>Received By</p>
                <Autocomplete
                sx={{ width: "100%" }}
                options={ allActiveEmpList|| [""]}
                getOptionLabel={(option) =>
                  `${option?.emp_id}-${option?.staff_name}`
                }
                renderInput={(params) => <TextField {...params} />}
                value={receivedBy}
                onChange={(e, newValue) =>{
                  setReceivedBy(newValue)
                  setRecieverAlert(false)
                  setVoucherAlert(false)
                  if(newValue===null){
                    setRecieverAlert(true)
                  }
                }
                }
              />{recieverAlert?
                <p className="doc-validation-alert" style={{top:"70%"}}>Select a reciever</p>
                :""
                }
              </div>
              <div className="global-single-input pWopO-input">
                <p>Remarks</p>
                <textarea rows={3}  value={purchaseWoPoForm?.remarks} onChange={getRemarks} />
              </div>
            </div>
          </div>
          <div className="fr-right-container">
            <div className="fr-right-main-container">
            {addProductAlert?
              <p className="purchase-wopo-add-product-validation">
                add product details !!</p>
             :""}
             <div className="fr-right-radio-container">
                <div className="fr-right-radio-single-container">
                  <input 
                  type="radio" 
                  id="purchaseWoPoMaterial" 
                  name="purchaseWoPo"
                  value={2}
                  checked={productCategory===2}
                  // defaultChecked
                  onChange={getProductCategory}
                  />
                  <label htmlFor="purchaseWoPoMaterial">Material</label>
                </div>
                <div className="fr-right-radio-single-container">
                  <input 
                  type="radio" 
                  id="purchaseWoPoReadymade" 
                  name="purchaseWoPo"
                  value={0}
                  checked={productCategory===0}
                  onChange={getProductCategory}
                  />
                  <label htmlFor="purchaseWoPoReadymade">Readymade</label>
                </div>
                <div className="fr-right-radio-single-container">
                  <input 
                  type="radio" 
                  id="purchaseWoPoAccessories" 
                  name="purchaseWoPo"
                  value={1}
                  checked={productCategory===1}
                  onChange={getProductCategory}
                  />
                  <label htmlFor="purchaseWoPoAccessories">Accessories</label>
                </div>
              </div>
              <div className="fr-right-form-container">
              <div className="global-single-input pWopO-input auto-complete" >
                <p>Product Code</p>
                <Autocomplete
                sx={{ width: "100%" }}
                options={ listOfProducts|| [""]}
                value={product}
                getOptionLabel={(option) =>
                  option?.productId
                }
                renderInput={(params) => <TextField {...params} />}
                onChange={(e, newValue) =>{
                  setProduct(newValue)
                  setProductAlert(false)
                  if(newValue===null){
                    setProductAlert(true)
                  }
                }
                }
                />{productAlert?
                <p className="doc-validation-alert">Select a product</p>
                :""
                }
              </div>
              <div className="global-single-input pWopO-input">
                <p>Product Name</p>
                <input type="text" value={purchaseWoPoForm?.productName} />
              </div>
              <div className="global-single-input pWopO-input">
                <p>Unit</p>
                 <input type="text" value={purchaseWoPoForm?.unit} />
                 
              </div>
              {
                productCategory!==2 &&
                <div className="global-single-input pWopO-input auto-complete">
                   <p>Size</p>
                    <Autocomplete
                    sx={{ width: "100%" }}
                    options={ purchaseWoPoForm?.size|| [""]}
                    getOptionLabel={(option) =>
                      option?.brandUnit
                    }
                    renderInput={(params) => <TextField {...params} />}
                    value={size}
                    onChange={(e,newValue)=>{
                      setSize(newValue)
                      setSizeAlert(false)
                      if(newValue===null){
                        setSizeAlert(true)
                      }
                    }
                    }
                  />
                {sizeAlert?
                <p className="doc-validation-alert">Select a size</p>
                :""}
                </div>
              }
              <div className="global-single-input pWopO-input" style={{position:"relative"}}>
                <p>Qty</p>
                <input type="number" value={purchaseWoPoForm?.qty} onChange={getQty}/>
                {quantityAlert?
                <p className="doc-validation-alert">Enter Quantity</p>
                :""}
              </div>
              <div className="global-single-input pWopO-input">
                <p>Rate</p>
                <input type="number" value={purchaseWoPoForm?.rate} onChange={getRate}/>
              </div>
              <div className="global-single-input pWopO-input">
                <p>Amount</p>
                <input type="number" value={purchaseWoPoForm?.amount}/>
              </div>
              {
                isTax === true &&
                <>
                <div className="global-single-input pWopO-input">
                  <p>IGST</p>
                  <input type="number" value={purchaseWoPoForm?.igst} onChange={getIgst}/>
                </div>
                <div className="global-single-input pWopO-input">
                  <p>GST</p>
                  <input type="number" value={purchaseWoPoForm?.gst} onChange={getGst}/>
                </div>
                <div className="global-single-input pWopO-input">
                  <p>SGST</p>
                  <input type="number" value={(purchaseWoPoForm?.gst)/2}/>
                </div>
                <div className="global-single-input pWopO-input">
                  <p>CGST</p>
                  <input type="number"  value={(purchaseWoPoForm?.gst)/2}/>
                </div>
                </>
              }
             
             
              <div className="global-single-input pWopO-input">
                <p>Net Amount</p>
                <input type="number"  value={purchaseWoPoForm?.total}/>
              </div>
             
            </div>
            </div>
           
            <div className="cancel-submit-container">
              <button className="btn btn-secondary-outlined cancel-btn">
                Cancel
              </button>
              <button onClick={purchaseWoPoSubmitBtnFn} className="btn btn-secondary">Submit</button>
            </div>
          </div>
        </div>
        <div className="second-row">
          <h4>Orders Listed</h4>
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Product Code</th>
                  <th>Product Name</th>
                  <th>Unit</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  {
                    isTax === true &&
                    <>
                       <th>IGST</th>
                       <th>GST</th>
                       <th>SGST</th>
                        <th>CGST</th>
                  </>
                  }
                  
                 
                  <th>Net Amount</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {
                 tableDataForFrontEnd?.length !==0? tableDataForFrontEnd?.map((r,i)=>(
                 <tr key={i}>
                  <td>{i+1}</td>
                  <td>{r?.productCode}</td>
                  <td>{r?.productName}</td>
                  <td>{r?.unit}</td>
                  <td>{r?.qty}</td>
                  <td>{r?.rate}</td>
                  <td>{r?.amount}</td>
                  {
                    isTax === true &&
                    <>
                    <td>{r?.igst}</td>
                    <td>{r?.gst}</td>
                    <td>{r?.sgst}</td>
                    <td>{r?.cgst}</td>
                    </>
                  }
                 
                 
                  <td>{r?.amtWithGst}</td>
                  <td>
                    <IconButton onClick={()=>editProductInfoTable(i)}>
                      <i class="bi bi-pencil-square edit-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <IconButton  onClick={()=>deleteTableRow(i)}>
                      <i class="bi bi-trash3 delete-icon"></i>
                    </IconButton>
                  </td>
                </tr>
                  ))
                  :
                  <tr>
                    <td colSpan={14} style={{textAlign:"center"}}>No Data</td>
                  </tr>
                }
                
              </tbody>
            </table>
          </div>
          <div className="bottom-net-amount-container">
            <div className="global-single-input percentage net">
              <p>Discount (%)</p>
              <input type="number" name="" id="" value={totalForm?.discount} onChange={getDiscount}/>
            </div>
            <div className="global-single-input amount net">
              <p>Amount</p>
              <input type="number" name="" id="" value={totalForm?.totalAmount===null?0:totalForm?.totalAmount}/>
            </div>
            <div className="global-single-input net-amount net">
              <p>Net Amount</p>
              <input type="number" name="" id="" value={totalForm?.netAmount===null?0:totalForm?.netAmount} />
            </div>
          </div>
        </div>
        <div className="cancel-finish-container">
          <button className="btn btn-secondary-outlined">Cancel</button>
          <button onClick={purchaseWOPOFinalSubmitFn} className="btn btn-primary">Finish</button>
          <button className="btn btn-voucher" onClick={openVoucherDialog}>Create Voucher
            { voucherAlert?
            <p className="doc-validation-alert" style={{top:"80%"}}>Fill the above fields first !!</p>
            :""}          
          </button>
         
        </div>
      </div>

      {/* Voucher Component */}
      <Voucher 
        open={isOpenVoucher}
        onClose={closeVoucherDialog} 
        idLable="W/O PO ID"
        supplierData={purchaseWoPoForm}
        onChange={getVoucherChange}
        Cardlist={cardManual}
        UpiList={upiManual}
        finalApiCall={purchaseWOPOPostWithVoucher}
        transactionId={voucherTransactionId?.transNo}
      >
        <input type="text" 
        value={supplier!==undefined && supplier!==null &&supplier.supplierName} disabled
        />
      </Voucher>

      {/* add supplier  */}
      <Dialog
        open={addSupplierDialog}
        onClose={()=>setAddSupplierDialog(false)}
        maxWidth="lg"
        onKeyDown={(e)=>e.key === "Escape" && setAddSupplierDialog(false)}
      >
       <SupplierAdd
       isDialog={true} 
       closeDialog={closeSupplierDialog}
       updateListenerProp={updateListener}
       />
      </Dialog>

      {/* snackbar */}
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
