import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { get_delivery_noterow_data_list } from './deliveryNoteSlice';
import { DelivaryNoteAPICall, viewDeliveryNotesAPICall } from './deliveryNoteAPI';
import { Skeleton } from '@mui/material';
import store from '../../../../../Redux/store';
import { convertDateFormat, getCurrentTime } from '../../../../../Js/Date';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import CustomPagination from '../../../../Single Components/CustomPagination';
import axios from 'axios';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';

export const DeliveryNote = () => {
  let navigate = useNavigate();
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const userRole = useSelector((state) => state.userRoleSlice.value)
  const userBranchId = localStorage.getItem("branchId")

  const tableListData = useSelector((state) => state.deliveryNoteSlice.apiResList);

  const [isLoading, setIsLoading] = useState(false)
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages =tableListData?.pages;
  const [selectedBranch, setSelectedBranch] = useState(null);

  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };


  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  const singleViewFn = (row) => {
    // store.dispatch(get_delivery_noterow_data_list({ deliveryNoteSingleData: row }))
    //navigate("/userdashboard/sales/orders/deliveryNote/create")
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("singleViewById", row?._id)
    navigate("/userdashboard/sales/orders/deliveryNote/SingleView")
  }
  useEffect(() => {
    // if (userRole === "user") {
    //   viewDeliveryNotesAPICall({ branchId: userBranchId }, setIsLoading)
    // }
    // else {
    //   viewDeliveryNotesAPICall({}, setIsLoading)
    // }
    localStorage.setItem("singleViewById", "")
    store.dispatch(get_delivery_noterow_data_list({ deliveryNoteSingleData: undefined }))
  }, [])

 

  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&   DelivaryNoteAPICall({
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        fromLocation: FormDataInfo.location,
        createdBy: FormDataInfo.createdBy,
        branchList: FormDataInfo.branchList,
        status: FormDataInfo.status,
        search: FormDataInfo.search,
        index: currentPage-1,
      }, setIsLoading);
    };
 
  }, [FormDataInfo, currentPage]);

  return (
    <div className='global-page-parent-container'>

      <div className='global-white-bg-container'>
        <div className='create-button-blue-container' style={{ justifyContent: "flex-end" }}>
          {/* <button  onClick={createDeliveryNote}  className='create-button-blue'>Create</button> */}
        </div>
        <div className="justify-space-between"
          style={{ gap: "0 12px", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
        >
           {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                <h3>Branch name: {selectedBranch?.storeCode}-{selectedBranch?.branchName}</h3>
              </div>
            )}
             <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
          <CategorySearchandFilter
            statusOptions={['COMPLETED', 'DRAFT']}
            onData={handleDataFromChild}
            isLocation={true}
            isCreatedBy={true}
            isStatus={true}
            onBranchSelect={handleBranchSelect}
          />
          </div>
        </div>
        <div className='create-button-blue-container' style={{ margin: "0 1%" }}>
          <h3>Delivery Note</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
        </div>
        <div className='new-global-table-container'>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Customer</th>
                {/* <th>Contact</th>
                <th>Sales Order</th> */}
                <th>Source Document</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody >
              {

                isLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={8}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) :

                  tableListData?.list !== undefined && tableListData?.list?.length !== 0 ? tableListData?.list?.slice(0)?.map((r, i) => (
                    <tr onClick={() => singleViewFn(r)}>
                      {/* <td>{convertDateFormat(r?.date)}</td> */}
                      <td>{r?.date}</td>
                      {/* <td>{"---"}</td> */}
                      <td>{r?.name}</td>
                      {/* <td>{r?.mobileNo}</td>
                      <td>{"---"}</td> */}
                      <td>{r?.CUSTOMER}</td>
                      <td>{r?.sourceDocument}</td>
                      <td>{r?.CREATEDBY}</td>
                      <td className='table-data-box'>
                        {r?.status === "DRAFT" ? (
                          <span className='draft'> DRAFT</span>
                        ) : r?.status === "COMPLETED" ? (
                          <span className='post'> POST</span>
                        ) : r?.status === "POSTED" ? (
                          <span className='post'> POST</span>
                        ) : r?.status === "RETURNED" ? (
                          <span className='return'> RETURN</span>
                        ) : (
                          <span>{r?.status}</span>
                        )}
                      </td>
                    </tr>
                  ))
                    :
                    <tr>
                      <td colSpan={7}>No Data</td>
                    </tr>
              }
            </tbody>
          </table>
        </div>
      </div>

    </div>
  )
}
