import axios from "axios"
import { HEADERS } from "../../UrlAndPaths"
import store from "../../../Redux/store"
import { getAllPurchaseOrderList, get_po_history, purchaseOrderSingleView,  } from "../../../Redux/Prchase Manager/RFQ/RfqSlice"

export const viewPurchaseOrderList =(branchId)=>{
    
    // purchase/viewPurchaseOrder

    axios.post('purchase/viewPurchaseOrder',{
        branchId:branchId === "null" ? null : branchId
    },HEADERS).then((res)=>{
        store.dispatch(getAllPurchaseOrderList({purchaseOrderList:res.data}));
    }).catch((err)=>{
        store.dispatch(getAllPurchaseOrderList({purchaseOrderList:undefined}));
    })
}
export const PurchaseOrderFilterAPICall =(body,setIsLoading)=>{
    setIsLoading!==undefined&&setIsLoading(true)

    axios.post("purchase/searchPurchaseOrder",body,{
        headers: {
          'Content-Type': 'application/json',
        },
       
      })
    .then((res)=>{
      if(res.status===200){
        store.dispatch(getAllPurchaseOrderList({purchaseOrderList:res.data}))
        setIsLoading!==undefined&&setIsLoading(false)
  
      }
    })
    .catch((err)=>{
      store.dispatch(getAllPurchaseOrderList({purchaseOrderList:undefined}))
       setIsLoading!==undefined&&setIsLoading(false)
  
    })
  
  }
export const viewPurchaseOrderSingleView =(body)=>{
    axios.post('purchase/viewPurchaseOrderById',body,HEADERS)
    .then((res)=>{
        store.dispatch(purchaseOrderSingleView({singleRowData:res.data}))
    })
    .catch((err)=>{
        store.dispatch(purchaseOrderSingleView({singleRowData:undefined}))
    })
}

// purchase order history
export const poHistoryAPICall=(body,setLoadingForm)=>{
  setLoadingForm!==undefined && setLoadingForm(true)
  axios.post("purchase/purchaseHistory",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_po_history({poHistoryData:res.data}))
      setLoadingForm!==undefined && setLoadingForm(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_po_history({poHistoryData:undefined}))
    setLoadingForm!==undefined && setLoadingForm(false)
  })
}
