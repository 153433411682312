import axios from "axios";
import store from "../../../../Redux/store";
import { HEADERS } from "../../../../API/UrlAndPaths";
import { setQrCodeData } from "./posReaderPreviewSlice";

export const getQrCodeData = async (body) => {
  try {
    const response = await axios.get(`admin/viewqrcodedata?${body.param}`);
    
    if (response.status === 200) {
      console.log(response);
      store.dispatch(setQrCodeData(response.data))
      
    }
  } catch (error) {
    store.dispatch(setQrCodeData([]))
    console.log(error);
  }
};
