import React from "react";
import { useSelector } from "react-redux";

function DailySalesReportPrint(props) {
  const { data, formValues, total } = props;

  console.log(data, "......bbbbbbb");
  console.log(formValues, "......aaaa");
  const decimalPosition = localStorage.getItem("decimalPosition");
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);

  const today = new Date();
  const formattedToday = `${today.getDate().toString().padStart(2, "0")}-${(
    today.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${today.getFullYear()}`;

  console.log(loginResponse);

  return (
    <div className="print-main-div">
      <div className="header-div">
        <div style={{ maxWidth: "100px", height: "62px" }}>
          <img
            src={loginResponse?.logo}
            alt=""
            style={{ maxWidth: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
        <div>
          <h3 style={{ fontSize: "1em", margin: "0" }}>{formValues?.label}</h3>
          <span style={{ textAlign: "center", display: "block" }}>
            {formattedToday}
          </span>
        </div>
        <div>
          {/* <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1> */}
          <p style={{ margin: "0", fontSize: "smaller" }}>
            {loginResponse?.data?.profile?.companyName}
          </p>
          <p style={{ margin: "0", fontSize: "smaller" }}>
            {loginResponse?.data?.profile?.companyAddress}
          </p>
          <p style={{ margin: "0", fontSize: "smaller" }}>
            {loginResponse?.data?.profile?.contactNumber}
          </p>
        </div>
      </div>

      <div className="customer-receipt-table-container">
        <table>
          <thead>
            <tr>
              <th>DATE</th>
              <th>INV COUNT</th>
              <th>INV AMOUNT</th>
              <th>RETURN AMOUNT</th>
              <th>TOTAL PAID</th>
              {total?.map((r) => (
                <th style={{ textAlignLast: "center" }}>{r}</th>
              ))}
              {/* <th>CASH</th>
              <th>BANK</th> */}
              <th>CREDIT</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>
                  {item._id}
                </td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                  {item.invoiceCount}
                </td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                  {item.invAmount.toFixed(decimalPosition)}
                </td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                  {item.returnAmount.toFixed(decimalPosition)}
                </td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                  {item.total.toFixed(decimalPosition)}
                </td>
                {total?.map((key, index) => {
                  return (
                    <td key={index}>
                      
                      {item?.totalPaidAmount[key]?.toFixed(decimalPosition) || "0.00"}
                    </td>
                  );
                })}
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                  {item.amountDue.toFixed(decimalPosition)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th
                style={{
                  textAlign: "start",
                  fontSize: "1rem",
                  paddingLeft: "5%",
                }}
                colSpan={2}
              >
                TOTAL(AED)
              </th>

              <th>{formValues?.invAmount}</th>
              <th>{formValues?.returnAmountSum}</th>
              <th>{formValues?.total}</th>
              {total?.map((key, index) => {
                  return (
                    <th key={index}>
                      
                      {formValues?.totalPaidAmount[key]?.toFixed(decimalPosition) || "0.00"}
                    </th>
                  );
                })}
              {/* <th >{SingleListData?.total !== undefined && SingleListData?.total?.totalPaidAmount?.Cash?.toFixed(decimalPosition)}</th>
              <th >{SingleListData?.total !== undefined && SingleListData?.total?.totalPaidAmount?.Bank?.toFixed(decimalPosition)}</th> */}
              <th>{formValues?.amountDue}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default DailySalesReportPrint;
