import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../../../../../Redux/store";
import { get_vendor_bill_row_data_list } from "../Slice/vendorBillSlice";
import { convertDateFormat, getCurrentTime } from "../../../../../Js/Date";
import { VendorBillsFilterAPICall, getVendorpoViewAPICall, viewVendorBillAPICall } from "../API/vendorBillAPI";
import { Skeleton } from "@mui/material";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import axios from "axios";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";

export const VendorBills1 = () => {

  let navigateTo = useNavigate();
  const [path,setPath]= useState(false);
  const VenderPoId =localStorage.getItem("singleViewByIdgrn")
  const [selectedHeading, setSelectedHeading] = useState("Vendor Bill");
  const [filteredArray, setFilteredArray] = useState([]);
  const [FormDataInfo,setFormDataInfo]=useState([])
  const filtereDataOptions = useSelector((state) => state.listCategory);
  
  const tableListData = useSelector(
    (state) => state.vendorBillSlice.apiResList
  );
  const vendorBill = useSelector((state)=> state.vendorBillSlice.PoVendorList)
 
  console.log(vendorBill);
//     const [isLoading,setIsLoading]=useState(false)
//     const handleClickRow=(row)=>{
//       // store.dispatch(get_vendor_bill_row_data_list({vendorBillSingleData:row}))
//       //navigateTo("/userdashboard/accounts/vendor/createvendorBills")
// // console.log("row",row);
//     localStorage.setItem('singleViewById', row._id);
//       navigateTo("/userdashboard/accounts/vendor/SingleView")
//     }
  
//     useEffect(()=>{
//   localStorage.removeItem('singleViewById');
//       store.dispatch(get_vendor_bill_row_data_list(undefined))
//       viewVendorBillAPICall(setIsLoading)
//     },[])


  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const totalPages = filteredArray?.pages;
  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    // store.dispatch(get_vendor_bill_row_data_list({vendorBillSingleData:row}))
    //navigateTo("/userdashboard/accounts/vendor/createvendorBills")
    // console.log("row",row);
    // get_vendor_bill_row_data_list({ vendorBillSingleRowData: undefined })
    localStorage.removeItem("singleVendorById");
    localStorage.setItem("singleViewById", row._id);
    navigateTo("/userdashboard/accounts/vendor/SingleView");
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  useEffect(()=>{
if(VenderPoId !==null){
  vendorBill!==undefined && setFilteredArray(vendorBill)
  setPath(true)
}
else{
  setPath(false)
      localStorage.removeItem("singleViewById");
      VenderPoId === null &&  setFilteredArray(tableListData)
}
  },[vendorBill,tableListData])

  useEffect(() => {
    if(VenderPoId){
      getVendorpoViewAPICall({purchaseId:VenderPoId},setIsLoading);
    }else{
    localStorage.removeItem("singleViewById");
    store.dispatch(
      get_vendor_bill_row_data_list({ vendorBillSingleRowData: undefined })
    );
    // viewVendorBillAPICall(setIsLoading);
}}, [VenderPoId]);
console.log(FormDataInfo.status);

useEffect(() => {

  if(FormDataInfo.length!==0){ 
    let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData && VendorBillsFilterAPICall({
  paymentStatus: FormDataInfo.status.length!==0 ? (FormDataInfo.status[0]==="PAID"):null,
  fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
  toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
  branchList: FormDataInfo.branchList,
  status:FormDataInfo.status.length!==0 ? (FormDataInfo.status[0]==="DRAFT"?FormDataInfo.status:["COMPLETED"] ):"",
  search: FormDataInfo.search,
  index: currentPage-1,
},setIsLoading);
}

}, [FormDataInfo, currentPage]);

const changeColor = (heading) => {
  setSelectedHeading(heading);
};
const handleBranchSelect = (selectedBranch) => {
  setSelectedBranch(selectedBranch); 

};

const handleDataFromChild = (data) => {
  setFormDataInfo(data)
};

const handleBackpage=()=>{
 // localStorage.removeItem("singleViewByIdgrn")
  navigateTo("/userdashboard/purchaseManager/NewPurchaseOrder")
}
  return (
    <div className="global-page-parent-container">
      {/* <p className="breadcrumb">Accounts &gt; Vendors &gt; Vendor Bills</p> */}
      {path && (
        <div className="first-main-container-div">
        <div className="first-main-heading-div" id="headingDiv">
          <p
            style={{
              marginLeft: "14px",
              color: selectedHeading === "Vendor Bill" ?"black" : "gray",
            }}
            onClick={() => {changeColor("Vendor Bill");handleBackpage()}}
          >
            Purchase Order
          </p>
          <p
            style={{
              color: selectedHeading === "purchase Vendor Bill" ? "black" : "gray",
            }}
            //onClick={() => changeColor("purchase Order GRN")}
          >
         Vendor Bill
          </p>
          
        </div>
        </div>
        )}
      <div className="global-white-bg-container">
      <div className="justify-space-between"
        style={{gap:"0 12px",display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}
        >
           {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                <h3>Branch name: {selectedBranch?.storeCode}-{selectedBranch?.branchName}</h3>
              </div>
            )} 
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              // gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
          <CategorySearchandFilter
          statusOptions={['COMPLETED','DRAFT','PAID']}
          onData={handleDataFromChild}
          isStatus={true}
          onBranchSelect={handleBranchSelect}
          />
          <button
              className="btn btn-primary"
              onClick={() =>
                navigateTo(
                  "/userdashboard/accounts/vendor/createvendorBills"
                )
              }
            >
              Create
            </button>
            </div>
        </div>
        <div className="create-button-blue-container">
          <h4>Vendor Bills</h4>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
          {/* <button
            className="create-button-blue"
            onClick={() => {
              localStorage.removeItem("singleViewById");
              navigateTo("/userdashboard/accounts/vendor/createvendorBills");
            }}
          >
            Create
          </button> */}
        </div>
      

        <div className="new-global-table-container">
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Vendor</th>
                <th>Bill Date</th>
                {/* <th>Due Date</th> */}
                <th>Bill Refference</th>
                <th>Total</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : filteredArray?.list !== undefined || filteredArray?.list?.length === 0 ? (
                filteredArray?.list
                  ?.slice(0)
                 
                  .map((r, i) => (
                    <tr
                      key={i}
                      onClick={() => handleClickRow(r)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{i + 1}</td>
                      <td>{r?.name}</td>
                      <td>{r?.vendor}</td>
                      <td>{convertDateFormat(r?.billDate)}</td>
                      {/* <td>
                        {r?.dueDate === "no date"
                          ? r?.dueDate
                          : convertDateFormat(r?.dueDate)}
                      </td> */}
                      <td>{r?.billreference}</td>
                      <td>{r?.total}</td>
                      <td className="table-data-box">
                        {r?.status === "COMPLETED" ? (
                          <span className="post"> POSTED</span>
                        ) : r?.status === "DRAFT" ?(
                          <span className="draft"> DRAFTED</span>
                        ) : r?.status==="PAID"?(
                            <span className='paid'>PAID</span>
                          )
                       : (
                          "---"
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={8}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
