import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { JournalEntriesFilterAPICall, deleteSingleJournalEntryAPICall, viewJournalEntryAPICall } from './JournalEntry/journalEntryApi';
import { useSelector } from 'react-redux';
import { convertDateFormat, getCurrentTime } from '../../../../Js/Date';
import { update_journal_entry_list_by_row } from './JournalEntry/journalEntrySlice';
import store from '../../../../Redux/store';
import { IconButton, Skeleton } from '@mui/material';
import { useState } from 'react';
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import CustomPagination from '../../../Single Components/CustomPagination';
import axios from 'axios';
import { setFilterActive } from '../../../Single Components/listSlice/filterCategorySlice';

export const JournalEntries = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const userRole = useSelector((state) => state.userRoleSlice.value)
  const userBranchId = localStorage.getItem("branchId")
  let navigateTo = useNavigate()
  const tableListData = useSelector((state) => state.journalEntrySlice.journalEntryList);

  const decimalePosition = localStorage.getItem("decimalPosition")
  const [isLoading, setIsLoading] = useState(false)
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const totalPages = tableListData?.pages;
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false
  });

  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  const handleClickRow = (row) => {
    localStorage.setItem("singleViewById", row._id)
    store.dispatch( setFilterActive(window.location.pathname))
    // store.dispatch(update_journal_entry_list_by_row({journalEntryRowData:row}))
    navigateTo("/userdashboard/accounts/accounting/journalEntriesCreate")
  }

  const deleteJournalEntryClick = (id) => () => {
    deleteSingleJournalEntryAPICall({ id })
  }
  const deletePostedJournal = () => {
    setSnackBarStates({ ...snackBarStates, message: "posted journal entrie can't be deleted!! ", error: true })
  }
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  useEffect(() => {
    localStorage.removeItem("singleViewById")
    store.dispatch(update_journal_entry_list_by_row({ journalEntryRowData: undefined }))

    // if(userRole==="user"){
    //   viewJournalEntryAPICall({branchId:userBranchId},setIsLoading)
    // }
    // else{
    //   viewJournalEntryAPICall({},setIsLoading)
    // }
  }, [])
  console.log(FormDataInfo);
  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData && JournalEntriesFilterAPICall({
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        journal: FormDataInfo?.journal,
        status: FormDataInfo.status[0]==="POSTED" ?["Completed"]:FormDataInfo.status,
        search: FormDataInfo.search,
        account: [],
        partner: [],
        index: currentPage-1,
      }, setIsLoading);
    }
   
  }, [FormDataInfo, currentPage]);

  console.log(tableListData?.list);
  return (
    <div className='global-page-parent-container'>
      {/* <p className="breadcrumb">Accounts &gt; Accounting &gt; Journal Entries</p> */}

      <div className="global-white-bg-container">
      <div className="justify-space-between"
          style={{ gap: "0 12px", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
        >
           {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                <h3>Branch name: {selectedBranch?.storeCode}-{selectedBranch?.branchName}</h3>
              </div>
            )} 
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
          <CategorySearchandFilter
            statusOptions={['POSTED', 'DRAFT']}
            onData={handleDataFromChild}
            isBranch={false}
            isStatus={true}
            isJournal={true}
            onBranchSelect={handleBranchSelect}
          />
          <button className='create-button-blue'
            onClick={() => navigateTo("/userdashboard/accounts/accounting/journalEntriesCreate")}>
            Create</button>
            </div>
        </div>
        <div className='create-button-blue-container' style={{ margin: "0 1%" }}>
          <h3>Journal Entries</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
        </div>
        <div className="new-global-table-container">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Reference</th>
                <th>Journal</th>
                <th>Total</th>
                <th>Created By</th>
                <th>Status</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {
                isLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={8}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) :
                 
                  tableListData?.list !== undefined && tableListData?.list?.length !== 0 ?
                  tableListData?.list?.slice(0)?.map((r,i)=>(
                    <tr key={i} style={{ cursor: "pointer" }}>
                         <td onClick={() => handleClickRow(r)}>{convertDateFormat(r?.accountingDate)}</td>
                         <td onClick={() => handleClickRow(r)}>{r?.name}</td>
                        <td onClick={() => handleClickRow(r)}>{r?.reference}</td>
                        <td onClick={() => handleClickRow(r)}>{r?.journalName}</td>
                         <td onClick={() => handleClickRow(r)}>{r?.total?.toFixed(decimalePosition)}</td>
                         <td onClick={() => handleClickRow(r)}>{r?.CREATEDBY || "---"}</td>
                         <td onClick={() => handleClickRow(r)} className='table-data-box'>
                           {r?.status === "DRAFT" ? (
                            <span className='draft'> DRAFT</span>
                          ) : r?.status === "POSTED" ? (
                            <span className='post'> POST</span>
                          ) : r?.status === "CANCEL" ? (
                            <span className="cancel"> CANCEL</span>
                          ) : (
                            <span>{r?.status}</span>
                          )}
                        </td>
                        <td>
                          <IconButton
                            onClick={r?.status === "POSTED" ? deletePostedJournal : deleteJournalEntryClick(r?._id)}
                          >
                            <i className={`bi bi-trash3 ${r?.status === "POSTED"
                                ? "edit-icon-disabled"
                                : "delete-icon"
                              }`}></i>
                          </IconButton>
                        </td>
                      </tr>
                  ))


                    :
                    <tr>
                      <td colSpan={8}>No data</td>
                    </tr>
              }

            </tbody>
          </table>
        </div>
      </div>
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
    </div>
  )
}
