import React, { useEffect, useState } from "react";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  MainCategoryAPiCall,
  addPOSCategoryAPICall,
  editPOSCategoryAPICall,
  posCategoryListAPICall,
  posCategorySingleViewAPI,
} from "./posCategoryAPI";
import { useDispatch, useSelector } from "react-redux";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";

export const CreatePOSCategory = () => {
  const singleViewId = localStorage.getItem("singleViewById");
  const singleViewOfPOS = useSelector(
    (state) => state?.posCategorySlice?.posSingleView
  );
  const dispatch = useDispatch();
  const MainCategoryList = useSelector(
    (state) => state?.posCategorySlice?.mainCategoryList
  );

  const posCategoryList = useSelector(
    (state) => state?.posCategorySlice?.value
  );
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
  });

  //closeSnackbar
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      message: "",
    });
  };

  let navigate = useNavigate();
  const posFormInitial = {
    mainCategory: null,
    name: "",
    parent: null,
  };

  const [loadingForm,setLoadingForm]=useState(false)

  const [posForm, setPosForm] = useState(posFormInitial);
  const [validationAlert, setValidationAlert] = useState({});
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [showEditBtn, setShowEditBtn] = useState(false);
  const [disabledInput, setDisabledInput] = useState(false);

  const backToMainPage = () => {
    if (disabledInput === false) {
      const userConfirmed = window.confirm(
        "Changes you made may not be saved."
      );

      if (userConfirmed) {
        localStorage.removeItem("singleViewById");
        navigate("/userdashboard/inventory/configuration/poscategory");
      }
    } else {
      navigate("/userdashboard/inventory/configuration/poscategory");
    }
  };

  useEffect(() => {
    MainCategoryAPiCall();
    posCategoryListAPICall();
    if (singleViewOfPOS === undefined && singleViewId) {
      posCategorySingleViewAPI({ _id: singleViewId });
    }
  }, []);

  //useEffect
  useEffect(() => {
    if (singleViewOfPOS !== undefined) {
      setShowSaveBtn(true);
      setShowEditBtn(true);
      setDisabledInput(true);
    }
  }, [singleViewOfPOS]);

  //useEffect
  useEffect(() => {
    if (singleViewOfPOS !== undefined) {
      setPosForm({
        ...posForm,
        mainCategory: filterObjFromList(
          "_id",
          MainCategoryList,
          "catId",
          singleViewOfPOS
        ),
        name: singleViewOfPOS?.name,
        parent: filterObjFromList(
          "_id",
          posCategoryList,
          "parentName",
          singleViewOfPOS
        ),
      });
    }
  }, [singleViewOfPOS, MainCategoryList, posCategoryList]);

  // getPosForm
  const getPosForm = (key) => (e, newValue) => {
    const { value } = e.target;
    setValidationAlert({});
    setPosForm({ ...posForm, [key]: value !== 0 ? value : newValue });
  };

  //CreatePosCategory
  const CreatePosCategory = () => {
    dispatch( setFilterActive(false))
    let clearFn=()=>{
      setPosForm(posFormInitial)
    }
    addPOSCategoryAPICall(
      {
        catId: posForm?.mainCategory?._id,
        name: posForm?.name,
        // parentName: posForm?.parent?._id === undefined ? "" : posForm?.parent?._id
        parentName: posForm?.parent?._id || "",
      },
      setSnackBarStates,
      snackBarStates,clearFn,setLoadingForm
    );
  };

  const editEnable = () => {
    setDisabledInput(false);
  };

  const editBody = {
    catId: posForm?.mainCategory?._id,
    name: posForm?.name,
    parentName: posForm?.parent?._id === undefined ? "" : posForm?.parent?._id,
    _id: singleViewOfPOS?._id,
  };

  //updatePosCategory
  const updatePosCategory = () => {
    editPOSCategoryAPICall(
      editBody,
      setSnackBarStates,
      snackBarStates,
      setDisabledInput,setLoadingForm
    );
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !disabledInput;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [disabledInput]);
  return (
    <div className="global-page-parent-container">
   
      <div className="global-white-bg-container">
        <Tooltip title="Back" onClick={backToMainPage}>
          <IconButton>
            <i class="bi bi-chevron-left"></i>
          </IconButton>
        </Tooltip>

        {showEditBtn ? (
          <Tooltip title="Edit">
            <IconButton>
              {disabledInput ? (
                <i
                  class="bi bi-pencil-square edit-icon1"
                  onClick={editEnable}
                ></i>
              ) : (
                <i
                  class="bi bi-check2-square edit-icon"
                  style={{ fontSize: "1.5rem" }}
                  onClick={updatePosCategory}
                ></i>
              )}

              {/* <i class="bi bi-check2-square edit-icon" style={{fontSize:"1.5rem"}}></i> */}
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}

        {showSaveBtn ? (
          ""
        ) : (
          <Tooltip
            title="Save"
            onClick={CreatePosCategory}
            disabled={posForm?.mainCategory == null || posForm?.name == ""}
          >
            <IconButton>
              <i class="bi bi-floppy"></i>
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div
        className="global-white-bg-container create-journal-container"
        style={{ height: "25vh" }}
      >
        <div className="global-single-input create-joutnal-input auto-complete">
          <p>Main Category</p>
          <Autocomplete
            sx={{ width: "100%" }}
            options={MainCategoryList || []}
            getOptionLabel={(option) => option?.categoryName}
            disabled={disabledInput}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Main Category" />
            )}
            value={posForm?.mainCategory}
            onChange={getPosForm("mainCategory")}
          />
          <p className="doc-validation-alert"></p>
        </div>
        <div className="global-single-input create-joutnal-input">
          <p>Name</p>
          <input
            type="text"
            value={posForm?.name}
            disabled={disabledInput}
            onChange={getPosForm("name")}
          />
          <p className="doc-validation-alert"></p>
        </div>
        <div className="global-single-input auto-complete create-joutnal-input">
          <p>Parent</p>
          <Autocomplete
            sx={{ width: "100%" }}
            options={posCategoryList || []}
            getOptionLabel={(option) => option?.name}
            disabled={disabledInput}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select" />
            )}
            value={posForm?.parent}
            onChange={getPosForm("parent")}
          />
        </div>
      </div>

      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

    <LoadingForm loading={loadingForm}/>

    </div>
  );
};
