
import { Autocomplete, IconButton, TextField, Tooltip, createFilterOptions } from '@mui/material'
import React, { useState } from 'react'
import draftRibbon from "../../../../Assets/statusRibbon/draft.png";
import postRibbon from "../../../../Assets/statusRibbon/post.png";
import { useEffect } from 'react';
import { companyProfileInfoForUser, viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { useSelector } from 'react-redux';
import { chartOfAccountListApiCall } from '../../../../API/AccountUpdated/chartOfAccountAPI';
import { customerListApi } from '../../../../API/Customer/customerAPI';
import { getCurrentTime, today } from '../../../../Js/Date';
import { ResetOpeningBalanceAPI, ViewOpeningBalanceAPI, draftOpeningBalanceAPI, postOpeningBalanceAPI } from './OpeningBalanceAPI';
import { filterObjFromList } from '../../../../Js/generalFunctions';
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';


const CreateOpeningBalance = () => {
  const [isLoading,setIsLoading]=useState(false)
  const [editClicked, setIsEditClicked] = useState(false);
  const [editDataId, setEditDataId] = useState("");

  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const decimalPosition = localStorage.getItem("decimalPosition");

  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );
  const companyProfileData = useSelector((state) => state?.companyProfileSlice?.userValue)
  const currencyListUpdated = useSelector((state) => state.viewUpdatedCurrency.value);

  const VendorList = useSelector((state) => state.vendorsListSlice.apiResList);
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  const singleViewData = useSelector((state) => state.Openingbalance.balance);
const formData={
  date:"",
  status: "",
  isEdit:false,
  total:0,
  totalCredit:0,
  totalDebit:0

}
const[formValue,setFormValue]=useState(formData)
const [symbol, setSymbol] = useState(null);

  const tableDataInitial = {
    account: null,
    partner: null,
    branch: null,
    reference: null,
    debit: "",
    credit: ""
  };
  const [tableData, setTableData] = useState([tableDataInitial]);
 
  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  const getFormInfo = (key) => (e) => {
    const updated = { ...formValue }; 
    updated[key] = e.target.value;
    setFormValue(updated);
  };
 
  const addRow = () => {
    setTableData([...tableData, tableDataInitial])

  }

  const ResetToDraft = () => {
    ResetOpeningBalanceAPI()
  };


  const onchangeTable = (index, name) => (e, newValue) => {
    const {value}=e.target;

  //  console.log(value);
    const updated=[...tableData]
     updated[index][name]=value||(newValue || null)

     if (name === "credit" && value !== "") {
      updated[index]["debit"] = ""; 
  } else if (name === "debit" && value !== "") {
      updated[index]["credit"] = ""; 
  }
    // const updatedData = tableData.map((row, id) => {
    //     if (id === index) {
    //         return { ...row, [name]: value === 0 ? newValue :value  };
    //     }
    //     return row;
    // });

    setTableData(updated);
};

useEffect(()=>{
  const totalCredit =  tableData?.reduce((acc, curr) => acc + parseFloat(curr.credit || 0), 0);
  const totalDebit = tableData?.reduce((acc, curr) => acc + parseFloat(curr.debit || 0), 0);
  const total = totalDebit-totalCredit;
  


    setFormValue({...formValue,
     totalCredit:totalCredit,
     totalDebit:totalDebit,
     total:total
    })
},[tableData])


const tableDataForBackend=tableData?.map((item,index)=>(
  {
    accountId:item?.account?._id,
    branchId:item?.branch?._id,
    partner:item?.partner?._id,
    reference:item?.reference,
    debit:Number(item?.debit),
    credit:Number(item?.credit)
  }
   
   
))



const handleSave=()=>{
  let payload ={
    table:tableDataForBackend,
    totalDebit:formValue?.totalDebit,
    totalCredit:formValue?.totalCredit,
    opBalanceDifference:formValue?.total,
    accountingDate:`${formValue.date} ${getCurrentTime()}`,
    postDate:`${today} ${getCurrentTime()}`,
  }
  const draftFn = () => {
    setFormValue({ ...formValue,isEdit: true, status: "DRAFT" });
  };
  draftOpeningBalanceAPI(payload,draftFn,setIsLoading,setEditDataId)
}

const postOpeningBalance =()=>{
  let postBody={
    _id:editDataId,
    accountingDate:`${formValue.date} ${getCurrentTime()}`,
    postDate:`${today} ${getCurrentTime()}`,
  }
  const draftFn = () => {
    setFormValue({
      ...formValue,
      isEdit: true,
      status: "POSTED",
    });
 
}
postOpeningBalanceAPI(postBody,draftFn,setIsLoading)
}

const editOnClick=()=>{
  if(formValue.isEdit===true){
    setFormValue({ ...formValue, isEdit:false })
    setIsEditClicked(true);
  }
  else{
  let payload ={
    
    table:tableDataForBackend,
    totalDebit:formValue?.totalDebit,
    totalCredit:formValue?.totalCredit,
    opBalanceDifference:formValue?.total,
    accountingDate:`${formValue.date} ${getCurrentTime()}`,
    postDate:`${today} ${getCurrentTime()}`,
  }
  const draftFn = () => {
    setFormValue({ ...formValue,isEdit: true, status: "DRAFT" });
  };
  draftOpeningBalanceAPI(payload,draftFn,setIsLoading,setEditDataId)
  setFormValue({ ...formValue, isEdit:true })
      setIsEditClicked(false);
}}
console.log(editDataId,"hlooo");
// single view
useEffect(()=>{
  if(singleViewData !== undefined){
    setEditDataId(singleViewData?._id);
    setFormValue({
      ...formValue,
      isEdit: true,
      status: singleViewData?.status,
      date:singleViewData?.accountingDate
    })
    const updatedArray=singleViewData?.table?.map((r)=>{
      let account =filterObjFromList("_id", chartOfAccountList,"accountId",r)
      return {
       account:account,
       partner:filterObjFromList("_id",account?.type === "Payable" ?VendorList:account?.type === "Receivables" ?customerList:[],"partner",r),
       branch:filterObjFromList("_id",allBranchesList,"branchId",r),
       reference:r?.reference,
       debit:r?.debit,
       credit:r?.credit,
      }
    })
    setTableData(updatedArray || [])

  }

},[singleViewData,VendorList,customerList])

useEffect(() => {
  let currencyObj = filterObjFromList(
    "_id",
    currencyListUpdated,
    "currency",
    companyProfileData
  );
  console.log(currencyObj);
  setSymbol(currencyObj?.symbol);
}, [companyProfileData, currencyListUpdated]);


  useEffect(() => {
    viewAllBranchesAPICall();
    chartOfAccountListApiCall();
    ViewOpeningBalanceAPI()
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, [])
  return (
    <div className="global-page-parent-container">
      <div className="global-white-bg-container">Opening Balance</div>

      <div className="new-global-white-bg-icon-container" style={{ backgroundColor: "white", padding: '14px', display: "flow" }}>
        <div>
          <div className="post-ribbon" style={{ right: "0px", top: "0px" }}>
            
            {formValue?.status === "DRAFT" && <img src={draftRibbon} alt=""  style={{ height: "53px" }}/>}
            {formValue?.status === "POSTED" && <img src={postRibbon} alt=""  style={{ height: "53px" }}/>}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
          {formValue?.status === "DRAFT" && (
            <Tooltip title="Edit" onClick={editOnClick}>
              <IconButton>
                <i
                 class={formValue?.isEdit ? 
                  "bi bi-pencil-square edit-icon1"
                  :
                  "bi bi-check2-square edit-icon"
                }
                ></i>
              </IconButton>
            </Tooltip>
          )} 
            {
              formValue?.status === "" && (
                <Tooltip title="Save">
              <IconButton
                  onClick={handleSave}
              >
                <i class="bi bi-floppy save-icon"></i>
              </IconButton>
            </Tooltip>
             )} 
            {formValue?.status === "DRAFT" && !editClicked && (
            <button
              className="btn btn-post" onClick={postOpeningBalance}
            >
              Post
            </button>
            )} 
           {formValue?.status ==="POSTED" && (
            <button
              className="btn btn-post" style={{width:"110px"}} onClick={ResetToDraft}
            >
              Reset To Draft
            </button>
            )}
          </div>
        </div>
        <hr className='global-hr' />
        <div style={{ padding: "15px" }}>
          <div className="new-global-single-input">
            <TextField
              value={formValue?.date}
              onChange={getFormInfo("date")}
              id="outlined-basic"
              label="Accounting Date*"
              variant="outlined"
              type="date"
              focused
              disabled={formValue?.isEdit}
            />

          </div>
        </div>

      </div>
      <div className="new-global-white-bg-icon-container" style={{ backgroundColor: "white", padding: '14px', marginTop: "5px", height: "65vh", display: "flow" }}>


        <div className="global-product-table" style={{ width: "100%" }}>
          <table >
            <thead>
              <tr>

                <th>Account</th>
                <th>Partner</th>
                <th>Branch</th>
                <th>Reference</th>
                <th>Debit</th>
                <th>Credit</th>

              </tr>
            </thead>

            <tbody style={{ textAlign: "center" }}>
              {
              tableData?.length!==0 ?
              tableData?.map((item,index) => (
                  <tr
                  key={index}
              
                >
                  <td style={{ border: "1px solid black" }}>
                    <div className="product-table-auto-complete">
                      <Autocomplete
                        options={chartOfAccountList || []}
                        style={{ maxHeight: "100px" }}
                        getOptionLabel={(option) => option?.accountName}
                        renderOption={(props, option) => (
                          <li {...props} key={option?._id}>
                            <span>{option?.accountName}</span>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                          />
                        )}
                        value={item?.account}
                        onChange={onchangeTable(index,"account")}
                       
                      disabled={formValue?.isEdit}

                      />
                    </div></td>
                  <td style={{ border: "1px solid black" }}>
                    <div className="product-table-auto-complete">
                      <Autocomplete
                        options={item?.account?.type === "Payable" ? (VendorList || []) : item?.account?.type === "Receivables" ? (customerList || []) : []}
                        style={{ maxHeight: "100px" }}
                        getOptionLabel={(option) => option?.name}
                        renderOption={(props, option) => (
                          <li {...props} key={option?._id}>
                            <span>{option?.name}</span>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                          />
                        )}
                        disabled={formValue?.isEdit}
                        value={item?.partner}
                        onChange={onchangeTable(index,"partner")}

                      />
                    </div>
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    <div className="product-table-auto-complete">
                      <Autocomplete
                        options={allBranchesList || []}
                        style={{ maxHeight: "100px" }}
                        getOptionLabel={(option) => option?.branchName}
                        renderOption={(props, option) => (
                          <li {...props} key={option?._id}>
                            <span>{option?.branchName}</span>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                          />
                        )}
                        // disabled={formValues?.isEdit}
                        value={item?.branch}
                        onChange={onchangeTable(index,"branch")}
                        disabled={formValue?.isEdit}
                      />
                    </div>
                  </td>
                  <td style={{ border: "1px solid black", width: "24%" }}>
                    <input
                      type="text"

                      onChange={onchangeTable(index, "reference")}

                      value={item?.reference}
                      disabled={formValue?.isEdit}

                    />
                  </td>
                  <td style={{ border: "1px solid black", width: "14%",textAlignLast:"right" }}>
                    <input
                      type="number"
                      value={item?.debit}
                      onChange={onchangeTable(index,"debit")}
                      disabled={formValue?.isEdit}
                      placeholder='0'
                    />
                  </td>
                  <td style={{ border: "1px solid black", width: "14%",textAlignLast:"right" }}>
                    <input
                      type="number"
                      value={item?.credit}
                      onChange={onchangeTable(index, "credit")}
                      disabled={formValue?.isEdit}
                      placeholder='0'
                    />
                  </td>

                  <td style={{ border: "none", width: "4%" }}>
                    <IconButton
                      onClick={() => handleDelete(index)}
                      style={{ height: "30px" }}
                      disabled={formValue?.isEdit}
                    >
                      <i
                        className="bi bi-trash3"
                        style={{
                          color: "#db0000",
                          fontSize: "1.3rem",
                        }}
                      ></i>
                    </IconButton>
                  </td></tr>
              ))
            :
            <tr>
              <td colSpan={6}>No Data</td>
            </tr>
            
            }
            </tbody>

          </table>

        </div>
        {
          !(formValue?.isEdit) &&
        <p style={{ fontSize: "small", color: "#1414a7", cursor: "pointer",maxWidth:"90px" }} onClick={() => addRow()}>Add Line</p>}
        <div style={{ width: "50%", backgroundColor: "#dee3f8", paddingInline: "21px", float: "inline-end", marginTop: "21px" ,marginRight:"41px"}}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p style={{ fontSize: "small", fontWeight: "500" }}>Total</p>
            </div>
            <div style={{ display: "flex" ,width:"40%",justifyContent:"space-between" }}>
              <p style={{ fontSize: "small" ,paddingRight:"40px"}}>{symbol} {(formValue?.totalDebit)?.toFixed(decimalPosition)}</p>
              <p style={{ fontSize: "small" }}>{symbol} {(formValue?.totalCredit)?.toFixed(decimalPosition)}</p>

            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p style={{ fontSize: "small", fontWeight: "500",color:"red" }}>Opening Balance Difference</p>
            </div>
            <div style={{ display: "flex" ,width:"40%", justifyContent: formValue && formValue.total > 0 ? "flex-end" : "flex-start" }}>
              {/* <p style={{ fontSize: "small",paddingInline:"10px" }}>AED 00.00</p> */}
              <p style={{ fontSize: "small",color:"red" }}>{symbol} { Math.abs(formValue?.total)?.toFixed(decimalPosition)}</p>
            </div>
          </div>
          <hr className='global-hr'/>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p style={{ fontSize: "small", fontWeight: "500",marginBottom:"0"}}>Amount Total</p>
            </div>
            <div style={{ display: "flex",width:"42%",justifyContent:"space-between"  }}>
            <p style={{ fontSize: "small", paddingInline: "10px" }}>{formValue?.total < 0 ? `${symbol} ${(Math.abs(formValue?.total) + formValue?.totalDebit)?.toFixed(decimalPosition)}` : `${symbol} ${formValue?.totalDebit.toFixed(2)}`}</p>
            <p style={{ fontSize: "small", paddingInline: "10px" }}>{formValue?.total > 0 ? `${symbol} ${(Math.abs(formValue?.total) + formValue?.totalCredit)?.toFixed(decimalPosition)}` : `${symbol} ${formValue?.totalCredit.toFixed(2)}`}</p>

            </div>
          </div>
          <p style={{ fontSize: "x-small", fontWeight: "500",marginTop:"0"}}>Including opening balance adjustment acount</p>

        </div>

      </div>


    </div>
  )
}

export default CreateOpeningBalance