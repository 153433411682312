import axios from "axios"

import { get_catalog_category,
     get_catalog_parts,
      get_catalog_sub_category,
      get_catalog_brand,
      get_catalog_model,
      get_catalog_manuf_year
     } from "./catalogCategorySlice"

import { HEADERS,HEADER_FORM_DATA,ADMINBASEURL } from "../../../API/UrlAndPaths"
import store from "../../../Redux/store"

// view category
export const viewCatalogCategoriesAPICall=(body,setLoadingForm)=>{
    setLoadingForm(true)
    ADMINBASEURL.post("catalogue/viewCatogoryList",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_catalog_category({catalogCategoryData:res.data}))
            setLoadingForm(false)
        }
    })
    .catch((err)=>{
        store.dispatch(get_catalog_category({catalogCategoryData:undefined}))
        setLoadingForm(false)
    })
}

// add category
export const addCatalogCategoryAPICall=(body,updateListner,clearFn)=>{
    ADMINBASEURL.post("catalogue/addCatogory",body,HEADER_FORM_DATA)
    .then((res)=>{
        if(res.status===200){
            updateListner()
            clearFn()
        }
    })
    .catch((err)=>{
        console.log('err');
    })
}


// view sub category
export const viewSubCategoryAPICall=(body,setLoadingForm)=>{
    setLoadingForm(true)
    ADMINBASEURL.post("catalogue/viewSubCatogoryListOfaCat",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_catalog_sub_category({catalogSubCategoryData:res.data}))
            setLoadingForm(false)
        }
    })
    .catch((err)=>{
        store.dispatch(get_catalog_sub_category({catalogSubCategoryData:undefined}))
        setLoadingForm(false)
    })
}
//add sub category
export const addSubCategoryAPICall=(body,setLoadingForm,updateListner,clearstate)=>{
    setLoadingForm(true)
    ADMINBASEURL.post("catalogue/addSubCatogory",body,HEADER_FORM_DATA)
  .then((res)=>{
    if(res.status===200){
        setLoadingForm(false)
        updateListner()
        clearstate()
    }
  })
  .catch((err)=>{
    setLoadingForm(false)
  })
}

// view parts
export const viewCatalogPartsAPICall=(body)=>{
    ADMINBASEURL.post("catalogue/filterCatalogue",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_catalog_parts({partsData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_catalog_parts({partsData:undefined}))

    })
}
// add parts
export const addCatalogParts=(body,updateListner,clearState)=>{
    ADMINBASEURL.post("catalogue/addCatalogue",body,HEADER_FORM_DATA)
   .then((res)=>{
    if(res.status===200){
        updateListner()
        clearState()
    }
   })
   .catch((err)=>{
    console.log(err.response);
   })
}

// view brand api call
export const viewBrandAPICall=()=>{
    ADMINBASEURL.post("catalogue/viewBrandList",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_catalog_brand({brandData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_catalog_brand({brandData:undefined}))

    })
}
// add brand
export const addBrancdAPICall=(body,updateListner)=>{
    ADMINBASEURL.post("catalogue/addBrand",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            console.log('add');
            updateListner()
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

// view model
export const viewModelAPICall=()=>{
    ADMINBASEURL.post("catalogue/viewBrandModelList",{},HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_catalog_model({modelData:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_catalog_model({modelData:undefined}))
    
  })
}
// add model 
export const addModelAPICall=(body,updateListner)=>{
    ADMINBASEURL.post("catalogue/addBrandModel",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            updateListner()
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

// view manufacture year api call
export const viewManufYearAPICall=()=>{
    ADMINBASEURL.post("catalogue/viewManufYearList",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_catalog_manuf_year({manufYearData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_catalog_manuf_year({manufYearData:undefined}))
        
    })
}

// add manuf year
export const addManufYearAPICall=(body,updateListner)=>{
    ADMINBASEURL.post("catalogue/addManufYear",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            updateListner()
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

/// add to product 
// validate
export const validateProductsForAddingAPICall=(body,setConfirmDialog,setSnackBarStates,snackBarStates,setLoadingForm)=>{
    setLoadingForm(true)
    axios.post("readymade/validateProductsForAdding",body,HEADERS)
 .then((res)=>{
    if(res.status===200){
        setConfirmDialog(true)
        setSnackBarStates({...snackBarStates,message:"success",success:true})
        setLoadingForm(false)
    }
 })
 .catch((err)=>{
    
    setSnackBarStates({...snackBarStates,message:err?.response?.data?.message,error:true})
    setLoadingForm(false)
 })
}

// multipler add 
export const addToProductAPICall=(body,setSnackBarStates,snackBarStates,setConfirmDialog)=>{
 axios.post("readymade/multipleProductsAdd",body,HEADERS)
 .then((res)=>{
    if(res.status===200){
        setSnackBarStates({...snackBarStates,message:"success",success:true})
        setConfirmDialog(false)
    }
 })
 .catch((err)=>{
    setSnackBarStates({...snackBarStates,message:err?.response?.data,error:true})

 })
}