import { createSlice } from "@reduxjs/toolkit";

export const customerRelationIdSlice = createSlice({
  name: "customerRelationIdSlice",
  initialState: {
    value: undefined,
  },
  reducers: {
    update_customer_relation_id: (state, action) => {
      state.value = action.payload.customerRelationId;
    },
  },
});

export const { update_customer_relation_id } = customerRelationIdSlice.actions;

export default customerRelationIdSlice.reducer;
