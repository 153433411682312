import { Autocomplete, Dialog, IconButton, Switch, TextField, Tooltip } from '@mui/material'
import importIcon from "../../../../Assets/Images/Icon awesome-file-import.png"
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { journalListApiCall } from '../../../../API/AccountUpdated/journalAPI';
import { useSelector } from 'react-redux';
import { BankReconciliationComputeAPI, BankReconciliationEditAPI, BankReconciliationMatchAPI, BankReconciliationSaveAPI, BankReconciliationSingleListAPI, BankReconciliationTableAPI, BankReconciliationUpdateAPI } from './BankReconciliationAPI';
import { getCurrentTime } from '../../../../Js/Date';
import CloudUploadSharpIcon from '@mui/icons-material/CloudUploadSharp';
import upload from "../../../../Assets/SVG/Icon awesome-cloud-upload-alt.svg"
import { convertExcelToJson, filterObjFromList } from '../../../../Js/generalFunctions';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import draft from "../../../../Assets/statusRibbon/draft.png";
import { LoadingForm } from '../../../Single Components/LoadingForm';
import SuccessSnackbar from '../../../Single Components/SnackBars/SuccessSnackbar';
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar';
import { singleView } from '../../Inventory/Operations/LandedCost/LandedCostSlice';
import inprogressRibbon from "../../../../Assets/statusRibbon/inprogress.png"

export const CreateBankReconciliation = () => {

  let navigate = useNavigate();
  // useRef 
  const containerRef = useRef(null);

  // redux states
  const journalList = useSelector((state) => state?.journalSlice?.value)
  const tableList = useSelector((state) => state?.bankReconciliation?.bankReconciliationtable)
  const reconciliationSave = useSelector((state) => state?.bankReconciliation?.bankReconciliationSave)
  const singleView = useSelector((state) => state?.bankReconciliation?.bankReconciliationSingleListData)
  console.log(singleView);
  // useStates
  const predefinedOptionsInitial = [
    'Transaction Date',
    'Transaction Amount',
    'Bank Amount',
    'Bank charge',
    'Approval No',
    'Bank Date']
  const [predefinedOptions, setPredefinedOptions] = useState(predefinedOptionsInitial);

  const [uploadDialog, setUploadDialog] = useState(false);
  const [postDialog, setPostDialog] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const [onSubmit, setOnSubmit] = useState(false);
  const [fileHeaders, setFileHeaders] = useState()
  const [fileRow, setFileRow] = useState()
  const [selectedImportOption, setSelectedImportOption] = useState({});
  const [importData, setImportData] = useState([]);
  const [importFinalData, setImportFinalData] = useState([]);
  const [showImportButton, setShowImportButton] = useState(false)
  const [importFinalDataForBackend, setImportFinalDataForBackend] = useState([])
  const [switchOn, setSwitchOn] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false)
  const [notReflectedInCompanyBook, setNotReflectedInCompanyBook] = useState(null)
  const [notReflectedInBank, setNotReflectedInBank] = useState(null)
  const [editClicked, setIsEditClicked] = useState(false);
  const [firstTable,setFirstTable]=useState([])
  console.log(notReflectedInBank);
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false,
  });
 

  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
  const FormInitial = {
    Reference: "",
    journal: null,
    Date: "",
    closingBalance: "",
    BankBalance: "",
    systemClosingBalance: "",
    notReflectedInCompany: "",
    notReflectedInBank: "",
    bankCharge: "",
    closingCompanyBalance: "",
    status: "",
    isEdit: false
  }
  const [formData, setformData] = useState(FormInitial)
  // onChange
  const getFormPostInfo = (key) => (e, newValue) => {
    const { value } = e.target;
    setformPostData({
      ...formPostData,
      [key]: value !== 0 ? value : newValue,
    });
  };

  const FormPostingInitial={
    account:null,
    date:"",
    amount:"",
    ref:"",
    journal:null,
    transferAmount:"",
    transferDate:"",
    transferRef:"",
  }
  const[postForm,setPostForm]=useState(FormPostingInitial)
  const getPostInfo = (key) => (e, newValue) => {
    const { value } = e.target;
    setPostForm({
      ...formData,
      [key]: value !== 0 ? value : newValue,
    });
  };

  const FormPostInitial = {
    account: null,
    date: "",
    amount: "",
    reference: "",
    journal: null,
    transferDate: "",
    transferAmount: "",
    transferReferance: "",
  }
  const [formPostData, setformPostData] = useState(FormPostInitial)
  const bank_id = localStorage.getItem("bank_id")
  // onChange
  const getFormInfo = (key) => (e, newValue) => {
    const { value } = e.target;
    setformData({
      ...formData,
      [key]: value !== 0 ? value : newValue,
    });
  };
  console.log(formData);
  // backpage 
  const handleback = () => {
  localStorage.removeItem("bank_id")
    navigate("/userdashboard/accounts/Reconciliation/BankReconciliation")
  }

 

  const handleSwitchChange = () => {
    setSwitchOn(!switchOn);
  };

  // fileUpload funciton
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {

      convertExcelToJson(file)
        .then((data) => {
          setSelectedFile(data)
          setImportData(data)
          let tableHeader = Object.keys(data[0])
          setFileRow(data[0])
          setFileHeaders(tableHeader)
        })

        .catch((error) => console.error('Error converting Excel to JSON:', error));
    }
    setOnSubmit(!!file);
  };
  // cancel button function
  const cancelImportPopup = () => {
    setImportData([])
    setFileRow()
    setFileHeaders()
    setOnSubmit(false);
    setUploadDialog(false)
    setSelectedImportOption({})
  }
  // imported files dropdown selection onChange
  const handleAutocompleteChange = (header, value) => {
    setSelectedImportOption((prevOptions) => ({
      ...prevOptions,
      [header]: value
    }));
  };

  const CancelPostPopup = () => {
    setPostDialog(false)
  }
  // scroll left and right function in import popup 
  const scrollContainer = (scrollAmount) => {

    if (containerRef.current) {
      containerRef.current.scrollLeft += scrollAmount;
    }
  };

  // to remove object with values null
  const filteredObject = Object.fromEntries(
    Object.entries(selectedImportOption).filter(([key, value]) => value !== null)
  );

  const numberOfImportOptions = Object.keys(filteredObject).length;

  // import file submit button
  const filterImportedFile = () => {
    const newFilteredData = importData?.map(item =>
      Object.keys(filteredObject).reduce((acc, key) => {
        acc[filteredObject[key]] = item[key];
        return acc;
      }, {})
    );
    setImportFinalData(newFilteredData);
  };

  // const handleApprovalNumberChange = (e, index) => {
  //   const newValue = e.target.value;
  //   const updatedNotReflectedInBank = [...notReflectedInBank];
  //   updatedNotReflectedInBank[index].approvalNumber = newValue;
  //   setNotReflectedInBank(updatedNotReflectedInBank);
  // };

  const handleDateChange = (e, index) => {
    setNotReflectedInBank((prevNotReflectedInBank) => {
      const updatedNotReflectedInBank = [...prevNotReflectedInBank];
      const updatedItem = { ...updatedNotReflectedInBank[index] };
      updatedItem.bankDate = e.target.value; // Update the 'date' property instead of 'approvalNumber'
      updatedNotReflectedInBank[index] = updatedItem;
      return updatedNotReflectedInBank;
    });
  };
  
  const handleApprovalNumberChange = (e, index) => {
    setNotReflectedInBank((prevNotReflectedInBank) => {
      const updatedNotReflectedInBank = [...prevNotReflectedInBank];
      const updatedItem = { ...updatedNotReflectedInBank[index] };
      updatedItem.approvalNumber = Number(e.target.value);
      updatedNotReflectedInBank[index] = updatedItem;
      return updatedNotReflectedInBank;
    });
  };

  const handleEdit =()=>{
    if(formData.isEdit===true){
      
    setformData({ ...formData, isEdit:false })
    setIsEditClicked(true);}
    
    else{
      let body={
        _id:singleView._id,
        notReflectedInBank:notReflectedInBank,
      }
       BankReconciliationEditAPI(body,snackBarStates,setLoadingForm)
      console.log(body);
      setformData({ ...formData, isEdit:true })
      setIsEditClicked(false);
    }
  }
  console.log(notReflectedInBank);
  const handleSave = () => {

    let savefunction = () => {
      setformData({ ...formData, status: "Drafted" })
    }

    let payload = {
      reference: formData?.Reference,
      journalId: formData?.journal?._id,
      date: formData?.Date,
      closingBalance: tableList?.balance,
      bankBalance: formData?.BankBalance,
      reconcileTable: tableList[0].list,
    }

    BankReconciliationSaveAPI(payload, setSnackBarStates, setLoadingForm, savefunction)
  }

  const handleUpdate = () => {
    let payload = {
      _id: reconciliationSave?._id || singleView?._id,
      table: importFinalDataForBackend,

    }
    let postFunction =()=>{
      setUploadDialog(false)
      setformData({...formData,status:"In-progress"})
    }
    BankReconciliationUpdateAPI(payload,setSnackBarStates,setLoadingForm, setNotReflectedInCompanyBook, setNotReflectedInBank,postFunction)
    
  }
useEffect(()=>{
  if(singleView===undefined){
    setFirstTable({...tableList})
  }
},[tableList])
  useEffect(() => {
    if (bank_id !== null) {
      BankReconciliationSingleListAPI({ _id: bank_id })
    }
  }, [bank_id])
  // useEffects
  useEffect(() => {
    journalListApiCall();
  }, [])

  // api call onChange of journal and date
  useEffect(() => {
    let body = {
      journalId: formData?.journal?._id,
      date: `${formData.Date} ${getCurrentTime()}`
    }
    if (formData.journal !== null && formData.Date !== "") {
      BankReconciliationTableAPI(body)
    }
  }, [formData.journal, formData.Date])

  // import popup submit filtering table
  useEffect(() => {
    const objValues = Object.values(selectedImportOption);
    const filteredOptions = predefinedOptionsInitial.filter(option => !objValues.includes(option));
    setPredefinedOptions(filteredOptions);
  }, [selectedImportOption]);

  useEffect(() => {
    importFinalData !== undefined &&
      setImportFinalDataForBackend(
        importFinalData?.map((obj) => {
          return {
            approvalNumber: obj['Approval No'],
            amount: obj['Transaction Amount'],
            date: obj['Transaction Date'],
            bankDate: obj['Bank Date '],
            bankCharge: obj['Bank charge'],
            bankBalance: obj['Bank Amount']
          };
        })
      );
  }, [importFinalData])

  const compute =()=>{
    let body={
      journalId: formData?.journal?._id,
      _id:singleView?._id,
    }
    BankReconciliationComputeAPI(body,setSnackBarStates,setLoadingForm)
  }
  const Match =()=>{
    let body={
      // notReflectedInCompanyBook:notReflectedInCompanyBook,
      _id:singleView?._id,
    }
    BankReconciliationMatchAPI(body,setSnackBarStates,setLoadingForm)
  }

  
  useEffect(() => {
    if (singleView !== undefined) {
      setformData({
        ...formData,
        Reference: singleView?.reference,
        journal: filterObjFromList("_id", journalList, "journalId", singleView),
        Date: singleView?.date,
        closingBalance: "",
        BankBalance: singleView.bankBalance,
        systemClosingBalance: singleView.systemClosingBalance,
        notReflectedInCompany: singleView.notReflectedInCompanytotal,
        notReflectedInBank: singleView.notReflectedInBanktotal,
        bankCharge: singleView.bankChargeTotal,
        closingCompanyBalance: singleView.closingCompanyBookTotal,
        status: singleView.status,
        isEdit: true,
      });
      setFirstTable(singleView?.reconcileTable || [])
      setNotReflectedInCompanyBook(singleView?.notReflectedInCompanyBook || [])
      setNotReflectedInBank(singleView?.notReflectedInBank || [])
    }
  }, [singleView, journalList])


  return (
    <div className="global-page-parent-container parent-container-log">
      < div className="global-white-bg-container">
        <div className='new-global-white-bg-icon-container'>
          <div className="create-button-blue-container" style={{ margin: "0" }}>

            <Tooltip
              title="Back" onClick={() => handleback()}>
              <IconButton>
                <i class="bi bi-chevron-left"></i>
              </IconButton>
            </Tooltip>

            {(formData.status === "Drafted" || formData.status ==="In-progress")&&(
              <Tooltip title="Edit" onClick={() =>handleEdit()}>
                <IconButton>
                  <i
                    class={formData?.isEdit ? 
                      "bi bi-pencil-square edit-icon1"
                      :
                      "bi bi-check2-square edit-icon"
                    }
                  ></i>
                  {/* <i class="bi bi-check2-square edit-icon"></i> */}
                </IconButton>
              </Tooltip>
           )}

            {formData.status === "" &&
              <Tooltip title="Save">
                <IconButton onClick={handleSave}>
                  <i class="bi bi-floppy save-icon"></i>
                </IconButton>
              </Tooltip>}
            {formData.status === "In-progress" && editClicked===false &&
              <>
                <button className="btn btn-post" onClick={() => setPostDialog(true)}>
                  Post
                </button>
                <button className="btn btn-post" style={{ backgroundColor: "#B080F8" }} onClick={compute} >
                  Compute
                </button>
              </>
            }
          </div>
          {formData.status === "Drafted" && editClicked===false &&(
            <>
              <div className="export-actions-ctn" style={{ float: "inline-end", width: "12%", borderRadius: "3px", marginRight: "33px" }}>
                <div
                 
                  onClick={() => {setUploadDialog(true)}}
                  className="export-actions-section">
                  <p>IMPORT</p>
                  <div className="import" >
                    <img src={importIcon} alt="" />
                  </div>
                </div>
                
              </div>

            </>
          )}
          <div className="post-ribbon">
            {formData?.status === "Drafted" && <img src={draft} alt="" />}
            {formData?.status === "In-progress" && <img src={inprogressRibbon} alt='' />}
          </div>
        </div>
        <hr className="global-hr" />

        <div className="new-global-input-container" style={{ flexWrap: "nowrap", width: "88%" }}>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Refrence"
              variant="outlined"
              type="text"
              focused
              value={formData?.Reference}
              onChange={getFormInfo("Reference")}
              disabled={formData.isEdit ||  formData.status === "In-progress"}
            />
          </div>
          <div className="new-global-single-input auto-complete-new">
            <Autocomplete
              disablePortal
              options={journalList || []}
              getOptionLabel={(option) => option?.journalName}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Journal" focused />
              )}
              value={formData?.journal}
              onChange={getFormInfo("journal")}
              disabled={formData.isEdit || formData.status === "In-progress"}
            />
          </div>

          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Date*"
              variant="outlined"
              type="date"
              focused
              value={formData?.Date}
              onChange={getFormInfo("Date")}
              disabled={formData.isEdit ||  formData.status === "In-progress"}
            />
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Closing Balance"
              variant="outlined"
              type="Number"
              focused

              value={tableList?.balance || " "}
           
            />
          </div>

          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Bank Balance"
              variant="outlined"
              type="Number"
              focused
              value={formData?.BankBalance}
              onChange={getFormInfo("BankBalance")}
              disabled={formData.isEdit ||  formData.status === "In-progress"}
              
            />
          </div>
        </div>
      </div>

      <div className="global-product-table" style={{ padding: "1%", maxHeight: "18vh", paddingInline: "19px" }}>
        <table >
          <thead>
            <tr>
              <th style={{ borderRight: '1px solid #000' }}>Date</th>
              <th style={{ borderRight: '1px solid #000' }}>Transaction Type</th>
              <th style={{ borderRight: '1px solid #000' }}>Name</th>
              <th style={{ borderRight: '1px solid #000' }}>Approval No</th>
              <th style={{ borderRight: '1px solid #000' }}>Amount</th>
              <th style={{ borderRight: '1px solid #000' }}>Charge</th>
              <th style={{ borderRight: '1px solid #000' }}>Bank Date</th>
              <th style={{ borderRight: '1px solid #000' }}>Bank Charge</th>
              <th style={{ borderRight: '1px solid #000' }}>Bank Balance</th>
              <th >Status</th>
              <th style={{ backgroundColor: "#fff" }}></th>
            </tr>
          </thead>
          <tbody>
            {firstTable?.length !== 0 ? firstTable[0]?.list?.map((item, index) => (
              <tr key={index}>
                <td>{item.date}</td>
                <td>{item.transactionType}</td>
                <td>{item.name}</td>
                <td>{item.approvalNumber}</td>
                <td>{item.amount}</td>
                <td>{item.charge}</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td className='table-data-box' style={{ padding: "5px" }}> {item?.status === "Pending" ? (
                  <span className='return' style={{ padding: "3px 12px" }}> Pending</span>
                ) :  (
                  <span>{item?.status}</span>
                )}</td>
              </tr>
            )) : (
              <tr>
                <td colSpan={10}>No Data</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="global-product-table" style={{ marginTop: "3px", padding: "1%", maxHeight: "18vh", paddingInline: "19px" }}>
        <p style={{ color: "black", margin: "0 0 4px 0", fontWeight: "700" }}>Transaction not refleced in bank</p>
        <table>
          <thead>
            <tr>
              <th style={{ borderRight: '1px solid #000' }}>Date</th>
              <th style={{ borderRight: '1px solid #000' }}>Transaction Type</th>
              <th style={{ borderRight: '1px solid #000' }}>Name</th>
              <th style={{ borderRight: '1px solid #000' }}>Approval No</th>
              <th style={{ borderRight: '1px solid #000' }}>Amount</th>
              <th style={{ borderRight: '1px solid #000' }}>Charge</th>
              <th style={{ borderRight: '1px solid #000' }}>Bank Date</th>
              <th>Status</th>
              <th style={{ backgroundColor: '#fff' }}></th>
            </tr>
          </thead>
          <tbody>
            {notReflectedInBank?.length !== 0 ? (
              notReflectedInBank?.map((item, index) => (
                <tr key={index}>
                  <td>{item.date}</td>
                  <td>{item.transactionType}</td>
                  <td>{item.name}</td>
                  <td>
                
                  <input
                    type="text"
                    // value={notReflectedInBank[index]?.approvalNumber || item.approvalNumber}
                    value={item.approvalNumber}
                    onChange={(e) => handleApprovalNumberChange(e, index)}
                    disabled={formData.isEdit || formData.status !== "In-progress"}
                    />
               
                
              </td>
                  <td>{item.amount}</td>
                  <td>{item.charge}</td>
                  <td>
                
                  <input
                    type="date"
                    // value={notReflectedInBank[index]?.approvalNumber || item.approvalNumber}
                    value={item.bankDate}
                    onChange={(e) => handleDateChange(e, index)}
                    disabled={formData.isEdit || formData.status !== "In-progress"}
                    />
               
                
              </td>
                  <td className='table-data-box' style={{ padding: "5px" }}> {item?.status === "Pending" ? (
                  <span className='return' style={{ padding: "3px 12px" }}> Pending</span>
                ) :  (
                  <span>{item?.status}</span>
                )}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={10}>No Data</td>
              </tr>
            )}
          </tbody>
        </table>
      
      </div>
      {formData.status === "In-progress" && editClicked===false &&
          <div style={{ float: "inline-end", margin: "4px 28px 0 6px", alignSelf:"end" }}>
            <button className="btn btn-post" style={{ backgroundColor: "#FF8A30" }} onClick={Match}>
              Match
            </button>
          </div>
        }
      <div className="global-product-table" style={{ marginTop: "3px", padding: "1%", maxHeight: "15vh", paddingInline: "19px" }}>
        <p style={{ color: "black", margin: "0 0 4px 0", fontWeight: "700" }}>Transaction not refleced in company book</p>
        <table >
          <thead>
            <tr>
              <th style={{ borderRight: '1px solid #000' }}>Date</th>
              <th style={{ borderRight: '1px solid #000' }}>Transaction Type</th>
              <th style={{ borderRight: '1px solid #000' }}>Name</th>
              <th style={{ borderRight: '1px solid #000' }}>Approval No</th>
              <th style={{ borderRight: '1px solid #000' }}>Amount</th>
              <th style={{ borderRight: '1px solid #000' }}>Charge</th>
              <th style={{ borderRight: '1px solid #000' }}>Bank Date</th>
              <th style={{ borderRight: '1px solid #000' }}>Bank Charge</th>
              <th style={{ borderRight: '1px solid #000' }}>Bank Balance</th>
              <th >Status</th>
              <th style={{ backgroundColor: "#fff" }}></th>
            </tr>
          </thead>
          <tbody>
            {notReflectedInCompanyBook?.length !== 0 ? (notReflectedInCompanyBook?.map((item, index) => (
              <tr key={index}>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>{item.approvalNumber}</td>
                <td>{item.amount}</td>
                <td>--</td>
                <td>{item.bankDate}</td>
                <td>{item.bankCharge}</td>
                <td>{item.bankBalance}</td>
                <td className='table-data-box' style={{ padding: "5px" }}> {item?.status === "Pending" ? (
                  <span className='return' style={{ padding: "3px 12px" }}> Pending</span>
                ) :  (
                  <span>{item?.status}</span>
                )}</td>
              </tr>
            ))) : (
              <tr>
                <td colSpan={10}>No data</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className='global-div-table-total'>
        <p style={{ paddingLeft: "45px" }}>System closing balance</p>
        <p style={{ paddingRight: "155px" }}>{formData?.systemClosingBalance}</p>
      </div>

      <div className='global-div-table-total' style={{ margin: "5px 0 0 0" }}>
        <p style={{ paddingLeft: "45px" }}>Not Reflected in company</p>
        <p style={{ paddingRight: "155px" }}>{formData?.notReflectedInCompany}</p>
      </div>

      <div className='global-div-table-total' style={{ margin: "5px 0 0 0" }}>
        <p style={{ paddingLeft: "45px" }}>Not Reflected in bank</p>
        <p style={{ paddingRight: "155px" }}>{formData?.notReflectedInBank}</p>
      </div>

      <div className='global-div-table-total' style={{ margin: "5px 0 0 0" }}>
        <p style={{ paddingLeft: "45px" }}>Bank charge</p>
        <p style={{ paddingRight: "155px" }}>{formData?.bankCharge}</p>
      </div>

      <div className='global-div-table-total'>
        <p style={{ paddingLeft: "45px" }}>Closing Company Book</p>
        <p style={{ paddingRight: "155px" }}>{formData?.closingCompanyBalance}</p>
      </div>

      <Dialog
        open={uploadDialog}
        onClose={()=>setUploadDialog(false)}
        maxWidth="auto">
         
        <div
          className="shift-end-button-password-container"
          style={{ width: "70vw", padding: "0" }}
        >
          <div className='headline-import-file'>
            <p>IMPORT FILE</p>

            <div className='headline-import-file-side-div'>
              <div className='uploading-div'>
                <input
                  // disabled={formValues?.isEdit} 
                  type="file"
                  id="fileUpload"
                  accept=".xlsx"
                  style={{ display: 'none' }}
                  onChange={(e) => handleFileChange(e)}
                />
                <label htmlFor="fileUpload" style={{ paddingLeft: "10px", fontSize: "smaller" }}>

                  Upload File

                </label>
                <button><CloudUploadSharpIcon sx={{ color: "white" }} /></button>
              </div>



              <button className='submit-btn'
                style={{ cursor: "pointer", backgroundColor: numberOfImportOptions === 6 && "blue" }}
                onClick={filterImportedFile}
              // disabled={!(numberOfImportOptions===6)}
              >submit</button>
            </div>
          </div>
          <div style={{ borderBottom: '1px dashed #a5a2a2', margin: '10px 0' }}></div>

          {onSubmit === false && (
            <div className='image-div'>
              <img src={upload} alt=" " ></img>
              <p>File upload</p>
            </div>
          )}
          {onSubmit === true && (
            <div className='image-detail-container'>
              <div className='image-head-name'>
                <p>import form</p>
              </div>
              <div
                style={{ position: " absolute", top: "171px" }}
              >
                <button
                  className="left-second-div-arrow" style={{ height: "51px", borderRadius: "0", backgroundColor: " #e5e0e0", padding: "0", top: "24px", zIndex: "40" }}
                  onClick={() => scrollContainer(-150)}
                >
                  <ArrowBackIosNewIcon sx={{ color: "gray" }} />
                </button>
              </div>
              <div className='drop-down-div'>
                <div className='scroll-div' ref={containerRef} style={{ display: "flex", overflowX: "scroll", scrollBehavior: "smooth", gap: "7px" }}>
                  {fileHeaders?.map((r, i) => (
                    <div key={i} className='global-single-input auto-complete'>
                      <p style={{ fontSize: "17px", fontWeight: "300", width: 'max-content' }}>{r}</p>

                      <Autocomplete
                        options={predefinedOptions.filter((option) => !selectedImportOption[r]?.includes(option))}
                        getOptionLabel={(option) => option}
                        onChange={(event, value) => handleAutocompleteChange(r, value)}
                        value={selectedImportOption[r] || null}
                        renderInput={(params) => <TextField {...params} />}
                        sx={{ width: '162px', border: '1px solid', borderRadius: '0px' }}
                      />
                      <div className='sub-autocomplete'>
                        {fileRow[r] === " " ? "No Data" : fileRow[r]}
                      </div>
                    </div>
                  ))
                  }
                </div>
              </div>
              <div
                style={{ position: " absolute", right: "24px", top: "171px" }}
              >
                <button
                  className="left-second-div-arrow" style={{ height: "51px", borderRadius: "0", backgroundColor: " #e5e0e0", padding: "0", top: "24px" }}
                  onClick={() => scrollContainer(150)}
                >
                  <ArrowForwardIosIcon sx={{ color: "gray" }} />
                </button>
              </div>
              {
                importFinalData.length !== 0 &&
                <>

                  <div className='global-product-table' style={{ padding: "15px" }}>
                    <table>
                      <thead>
                        <tr>

                          <th>Transaction Date</th>
                          <th>Transaction Amount</th>
                          <th>Bank Amount</th>
                          <th>Bank Charge</th>
                          <th>Approval No</th>
                          <th>Bank Date</th>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          importFinalData?.map((r, i) => (
                            <tr key={i}>
                              <td>{r["Transaction Date"]}</td>
                              <td>{r["Transaction Amount"]}</td>
                              <td>{r["Bank Amount"]}</td>
                              <td>{r["Bank charge"]}</td>
                              <td>{r["Approval No"]}</td>
                              <td>{r["Bank Date"]}</td>
                            </tr>
                          ))
                        }
                      </tbody>

                    </table>
                  </div>

                  <div className='justify-center' style={{ margin: "10px 0" }}>
                    <button
                      onClick={cancelImportPopup}
                      className="btn btn-secondary-outlined">
                      CANCEL
                    </button>
                    <button className="btn btn-primary" onClick={handleUpdate}  >
                      POST
                    </button>
                  </div>
                </>
              }
            </div>
          )}


        </div>


      </Dialog>
      <Dialog open={postDialog} maxWidth="auto" >
        <div style={{ width: "40vw", padding: "18px" }}>
          <div>
            <p style={{ fontWeight: "600" }}>BANK CHARGE DEFERENCE SETUP</p>
          </div>

          <div className='bank-charge-div'>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="new-global-single-input" style={{ width: "45%" }}>
                <Autocomplete
                  disablePortal
                  options={journalList || []}
                  getOptionLabel={(option) => option?.journalName}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="ACCOUNT" focused />
                  )}
                  // value={formData?.journal}
                  //onChange={getFormInfo("journal")}
                  disabled={false}
                />
              </div>

              <div className="new-global-single-input" style={{ width: "45%" }}>
                <TextField

                  id="outlined-basic"
                  label="DATE*"
                  variant="outlined"
                  type="date"
                  focused
                  value={postForm?.date}
                  onChange={getPostInfo("date")}
                 
                />
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="new-global-single-input" style={{ width: "45%" }}>
                <TextField
                  id="outlined-basic"
                  label="AMOUNT"
                  variant="outlined"
                  type="Number"
                  focused
                  value={postForm?.amount}
                  onChange={getPostInfo("transferAmount")}
                //  onChange={getFormInfo("closingBalance")}
                />
              </div>
              <div className="new-global-single-input" style={{ width: "45%" }}>
                <TextField
                  id="outlined-basic"
                  label="REF"
                  variant="outlined"
                  type="text"
                  focused
                  value={postForm?.ref}
                  onChange={getPostInfo("reference")}
                />
              </div>
            </div>
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ fontWeight: "600" }}>AMOUNT TRANSFER</p>
              <Switch
                checked={switchOn}
                onChange={handleSwitchChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div> */}
            {/* {switchOn && (
              <div className='amount-transfer-div'>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="new-global-single-input" style={{ width: "45%" }}>
                    <Autocomplete
                      disablePortal
                      options={journalList || []}
                      getOptionLabel={(option) => option?.journalName}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="JOURNAL" focused />
                      )}
                      // value={formData?.journal}
                      //onChange={getFormInfo("journal")}
                      disabled={false}
                    />
                  </div>

                  <div className="new-global-single-input" style={{ width: "45%" }}>
                    <TextField

                      id="outlined-basic"
                      label="DATE*"
                      variant="outlined"
                      type="date"
                      focused
                      value={postForm?.transferDate}
                      onChange={getPostInfo("transferDate")}
                      disabled={false}
                    />
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="new-global-single-input" style={{ width: "45%" }}>
                    <TextField
                      id="outlined-basic"
                      label="AMOUNT"
                      variant="outlined"
                      type="Number"
                      focused
                      value={postForm?.transferAmount}
                      onChange={getPostInfo("amount")}
                    //  onChange={getFormInfo("closingBalance")}
                    />
                  </div>

                  <div className="new-global-single-input" style={{ width: "45%" }}>
                    <TextField
                      id="outlined-basic"
                      label="REF"
                      variant="outlined"
                      type="text"
                      focused
                      value={postForm?.transferRef}
                      onChange={getPostInfo("transferReference")}
                    />
                  </div>
                </div>
              </div>
            )} */}
          </div>

          <div className='justify-center' style={{ margin: "10px 0" }}>
            <button
              onClick={CancelPostPopup}
              className="btn btn-secondary-outlined">
              CANCEL
            </button>
            <button className="btn btn-primary">
              POST
            </button>
          </div>
        </div>
      </Dialog>
      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <LoadingForm loading={loadingForm} />
    </div>

  )
}
