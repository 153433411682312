import axios from "axios"
import store from "../../../../../Redux/store"
import {invoice_margin_report_single_list,invoice_margin_report_list, Bank_and_cash_list, Bank_and_cash_single_list,product_margin_report_list,product_margin_report_single_list, customer_receipt_report_list, customer_receipt_report_single_list } from "./GeneralReportSlice"
import { HEADERS } from "../../../../../API/UrlAndPaths"

export const BankAndCashReportAPIcall=async(body)=>{
    await axios
    .post("account/bankAndCashReport",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(Bank_and_cash_list({BankAndCashData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(Bank_and_cash_list({BankAndCashData:undefined}))
        console.error(err.response);
    })
}

export const ViewProductMarginReportAPIcall=async(body)=>{
    await axios
    .post("account/productMarginReport",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(product_margin_report_list({ProductMarginReportData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(product_margin_report_list({ProductMarginReportData:undefined}))
        console.error(err.response);
    })
}
export const ViewInvoiceMarginReportAPIcall=async(body)=>{
    await axios
    .post("account/invoiceMarginReport",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(invoice_margin_report_list({InvoiceMarginReportData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(invoice_margin_report_list({InvoiceMarginReportData:undefined}))
        console.error(err.response);
    })
}
export const ProductMarginReportSingleViewAPIcall=async(body)=>{
    await axios
    .post("account/productMarginReportById",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(product_margin_report_single_list({ProductMarginReportDataSingle:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(product_margin_report_single_list({ProductMarginReportDataSingle:undefined}))
        console.error(err.response);
    })
}
export const InvoiceMarginReportSingleViewAPIcall=async(body)=>{
    await axios
    .post("account/invoiceMarginReportById",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(invoice_margin_report_single_list({InvoiceMarginReportDataSingle:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(invoice_margin_report_single_list({InvoiceMarginReportDataSingle:undefined}))
        console.error(err.response);
    })
}

export const BankAndCashReportSingleAPIcall=async(body)=>{
    await axios
    .post("account/bankAndCashReportById",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(Bank_and_cash_single_list({BankAndCashDataSingle:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(Bank_and_cash_single_list({BankAndCashDataSingle:undefined}))
        console.error(err.response);
    })
}
export const CustomerReceiptReportAPIcall=async(body)=>{
    await axios
    .post("account/customerReceiptReport",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(customer_receipt_report_list({customerReceiptReportList:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(customer_receipt_report_list({customerReceiptReportList:undefined}))
        console.error(err.response);
    })
}
export const CustomerReceiptReportSingleViewAPIcall=async(body)=>{
    await axios
    .post("account/customerReceiptReportById",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(customer_receipt_report_single_list({customerReceiptReportSingleList:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(customer_receipt_report_single_list({customerReceiptReportSingleList:undefined}))
        console.error(err.response);
    })
}