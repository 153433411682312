import { fontWeight } from "@mui/system";

export let dashboardStyle = {
    textDecoration:"none",
    color: "#0bce8c",
    fontWeight:"700",
    
};
export let productListStyle ={
    textDecoration:'none',
    color:'#870bce',
    fontWeight:600,
}
export let catalogStyle={
    textDecoration:"none",
    color:"#9a00ff",
    fontWeight:600
}
export let productMasterStyle={
    textDecoration:'none',
    color:'#910083',
    fontWeight:600,
}
export let purchaseManageStyle={
    textDecoration:'none',
    color:'#910000',
    fontWeight:600,
}
export let purchaseManageSubStyle={
    textDecoration:'none',
    color:'#910000',
    fontWeight:600,
}
export let ordersStyle={
    textDecoration:'none',
    color:'#00914d',
    fontWeight:600
}
export let salesStyle={
    textDecoration:'none',
    color:'#fc6107',
    fontWeight:600
}
export let customerStyle={
    textDecoration:"none",
    color:'#fc0707',
    fontWeight:600
}
export let expenseStyle={
    textDecoration:"none",
    color:'#e5d709',
    fontWeight:600
}
export let rewardStyle={
    textDecoration:"none",
    color:'#ce590b',
    fontWeight:600
    
}
export let offerStyle={
    textDecoration:"none",
    color:'#ce0b0b',
    fontWeight:600
}
export let walletStyle={
    textDecoration:"none",
    color:'#af0bce',
    fontWeight:600
}
export let reportStyle={
    textDecoration:"none",
    color:'#cebb0b',
    fontWeight:600
}
export let accountsStyle={
    textDecoration:"none",
    color:'#01818f',
    fontWeight:600
}
export let payrollNewStyle={
    textDecoration:"none",
    color:'#0d9426',
    fontWeight:600
}
export let staffStyle={
    textDecoration:"none",
    color:'#6d1b7b',
    fontWeight:600
}
export let settingsStyle={
    textDecoration:"none",
    color:'#b23c17',
    fontWeight:600
}
export let qrCodeStyle={
    textDecoration:"none",
    color:'#14a37f',
    fontWeight:600
}


export let deviceStyle ={
    textDecoration:"none",
    color:"#b23c17",
    fontWeight:600

}

export let inventorySubStyle={
    textDecoration:'none',
    color:'#ef7000',
    fontWeight:600,
    marginLeft:"22px"
    
}
export let inventorySubcloseStyle={
    textDecoration:'none',
    color:'#000',
    fontWeight:600,
    marginLeft:"22px"
    
}
