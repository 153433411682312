import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Dialog, Icon, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import uploadImage from '../../../Assets/Images/upload-image.png'
import { useSelector } from 'react-redux';

import { addBrancdAPICall, addCatalogParts, addManufYearAPICall, addModelAPICall, addToProductAPICall, validateProductsForAddingAPICall, viewBrandAPICall, viewCatalogPartsAPICall, viewManufYearAPICall, viewModelAPICall } from './catalogCategoryAPI';
import { viewAllReadymadeBrandListAPICall, viewAllReadymadeBrandModelListAPICall, viewManufYearListAPICall } from '../../../API/Product Master/ProductMasterAPI';
import { viewUOMSubListAPICall } from '../Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentAPICall';
import SuccessSnackbar from '../../Single Components/SnackBars/SuccessSnackbar';
import ErrorSnackbar from '../../Single Components/SnackBars/ErrorSnackbar';
import { filterObjFromList } from '../../../Js/generalFunctions';
import { LoadingForm } from '../../Single Components/LoadingForm';

export const Parts = () => {
    const navigateTo=useNavigate()
    const catId=localStorage.getItem("catId")
    const catname=localStorage.getItem("catName")
    const subCatId=localStorage.getItem("subCatId")
    const subCatname=localStorage.getItem("subCatName")
     const partsList=useSelector((state)=>state.catalogCategorySlice.parts)
     //***** admin **///
     const brandList=useSelector((state)=>state.catalogCategorySlice.brand)
     const modelList=useSelector((state)=>state.catalogCategorySlice.model)
     const yearList = useSelector((state)=>state.catalogCategorySlice.year)
    ///** user */
    const allBrandList = useSelector((state) => state.readymadeBrandSlice.brandlist);
    const allBrandModelList = useSelector((state) => state.readymadeBrandSlice.modellist);
    const allManufYearList = useSelector((state) => state.readymadeBrandSlice.manufYear);
    const uomSubList = useSelector((state) => state.unitOfMeasurmentSlice.subUomList);


    console.log(brandList);

     const [createParts,setcreateParts]=useState(false)
     const [addBrand,setAddBrand]=useState(false)
     const [addModel,setAddModel]=useState(false)
     const [addYear,setAddYear]=useState(false)
     const [uploadedImage,setUploadedImage]=useState()
     const [imageUrl,setImageUrl]=useState()
     const [loadingForm,setLoadingForm]=useState(false)
     const [isModify,setIsModify]=useState(false)
     const [filtererdModellist,setFilteredModelList]=useState([])
     const [addToProductPopup,setAddToProductPopup]=useState(false)
     const [partIds,setPartsIds]=useState([])
     const [addToCartArray,setAddToCartArray]=useState([])
     const [filteredModelListForProduct,setFilteredModelListForProduct]=useState([])
     const [confirmDialog,setConfirmDialog]=useState(false)
     const [tableDataForBackend,setTableDataForBackend]=useState(false)
     const formValuesInitialState={
         partName:"",
         partNo:"",
         brand:null,
         model:null,
         year:null
     }
     const [formValues,setFormValues]=useState(formValuesInitialState)
     const [brandName,setBrandName]=useState("")
     const addModelFormInitial={
        brandId:null,
        modelName:""
     }
     const [addModelForm,setAddModelForm]=useState(addModelFormInitial)
     const [yearname,setYearName]=useState('')
     const [searchInput,setSearchInput]=useState('')
     const filterFormInitial={
        brand:null,
        model:null,
        year:null
     }
   
     const [filterForm,setFilterForm]=useState(filterFormInitial)
     const [snackBarStates, setSnackBarStates] = useState({
      success: false,
      error: false,
      message: "",
      alert: false
  });
  const [validationMessages, setValidationMessages] = useState({});
  const closeSnackbar = () => {
    setSnackBarStates({
        ...snackBarStates,
        success: false,
        error: false,
        alert: false,
        message: "",
    });
};
     const updateListner=()=>{
        setIsModify(!isModify)
     }
    const backToSubcat=()=>{
        localStorage.removeItem("subCatId")
        localStorage.removeItem("subCatName")
        navigateTo("/userdashboard/catalog/subCategory")
    }
    const backToCat=()=>{
        localStorage.removeItem("catId")
        localStorage.removeItem("catName")
        localStorage.removeItem("subCatId")
        localStorage.removeItem("subCatName")
        navigateTo("/userdashboard/catalog")
    }

    const imageUploadFn=(e)=>{
        setUploadedImage(e.target.files[0])
        setImageUrl(URL.createObjectURL(e.target.files[0]));

      }
      const removeImage=()=>{
        setImageUrl(undefined)
        setUploadedImage(undefined)

      }
    // useEffect(()=>{
    //     let image = undefined;
    //     if(typeof uploadedImage ==="string"){
    //       image = uploadedImage
    //     }
    //     else if(uploadedImage !== undefined && uploadedImage !== null){
    //       image=URL.createObjectURL(uploadedImage)
    //     }
    //     setImageUrl(image)
    // },[uploadedImage])

    const getFormValues=(key)=>(e,newValue)=>{
        const { value } = e.target;
        
        setFormValues({ ...formValues, [key]: value !== 0 ? value : newValue });
    }

    const saveBrandFunction=()=>{ 
        addBrancdAPICall({name:brandName},updateListner)

    }
    const saveModelFunction=()=>{
        addModelAPICall({
            name:addModelForm?.modelName,
            brandId:addModelForm?.brandId?._id
        },updateListner)
    }
    const saveYearFunction=()=>{
       addManufYearAPICall({name:yearname},updateListner)

    }

    const clearState=()=>{
      setFormValues(formValuesInitialState)
    }

    const formData=new FormData()
    formData.append("partName",formValues?.partName)
    formData.append("partNo",formValues?.partNo)
    formData.append("file",uploadedImage)
    formData.append("brandId",formValues?.brand?._id)
    formData.append("modelId",formValues?.model?._id)
    formData.append("manufacYearId",formValues?.year?._id)
    formData.append("categoryId",catId)
    formData.append("subCategoryId",subCatId)

    const saveFunction=()=>{
        addCatalogParts(formData,updateListner,clearState)
    }

    useEffect(()=>{
      // if(filterForm?.brand !== null){
        setFilteredModelList(modelList?.filter((obj)=>{
          if(obj?.brandId===filterForm?.brand?._id){
              return obj
          }
         
         
      }))
      // }
      // else{
      //   setFilteredModelList(modelList?.map((obj)=>obj))
      // }
      
    },[filterForm?.brand])
    const searchFunction=()=>{
      viewCatalogPartsAPICall({
        categoryId:catId,
        subCategoryId:subCatId,
        search:searchInput
    })
    }

    const searchButtonFunction=()=>{
      viewCatalogPartsAPICall({
        categoryId:catId,
        subCategoryId:subCatId,
        brandId:filterForm?.brand?._id,
        modelId:filterForm?.model?._id,
        manufacYearId:filterForm?.year?._id
      
    })
    }

    const checkParts=(e)=>{
      const rowId=e.target.value
      if(partIds.includes(rowId)){
        setPartsIds((pre)=>pre.filter((id)=>id !==rowId))
      }
      else{
        setPartsIds((prev)=>[...prev,rowId])
      }
    }
    const checkAllFunction=(e)=>{
        const isChecked=e.target.checked;
        if(isChecked){
          setPartsIds(partsList?.map((r)=>r?._id))
        }
        else{
          setPartsIds([])
        }
    }

  
    // add to product function
    const addToProductBtnFn=()=>{
      if(partIds?.length !==0){
        setAddToProductPopup(true)
      
      }
      else{
        alert("Select part")
      }
     
    }

    useEffect(()=>{
         let addToVartArray=[]
         addToVartArray=partsList?.filter(item => partIds?.includes(item?._id));
         setAddToCartArray(addToVartArray?.map((obj)=>{
          return{
            ...obj,
            brand:null,
            model:null,
            uom:null
          }
         }))
    },[partIds,partsList,allBrandList,filteredModelListForProduct,addToProductPopup])




    const handleProductChange=(i,key)=>(e,newValue)=>{
      const updatedData = addToCartArray.map((row, index) => {
        if (index === i) {
            if(key==="brand"){
              const modelList= newValue?._id !== undefined ? allBrandModelList?.filter((opt)=>opt?.brandId===newValue?._id):allBrandModelList
              filteredModelListForProduct[i]=modelList;
              return { ...row, [key]: newValue };
            }
            else{
              return { ...row, [key]: newValue };

            }
          
        }
        return row;
      });
      setAddToCartArray(updatedData)
    }
   

    const delectAddToProduct=(index)=>()=>{
      const updatedTableData = [...addToCartArray];
      updatedTableData.splice(index, 1);
      setAddToCartArray(updatedTableData);
      
    }

    const closeProductPopup=()=>{
      setAddToProductPopup(false)
    }

    console.log(partIds);
    console.log(addToCartArray);

    const bodyToApi={
      productInfo:tableDataForBackend
    }
    const validateButtonFunction=()=>{
      const newValidationMessages = {};

        addToCartArray.forEach((product, index) => {
            if (product.brand === null) {
                newValidationMessages[index] = {
                    ...newValidationMessages[index],
                    brand: "Brand is required"
                };
            }
             if (product.model === null) {
                newValidationMessages[index] = {
                    ...newValidationMessages[index],
                    model: "Model is required"
                };
            }
            if (product.uom === null) {
              newValidationMessages[index] = {
                ...newValidationMessages[index],
                uom: "uom is required"
            };
            }
            
        });

        setValidationMessages(newValidationMessages);
         // Check if there are any validation messages
         const hasValidationErrors = Object.keys(newValidationMessages).length > 0;

         // If there are no validation messages, call the API
         if (!hasValidationErrors) {
             // Call your API here
             validateProductsForAddingAPICall(bodyToApi,setConfirmDialog,setSnackBarStates,snackBarStates,setLoadingForm)
         }
    
      // validateProductsForAddingAPICall(bodyToApi,setConfirmDialog,setSnackBarStates,snackBarStates)
      // setConfirmDialog(true)
    }

    const confirmBtnFn=()=>{
      addToProductAPICall(bodyToApi,setSnackBarStates,snackBarStates,setConfirmDialog)
    }

    useEffect(()=>{
      setTableDataForBackend(addToCartArray?.map((obj)=>{
        return {
          name:obj?.partName,
          partNo:obj?.partNo,
          mainCatgeoryId:obj?.catName,
          poscat:obj?.subCatName,
          manufactureYearId:obj?.manufacYearId,
          imageUrl:obj?.imageUrl,
          uom:obj?.uom?._id,
          purchaseuom:obj?.uom?._id,
          brand:obj?.brand?._id,
          brandModelId:obj?.model?._id
        }
      }))
    },[addToCartArray])

    console.log(addToCartArray);

    useEffect(()=>{
        viewCatalogPartsAPICall({
            categoryId:catId,
            subCategoryId:subCatId
        })
        viewBrandAPICall()
        viewModelAPICall()
        viewManufYearAPICall()
   
    },[isModify])

    useEffect(()=>{
      viewAllReadymadeBrandListAPICall()
      viewAllReadymadeBrandModelListAPICall()
      viewManufYearListAPICall()
      viewUOMSubListAPICall()
    },[])

  

     
    useEffect(()=>{
      searchFunction()
    },[searchInput])
   
  return (
    <>
    <div >
        <div className='catelog-header-container'>
            <p onClick={backToCat} style={{cursor:"pointer"}}>Catalog</p>
            <p><i class="bi bi-chevron-right"></i></p>
            <p onClick={backToSubcat} style={{cursor:"pointer"}}>{catname}</p>
            <p><i class="bi bi-chevron-right"></i></p>
            <p >{subCatname}</p>
        </div>
        <div className='catelog-heading-button'>
            <p> Parts </p>
            <div style={{width:"65%"}} className='catelog-buttom-container'>
           
            <TextField 
            value={searchInput}
            onChange={(e)=>{
              setSearchInput(e.target.value)
             
            }}
            label="Search" 
            color="primary" 
            focused 
            InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                       <IconButton onClick={searchFunction}>
                     <SearchIcon
      className="billing-search-icon"
      style={{ color: "#736ff3" }}
     
    /> 
    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

                {/* <button className='catalog-button export'>EXPORT</button> */}
               {/* <button className='catalog-button import'>IMPORT</button> */}
                {/* <button onClick={()=>setcreateParts(true)} className='catalog-button create'>Create</button> */}
            </div>
        </div>
        <div className='catelog-search-container'>
                  <div className='new-global-single-input' style={{width:"23%"}}>
                <Autocomplete
              sx={{width:"100%"}}
              options={brandList||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params} focused label="Select Brand"/>}
              value={filterForm?.brand}
              onChange={(e,newValue)=>setFilterForm({...filterForm,brand:newValue})}
            />
                  </div>
                  <div className='new-global-single-input' style={{width:"23%"}}>
                <Autocomplete
              sx={{width:"100%"}}
              options={filterForm?.brand !== null?filtererdModellist:modelList||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params} focused label="Select Model"/>}
              value={filterForm?.model}
              onChange={(e,newValue)=>setFilterForm({...filterForm,model:newValue})}
            />
            
                  </div>
                  <div className='new-global-single-input' style={{width:"23%"}}>
                <Autocomplete
              sx={{width:"100%"}}
              options={yearList||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params} focused label="Select Year"/>}
              value={filterForm?.year}
              onChange={(e,newValue)=>setFilterForm({...filterForm,year:newValue})}
           />
             

                  </div>
                  <button onClick={searchButtonFunction} className='catalog-button create' style={{width:"18%"}}>SEARCH</button>
        </div>

         <button onClick={addToProductBtnFn} className='catalog-button create' style={{width:"13%",height:"30px",margin:"1%"}}>Add To Product</button>


        <div className='catalog-table-container'>
            <table>
                <thead>
                    <tr>
                        <th>Image</th>
                        <th>Parts No</th>
                        <th>Name</th>
                        <th>Brand</th>
                        <th>Model</th>
                        <th>Year</th>
                        <th>
                            <input 
                             type="checkbox"
                             onChange={checkAllFunction}
                             checked={partsList?.length !==0 && partIds?.length===(partsList?.map((row) => row._id))?.length}
                             />
                        </th>
                        
                    </tr>
                </thead>
                <tbody>
                    {
                     partsList?.map((r,i)=>{
                      const imageData = [r?.imageUrl[0]?.data];
                      const base64String = imageData;

                      return  <tr>
                        <td>
                              <img src={`data:name;base64,${base64String}`} alt="" style={{width:"50px",height:"50px",borderRadius:"50%",border:"1px solid #000",}} />

                        </td>
                        <td>{r?.partNo}</td>
                        <td>{r?.partName}</td>
                        <td>{r?.brandName}</td>
                        <td>{r?.modelName}</td>
                        <td>{r?.manufactureYear}</td>
                        <td>
                        <input 
                            type="checkbox"
                            value={r?._id}
                            onChange={checkParts}
                            checked={partIds.includes(r?._id)}
                            />
                        </td>
                       
                    </tr>
             })   
                    }
                    
                </tbody>
            </table>
        </div>
       {/* create parts */}
       <Dialog open={createParts} maxWidth="lg">
          <div className='create-category-container' style={{width:"60vw"}}>
             <div style={{padding:"4%"}}>
               <div className='create-category-icon-container'>
            
             <IconButton onClick={()=>setcreateParts(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                   <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                   <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                </svg>
             </IconButton>
              <IconButton onClick={saveFunction}>
               <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="blue" class="bi bi-floppy" viewBox="0 0 16 16">
                      <path d="M11 2H9v3h2z"/>
                      <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
              </svg>
              </IconButton>
               </div>
                <hr />
               <p className='create-category-name'>Create Parts</p>
                <div className='create-category-input-field'>
                  <div className='new-global-single-input' style={{width:"30%"}}>
                   <TextField
                      label="Part Name"
                      focused
                      value={formValues?.partName}
                      onChange={getFormValues('partName')}
                     
                   />
                  </div>
                  <div className='new-global-single-input' style={{width:"30%"}}>
                   <TextField
                      label="Part No"
                      focused
                      value={formValues?.partNo}
                      onChange={getFormValues('partNo')}
                   />
                  </div>
                <div className='global-single-input '>
           
            {
                    imageUrl === undefined &&
                  <div className='image-upload-file-conatiner'>
                  <input type="file" id="upload-image" onChange={imageUploadFn} />
                  <label htmlFor="upload-image">
                    <img src={uploadImage} alt="" />
                  </label>
                  </div>
                  }
                  {
                    imageUrl !== undefined &&
                    <div className='image-preview'>
                        <IconButton className='image-close-icon' onClick={removeImage}>
                           <i class="bi bi-x"></i>
                        </IconButton>
                        <img src={imageUrl} alt="" />
                    </div>
                    
                  }
                   <p>Upload Image</p>
                </div>
                <div className='new-global-single-input' style={{width:"30%"}}>
                <Autocomplete
              sx={{width:"100%"}}
              options={brandList||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params} focused label="Select Brand"/>}
              value={formValues?.brand}
              onChange={getFormValues('brand')}
            />
            <p onClick={()=>setAddBrand(true)} className='add-model'>Add Brand</p>
                  </div>
                  <div className='new-global-single-input' style={{width:"30%"}}>
                <Autocomplete
              sx={{width:"100%"}}
              options={filtererdModellist||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params} focused label="Select Model"/>}
              value={formValues?.model}
              onChange={getFormValues('model')}
            />
              <p onClick={()=>setAddModel(true)} className='add-model'>Add Model</p>
                  </div>
                  <div className='new-global-single-input' style={{width:"30%"}}>
                <Autocomplete
              sx={{width:"100%"}}
              options={yearList||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params} focused label="Select Year"/>}
              value={formValues?.year}
              onChange={getFormValues('year')}
            />
              <p onClick={()=>setAddYear(true)} className='add-model'>Add Year</p>

                  </div>
              </div>
             </div>
           </div>
       </Dialog>
       {/* add brand */}
       <Dialog open={addBrand} maxWidth="lg">
       <div className='create-category-container' style={{width:"25vw"}}>
             <div style={{padding:"4%"}}>
               <div className='create-category-icon-container'>
            
             <IconButton onClick={()=>setAddBrand(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                   <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                   <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                </svg>
             </IconButton>
              <IconButton onClick={saveBrandFunction}>
               <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="blue" class="bi bi-floppy" viewBox="0 0 16 16">
                      <path d="M11 2H9v3h2z"/>
                      <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
              </svg>
              </IconButton>
               </div>
                <hr />
               <p className='create-category-name'>Create Brand</p>
                <div className='create-category-input-field'>
                  <div className='new-global-single-input' style={{width:"95%"}}>
                   <TextField
                      label="Brand Name"
                      focused
                      value={brandName}
                      onChange={(e)=>setBrandName(e.target.value)}
                     
                   />
                  </div>
               
             
                
              </div>
              <div style={{margin:"2% 4%"}}>
                {
                    brandList?.map((r,i)=>(
                        <div style={{display:"flex",justifyContent:"space-between",margin:"2% 1%"}}>
                        <p style={{fontSize:"0.8rem",fontWeight:600}}>{r?.name}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="red" class="bi bi-trash3" viewBox="0 0 16 16">
                             <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                        </svg>
                      </div>
                    ))
                }
           
              </div>
            
             </div>
           </div>
       </Dialog>

        {/* add model */}
        <Dialog open={addModel} maxWidth="lg">
       <div className='create-category-container' style={{width:"25vw"}}>
             <div style={{padding:"4%"}}>
               <div className='create-category-icon-container'>
            
             <IconButton onClick={()=>setAddModel(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                   <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                   <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                </svg>
             </IconButton>
              <IconButton onClick={saveModelFunction}>
               <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="blue" class="bi bi-floppy" viewBox="0 0 16 16">
                      <path d="M11 2H9v3h2z"/>
                      <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
              </svg>
              </IconButton>
               </div>
                <hr />
               <p className='create-category-name'>Create Model</p>
                <div className='create-category-input-field'>
                <div className='new-global-single-input' style={{width:"95%"}}>
                <Autocomplete
              sx={{width:"100%"}}
              options={brandList||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params} focused label="Select Brand"/>}
              value={addModelForm?.brandId}
              onChange={(e,newValue)=>setAddModelForm({...addModelForm,brandId:newValue})}
            />
                  </div>
                  <div className='new-global-single-input' style={{width:"95%"}}>
                   <TextField
                      label="Model Name"
                      focused
                      value={addModelForm?.modelName}
                      onChange={(e)=>setAddModelForm({...addModelForm,modelName:e.target.value})}
                     
                   />
                  </div>
                 
             
                
              </div>
              <div style={{margin:"2% 4%"}}>
                {
                    modelList?.map((r,i)=>(
                        <div style={{display:"flex",justifyContent:"space-between",margin:"4% 2% "}}>
                        <p style={{fontSize:"0.8rem",fontWeight:600}}>{r?.name}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="red" class="bi bi-trash3" viewBox="0 0 16 16">
                             <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                        </svg>
                      </div>
                    ))
                }
             
              </div>
            
             </div>
           </div>
       </Dialog>

        {/* add year */}
        <Dialog open={addYear} maxWidth="lg">
           <div className='create-category-container' style={{width:"25vw"}}>
             <div style={{padding:"4%"}}>
               <div className='create-category-icon-container'>
            
             <IconButton onClick={()=>setAddYear(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                   <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                   <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                </svg>
             </IconButton>
              <IconButton onClick={saveYearFunction}>
               <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="blue" class="bi bi-floppy" viewBox="0 0 16 16">
                      <path d="M11 2H9v3h2z"/>
                      <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
              </svg>
              </IconButton>
               </div>
                <hr />
               <p className='create-category-name'>Create Year</p>
                <div className='create-category-input-field'>
                  <div className='new-global-single-input' style={{width:"95%"}}>
                   <TextField
                      label="Year"
                      focused
                      value={yearname}
                      onChange={(e)=>setYearName(e.target.value)}
                     
                   />
                  </div>
               
             
                
              </div>
              <div style={{margin:"2% 4%"}}>
                {
                    yearList?.map((r,i)=>(
                        <div style={{display:"flex",justifyContent:"space-between",margin:"2% 1%"}}>
                        <p style={{fontSize:"0.8rem",fontWeight:600}}>{r?.name}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="red" class="bi bi-trash3" viewBox="0 0 16 16">
                             <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                        </svg>
                      </div>
                    ))
                }
           
              </div>
            
             </div>
           </div>
       </Dialog>


       {/* add to product */}
       {
      addToProductPopup &&
        //  <div className='add-to-product-pop-up'>
        //     <p>Add To Product</p>   
        //  </div>
        <div className="popup-overlay">
        <div className="popup-catalog-addtoproduct">
            <div className='popup-catalog-addtoproduct-top'>
              <p>Auto Parts</p>
              <div style={{width:"20%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <button  onClick={validateButtonFunction} className='catalog-button create' style={{width:"70%"}}>VALIDATE</button>
              <div onClick={closeProductPopup}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
               <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
             </svg>
              </div>
            
             </div>
            </div>
            <div className='popup-catalog-addtoproduct-second'>
              {
                addToCartArray?.map((r,i)=>{
                  const imageData = [r?.imageUrl[0]?.data];
                  const base64String = imageData;
                return <div className='popup-catalog-addtoproduct-second-single'>
                   <div className='popup-catalog-addtoproduct-second-single-img'>
                   <img src={`data:name;base64,${base64String}`} alt="" style={{width:"70px",height:"70px"}} />
                  </div>
               
                 <div className='popup-catalog-addtoproduct-second-single-content'>
                   <div className='popup-add-to-cart-name'>
                      <p>Name</p>
                      <p style={{fontSize:"0.75rem",fontWeight:500,color:"#000"}}>{r?.partName}</p>
                   </div>
                   <div className='popup-add-to-cart-name'>
                      <p>Brand</p>
                      <p style={{fontSize:"0.75rem",fontWeight:500,color:"#000"}}>{r?.brandName}</p>
                     
                   </div>
                   <div className='popup-add-to-cart-name'>
                      <p>Model</p>
                      <p style={{fontSize:"0.75rem",fontWeight:500,color:"#000"}}>{r?.modelName}</p>

                   </div>
                   <div className='popup-add-to-cart-name'>
                      <p>Part No</p>
                      <p style={{fontSize:"0.75rem",fontWeight:500,color:"#000"}}>{r?.partNo}</p>

                   </div>
                   <div className='popup-add-to-cart-name'>
                      <p>Year</p>
                      <p style={{fontSize:"0.75rem",fontWeight:500,color:"#000"}}>{r?.manufactureYear}</p>

                   </div>
                 </div>
                 <div class="vertical-hr"></div>
                 <div className='popup-catalog-addtoproduct-second-single-drpdown'>
                 <div className='new-global-single-input add-to-cart-drpdown' style={{width:"90%"}}>
                <Autocomplete
                  sx={{width:"100%"}}
                  options={allBrandList||[""]}
                  getOptionLabel={(option)=>option?.name}
                  renderInput={(params)=><TextField {...params} focused label="Brand"/>}
                  value={r?.brand}
                  onChange={handleProductChange(i,"brand")}
                  />
                   {validationMessages[i] && (
                            <div>
                                {validationMessages[i].brand && (
                                    <p className="doc-validation-alert">{validationMessages[i].brand}</p>
                                )}
                               
                            </div>
                        )}
               
             
                  </div>
                  <div className='new-global-single-input add-to-cart-drpdown' style={{width:"90%"}}>
                <Autocomplete
                  sx={{width:"100%"}}
                  options={filteredModelListForProduct[i]||[""]}
                  getOptionLabel={(option)=>option?.name}
                  renderInput={(params)=><TextField {...params} focused label="Model"/>}
                  value={r?.model}
                  onChange={handleProductChange(i,"model")}
                />
               
               {validationMessages[i] && (
                            <div>
                              
                                {validationMessages[i].model && (
                                    <p className="doc-validation-alert">{validationMessages[i].model}</p>
                                )}
                            </div>
                        )}
                  </div>
                  <div className='new-global-single-input add-to-cart-drpdown' style={{width:"90%"}}>
                <Autocomplete
                  sx={{width:"100%"}}
                  options={uomSubList||[""]}
                  getOptionLabel={(option)=>option?.uomName}
                  renderInput={(params)=><TextField {...params} focused label="UOM"/>}
                  value={r?.uom}
                  onChange={handleProductChange(i,"uom")}

                 />
                     {validationMessages[i] && (
                            <div>
                              
                                {validationMessages[i].uom && (
                                    <p className="doc-validation-alert">{validationMessages[i].uom}</p>
                                )}
                            </div>
                        )}
                  </div>
                 </div>
                 <div style={{cursor:"pointer"}} onClick={delectAddToProduct(i)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-trash3" viewBox="0 0 16 16">
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                  </svg>
                 </div>
               </div>
              })
              }
               
            </div>
        </div>
      </div>
     }

     {/* confirm dialog */}
       <Dialog open={confirmDialog} maxWidth="lg">
           <div className='create-category-container' style={{width:"30vw"}}>
             <div style={{padding:"8% 4%",display:"flex",flexDirection:"column",alignItems:"center"}}>
                {/* <div> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="black" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                  </svg>
                {/* </div> */}
                <p style={{fontSize:"0.8rem",fontWeight:600,width:"210px",textAlign:"center",lineHeight:"2"}}>Are you sure to add all products in your product list</p>
                <div  style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}>
                  <button onClick={()=>setConfirmDialog(false)} className='btn btn-secondary-outlined' style={{width:"23%",height:"30px"}}>Cancel</button>
                  <button onClick={confirmBtnFn} className='btn btn-primary ' style={{width:"23%",height:"30px"}}>Confirm</button>
                </div>
             </div>
           </div>
       </Dialog>

       <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
     />
     <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
     />
      <LoadingForm loading={loadingForm}/>
       
    </div>
   
     </>
  )
}
