import axios from "axios";
import { update_jc_single_view } from "../../Redux/Orders/Job completion/jcSingleViewSlice";
import { update_jc_filter_result } from "../../Redux/Orders/Job completion/jobCompletionFilterResSlice";
import { update_order_numbers_for_job_completion } from "../../Redux/Orders/Job completion/orderNosForJobCompletion";
import store from "../../Redux/store";
import { HEADERS } from "../UrlAndPaths";

//Get all orders eligible for work order
export const getAllWorkOrderNosForJobCompletionAPICall = async (body) => {
  await axios
    .post("workorder/getWorkorderNosForJobCompletion",body,HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_order_numbers_for_job_completion({
            orderNosForJobCompletion: res.data,
          })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        update_order_numbers_for_job_completion({
          orderNosForJobCompletion: undefined,
        })
      );
      console.error(err.response);
    });
};

//Job completion filter
export const jobCompletionFilterAPICall = async (body) => {
  await axios
    .post("workorder/viewFilterJobCompletion", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_jc_filter_result({ jcFilterRes: res.data }));
        console.log("JC filter get successfully");
      }
    })
    .catch((err) => {
      store.dispatch(update_jc_filter_result({ jcFilterRes: undefined }));
      console.error(err.response);
    });
};

//Single view Job Completion
export const getSingleViewJobCompletionAPICall = async (body) => {
  await axios
    .post("workorder/singleViewJobCompletion", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_jc_single_view({ jcSingleView: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_jc_single_view({ jcSingleView: undefined }));
      console.error(err.response);
    });
};

//Final Post - jobCompletion
export const finalPostJobCompletionAPICall = async (body,setSuccess,setError,setSnackMsg,clearStates,updateListener) => {
  await axios.post("workorder/jobCompletion", body, HEADERS).then((res)=>{
      if(res.status===200)
      {
        console.log('Job completion posted successfully');
        setSnackMsg('Job Completion updated 👍')
        setSuccess(true)
        clearStates()
        updateListener()
      }
  }).catch((err)=>{
    switch (err.response.status) {
      case 401:
          setSnackMsg('Unauthorized!!')
        break;
      case 409:
          setSnackMsg('Already completed!!')
        break;
      case 403:
          setSnackMsg('Forbidden!!')
        break;
      case 422:
          setSnackMsg(err.response.data?.msg)
        break;
      case 500:
          setSnackMsg('ServerError!!')
        break;
    
      default:
        break;
    }

    setError(true)
    console.error(err.response);
  });
};
