import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { CustomerReceiptReportSingleViewAPIcall } from './GeneralReportAPI';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import { convertDateFormat } from '../../../../../Js/Date';
import { IconButton, Skeleton } from '@mui/material';


const CustomerReceiptSingleViewPrint = () => {
    const [symbol, setSymbol] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const singleViewId = localStorage.getItem("customerReceiptSingleViewId")

    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue)
    const decimalPosition = localStorage.getItem("decimalPosition");

    const SingleListData = useSelector((state) => state?.generalReport?.customerReceiptReportSingleView)

    const totalJournalKeys = SingleListData !== undefined ? Object.keys(SingleListData?.total?.journal) : [];

    useEffect(() => {

        CustomerReceiptReportSingleViewAPIcall({
            _id: singleViewId,

        })
    }, [singleViewId])

    useEffect(() => {
        let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
        setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])
    useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
    }, [])


    return (
        <div>
            <div style={{display:"flex",justifyContent:"space-between",padding:"10px 23px 30px 18px",alignItems:"center"}}>
                <div>
                    <img style={{height:"6vh"}}
                        src={companyProfileData?.logo}
                        alt=""
                    />
                </div>
                <div>
                    <h1  style={{margin:'0',fontSize:"x-large"}}>Customer Receipt</h1>
                </div>
                <div>
                    <div className="right-side-div" style={{width:"100%"}}>
                        <h1 style={{ fontSize: "medium" }}>
                            {companyProfileData?.companyName === null ? "" : companyProfileData?.companyName}
                        </h1>
                        <p style={{ paddingLeft: "30px", paddingTop: "10px" }}>
                            {companyProfileData?.companyAddress === null || companyProfileData?.companyAddress === "null" ? "" : companyProfileData?.companyAddress}</p>
                        <p style={{ fontWeight: "100" }}>{companyProfileData?.contactNumber === null ? "" : companyProfileData?.contactNumber}</p>
                        {companyProfileData?.companyTRN !== "" && (
                            <p style={{ fontWeight: "100" }}>TRN: {companyProfileData?.companyTRN}</p>
                        )}
                    </div>
                </div>

            </div>

            <div className="customer-receipt-table-container" style={{paddingInline:"20px"}}>
                <table>
                    <thead>
                        <tr>

                            <th>Date</th>
                            <th>Name</th>
                            <th>Discount</th>
                            <th>Tax Value</th>
                            <th>Invoice Amount</th>
                            <th>Paid Amount</th>
                            {totalJournalKeys?.map(r =>
                                <th>{r}</th>
                            )}
                            <th>Credit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            [...Array(10)].map((r, i) => (
                                <tr key={i}>
                                    <td colSpan={8}>
                                        <Skeleton
                                            variant="rectangular"
                                            animation="wave"
                                            width={"100%"}
                                            height={40}
                                        />
                                    </td>
                                </tr>
                            ))
                        ) : SingleListData?.result !== undefined && SingleListData?.result?.length !== 0 ? (
                            SingleListData?.result
                                ?.slice(0)
                                ?.map((r, i) => (
                                    <tr key={i}>
                                        <td>{convertDateFormat(r?.invoiceDate)}</td>
                                        <td>{r?.invoiceNo}</td>
                                        <td>{symbol}&nbsp;{r?.discount?.toFixed(decimalPosition)}</td>
                                        <td>{symbol}&nbsp;{r?.tax?.toFixed(decimalPosition)}</td>
                                        <td>{symbol}&nbsp;{r?.invAmount?.toFixed(decimalPosition)}</td>
                                        <td>{symbol}&nbsp;{r?.paidAmount?.toFixed(decimalPosition)}</td>
                                        {totalJournalKeys?.length > 0 && totalJournalKeys.map((key, i) => {
                                            const value = r?.journal[key]?.toFixed(decimalPosition) || '0.00';
                                            return (
                                                <td key={i}>{symbol}&nbsp;{value}</td>
                                            );
                                        })}

                                        <td>{symbol}&nbsp;{r?.amountDue?.toFixed(decimalPosition)}</td>

                                    </tr>
                                ))
                        ) : (
                            <tr>
                                <td style={{ textAlign: 'center' }} colSpan={9}>No Data</td>
                            </tr>
                        )}

                    </tbody>
                    <tfoot >
                        <tr>
                            <th style={{ textAlign: 'start', fontSize: '1rem', paddingLeft: '3%' }} colSpan={4}>TOTAL</th>
                            <th >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.invAmount?.toFixed(decimalPosition)}</th>
                            <th >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.paidAmount?.toFixed(decimalPosition)}</th>
                            {totalJournalKeys.map((key) => (

                                <th key={key}>{symbol}&nbsp;{SingleListData?.total?.journal[key]?.toFixed(decimalPosition) || '0.00'}</th>
                            ))}
                            <th >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.amountDue?.toFixed(decimalPosition)}</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    )
}

export default CustomerReceiptSingleViewPrint