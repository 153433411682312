import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import { PurchaseReportNewSingleAPI } from './purchaseReportNewAPI';
import { getCurrentTime } from '../../../../../Js/Date';

export const PurchaseReportSingleView = () => {
    let navigate = useNavigate();
    const [symbol, setSymbol] = useState(null);
    const name= localStorage.getItem("name")
    const id= localStorage.getItem("singleViewId")
    const fromDate= localStorage.getItem("fromDate")
    const toDate= localStorage.getItem("toDate")
    const branch= localStorage.getItem("branch")

 const singleView = useSelector((state)=>state?.purchaseRport?.purhaseReportNwSingleList)
//  console.log(singleView);  
 const decimalPosition = localStorage.getItem("decimalPosition");

    const currencyListUpdated = useSelector(
        (state) => state?.viewUpdatedCurrency?.value
    );
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue
    );
    const handleback = () => {
        navigate("/userdashboard/purchasemanager/PurchaseReport/PurchaseReportNew")
    }

    useEffect(() => {
        let currencyObj = filterObjFromList(
            "_id",
            currencyListUpdated,
            "currency",
            companyProfileData
        );
        // console.log(currencyObj);
        setSymbol(currencyObj?.symbol);
    }, [companyProfileData, currencyListUpdated]);

    useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
    }, []);
    useEffect(()=>{
        PurchaseReportNewSingleAPI({
            branchId:branch,
            fromDate:fromDate===''?'':`${fromDate} ${getCurrentTime()}`,
            endDate:toDate===''?'':`${toDate} ${getCurrentTime()}`,
            productId:id
        })
    },[])

    return (
        <div className="global-page-parent-container" style={{ margin: "0" }}>
            <div className="global-white-bg-container">
                <div style={{ paddingBottom: "55px" }}>
                    <div className="sharp-arrow" onClick={() => handleback()}> <p>Purchase Report</p></div>
                    <div className="sharp-arrow" style={{ marginLeft: "18px", width: "auto" }}> <p>{name}</p></div>
                </div>
                <div className='global-report-table-container' style={{ maxHeight: "80vh" }}>
                    <table>
                    <thead>
                            <tr>
                                <th>Date</th>
                                <th>Vendor</th>
                                <th>Bill No.</th>
                                <th>Qty</th>
                                <th>Unit Price</th>
                                <th>Total</th>
                            </tr>
                            
                        </thead>
                        <tbody>
                       
                            {singleView?.table?.map((item,index)=>(
                            <tr  key={index} style={{ height: "38px" }}>
                                <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.date}</td>
                                <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.vendorName}</td>
                                <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.invoiceNo}</td>
                                <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.qty}</td>
                                <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7",textAlign:"end",paddingRight:"7px" }}>{symbol}&nbsp;{item.unitPrice}</td>
                                <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7",textAlign:"end",paddingRight:"7px"  }}>{symbol}&nbsp;{item.totalAmount}</td>
                            </tr>
))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan="5">Total</th>
                                <th>{symbol}&nbsp;{singleView?.amountTotal}</th>
                                
                            </tr>
                        </tfoot>
                    </table>

                </div>
            </div>

        </div>

    )
}



