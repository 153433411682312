import React, { useCallback, useEffect, useMemo, useRef } from "react";
import "../../../../css/Sales/Billingnew.css";
import { useState } from "react";
import emptyCartImg from "../../../../Assets/Images/Cart-Empty.png"
import { Autocomplete, Backdrop, CircularProgress, Dialog, IconButton, MenuItem, Paper, Select, Skeleton, TextField } from "@mui/material";
import img2 from "./image/Path 2.png";
import SearchIcon from "@mui/icons-material/Search";
import { Datepicker } from "../../../Single Components/Datepicker";
import { useSelector } from "react-redux";
import { categoryListAPICall } from "../../Inventory/Configuration/Category/CategoryAPI";
import { addNewCustomerBillingAPICall, barCodeAddProductsBilling, getAllProductsListForBilling, getAllSubCatForBilling, paymentTypesForBilling, searhProductsBilling } from "./newBillingAPI";
import { FilePicker } from "../../../Single Components/filePicker";
import { viewAllCustomersAPICall } from "../../../../API/Customer/customerAPI";
import { convertPercentageToAmount, roundNumericFields } from "../../../../Js/generalFunctions";
import { NoBackpackSharp, PrintOutlined } from "@mui/icons-material";
import { getCreditOfCustomerAPICall, viewWalletTotalByCustomerIdAPICall } from "../../../../API/Credit and Wallet/creditAndWalletAPI";
import store from "../../../../Redux/store";
import { update_credit_of_customer } from "../../../../Redux/Customer/singleCustomerCreditSlice";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { OrderList } from "../OrderList";
import { viewPosSettingsAPICall } from "../../PointOfSale/Configuration/Settings/posSettingsAPI";
import { viewCardManualAPICall } from "../../../../API/Settings/CardAndUpiType/cardManualAPI";
import { getCurrentTime, today } from "../../../../Js/Date";
import { addBillingHoldAPICall, finalBillingAPICall, getBillingHeldOrdersAPICall } from "../../../../API/Sales/billing";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ReactToPrint from "react-to-print";
import { Invoice } from "./Invoice";
import barCodeIcon from "../Billing/image/barCodeIcon.png"
import { getDiscountAPICall } from "../../../../API/Settings/Discount/discountAPI";
import countryList from "../../../../Assets/JSON/countryStates.json"
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export const BillingNew = () => {
  
  // localStorage.setItem("decimalPosition",2)  
  const decimalPosition=Number(localStorage.getItem("decimalPosition"))
  let navigate = useNavigate();

  let loginResponse = JSON.parse(localStorage.getItem("login_meta"));
  const storeCode=loginResponse?.branchPk;
  const employeeId= loginResponse?.data?._id 
  // userBranchId
  const userBranchId=localStorage.getItem("branchId")
  //products list
  const allNewProductList = useSelector((state) => state.newBillingSlice.productsList);
  const barCodeList = useSelector((state) => state.newBillingSlice.barCodeList);
  //category list
  const CategoryList = useSelector((state) => state.categorySlice.value);
  const posCategoryList = useSelector((state) => state.newBillingSlice.subCategoryList);
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  // customer wallet info
  const customerWallet = useSelector((state) => state.singleCustomerWalletSlice.value);
  //Credits of Selected Customer
  const customerCredit = useSelector(
    (state) => state.singleCustomerCreditSlice.value
  );
  // customer credit list
// country list
  const countryWithState=countryList.countries

  // pos settings list
  const posSettingsList=useSelector((state)=>state.posSettingSlice.apiResList)
  // payment types list
  const paymentTypeList = useSelector((state) => state.newBillingSlice.paymentTypeList);
  // card details
  const cardManual = useSelector((state) => state.cardManualSlice.value);
  //Invoice data
  const invoiceData = useSelector((state) => state.invoiceSlice.value);
  //Held order list
  const heldOrdersList = useSelector(
    (state) => state.billOrderHeldProductSlice.value
  );
  // discount percentage set for the company
  const maximumDiscount = useSelector((state) => state.discountSlice.value);
  // useref
  let invoicePrint = useRef();
  const barcodeScannerRef = useRef(null);
  const [barCodeEnabled,setBarCodeEnabled]=useState(false)
  const [barCodeValue,setBarCodeValue]=useState("")
  const customerFormValuesInitial={
    name:"",
    country:null,
    state:null,
    city:"",
    street:"",
    zipCode:"",
    taxId:"",
    mobile:"",
    email:"",
    paymentTerms:null,
    supplierCurrency:"",
    contactPerson:"",
    contactPersonMobile:"",
    companyCode:"",
    branch:userBranchId,
    customerGroup:null,
    phone:"",
    inactive:true,
  }
  const [customerFormValues, setCustomerFormValues] = useState(customerFormValuesInitial);

  const billingFormValueInitial={
    customer:null,
    subTotal:"",
    deliveryCharge:"",
    rewardPoint:"",
    discount:"",
    paidAmount:"",
    wallet:"",
    creditAmount:"",
    totalAmount:0,
    change:0,
    balance:0,
    isWalletRedeemed:false,
    walletUsed:0,

    inclusiveTax:0,
    exclusiveTax:0,

    discountLimit:false,
    maxDiscount:0
  }
  const [billingFormValues,setBillingFormValues]=useState(billingFormValueInitial)

  const [isLoading, setIsLoading] = useState(false);
  const [billLoading, setBillLoading] = useState(false);
  
  const [customerImg, setCustomerImg] = useState(null);
  const [isCartModified, setIsCartModified] = useState(false);
  const [validationAlert, setValidationAlert] = useState({});
  const [paymentTypesArray, setPaymentTypesArray] = useState([]);
  const [combinedPaymentArray, setCombinedPaymentArray] = useState([]);
  const paymentMethodInitial={
    type1:"",
    type2:"",
    type3:"",
    multipleChecked:false
  }
  const [paymentMethod, setPaymentMethod] = useState(paymentMethodInitial);
  const [paymentType, setPaymentType] = useState({});
  const [paymentType1,setPaymentType1] =  useState({});
  const [paymentType2,setPaymentType2] = useState({});
  const [frontendCreditList, setFrontendCreditList] = useState([]);
  const [creditsForBackend, setCreditsForBackend] = useState([]);
  const [selectedCredits, setSelectedCredits] = useState([]);

  const [addCustomerDialog, setAddCustomerDialog] = useState(false);
  const [openPreviousCreditDialog, setOpenPreviousCreditDialog] = useState(false);
  const [viewOrderDialogOpen, setViewOrderDialogOpen] = useState(false);
  const [invoiceDialog, setInvoiceDialog] = useState(false);
  const [heldProductDialog, setHeldProductDialog] = useState(false);
  

  const containerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [selectedPIndex, setSelectedPIndex] = useState(null);
  const [selectedPOSCategory, setSelectedPOSCategory] = useState(null);
 
  //hold related
  const [isHeldOrder, setIsHeldOrder] = useState(false);
  const [heldPayingObject, setHeldPayingObject] = useState(null);

  const [searchKeyword,setSearchKeyword]=useState("")

  // snackbar states and function
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false
  });



  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
  // customer details onchange
  const getCustomerFormInfo=(key)=>(e,newValue)=>{
    const{value}=e.target;
    setValidationAlert({})
    setCustomerFormValues({...customerFormValues,[key]:value===0?newValue:value})
  }

  
  //constant
  const containerWidth = 500;

  // Function to add a product to the cart
  const addToCart = (product) => {
   
    // Check if the product is already in the cart based on its id
    const existingItemIndex = cartItems.findIndex(
      (item) => item._id === product._id
    );
    if (existingItemIndex !== -1) {
      
      const updatedCartItems = [...cartItems];
      // If the product is already in the cart, update its quantity
      if(product?.stock?.toFixed(decimalPosition)<(updatedCartItems[existingItemIndex].quantity+1)){
        setSnackBarStates({ ...snackBarStates, message:`selected quanity greater than stock`, alert: true })
      }
      else{
        updatedCartItems[existingItemIndex].quantity += 1; // Increase quantity by 1
        setCartItems(updatedCartItems);
      }
    } else {
      // If the product is not in the cart, add it with a quantity of 1
      if(product?.stock?.toFixed(decimalPosition)<=0){
        setSnackBarStates({ ...snackBarStates, message:`out of stock`, alert: true })
      }else{
        setCartItems([...cartItems, { ...product, quantity: 1 }]);
      }
    }
    setIsCartModified(!isCartModified)
  };

  // Function to handle scrolling in the product list
  const handleScroll = (scrollAmount) => {
    const newScrollPosition = scrollPosition + scrollAmount;
    if (containerRef.current) {
      if (newScrollPosition >= containerWidth) {
        // Wrap around to the beginning if the end is reached
        setScrollPosition(0);
      } else {
        // Update the scroll position
        setScrollPosition(newScrollPosition);
      }

      containerRef.current.scroll({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

 // ...

 const scrollRight = () => {
  const container = document.querySelector(".billing-left-firstnav-container");
  const totalCategories = CategoryList.length;
  const scrollAmount = container.scrollWidth / totalCategories;

  // Check if it has reached the end of the container
  if (container.scrollLeft + container.clientWidth + scrollAmount >= container.scrollWidth) {
    // If at the end, scroll back to the beginning
    container.scrollTo({
      left: -10,
      behavior: "smooth",
    });
  } else {
    // Otherwise, scroll to the next position
    container.scrollTo({
      left: container.scrollLeft + scrollAmount,
      behavior: "smooth",
    });
  }
};

// ...

  // Functions to handle cart item operations
  const handleDelete = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems.splice(index, 1); // Remove the item at the specified index
    setCartItems(updatedCartItems);
    setIsCartModified(!isCartModified)
  };

  const handleIncrement = (index) => {
    // Increase the quantity of the product at the specified index
    const updatedCartItems = [...cartItems];

    if(updatedCartItems[index].stock?.toFixed(decimalPosition)<(updatedCartItems[index].quantity+1)){
      setSnackBarStates({ ...snackBarStates, 
                            message:`selected quanity greater than stock`, 
                            alert: true })
    }
    else{
      updatedCartItems[index].quantity += 1;
      setCartItems(updatedCartItems);
    }
    setIsCartModified(!isCartModified)
  };

  const handleDecrement = (index) => {
    // Decrease the quantity of the product at the specified index
    if (cartItems[index].quantity > 1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[index].quantity -= 1;
      setCartItems(updatedCartItems);
    }
    setIsCartModified(!isCartModified)
  };

  // input the changes of quantity from cart
  const cartQuantityChange = (index)=>(e) => {
      
    if(e.target.value!==""){
      const updatedCartItems = [...cartItems];
      if(updatedCartItems[index]?.stock?.toFixed(decimalPosition)<(Number(e.target.value)+1)){
        setSnackBarStates({ ...snackBarStates, 
                              message:`selected quanity greater than stock`, 
                              alert: true })
      }

      else{
      updatedCartItems[index].quantity = Number(e.target.value);
      setCartItems(updatedCartItems);
      }

      setIsCartModified(!isCartModified)}
  };
  const cartSalesPriceChange = (index)=>(e) => {
      
    if(e.target.value!==""){
      const updatedCartItems = [...cartItems];

      updatedCartItems[index].salesPrice = Number(e.target.value);
      setCartItems(updatedCartItems);
    

      setIsCartModified(!isCartModified)}
  };

  const getBillingFormInfo=(key)=>(e)=>{
    const{value}=e.target;
    setBillingFormValues({...billingFormValues,[key]:Number(value)})
  }


  // Memoized functions for calculating subtotal and total amount
  const calculateSubtotal = useCallback(() => {
    let subtotal = 0;
    for (const cartItem of cartItems) {
      subtotal += cartItem.quantity * cartItem.salesPrice;
    }
    return subtotal;
  }, [cartItems]);

  

  const handleMouseEnter=()=>{
    const updatedArray = roundNumericFields([billingFormValues],
      ["deliveryCharge",
      "discount",
      "paidAmount",
      "wallet"]);
    setBillingFormValues(updatedArray[0]);
  }
  const searchProduct=(e)=>{

    setSearchKeyword(e.target.value)
    searhProductsBilling(
      {
        search:e.target.value,
      }
    )
  }
  // Event handler for multiple check change
  const multipleCheckChange = (e) => {
    if(creditsForBackend.length===0){
      setPaymentMethod({...paymentMethod,multipleChecked:e.target.checked});
      setPaymentType1({})
      setPaymentType2({})
    }
    else{
      setSnackBarStates({ ...snackBarStates, 
        message:`Multiple payment is not allowed when clearing previous credits !!`, 
        alert: true })
    }
  };

  const closeCustomerDialog=()=>{
    setAddCustomerDialog(false)
    setCustomerFormValues(customerFormValuesInitial)
  }

  const customerFormData=new FormData()
  customerFormData.append("name",customerFormValues?.name)
  customerFormData.append("mobileNo",customerFormValues?.mobile)
  customerFormData.append("branchId",customerFormValues?.branch)
  customerFormData.append("status",customerFormValues?.inactive)
  customerFormData.append("isCustomer",true)
  customerImg!==null && customerFormData.append("file",customerImg)
  customerFormValues.country!==null && customerFormData.append("country",customerFormValues.country?.country)
  customerFormValues.state!==null && customerFormData.append("state",customerFormValues.state)
  
  const addCustomerSubmit=()=>{
    if(customerFormValues?.name===""){
      setValidationAlert({...validationAlert,name:"fill this field !!"})
    }
    else if(customerFormValues?.mobile===""){
      setValidationAlert({...validationAlert,mobile:"fill this field !!"})
    }
    else{
    
      addNewCustomerBillingAPICall(customerFormData,closeCustomerDialog,setSnackBarStates,snackBarStates,setBillingFormValues)
    }
  }

  // function to redeem wallet amount froom total amount
  const redeemFromWallet=()=>{
    
      let walletTot=customerWallet?.amount;
      let newTotal=0;
      let walletBalance=0;

      let redeemAmount=Number(billingFormValues?.wallet)+Number(billingFormValues?.walletUsed)
      let totalAmountToPay=Number(billingFormValues?.totalAmount)
     
      if(redeemAmount > walletTot){
          setSnackBarStates({ ...snackBarStates, message:`Wallet balance is only ${walletTot}`, alert: true })

      } else {
        if(redeemAmount > totalAmountToPay) {
          setSnackBarStates({ ...snackBarStates, message:"Redeemed amount must be less than or equal to total amount!!", alert: true })

        } else {
          
          newTotal=parseFloat(totalAmountToPay) - Number(billingFormValues?.wallet);
          walletBalance=parseFloat(walletTot) - Number(billingFormValues?.wallet);
          setBillingFormValues({...billingFormValues,
            isWalletRedeemed:true,
            // totalAmount:newTotal,
            wallet:walletBalance,
            walletUsed:billingFormValues?.walletUsed+Number(billingFormValues?.wallet)
          })
        }
      }
   
  };
// function to open creditlist dialog of a customer
const clickCreditAdd=()=>{
  setOpenPreviousCreditDialog(true)
}
const checkOrUncheckCreditItem = (index) => (e) => {
  const { checked } = e.target;
  if (frontendCreditList?.length > 0) {
    if (checked) {
      frontendCreditList[index].isChecked = true;
    } else {
      frontendCreditList[index].isChecked = false;
    }

    setFrontendCreditList([...frontendCreditList]);
  }
};
const createCreditListForBackend = (selectedCredits) => {
  let creditsForBackend = [];

  creditsForBackend = selectedCredits?.map((obj) => {
    return { order_id: obj.purchasePk, balance: obj.balance ,purchaseId:obj?.purchaseId};
  });
  setCreditsForBackend(creditsForBackend);
};
  //Create Front end Credit list with isChecked injected
  const createCreditListForFrontend = (apiResponse) => {
    if (apiResponse !== undefined) {
      let newCreditList = apiResponse?.map((obj) => {
        return { ...obj, isChecked: false };
      });
      setFrontendCreditList(newCreditList);
    } else {
      setFrontendCreditList([]);
    }
  };
 //Create Selected(checked) Credit Items Array
 const createSelectedCreditItems = (completeCreditList) => {
  let filteredList = [];
  if (completeCreditList !== undefined) {
    filteredList = completeCreditList?.filter(
      (obj) => obj.isChecked === true
    );
    setSelectedCredits(filteredList);
  } else {
    setSelectedCredits([]);
  }
};
//Calculate Total amount of credit selected from dialog
const calculateTotalSelectedCreditAmount = () => {
  let selectedCreditTot = 0;
  selectedCredits?.forEach((obj) => {
    selectedCreditTot += parseFloat(obj?.balance);
  });
  if (isNaN(selectedCreditTot)) {
    setBillingFormValues({...billingFormValues,creditAmount:0})
  } else {
    setBillingFormValues({...billingFormValues,creditAmount:Number(selectedCreditTot)})
  }
};
//Click Previous Credit dialog Submit Fn
  //Click Previous Credit dialog Submit Fn
  const submitPreviousCreditDialog = () => {
    createCreditListForBackend(selectedCredits);
    calculateTotalSelectedCreditAmount();
    setOpenPreviousCreditDialog(false);
  };
// payment type onchange
const changePaymentType=(type,key,index)=>(e)=>{
  const setState=index===3?setPaymentType2:index===2?setPaymentType1:setPaymentType
  if(type==="type"){
    setPaymentMethod({...paymentMethod,[key]:e.target.value})
  }
  else{
    setState((prevObject) => ({
      ...prevObject,
      [key]:e.target.value
    }));
  }
  if(e.target.value?.type==="card"){

    cardManual===undefined && viewCardManualAPICall({journalId:e.target.value?.journalId})
  }
}

  //click pay held order btn in held dialog
  const clickPayHeldOrder = (order) => {
    setIsHeldOrder(true);
    setHeldPayingObject(order);
    setHeldProductDialog(false);
    const { cartItems } = order?.order;
    setCartItems(cartItems);
  };

// calculate tax of the selected cart items
const calculateAndSetCalculatedValues = () => {
  const calculatedValues = cartItems?.map((row) => {
    if(row?.includedPrice===true){

      let totalAmt=Number(row?.quantity)*Number(row?.salesPrice)
      let taxArray=row?.taxes?.map((obj)=>obj?.amount)

      let totalOfTax=taxArray.reduce((sum,item)=>sum+item,0)
      let taxableAmount=(totalAmt/(totalOfTax+100))*100
      

      let subtotalBeforeTax=(taxableAmount)?.toFixed(decimalPosition)
      

      let totalDiscount = taxArray.reduce((total, taxPercentage) => {
        const discountAmount = (taxPercentage / 100) * subtotalBeforeTax;
        return total + discountAmount;
      }, 0);
      return { ...row, inclusiveTax:totalDiscount,exclusiveTax:0};
    }
    else if(row?.includedPrice===false){

      let totalAmt=Number(row?.quantity)*Number(row?.salesPrice)

      let subtotalBeforeTax=(totalAmt)?.toFixed(decimalPosition)

      let totalTax=row?.tax?.reduce((sum,item)=>sum + item?.amount,0)

      let totalExclTax=(subtotalBeforeTax*totalTax)/100

      return { ...row, exclusiveTax:totalExclTax,inclusiveTax:0};
    }

    else{
      return { ...row,exclusiveTax:0,inclusiveTax:0};
    }
  });
  setCartItems(calculatedValues);

};
const cartDataToBackend=cartItems?.map((item)=>(
  {
    itemInfo:item?._id,
    quantity: item?.quantity,
    productName: item?.productName,
    rate: item?.salesPrice,
    cost: item?.cost,
    offer: null,
    unit:item?.uomName,
    poscat: item?.poscat,
    mainCatgeoryId: item?.mainCatgeoryId,
    uom: item?.uom,
    discount: 0,
    taxes:item?.taxes
}

))

const finalBody={
  cus_id: billingFormValues?.customer?._id || "cash",
  branchId: userBranchId,
  orderDate: `${today} ${getCurrentTime()}`,
  orderInfo:cartDataToBackend,
  paymentMethod:combinedPaymentArray,
  totalAmount: Number(billingFormValues?.totalAmount),
  discount: billingFormValues?.discount===""?0:Number(billingFormValues?.discount),
  shipmentCharge: billingFormValues?.deliveryCharge===""?0:Number(billingFormValues?.deliveryCharge),
  emp_id: employeeId,
  usedPoints: 0,
  usedWallet: Number(billingFormValues?.walletUsed),
  creditCleared:creditsForBackend?.length!==0?creditsForBackend:null,
  isWallet: false,
  _id: isHeldOrder ? heldPayingObject?._id : null,
}



const disablePlaceOrder=( cartItems?.length===0 || (billingFormValues?.customer===null && (Number(billingFormValues?.paidAmount)!==Number(billingFormValues?.totalAmount))))
const clearStates=()=>{
  setCartItems([])
  setBillingFormValues(billingFormValueInitial)
  setPaymentType1({})
  setPaymentType2({})
  setPaymentType({})
  setIsHeldOrder(false);
  setHeldPayingObject(null);
  setPaymentMethod({...paymentMethod,multipleChecked:false})
}
const finalPlaceOrder = () => {
  let balance=Number(billingFormValues?.balance)
  let change=Number(billingFormValues?.change)
  if (balance!==0) {
    if (
      window.confirm(
        "Paid amount is less than total amount!! \nbalance amount will be added to customer credit \n would you like to continue??"
      ) === true
    ) {
        // finalBilling
        finalBillingAPICall(
          finalBody,
          setBillLoading,
          setSnackBarStates,
          snackBarStates,
          clearStates,
          setInvoiceDialog
        );
    } else {
    }
  } else if (change!==0) {
    if (
      window.confirm(
        "Paid amount is greater than total amount!! \n Would you like to add change to customer wallet?? \n If not change paid amount!! "
      ) === true
    ) {
   
        // Call billing api
        finalBody.isWallet = true;
        finalBillingAPICall(
          finalBody,
          setBillLoading,
          setSnackBarStates,
          snackBarStates,
          clearStates,
          setInvoiceDialog
        );

      }
  } else {
      // Call billing api
      finalBillingAPICall(
        finalBody,
        setBillLoading,
        setSnackBarStates,
        snackBarStates,
        clearStates,
        setInvoiceDialog
      );
  }
};

//hide invoice print button on scroll
const hideInvoicePrint = () => {
  document.getElementById("invoicePrintIcon").style.display = "none";
};

//Billing hold body
const holdBody = useMemo(
  () => ({
    cusId: billingFormValues?.customer?._id || "cash",
    order: { cartItems, finalBody },
    branchId:userBranchId,
    _id: isHeldOrder ? heldPayingObject?._id : null,
  }),
  [
    billingFormValues?.customer,
    finalBody,
    isHeldOrder,
    heldPayingObject?._id,
  ]
);

//hold order function
const holdOrder = () => {
  addBillingHoldAPICall(
    holdBody,
    setSnackBarStates,
    snackBarStates,
    clearStates
  );
};
  //Click held products btn
const clickHeldProductsBtn = () => {
  setHeldProductDialog(true);
  getBillingHeldOrdersAPICall({ branchId:userBranchId, cusId: billingFormValues?.customer?._id || "cash" });
};


const paymentMethodCheck =()=>{
  if(posSettingsList?.posStatus){
    paymentTypeList===undefined && 
    Swal.fire({
      text: 'Payment Methods are not added for this branch do you want to add',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ok',
      cancelButtonText: 'Cancel',
      reverseButtons: true, // To swap the positions of the buttons
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.setItem("singleViewById",loginResponse?.branchPk)
        navigate("/userdashboard/pointOfSales/configuration/BranchSettings/create")
   
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
}
  useEffect(()=>{
    calculateAndSetCalculatedValues()
  },[isCartModified])
  // useeffect to calculate total amount
  useEffect(() => {
    
    let creditTotal=creditsForBackend?.reduce((sum,item)=>sum+item?.balance,0)

    let totalExclTax= cartItems?.reduce((sum,item)=>sum+item?.exclusiveTax,0)
    const subtotal = calculateSubtotal();
    const totalAmount = creditTotal + subtotal+ (isNaN(totalExclTax)?0:totalExclTax) + Number(billingFormValues?.deliveryCharge) - Number(billingFormValues?.discount)
    let change=0;
    let balance=0;

    if(totalAmount>Number(billingFormValues?.paidAmount)){

      balance=totalAmount-(Number(billingFormValues?.paidAmount)+Number(billingFormValues?.walletUsed))
      change=0
    }
    else{ 
      change=(Number(billingFormValues?.paidAmount)+Number(billingFormValues?.walletUsed))-totalAmount
      balance=0

    }
    setBillingFormValues((prevState)=>({...prevState,
                          totalAmount:totalAmount,
                          change:change,
                          balance:balance,
                          exclusiveTax:isNaN(totalExclTax)?0:totalExclTax
                      }))

    if (maximumDiscount !== undefined) {
      const maxDiscount = convertPercentageToAmount(
        subtotal,
        maximumDiscount?.discountAmount
      );
      if(maxDiscount<Number(billingFormValues?.discount)){
        setBillingFormValues({...billingFormValues,discountLimit:true,maxDiscount:maxDiscount})
      }
      // setMaxDiscountAmt(maxDiscount);
    }
  }, [calculateSubtotal, 
      billingFormValues?.deliveryCharge, 
      billingFormValues?.discount,
      billingFormValues?.paidAmount,
      billingFormValues?.walletUsed,
      creditsForBackend,

    ]);

// useEffect to alert maximum discount
useEffect(()=>{
  if(billingFormValues?.discountLimit){
    setBillingFormValues({...billingFormValues,discount:billingFormValues?.maxDiscount,discountLimit:false})
    setSnackBarStates({ ...snackBarStates, message:`maximum discount rate allowed is ${billingFormValues?.maxDiscount}`, alert: true })
  }
},[billingFormValues?.discountLimit])
// initial api calls
  useEffect(() => {
    categoryListAPICall();
    paymentTypesForBilling({branchId:userBranchId})
    viewPosSettingsAPICall()
    getDiscountAPICall();
    viewCardManualAPICall()
  }, []);

  // api calls to filter category
  useEffect(()=>{
    let payload=new FormData()
      
      payload.append("branchId",userBranchId)
      selectedPIndex!==null && payload.append("mainCatgeoryId",selectedPIndex)
      selectedPOSCategory!==null && payload.append("poscat",selectedPOSCategory)
      payload.append("index",0)

      let categoryFilterPayload=new FormData()
      selectedPIndex!==null && categoryFilterPayload.append("catId",selectedPIndex)
      selectedPOSCategory!==null && categoryFilterPayload.append("parentCategories",selectedPOSCategory)
      
      getAllProductsListForBilling(payload,setIsLoading)
      getAllSubCatForBilling(categoryFilterPayload)

  },[selectedPIndex,selectedPOSCategory,invoiceDialog])

  useEffect(()=>{
    // viewAllCustomersAPICall({branchId:userBranchId==="null"?null:userBranchId})
    viewAllCustomersAPICall({branchId:storeCode})

  },[addCustomerDialog])

  // api call to get the customer wallet and credit details
  useEffect(() => {
    // get credit of customer
      getCreditOfCustomerAPICall({ _id: billingFormValues?.customer?._id, prefix: "ORD" });
    // get wallet amount of customer
      viewWalletTotalByCustomerIdAPICall({ cus_id: billingFormValues?.customer?._id });
    if (billingFormValues?.customer === null) {
      store.dispatch(update_credit_of_customer({ singleCusCredit: undefined }));
    }
  }, [billingFormValues?.customer]);

  // assigning the wallet amount
  useEffect(()=>{
    setBillingFormValues({...billingFormValues,wallet:customerWallet?.amount || 0})
  },[customerWallet])

  useEffect(()=>{
    if(posSettingsList?.posStatus){
      setPaymentTypesArray(paymentTypeList)
      paymentTypeList!==undefined &&setPaymentMethod(({
                                    type1:paymentTypeList[0],
                                    type2:paymentTypeList[0],
                                    type3:paymentTypeList[0],
                                  }))
    }else{
      let paymentArray=[
        {
          journalName:"Cash",
          type:"cash"
        },
        {
          journalName:"Card",
          type:"card"
        },
        {
          journalName:"Cheque",
          type:"cheque"
        },
      ]
      setPaymentTypesArray(paymentArray)
      setPaymentMethod(({
        type1:paymentArray[0],
        type2:paymentArray[0],
        type3:paymentArray[0],
      }))

    }
  },[paymentTypeList,posSettingsList])
   // Helper function to get the current paymentType based on the type
   const getPaymentType = (type) => {
    switch (type) {
      case 'type1':
        return paymentType;
      case 'type2':
        return paymentType1;
      case 'type3':
        return paymentType2;
      default:
        return null;
    }
  };
  // to combine the payment array to send in payload
  useEffect(() => {
    if(!paymentMethod?.multipleChecked){
      let body={
        type: paymentMethod.type1?.type,
        vendor:
            paymentMethod.type1?.type === 'card'
              ? paymentType?.card?.cardName || null
              : null,
        paidAmount:Number(billingFormValues?.paidAmount)-Number(paymentType1?.amount||0)-Number(paymentType2?.amount|| 0),
       
        cardCommission:
        paymentMethod.type1?.type === 'card'
          ? paymentType?.card?.commission || 0
          : 0,
        date:`${today} ${getCurrentTime()}`,
        // account:paymentMethod.type1?.type === 'card'
        //   ? paymentMethod.type1?.journalId || null:null,
        account:paymentMethod.type1?.journalId || null,
        recieptNo:paymentMethod.type1?.type === "cheque"?
         ( paymentType?.chequeNo || null)
          :
          paymentMethod.type1?.type === "card"?
          (paymentType?.cardNo || "") :
          null,
      }
      setCombinedPaymentArray([body])
      // setPaymentType1({})
      // setPaymentType2({})
    }

    else{
    const typesArray = ['type1', 'type2', 'type3'];

    // Create combined objects for each type
    const newArray = typesArray.filter((type) => {
      const currentPaymentType = getPaymentType(type);
        return currentPaymentType?.amount !== undefined && currentPaymentType.amount !== "";
    }).map((type) => {

      // Dynamically get the current paymentType based on the type
      const currentPaymentType = type === 'type1' ? paymentType : type === 'type2' ? paymentType1 : paymentType2
      

      // Create the combined object
      const combinedObject = {
        type: paymentMethod[type].type,
        vendor:
          paymentMethod[type].type === 'card'
              ? currentPaymentType?.card?.cardName || null
              : null,
        paidAmount: Number(currentPaymentType?.amount) || null,
        cardCommission:
          paymentMethod[type].type === 'card'
          ? currentPaymentType?.card?.commission || null
          : null,
        date:`${today} ${getCurrentTime()}`,
        // account:paymentMethod[type].type === 'card'?
        //         paymentMethod[type].journalId || null
        //         :null,
        account:paymentMethod[type].journalId || null
                ,
        recieptNo:paymentMethod[type].type === "cheque"?
                  (currentPaymentType?.chequeNo || ""):
                  paymentMethod[type].type === "card"?
                  (currentPaymentType?.cardNo || "")
                  :null,
      };
      return combinedObject;
    });

    // Update the state with the new array
    let total=newArray?.reduce((sum,item)=>sum+item?.paidAmount,0)

    setBillingFormValues({...billingFormValues,paidAmount:total})
    setCombinedPaymentArray(newArray);
    
    }
  }, [paymentType, paymentType1, paymentType2, paymentMethod,billingFormValues?.paidAmount]);

 
  // removed billingFormValues?.paidAmount

    //Call Create Customer credit for front end as per the customer credit api changes
    useEffect(() => {
      // calTotCreditAmount(customerCredit);
      createCreditListForFrontend(customerCredit);
    }, [customerCredit]);

  //Call Create Selected Credit List
  useEffect(() => {
    createSelectedCreditItems(frontendCreditList);
  }, [frontendCreditList]);

  useEffect(() => {
    if (barCodeEnabled) {
      // Focus on the barcode scanner when isBarcodeSelected is true
      barcodeScannerRef.current.focus();
    }
  }, [barCodeEnabled]);

  useEffect(()=>{
    let barCodeFn=()=>{
      setBarCodeValue("")
    }
    barCodeValue!=="" &&
    barCodeAddProductsBilling(
      {
        search:barCodeValue,
      },barCodeFn
    )
  },[barCodeValue])

  useEffect(()=>{
    if(barCodeList!==undefined){
      barCodeList?.length===1 && addToCart(barCodeList[0])
    }
  },[barCodeList])
  
  // consoles
  return (
    <>
      <div className="global-page-parent-container billing-parent-container">
        <div className="billing-main-container">
          <div className="billing-left-container">
            <div className="billing-left-first">
              <div style={{width:"52%",position:"relative"}}>
               <div className="billing-left-firstnav-container">
                {
                CategoryList!==undefined ?
                CategoryList?.map((text, index) => (
                  <p
                    key={index}
                    className={selectedPIndex === text?._id ? "selected-p" : ""}
                    onClick={() =>{
                      setSelectedPOSCategory(null)
                      selectedPIndex === text?._id?
                      setSelectedPIndex(null)
                      :
                      setSelectedPIndex(text?._id)
                      }}
                  >
                    {text.categoryName}
                  </p>
                ))
                :
                <p>
                <Skeleton width={"80px"} height="20px" />
                </p>
              }
               </div>
               <div
                  style={{ position: " absolute", right: "22px", top: "19px" }}
                >
                  <button
                    className="left-second-div-arrow" onClick={scrollRight}
                  >
                    &gt;
                  </button>
                </div>
              </div>
              <div
                className="billing-left-secoundnav-container"
              >
                <button className="billing-btn-2">
                  <span
                  onClick={()=>setAddCustomerDialog(true)}
                  className="circle-button">+</span>
                  <Autocomplete
                    sx={{paddingRight:"10px"}}
                    options={customerList || []}
                    getOptionLabel={(option)=>option?.name}
                    renderInput={(params)=>(
                        <TextField
                          {...params} placeholder="Customer"/>)}
                          value={billingFormValues?.customer}
                          onChange={(e,newValue)=>setBillingFormValues({...billingFormValues,customer:newValue})}
                  
                  //   PaperComponent={({ children }) => (
                  //   <Paper style={{ maxHeight: 'unset' }}>{children}</Paper>
                  // )}
                  />
                </button>
                <button 
                onClick={()=>setViewOrderDialogOpen(true)}
                className="billing-btn-3">View Order</button>
                <button 
                style={{position:"relative"}}
                onClick={clickHeldProductsBtn}
                className="billing-btn-4">Held Orders
                {
                  heldOrdersList!==undefined &&
                <span
                style={{position:"absolute",top:"-10px",
                        right:"0",
                        border:"1px solid #f09431",
                        borderRadius:"50%",
                        padding:"2px 5px",
                        backgroundColor:"#fff"
                      }}
                >
                  {heldOrdersList?.length}
                </span>}
                </button>
              </div>
            </div>

            <div className="billing-left-button">
              <div className="left-second-div">
                {
                  isLoading?
                <div className="billing-array" style={{gap:"6px"}}>
                  {[...Array(10)]?.map((r, i) => (
                    <div>
                      <Skeleton width={"80px"} height="60px" />
                    </div>
                  ))}
                </div>
                :
                <div className="billing-array" ref={containerRef}>
                {posCategoryList?.map((text, index) => (
                  <p
                    key={index}
                    className={selectedPOSCategory === text?._id ? "selected-pos" : ""}
                    onClick={() =>{
                      selectedPOSCategory === text?._id?
                      setSelectedPOSCategory(null)
                      :
                      setSelectedPOSCategory(text?._id)
                      }}
                  >
                    {text.name}
                  </p>
                ))}
              </div>  
              }
                <div
                  style={{ position: " absolute", right: "17px", top: "4px" }}
                >
                  <button
                    className="left-second-div-arrow"
                    onClick={() => handleScroll(100)}
                  >
                    &gt;
                  </button>
                </div>
              </div>
              
              <button 
                style={{width:"20%",padding:"0"}}
                className="billing-btn-3">Old Stock</button>
              <div className="billing-search-bar">
                <SearchIcon className="billing-search-icon" />
                <input
                  type="text"
                  placeholder="Search product by name.."
                  className="search-bar"
                  value={searchKeyword}
                  onChange={searchProduct}
                />
                <img
                className={barCodeEnabled &&"bar-code-enabled"}
                style={{width:"30px",position:"absolute",right:"0",top:"1px",cursor:"pointer"}}
                onClick={()=>setBarCodeEnabled(!barCodeEnabled)}
                src={barCodeIcon} alt="" />
                <input 
                  className="barcode-input"
                  type="text"
                  value={barCodeValue}
                  onChange={(e)=>setBarCodeValue(e.target.value)}
                  ref={barcodeScannerRef}
                />
              </div>
            </div>
            <div className="cards">
              {
              
              isLoading?
              (
                [...Array(10)].map((r,i) => (
                  <div key={i} className="billing-single-product-container">
                    <Skeleton width={"100%"} height="100px" />
                    <Skeleton width={"100%"} height="20px" />
                    <div className="billing-product-list-select-container">
                      <Skeleton width={"60%"} height="20px" />
                      <Skeleton width={"30%"} height="30px" />
                    </div>
                  </div>
                ))
              )
              :
              allNewProductList?.map((product, index) => (
                <div
                  className="card-content"
                  key={index}
                  onClick={() => addToCart(product)}
                >
                  <img src={product?.imageUrl} alt="No Img" className="product-image " />
                  <div className="card-details">
                    <h1 className="p-name">{product.productName}</h1>
                    <p>Price : {product.salesPrice?.toFixed(decimalPosition)}</p>
                    <p style={{ color: "blue" }}>Stock : {product.stock?.toFixed(decimalPosition)}</p>
                    <div className="card-buttons">
                      {
                        product?.attribute?.length!==0?
                        product?.attribute?.map((r,i)=>(
                          <button className="button-c">{r?.value}</button>
                        ))
                      :""
                      }
                    </div>
                  </div>
                  {
                    product?.offer &&
                    <img src={img2} alt="alt" className="offer-price" />}
                </div>
              ))}
            </div>
          </div>

          <div
            className="billing-rightside-container"
            style={{ height: "90vh" }}
          >
            <div className="billing-right-card">
              {
              cartItems?.length!==0?
              cartItems.map((cartItem, index) => (
                <div className="billing-card-content" key={index}>
                  <div className="billing-card-image">
                    <img src={cartItem?.imageUrl} alt="" />
                  </div>
                  <div className="billing-card-details">
                    <h1>{cartItem.productName}</h1>
                    {/* <p>{cartItem.salesPrice}</p> */}
                    <input
                    type="number"
                    style={{border:"none",
                            fontSize:"0.7rem",
                            paddingLeft:"0"
                    }}
                    value={cartItem.salesPrice}
                    onChange={cartSalesPriceChange(index)}

                    />
                    <p>
                      {
                          cartItem?.attribute?.length!==0?
                          cartItem?.attribute?.map((r,i)=>
                          r?.value
                          ).join("-")
                        :""
                        }

                    </p>
                  </div>
                  <div className="cart-buttons-ctn">
                    <div className="count-buttons">
                      <button
                        onClick={() => handleDecrement(index)}
                        className="btn-decrease"
                      >
                        -
                      </button>
                    </div>
                    <div>
                      <input 
                        type="number" 
                        value={cartItem.quantity}
                        onChange={cartQuantityChange(index)}
                      />
                      {/* <span className="count">{cartItem.quantity}</span> */}
                    </div>
                    <div>
                      <button
                        onClick={() => handleIncrement(index)}
                        className="btn-increase"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="total-price" style={{ fontSize: "0.8rem" }}>
                    {cartItem.quantity * cartItem.salesPrice}
                  </div>
                  <IconButton onClick={() => handleDelete(index)}>
                    <i
                      class="bi bi-trash3 delete-icon"
                      style={{ fontSize: "15px" }}
                    ></i>
                  </IconButton>
                </div>
              ))
                :
                <div className="billing-right-empty-cart-container">
                <img src={emptyCartImg} alt="" />
                <p>cart is empty</p>
              </div>
            }

            </div>
            <div>
            <div className="payment-container"
              onMouseLeave={handleMouseEnter}
              >
              <div className="billing-section-head">
                <p>PAYMENTS</p>
                <button
                onClick={clearStates}
                className="billing-clear-button">clear</button>
              </div>
              <div className="dashed-line"></div>
              <div className="billing-input-field-sub">
                <p>Subtotal</p>
                <input
                style={{caretColor:"transparent"}}
                onMouseLeave={handleMouseEnter}
                onMouseEnter={handleMouseEnter}
                type="text" value={calculateSubtotal().toFixed(2)} />
              </div>

              <div className="billing-input-field">
                <p>Delivery charge</p>
                <input
                  placeholder="00.00"
                  type="number"
                  onMouseLeave={handleMouseEnter}
                  onMouseEnter={handleMouseEnter}

                  value={billingFormValues?.deliveryCharge}
                  onChange={getBillingFormInfo("deliveryCharge")}
                />
              </div>

              {
                billingFormValues?.customer!==null &&<div className="billing-input-field">
                <p>Reward point</p>
                <div className="input-div">
                  <span className="billing-input-field-add">Add</span>
                  <input
                  onMouseLeave={handleMouseEnter}
                  onMouseEnter={handleMouseEnter}
                  type="text" placeholder="00.00" />
                </div>
              </div>}

              <div className="billing-input-field">
                <p>Discount</p>
                <input
                  onMouseLeave={handleMouseEnter}
                  onMouseEnter={handleMouseEnter}

                  type="number"
                  value={billingFormValues?.discount}
                  onChange={getBillingFormInfo("discount")}
                />
              </div>

              <div className="billing-input-field">
                <p style={{fontWeight:"600"}}>Paid Amount</p>
                <input
                  onMouseLeave={handleMouseEnter}
                  onMouseEnter={handleMouseEnter}
                  style={{fontWeight:"600"}}
                  type="number"
                  placeholder="00.00"
                  value={
                    billingFormValues?.paidAmount}
                  onChange={getBillingFormInfo("paidAmount")}
                  disabled={paymentMethod?.multipleChecked}
                />
              </div>

              {
                billingFormValues?.customer!==null &&
                <>
                <div className="billing-input-field">
                <div style={{display:"flex",gap:"3px"}}>
                  <p>Wallet</p>
                  {
                    Number(billingFormValues?.walletUsed) > 0 &&
                    <p style={{color:"green",fontWeight:"600"}}>{`(${billingFormValues?.walletUsed})`}</p>}
                </div>
                <div className="input-div">
                <span
                onClick={redeemFromWallet}
                style={{   
                   width: "50px",
                   backgroundColor: "rgb(83, 211, 194)"}}
                className="billing-input-field-add2">Redeem</span>
                <input
                onMouseLeave={handleMouseEnter}
                onMouseEnter={handleMouseEnter}
                value={billingFormValues?.wallet}
                onChange={getBillingFormInfo("wallet")}
                type="number" placeholder="00.00" />
                </div>
              </div>

              <div className="billing-input-field-credit">
                <p>Credit Amount</p>
                <div className="input-div">

                <span 
                onClick={clickCreditAdd}
                className="billing-input-field-add2">Add</span>
                <input
                  onMouseLeave={handleMouseEnter}
                  onMouseEnter={handleMouseEnter}
                  type="number" placeholder="00.00" />
                </div>
              </div>
              </>
              }
            </div>
            <div 
              className="total-amount"
              onMouseEnter={handleMouseEnter}
              >
              <div className="total">
                {billingFormValues?.exclusiveTax!==0 &&<p>Exclusive Tax</p>}
                {billingFormValues?.inclusiveTax!==0 &&<p >Inclusive Tax</p>}
                <h1>Total amount </h1>
                <p >change</p>
                <p>Balance</p>
              </div>
              <div className="total-a">
                {
                billingFormValues?.exclusiveTax!==0 &&
                <p>{billingFormValues?.exclusiveTax?.toFixed(decimalPosition)}</p>}
                {
                billingFormValues?.inclusiveTax!==0 &&
                <p>{billingFormValues?.inclusiveTax?.toFixed(decimalPosition)}</p>}
                
                <h1>{billingFormValues?.totalAmount?.toFixed(decimalPosition)}</h1>
                <p>{billingFormValues?.change?.toFixed(decimalPosition)}</p>
                <p>{billingFormValues?.balance?.toFixed(decimalPosition)}</p>
              </div>
            </div>
            <div className="payment-m">
              <h1>payment Method</h1>
              <div className="payment-method-checkbox-container">
                <input
                  onChange={multipleCheckChange}
                  type="checkbox"
                  disabled={"creditAmount" > 0}
                  id="paymentMultiple"
                  checked={paymentMethod?.multipleChecked===true}
                />
                <label htmlFor="paymentMultiple">Multiple</label>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
              onMouseEnter={paymentMethodCheck}
            >
              <div className="global-single-input billing-selectNew">
                <Select
                  value={paymentMethod.type1}
                  onChange={changePaymentType("type","type1",1)}
                >
                  {
                    paymentTypesArray?.map((card,i)=>
                    <MenuItem 
                    key={i}
                    value={card} 
                    // data-additional-value={card?.journalId}
                    >{card?.journalName}</MenuItem>
                    )
                  }
                </Select>
              </div>

              {paymentMethod?.type1?.type === "card" && (
                <div className="billing-type-right-card-ctn">
                  <div
                    className="global-single-input billing-selectNew"
                    style={{ width: "50%" }}
                  >
                    <Select
                      value={paymentType?.card}
                      onChange={changePaymentType("other","card",1)}
                    >
                      {
                        cardManual?.map((card,i)=>
                        <MenuItem
                          key={i}
                          value={card}
                          sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}
                          >
                            {card?.cardName}
                            <img
                            style={{width:"25px",height:"15px"}}
                            src={card?.imageUrl} alt="" />
                          </MenuItem>
                        )
                      }
                    </Select>
                  </div>
                  <div className="billing-card-number">
                    <input
                    value={paymentType?.cardNo}
                    onChange={changePaymentType("other","cardNo",1)}
                    type="text" placeholder="Approval no" />
                  </div>

                  {
                  paymentMethod?.multipleChecked &&
                  <div
                  style={{width:"100%"}}
                    value={paymentType?.amount}
                    onChange={changePaymentType("other","amount",1)}
                    className="billing-card-number">
                    <input type="number" placeholder="Enter Amount" />
                  </div>}
                </div>
              )}
              {paymentMethod?.type1?.type === "cheque" && (
                <>
                  <div className="billing-cheque-section">
                    <div
                      className="billing-input-field-cheque"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="billing-cheque-number">
                        <input
                          type="text"
                          placeholder="cheque"
                          style={{ width: "53px" }}
                          value={paymentType?.chequeNo}
                          onChange={changePaymentType("other","chequeNo",1)}
                        />
                      </div>
                      <div className="billing-cheque-date">
                        <Datepicker
                          type="date"
                          value={paymentType?.chqDate}
                          onChange={changePaymentType("other","chqDate",1)}
                          placeholderText="DD/MM/YYYY"
                        />
                      </div>
                    </div>
                    {
                    paymentMethod?.multipleChecked &&
                    <div className="billing-input-field-cheque-amount">
                      <input 
                      value={paymentType?.amount}
                      onChange={changePaymentType("other","amount",1)}
                      type="text" placeholder="00.00" />
                    </div>
                    }
                  </div>
                </>
              )}
              {paymentMethod?.type1?.type === "cash" && (
                <>
                {
                  paymentMethod?.multipleChecked?
                  <div className="billing-input-field-cheque-amount">
                      <input
                      value={paymentType?.amount}
                      onChange={changePaymentType("other","amount",1)}
                      type="text" placeholder="00.00" />
                    </div>
                  :<div className="billing-input-field-payment">
                    <p>Collect amount</p>
                  </div>
                }
                </>
              )}
            </div>

            {paymentMethod?.multipleChecked && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="global-single-input billing-selectNew">
                  <Select
                  value={paymentMethod.type2}
                  onChange={changePaymentType("type","type2",2)}
                  >
                  {
                    paymentTypesArray?.map((card,i)=>
                    <MenuItem 
                    key={i}
                    value={card} 
                    // data-additional-value={card?.journalId}
                    >{card?.journalName}</MenuItem>
                    )
                  }
                </Select>
                  </div>

                  {paymentMethod?.type2?.type === "card" && (
                <div className="billing-type-right-card-ctn">
                  <div
                    className="global-single-input billing-selectNew"
                    style={{ width: "50%" }}
                  >
                    <Select
                    value={paymentType1?.card}
                    onChange={changePaymentType("other","card",2)}
                    >
                      {
                        cardManual?.map((card,i)=>
                        <MenuItem
                          key={i}
                          value={card}
                          sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}
                          >
                            {card?.cardName}
                            <img
                            style={{width:"25px",height:"15px"}}
                            src={card?.imageUrl} alt="" />
                          </MenuItem>
                        )
                      }
                    </Select>
                  </div>
                  <div className="billing-card-number">
                    <input
                      value={paymentType1?.cardNo}
                      onChange={changePaymentType("other","cardNo",2)}
                      type="text" placeholder="Approval no" />
                  </div>

                  <div
                  style={{width:"100%"}}
                  className="billing-card-number">
                    <input 
                    value={paymentType1?.amount}
                    onChange={changePaymentType("other","amount",2)}
                    type="number" placeholder="Enter Amount" />
                  </div>
                </div>
              )}
              {paymentMethod?.type2?.type === "cheque" && (
                <>
                  <div className="billing-cheque-section">
                    <div
                      className="billing-input-field-cheque"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="billing-cheque-number">
                        <input
                          type="text"
                          placeholder="cheque"
                          style={{ width: "53px" }}
                          value={paymentType1?.chequeNo}
                          onChange={changePaymentType("other","chequeNo",2)}
                        />
                      </div>
                      <div className="billing-cheque-date">
                        <Datepicker
                          type="date"
                          value={paymentType1?.chqDate}
                          onChange={changePaymentType("other","chqDate",2)}
                          placeholderText="DD/MM/YYYY"
                        />
                      </div>
                    </div>
                    <div className="billing-input-field-cheque-amount">
                      <input
                      value={paymentType1?.amount}
                      onChange={changePaymentType("other","amount",2)}
                      type="text" placeholder="00.00" />
                    </div>
                    
                  </div>
                </>
              )}
              {paymentMethod?.type2?.type === "cash" && (
                <>
                  <div className="billing-input-field-cheque-amount">
                      <input 
                      value={paymentType1?.amount}
                      onChange={changePaymentType("other","amount",2)}
                      type="text" placeholder="00.00" />
                    </div>
                  
                </>
              )}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="global-single-input billing-selectNew">
                  <Select
                  value={paymentMethod.type3}
                  onChange={changePaymentType("type","type3",3)}
                >
                  {
                    paymentTypesArray?.map((card,i)=>
                    <MenuItem 
                    key={i}
                    value={card} 
                    >{card?.journalName}</MenuItem>
                    )
                  }
                </Select>
                  </div>

                  {paymentMethod?.type3?.type === "card" && (
                <div className="billing-type-right-card-ctn">
                  <div
                    className="global-single-input billing-selectNew"
                    style={{ width: "50%" }}
                  >
                    <Select
                      value={paymentType2?.card}
                      onChange={changePaymentType("other","card",3)}
                    >
                      {
                        cardManual?.map((card,i)=>
                        <MenuItem
                          key={i}
                          value={card}
                          sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}
                          >
                            {card?.cardName}
                            <img
                            style={{width:"25px",height:"15px"}}
                            src={card?.imageUrl} alt="" />
                          </MenuItem>
                        )
                      }
                    </Select>
                  </div>
                  <div className="billing-card-number">
                    <input
                    value={paymentType2?.cardNo}
                    onChange={changePaymentType("other","cardNo",3)}
                    type="text" placeholder="number" />
                  </div>

                  <div
                  style={{width:"100%"}}
                  className="billing-card-number">
                    <input
                    value={paymentType2?.amount}
                    onChange={changePaymentType("other","amount",3)}
                    type="number" placeholder="Enter Amount" />
                  </div>
                </div>
              )}
              {paymentMethod?.type3?.type === "cheque" && (
                <>
                  <div className="billing-cheque-section">
                    <div
                      className="billing-input-field-cheque"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="billing-cheque-number">
                        <input
                         value={paymentType2?.chequeNo}
                         onChange={changePaymentType("other","chequeNo",3)}
                          type="text"
                          placeholder="cheque"
                          style={{ width: "53px" }}
                        />
                      </div>
                      <div className="billing-cheque-date">
                        <Datepicker
                          type="date"
                          value={paymentType2?.chqDate}
                          onChange={changePaymentType("other","chqDate",3)}
                          placeholderText="DD/MM/YYYY"
                        />
                      </div>
                    </div>
                    <div className="billing-input-field-cheque-amount">
                      <input
                      value={paymentType2?.amount}
                      onChange={changePaymentType("other","amount",3)}
                      type="text" placeholder="00.00" />
                    </div>
                    
                  </div>
                </>
              )}
              {paymentMethod?.type3?.type === "cash" && (
                <>
                  <div className="billing-input-field-cheque-amount">
                      <input
                      value={paymentType2?.amount}
                      onChange={changePaymentType("other","amount",3)}
                      type="number" placeholder="00.00" />
                    </div>
                  
                </>
              )}
                </div>
              </>
            )}

            <div className="billing-last-button">
              <button
              disabled={disablePlaceOrder}
              onClick={finalPlaceOrder}
              style={{cursor:disablePlaceOrder && "not-allowed"}}
              className="billing-placeOrder-button">Place Order</button>
              <button 
              // disabled={cartItems?.length}
              
              style={{cursor:cartItems?.length===0 && "not-allowed"}}
              className="billing-Hold-button"
              onClick={holdOrder}
              >Hold</button>
            </div>

            </div>
          </div>
        </div>
      </div>

      {/* popup to add customer */}
      <Dialog
       open={addCustomerDialog}
       onClose={closeCustomerDialog}
       maxWidth="lg"
       >
        <div className="billing-customer-ctn">
          <h4>Customer</h4>
          <div className="billing-customer-ctn-fields">
            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.name}
                onChange={getCustomerFormInfo("name")}
                id="outlined-basic"
                label="Name*"
                variant="outlined"
                type="text"
                focused />
                <p
                style={{top:"45%"}}
                className="doc-validation-alert">{validationAlert?.name}</p>
            </div>
            <div className="global-single-input">
              <p>Image Upload</p>
              <FilePicker
                id="addVendorImage"
                uploadImageUrl={customerImg}
                setImage={setCustomerImg}
                onChange={(e) => {
                    setCustomerImg(e.target.files[0]);
                }}
                style={{marginLeft:"-12%"}}
              />
            </div>
            <div className='new-global-single-input auto-complete-new'>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={countryWithState || []}
                getOptionLabel={(option)=>option?.country}
                renderInput={(params)=>(
                  <TextField {...params} label="Country" focused />
                )}
                value={customerFormValues?.country}
                onChange={getCustomerFormInfo("country")}
              />
            </div>
            <div className='new-global-single-input auto-complete-new'>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={customerFormValues?.country?.states || []}
                getOptionLabel={(option)=>option}
                renderInput={(params) => <TextField {...params} label="State" focused />}
                value={customerFormValues?.state}
                onChange={getCustomerFormInfo("state")}
              />
            </div>

            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.city}
                onChange={getCustomerFormInfo("city")}
                id="outlined-basic"
                label="City"
                variant="outlined"
                type="text"
                focused />
            </div>

            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.street}
                onChange={getCustomerFormInfo("street")}
                id="outlined-basic"
                label="Street"
                variant="outlined"
                type="text"
                focused />
            </div>

            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.zipCode}
                onChange={getCustomerFormInfo("zipCode")}
                id="outlined-basic"
                label="Zip Code"
                variant="outlined"
                type="text"
                focused />
            </div>

            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.taxId}
                onChange={getCustomerFormInfo("taxId")}
                id="outlined-basic"
                label="Tax ID"
                variant="outlined"
                type="text"
                focused />
            </div>

            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.mobile}
                onChange={getCustomerFormInfo("mobile")}
                id="outlined-basic"
                label="Mobile*"
                variant="outlined"
                type="text"
                focused />
                <p className="doc-validation-alert">{validationAlert?.mobile}</p>
            </div>

            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.email}
                onChange={getCustomerFormInfo("email")}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                type="text"
                focused />
            </div>
            
            <div className='new-global-single-input auto-complete-new'>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[]}
                getOptionLabel={(option)=>option}
                renderInput={(params) => <TextField {...params} label="Payment Terms" focused />}
                value={customerFormValues?.paymentTerms}
                onChange={getCustomerFormInfo("paymentTerms")}
              />
            </div>
            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.supplierCurrency}
                onChange={getCustomerFormInfo("supplierCurrency")}
                id="outlined-basic"
                label="Supplier Currency"
                variant="outlined"
                type="text"
                focused />
            </div>
            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.contactPerson}
                onChange={getCustomerFormInfo("contactPerson")}
                id="outlined-basic"
                label="Contact Person"
                variant="outlined"
                type="text"
                focused />
            </div>
            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.contactPersonMobile}
                onChange={getCustomerFormInfo("contactPersonMobile")}
                id="outlined-basic"
                label="Contact Person Mobile"
                variant="outlined"
                type="text"
                focused />
            </div>
            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.companyCode}
                onChange={getCustomerFormInfo("companyCode")}
                id="outlined-basic"
                label="Company Code"
                variant="outlined"
                type="text"
                focused />
            </div>
            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.branch}
                // onChange={getCustomerFormInfo("companyCode")}
                id="outlined-basic"
                label="Branch"
                variant="outlined"
                type="text"
                focused />
            </div>
            <div className='new-global-single-input auto-complete-new'>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[]}
                getOptionLabel={(option)=>option}
                renderInput={(params) => <TextField {...params} label="Customer Group" focused />}
                value={customerFormValues?.customerGroup}
                onChange={getCustomerFormInfo("customerGroup")}
              />
            </div>
            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.phone}
                onChange={getCustomerFormInfo("phone")}
                id="outlined-basic"
                label="Phone"
                variant="outlined"
                type="text"
                focused />
            </div>
            <div className="global-single-input" style={{alignItems:"start"}}>
              <p>In Active</p>
              <input 
              checked={customerFormValues?.inactive}
              onChange={(e)=>setCustomerFormValues({...customerFormValues,inactive:e.target.checked})}
              type="checkbox" className="toggle-checkbox"/>
            </div>
          </div>

          <div className="justify-center">
            <button
            onClick={closeCustomerDialog}
            className="btn btn-secondary-outlined">Cancel</button>
            <button className="btn btn-primary"
            onClick={addCustomerSubmit}
            >Submit</button>
          </div>
        </div>
      </Dialog>

      {/* popup showing customers credit list */}
      <Dialog
        open={openPreviousCreditDialog}
        maxWidth="lg"
        onKeyDown={(e) =>
          e.key === "Escape" && setOpenPreviousCreditDialog(false)
        }
        onClose={()=>setOpenPreviousCreditDialog(false)}
      >
        <div className="held-product-dialog">
          <h3>Credits</h3>
          <table className="held-products-table">
            <thead>
              <tr>
                <th>Order No</th>
                <th>Order Date</th>
                <th>Last Paid Date</th>
                <th>Net Amount</th>
                <th>Paid Amount</th>
                <th>Discount</th>
                <th>Balance</th>
                <th>select</th>
              </tr>
            </thead>
            <tbody>
              {(frontendCreditList?.length > 0 &&
                frontendCreditList?.map((r, i) => (
                  <tr key={i}>
                    <td>{r?.purchaseId}</td>
                    <td>{r?.purchaseDate}</td>
                    <td>{r?.lastPaidDate}</td>
                    <td>{r?.netAmount}</td>
                    <td>{r?.paidAmount}</td>
                    <td>{r?.discount}</td>
                    <td>{r?.balance}</td>
                    <td>
                      {/* <span className="held-products-pay">Pay</span> */}
                      <input
                        type="checkbox"
                        className="global-checkbox"
                        name=""
                        id=""
                        checked={r?.isChecked}
                        onClick={checkOrUncheckCreditItem(i)}
                      />
                    </td>
                  </tr>
                ))) || (
                <tr>
                  <td colSpan={8}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="held-product-button-container">
            <button
              onClick={() => setOpenPreviousCreditDialog(false)}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button
              className="btn btn-secondary"
              onClick={submitPreviousCreditDialog}
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>

    {/* view order dialog */}
      <Dialog
        open={viewOrderDialogOpen}
        maxWidth="lg"
        onClose={() => setViewOrderDialogOpen(false)}
        onKeyDown={(e) => e.key === "Escape" && setViewOrderDialogOpen(false)}
      >
        <div style={{ width: "1200px" }}>
          <OrderList isDialog={true} />
        </div>
      </Dialog>
  {/* held product dialog */}
      <Dialog
        open={heldProductDialog}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setHeldProductDialog(false)}
      >
        <div className="held-product-dialog">
          <h3>Held Products</h3>
          <table className="held-products-table">
            <thead>
              <tr>
                <th>Sl. No</th>
                <th>Customer Name</th>
                <th>Total Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {heldOrdersList !== undefined ? (
                heldOrdersList?.map((order, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{order?.customerName}</td>
                    <td>{order?.order?.finalBody?.totalAmount}</td>
                    <td>
                      <span
                        className="held-products-pay"
                        onClick={() => clickPayHeldOrder(order)}
                      >
                        Pay
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="held-product-button-container">
            <button
              onClick={() => setHeldProductDialog(false)}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
{/* invoice bill dialog */}
      <Dialog
        open={invoiceDialog}
        maxWidth="lg"
        onClose={() => setInvoiceDialog(false)}
        onKeyDown={(e) => e.key === "Escape" && setInvoiceDialog(false)}
      >
        {/* <Invoice data={invoiceData} /> */}
        <div className="billing-parent-wrapper" onScroll={hideInvoicePrint}>
          <div ref={(el) => (invoicePrint = el)}>
            <Invoice
              data={invoiceData}
            // invoiceData={generatedInvoice}
            />
          </div>
          <div className="invoice-print-icon-container" id="invoicePrintIcon">
            <ReactToPrint
              trigger={() => (
                <IconButton
                  className="invoice-print-btn"
                  sx={{
                    backgroundColor: "#d3e3ec",
                  }}
                // onClick={invoiceDialogClose}
                >
                  <PrintOutlined
                    className="print-icon"
                    sx={{ color: "#000" }}
                  />
                </IconButton>
              )}
              content={() => invoicePrint}
            />
          </div>
        </div>
      </Dialog>
      {/* alert snackbar */}
      <ErrorSnackbar
        style={{ top: "10%", left: "50%", height: "20px", width: "auto",transform:"translateX(-50%)",position:"absolute" }}
        open={snackBarStates.alert}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      {/* Backdrop on loading  */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 500 }}
        open={billLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
