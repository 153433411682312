import React, { useEffect, useState } from 'react'
import './InvoiceMarginReport.css'
import { FormControlLabel, IconButton, Skeleton, Switch } from '@mui/material';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import { ViewInvoiceMarginReportAPIcall } from './GeneralReportAPI';
import { useSelector } from 'react-redux';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import { convertDateFormat } from '../../../../../Js/Date';
import { useNavigate } from 'react-router-dom';
import { Bar } from "react-chartjs-2";
import { styled } from '@mui/material/styles';
import CustomPagination from '../../../../Single Components/CustomPagination';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../../Redux/store';


function InvoiceMarginReport() {
    const filtereDataOptions = useSelector((state) => state.listCategory);
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue)
    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );

    let navigate = useNavigate();

    const invoiceMarginList = useSelector((state) => state?.generalReport?.InvoiceMarginReport)
    console.log(invoiceMarginList, 'hhh')
    const decimalPosition = localStorage.getItem("decimalPosition");
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [FormDataInfo, setFormDataInfo] = useState([])
    const [symbol, setSymbol] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedButton, setSelectedButton] = useState('customer');
    const [showPaymentsContainer, setShowPaymentsContainer] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = invoiceMarginList?.pages;
    const handlePageChange = (page) => {

        setCurrentPage(page);
    }
    const handleChartViewToggle = () => {
        setShowPaymentsContainer(!showPaymentsContainer);
    };
    const handleBranchSelect = (selectedBranch) => {
        setSelectedBranch(selectedBranch); 
      
      };
    const IOSSwitch = styled(({ value, handleChange, ...props }) => (
        <Switch
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            checked={value}
            onChange={handleChange}
            {...props}
        />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    const handleDataFromChild = (data) => {
        setFormDataInfo(data);
    };
    const handleRowClick = (row) => {
        store.dispatch( setFilterActive(window.location.pathname))
        localStorage.setItem("invoiceSingleViewId", row?._id);
        navigate("/userdashboard/accounts/report/generalReport/InvoiceMarginReport/SingleView", { state: { invoiceNo: row?.invoiceNo } })
        // navigate("/userdashboard/accounts/report/generalReport/InvoiceMarginReport/SingleView")
    };
    const handleButtonClick = (buttonType) => {
        setSelectedButton(buttonType);
    };

    const labels = selectedButton === 'customer' ?
        invoiceMarginList?.report?.map(item => item?.vendorName) || [] :
        invoiceMarginList?.report?.map(item => `${item?.employeeName} - ${item?.invoiceNo}`) || [];

    const option = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: "Chart.js Bar Chart",
            },
        },
        scales: {
            y: {
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                    stepSize: 10,
                },
            },
        },
    };

    const datas = {
        labels,
        datasets: [
            {
                label: "Profit Margin",
                data: invoiceMarginList?.report?.map(item => item.profitMargin?.toFixed(decimalPosition)) || [],
                backgroundColor: "#05b30e",
                barThickness: 35,
            },
        ],
    };

    useEffect(() => {
        if (FormDataInfo.length !== 0) {
            let loadData=window.location.pathname===filtereDataOptions?.filterActive
            !loadData &&     ViewInvoiceMarginReportAPIcall({
                fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
                toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
                employee: FormDataInfo?.createdBy.length === 0 ? '' : FormDataInfo?.createdBy,
                vendor: FormDataInfo?.customer?.length === 0 ? '' : FormDataInfo?.customer,
                search: FormDataInfo?.search,
                branchId: FormDataInfo?.branchList,
                index: currentPage-1,
            })
        }
    }, [FormDataInfo, currentPage])

    useEffect(() => {
        let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
        setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])

    useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
    }, [])


    return (
        <div className="in-or-out-container">
            <div className="in-or-out-report-wrapper">
                <div className="sub-nav-head">
                    <div>
                        <div className="path-name-wrapper" style={{ backgroundColor: '#c3d4f7', maxWidth: "17em" }}>
                            <h3 style={{ color: '#06066f' }} className="path-name">Invoice Margin Report</h3>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}>
                        <CategorySearchandFilter
                            onData={handleDataFromChild}
                            // isCustomer={true}
                            isCreatedBy={true}
                            page="invoiceMarginReport"
                            onBranchSelect={handleBranchSelect}
                        />
                        <IconButton
                            style={{ width: "100px" }}

                            className="print-icon-container">
                            <i class="bi bi-printer printer-icon"></i>
                        </IconButton>
                        <IconButton
                            style={{ width: "100px" }}
                            className="download-icon-container">
                            <i class="bi bi-arrow-down-circle download-icon"></i>
                        </IconButton>
                    </div>
                </div>
                <div className="invoice-table-container">
                    <div className="invoice-main-div">
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: "98%"
                        }}>


                            <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }}
                                    defaultChecked
                                    value={showPaymentsContainer}
                                    handleChange={handleChartViewToggle} />}
                                labelPlacement="start"
                                label="Chart View"
                                onChange={handleChartViewToggle}
                            />

                            <CustomPagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}

                            />

                        </div>

                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Invoice</th>
                                    <th>Employee</th>
                                    <th>Customer</th>
                                    <th>Net Cost</th>
                                    <th>Revenue</th>
                                    <th>Profit </th>
                                    <th>Profit Margin %</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    [...Array(10)].map((r, i) => (
                                        <tr key={i}>
                                            <td colSpan={8}>
                                                <Skeleton
                                                    variant="rectangular"
                                                    animation="wave"
                                                    width={"100%"}
                                                    height={40}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                ) : invoiceMarginList?.report !== undefined && invoiceMarginList?.report?.length !== 0 ? (
                                    invoiceMarginList?.report
                                        ?.slice(0)
                                        ?.map((r, i) => (
                                            <tr key={i} onClick={() => handleRowClick(r)}>
                                                <td>{convertDateFormat(r?.invoiceDate)}</td>
                                                <td>{r?.invoiceNo}</td>
                                                <td>{r?.employeeName}</td>
                                                <td>{r?.vendorName}</td>
                                                <td style={{textAlign:"end"}}>{symbol}&nbsp;{r?.cost?.toFixed(decimalPosition)}</td>
                                                <td style={{textAlign:"end"}}>{symbol}&nbsp;{r?.revenue?.toFixed(decimalPosition)}</td>
                                                <td style={{textAlign:"end"}}>{symbol}&nbsp;{r?.profit?.toFixed(decimalPosition)}</td>
                                                <td style={{textAlign:"end"}}>{r?.profitMargin?.toFixed(decimalPosition)}</td>
                                            </tr>
                                        ))
                                ) : (
                                    <tr>
                                        <td style={{ textAlign: 'center' }} colSpan={8}>No Data</td>
                                    </tr>
                                )}
                            </tbody>
                            <tr style={{ textAlign: "end", position: "sticky", bottom: "0" }}>
                                <td colSpan={4} style={{ fontWeight: 'bold' }}>Total</td>
                                <td style={{ fontWeight: 'bold',textAlign:"end"}}>{symbol}&nbsp;{invoiceMarginList?.total !== undefined && invoiceMarginList?.total?.cost?.toFixed(decimalPosition)}</td>
                                <td style={{ fontWeight: 'bold',textAlign:"end"}}>{symbol}&nbsp;{invoiceMarginList?.total !== undefined && invoiceMarginList?.total?.revenue?.toFixed(decimalPosition)}</td>
                                <td style={{ fontWeight: 'bold',textAlign:"end"}}>{symbol}&nbsp;{invoiceMarginList?.total !== undefined && invoiceMarginList?.total?.profit?.toFixed(decimalPosition)}</td>
                                <td style={{ fontWeight: 'bold',textAlign:"end"}}>{invoiceMarginList?.total !== undefined && invoiceMarginList?.total?.profitMargin?.toFixed(decimalPosition)}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                {showPaymentsContainer && (
                    <div className="payments-container">
                        <div
                            className="top-bar"
                            style={{ marginTop: "4%", position: "relative", padding: "0 3%" }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <div className='invoice-button-ctn'>
                                    <button
                                        className={selectedButton === 'customer' ? 'selected-button' : 'non-selected-btn'}
                                        onClick={() => handleButtonClick('customer')}
                                    >
                                        Customer
                                    </button>
                                    <button
                                        className={selectedButton === 'employee' ? 'selected-button' : 'non-selected-btn'}
                                        onClick={() => handleButtonClick('employee')}
                                    >
                                        Employee
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* <div style={{ marginTop: "3%", width: "100%", position: "relative" }}>
                            <Bar options={option} data={datas} />
                        </div> */}
                        <div style={{ marginTop: "3%", width: "100%", position: "relative", overflowX: "auto" }}>
                            <Bar options={option} data={datas} />
                        </div>


                    </div>
                )}
            </div>
        </div>
    )
}

export default InvoiceMarginReport