import axios from "axios";
import { update_work_order_single, view_order_single_table_info } from "../../Redux/Orders/View Orders/workOrderSingleDataSlice";
import { update_work_orders_list } from "../../Redux/Orders/View Orders/workOrdersListSlice";
import store from "../../Redux/store";
import { HEADERS } from "../UrlAndPaths";
import { allWorkOrderList } from "../../Redux/Orders/View Orders/allWorkOrdersListSlice";
import { update_view_order_relation_list } from "../../Redux/Orders/View Orders/viewOrderSearchRelationSlice";


//getAllWorkOrdersAPIcall
export const getAllWorkOrdersAPIcall = async(body,setIsLoading)=>{
  setIsLoading(true)
  await axios.post("workorder/viewWorkorders",body,HEADERS)
  .then((res)=>{
    if(res.status === 200){
      store.dispatch(allWorkOrderList({
        allWorkOrderAlldata : res.data
      }))

      setIsLoading(false);
    }
  })
  .catch((err)=>{
    console.log("something is wronggg....")
    setIsLoading(false)

  })

}




//View Work orders by user
export const viewWorkOrdersByUserAPICall = async (body, setIsLoading) => {
  console.log("Body of View work order by user",body)
  setIsLoading(true);
  await axios
    .post("workorder/viewOrderUserFilter", body, HEADERS)
    .then((res) => {
      // console.log("Response data is --->",res.data)
      console.log(body)
      if (res.status === 200) {
        store.dispatch(update_work_orders_list({
           workOrdersList: res.data,
           index:body.index,
        }));
        setIsLoading(false);
      }
    })
    .catch((err) => {
      store.dispatch(update_work_orders_list({ workOrdersList: undefined }));
      setIsLoading(false);
      console.error(err.response);
    });
};
//View Work orders by Admin
export const viewWorkOrdersByAdminAPICall = async (body, setLoading) => {
  setLoading(true);
  await axios
    .post("workorder/viewOrderAdminFilter", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_work_orders_list({ workOrdersList: res.data }));
        setLoading(false);
      }
    })
    .catch((err) => {
      setLoading(false);
      store.dispatch(update_work_orders_list({ workOrdersList: undefined }));
      console.error(err.response);
    });
};

//User Search Work OrderList by orderNo,cusId,contactNo
export const searchWorkOrderListUserAPICall = async (body, setIsLoading) => {
  setIsLoading!==undefined &&setIsLoading(true);
  await axios
    .post("workorder/viewOrderSearchUserFilter", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_work_orders_list({ workOrdersList: res.data }));
        setIsLoading!==undefined && setIsLoading(false);
      }
    })
    .catch((err) => {
      console.error(err.response);
      store.dispatch(update_work_orders_list({ workOrdersList: undefined }));
      setIsLoading!==undefined && setIsLoading(false);
    });
};

//Admin Search Work OrderList by branchId,orderNo,cusId,contactNo
export const searchWorkOrderListAdminAPICall = async (body, setIsLoading) => {
  setIsLoading(true);
  await axios
    .post("workorder/viewOrderSearchAdminFilter", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_work_orders_list({ workOrdersList: res.data }));
        setIsLoading(false);
      }
    })
    .catch((err) => {
      store.dispatch(update_work_orders_list({ workOrdersList: undefined }));
      setIsLoading(false);
    });
};

//Single View of work order(also used in cutting slip)
export const workOrderSingleViewAPICall = async (
  body,
  setIsLoading,
  setSuccess,
  setError,
  setMsg
) => {
  setIsLoading !== undefined && setIsLoading(true);
  await axios
    .post("workorder/viewSingleCuttingSlip", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_work_order_single({ workOrderSingle: res.data }));
        setMsg("Details Fetched Successfully..");
        setIsLoading !== undefined && setIsLoading(false);
        setSuccess(true);
      }
    })
    .catch((err) => {
      setIsLoading !== undefined && setIsLoading(false);

      store.dispatch(update_work_order_single({ workOrderSingle: undefined }));
      switch (err.response.status) {
        case 404:
          setMsg("No details available!!");
          break;
        case 401:
          setMsg("Unauthorized!!");
          break;
        case 409:
          setMsg("conflict!!");
          break;
        case 500:
          setMsg("Server Error!!");
          break;
        case undefined:
          setMsg("Network error!! Please try again..");
          break;
        default:
          setMsg("Invalid response!! Refresh and try again...");
          break;
      }
      console.error(err.response);
      setError(true);
    });
};

//Measurement Updation API
export const updateMeasurementAPICall = async (body) => {
  await axios
    .post("workorder/measurementUpdation", body, HEADERS)
    .then((res)=>{
      if(res.status===200)
      {
        console.log('Measurement updated successfully...');
      }
    })
    .catch((err)=>{
      console.error(err.response.status);
    });
};


// view order by relation search

export const viewOrderByRelationSearchAPICall=async(body,setIsLoading)=>{
  setIsLoading!==undefined && setIsLoading(true)
    await axios.post("workorder/relationSearchUser",body,HEADERS)
     .then((res)=>{
        if(res.status===200){
          store.dispatch(update_view_order_relation_list({
            searchRelationData:res.data
          }))
          setIsLoading!==undefined && setIsLoading(false)

        }
     })
     .catch((err)=>{
      store.dispatch(update_view_order_relation_list({
        searchRelationData:undefined
      }))
      setIsLoading!==undefined && setIsLoading(false)

      console.error(err.res.data)
     })
}


// view single order details

export const viewOrderSingleTableInfo=async(body)=>{
  await axios.post("workorder/viewordersingle",body,HEADERS)
   .then((res)=>{
    if(res.status===200){
      store.dispatch(view_order_single_table_info(res?.data))
    }
   })
   .catch((err)=>{
    store.dispatch(view_order_single_table_info(undefined))
   })
}