import { Autocomplete, Dialog, IconButton, Skeleton, TextField, Tooltip } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { categoryListAPICall } from "../../Configuration/Category/CategoryAPI";
import { useSelector } from "react-redux";
import { useState } from "react";
import store from "../../../../../Redux/store";
import { get_inventory_product_row_list } from "../inventoryProductsSlice";
import { ProductListUpdatedFilterAPICall, deleteProductAPICall, exportProductListAPICall, importProductListAPICall, viewInventoryProductListAPICall } from "../inventoryProductsAPI";
import BrokenImageIcon from '../../../../../Assets/Images/brokenImg.png';
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import exportIcon from "../../../../../Assets/Images/Icon awesome-file-export.png"
import importIcon from "../../../../../Assets/Images/Icon awesome-file-import.png"
import uploadFile from "../../../../../Assets/Images/Icon feather-upload.png";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import { getCurrentTime } from "../../../../../Js/Date";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import axios from "axios";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
export const ProductListUpdated = () => {

    let navigateTo = useNavigate()
    const filtereDataOptions = useSelector((state) => state.listCategory);
    const tableData = useSelector((state) => state.inventoryProductSlice.apiResListProduct)
    const CategoryList = useSelector((state) => state.categorySlice.value);

    const [isLoading, setIsLoading] = useState(false)
    const [importPopup, setImportPopup] = useState(false)
    const [FormDataInfo, setFormDataInfo] = useState([])
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [fileToExport, setFileToExport] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = tableData?.pages;
    const [validationAlert, setValidationAlert] = useState({});
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [snackBarStates, setSnackBarStates] = useState({
        success: false,
        error: false,
        message: "",
        alert: false
    });
    const handleBranchSelect = (selectedBranch) => {
        setSelectedBranch(selectedBranch); 
      
      };
    const closeImportPopup = (e) => {
        setImportPopup(false)
        setFileToExport([])
    }
    const changeImage = (e) => {
        setFileToExport([...fileToExport, e.target.files[0]]);
        setValidationAlert({})
    };
    const closeSnackbar = () => {
        setSnackBarStates({
            ...snackBarStates,
            success: false,
            error: false,
            alert: false,
            message: "",
        });
    };
    const submitExportClick = () => {
        let newFormData = new FormData()

        // newFormData.append("_id",singleViewById)
        newFormData.append("file", fileToExport[0])

        if (fileToExport.length === 0) {
            setValidationAlert({ ...validationAlert, fileExport: "select a file !!" })
        }
        else {
            setIsLoading(true)
            importProductListAPICall(newFormData, setSnackBarStates, snackBarStates, closeImportPopup,setIsLoading)
        }
    }
    const singleViewFn = (data) => {
        store.dispatch( setFilterActive(window.location.pathname))
        navigateTo("/userdashboard/inventory/products/product/create")
        localStorage.setItem("singleViewById", data?._id)
        // store.dispatch(get_inventory_product_row_list({inventoryProductSingleData:data}))
    }
    console.log(tableData?.list,'ggg')
    // select all products
    const handleSelectAllChange = () => {
        if (selectAllChecked) {
            setSelectedRows([]);
        } else {
            const allRowIds = tableData?.list?.map((row) => row._id);
            setSelectedRows(allRowIds);
        }
        setSelectAllChecked(!selectAllChecked);
    };
    const checkBoxClick = (id) => () => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
            setSelectAllChecked(false);
  
        } else {
            setSelectedRows([...selectedRows, id]);
        }

    };
    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
    };
    const handlePageChange = (page) => {

        setCurrentPage(page);
    };


    const handleDelete = (id) => {
        deleteProductAPICall({ id }, setSnackBarStates)
    }
    useEffect(() => {
        // viewInventoryProductListAPICall({}, setIsLoading, setSnackBarStates, snackBarStates)
        categoryListAPICall()
        localStorage.removeItem("singleViewById")
        store.dispatch(get_inventory_product_row_list({ inventoryProductSingleData: undefined }))
    }, [])

    useEffect(() => {
      
        if (FormDataInfo.length !== 0) {
            let loadData=window.location.pathname===filtereDataOptions?.filterActive
            !loadData &&  ProductListUpdatedFilterAPICall({
                branchList: FormDataInfo.branchList,
                mainCategory: FormDataInfo.category,
                posCategory: FormDataInfo.posCategory,
                search: FormDataInfo.search,
                index: currentPage - 1,
            }, setIsLoading);
        }
        
    }, [FormDataInfo, currentPage]);
    return (
        <>
            <div className="global-page-parent-container">

                <div className="global-white-bg-container">
                    <div className="create-button-blue-container" style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <div className="export-actions-ctn">
                            <div
                                onClick={() => setImportPopup(true)}
                                className="export-actions-section">
                                <p>Upload</p>
                                <div className="import">
                                    <img src={importIcon} alt="" />
                                </div>
                            </div>
                            <div
                                onClick={() => exportProductListAPICall({ productIds: selectAllChecked ? [] : selectedRows, noProduct: selectedRows?.length === 0 }, setSnackBarStates, snackBarStates)}
                                className="export-actions-section">
                                <p>Download</p>
                                <div className="export">
                                    <img src={exportIcon} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="justify-space-between"
                            style={{ gap: "0 12px", justifyContent: "flex-end" }}
                        >
                            <CategorySearchandFilter
                                onData={handleDataFromChild}
                                isCategory={true}
                                isposCategory={true}
                                isDate={false}
                                onBranchSelect={handleBranchSelect}
                            />
                            <button
                                className='create-button-blue'
                                onClick={() => {
                                    navigateTo("/userdashboard/inventory/products/product/create")
                                }}>
                                Create</button>
                        </div>

                        {/* <button
                            className='create-button-blue'
                            onClick={() => {
                                navigateTo("/userdashboard/inventory/products/product/create")
                            }}>
                            Create</button> */}
                    </div>
                    <div className="justify-center" style={{ justifyContent: "flex-end" }}>
                        <CustomPagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            setFormDataInfo={setFormDataInfo}
                        />
                    </div>
                    <div className="global-table-container check-box-table">
                        <table className="global-table">
                            <thead>
                                <tr>
                                    <th>Product Id</th>
                                    <th>Part Name</th>
                                    <th>Part Number</th>
                                    <th>UOM</th>
                                    <th><input
                                        style={{ cursor: "pointer" }}
                                        type="checkbox"
                                        checked={selectAllChecked}
                                        onChange={handleSelectAllChange}
                                    /></th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    isLoading ? (
                                        [...Array(10)].map((r, i) => (
                                            <tr key={i}>
                                                <td colSpan={7}>
                                                    <Skeleton
                                                        variant="rectangular"
                                                        animation="wave"
                                                        width={"100%"}
                                                        height={40}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    )
                                        :
                                        (tableData?.list !== undefined && tableData?.list?.length !== 0) ?
                                            tableData?.list?.map((r, i) => (
                                                <tr key={i}>
                                                    <td onClick={() => singleViewFn(r)}>{r?.prodId}</td>
                                                    <td onClick={() => singleViewFn(r)}>{r?.name}</td>
                                                    <td onClick={() => singleViewFn(r)}>{r?.partNo}</td>
                                                    <td onClick={() => singleViewFn(r)}>{r?.UOMNAME || "---"}</td>
                                                    <td onClick={checkBoxClick(r?._id)}>
                                                        <input
                                                            style={{ cursor: "pointer" }}
                                                            checked={selectedRows.includes(r?._id)}

                                                            type="checkbox" />
                                                    </td>
                                                    <td>
                                                        <IconButton
                                                            onClick={() => handleDelete(r?._id)}
                                                        >
                                                            <i
                                                                class="bi bi-trash3 delete-icon"
                                                                style={{ fontSize: "15px" }}
                                                            >     </i>
                                                        </IconButton>
                                                    </td>
                                                </tr>
                                            )) :
                                            <tr>
                                                <td colSpan={7}>No data</td>
                                            </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            <ErrorSnackbar
                open={snackBarStates.alert}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
            <SuccessSnackbar
                open={snackBarStates.success}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
            <ErrorSnackbar
                open={snackBarStates.error}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
            <Dialog
                open={importPopup}
                maxWidth="lg"
                onClose={closeImportPopup}
            >
                <div className='actions-select-dropdown-dialog'>
                    <h4 style={{ margin: "0" }}>Upload</h4>
                    <hr className="global-hr" />
                    <div className="file-picker-new" style={{ position: "relative", margin: "10px 0" }}>
                        <input
                            // disabled={formValues?.isEdit} 
                            type="file"
                            id="fileUpload"
                            accept=".xlsx"
                            onChange={changeImage}
                            disabled={fileToExport?.length !== 0}
                        />
                        <label htmlFor="fileUpload"
                            style={{ cursor: fileToExport?.length !== 0 && "not-allowed" }}
                        >
                            <img src={uploadFile} alt="" />
                            Upload File
                        </label>

                        <p style={{ top: "70%" }} className="doc-validation-alert">{validationAlert?.fileExport}
                        </p>
                    </div>

                    {fileToExport.length !== 0 &&
                        <div style={{ width: "100%" }} className="global-single-input">
                            <p>{fileToExport[0]?.name}
                                <sup
                                    onClick={() => setFileToExport([])}
                                    style={{ fontSize: "1rem", color: "red", fontWeight: "600", cursor: "pointer" }}>X</sup>
                            </p>
                        </div>}
                    <div className='justify-center'>
                        <button
                            onClick={submitExportClick}
                            className="btn btn-primary">Submit</button>
                        <button className="btn btn-secondary-outlined"
                            onClick={closeImportPopup}
                        >Cancel</button>
                    </div>
                    <ErrorSnackbar
                        open={snackBarStates.error}
                        handleClose={closeSnackbar}
                        message={snackBarStates.message}
                    />
                    <SuccessSnackbar
                        open={snackBarStates.success}
                        handleClose={closeSnackbar}
                        message={snackBarStates.message}
                    />
                </div>
            </Dialog>
    <LoadingForm loading={isLoading} />

        </>
    )
}