import React, { useState } from "react";
import "../../../../css/Orders/workOrderPayment.css";
import CloseIcon from "@mui/icons-material/Close";
import {
  MenuItem,
  Select,
  Dialog,
  Tooltip,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useEffect } from "react";
import {
  getCreditOfCustomerAPICall,
  viewWalletTotalByCustomerIdAPICall,
} from "../../../../API/Credit and Wallet/creditAndWalletAPI";
import { useSelector } from "react-redux";
import { getCurrentTime, today } from "../../../../Js/Date";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { PrintOutlined } from "@mui/icons-material";
import { generateInvoiceAPICall } from "../../../../API/Orders/workOrderAPI";
import { viewCardManualAPICall } from "../../../../API/Settings/CardAndUpiType/cardManualAPI";
import { AlterationInvoice } from "../../../Modules/Orders/Alteration/AlterationInvoice";
import { Invoice } from "../../Sales/Billing/Invoice";
import { getDiscountAPICall } from "../../../../API/Settings/Discount/discountAPI";
import { convertPercentageToAmount, filterObjFromList } from "../../../../Js/generalFunctions";
// import BankList from '../../../../Assets/JSON/banknames.json';
import { Datepicker } from "../../../Single Components/Datepicker";
import { viewChequeManualAPICall } from "../../../../API/Settings/CardAndUpiType/ChequeManualAPI";
import PaymentsMethod from "../../../../Assets/JSON/PaymentMethod.json";
import { viewPosSettingsAPICall } from "../../PointOfSale/Configuration/Settings/posSettingsAPI";
import { paymentTypesForBilling } from "../../Sales/Billing/newBillingAPI";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { LoadingForm } from "../../../Single Components/LoadingForm";

export const PaymentsWO = (props) => {
  const {
    closePayments,
    marginTop,
    width,
    parentWidth,
    left,
    right,
    zIndex,
    boxShadow,
    cusId,
    paidAmount,
    shipmentCharge,
    // values,
    // onChange,
    bodyWOPayment,
    couponApply, //needs to be changed
    rewardAdd, // needs to be changed
    finalAPI,
    setOpenSuccessSnack,
    setOpenErrorSnack,
    setSnackMsg,
    clearWOState,
    updateListenerProp,
    setPaymnetDialog,
    // creditAdd,
    subTotal,
    // totalAmount,
    placeOrderClick,
    isMultiplePayment,
    clearListClick,
    holdClick,
    heldClick,
    paymentSection,
    woId,
    totalOrderAmount,

    returnPaymnetType,
    bodyWOPaymnetCustomer,
    branchId = null,
    closePaymentWindow,
    heldProductBtnRef,
    holdOrderAPICall,
    holdOrderBody,
    heldProductList = [],
    heldPayFn,
    exclusiveTax,
    inclusiveTax
  } = props;

  // console.log(paymentSection)
  // console.log("return paymentType---->",returnPaymnetType);
  // console.log("bodyWOPayment===>",bodyWOPayment);

  // console.log("paymentMethod---->",PaymentsMethod[0])
  let navigate = useNavigate();

  const storeCode = localStorage.getItem("branchId");
  //  console.log(branchId);
  //*Redux states
  let loginResponse = JSON.parse(localStorage.getItem("login_meta"));

  //Wallet of Selected Customer
  const customerWallet = useSelector(
    (state) => state.singleCustomerWalletSlice.value
  );
  //Credits of Selected Customer
  const customerCredit = useSelector(
    (state) => state.singleCustomerCreditSlice.value
  );
  //New WO id
  const newWorkOrderResponse = useSelector(
    (state) => state.newWorkOrderResponseSlice.value
  );
  //Generated Invoice
  const generatedInvoice = useSelector((state) => state.invoiceSlice.value);
  //Added Cards list
  const cardManual = useSelector((state) => state.cardManualSlice.value);
  // console.log("CardManual---->", cardManual);
  //added cheque List
  const chequeManual = useSelector((state) => state.chequeManualSlice.value);
  // pos settings list
  const posSettingsList=useSelector((state)=>state.posSettingSlice.apiResList)
  // payment types list
  const paymentTypeList = useSelector((state) => state.newBillingSlice.paymentTypeList);

  //Maximum Discount data
  const maximumDiscount = useSelector((state) => state.discountSlice.value);

  let invoicePrint = useRef();
  let alterationInvoicePrint = useRef();
  //invoice dialog
  const [invoiceDialog, setInvoiceDialog] = useState(false);
  //Update listener states
  const [isModify, setIsModify] = useState(false);
  // copy state
  const [isCopy, setIsCopy] = useState(false);
  //isLoading or not
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod1, setPaymentMethod1] = useState("card");

  const [paymentTypesArray, setPaymentTypesArray] = useState([]);

  // console.log("PaymentMethod--->",paymentMethod1)
  const [paymentMethod2, setPaymentMethod2] = useState("card");
  const [paymentMethod3, setPaymentMethod3] = useState("card");
  const [paymentMethod4, setPaymentMethod4] = useState("card");

  // console.log("PaymentMethod3--->", paymentMethod3);
  //Vender states
  const [vendor1, setVendor1] = useState(null);

  const [vendor2, setVendor2] = useState(null);
  const [vendor3, setVendor3] = useState(null);
  const [vendor4, setVendor4] = useState(null);
  const [vendor5, setVendor5] = useState(null);
  const [vendor6, setVendor6] = useState(null);

  //paid amount states
  const [paidAmount1, setPaidAmount1] = useState(null);
  const [paidAmount2, setPaidAmount2] = useState(null);
  const [paidAmount3, setPaidAmount3] = useState(null);
  // console.log("parseFloat---->", parseFloat(paidAmount3));
  const [paidAmount4, setPaidAmount4] = useState(null);
  const [paidAmount5, setPaidAmount5] = useState(null);

  const [paidAmount6, setPaidAmount6] = useState(null);
  const [paidAmount7, setPaidAmount7] = useState(null);

  // cheque Date
  const [chequeDate, setChequeDate] = useState();
  const [chequeDate1, setChequeDate1] = useState();
  const [chequeDate2, setChequeDate2] = useState();

  //chequeNo state

  const [chequeNo, setChequeNo] = useState();

  const [chequeNo1, setChequeNo1] = useState();
  const [chequeNo2, setChequeNo2] = useState();

  // console.log("chequeDate--->", chequeDate);

  const [multipleCheck, setMultipleCheck] = useState(false);
  const [heldProductDialog, setHeldProductDialog] = useState(false);
  //to know wallet redeemed or not
  const [isRedeemed, setIsRedeemed] = useState(false);
  //Billing Form initialState
  const billingFormInitialState = {
    shipmentCharge: shipmentCharge || 0,
    couponCode: "",
    rewardPoints: 0,
    paidAmount: paidAmount || 0,
    discount: 0,
    // wallet: 0,
    // netCredit: 0,
  };
  //Billing Form
  const [billingForm, setBillingForm] = useState(billingFormInitialState);
  //Customer Wallet
  const [wallet, setWallet] = useState(0);
  //Front end Credit List
  const [frontendCreditList, setFrontendCreditList] = useState([]);
  //checked Credits Filtered
  const [selectedCredits, setSelectedCredits] = useState([]);
  //Credits Data for backend
  const [creditsForBackend, setCreditsForBackend] = useState([]);
  //Customer Net credit
  const [netCredit, setNetCredit] = useState(0);
  //O/s balance
  const [osBalance, setOsBalance] = useState(0);
  //Total Amount
  const [totalAmount, setTotalAmount] = useState(0);
  //Order Total[ total amount that sends to backend]
  const [orderTotal, setOrderTotal] = useState(0);
  //Previous Credits dialog State
  const [openPreviousCreditDialog, setOpenPreviousCreditDialog] =
    useState(false);
  // Change Amount
  const [changeAmount, setChangeAmount] = useState(0);
  //Wallet btn txt
  const [walletBtnTxt, setWalletBtnTxt] = useState("Redeem");
  //PaymentMethod body state
  const [paymentMethodBody, setPaymentMethodBody] = useState([]);
  //Balance amount
  const [cusBalance, setCusBalance] = useState(0);

  //Maximum discount amount
  const [maxDiscountAmt, setMaxDiscountAmt] = useState(null);

  // wallet redeem click
  const [redeemClickBtn, setRedeemClickBtn] = useState(false);

  const [loadingForm,setLoadingForm]=useState(false)
  //*onChange Fn
  const getBillingFormData = (key) => (e) => {
    const { value } = e.target;
    const limit = totalAmount;

    switch (key) {
      case "shipmentCharge":
        setBillingForm({ ...billingForm, shipmentCharge: value });
        break;
      case "couponCode":
        setBillingForm({ ...billingForm, couponCode: value });
        break;
      case "rewardPoints":
        setBillingForm({ ...billingForm, rewardPoints: value });
        break;
      case "paidAmount":
        // if(value>limit){
        //   setBillingForm({ ...billingForm, paidAmount: limit });
        // }
        // else{
        setBillingForm({ ...billingForm, paidAmount: value });
        // }

        break;
      case "discount":
        if (maxDiscountAmt !== null) {
          if (maxDiscountAmt < value) {
            alert(
              "Discount is greater than allowed limit!! Maximum discount : " +
                maxDiscountAmt
            );
          } else {
            setBillingForm({ ...billingForm, discount: value });
          }
        } else {
          setBillingForm({ ...billingForm, discount: value });
        }

        break;
      // case "wallet":
      //   setBillingForm({ ...billingForm, wallet: value });
      //   break;
      // case "netCredit":
      //   setBillingForm({ ...billingForm, netCredit: value });
      //   break;

      default:
        break;
    }
  };
  //Get Wallet used
  const getWallet = (e) => {
    setWallet(e.target.value);
  };

  //*Fns
  //UpdateListener
  const updateListener = () => {
    setIsModify(!isModify);
  };
  //hide invoice print button on scroll
  const hideInvoicePrint = () => {
    document.getElementById("invoicePrintIcon").style.display = "none";
  };
  //Calculate change which must be return to customer
  const calculateChangeAmount = (totalAmount, paidAmount) => {
    let change = 0;
    change = parseFloat(paidAmount) - parseFloat(totalAmount);
    if (isNaN(change) || change < 0) return 0;
    return change;
  };

  //Redeem Amount From Wallet[totalAmount-redeemed]
  const redeemFromWallet = (totalAmount, redeemAmount) => () => {
    // setRedeemClickBtn(true);
    if (customerWallet !== undefined) {
      let walletTot = customerWallet?.amount;
      let newTotal = 0;
      let walletBalance = 0;
      if (redeemAmount > walletTot) {
        alert(`Redeemed amount must be less than or equal to ${walletTot}`);
      } else {
        if (redeemAmount > totalAmount) {
          alert("Redeemed amount must be less than or equal to total amount!!");
        } else {
          setRedeemClickBtn(true);
          newTotal = parseFloat(totalAmount) - parseFloat(redeemAmount);
          walletBalance = parseFloat(walletTot) - parseFloat(redeemAmount);
          setTotalAmount(newTotal);
          setWallet(walletBalance);
          alert("Amount redeemed from wallet successfully👍");
          // setIsRedeemed(true); commented on 11-11-22 as per key usage change; isWallet is used for add extra amount to customer credit
        }
      }
    } else {
      alert("Can not redeem..,Wallet is empty!!");
    }
  };
  // console.log(customerWallet?.amount);
  //Multiple payment checkbox click fn
  const clickMultipleCheck = (e) => {
    setMultipleCheck(e.target.checked);
  };
  //calculate TotalAmount
  const calcTotalAmount = () => {
    let tot = 0;
    let orderTot = 0;
    tot =
      parseFloat(subTotal || 0) +
      parseFloat(billingForm?.shipmentCharge) +
      parseFloat(netCredit) -
      parseFloat(billingForm.discount);
    orderTot =
      parseFloat(subTotal || 0) +
      parseFloat(billingForm?.shipmentCharge) -
      parseFloat(billingForm.discount);
    if (isNaN(tot)) setTotalAmount(subTotal);
    else setTotalAmount(tot);
    if (isNaN(orderTot)) setOrderTotal(subTotal);
    else setOrderTotal(orderTot);
  };
  //Calculate Total Credit Amount
  const calTotCreditAmount = (credits) => {
    let creditTot = 0;
    credits?.forEach((obj) => {
      creditTot += parseFloat(obj?.balance);
    });
    // setNetCredit(creditTot);
    setOsBalance(creditTot);
  };

  //Create Front end Credit list with isChecked injected
  const createCreditListForFrontend = (apiResponse) => {
    if (apiResponse !== undefined) {
      let newCreditList = apiResponse?.map((obj) => {
        return { ...obj, isChecked: false };
      });
      setFrontendCreditList(newCreditList);
    } else {
      setFrontendCreditList([]);
    }
  };

  //Check or uncheck credit item
  const checkOrUncheckCreditItem = (index) => (e) => {
    const { checked } = e.target;
    if (frontendCreditList?.length > 0) {
      if (checked) {
        frontendCreditList[index].isChecked = true;
        // frontendCreditList?.forEach((obj,i)=>{
        //   if(i===index) obj.isChecked=true
        // })
      } else {
        frontendCreditList[index].isChecked = false;
        // frontendCreditList?.forEach((obj,i)=>{
        //   if(i===index) obj.isChecked=false
        // })
      }

      setFrontendCreditList([...frontendCreditList]);
    }
  };

  //Create Selected(checked) Credit Items Array
  const createSelectedCreditItems = (completeCreditList) => {
    let filteredList = [];
    if (completeCreditList !== undefined) {
      filteredList = completeCreditList?.filter(
        (obj) => obj.isChecked === true
      );
      setSelectedCredits(filteredList);
    } else {
      setSelectedCredits([]);
    }
  };

  //Create Credits for backend
  const createCreditListForBackend = (selectedCredits) => {
    let creditsForBackend = [];

    creditsForBackend = selectedCredits?.map((obj) => {
      return { order_id: obj.purchasePk, balance: obj.balance };
    });
    setCreditsForBackend(creditsForBackend);
  };
  //Calculate Total amount of credit selected from dialog
  const calculateTotalSelectedCreditAmount = () => {
    let selectedCreditTot = 0;
    selectedCredits?.forEach((obj) => {
      selectedCreditTot += parseFloat(obj?.balance);
    });
    if (isNaN(selectedCreditTot)) {
      setNetCredit(0);
    } else {
      setNetCredit(selectedCreditTot);
    }
  };
  //set credit,wallet amount initially onchange of paid amount and total amount
  const setInitialCreditAmount = (totalAmt, paidAmt) => {
    let initialCredit = 0;
    let initialWallet = 0;
    if (paidAmt < totalAmt) {
      initialCredit = parseFloat(totalAmt) - parseFloat(paidAmt);
      initialWallet = 0;
    } else if (paidAmt === totalAmt) {
      initialCredit = 0;
      initialWallet = 0;
    } else {
      initialWallet = parseFloat(paidAmt) - parseFloat(totalAmt);
      initialCredit = 0;
    }

    setCusBalance(isNaN(initialCredit) ? 0 : initialCredit);
    // setWallet(initialWallet);
  };

  //Click Previous Credit dialog Submit Fn
  const submitPreviousCreditDialog = () => {
    createCreditListForBackend(selectedCredits);
    calculateTotalSelectedCreditAmount();
    setOpenPreviousCreditDialog(false);
  };

  //Create Payment method body
  const createPaymentMethodBody = () => {
    let paymentMethodBody = [];
    if (multipleCheck) {
      //in case of multiple payment


      paymentMethodBody = [
        {
          type: paymentMethod1?.type,
          account:  paymentMethod1?.journalId || null,
          date: `${today} ${getCurrentTime()}`,
          paidAmount: paidAmount1 ? parseFloat(paidAmount1) : null,
          vendor: paymentMethod1?.type === 'card' ? vendor1 : null,
          receiptNo: paymentMethod1?.type === 'card' ? chequeNo : null,
          chequeDate: chequeDate ? chequeDate : null,
          chequeNo: chequeNo ? chequeNo : null,
          cardCommission:
            paymentMethod1?.type === 'card'
                ? filterObjFromList("cardName",cardManual,"cardName",{cardName:vendor1})?.commission || 0
                : 0,
        },
        {
          type: paymentMethod2?.type,
          account: paymentMethod2?.journalId || null,
          date: `${today} ${getCurrentTime()}`,
          paidAmount: paidAmount2 ? parseFloat(paidAmount2) : null,
          vendor: paymentMethod2?.type === 'card' ? vendor2 : null,
          receiptNo: paymentMethod1?.type === 'card' ? chequeNo1 : null,

          chequeDate: chequeDate1 ? chequeDate1 :null,
          chequeNo: chequeNo1 ? chequeNo1 : null,
          cardCommission:
            paymentMethod2?.type === 'card'
                ? filterObjFromList("cardName",cardManual,"cardName",{cardName:vendor2})?.commission || 0
                : 0,
        },

        {
          type: paymentMethod3?.type,
          account:paymentMethod3?.journalId || null,
          date: `${today} ${getCurrentTime()}`,
          paidAmount: paidAmount4 ? parseFloat(paidAmount4) : null,
          vendor: paymentMethod3?.type === 'card' ? vendor4 : null,
          chequeDate: chequeDate2 ? chequeDate2 : null,
          chequeNo: chequeNo2 ? chequeNo2 :null,
          receiptNo: paymentMethod1?.type === 'card' ? chequeNo2 : null,

          cardCommission:
            paymentMethod3?.type === 'card'
                ? filterObjFromList("cardName",cardManual,"cardName",{cardName:vendor4})?.commission || 0
                : 0,
        },
      ];



    } //in case of single payment

    else {
      if (paymentMethod1?.type === "card") {
        paymentMethodBody = [
          {
            type: paymentMethod1?.type,
            account:paymentMethod1?.journalId || null,
            date: `${today} ${getCurrentTime()}`,
            paidAmount: parseFloat(billingForm?.paidAmount),
            receiptNo: paymentMethod1?.type === 'card' ? chequeNo : null,

            vendor: vendor1,
            cardCommission:
            paymentMethod1?.type === 'card'
                ? filterObjFromList("cardName",cardManual,"cardName",{cardName:vendor1})?.commission || 0
                : 0,
          },
        ];
      }

      if (paymentMethod1?.type === "cash") {
        paymentMethodBody = [
          {
            type: paymentMethod1?.type,
            account: paymentMethod1?.journalId || null,
            date: `${today} ${getCurrentTime()}`,
            paidAmount: parseFloat(billingForm?.paidAmount),
            vendor: vendor1,
            cardCommission:0,
          },
        ];
      }

      if (paymentMethod1?.type === "cheque") {
        console.log("<<<<<.....cheque selected......>>>>>>>");
        paymentMethodBody = [
          {
            type: paymentMethod1?.type,
            account: paymentMethod1?.journalId || null,
            date: `${today} ${getCurrentTime()}`,
            paidAmount: parseFloat(billingForm?.paidAmount),
            // paidAmount: parseFloat(paidAmount3),
            vendor: vendor3,
            chequeDate: chequeDate,
            chequeNo: chequeNo,
            cardCommission:0,

          },
        ];
      
      }
    }

  
    console.log("paymentMEthod BOdy--->",paymentMethodBody);

    let data1 = paymentMethodBody.filter((item)=>item.paidAmount !== null)
    console.log("filterddata--->",data1)
    if(data1[2]){
      // console.log("data1[2]",data1[2])
      if(data1[2].type == "card" || data1[2].type == "cheque" ){
        if(data1[2].vendor === null){

          console.log("data1---->",data1)
          
        //  let popped_object = data1.pop();
        data1.pop();
         console.log(data1)
        }
      }
    }
    // console.log(data1)
    return data1;
    
  };

  //Form Payment method body
  const formPaymentMethodBody = () => {
    let paymentMethodBody = createPaymentMethodBody();
    // if (multipleCheck) {
    //   //in case of multiple payment
    //   paymentMethodBody = [
    //     {
    //       type: paymentMethod1?.type,
    //       account: null, //get account if Cash null, if Card null, if UPI abc@ybl
    //       date: `${today} ${getCurrentTime()}`,
    //       paidAmount: parseFloat(paidAmount1),
    //       vendor: vendor1,
    //     },
    //     {
    //       type: paymentMethod2?.type,
    //       account: null, //get account if Cash null, if Card null, if UPI abc@ybl
    //       date: `${today} ${getCurrentTime()}`,
    //       paidAmount: parseFloat(paidAmount2),
    //       vendor: vendor2,
    //     },
    //   ];
    // } //in case of single payment
    // else {
    //   paymentMethodBody = [
    //     {
    //       type: paymentMethod1?.type,
    //       account: null,
    //       date: `${today} ${getCurrentTime()}`,
    //       paidAmount: parseFloat(billingForm?.paidAmount),
    //       vendor: vendor1,
    //     },
    //   ];
    // }

    createFinalBody(paymentMethodBody);
    // setPaymentMethodBody(paymentMethodBody);
  };

  //Create final body with payments
  const createFinalBody = (paymentMethodBody) => {
    if (bodyWOPayment !== undefined || holdOrderBody !== undefined) {
      // bodyWOPayment.paymentMethod = paymentMethodBody;
      //  bodyWOPayment.paymentMethod = paymentMethod1?.type;
      if (paymentSection === "salesReturn") {
        if (returnPaymnetType === "customer") {
          bodyWOPayment.paymentMethod = paymentMethodBody;
          bodyWOPayment.billPaymentMethod = null;
        }
        if (returnPaymnetType === "company") {
          bodyWOPayment.billPaymentMethod = paymentMethodBody;
          bodyWOPayment.paymentMethod = null;
        }
      } else {
        bodyWOPayment.paymentMethod = paymentMethodBody;
      }
      if (paymentSection === "salesReturn") {
        bodyWOPayment.total = bodyWOPayment.total;
      } else {
        bodyWOPayment.total = subTotal;
      }

      // bodyWOPayment.total = orderTotal;
      bodyWOPayment.discount = 0;
      if (paymentSection === "salesReturn") {
        if (bodyWOPayment.shipmentCharge > 0) {
          bodyWOPayment.shipmentCharge = bodyWOPayment.shipmentCharge;
        } else {
          bodyWOPayment.shipmentCharge = parseFloat(billingForm.shipmentCharge);
        }
      } else {
        bodyWOPayment.shipmentCharge = parseFloat(billingForm.shipmentCharge);
      }

      bodyWOPayment.usedPoints = 0;
      if (paymentSection === "salesReturn") {
        bodyWOPayment.branchId = bodyWOPayment.branchId;
      } else {
        bodyWOPayment.branchId = bodyWOPayment.branchId || branchId;
      }

      bodyWOPayment.discount = parseFloat(billingForm?.discount);
      bodyWOPayment.creditCleared =
        creditsForBackend.length > 0 ? creditsForBackend : null;
      if (paymentSection === "delivery") bodyWOPayment.id = woId;
      if (paymentSection === "viewOrders") bodyWOPayment._id = woId;
      if (paymentSection === "alteration") bodyWOPayment._id = woId || null;

      bodyWOPayment.isWallet = isRedeemed;
      {
        redeemClickBtn === true
          ? (bodyWOPayment.usedWallet = customerWallet?.amount-wallet)
          : (bodyWOPayment.usedWallet = 0);
      }

      console.log("Payment method created");
    } else {
      console.log("body WO payment is undefined");
    }
  };

  //Generate invoice of the Order
  const generateInvoice = () => {
    if (newWorkOrderResponse !== undefined) {
      const { _id } = newWorkOrderResponse;
      generateInvoiceAPICall({ _id }, openInvoiceDialog);
    }
  };
  // console.log(isLoading);

  //place order api call function
  const placeOrderApiFunction = () => {
    if (Object.keys(bodyWOPayment)?.includes("paymentMethod") === true) {

      
  finalAPI(
    bodyWOPayment,
    setIsLoading,
    setOpenSuccessSnack,
    setOpenErrorSnack,
    setSnackMsg,
    updateListenerProp,
    clearWOState,
    openInvoiceDialog,
    clearBillingForm,
    paymentSection === "salesReturn" && setPaymnetDialog
  );
    } else {
      alert("No payment method created try again!!");
    }
  };  

  const paymentMethodCheck =()=>{
    if(posSettingsList?.posStatus){
      paymentTypeList===undefined && 
      Swal.fire({
        text: 'Payment Methods are not added for this branch do you want to add',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ok',
        cancelButtonText: 'Cancel',
        reverseButtons: true, // To swap the positions of the buttons
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.setItem("singleViewById",loginResponse?.branchPk)
          navigate("/userdashboard/pointOfSales/configuration/BranchSettings/create")
     
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    }
  }


  const walletStateChangeFn = () => {
    setIsRedeemed(true);
    // console.log(paymentMethodBody);
    setTimeout(() => {
      placeOrderApiFunction();
    }, 1000);
  };

  //Check to alert credit or not
  const alertCreditAndPlaceOrder = () => {
    if (billingForm?.paidAmount < totalAmount) {
      if (
        window.confirm(
          "Paid amount is less than total amount!! \nbalance amount will be added to customer credit \n would you like to continue??"
        ) === true
      ) {
        placeOrderApiFunction();
      } else {
        console.log("Cancelled");
      }
    } else if (billingForm?.paidAmount > totalAmount) {
      if (
        window.confirm(
          "Paid amount is greater than total amount!! \n Would you like to add change to customer wallet?? \n If not change paid amount!! "
        ) === true
      ) {
        //  placeOrderApiFunction();
        walletStateChangeFn();
      } else {
        console.log("Cancelled");
        placeOrderApiFunction();
      }
    } else {
      placeOrderApiFunction();
    }
  };

  // return alert
  const returnAlertAndPlaceOrder = () => {
    if (returnPaymnetType === "customer" && cusId !== "cash") {
      if (
        window.confirm("Would you like to add amount to customer wallet") ===
        true
      ) {
        console.log("add to wallet");
        walletStateChangeFn();
      } else {
        placeOrderApiFunction();
      }
    } else {
      placeOrderApiFunction();
    }
  };
  //Final API call
  const finalPlaceOrder = () => {
    if (cusId === undefined || cusId === "0") {
      alert("Select a customer");
    } else if (multipleCheck) {
      if (paymentMethod1?.type === "card" || paymentMethod1?.type ==="cheque") {
        if (vendor1 === null) {
          // alert("Please select a card vendor!!");
          alert("Please select a vendor 1 !!");
        }
        else if(chequeNo===null || chequeNo===""){
          alert("Please enter approval no.");
        }
        else {
          if (paymentMethod2?.type === "card" || paymentMethod2?.type ==="cheque") {

            if (vendor2 === null) {
              alert("Please select a vendor 2  !!");
            } else {
              //Placing order
              if (paidAmount1 === null) {
                alert("Enter split amount 1!!");
              } else if (paidAmount2 === null) {
                alert("Enter split amount 2!!");
              } else {
                if (returnPaymnetType === "customer") {
                  returnAlertAndPlaceOrder();
                } else {
                  alertCreditAndPlaceOrder();
                }
              }
            }
          } else {
            //Placing order
            if (paidAmount1 === null) {
              alert("Enter split amount 1!!");
            } else if (paidAmount2 === null) {
              alert("Enter split amount 2!!");
            } else {
              if (returnPaymnetType === "customer") {
                returnAlertAndPlaceOrder();
              } else {
                alertCreditAndPlaceOrder();
              }
            }
          }
        }
      } else {
        if (paymentMethod2?.type === "card") {
          if (vendor2 === null) {
            alert("Please select a card vendor");
          } else {
            //Placing order
            if (paidAmount1 === null) {
              alert("Enter split amount 1!!");
            } else if (paidAmount2 === null) {
              alert("Enter split amount 2!!");
            } else {
              if (returnPaymnetType === "customer") {
                returnAlertAndPlaceOrder();
              } else {
                alertCreditAndPlaceOrder();
              }
            }
          }
        } else {
          //Placing order
          if (paidAmount1 === null) {
            alert("Enter split amount 1!!");
          } else if (paidAmount2 === null) {
            alert("Enter split amount 2!!");
          } else {
            if (returnPaymnetType === "customer") {
              returnAlertAndPlaceOrder();
            } else {
              alertCreditAndPlaceOrder();
            }
          }
        }
      }
      

    } else if (!multipleCheck) {
      if (paymentMethod1?.type === "card") {
        if (vendor1 === null) {
          alert("Please select a card vendor!!");
        } else {
          //Placing order
          if (returnPaymnetType === "customer") {
            returnAlertAndPlaceOrder();
          } else {
            alertCreditAndPlaceOrder();
          }
        }
      }

      // cheque


      if (paymentMethod1?.type === "cheque") {
        // alert("Payment method is Cheque")
        if (
          vendor3 === null
        ) {
          alert("Please Check the Bank");
        } 
        else if(chequeNo === undefined ){
          alert("Please fill Cheque No")

        }
        else if(chequeDate === undefined){
          alert("Please fill Cheque Date")

        }
        
        else {
          // alert("leeyet");
          if (returnPaymnetType === "customer") {
            returnAlertAndPlaceOrder();
          } else {
            alertCreditAndPlaceOrder();
          }
        }
      }

      if (paymentMethod1?.type === "cash") {
        if (returnPaymnetType === "customer") {
          returnAlertAndPlaceOrder();
        } else {
          alertCreditAndPlaceOrder();
        }
      }

      // else {
      //   //Placing order
      //   if (returnPaymnetType === "customer") {
      //     returnAlertAndPlaceOrder();
      //   } else {
      //     alertCreditAndPlaceOrder();
      //   }
      // }
    }
  };

  //Clear Billing Form
  const clearBillingForm = () => {
    setBillingForm(billingFormInitialState);
    setVendor1(null);
    setVendor2(null);
    setVendor3(null);
    setVendor4(null);
    setVendor6(null);
    setPaidAmount4(null);
    setPaidAmount5(null);
    setPaidAmount6(null);
    setPaidAmount7(null);
    setChequeDate(null);
    setChequeDate1(null);
    setChequeDate2(null);
    setChequeNo(null);
    setChequeNo1(null);
    setChequeNo2(null);

    setVendor5(null);

    setPaidAmount1(null);
    setPaidAmount2(null);
    setPaidAmount3(null);
    setIsRedeemed(false);
    setSelectedCredits([]);
    setCreditsForBackend([]);
    setNetCredit(0);
    // setTotalAmount(0);
    setPaymentMethodBody([]);
    updateListener();
  };

  // console.log(generatedIn);
  //Open Invoice Dialog
  const openInvoiceDialog = () => {
    setInvoiceDialog(true);
  };

  //Hold Order Fn
  const holdOrder = () => {
    formPaymentMethodBody();

    setTimeout(() => {
      holdOrderBody.order.paymentMethod = createPaymentMethodBody();
    }, 1);

    setTimeout(() => {
      if (holdOrderAPICall !== undefined) {
        if (cusId === undefined || cusId === "0") {
          alert("select a customer first!!");
        } else {
          holdOrderAPICall(
            holdOrderBody,
            setOpenSuccessSnack,
            setOpenErrorSnack,
            setSnackMsg,
            clearWOState,
            closePaymentWindow
          );
        }
      } else {
        alert("Hold Function is not assigned!!");
      }
    }, 2);
  };

  // console.log(frontendCreditList);
  // console.log(selectedCredits);
  // console.log(creditsForBackend);
  // console.log(orderTotal);
  // console.log(paymentMethodBody);
  // console.log(bodyWOPayment);
  //Click Pay button in held list dialog
  const clickHeldPayBtn = (payingIndex) => {
    //function received as prop to pass index into higher component
    if (heldPayFn !== undefined) heldPayFn(payingIndex);
    setHeldProductDialog(false);
  };

  //Call Maximum discount percentage API call
  useEffect(() => {
    getDiscountAPICall();
    viewPosSettingsAPICall()
    paymentTypesForBilling({branchId:storeCode})
  }, []);

  // viewCardManual APICall
  useEffect(() => {
    viewCardManualAPICall();
  }, [isModify]);

  //Call Customer Wallet And Credit API
  useEffect(() => {
    if (cusId !== undefined) {
      //Get Wallet amount of customer
      viewWalletTotalByCustomerIdAPICall({ cus_id: cusId });
      //Get Credits of Customer
      getCreditOfCustomerAPICall({ _id: cusId });
    }
  }, [cusId]);

  //Check multiple payment or not
  useEffect(() => {
    if (isMultiplePayment !== undefined) {
      isMultiplePayment(multipleCheck);
    }
  }, [multipleCheck]);

  //Call Calculate Change fn
  useEffect(() => {
    let change = calculateChangeAmount(totalAmount, billingForm?.paidAmount);
    setChangeAmount(change);
    if (change > 0) {
      setWalletBtnTxt("Add");
    } else {
      setWalletBtnTxt("Redeem");
    }
  }, [billingForm?.paidAmount, totalAmount]);

  //calculate Total Amount
  useEffect(() => {
    calcTotalAmount();
  }, [subTotal, billingForm?.shipmentCharge, netCredit, billingForm.discount]);

  //Set Customer Wallet into State
  useEffect(() => {
    if (customerWallet !== undefined) {
      setWallet(customerWallet?.amount);
    }
    if (customerWallet === undefined) {
      setWallet(0);
    }
  }, [customerWallet]);
  //Call total Credit Amount
  useEffect(() => {
    if (customerCredit !== undefined) {
      calTotCreditAmount(customerCredit);
      createCreditListForFrontend(customerCredit);
    }
    if (customerCredit === undefined) {
      setNetCredit(0);
      setOsBalance(0);
      setCreditsForBackend([]);
      setFrontendCreditList([]);
      setSelectedCredits([]);
    }
  }, [customerCredit]);

  //Call Create Selected Credit List
  useEffect(() => {
    createSelectedCreditItems(frontendCreditList);
  }, [frontendCreditList]);

  //set vendor null when cash method
  useEffect(() => {
    if (paymentMethod1?.type === "cash") setVendor1(null);
    if (paymentMethod2?.type === "cash") setVendor2(null);
  }, [paymentMethod1, paymentMethod2]);

  //Create Final body
  useEffect(() => {
    if (paymentSection === "salesReturn") {
      if (paymentMethodBody?.length > 0) {
        createFinalBody(paymentMethodBody);
      }
      if (isRedeemed) {
        bodyWOPayment.isWallet = true;
      } else {
        bodyWOPayment.isWallet = false;
      }
    } else {

      if (paymentMethodBody?.length > 0) {
        createFinalBody(paymentMethodBody);
      }
      if (isRedeemed) {
        bodyWOPayment.isWallet = true;
      } else {
        bodyWOPayment.isWallet = false;
      }
      // createFinalBody(paymentMethodBody);
    }
  }, [
    paymentMethodBody,
    billingForm.shipmentCharge,
    creditsForBackend,
    isRedeemed,
  ]);

  //filter credit list excluding current order in case of delivery
  useEffect(() => {
    if (
      paymentSection === "delivery" ||
      paymentSection === "viewOrders" ||
      paymentSection === "alteration"
    ) {
      // console.log(woId);
      let _deliveryCreditList = frontendCreditList?.filter(
        (obj) => obj?.purchasePk !== woId
      );
      setFrontendCreditList(_deliveryCreditList);
      // console.log('fn invoked');
      // console.log(_deliveryCreditList);
    }
  }, [woId, frontendCreditList]);

  // const scrollFunction=()=>{
  //   const element=document.getElementById('paymentDiv')
  //   element.scrollTop=50;
  // }
  //   console.log(paymentMethod1);
  //   console.log(paymentMethod2);
  // console.log(totalAmount);

  //Set credit and wallet amount initially
  useEffect(() => {
    setInitialCreditAmount(totalAmount, billingForm.paidAmount);
  }, [billingForm.paidAmount]);

  //Make Multiple payment option single when credit amount selected
  useEffect(() => {
    if (netCredit > 0) setMultipleCheck(false);
  }, [netCredit]);

  //Calculate Maximum discount amount
  useEffect(() => {
    if (maximumDiscount !== undefined) {
      const maxDiscount = convertPercentageToAmount(
        subTotal,
        maximumDiscount?.discountAmount
      );
      setMaxDiscountAmt(maxDiscount);
    }
  }, [subTotal, maximumDiscount?.discountAmount]);

  //view Cheque Manual APIcall

  useEffect(() => {
    viewChequeManualAPICall();
  }, [isModify]);


  // payment type setting according to the pos settings
  useEffect(()=>{
    if(posSettingsList?.posStatus){
      setPaymentTypesArray(paymentTypeList)
      
      if(paymentTypeList!==undefined && paymentTypeList.length!==0){
        setPaymentMethod1(paymentTypeList[0])
        setPaymentMethod2(paymentTypeList[0])
        setPaymentMethod3(paymentTypeList[0])
      }
    }else{
      let paymentArray=[
        {
          journalName:"Cash",
          type:"cash"
        },
        {
          journalName:"Card",
          type:"card"
        },
        {
          journalName:"Cheque",
          type:"cheque"
        },
      ]
      setPaymentTypesArray(paymentArray)
        setPaymentMethod1(paymentArray[0])
        setPaymentMethod2(paymentArray[0])
        setPaymentMethod3(paymentArray[0])

    }
  },[paymentTypeList,posSettingsList])


  return (
    <>
      <style type="text/css" media="print">
        {
          "\
           @page { size: portrait; }\
          "
        }
      </style>
      <div
        className="payments-parent-overlay"
        style={{ width: parentWidth, overflowY: "hidden" }}
      >
        <div
          className="work-order-payments"
          id="paymentDiv"
          style={{
            marginTop: marginTop,
            width: width,
            left: left,
            right: right,
            boxShadow: boxShadow,
            zIndex: zIndex,
          }}
        >
          <div
            className="work-order-payment-top-container"
            id="workOrderPaymentTop"
          >
            <h3>{returnPaymnetType === "customer"?"Refund":"Payments"}</h3>
            <div
              onClick={closePayments}
              className="work-order-payments-close-icon"
            >
              <CloseIcon />
            </div>
          </div>
          <div className="work-order-payment-second-container">
            <div className="work-order-payments-input-container sub-total-container">
              <p>Sub Total</p>
              <input
                // value={values?.subTotal}
                value={subTotal || 0}
                // onChange={onChange != undefined && onChange("subTotal")}
                disabled
              />
              {paymentSection === "delivery" && (
                <p className="delivery-total-wo-amount">
                  Total order amount :{" "}
                  <span style={{ color: "#2f0ae9" }}>
                    {totalOrderAmount?.toFixed(2) || 0}
                  </span>
                </p>
              )}
            </div>

            <div className="work-order-payments-input-container">
              <p>Shipping Charge</p>
              <input
                value={billingForm?.shipmentCharge}
                onChange={getBillingFormData("shipmentCharge")}
                dir="rtl"
              />
            </div>
            <div className="work-order-payments-input-container inner-btn">
              <p>Coupon Code</p>
              <input
                disabled
                value={billingForm?.couponCode}
                onChange={getBillingFormData("couponCode")}
                placeholder="coupon code..."
                // onChange={onChange != undefined && onChange("couponCode")}
              />
              <button
                className="btn btn-primary payment-coupon-btn"
                onClick={couponApply}
                disabled
              >
                Apply
              </button>
            </div>
            <div className="work-order-payments-input-container inner-btn">
              <p>Reward Points</p>
              <input
                disabled
                value={billingForm?.rewardPoints}
                onChange={getBillingFormData("rewardPoints")}
                // onChange={onChange != undefined && onChange("rewardPoints")}
              />
              <button
                className="btn btn-primary payment-reward-btn"
                onClick={rewardAdd}
                disabled
              >
                Redeem
              </button>
            </div>
            <div className="work-order-payments-input-container">
              <p>Discount</p>
              <input
                value={billingForm?.discount}
                onChange={getBillingFormData("discount")}
                dir="rtl"
                // onChange={onChange != undefined && onChange("paidAmount")}
              />
            </div>
            <div className="work-order-payments-input-container">
              <p>{returnPaymnetType === "customer"?"Refund Amount":"Paid Amount"}</p>
              <input
                value={billingForm?.paidAmount}
                onChange={getBillingFormData("paidAmount")}
                dir="rtl"
                // onChange={onChange != undefined && onChange("paidAmount")}
              />
            </div>
            
            <div className="work-order-payments-input-container inner-btn" style={{margin:"1% 0 2% 0"}}>
              <p>Wallet</p>
              <input
                disabled={isRedeemed}
                value={wallet}
                onChange={getWallet}
                // onChange={onChange != undefined && onChange("wallet")}
              />
              {cusId !== "cash" && posSettingsList?.activeWalletStatus === true && (
                <button
                  disabled={isRedeemed}
                  className="btn btn-primary payment-wallet-btn"
                  onClick={redeemFromWallet(totalAmount, wallet)}
                >
                  Redeem
                  {/* {walletBtnTxt} */}
                </button>
              )}
              <p className="payments-section-current-wallet-p">
                wallet amount:{customerWallet===undefined?0:customerWallet?.amount}
              </p>
            </div>
            <div className="work-order-payments-input-container inner-btn credit-amount-container">
              <p>Credit Amount</p>
              <input
                readOnly
                value={netCredit}
                // onChange={onChange != undefined && onChange("creditAmount")}
              />
              {cusId !== "cash" && (
                <button
                  className="btn btn-primary payment-credit-btn"
                  onClick={() => setOpenPreviousCreditDialog(true)}
                >
                  Add
                </button>
              )}
            </div>
            {
              
              (exclusiveTax && exclusiveTax!==0) ?
            <div
            style={{padding:"1% 6%",height:"auto"}}
            className="work-order-payments-total-amount-container">
              <p
              style={{fontWeight:"400",fontSize:"0.8rem"}}
              >Exclusive Tax</p>
              <p 
              style={{fontWeight:"400",fontSize:"0.8rem"}}
              className="payment-total-amount">{exclusiveTax?.toFixed(2)}</p>
            </div>:""}
            {
              (inclusiveTax && inclusiveTax!==0) ?
            <div
            style={{padding:"1% 6%",height:"auto"}}
            className="work-order-payments-total-amount-container">
              <p
              style={{fontWeight:"400",fontSize:"0.8rem"}}
              >Inclusive Tax</p>
              <p
              style={{fontWeight:"400",fontSize:"0.8rem"}}
              className="payment-total-amount">{inclusiveTax?.toFixed(2)}</p>
            </div>:""}
            <div className="work-order-payments-total-amount-container" style={{height:"auto"}}>
              <p>Total Amount ()</p>
              <p className="payment-total-amount">{totalAmount?.toFixed(2)}</p>
            </div>
            <div className="work-order-payments-change-amount-container ">
              <p>Change ()</p>
              <p className="payment-total-amount">{changeAmount?.toFixed(2)}</p>
            </div>
            <div className="work-order-payments-change-amount-container ">
              <p>Balance ()</p>
              <p className="payment-total-amount">{cusBalance?.toFixed(2)}</p>
            </div>
            <div className="payment-os-balance">
              <p>O/S Balance&nbsp;:&nbsp;{osBalance?.toFixed(2)}</p>
            </div>

            <div className="payment-method-container">
              <div className="payment-method-top-container">
                <p>Payment Method</p>
                <div className="payment-method-checkbox-container">
                  <input
                    onClick={clickMultipleCheck}
                    checked={multipleCheck}
                    type="checkbox"
                    disabled={
                      netCredit > 0
                      // billingForm?.paidAmount === 0 ||
                      // billingForm?.paidAmount === ""
                    }
                  />
                  <label>Multiple</label>
                </div>
              </div>

              <div
              onMouseEnter={paymentMethodCheck}
              className="payment-method-type-container">
                <div className="payment-method-type-input-container">
                  <Select
                    // disabled={
                    //   billingForm?.paidAmount === 0 ||
                    //   billingForm?.paidAmount === ""
                    // }
                    value={paymentMethod1}
                    onChange={(e) => setPaymentMethod1(e.target.value)}
                  >
                    {
                    paymentTypesArray?.map((card,i)=>
                    <MenuItem 
                    key={i}
                    value={card} 
                    >{card?.journalName}</MenuItem>
                    )
                  }
                  </Select>
                </div>
                <div className="payment-method-card-type-container">
                  {paymentMethod1?.type === "card" ? (
                    <>
                      <Select
                        // disabled={
                        //   billingForm?.paidAmount === 0 ||
                        //   billingForm?.paidAmount === ""
                        // }
                        value={vendor1}
                        onChange={(e) => setVendor1(e.target.value)}
                      >
                        {cardManual?.map((card, i) => (
                          <MenuItem value={card?.cardName} key={i}>
                            {card?.cardName?.toUpperCase()}
                          </MenuItem>
                        )) || <MenuItem>No cards added</MenuItem>}
                        {/* <MenuItem value="visa">Visa</MenuItem>
                        <MenuItem value="master card">Master card</MenuItem> */}
                      </Select>
                      <input 
                          type="text" 
                          placeholder="Approval No"
                          value={chequeNo}
                          onChange={(e) => setChequeNo(e.target.value)} />
                      {multipleCheck && (
                        <input
                          placeholder="Enter Amount..."
                          value={paidAmount1}
                          onChange={(e) => setPaidAmount1(e.target.value)}
                        />
                      )}
                    </>
                  ) : paymentMethod1?.type === "cash" ? (
                    multipleCheck ? (
                      <input
                        placeholder="Enter Amount..."
                        value={paidAmount1}
                        onChange={(e) => setPaidAmount1(e.target.value)}
                      />
                    ) : (
                      <input
                        readOnly
                        value={`Collect amount ${billingForm?.paidAmount}`}
                      />
                    )
                  ) : paymentMethod1?.type === "cheque" ? (
                    multipleCheck ? (
                      <>
                        <Select
                          value={vendor1}
                          onChange={(e) => setVendor1(e.target.value)}
                        >
                          {chequeManual?.map((Bank, i) => (
                            <MenuItem value={Bank?.bankName} key={i}>
                              {Bank?.bankName?.toUpperCase()}
                            </MenuItem>
                          )) || <MenuItem>No cards added</MenuItem>}
                          {/* <MenuItem value="visa">Visa</MenuItem>
                    <MenuItem value="master card">Master Card</MenuItem> */}
                        </Select>

                        <>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                            }}
                          >
                            <input
                              // style={{
                              //   width: "9rem",
                              // }}
                              type="text"
                              placeholder="Cheque No"
                              value={chequeNo}
                              onChange={(e) => setChequeNo(e.target.value)}
                            />

                            {/* <input
                            type="text"
                            placeholder="Date"
                            value={paidAmount2}
                            onChange={(e) =>
                              setPaidAmount2(e.target.value)
                            }

                          /> */}
                            <Datepicker
                              type="date"
                              // backgroundColor="#f1f2ff"

                              onChange={(e) => setChequeDate(e.target.value)}
                              value={chequeDate}
                            />
                          </div>

                          <input
                            type="text"
                            placeholder="Enter amount..."
                            value={paidAmount1}
                            onChange={(e) => setPaidAmount1(e.target.value)}
                          />
                        </>
                      </>
                    ) : 
                    (
                      <>
                        {paymentMethod1?.type === "cheque" ? (
                          <>
                            <Select
                              value={vendor3}
                              onChange={(e) => setVendor3(e.target.value)}
                            >
                              {chequeManual?.map((Bank, i) => (
                                <MenuItem value={Bank?.bankName} key={i}>
                                  {Bank?.bankName?.toUpperCase()}
                                </MenuItem>
                              )) || <MenuItem>No cards added</MenuItem>}
                              {/* <MenuItem value="visa">Visa</MenuItem>
                          <MenuItem value="master card">Master Card</MenuItem> */}
                            </Select>

                            <>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                }}
                              >
                                <input
                                  // style={{
                                  //   width: "9rem",
                                  // }}
                                  type="text"
                                  placeholder="Cheque No"
                                  value={chequeNo}
                                  onChange={(e) => setChequeNo(e.target.value)}
                                />

                                {/* <input
                                  type="text"
                                  placeholder="Date"
                                  value={paidAmount2}
                                  onChange={(e) =>
                                    setPaidAmount2(e.target.value)
                                  }

                                /> */}
                                <Datepicker
                                  type="date"
                                  // backgroundColor="#f1f2ff"

                                  onChange={(e) =>
                                    setChequeDate(e.target.value)
                                  }
                                  value={chequeDate}
                                />
                              </div>

                              {/* <input
                                type="text"
                                placeholder="Enter amount..."
                                value={paidAmount3}
                                onChange={(e) => setPaidAmount3(e.target.value)}
                              /> */}

                              <input
                                value={billingForm?.paidAmount}
                                onChange={getBillingFormData("paidAmount")}
                                dir="rtl"
                                // onChange={onChange != undefined && onChange("paidAmount")}
                              />
                            </>
                          </>
                        ) : paymentMethod1?.type === "cheque" ? (
                          <input
                            placeholder="Enter Amount"
                            value={paidAmount3}
                            onChange={(e) => setPaidAmount3(e.target.value)}
                          />
                        ) : undefined}
                      </>
                    )
                  ) : undefined}
                </div>
              </div>

              {multipleCheck === true && (
                <>
                  <div className="payment-method-type-container pymtcontainer">
                    <div className="payment-method-type-input-container">
                      <Select
                        value={paymentMethod2}
                        onChange={(e) => setPaymentMethod2(e.target.value)}
                      >
                        {
                          paymentTypesArray?.map((card, i) =>
                            <MenuItem
                              key={i}
                              value={card}
                            >{card?.journalName}</MenuItem>
                          )
                        }
                      </Select>
                    </div>
                    <div className="payment-method-card-type-container pymtcontainer1">
                      {paymentMethod2?.type === "card" ? (
                        <>
                          <Select
                            value={vendor2}
                            onChange={(e) => setVendor2(e.target.value)}
                          >
                            {cardManual?.map((card, i) => (
                              <MenuItem value={card?.cardName} key={i}>
                                {card?.cardName?.toUpperCase()}
                              </MenuItem>
                            )) || <MenuItem>No cards added</MenuItem>}
                          </Select>
                          <input
                            type="text"
                            placeholder="Approval No"
                            value={chequeNo1}
                            onChange={(e) => setChequeNo1(e.target.value)} />
                          {multipleCheck && (
                            <input
                              type="text"
                              placeholder="Enter amount..."
                              value={paidAmount2}
                              onChange={(e) => setPaidAmount2(e.target.value)}
                            />
                          )}
                        </>
                      ) : paymentMethod2?.type === "cash" ? (
                        <input
                          placeholder="Enter Amount"
                          value={paidAmount2}
                          onChange={(e) => setPaidAmount2(e.target.value)}
                        />
                      ) : (
                        <>
                          {paymentMethod2?.type === "cheque" ? (
                            <>
                              <Select
                                value={vendor2}
                                onChange={(e) => setVendor2(e.target.value)}
                              >
                                {chequeManual?.map((Bank, i) => (
                                  <MenuItem value={Bank?.bankName} key={i}>
                                    {Bank?.bankName?.toUpperCase()}
                                  </MenuItem>
                                )) || <MenuItem>No cards added</MenuItem>}
                                {/* <MenuItem value="visa">Visa</MenuItem>
                            <MenuItem value="master card">Master Card</MenuItem> */}
                              </Select>

                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                  }}
                                >
                                  <input
                                    // style={{
                                    //   width: "9rem",
                                    // }}
                                    type="text"
                                    placeholder="Cheque No"
                                    value={chequeNo1}
                                    onChange={(e) =>
                                      setChequeNo1(e.target.value)
                                    }
                                  />

                                  {/* <input
                                    type="text"
                                    placeholder="Date"
                                    value={paidAmount2}
                                    onChange={(e) =>
                                      setPaidAmount2(e.target.value)
                                    }
  
                                  /> */}
                                  <Datepicker
                                    type="date"
                                    // backgroundColor="#f1f2ff"

                                    onChange={(e) =>
                                      setChequeDate1(e.target.value)
                                    }
                                    value={chequeDate1}
                                  />
                                </div>

                                <input
                                  type="text"
                                  placeholder="Enter amount..."
                                  value={paidAmount2}
                                  onChange={(e) =>
                                    setPaidAmount2(e.target.value)
                                  }
                                />
                              </>
                            </>
                          ) : paymentMethod3?.type === "Cheque" ? (
                            <input
                              placeholder="Enter Amount"
                              value={paidAmount3}
                              onChange={(e) => setPaidAmount3(e.target.value)}
                            />
                          ) : undefined}
                        </>
                      )}
                    </div>
                  </div>

                  {/* cheque */}

                  <div className="payment-method-type-container pymtcontainer">
                    <div className="payment-method-type-input-container">
                      <Select
                        value={paymentMethod3}
                        onChange={(e) => setPaymentMethod3(e.target.value)}
                      >
                       {
                          paymentTypesArray?.map((card, i) =>
                            <MenuItem
                              key={i}
                              value={card}
                            >{card?.journalName}</MenuItem>
                          )
                        }
                      </Select>
                    </div>
                    <div className="payment-method-card-type-container pymtcontainer1">
                      {paymentMethod3?.type === "card" ? (
                        <>
                          <Select
                            value={vendor4}
                            onChange={(e) => setVendor4(e.target.value)}
                          >
                            {cardManual?.map((card, i) => (
                              <MenuItem value={card?.cardName} key={i}>
                                {card?.cardName?.toUpperCase()}
                              </MenuItem>
                            )) || <MenuItem>No cards added</MenuItem>}
                            {/* <MenuItem value="visa">Visa</MenuItem>
                          <MenuItem value="master card">Master Card</MenuItem> */}
                          </Select>
                          <input
                            type="text"
                            placeholder="Approval No"
                            value={chequeNo2}
                            onChange={(e) => setChequeNo2(e.target.value)} />
                          {multipleCheck && (
                            <input
                              type="text"
                              placeholder="Enter amount..."
                              value={paidAmount4}
                              onChange={(e) => setPaidAmount4(e.target.value)}
                            />
                          )}
                        </>
                      ) : paymentMethod3?.type === "cash" ? (
                        <input
                          placeholder="Enter Amount"
                          value={paidAmount4}
                          onChange={(e) => setPaidAmount4(e.target.value)}
                        />
                      ) : (
                        <>
                          {paymentMethod3?.type === "cheque" ? (
                            <>
                              <Select
                                value={vendor4}
                                onChange={(e) => setVendor4(e.target.value)}
                              >
                                {chequeManual?.map((Bank, i) => (
                                  <MenuItem value={Bank?.bankName} key={i}>
                                    {Bank?.bankName?.toUpperCase()}
                                  </MenuItem>
                                )) || <MenuItem>No cards added</MenuItem>}
                                {/* <MenuItem value="visa">Visa</MenuItem>
                            <MenuItem value="master card">Master Card</MenuItem> */}
                              </Select>

                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                  }}
                                >
                                  <input
                                    // style={{
                                    //   width: "9rem",
                                    // }}
                                    type="text"
                                    placeholder="Cheque No"
                                    value={chequeNo2}
                                    onChange={(e) =>
                                      setChequeNo2(e.target.value)
                                    }
                                  />

                                  {/* <input
                                    type="text"
                                    placeholder="Date"
                                    value={paidAmount2}
                                    onChange={(e) =>
                                      setPaidAmount2(e.target.value)
                                    }
  
                                  /> */}
                                  <Datepicker
                                    type="date"
                                    // backgroundColor="#f1f2ff"

                                    onChange={(e) =>
                                      setChequeDate2(e.target.value)
                                    }
                                    value={chequeDate2}
                                  />
                                </div>

                                <input
                                  type="text"
                                  placeholder="Enter amount..."
                                  value={paidAmount4}
                                  onChange={(e) =>
                                    setPaidAmount4(e.target.value)
                                  }
                                />
                              </>
                            </>
                          ) : paymentMethod3?.type === "cheque" ? (
                            <input
                              placeholder="Enter Amount"
                              value={paidAmount3}
                              onChange={(e) => setPaidAmount3(e.target.value)}
                            />
                          ) : undefined}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div className="payment-method-bottom-container">
                <button
                  className="payment-place-order-btn"
                  onMouseDown={formPaymentMethodBody}
                  // onMouseUp={createFinalBody}
                  onClick={finalPlaceOrder}
                  // onMouseUp={generateInvoice}
                  disabled={
                    cusId === "cash"
                      ? totalAmount == billingForm?.paidAmount
                        ? false
                        : true
                      : false
                  }
                >
                  {paymentSection === "viewOrders"
                    ? "Pay Balance"
                    : paymentSection === "delivery"
                    ? "Deliver"
                    : paymentSection === "alteration"
                    ? "Pay Amount"
                    : returnPaymnetType === "customer"
                    ? "Pay Customer"
                    : "Place Order"}
                </button>
                <button
                  className="clear-list-button"
                  onClick={clearBillingForm}
                >
                  Clear List
                </button>
              </div>
              <div className="payment-method-bottom-container">
                <button className="hold-button" onClick={holdOrder}>
                  Hold
                </button>
                <button
                  id="heldProductsBtnInPayment"
                  ref={heldProductBtnRef}
                  onClick={() => {
                    setHeldProductDialog(true);
                    console.log("onclick triggered");
                  }}
                  className="clear-list-button held-products"
                >
                  Held Products
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Backdrop on loading  */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 500 }}
        open={isLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Held Products Dialog  */}
      <Dialog
        open={heldProductDialog}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setHeldProductDialog(false)}
      >
        <div className="held-product-dialog">
          <h3>Held Products</h3>
          <table className="held-products-table">
            <thead>
              <tr>
                <th>Sl. No</th>
                <th>Customer Name</th>
                <th>Total Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {heldProductList?.length === 0 ? (
                <tr>
                  <td colSpan={4}>No orders on hold</td>
                </tr>
              ) : (
                heldProductList?.map((r, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{r?.customerName}</td>
                    <td>{r.total}</td>
                    <td>
                      <span
                        className="held-products-pay"
                        onClick={() => clickHeldPayBtn(i)}
                      >
                        Pay
                      </span>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <div className="held-product-button-container">
            <button
              onClick={() => setHeldProductDialog(false)}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>

      {/* Previous Credits Dialog  */}
      <Dialog
        open={openPreviousCreditDialog}
        maxWidth="lg"
        onKeyDown={(e) =>
          e.key === "Escape" && setOpenPreviousCreditDialog(false)
        }
      >
        <div className="held-product-dialog">
          <h3>Credits</h3>
          <table className="held-products-table">
            <thead>
              <tr>
                <th>Order No</th>
                <th>Order Date</th>
                <th>Last Paid Date</th>
                <th>Net Amount</th>
                <th>Paid Amount</th>
                <th>Discount</th>
                <th>Balance</th>
                <th>select</th>
              </tr>
            </thead>
            <tbody>
              {(frontendCreditList?.length > 0 &&
                frontendCreditList?.map((r, i) => (
                  <tr key={i}>
                    <td>{r?.purchaseId}</td>
                    <td>{r?.purchaseDate}</td>
                    <td>{r?.lastPaidDate}</td>
                    <td>{r?.netAmount}</td>
                    <td>{r?.paidAmount}</td>
                    <td>{r?.discount}</td>
                    <td>{r?.balance}</td>
                    <td>
                      {/* <span className="held-products-pay">Pay</span> */}
                      <input
                        type="checkbox"
                        className="global-checkbox"
                        name=""
                        id=""
                        checked={r?.isChecked}
                        onClick={checkOrUncheckCreditItem(i)}
                      />
                    </td>
                  </tr>
                ))) || (
                <tr>
                  <td colSpan={8}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="held-product-button-container">
            <button
              onClick={() => setOpenPreviousCreditDialog(false)}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button
              // onClick={() => setOpenPreviousCreditDialog(false)}
              className="btn btn-secondary"
              onClick={submitPreviousCreditDialog}
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>

      {/* //Billing invoice Dialog  */}
      <Dialog
        open={paymentSection === "salesReturn" ? null : invoiceDialog}
        maxWidth="lg"
        onClose={() => {
          setInvoiceDialog(false);
          closePayments();
        }}
        onKeyDown={(e) => e.key === "Escape" && setInvoiceDialog(false)}
      >
        {paymentSection === "alteration" ? (
          <div className="billing-parent-wrapper" onScroll={hideInvoicePrint}>
            <div ref={(el) => (alterationInvoicePrint = el)}>
              <AlterationInvoice
                data={generatedInvoice}
                // invoiceData={generatedInvoice}
              />
            </div>
            <div className="invoice-print-icon-container" id="invoicePrintIcon">
              {/* <Tooltip title="Print" placement="right" arrow> */}
              <ReactToPrint
                trigger={() => (
                  <IconButton
                    className="invoice-print-btn"
                    sx={{
                      backgroundColor: "#d3e3ec",
                    }}
                    // onClick={invoiceDialogClose}
                  >
                    <PrintOutlined
                      className="print-icon"
                      sx={{ color: "#000" }}
                    />
                  </IconButton>
                )}
                content={() => alterationInvoicePrint}
              />
              {/* </Tooltip> */}
            </div>
          </div>
        ) : (
          <div className="billing-parent-wrapper" onScroll={hideInvoicePrint}>
            <div ref={(el) => (invoicePrint = el)}>
              <Invoice
                data={generatedInvoice}
                // invoiceData={generatedInvoice}
              />
            </div>
            <div className="invoice-print-icon-container" id="invoicePrintIcon">
              {/* <Tooltip title="Print" placement="right" arrow> */}
              <ReactToPrint
                trigger={() => (
                  <IconButton
                    className="invoice-print-btn"
                    sx={{
                      backgroundColor: "#d3e3ec",
                    }}
                    // onClick={invoiceDialogClose}
                  >
                    <PrintOutlined
                      className="print-icon"
                      sx={{ color: "#000" }}
                    />
                  </IconButton>
                )}
                content={() => invoicePrint}
              />
              {/* </Tooltip> */}
            </div>
          </div>
        )}
      </Dialog>
      <LoadingForm loading={loadingForm}/>
    </>
  );
};