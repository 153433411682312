import React from 'react'
import { useSelector } from 'react-redux';

const StockMovePrint = (props) => {
    const {stockMove,symbol}=props;
  const decimalPosition = localStorage.getItem("decimalPosition");
  const decimalPositionStock = localStorage.getItem("decimalPositionStock");

    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue
    );

  return (
    <div className="print-main-div" style={{ backgroundColor: "white" }}>
            <div className='header-div' style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <img src={companyProfileData?.companyLogo} alt="" style={{ height: "62px" }} />
                </div>
                <div style={{ textAlignLast: "center" }}>
                    <h1 style={{ fontSize: "medium", margin: "0" }}>Shift Report</h1>
                    
                   
                </div>
                <div>
                    <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyName}</p>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyAddress}</p>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyContact}</p>
                </div>
            </div>
            <div className="global-report-table-container" style={{overflow:"visible"}}>
          <table>
            <thead>
              <tr >
                <th>Item</th>
                <th>UOM</th>
                <th>Opening</th>
                <th>In</th>
                <th>Out</th>
                <th>Closing</th>
                <th>Cost</th>
                <th>Values</th>
              </tr>
            </thead>
            <tbody>
              {stockMove?.slice()?.reverse()?.map((item, index) => (
                <tr key={index} >
                  <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.productName}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.uom}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.openingBalance}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalInDate?.toFixed(decimalPositionStock)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalOutDate?.toFixed(decimalPositionStock)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.closingBalance?.toFixed(decimalPosition)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                    {item && item.cost !== null ? ` ${item.cost.toFixed(decimalPosition)}` : ` 0.${'0'.repeat(decimalPosition)}`}
                  </td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.value?.toFixed(decimalPosition)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={2}>Total</th>
                <th>
                  {stockMove && stockMove.length > 0 ?
                    stockMove.reduce((sum, item) => sum + (parseFloat(item?.openingBalance) || 0), 0).toFixed(decimalPosition)
                    : '0.00'
                  }
                </th>
                <th >{(stockMove?.reduce((sum, item) => sum + item?.totalInDate, 0)?.toFixed(decimalPosition))}</th>
                <th >{(stockMove?.reduce((sum, item) => sum + item?.totalOutDate, 0)?.toFixed(decimalPosition))}</th>
                <th >{(stockMove?.reduce((sum, item) => sum + item?.closingBalance, 0)?.toFixed(decimalPosition))}</th>
                <th >{(stockMove?.reduce((sum, item) => sum + item?.cost, 0)?.toFixed(decimalPosition))}</th>
                <th >{symbol}&nbsp;{(stockMove?.reduce((sum, item) => sum + item?.value, 0)?.toFixed(decimalPosition))}</th>
              </tr>
            </tfoot>
            
          </table>
         
        </div>
            </div>
  )
}

export default StockMovePrint