import React, { useState } from "react";
import "../../../css/Dashboard/homeDashboard.css";
import { ClickableCard } from "../../Single Components/ClickableCard";
import DemoDress from "../../../Assets/Images/demoDress.png";
import QuickAccess from "../../Single Components/QuickAccess";
import SalesIcon from "../../../Assets/SVG/stock.svg";
import StaffIcon from "../../../Assets/SVG/businessman(1).svg";
import QuickAddIcon from "../../../Assets/SVG/timer.svg";
import StockAdjIcon from "../../../Assets/SVG/STOCK ADJUSTMENT.svg";
import ProductMasterIcon from "../../../Assets/SVG/PRODUCT MASTER.svg";
import {
  CalendarMonthOutlined,
  KeyboardArrowDown,
  VisibilityOutlined,
} from "@mui/icons-material";
import { CustomRadioGroup } from "../../Single Components/CustomRadioGroup";
import Chart from "chart.js/auto";
import { Bar, Line } from "react-chartjs-2";
import { Datepicker } from "../../Single Components/Datepicker";
import PaymentDocImage from "../../../Assets/Images/PaymentDoc.png";
import {
  IconButton,
  Tooltip,
  Dialog,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Skeleton,
  Menu,
} from "@mui/material";
import { DatepickerRanges } from "../../Single Components/DatepickerRanges";
import kurti from "../../../Assets/Images/kurti.jpg";
import tshirt from "../../../Assets/Images/shirt1.jpg";
import pant from "../../../Assets/Images/pant.jpg";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getCountOfEmployeesAPICall,
  getFastSellingAPICall,
} from "../../../API/Dashboards/mainDashboardAPI";
import { convertDateFormat, month } from "../../../Js/Date";
import { useNavigate } from "react-router";
import sideMenuList from "../../../Assets/JSON/sidemenuList.json";
import { today, currentTime, previousDate } from "../../../Js/Date";
import { getDashboardProductListAPICall } from "../../../API/Dashboards/mainDashboardAPI";
import {
  getRecentSaleAPICall,
  getCreditDetailsAPICall,
  viewAllQuickAccess,
  addQuickAccessAPICall,
  viewExpiredDocAPICall,
} from "../../../API/Dashboards/mainDashboardAPI";
import { getTotalRevenueGraphAPICall } from "../../../API/Dashboards/mainDashboardAPI";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { viewAllBranchesAPICall } from "../../../API/Settings/Company Settings/companySettingsAPI";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
export const HomeDashboard = () => {
  //*Redux States
  let dashboardFastSellingGraph = useRef;
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  //Employee Gender Count
  const employeeGenderCount = useSelector(
    (state) => state.employeeGenderCountSlice.value
  );
  // product list
  const dashboardProductList = useSelector(
    (state) => state.dashboardProductListSlice.value
  );
  console.log(dashboardProductList);
  // recent sale
  const recentSale = useSelector((state) => state.recentSaleSlice.value);
  // total revenue graph
  const totalRevenueGraph = useSelector(
    (state) => state.totalRevenueGraph.value
  );
  // credit details
  const creditDetails = useSelector((state) => state.creditDetails.value);
  // view quick access
  const viewQuickAccess = useSelector((state) => state.quickAccessSlice.value);
  //  view expired document
  const viewExpiredDocument = useSelector(
    (state) => state.expiredDocumentSlice.value
  );
  // fast selling list
  const fastSellingList = useSelector((state) => state.fastSellingSlice.value);
  // branches
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);

  const loginMeta = localStorage.getItem("login_meta");
  const userBranchId = JSON.parse(loginMeta)?.branchPk;
  const arrayOfObj = fastSellingList;
  const storeCode = localStorage.getItem("branchId");
  const { permission } =loginMeta!==null && JSON.parse(loginMeta);

  //Update Listener States
  const [isModify, setIsModify] = useState(false);
  // quick access dialog
  const [openQuickAccessDialog, setOpenQuickAccessDialog] = useState(false);

  // total Revenue graph
  const [status, setStatus] = useState("week");
  // fast selling chart
  const [chartType, setChartType] = useState("0");
  const [revenueGraphSliced, setRevenueGraphSliced] = useState([]);
  const [index, setIndex] = useState(false);
  const [dataEmpty, setDataEmpty] = useState(false);
  const [toggleFastSelling, setToggleFastSelling] = useState(0);
  const [startIndex, setStartIndex] = useState(0);

  const [branchId, setBranchId] = useState(null);
  const [salesPercData, setSalesPercData] = useState([]);
  const [salesProdName, setSalesProdName] = useState([]);
  // product list state
  const [type, setType] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(previousDate);
  const [toDate, setToDate] = useState(today);

  // quick Access state
  const [quickAccess1, setQuickAccess1] = useState();
  const [quickAccess2, setQuickAccess2] = useState();
  const [quickAccess3, setQuickAccess3] = useState();
  const [quickAccess4, setQuickAccess4] = useState();
  const [quickAccess5, setQuickAccess5] = useState();
  const [quickAccess6, setQuickAccess6] = useState();
  const [quickAccess7, setQuickAccess7] = useState();

  const [name1, setName1] = useState();
  const [name2, setName2] = useState();
  const [name3, setName3] = useState();
  const [name4, setName4] = useState();
  const [name5, setName5] = useState();
  const [name6, setName6] = useState();
  const [name7, setName7] = useState();

  const [section1, setSection1] = useState();
  const [section2, setSection2] = useState();
  const [section3, setSection3] = useState();
  const [section4, setSection4] = useState();
  const [section5, setSection5] = useState();
  const [section6, setSection6] = useState();
  const [section7, setSection7] = useState();


  const [permittedQuickAccess,setPermittedQuickAccess]=useState([])

  // expiry document states
  const [singleEmpObj, setSingleEmpObj] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //*Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

  // radio buttons
  const customRadio = [
    {
      id: "fstRadio",
      label: "Today",
      name: "customRadio",
      value: "today",
    },
    {
      id: "secondRadio",
      label: "Week",
      name: "customRadio",
      value: "week",
      checked: true,
    },
    {
      id: "thirdRadio",
      label: "Month",
      name: "customRadio",
      value: "month",
    },
  ];

  const totalExpenseGraphChange = (e) => {
    setStatus(e.target.value);
  };
  const fastSellingChartChange = (e) => {
    setChartType(e.target.value);
  };
  const fastSellingListChange = (key) => () => {
    if (key === "min") {
      if (startIndex !== 0) {
        setStartIndex(startIndex - 1);
      }
      if (index === true) {
        setStartIndex(startIndex - 1);
        setIndex(false);
      }
    }
    if (key === "add") {
      setStartIndex(startIndex + 1);
    }
  };
  const clickFastSellingToggle = (key) => () => {
    if (key === "slowSelling") {
      setToggleFastSelling(1);
      setStartIndex(0);
    }
    if (key === "fastSelling") {
      setToggleFastSelling(0);
      setStartIndex(0);
    }
  };

  //Line Chart Data
  const todayLabels = [
    "1hrs",
    "2hrs",
    "3hrs",
    "4hrs",
    "5hrs",
    "6hrs",
    "7hrs",
    "8hrs",
    "9hrs",
    "10hrs",
    "11hrs",
    "12hrs",
    "13hrs",
    "14hrs",
    "15hrs",
    "16hrs",
    "17hrs",
    "18hrs",
    "19hrs",
    "20hrs",
    "21hrs",
    "22hrs",
    "23hrs",
    "24hrs",
  ];
  const weekLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthLabels = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31"
  ];
  const data = {
    labels:
      status === "today"
        ? todayLabels
        : status === "week"
        ? weekLabels
        : status === "month"
        ? monthLabels
        : undefined,

    datasets: [
      {
        label: "Revenue",
        data: 
         status==="month"?
            revenueGraphSliced
           :totalRevenueGraph,
        backgroundColor: "#3a00ff",
        borderColor: "#3a00ff",
      },
    ],
  };
  //Line Chart Options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Revenue Graph",
      },
    },
  };
  // bar chart data and options

  const option = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
  };

  const labels = salesProdName;
  // [
  //   'Product1', 'Product2', 'Product3', 'Product4', 'Product5', 'Product6', 'Product7','Product8','product9','product10',
  //   'Product11', 'Product12', 'Product13', 'Product14', 'Product15', 'Product16', 'Product17','Product18','product19','product20'
  //   //  'Product1', 'Product2', 'Product3', 'Product4', 'Product5', 'Product6', 'Product7','Product8',
  //   // 'Product1', 'Product2', 'Product3', 'Product4', 'Product5', 'Product6', 'Product7','Product8'
  // ];

  // const [sortedRevenue,setSortedRevenue]=useState([])
  // const [fastSellingData,setFastSellingData]=useState([])

  // useEffect(()=>{
  //   let filterAr=[]
  //       if(totalRevenueGraph!==undefined){
  //         filterAr=totalRevenueGraph.slice().sort((a,b) => b-a)
  //       }
  //       setSortedRevenue(filterAr)
  //   },[totalRevenueGraph])

  // useEffect(()=>{
  //   let filteredArray=sortedRevenue.slice(startIndex,startIndex+3)
  //   setFastSellingData(filteredArray)
  // },[startIndex,sortedRevenue])
  // console.log(fastSellingData);
  const fastSellingInitalBody = {
    fromDate: previousDate,
    toDate: today,
  };

  const [fastSellingBody, setFastSellingBody] = useState(fastSellingInitalBody);

  const getFastSellingForm = (key) => (e) => {
    const { value } = e.target;
    if (key === "fromDate") {
      setFastSellingBody({ ...fastSellingBody, fromDate: value });
    }
    if (key === "toDate") {
      setFastSellingBody({ ...fastSellingBody, toDate: value });
    }
  };

  const fastSellingPayloadBodyWithoutBranch = {
    type: chartType,
    toDate: `${fastSellingBody.toDate} 23:59:59`,
    fromDate: `${fastSellingBody.fromDate} 00:00:00`,
    index: startIndex,
    fast: toggleFastSelling,
  };
  const fastSellingPayloadBodyWithBranchId = {
    type: chartType,
    toDate: `${fastSellingBody.toDate} 23:59:59`,
    fromDate: `${fastSellingBody.fromDate} 00:00:00`,
    index: startIndex,
    fast: toggleFastSelling,
    branchId:
      userRole === "user" ? userBranchId : branchId !== undefined && branchId,
  };
  const fastSellingPayloadBody =
    userRole === "admin"
      ? branchId === null || branchId === undefined
        ? fastSellingPayloadBodyWithoutBranch
        : fastSellingPayloadBodyWithBranchId
      : fastSellingPayloadBodyWithBranchId;
  useEffect(() => {
    getFastSellingAPICall(fastSellingPayloadBody, setIndex, setDataEmpty);
  }, [startIndex, chartType, fastSellingBody, toggleFastSelling, branchId]);

  const datas = {
    labels,
    datasets: [
      {
        label: "",
        data: salesPercData,
        backgroundColor: "#0018b4",
        barThickness: 15,
      },
    ],
  };

  // bar chart radio
  const productOutlerRadio = [
    {
      id: "productRadioBarChar",
      label: "Product",
      name: "produtOutetRadio",
      value: 0,
      checked: true,
    },
    {
      id: "outletRadioBarChar",
      label: "Outlet",
      name: "produtOutetRadio",
      value: 1,
    },
  ];
  const productOnlyRadio = [
    {
      id: "productRadioBarChar",
      label: "Product",
      name: "produtOutetRadio",
      value: 0,
      checked: true,
    },
  ];

  // assign L2
  const assignL2 = (moduleKeyList = [], permissionLObj, key1, stringsArray) => {
   
    moduleKeyList.forEach((key2, i) => {
      if (typeof permissionLObj[key2] === "boolean") {
        if (permissionLObj[key2]) stringsArray.push(`${key1}_${key2}`);
      }
      if (key1 === "purchaseManage") {
        if (typeof permissionLObj[key2] === "boolean") {
          if (permissionLObj[key2]) stringsArray.push(`${key1}_${key2}`);
        }
        if (typeof permissionLObj[key2] === "object") {
          Object.keys(permissionLObj[key2]).forEach((key3, i) => {
            if (permissionLObj[key2][key3])
              stringsArray.push(`${key1}_${key2}_${key3}`);
          });
        }
      }
    });
  };

  //make permission object as string
  const formPermissionStrings = (permission = {}) => {
    if (permission !== undefined) {
      let levelOneKeys = Object.keys(permission);
      var permissionStrings = [];
      let twoLevelKeys = [
        "customer",
        "expense",
        "offers",
        "order",
        "report",
        "rewards",
        "sale",
        "staff",
        "purchaseManage",
      ];

      levelOneKeys.forEach((key1, i) => {
        if (typeof permission[key1] === "boolean") {
          if (permission[key1]) permissionStrings.push(key1);
        }
        if (typeof permission[key1] === "object") {
          if (twoLevelKeys.includes(key1)) {
            assignL2(
              Object.keys(permission[key1]),
              permission[key1],
              key1,
              permissionStrings
            );
          }
        }
      });
    }
    setPermittedQuickAccess([...new Set([...permissionStrings])])
  };

  useEffect(() => {
    getCountOfEmployeesAPICall({branchId:storeCode});
    formPermissionStrings(permission);
  }, []);

  let navigate = useNavigate();
  const getProductMaster = () => {
    navigate("/userdashboard/inventory/products/product");
  };
  const getProductList = () => {
    navigate("/userdashboard/productList");
  };
  const getSales = () => {
    navigate("/userdashboard/sales/billingnew");
  };
  const getStaff = () => {
    navigate("/userdashboard/staff/dashboard");
  };
  const getStockAdjustment = () => {
    navigate(
      "/userdashboard/inventory/operation/inventoryAdjustment"
    );
  };
  const getOrders = () => {
    navigate("/userdashboard/purchaseManager/NewPurchaseOrder");
  };

  // view Quick access
  useEffect(() => {
    viewAllQuickAccess();
  }, [isModify]);

  // add quick access
  const addQuickAccess = () => {
    setOpenQuickAccessDialog(true);
  };

  useEffect(() => {
    if (viewQuickAccess !== undefined) {
      setQuickAccess1(viewQuickAccess?.access[0].url);
      setQuickAccess2(viewQuickAccess?.access[1].url);
      setQuickAccess3(viewQuickAccess?.access[2].url);
      setQuickAccess4(viewQuickAccess?.access[3].url);
      setQuickAccess5(viewQuickAccess?.access[4].url);
      setQuickAccess6(viewQuickAccess?.access[5].url);
      setQuickAccess7(viewQuickAccess?.access[6].url);
      setName1(viewQuickAccess?.access[0].shortCutName);
      setName2(viewQuickAccess?.access[1].shortCutName);
      setName3(viewQuickAccess?.access[2].shortCutName);
      setName4(viewQuickAccess?.access[3].shortCutName);
      setName5(viewQuickAccess?.access[4].shortCutName);
      setName6(viewQuickAccess?.access[5].shortCutName);
      setName7(viewQuickAccess?.access[6].shortCutName);
    }
  }, [viewQuickAccess]);

  useEffect(() => {
    sideMenuList?.map((item, i) => {
      if (item.path === quickAccess1) {
        setName1(item.name);
        setSection1(item.section);
      }
      if (item.path === quickAccess2) {
        setName2(item.name);
        setSection2(item.section);
      }
      if (item.path === quickAccess3) {
        setName3(item.name);
        setSection3(item.section);
      }
      if (item.path === quickAccess4) {
        setName4(item.name);
        setSection4(item.section);
      }
      if (item.path === quickAccess5) {
        setName5(item.name);
        setSection5(item.section);
      }
      if (item.path === quickAccess6) {
        setName6(item.name);
        setSection6(item.section);
      }
      if (item.path === quickAccess7) {
        setName7(item.name);
        setSection7(item.section);
      }
    });
  }, [
    quickAccess1,
    quickAccess2,
    quickAccess3,
    quickAccess4,
    quickAccess5,
    quickAccess6,
    quickAccess7,
  ]);

  // add quick access api calll
  const bodyToApi = {
    access: [
      {
        shortCutName: name1,
        url: quickAccess1,
      },
      {
        shortCutName: name2,
        url: quickAccess2,
      },
      {
        shortCutName: name3,
        url: quickAccess3,
      },
      {
        shortCutName: name4,
        url: quickAccess4,
      },
      {
        shortCutName: name5,
        url: quickAccess5,
      },
      {
        shortCutName: name6,
        url: quickAccess6,
      },
      {
        shortCutName: name7,
        url: quickAccess7,
      },
    ],
  };

  const addQuickAccessSubmitBtn = () => {
    if (
      quickAccess1 !== undefined &&
      quickAccess2 !== undefined &&
      quickAccess3 !== undefined &&
      quickAccess4 !== undefined &&
      quickAccess5 !== undefined &&
      quickAccess6 !== undefined &&
      quickAccess7 !== undefined
    ) {
      addQuickAccessAPICall(bodyToApi, updateListener);
      setOpenQuickAccessDialog(false);
    } else {
      alert("Add All Quick Access");
    }
  };

  //  all product list api call

  const getFromDate = (e) => {
    setFromDate(e.target.value);
  };
  const getToDate = (e) => {
    setToDate(e.target.value);
  };


  useEffect(()=>{
    if(totalRevenueGraph!==undefined){
      setRevenueGraphSliced(totalRevenueGraph.slice(1))
    }
  },[totalRevenueGraph])

  useEffect(() => {
    getDashboardProductListAPICall(
      {
        type: type,
        index: 0,
        fromDate: fromDate,
        endDate: toDate,
        branchId: storeCode,
      },
      setIsLoading
    );
  }, [type, fromDate, toDate]);

  // RECENT SALE API CALL,credit details
  useEffect(() => {
    getRecentSaleAPICall({branchId:storeCode},setIsLoading);
    getCreditDetailsAPICall({branchId:storeCode},setIsLoading);
    viewExpiredDocAPICall(setIsLoading);
  }, []);

  // total revenue graph api call

  useEffect(() => {
    getTotalRevenueGraphAPICall({ timeFilter: status, branchId: storeCode });
  }, [status]);

  const extractSingleEmpObj = (empId) => () => {
    let extractedObjArray = [];
    extractedObjArray = viewExpiredDocument?.filter((obj) => {
      if (obj.employeeId === empId) {
        return obj;
      }
    });
    setSingleEmpObj(extractedObjArray);
  };

  useEffect(() => {
    let filteredSalesPer = [];
    let filteredProductName = [];
    if (chartType === "0") {
      filteredSalesPer = arrayOfObj?.map((obj) => obj.salePercent);
      filteredProductName = arrayOfObj?.map((obj) => obj.productName);
      setSalesPercData(filteredSalesPer);
      setSalesProdName(filteredProductName);
    } else {
      filteredSalesPer = arrayOfObj?.map((obj) => obj.salePercent);
      filteredProductName = arrayOfObj?.map((obj) => obj.branchName);
      setSalesPercData(filteredSalesPer);
      setSalesProdName(filteredProductName);
    }
  }, [arrayOfObj, toggleFastSelling, chartType, branchId]);

  useEffect(() => {
    if (dataEmpty === true) {
      setSalesPercData([]);
      setSalesProdName([]);
    }
    setIndex(false);
  }, [dataEmpty, branchId]);

  useEffect(() => {
    viewAllBranchesAPICall();
  }, []);

  return (
    <>
      <div className="global-page-parent-container">
        {/* Clickable card row  */}
        <div className="clickable-card-row">
          {userRole === "admin" ? (
            <ClickableCard
              bgColor="#f26149"
              icon={ProductMasterIcon}
              label="Product Master"
              onClick={getProductMaster}
            />
          ) : userRole === "user" ? (
            <ClickableCard
              bgColor="#f26149"
              icon={ProductMasterIcon}
              label="Product List"
              onClick={getProductList}
            />
          ) : undefined}
          <ClickableCard
            bgColor="#1da9e9"
            label="Sales"
            icon={SalesIcon}
            marginLeft="1%"
            onClick={getSales}
          />
          <ClickableCard
            bgColor="#27bc86"
            label="Staff"
            icon={StaffIcon}
            marginLeft="1%"
            onClick={getStaff}
          />
          {userRole === "admin" ? (
            <ClickableCard
              bgColor="#f89f0e"
              label="Stock Adjustment"
              icon={StockAdjIcon}
              marginLeft="1%"
              onClick={getStockAdjustment}
            />
          ) : userRole === "user" ? (
            <ClickableCard
              bgColor="#f89f0e"
              label="Purchase Order"
              icon={StockAdjIcon}
              marginLeft="1%"
              onClick={getOrders}
            />
          ) : undefined}

          <ClickableCard
            bgColor="#f6689d"
            label="Quick Add"
            icon={QuickAddIcon}
            marginLeft="1%"
            onClick={addQuickAccess}
          />
        </div>
        {/* recent sales and quick access row  */}

        <div className="recent-sales-and-quickAccess-row">
          <div className="recent-sales-container">
            <h4>Recent Sales</h4>
            <div className="global-table-container dashboard-recent-sales-table">
              <table className="global-table">
                <thead>
                  <tr>
                    {userRole === "admin" && <th>Branch</th>}

                    <th>Date</th>
                    <th>O.No</th>
                    <th>Billing Type</th>
                    <th>Item Qty</th>
                    <th>Amt</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <tbody>
                    <tr>
                      <td colSpan={10}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={10}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={10}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {recentSale !== undefined ? (
                      recentSale?.map((r, i) => (
                        <tr>
                          {userRole === "admin" && <td>{r.branchName}</td>}

                          <td>{convertDateFormat(r.orderdate)}</td>
                          <td>{r.orderNo}</td>
                          <td>{r.billingType}</td>
                          <td>{r.itemQty}</td>
                          <td>{r.amount?.toFixed(2)}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6}>No Data</td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
          <div className="recent-sales-container" style={{ marginLeft: "1%" }}>
            <h4>Credit Details</h4>
            <div className="global-table-container dashboard-recent-sales-table">
              <table className="global-table">
                <thead>
                  <tr>
                    {userRole === "admin" && <th>Branch</th>}
                    <th>Customer</th>
                    <th>Mobi</th>
                    <th>T.Cr.Amt</th>
                    <th>Last Paid</th>
                    <th>L.P.Date</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <tbody>
                    <tr>
                      <td colSpan={10}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={10}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={10}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {creditDetails !== undefined ? (
                      creditDetails?.map((r, i) => (
                        <tr>
                          {userRole === "admin" && <td>{r?.branchId}</td>}
                          <td>
                            {r?.customerName === "No customerName"
                              ? "Nil"
                              : r.customerName}
                          </td>
                          <td>
                            {r?.mobileno === "No mobilleNo"
                              ? "Nil"
                              : r.mobileno}
                          </td>
                          <td>{r?.totalcreditAmount}</td>
                          <td>{r?.LastPaidAmount?.toFixed(2)}</td>
                          <td>{convertDateFormat(r?.lastPaidDate)}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6}>No Data</td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
          <div className="quick-access-container" style={{ marginLeft: "1%" }}>
            <h4>Quick Access</h4>
            <div className="quick-access-menus">
              {viewQuickAccess?.access?.map((item, i) => (
                <QuickAccess
                  backgroundColor={
                    i === 0
                      ? "#d0ebef"
                      : i == 1
                      ? "#dbf0e2"
                      : i == 2
                      ? "#ffddc3"
                      : i == 3
                      ? "#d3e3ec"
                      : i == 4
                      ? "#ebebe0"
                      : i == 5
                      ? "#ecd3d3"
                      : i == 6
                      ? "#cfc3ee"
                      : undefined
                  }
                  label={item.shortCutName}
                  labelColor="#01818f"
                  path={item.url}
                />
              ))}
            </div>
          </div>
          <Dialog
            open={openQuickAccessDialog}
            maxWidth="lg"
            onKeyDown={(e) =>
              e.key === "Escape" && setOpenQuickAccessDialog(false)
            }
          >
            <div className="quick-access-dialog-container">
              <h3>Add Quick Access</h3>
              <hr className="global-hr" />
              <div className="quick-access-dialog-select">
                <div className="global-single-input quick-access-edit auto-complete">
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={sideMenuList.filter((obj)=>(permittedQuickAccess.includes(obj.id))) || [""]}
                    getOptionLabel={(option) =>
                      `${option?.section} >> ${option?.name}`
                    }
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(e, newValue) => setQuickAccess1(newValue?.path)}
                    defaultValue={{ section: section1, name: name1 }}
                  />
                </div>
                <div className="global-single-input quick-access-edit clr-input-second auto-complete">
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={sideMenuList.filter((obj)=>(permittedQuickAccess.includes(obj.id))) || [""]}
                    getOptionLabel={(option) =>
                      `${option?.section} >> ${option?.name}`
                    }
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(e, newValue) => setQuickAccess2(newValue?.path)}
                    defaultValue={{ section: section2, name: name2 }}
                  />
                </div>
                <div className="global-single-input quick-access-edit clr-input-third auto-complete">
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={sideMenuList.filter((obj)=>(permittedQuickAccess.includes(obj.id))) || [""]}
                    getOptionLabel={(option) =>
                      `${option?.section} >> ${option?.name}`
                    }
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(e, newValue) => setQuickAccess3(newValue?.path)}
                    defaultValue={{ section: section3, name: name3 }}
                  />
                </div>
                <div className="global-single-input quick-access-edit clr-input-four auto-complete">
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={sideMenuList.filter((obj)=>(permittedQuickAccess.includes(obj.id))) || [""]}
                    getOptionLabel={(option) =>
                      `${option?.section} >> ${option?.name}`
                    }
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(e, newValue) => setQuickAccess4(newValue?.path)}
                    defaultValue={{ section: section4, name: name4 }}
                  />
                </div>
                <div className="global-single-input quick-access-edit clr-input-five auto-complete">
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={sideMenuList.filter((obj)=>(permittedQuickAccess.includes(obj.id))) || [""]}
                    getOptionLabel={(option) =>
                      `${option?.section} >> ${option?.name}`
                    }
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(e, newValue) => setQuickAccess5(newValue?.path)}
                    defaultValue={{ section: section5, name: name5 }}
                  />
                </div>
                <div className="global-single-input quick-access-edit clr-input-six auto-complete">
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={sideMenuList.filter((obj)=>(permittedQuickAccess.includes(obj.id))) || [""]}
                    getOptionLabel={(option) =>
                      `${option?.section} >> ${option?.name}`
                    }
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(e, newValue) => setQuickAccess6(newValue?.path)}
                    defaultValue={{ section: section6, name: name6 }}
                  />
                </div>
                <div className="global-single-input quick-access-edit clr-input-seven auto-complete">
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={sideMenuList.filter((obj)=>(permittedQuickAccess.includes(obj.id))) || [""]}
                    getOptionLabel={(option) =>
                      `${option?.section} >> ${option?.name}`
                    }
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(e, newValue) => setQuickAccess7(newValue?.path)}
                    defaultValue={{ section: section7, name: name7 }}
                  />
                </div>
              </div>
              <div className="quick-access-edit-button">
                <button
                  onClick={() => setOpenQuickAccessDialog(false)}
                  className="quick-access-edit-cancel-button"
                >
                  Cancel
                </button>
                <button
                  onClick={addQuickAccessSubmitBtn}
                  className="quick-access-edit-submit-button"
                >
                  Submit
                </button>
              </div>
            </div>
          </Dialog>
        </div>

        {/* dashboard row 3 */}
        <div className="dashboard-row-group-three">
          <div className="row-group-three-left">
            <div className="employee-chart">
              <div className="top-heading-row">
                <p className="name">Employee Chart</p>
                <p>Total Employee</p>
                <p className="count">
                  {employeeGenderCount !== undefined
                    ? employeeGenderCount?.total
                    : 0}
                </p>
              </div>
              <div className="progress-bars">
                <Tooltip
                  placement="top"
                  arrow
                  title={`Male : ${
                    employeeGenderCount !== undefined
                      ? employeeGenderCount?.male
                      : 0
                  }`}
                >
                  <progress
                    className="male"
                    value={
                      employeeGenderCount !== undefined
                        ? employeeGenderCount?.male
                        : 0
                    }
                    max={
                      employeeGenderCount !== undefined
                        ? employeeGenderCount?.total
                        : 0
                    }
                  />
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  arrow
                  title={`Female : ${
                    employeeGenderCount !== undefined
                      ? employeeGenderCount?.female
                      : 0
                  }`}
                >
                  <progress
                    className="female"
                    value={
                      employeeGenderCount !== undefined
                        ? employeeGenderCount?.female
                        : 0
                    }
                    max={
                      employeeGenderCount !== undefined
                        ? employeeGenderCount?.total
                        : 0
                    }
                  />
                </Tooltip>
              </div>
              <div className="progress-legend-row">
                <div className="legend-group">
                  <div className="color-indicator-male"> </div>
                  <p className="male">Male</p>
                </div>
                <div className="legend-group">
                  <div className="color-indicator-female"> </div>
                  <p className="female">Female</p>
                </div>
              </div>
            </div>
            <div className="expired-documents-container">
              <p className="heading">Staff Expired Documents</p>
              <div className="global-table-container dash-expired-docs-table-container">
                <table className="global-table">
                  <thead>
                    <tr>
                      <th>Employee</th>
                      <th>Employee ID</th>
                      <th>Documents</th>
                      {/* <th>Expiry Date</th> */}
                    </tr>
                  </thead>
                  {isLoading ? (
                    <tbody>
                      <tr>
                        <td colSpan={10}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={30}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={10}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={30}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={10}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={30}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {viewExpiredDocument !== undefined ? (
                        viewExpiredDocument?.map((r, i) => (
                          <tr>
                            <td>{r.employee}</td>
                            <td>{r.employeeId}</td>
                            <td>
                              <div className="add-document-documents-btn">
                                <button
                                  onMouseDown={extractSingleEmpObj(
                                    r.employeeId
                                  )}
                                  onClick={handleClick}
                                >
                                  View Details
                                  <span style={{ marginTop: "4px" }}>
                                    <KeyboardArrowDown />
                                  </span>
                                </button>
                              </div>

                              {/* <KeyboardArrowDown /> */}
                            </td>
                            {/* <td>--</td> */}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={10}>No Data</td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </div>

              {/* document list */}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                {singleEmpObj[0]?.documents?.map((k, index) => (
                  <MenuItem>{`${k.documentType} - ${convertDateFormat(
                    k.expiryDate
                  )}`}</MenuItem>
                ))}
              </Menu>
            </div>
          </div>
          <div className="row-group-three-right">
            <div className="total-revenue-container">
              <div className="top-bar">
                <p>Total Revenue</p>
                <CustomRadioGroup
                  customRadioData={customRadio}
                  value={status}
                  onChange={totalExpenseGraphChange}
                  backgroundColor="#3a00ff"
                />
              </div>

              <div className="chart-container">
                <Line options={options} data={data} />
              </div>
            </div>
            <div className="payments-container">
              <div
                className="top-bar"
                style={{ position: "relative", padding: "0 3%" }}
              >
                <p style={{ width: "30%" }}>
                  Fast & Slow Moving <br />
                  <span className="bar-chart-arrow-icon">
                    <SouthIcon
                      className="bar-chart-arrow-down-icon"
                      onClick={clickFastSellingToggle("slowSelling")}
                    />
                    <NorthIcon
                      className="bar-chart-arrow-down-icon"
                      onClick={clickFastSellingToggle("fastSelling")}
                    />
                  </span>
                </p>
                <div
                  style={{
                    width: "68%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {userRole === "admin" && chartType === "0" ? (
                    <div
                      className="global-single-input auto-complete"
                      style={{ width: "160px", marginRight: "10px" }}
                    >
                      <Autocomplete
                        sx={{ width: "100%" }}
                        options={allBranchesList || ["No data"]}
                        getOptionLabel={(option) => option?.branchName}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="All Branches" />
                        )}
                        onChange={(e, newValue) => {
                          setBranchId(newValue?._id);
                          setStartIndex(0);
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  <CustomRadioGroup
                    customRadioData={
                      userRole === "admin"
                        ? productOutlerRadio
                        : productOnlyRadio
                    }
                    value={chartType}
                    onChange={fastSellingChartChange}
                    backgroundColor="#ff7541"
                    className="product-outlet-radio"
                    singleContainerWidth="100%"
                  />
                </div>
              </div>

              <div style={{ width: "100%", position: "relative" }}>
                <Bar options={option} data={datas} />
                <div className="fast-selling-next-btn-ctn">
                  <div className="fast-selling-forward-date-ctn">
                    <div
                      className="global-single-input"
                      style={{
                        width: "40%",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "0.8rem",
                      }}
                    >
                      <p style={{ fontSize: "0.8rem", fontWeight: "800" }}>
                        From
                      </p>
                      <input
                        type="date"
                        onChange={getFastSellingForm("fromDate")}
                        value={fastSellingBody?.fromDate}
                      />
                    </div>
                    <div
                      className="global-single-input"
                      style={{
                        width: "40%",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "0.8rem",
                        marginLeft: "5%",
                      }}
                    >
                      <p style={{ fontSize: "0.8rem", fontWeight: "800" }}>
                        To
                      </p>
                      <input
                        type="date"
                        onChange={getFastSellingForm("toDate")}
                        value={fastSellingBody?.toDate}
                      />
                    </div>
                  </div>
                  <div className="fast-selling-forward-icons-ctn">
                    <ReactToPrint
                      trigger={() => (
                        <IconButton
                          className="print-icon"
                          style={{ color: "#ff0000", marginRight: "8px" }}
                        >
                          <i class="bi bi-printer"></i>
                        </IconButton>
                      )}
                      content={() => dashboardFastSellingGraph}
                      pageStyle="@page {size:auto; margin:0 0 0 0;}}"
                    />
                    <ArrowBackIosIcon
                      onClick={fastSellingListChange("min")}
                      style={{ cursor: "pointer" }}
                    />
                    <ArrowForwardIosIcon
                      onClick={fastSellingListChange("add")}
                      style={{
                        cursor: "pointer",
                        display: index ? "none" : "flex",
                      }}
                    />
                  </div>
                </div>
                {dataEmpty ? (
                  <p className="home-dashboard-chart-no-data-alert">
                    No Products Available
                  </p>
                ) : (
                  ""
                )}
              </div>

              {/* <div className="top-bar">
                <p>Payments</p>
                <Datepicker type="month" value={month} />
              </div>
              <div className="payments-data">
                <div className="global-table-container payments-container">
                  <table className="global-table payments-data-table">
                    <tbody>
                      <tr>
                        <td>
                          <div className="doc-image-container">
                            <img src={PaymentDocImage} alt="documentImg" />
                          </div>
                        </td>
                        <td className="align-left">
                          <span className="main-data">INV-AT-145</span> <br />
                          <span className="label">Invoice</span>
                        </td>
                        <td className="align-left">10-12-2021</td>
                        <td className="align-left">
                          <span className="main-data">Rs. 500</span> <br />
                          <span className="label">Amount</span>
                        </td>
                        <td>
                          <span className="status paid">Paid</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="doc-image-container">
                            <img src={PaymentDocImage} alt="documentImg" />
                          </div>
                        </td>
                        <td className="align-left">
                          <span className="main-data">INV-AT-145</span> <br />
                          <span className="label">Invoice</span>
                        </td>
                        <td className="align-left">10-12-2021</td>
                        <td className="align-left">
                          <span className="main-data">Rs. 500</span> <br />
                          <span className="label">Amount</span>
                        </td>
                        <td>
                          <span className="status pending">Pending</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="doc-image-container">
                            <img src={PaymentDocImage} alt="documentImg" />
                          </div>
                        </td>
                        <td className="align-left">
                          <span className="main-data">INV-AT-145</span> <br />
                          <span className="label">Invoice</span>
                        </td>
                        <td className="align-left">10-12-2021</td>
                        <td className="align-left">
                          <span className="main-data">Rs. 500</span> <br />
                          <span className="label">Amount</span>
                        </td>
                        <td>
                          <span className="status cancelled">Cancelled</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="doc-image-container">
                            <img src={PaymentDocImage} alt="documentImg" />
                          </div>
                        </td>
                        <td className="align-left">
                          <span className="main-data">INV-AT-145</span> <br />
                          <span className="label">Invoice</span>
                        </td>
                        <td className="align-left">10-12-2021</td>
                        <td className="align-left">
                          <span className="main-data">Rs. 500</span> <br />
                          <span className="label">Amount</span>
                        </td>
                        <td>
                          <span className="status paid">Paid</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="doc-image-container">
                            <img src={PaymentDocImage} alt="documentImg" />
                          </div>
                        </td>
                        <td className="align-left">
                          <span className="main-data">INV-AT-145</span> <br />
                          <span className="label">Invoice</span>
                        </td>
                        <td className="align-left">10-12-2021</td>
                        <td className="align-left">
                          <span className="main-data">Rs. 500</span> <br />
                          <span className="label">Amount</span>
                        </td>
                        <td>
                          <span className="status paid">Paid</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* dashboard row 4  */}
        {/* <div className="dashboard-row-group-four">
          <div className="top-bar">
            <div className="top-bar-left">
              <p>Product List</p>
              <div className="global-single-input" style={{ width: "55%" }}>
                <Select value={type} onChange={(e) => setType(e.target.value)}>
                  <MenuItem value={2}>Material</MenuItem>
                  <MenuItem value={0}>Readymade</MenuItem>
                  <MenuItem value={1}>Accessories</MenuItem>
                </Select>
              </div>
            </div>

            <DatepickerRanges
              backgroundColor="#002bff"
              color="#fff"
              fromValue={fromDate}
              fromOnChange={getFromDate}
              toValue={toDate}
              toOnChange={getToDate}
            />
          
          </div>
          <div className="global-table-container product-list-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>Product ID</th>
                  <th>Product Image</th>
                  <th>Product Name</th>
                  <th>Release Date</th>
                  <th>Unit</th>
                  <th>Stock</th>
                  <th className="closing-stock-column">Closing Stock</th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {dashboardProductList !== undefined ? (
                    dashboardProductList?.list?.map((r, i) => (
                      <tr>
                        <td>{r.productId}</td>
                        <td>
                          <img src={r.imageUrl} alt="" />
                        </td>
                        <td>{r.productName}</td>
                        <td>{convertDateFormat(r.releaseDate)}</td>
                        <td>{r.unitName}</td>
                        <td>{r.stock}</td>
                        <td>{r.closingstock}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10}>No Data</td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div> */}
      </div>

      {/* <div style={{display:"none",width:"100%"}}> */}
      <div style={{ position: "absolute", left: "-9999px", width: "100%" }}>
        <div
          ref={(el) => (dashboardFastSellingGraph = el)}
          className="home-dashboard-print-fast-sell"
        >
          <Bar
            options={option}
            data={datas}
            className="home-dashboard-print-fast-sell-bar"
          />
        </div>
      </div>
    </>
  );
};
