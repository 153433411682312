import { IconButton, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Datepicker } from "../../../Single Components/Datepicker";
import { convertDateFormat, getCurrentTime, previousDate, today } from "../../../../Js/Date";
import { useNavigate } from "react-router-dom";
import { viewPurchaseOrderApiCall } from "../../../../API/Purchase Manager/purchaseOrderAPI";
import { PurchaseOrderFilterAPICall, viewPurchaseOrderList } from "../../../../API/Purchase Manager/NewPurchaseOrder/PurchaseOrder";
import { useSelector } from "react-redux";
import store from "../../../../Redux/store";
import { purchaseOrderSingleView } from "../../../../Redux/Prchase Manager/RFQ/RfqSlice";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { salesQuotationFilterAPICall } from "../../SalesNew/SalesOrders/SalesQuotation/salesQuatationAPI";
import { viewAllAllowedBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { getAllActiveEmployeesAPICall } from "../../../../API/Staff/HRM/employeeAPI";
import { get_sales_order_quot_row_data_list } from "../../SalesNew/SalesOrders/SalesQuotation/salesQuatationSlice";
import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../Single Components/CustomPagination";
import axios from "axios";
import { setFilterActive } from "../../../Single Components/listSlice/filterCategorySlice";
// import { convertDateFormat, previousDate } from "../../../../../Js/Date";

export const NewPurchaseOrder = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const userBranchId = localStorage.getItem("branchId");
  const [FormDataInfo, setFormDataInfo] = useState([])

  const branchId = localStorage.getItem("branchId");
  const PurchaseOrderList = useSelector(
    (state) => state?.RfqSlice?.purchaseOrderList
  );
  //console.log(PurchaseOrderList);
  let navigateTo = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const totalPages = PurchaseOrderList?.pages;
  localStorage.removeItem("singleViewByIdgrn");
  useEffect(() => {

    // viewPurchaseOrderList(branchId);
    localStorage.removeItem("singleViewById");
    store.dispatch(purchaseOrderSingleView({ singleRowData: undefined }));
  }, []);

  //handleClickRow

  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    //store.dispatch(purchaseOrderSingleView({singleRowData:row }));
    //navigateTo("/userdashboard/purchaseManager/CreateNewPurchaseOrder");
    localStorage.setItem("singleViewById", row?._id);
    localStorage.setItem("singleViewByIdgrn", row?._id);
    navigateTo("/userdashboard/purchaseManager/PurchaseOrder/singleView");
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData && PurchaseOrderFilterAPICall({
      //   fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
      // toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        createdBy: FormDataInfo.createdBy,
        branchList: FormDataInfo.branchList,
        type: FormDataInfo.status,
        search: FormDataInfo.search,
        index: currentPage - 1,
      }, setIsLoading);
    };
 
  }, [FormDataInfo, currentPage]);
  return (
    <>
      <div className="global-page-parent-container">
        {/* <p className="breadcrumb">Purchase Manager &gt; Purchase Order</p> */}
        <div className="purchase-order-main-container">
          <div className="justify-space-between"
            style={{ gap: "0 12px",  display: 'flex', justifyContent: 'space-between', alignItems: 'end'  }}
          >
             {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                <h3>Branch name: {selectedBranch?.storeCode}-{selectedBranch?.branchName}</h3>
              </div>
            )}
             <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
            <CategorySearchandFilter
              statusOptions={['PURCHASE ORDER', 'DONE']}
              onData={handleDataFromChild}
              isCreatedBy={true}
              isStatus={true}
              isDate={false}
              onBranchSelect={handleBranchSelect}
            />
            <button
              className="btn btn-primary"
              onClick={() =>
                navigateTo(
                  "/userdashboard/purchaseManager/CreateNewPurchaseOrder"
                )
              }
            >
              Create
            </button>
            </div>
          </div>
          <div className="top-bar">
            <h3>Purchase Order</h3>
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              setFormDataInfo={setFormDataInfo}
            />
          </div>
          <div className="new-global-table-container">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>PO No</th>
                  <th>Supplier Name</th>

                  <th>Date</th>
                  <th>Total</th>
                  <th>CreatedBy</th>
                  <th
                    style={{
                      width: "24%",
                    }}
                  >
                    Status
                  </th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {PurchaseOrderList?.list?.length !== 0 ? (
                    PurchaseOrderList?.list?.slice(0)

                      ?.map((r, i) => (
                        <tr key={i} onClick={() => handleClickRow(r)}>
                          <td>{i + 1}</td>
                          <td>{r?.sequence}</td>
                          <td>{r?.supplierName}</td>

                          <td>{r?.quotationDate}</td>
                          <td>{r?.total}</td>
                          <td>{r?.CREATEDBY}</td>
                          <td className="table-data-box">
                            {r?.type === "RFQ" ? (
                              <span className="rfq"> RFQ</span>
                            ) : r?.type === "PURCHASE ORDER" ? (
                              <span
                                className="po"
                                style={{ padding: "5px 25px" }}
                              >
                                PO
                              </span>
                            ) : r?.type === "CANCEL" ? (
                              <span className="cancel"> CANCEL</span>
                            ) : r?.type === "DONE" ? (
                              <span className="post"> DONE</span>
                            ) : (
                              "----"
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={10}>No Data</td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
