import axios from "axios";
import { HEADERS, HEADER_FORM_DATA } from "../../../../../API/UrlAndPaths";
import store from "../../../../../Redux/store";
import {
  getDefaultLocationProductList,
  get_branch_transfer_list,
  get_branch_transfer_product_list,
  get_branch_transfer_single_view,
  get_branch_transfer_transNo,
} from "./BranchTransferSlice";

export const viewAllBranchTransferAPICall = (body, setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true);
  axios
    .post("inventory/viewbranchtransfer", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_branch_transfer_list({ branchTransferData: res.data })
        );
        setIsLoading !== undefined && setIsLoading(false);
      }
    })
    .catch((err) => {
      console.log(err.response);
      setIsLoading !== undefined && setIsLoading(false);
    });
};
export const BranchTransferFilterAPICall = (body,setIsLoading) => {
  axios
    .post("inventory/viewbranchtransfer", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_branch_transfer_list({ branchTransferData: res.data }));
        setIsLoading!==undefined&&setIsLoading(false)
      }
    })
    .catch((err) => {
      store.dispatch(get_branch_transfer_list({ branchTransferData: undefined }));
      setIsLoading!==undefined&&setIsLoading(false)
    });
};

export const addBranchTransferAPICall = (
  formData,
  postFn,
  setState,
  state,
  setEditDataId,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  axios
    .post("inventory/addbranchtransfer", formData, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)
        setState({
          ...state,
          message: "Branch Transfer drafted",
          success: true,
        });
        postFn();
        setEditDataId(res.data?._id);
        localStorage.setItem("singleViewById", res.data?._id);
      }
    })
    .catch((err) => {
      // console.log(err.response.data)
      setLoadingForm!==undefined && setLoadingForm(false)
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    });
};

export const editBranchTransferAPICall = (
  body,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg,
  editFn,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)

  axios
    .post("inventory/editbranchtransfer", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)

        setSnackMsg("Edited succesfully");
        setSuccessSnackOpen(true);
        editFn !== undefined && editFn();
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)

      switch (err.response.status) {
        case 404:
          setSnackMsg("No data available..");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;
        case 409:
          setSnackMsg("Duplicated");
        default:
          setSnackMsg(
            "Unseen error occurred,Please contact to EYET Support!!!!"
          );
          break;
      }
      setErrorSnackOpen(true);
    });
};

export const generateBranchTransferNo = (body,setSnackMsg, setErrorSnackOpen) => {
  axios
    .post("inventory/generatebranchtransNo", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_branch_transfer_transNo({ branchTransferTransNo: res.data })
        );
      }
    })
    .catch((err) => {
      console.log(err.response);
      setSnackMsg(err.response?.data?.codeprefix);
      setErrorSnackOpen(true);
    });
};

export const postBranchTransferAPICall = (
  body,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg,
  editFn,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  axios
    .post("inventory/postBranchTransfer", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Branch transfere posted");
        setSuccessSnackOpen(true);
        editFn !== undefined && editFn();
        setLoadingForm!==undefined && setLoadingForm(false)
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)

      switch (err.response.status) {
        case 404:
          setSnackMsg(err.response.data);
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;
        case 409:
          setSnackMsg("Duplicated");
        default:
          setSnackMsg(
            "Unseen error occurred,Please contact to EYET Support!!!!"
          );
          break;
      }
      setErrorSnackOpen(true);
    });
};

//single view
export const singleViewBtApi = async (body) => {
  try {
    let response = await axios.post(
      `inventory/branchTransferSingleView`,
      body,
      HEADERS
    );
    if (response.status === 200) {
      store.dispatch(
        get_branch_transfer_single_view({
          singleBranchTransferData: response.data,
        })
      );
    }
  } catch (error) {
    console.log("Error: ", error);
    throw new Error(error);
  }
};
//get product api call
export const getLocationProductListApi = async (body) => {
  console.log("api body", body);
  try {
    let response = await axios.post(
      `readymade/viewProductsWithBranchFilter`,
      body,
      HEADERS
    );
    if (response.status === 200) {
      store.dispatch(get_branch_transfer_product_list(response.data));
    }
  } catch (error) {
    console.log("Error: ", error);
    throw new Error(error);
  }
};
//get product api call default loc
export const getDefaultLocationProductListApi = async (body) => {
  console.log("api body", body);
  try {
    let response = await axios.post(
      `inventory/getDefaultLocationProductList`,
      body,
      HEADERS
    );
    if (response.status === 200) {
      store.dispatch(get_branch_transfer_product_list(response.data));
    }
  } catch (error) {
    console.log("Error: ", error);
    throw new Error(error);
  }
};

//action api call 
export const branchTransferActionAPI = async (body,setLoadingForm,state,setState) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  try {
    let response = await axios.post(
      `inventory/requestBranchTransfer`,
      body,
      HEADERS
    );
    if (response.status === 200) {
      singleViewBtApi({id:response.data._id})
      setLoadingForm!==undefined && setLoadingForm(false)
    }
  } catch (error) {
    if (error.response.status === 500) {
      setState({ ...state, message: "Internal Server error !", error: true })
    } else {
      setState({ ...state, message: error?.response?.data, error: true })
    }
    setLoadingForm!==undefined && setLoadingForm(false)
    throw new Error(error);
  }
}

