import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "../inventoryProducts.css"
import { useState } from "react";
import { ProductsSwitchInputField } from "./Options/ProductsSwitchInputField";
import { FilePicker } from "../../../../Single Components/filePicker";
import { useEffect } from "react";
import { posCategoryListAPICall } from "../../Configuration/POSCategory/posCategoryAPI";
import { categoryListAPICall } from "../../Configuration/Category/CategoryAPI";
import { useSelector } from "react-redux";
import { filterObjFromList, validateFileSize } from "../../../../../Js/generalFunctions";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import { createInventoryProductMaster, editInventoryProductMasterAPICall, generateProductMasterCodeAPICall, singleViewProductMasterAPICall } from "../inventoryProductsAPI";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import { CloseOutlined } from "@mui/icons-material";
import { LoadingForm } from "../../../../Single Components/LoadingForm"
export const CreateProductMaster = () => {

    let navigateTo = useNavigate();

    const backButtonFunction = () => {

        if (formValues?.isEdit === false) {
            const userConfirmed = window.confirm("Changes you made may not be saved.");

            if (userConfirmed) {
                navigateTo("/userdashboard/inventory/products/productmaster");
            }
        }
        else {
            navigateTo("/userdashboard/inventory/products/productmaster");
        }
    }

    const singleViewById = localStorage.getItem("singleViewById")

    const editedData = useSelector((state) => state.inventoryProductSlice.rowData)
    const generateCode = useSelector((state) => state.inventoryProductSlice.generateCode)

    const posCategoryList = useSelector((state) => state?.posCategorySlice?.value);
    const CategoryList = useSelector((state) => state.categorySlice.value);

    const switchOptions = [{
        option: "General Info"
    },
    {
        option: "Accounting",
    },
    {
        option: "Inventory",
        // list: [
        //     { label: "Tracking", autocomplete: true, type: "text" },
        //     { label: "Reorder Quantity", autocomplete: false, type: "number" },
        // 
    },

    {
        option: "Other Info"

    },
    {
        option: "Attribute",

    }, {
        option: "Image Upload"
    }

    ]

    const formValuesInitialState = {
        productName: "",
        productId: "###",
        productType: "Goods",
        nativeName: "",
        partNo: null,
        mainCategory: null,
        posCategory: null,
        // isFabrics: true,
        isStock: true,

        isEdit: false
    }
    const [formValues, setFormValues] = useState(formValuesInitialState)
    const [validationAlert, setValidationAlert] = useState({})
    const [image, setImage] = useState(null);
    const [editClicked, setEditClicked] = useState(false);
    const [productAdded, setProductAdded] = useState(false);
    const [filteredPOSCategory, setFilteredPOSCategory] = useState([])
    const [loadingForm, setLoadingForm] = useState(false)



    const [imageEdit, setImageEdit] = useState(false)
    const [imageDisplay, setImageDisplay] = useState([])
    const [formDataInfo, setFormDataInfo] = useState({})
    const [selectedOption, setSelectedOption] = useState(switchOptions[0]);
    const [snackBarStates, setSnackBarStates] = useState({
        success: false,
        error: false,
        message: "",
        alert: false
    });
    const [isFabricHover, setIsFabricHover] = useState(false)



    const closeSnackbar = () => {
        setSnackBarStates({
            ...snackBarStates,
            success: false,
            error: false,
            alert: false,
            message: "",
        });
    };
    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
        // setFormValues((prev)=>({...prev,data}))
        // console.log(data,'kkk')

    };
    console.log()


    const getFormInfo = (key) => (e, newValue) => {
        const { value } = e.target;
        setValidationAlert({});
        // if (key === "fabrics") {
        //     setFormValues({ ...formValues, isFabrics: e.target.checked });
        // }
        if (key === "stock") {
            setFormValues({ ...formValues, isStock: e.target.checked });
        }
        else if (key === "mainCategory") {
            let filteredArray = posCategoryList?.filter((obj) => obj?.catId === newValue?.categoryName)

            setFilteredPOSCategory(filteredArray)

            setFormValues({ ...formValues, mainCategory: newValue, posCategory: null });
        }
        else {
            setFormValues({ ...formValues, [key]: value !== 0 ? value : newValue });
        }

    };


    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const getImage = (e) => {
        let file = e.target.files[0];
        let result = validateFileSize(file, 1, 400);

        if (result?.isValid) {
            setImage(file);
        } else {
            setSnackBarStates({ ...snackBarStates, message: "Select image with size less than 400 kb,current size " + result?.actualFileSize + " kb", alert: true })
        }
    };

    const clearStates = () => {
        setFormValues(formValuesInitialState)
        setImage(null)

        setProductAdded(true)
    }

    const formData = new FormData()

    /*required*/ formData.append("name", formValues?.productName)
    /*required*/ formData.append("partNo", formValues?.partNo)
    // formData.append("isFabric", formValues.isFabrics)
    formData.append("isStock", formValues.isStock)
    formValues?.nativeName !== "" && formData.append("nativeName", formValues?.nativeName)
    /*required*/ formData.append("mainCatgeoryId", formValues?.mainCategory?._id)
    /*required*/ formData.append("poscat", formValues?.posCategory?._id)
    /*required*/ formData.append("prodType", formValues?.productType)

    // generalinfo
    formDataInfo?.formValues?.barcode !== "" && formData.append("barcode", Number(formDataInfo?.formValues?.barcode))
    formDataInfo?.formValues?.brand !== null && formData.append("brand", formDataInfo?.formValues?.brand?._id)
    formDataInfo?.formValues?.brandModelId !== null && formData.append("brandModelId", formDataInfo?.formValues?.brandModelId?._id)
    formDataInfo?.formValues?.manufactureYearId !== null && formData.append("manufactureYearId", formDataInfo?.formValues?.manufactureYearId?._id)
    formDataInfo?.formValues?.partTypeId !== null && formData.append("partTypeId", formDataInfo?.formValues?.partTypeId?._id)
    formDataInfo?.formValues?.manufactureId !== null && formData.append("manufactureId", formDataInfo?.formValues?.manufactureId?._id)
    formDataInfo?.formValues?.description !== '' && formData.append("description", formDataInfo?.formValues?.description)
    formDataInfo?.formValues?.releaseDate !== "" && formData.append("releaseDate", formDataInfo?.formValues?.releaseDate ? `${formDataInfo?.formValues?.releaseDate} 00:00:00` : undefined)
    /*required*/ formData.append("uom", formDataInfo?.formValues?.uom?._id)
    /*required*/ formData.append("purchaseuom", formDataInfo?.formValues?.purchaseUom?._id)
    /*required*/ formData.append("cost", Number(formDataInfo?.formValues?.cost))
    /*required*/ formData.append("salesPrice", Number(formDataInfo?.formValues?.salesPrice))
    formDataInfo?.formValues?.hsnCode !== null && formData.append("hsnCode", formDataInfo?.formValues?.hsnCode)
    formDataInfo?.formValues?.hsCode !== null && formData.append("hsCode", formDataInfo?.formValues?.hsCode?.hs)
    formData.append("nonTaxable", formDataInfo?.formValues?.nonTaxable)
    formData.append("vendorTax", formDataInfo?.formValues?.vendorTax?._id || null)
    formData.append("customerTax", formDataInfo?.formValues?.customerTax?._id || null)
    formDataInfo?.formValues?.tracking !== null && formData.append("tracking", formDataInfo?.formValues?.tracking)
    formDataInfo?.formValues?.reorderQty !== "" && formData.append("reorderQty", Number(formDataInfo?.formValues?.reorderQty))
    formDataInfo?.formValues?.materialComp !== "" && formData.append("materialComposition", formDataInfo?.formValues?.materialComp)
    formDataInfo?.formValues?.productCare !== null && formData.append("productCare", formDataInfo?.formValues?.productCare?.productCareName)
    formDataInfo?.formValues?.pattern !== null && formData.append("pattern", formDataInfo?.formValues?.pattern?.patternName)
    formDataInfo?.formValues?.material !== null && formData.append("material", formDataInfo?.formValues?.material?.clothMaterialName)
    formDataInfo?.formValues?.countryOfOrigin !== null && formData.append("origin", formDataInfo?.formValues?.countryOfOrigin?.name)
    formDataInfo?.formValues?.returnPolicy !== null && formData.append("returnPolicy", formDataInfo?.formValues?.returnPolicy?.name)

    formDataInfo?.attributeTable?.length !== 0 && formData.append("attribute", JSON.stringify(formDataInfo?.attributeTable))
    image !== null && formData.append("imageUrl", image)
    formDataInfo?.image1 !== null && formData.append("imageUrl", formDataInfo?.image1)
    formDataInfo?.image2 !== null && formData.append("imageUrl", formDataInfo?.image2)
    formDataInfo?.image3 !== null && formData.append("imageUrl", formDataInfo?.image3)
    formDataInfo?.image4 !== null && formData.append("imageUrl", formDataInfo?.image4)

    editedData !== undefined && formData.append("_id", editedData?._id)


    const createBtnClick = (key) => () => {
        console.log(formDataInfo)
        if (formValues?.productName === "" || formValues?.productName === undefined) {
            setValidationAlert({ ...validationAlert, productName: "fill this field !!" })
        }
        else if (formValues?.mainCategory === null || formValues?.mainCategory === undefined) {
            setValidationAlert({ ...validationAlert, mainCategory: "fill this field !!" })
        }
        else if (formValues?.posCategory === null || formValues?.posCategory === undefined) {
            setValidationAlert({ ...validationAlert, posCategory: "fill this field !!" })
        }
        else if (formDataInfo?.formValues?.uom === null || formDataInfo?.formValues?.uom === undefined) {
            setSnackBarStates({ ...snackBarStates, message: "UOM is Required", alert: true })
        }
        else if (formDataInfo?.formValues?.purchaseUom === null || formDataInfo?.formValues?.purchaseUom === undefined) {
            setSnackBarStates({ ...snackBarStates, message: " Purchase UOM is Required", alert: true })
        }
        else if (formDataInfo?.formValues?.cost === "" || formDataInfo?.formValues?.cost === undefined) {
            setSnackBarStates({ ...snackBarStates, message: "Cost is Required", alert: true })
        }
        else if (formDataInfo?.formValues?.salesPrice === "" || formDataInfo?.formValues?.salesPrice === undefined) {
            setSnackBarStates({ ...snackBarStates, message: "Sales price is Required", alert: true })
        }
        // else if(formDataInfo?.formValues?.vendorTax===null || formDataInfo?.formValues?.vendorTax===undefined){
        //     setSnackBarStates({ ...snackBarStates, message: " Vendor Tax is Required", alert: true })
        // }
        // else if(formDataInfo?.formValues?.customerTax===null || formDataInfo?.formValues?.customerTax===undefined){
        //     setSnackBarStates({ ...snackBarStates, message: " Customer Tax is Required", alert: true })
        // }
        else {


            if (key === "add") {
                createInventoryProductMaster(formData, clearStates, setSnackBarStates, snackBarStates, setLoadingForm)
            }
            else if (key === "edit") {
                let postFn = () => {
                    setFormValues({ ...formValues, isEdit: true })
                    setEditClicked(false)
                }
                editInventoryProductMasterAPICall(formData, postFn, setSnackBarStates, snackBarStates, setLoadingForm)
            }
        }
    }

    useEffect(() => {
        if (editedData !== undefined) {
            setFormValues({
                ...formValues,
                isEdit: true,
                productName: editedData?.name,
                // isFabrics: editedData?.isFabric,
                isStock: editedData?.isStock,
                partNo: editedData?.partNo,
                nativeName: editedData?.nativeName,
                mainCategory: filterObjFromList("_id", CategoryList, "mainCatgeoryId", editedData),
                posCategory: filterObjFromList("_id", posCategoryList, "poscat", editedData),
                productType: editedData?.prodType,
                productId: editedData?.prodId
            })

            if (editedData?.imageUrl !== null) {
                setImageEdit(true)
                setImageDisplay(editedData?.imageUrl)
            }

        }
    }, [editedData, CategoryList, posCategoryList])




    useEffect(() => {
        posCategoryListAPICall()
        categoryListAPICall()
        generateProductMasterCodeAPICall()
        singleViewProductMasterAPICall({ _id: singleViewById })
    }, [])

    useEffect(() => {
        if (editedData === undefined) {
            generateCode !== undefined && setFormValues({ ...formValues, productId: `${generateCode?.prefix}${generateCode?.code}` })
        }
    }, [generateCode])
    return (
        <div style={{ position: "relative" }}>
            <div className="global-page-parent-container">
                {/* <p className="breadcrumb">
                    Inventory &gt; Products &gt; Product Master &gt; Create
                </p> */}
                <div className="global-white-bg-container">
                    <div style={{ width: "40%" }}>
                        <Tooltip title="Back">
                            <IconButton onClick={backButtonFunction}>
                                <i class="bi bi-chevron-left"></i>
                            </IconButton>
                        </Tooltip>

                        {
                            (editedData === undefined
                                // && !productAdded
                            ) ?
                                <Tooltip title="save">
                                    <IconButton
                                        onClick={createBtnClick("add")}
                                    >
                                        <i class="bi bi-floppy save-icon"></i>
                                    </IconButton>
                                </Tooltip> :
                                <Tooltip title="Edit">
                                    {
                                        !editClicked ?
                                            <IconButton
                                                onClick={() => {

                                                    let filteredArray = posCategoryList?.filter((obj) => obj?.catId === formValues?.mainCategory?.categoryName)
                                                    setFilteredPOSCategory(filteredArray)

                                                    setEditClicked(true)
                                                    setFormValues({ ...formValues, isEdit: false })
                                                }}>
                                                <i class="bi bi-pencil-square edit-icon1"></i>
                                            </IconButton> :
                                            <IconButton
                                                onClick={createBtnClick("edit")}>
                                                <i class="bi bi-check2-square edit-icon1"></i>
                                            </IconButton>
                                    }
                                </Tooltip>
                        }
                    </div>
                </div>

                <div className="global-white-bg-container" style={{ padding: "1% 2%" }}>
                    <div className="justify-space-between" style={{ justifyContent: "start" }}>
                        <div className="justify-space-between" style={{ width: "50%" }}>
                            <div className="global-single-input" style={{ width: "400px" }}>
                                <p style={{ fontSize: "1.2rem" }}>Product Name
                                    <span style={{ color: "rgb(155 155 155)", fontSize: "1rem", fontWeight: "400", marginTop: "5px", float: "right" }}>
                                        {formValues?.productId}</span></p>
                                <input
                                    style={{ height: "40px" }}
                                    type="text" placeholder="Name"
                                    value={formValues?.productName}
                                    onChange={getFormInfo("productName")}
                                    disabled={formValues?.isEdit}
                                />
                                <p className="doc-validation-alert">{validationAlert?.productName}</p>


                            </div>
                            <div className="global-single-input auto-complete">
                                <p>Part Number</p>
                                <input type="text"
                                    value={formValues?.partNo}
                                    onChange={getFormInfo("partNo")}
                                    disabled={formValues?.isEdit}
                                />

                            </div>
                            {/* <div className="isFabric-class">
                                 <input type="checkbox" 
                                    id="isFabricProductMaster"
                                    value={true}
                                    onChange={getFormInfo("fabrics")}
                                    checked={formValues?.isFabrics===true}
                                    disabled={formValues?.isEdit}
                                 />
                                 <label htmlFor="isFabricProductMaster">IS FABRIC</label>
                                 
                                 <i onMouseEnter={()=>setIsFabricHover(true)}
                                 onMouseLeave={()=>setIsFabricHover(false)}
                                 class="bi bi-question-circle fabric-details-icon"></i>
                                 
                                
                            </div> */}
                        </div>


                        <div className="justify-space-between" style={{ width: "50%", position: 'relative' }}>

                            <p
                                style={{ display: isFabricHover ? "block" : "" }}
                                className="fabrics-details">
                                The checked items will be displayed in workorder for stitching
                            </p>

                            {
                                !imageEdit ?
                                    <FilePicker
                                        position="absolute"
                                        right="0"
                                        id="productImg"
                                        uploadImageUrl={image}
                                        setImage={setImage}
                                        onChange={getImage}

                                    /> :
                                    <div className="uploaded-image-list" style={{ position: "absolute", right: 0 }}>
                                        <img
                                            src={imageDisplay[0]}
                                            alt="" />
                                        <div className="image-remove-icon-new">
                                            {
                                                formValues?.isEdit === false &&
                                                <IconButton
                                                    onClick={() => setImageEdit(false)}
                                                // disabled={branchLocationForm?.isEdit}
                                                >
                                                    <CloseOutlined
                                                        sx={{ color: "#fd0301", fontSize: "0.9rem" }}
                                                    />
                                                </IconButton>}
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="global-input-field-container" style={{ flexWrap: "wrap", justifyContent: "space-between" }}>
                        <div className="radio-groups-container" style={{ width: "15%", marginTop: "1%", alignSelf: "start", gap: "10px" }}>
                            <div className="global-radio-button  radio-group">
                                <input
                                    type="radio"
                                    name="productMasterProductType"
                                    id="productMasterGoods"
                                    value={true}
                                    checked={formValues?.isStock === true}
                                    onChange={getFormInfo("stock")}
                                    disabled={formValues?.isEdit}

                                />
                                <label htmlFor="productMasterGoods">Stock</label>
                            </div>
                            {/* <div className="isFabric-class">
                                 <input type="checkbox" 
                                    id="isFabricProductMaster"
                                    value={true}
                                    onChange={getFormInfo("fabrics")}
                                    checked={formValues?.isFabrics===true}
                                    disabled={formValues?.isEdit}
                                 />
                                 <label htmlFor="isFabricProductMaster">IS FABRIC</label>
                                 
                                 <i onMouseEnter={()=>setIsFabricHover(true)}
                                 onMouseLeave={()=>setIsFabricHover(false)}
                                 class="bi bi-question-circle fabric-details-icon"></i>
                                 
                                
                            </div> */}
                            <div className="global-radio-button  radio-group">
                                <input
                                    type="radio"
                                    name="productMasterProductType"
                                    id="productMasterService"
                                    value="Service"
                                    checked={formValues.productType === "Service"}
                                    onChange={getFormInfo("productType")}
                                    disabled={formValues?.isEdit}
                                />
                                <label htmlFor="productMasterService">Service</label>
                            </div>
                        </div>
                        <div className="global-single-input">
                            <p>Native Name</p>
                            <input type="text" placeholder="Native Name"
                                value={formValues?.nativeName}
                                onChange={getFormInfo("nativeName")}
                                disabled={formValues?.isEdit}
                            />
                        </div>
                        <div className="global-single-input auto-complete">
                            <p>Category</p>
                            <Autocomplete
                                options={CategoryList || []}
                                getOptionLabel={(option) => option?.categoryName}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="select a category" />
                                )}
                                value={formValues?.mainCategory}
                                onChange={getFormInfo("mainCategory")}
                                disabled={formValues?.isEdit}
                            />
                            <p className="doc-validation-alert">{validationAlert?.mainCategory}</p>
                        </div>
                        <div className="global-single-input auto-complete">
                            <p>Sub Category</p>
                            <Autocomplete
                                options={filteredPOSCategory || []}
                                getOptionLabel={(option) => option?.name}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="select a pos category" />
                                )}
                                value={formValues?.posCategory}
                                onChange={getFormInfo("posCategory")}
                                disabled={formValues?.isEdit}
                            />
                            <p className="doc-validation-alert">{validationAlert?.posCategory}</p>
                        </div>
                    </div>

                    {/* switch field section */}
                    <div className="products-switch-menu">
                        {
                            switchOptions?.map((option) => (

                                <div
                                    key={option?.option}
                                    className={`option ${selectedOption?.option === option?.option ? 'selected' : ''}`}
                                    onClick={() => handleOptionClick(option)}
                                >
                                    {option?.option}
                                </div>
                            ))
                        }
                    </div>

                    <div className="products-switch-list">
                        <ProductsSwitchInputField
                            options={selectedOption}
                            onData={handleDataFromChild}
                            editedData={editedData}
                            editEnabled={formValues?.isEdit}
                            productAdded={productAdded}
                        />
                    </div>
                </div>
            </div>


            <ErrorSnackbar
                style={{ top: "7%", left: "50%", height: "20px", width: "auto", transform: "translateX(-50%)", position: "absolute" }}
                open={snackBarStates.alert}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
            <SuccessSnackbar
                open={snackBarStates.success}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
            <ErrorSnackbar
                open={snackBarStates.error}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
            <LoadingForm loading={loadingForm} />

        </div>
    )
}