import {
  Autocomplete,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { chartOfAccountListApiCall } from "../../../../API/AccountUpdated/chartOfAccountAPI";
import { useSelector } from "react-redux";
import {
  TaxSettingsFilterAPICall,
  UpdateTaxStatusAPiCall,
  addTaxSettings,
  createTaxSettings,
  createTaxSettingsApicall,
  getAllTaxSettingsListAPICall,
  updateTaxSettingsApiCall,
} from "../../../../API/Settings/TaxSettings/taxSettingsAPI";
import store from "../../../../Redux/store";
import { Tax_single_View } from "../../../../Redux/Settings/TaxSettings/taxSettingsSlice";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { filterObjFromList } from "../../../../Js/generalFunctions";
import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../Single Components/CustomPagination";
import { setFilterActive } from "../../../Single Components/listSlice/filterCategorySlice";

// -------------------- //
const TaxScop = [
  {
    Scope: "Service",
  },
  {
    Scope: "Goods",
  },
];

const Tax_computation = [
  {
    computation: "Percentage of price",
  },
  {
    computation: "Fixed",
  },
];

const TaxAccount = [
  {
    Account: "",
  },
];


const Tax_type = [
  {
    Type: "Purchase",
  },
  {
    Type: "Sales",
  },
];

// ---------------------- //

export const TaxSettings = () => {
  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );

  const filtereDataOptions = useSelector((state) => state.listCategory);
  const TaxList = useSelector((state) => state.taxSettingsSlice.taxFiltered);
  const SingleViewTax = useSelector(
    (state) => state?.taxSettingsSlice?.TaxSingleView
  );

  const [TaxStatus,setTaxStatus] = useState(false)
  const [CreateTax, setCreateTax] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const currencyListUpdated = ["a", "b"];
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!!");
  const [TaxScopName, setTaxScopName] = useState(null);
  const [taxComputationName, settaxComputationName] = useState(null);
  const [TaxAccountName, setTaxAccountName] = useState(null);
  const [TaxTypeName, setTaxTypeName] = useState(null);
  const [TaxAccountRefund, setTaxAccountRefund] = useState(null);

  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [disabledInput, setDisabledInput] = useState(false);
  const [ConfEditShow, setConfEditShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = TaxList?.pages;
  const [isLoading, setIsLoading] = useState(false)
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [addCreateTax, setAddCreateTax] = useState({
    taxName: "",
    taxScope: "",
    isReverseVat: false,
    isexempted: false,
    taxComputation: "",
    taxAccount: "",
    taxType: "",
    amount: "",
    taxAccountOnRefund: "",
    includedPrice: false,
    // status: false
  });

  const [addTaxAlert, setAddTaxAlert] = useState({
    taxNameAlert: false,
    taxScopeAlert: false,
    taxComputationAlert: false,
    taxAccountAlert: false,
    taxTypeAlert: false,
    amountAlert: false,
    taxAccountOnRefundAlert: false,
  });
  const [selectedBranch, setSelectedBranch] = useState(null);
const handleBranchSelect = (selectedBranch) => {
  setSelectedBranch(selectedBranch); 

};

  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const closeSuccessSnack = () => {
    setSuccessSnackOpen(false);
  };

  const closeErrorSnack = () => {
    setErrorSnackOpen(false);
  };

  const CreateTaxbtn = () => {
    setShowSaveBtn(true);
    setCreateTax(true);
    setDisabledInput(false);
  };


  // useEffect(() => {
  //   // getAllTaxSettingsListAPICall();
  // }, [CreateTax,TaxStatus]);

  //singleViewTax
  const singleViewTax = (r) => {
    store.dispatch( setFilterActive(window.location.pathname))
    store.dispatch(Tax_single_View({ singleViewOfTax: r }));
    setShowSaveBtn(false);
    setCreateTax(true);
    setDisabledInput(true);
    setConfEditShow(false);
  };

  //  CreateTaxFun
  const CreateTaxFun = (key) => (e) => {
    const { value } = e.target;
    // TaxName
    if (key === "TaxName") {
      setAddCreateTax({
        ...addCreateTax,
        taxName: value,
      });
      setAddTaxAlert({
        ...addTaxAlert,
        taxNameAlert: false,
      });
      if (value === "") {
        setAddTaxAlert({
          ...addTaxAlert,
          taxNameAlert: true,
        });
      }
    }

    //Tax Amount

    if (key === "Amount") {
      setAddCreateTax({
        ...addCreateTax,
        amount: value >= 100 ? 99 : value,
      });

      setAddTaxAlert({
        ...addTaxAlert,
        amountAlert: false,
      });

      if (value === "") {
        setAddTaxAlert({
          ...addTaxAlert,
          amountAlert: true,
        });
      }
    }
  };

  //IsReverseVat
  const IsReverseVat = (e) => {
    setAddCreateTax({
      ...addCreateTax,
      isReverseVat: e.target.checked,
    });
  };

  //IsExempted
  const IsExempted = (e) => {
    setAddCreateTax({
      ...addCreateTax,
      isexempted: e.target.checked,
    });
  };

  //includedPrice

  const includedPrice = (e) => {
    setAddCreateTax({
      ...addCreateTax,
      includedPrice: e.target.checked,
    });
  };

  //SaveTaxBtn
  const SaveTaxBtn = (key) => () => {
    const {
      taxName,
      taxScope,
      taxComputation,
      taxAccount,
      taxType,
      amount,
      taxAccountOnRefund,
    } = addCreateTax;

    if (taxName === "") {
      setAddTaxAlert({
        ...addTaxAlert,
        taxNameAlert: true,
      });

    } else if (TaxScopName === null) {
      setAddTaxAlert({
        ...addTaxAlert,
        taxScopeAlert: true,
      });
    } else if (taxComputationName === null) {
      setAddTaxAlert({
        ...addTaxAlert,
        taxComputationAlert: true,
      });
    } else if (TaxAccountName === null) {
      setAddTaxAlert({
        ...addTaxAlert,
        taxAccountAlert: true,
      });
    } else if (TaxTypeName === null) {
      setAddTaxAlert({
        ...addTaxAlert,
        taxTypeAlert: true,
      });
    } else if (amount === "") {
      setAddTaxAlert({
        ...addTaxAlert,
        amountAlert: true,
      });
    } 
    // else if (TaxAccountRefund === null) {
    //   setAddTaxAlert({
    //     ...addTaxAlert,
    //     taxAccountOnRefundAlert: true,
    //   });

    // }
     else {
      if (key === "create") {
        createTaxSettingsApicall(
          addCreateTax,
          setSuccessSnackOpen,
          setErrorSnackOpen,
          setSnackMsg,
          setCreateTax
        );
      } else if ("edit") {
        let editPayload = { ...addCreateTax };
        editPayload._id = SingleViewTax?._id;

        updateTaxSettingsApiCall(
          editPayload,
          setSuccessSnackOpen,
          setSnackMsg,setCreateTax
        );
      }
    }
  };

  //Tax Single View

  useEffect(() => {
    if (SingleViewTax !== undefined) {
      setAddCreateTax({
        ...addCreateTax,
        taxName: SingleViewTax?.taxName,
        taxScope: SingleViewTax?.taxScope,
        isReverseVat: SingleViewTax?.isReverseVat,
        isexempted: SingleViewTax?.isexempted,
        taxComputation:SingleViewTax?.taxComputation,
        taxAccount: SingleViewTax?.taxAccount,
        taxType:SingleViewTax?.taxType,
        amount: SingleViewTax?.amount,
        taxAccountOnRefund: SingleViewTax?.taxAccountOnRefund,
        includedPrice: SingleViewTax?.includedPrice,
      });
      setTaxScopName(
        filterObjFromList("Scope", TaxScop, "taxScope", SingleViewTax)
      );
      settaxComputationName(
        filterObjFromList(
          "computation",
          Tax_computation,
          "taxComputation",
          SingleViewTax
        )
      );
      setTaxAccountName(
        filterObjFromList(
          "_id",
          chartOfAccountList,
          "taxAccount",
          SingleViewTax
        )
      );
      setTaxTypeName(
        filterObjFromList("Type", Tax_type, "taxType", SingleViewTax)
      );
      setTaxAccountRefund(
        filterObjFromList(
          "_id",
          chartOfAccountList,
          "taxAccountOnRefund",
          SingleViewTax
        )
      );
    }
  }, [SingleViewTax]);

  //
  useEffect(() => {
    if (CreateTax === false) {
      setAddCreateTax({
        ...addCreateTax,
        taxName: "",
        taxScope: "",
        isReverseVat: false,
        isexempted: false,
        taxComputation: "",
        taxAccount: "",
        taxType: "",
        amount: "",
        taxAccountOnRefund: "",
        includedPrice: false,
      });
      setTaxScopName(null);
      settaxComputationName(null);
      setTaxAccountName(null);
      setTaxTypeName(null);
      setTaxAccountRefund(null);
    }
  }, [CreateTax]);

  const TaxEdit = () => {
    setDisabledInput(false);
    setConfEditShow(true);
  };

  const TaxUpdate =(id,status)=>{
    UpdateTaxStatusAPiCall(id,status,setTaxStatus,TaxStatus)
  }

  useEffect(()=>{
    chartOfAccountListApiCall()
  },[])
  useEffect(() => {
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&  TaxSettingsFilterAPICall({
        status: FormDataInfo.status.length !== 0 ? (FormDataInfo.status[0] === "ACTIVE" ? true : false) : null,
        search: FormDataInfo.search,
        index: currentPage - 1,
      }, setIsLoading);
    }
  }, [FormDataInfo, currentPage,CreateTax,TaxStatus]);

  return (
    <>
      {CreateTax === true ? (
        <>
          <div
            className="global-white-bg-container"
            style={{ margin: "1% 1%", borderRadius: "4px" }}
          >
            <Tooltip title="Back">
              <IconButton onClick={() => setCreateTax(false)}>
                <i class="bi bi-chevron-left"></i>
              </IconButton>
            </Tooltip>

            {showSaveBtn ? (
              <Tooltip title="Save">
                <IconButton onClick={SaveTaxBtn("create")}>
                  <i class="bi bi-floppy"></i>
                </IconButton>
              </Tooltip>
            ) : ConfEditShow === false ? (
              <Tooltip title="Edit">
                <IconButton onClick={TaxEdit}>
                  {isDisabled ? (
                    <i
                      class="bi bi-check2-square edit-icon"
                      style={{ fontSize: "1.5rem" }}
                    ></i>
                  ) : (
                    <i class="bi bi-pencil-square edit-icon1"></i>
                  )}
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Update Tax">
                <IconButton onClick={SaveTaxBtn("edit")}>
                  <i
                    class="bi bi-check2-square edit-icon"
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                </IconButton>
              </Tooltip>
            )}
          </div>
          <div
            className="utility-settings-menu-container"
            style={{
              padding: "0px 4px",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "98%",
            }}
          >
            <div className="global-single-input currency-updated-single-input">
              <p>Tax Name</p>
              <input
                type="text"
                placeholder="Tax Name"
                onChange={CreateTaxFun("TaxName")}
                value={addCreateTax?.taxName}
                disabled={disabledInput}
              />
              {addTaxAlert?.taxNameAlert && (
                <p className="doc-validation-alert">Fill TaxName!!</p>
              )}
            </div>
            <div className="global-single-input auto-complete currency-updated-single-input">
              <p>Tax Scop</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={TaxScop || ["No Data"]}
                getOptionLabel={(option) => option.Scope}
                value={TaxScopName}
                disabled={disabledInput}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Purchases" />
                )}
                onChange={(e, newValue) => {
                  setTaxScopName(newValue);
                  setAddCreateTax({
                    ...addCreateTax,
                    taxScope: newValue?.Scope,
                  });

                  setAddTaxAlert({
                    ...addTaxAlert,
                    taxScopeAlert: false,
                  });

                  if (newValue === null) {
                    setAddTaxAlert({
                      ...addTaxAlert,
                      taxScopeAlert: true,
                    });
                  }
                }}
              />
              {addTaxAlert?.taxScopeAlert && (
                <p className="doc-validation-alert">Select Tax Scop!!</p>
              )}
            </div>
            {/* <div className="global-single-input1" style={{ width: "30%" }}>
              <p>Is Reverse VAT</p>
              <input
                className="TaxCreateCheckBox"
                type="checkbox"
                disabled={disabledInput}
                onChange={(e) => IsReverseVat(e)}
                checked={addCreateTax.isReverseVat}
              />
       
            </div> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "0px 4px",
                margin: "1rem",
                width: "100%",
              }}
            >
              <div className="tab-menu-button active" style={{ width: "10%" }}>
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Details
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <div className="global-single-input auto-complete currency-updated-single-input">
                  <p>Tax Computaion</p>
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={Tax_computation || ["No Data"]}
                    getOptionLabel={(option) => option.computation}
                    value={taxComputationName}
                    disabled={disabledInput}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Tax Computation"
                      />
                    )}
                    onChange={(e, newValue) => {
                      settaxComputationName(newValue);
                      setAddCreateTax({
                        ...addCreateTax,
                        taxComputation: newValue?.computation,
                      });

                      setAddTaxAlert({
                        ...addTaxAlert,
                        taxComputationAlert: false,
                      });
                      if (newValue === null) {
                        setAddTaxAlert({
                          ...addTaxAlert,
                          taxComputationAlert: true,
                        });
                      }
                    }}
                  />

                  {addTaxAlert?.taxComputationAlert && (
                    <p className="doc-validation-alert">
                      Select Tax Computaion!!
                    </p>
                  )}
                </div>
                <div className="global-single-input auto-complete currency-updated-single-input">
                  <p>Tax Account</p>
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={chartOfAccountList || []}
                    getOptionLabel={(option) => option?.accountName}
                    value={TaxAccountName}
                    disabled={disabledInput}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Tax Account" />
                    )}
                    onChange={(e, newValue) => {
                      setTaxAccountName(newValue);
                      setAddCreateTax({
                        ...addCreateTax,
                        // taxAccount: newValue?.type,
                        taxAccount: newValue?._id,
                      });

                      setAddTaxAlert({
                        ...addTaxAlert,
                        taxAccountAlert: false,
                      });

                      if (newValue === null) {
                        setAddTaxAlert({
                          ...addTaxAlert,
                          taxAccountAlert: true,
                        });
                      }
                    }}
                  />
                  {addTaxAlert?.taxAccountAlert && (
                    <p className="doc-validation-alert">Select Tax Account!!</p>
                  )}
                </div>
                <div className="global-single-input auto-complete currency-updated-single-input">
                  <p>Tax Type</p>
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={Tax_type || ["No Data"]}
                    value={TaxTypeName}
                    disabled={disabledInput}
                    getOptionLabel={(option) => option.Type}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Tax Type" />
                    )}
                    onChange={(e, newValue) => {
                      setTaxTypeName(newValue);
                      setAddCreateTax({
                        ...addCreateTax,
                        taxType: newValue?.Type,
                      });

                      setAddTaxAlert({
                        ...addTaxAlert,
                        taxTypeAlert: false,
                      });
                      if (newValue === null) {
                        setAddTaxAlert({
                          ...addTaxAlert,
                          taxTypeAlert: true,
                        });
                      }
                    }}
                  />

                  {addTaxAlert?.taxTypeAlert && (
                    <p className="doc-validation-alert">Select Tax Type!!</p>
                  )}
                </div>
                <div className="global-single-input currency-updated-single-input">
                  <p>Amount</p>
                  <input
                    type="number"
                    placeholder="Enter Amount"
                    onChange={CreateTaxFun("Amount")}
                    disabled={disabledInput}
                    value={addCreateTax?.amount}
                  />

                  {addTaxAlert?.amountAlert && (
                    <p className="doc-validation-alert">Fill Amount!!</p>
                  )}
                </div>

                {/* <div className="global-single-input auto-complete currency-updated-single-input">
                  <p>Tax Account On Refunds</p>
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={chartOfAccountList || ["No Data"]}
                    value={TaxAccountRefund}
                    disabled={disabledInput}
                    getOptionLabel={(option) => option?.accountName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="
                      Tax Account On Refunds"
                      />
                    )}
                    onChange={(e, newValue) => {
                      setTaxAccountRefund(newValue);
                      setAddCreateTax({
                        ...addCreateTax,
                        taxAccountOnRefund: newValue?._id,
                      });

                      setAddTaxAlert({
                        ...addTaxAlert,
                        taxAccountOnRefundAlert: false,
                      });

                      if (newValue === null) {
                        setAddTaxAlert({
                          ...addTaxAlert,
                          taxAccountOnRefundAlert: true,
                        });
                      }
                    }}
                  />

                  {addTaxAlert?.taxAccountOnRefundAlert && (
                    <p className="doc-validation-alert">
                      Select Tax account On Refund!!
                    </p>
                  )}
                </div> */}
                <div className="global-single-input1" style={{ width: "30%" }}>
                  <p>Included Price</p>
                  <input
                    className="TaxCreateCheckBox"
                    type="checkbox"
                    disabled={disabledInput}
                    onChange={(e) => includedPrice(e)}
                    checked={addCreateTax?.includedPrice}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="utility-settings-container">
          <div
            className="utility-settings-menu-container"
            style={{ padding: "0px 4px" }}
          >
            <div className="justify-space-between"
          style={{ gap: "0 12px", justifyContent: "flex-end" }}
        >
          <CategorySearchandFilter
            statusOptions={['ACTIVE', 'INACTIVE']}
            onData={handleDataFromChild}
            isStatus={true}
            isDate={false}
            isBranch={false}
            onBranchSelect={handleBranchSelect}
          />
         <button onClick={CreateTaxbtn} className="create-button-blue">
                Create Tax
              </button>
        </div>
        <div className="justify-center" style={{ justifyContent: "flex-end" }}>
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
            
            <div className="global-table-container">
              <table className="global-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Tax Name</th>
                    <th>Tax Scope</th>
                    <th>Active/Deactive</th>
                  </tr>
                </thead>
                <tbody>
                {
                  isLoading ? (
                    [...Array(10)].map((r, i) => (
                      <tr key={i}>
                        <td colSpan={8}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                          />
                        </td>
                      </tr>
                    ))
                  ) :
                  TaxList?.list !== undefined?
                  TaxList?.list?.slice(0)
                   
                    ?.map((r, i) => (
                      <tr
                        // onClick={() => singleViewTax(r)}
                        style={{ cursor: "pointer" }}
                        title="View Single Tax"
                      >
                        <td onClick={() => singleViewTax(r)}>{i + 1}</td>
                        <td onClick={() => singleViewTax(r)}>{r?.taxName}</td>
                        <td onClick={() => singleViewTax(r)}>{r?.taxScope}</td>
                        <td>
                          <input
                            onClick={()=>TaxUpdate(r._id,r?.status)}
                            className="toggle-checkbox"
                            type="checkbox"
                            checked={r?.status}
                          />
                        </td>
                        {/* <td>
                        <input
                          className="toggle-checkbox"
                          type="checkbox"
                          checked={r?.status}
                        />
                      </td> */}
                      </tr>
                    ))
                    :
                    <tr>
                      <td colSpan={4}>No Data</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnack}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnack}
        message={snackMsg}
      />
    </>
  );
};
