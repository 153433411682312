import React, { useState, useEffect, useRef } from "react";
import "../../../css/Sales/payments.css";
import {
  Select,
  IconButton,
  MenuItem,
  Dialog,
  Autocomplete,
  TextField,
  Skeleton,
  Tooltip,
  createFilterOptions,
} from "@mui/material";
import { Datepicker } from "../../Single Components/Datepicker";
import {
  viewPaymentsAPICall,
  allInvoiceNoAPICall,
  customerSuppAPICall,
} from "../../../API/Sales/paymentsAPI";
import { generateInvoiceAPICall } from "../../../API/Orders/workOrderAPI";
import { useSelector } from "react-redux";
import { viewAllCustomersAPICall } from "../../../API/Customer/customerAPI";
import ReactToPrint from "react-to-print";

import { convertDateFormat, previousDate, today } from "../../../Js/Date";
import printImage from "../../../Assets/SVG/invoice2.svg";
import { Invoice } from "./Billing/Invoice";
import { PrintOutlined } from "@mui/icons-material";
import { AlterationInvoice } from "../Orders/Alteration/AlterationInvoice";
import { alterationInvoiceAPICall } from "../../../API/Orders/AlterationAPI";
import { generateBillingInvoiceAPICall } from "../../../API/Sales/billing";
import { viewAllBranchesAPICall } from "../../../API/Settings/Company Settings/companySettingsAPI";

export const Payments = () => {
  let paymentsPrint = useRef();
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  // view payments data
  const paymentsData = useSelector((state) => state.viewPayments.value);
  //List of All Customers
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  // all invoice no list
  const allInvoiceList = useSelector((state) => state.invoiceNoList.value);
  // customer supplier list
  const customerSuppList = useSelector((state) => state.customerSuppList.value);
  //Invoice Data
  const invoiceData = useSelector((state) => state.invoiceSlice.value);
   // all branch list
   const allBranchesList = useSelector((state) => state.allBranchesSlice.value);

  //storeCode
  const storeCode= localStorage.getItem('branchId')

  const userLoginMeta = localStorage.getItem('login_meta')
  const userBranchId=JSON.parse(userLoginMeta)?.branchPk;

  const [paymentType, setPaymentType] = useState("null");
  const [paymentView, setPaymentView] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [fromDate, setFromDate] = useState(previousDate);
  const [toDate, setToDate] = useState(today);
  const [paymentTypeFilterData, setPaymentTypeFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [time, setTime] = useState(null);
  const [branchName,setBranchName]=useState(null)

  //Type Of order Clicked
  const [invoiceType, setInvoiceType] = useState(null);
  //Open Invoice Dialog
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);


  let invoicePrint = useRef();
  let alterationInvoicePrint = useRef();

  // get current time fn
  useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }, 1000);
  }, [time]);

  useEffect(() => {
    viewPaymentsAPICall(setIsLoading);
    viewAllCustomersAPICall({branchId:userRole==="admin"?null:userBranchId})

    customerSuppAPICall({branchId:storeCode});
    viewAllBranchesAPICall();
  }, []);

  useEffect(()=>{
    allInvoiceNoAPICall({branchId:branchName});
  },[branchName])

  // const formData = new FormData();
  // {
  //   invoiceId !== null &&
  //     invoiceId !== undefined &&
  //     formData.append("invoiceId", invoiceId);
  // }
  // {
  //   customer !== null &&
  //     customer !== undefined &&
  //     formData.append("cus_id", customer);
  // }

  // {
  //   fromDate !== "" &&
  //     fromDate !== undefined &&
  //     formData.append("fromDate", `${fromDate} 00:00:00`);
  // }
  // {
  //   toDate !== "" &&
  //     fromDate !== undefined &&
  //     formData.append("endDate", `${toDate} 23:59:59`);
  // }

  // formData.append("paytype", paymentType);
  // {
  //   userRole ==="admin" ?
  //   formData.append("branchId",branchName)
  //   :userRole === "user"?
  //   formData.append("branchId",storeCode)
  //   :
  //   undefined
  // }

  const bodyToApi={
    invoiceId:invoiceId,
    cus_id:customer,
    fromDate:fromDate === "" ? null :`${fromDate} 00:00:00`,
    endDate:toDate === ""?null:`${toDate} 23:59:59`,
    paytype:paymentType,
    branchId:userRole =="admin"?branchName:userRole=="user"?userBranchId:undefined
  }
  

  useEffect(() => {
    viewPaymentsAPICall(setIsLoading, bodyToApi);
  }, [paymentType]);

  const paymentsSubmitButtonFn = () => {
    viewPaymentsAPICall(setIsLoading, bodyToApi);
  };

  // extract customer
  const [customerData, setCustomerData] = useState([]);
  useEffect(() => {
      if(paymentsData !== undefined){
        let extractedData = paymentsData?.filter((obj) => {
          if (obj?.invoiceNo === invoiceId) {
            return obj;
          }
          
        });
        // setCustomerData(extractedData);
        setCustomer(extractedData[0]?.customerName);
      }
  
  },[paymentsData, invoiceId]);

  

  // useEffect(() => {
  //   setCustomer(customerData !== undefined && customerData[0]?.customerName);
  // }, [customerData, invoiceId]);

  //Open Invoice Dialog
  const openInvoiceDialogFn=()=>{
    setOpenInvoiceDialog(true)
    
  }
   //hide invoice print button on scroll
   const hideInvoicePrint = () => {
    document.getElementById("invoicePrintIcon").style.display = "none";
  };
 
  // print payment invoice
  const paymentsInvoiceClick = (order) => {
    if (order !== undefined) {
      const { type, purchasePk } = order;

      if (type == "WO") {
        //work order
        setInvoiceType("workOrder");
        generateInvoiceAPICall({ _id: purchasePk },openInvoiceDialogFn);

      }
      if (type == "ALT") {
        // alteration
        setInvoiceType("alteration");
        alterationInvoiceAPICall({_id:purchasePk},openInvoiceDialogFn)
        openInvoiceDialogFn()
      }
      if (type == "ORD") {
        // Sales billing
        setInvoiceType("billing");
        generateBillingInvoiceAPICall({_id:purchasePk},openInvoiceDialogFn)
      }
      if (type == 3) {
        //Others [GRN]
        setInvoiceType("other");
        //TODO set this if GRN has invoice
      }
    }

    
  };


    //Filter by name and mob no in same input field
    const filterOptions = createFilterOptions({
      matchFrom: "any",
      stringify: (option) => option?.name + option.mobileNo,
    });
    

  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">Sales &gt; Receipts</p>
      <div className="global-white-bg-container sales-payments-container">
        <h3>Receipts</h3>
        <hr className="global-hr" />
        <div className="daily-report-top-input-container">
          {
            userRole === "admin" &&
            <div className="global-single-input auto-complete">
            <p>Branch</p>

            <Autocomplete
              sx={{ width: "100%" }}
              options={allBranchesList || [""]}
              getOptionLabel={(option) =>
                `${option?.storeCode}-${option?.branchName}`
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="select Branch..." />
              )}
              onChange={(e, newValue) => setBranchName(newValue?._id)}
            />
          </div>
          }
      

          <div className="global-single-input auto-complete">
            <p>Invoice ID</p>

            <Autocomplete
              sx={{ width: "100%" }}
              options={allInvoiceList || [""]}
              getOptionLabel={(option) => option?.invoiceNo}
              renderInput={(params) => (
                <TextField {...params} placeholder="select InvoiceId..." />
              )}
              onChange={(e, newValue) => setInvoiceId(newValue?.invoiceNo)}
            />
          </div>
          <div className="global-single-input auto-complete">
            <p>Customer</p>
            {invoiceId !== undefined && invoiceId !== null ? (
              <input type="text" value={customer} />
            ) : (
              <Autocomplete
                sx={{ width: "100%" }}
                options={customerList || [{cusId:"-No data",name:""}]}
                getOptionLabel={(option) => `${option?.cusId}-${option?.name}`}
                renderInput={(params) => (
                  <TextField {...params} placeholder="select Customer..." />
                )}
                onChange={(e, newValue) => setCustomer(newValue?._id)}
                filterOptions={filterOptions}
              />
            )}
          </div>
          <div className="global-single-input">
            <p>From</p>
            <input
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div className="global-single-input">
            <p>To</p>
            <input
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
          <button
            onClick={paymentsSubmitButtonFn}
            className="btn btn-primary report-submit-btn"
          >
            Submit
          </button>
        </div>
      </div>

      {/* table container */}
      <div className="global-white-bg-container sales-payments-container">
        <div className="sales-payments-table-top-container">
          <div className="sales-payments-table-top-left-container">
            {/* <Datepicker type="month" boxShadow="0 0 8px 2px #eee" height="40px"/> */}
            <div
              className="global-single-input"
              style={{ width: "55%", margin: "0" }}
            >
              <Select
                value={paymentType}
                onChange={(e) => setPaymentType(e.target.value)}
              >
                <MenuItem value="null">All</MenuItem>
                <MenuItem value="Cash">Cash</MenuItem>
                <MenuItem value="Card">Card</MenuItem>
                <MenuItem value="Upi">UPI</MenuItem>
              </Select>
            </div>
          </div>
          <div className="stock-report-print-download-icon-container sales-payments-icons">
            <ReactToPrint
              trigger={() => (
                <IconButton className="print-icon-container">
                  <i class="bi bi-printer printer-icon"></i>
                </IconButton>
              )}
              content={() => paymentsPrint}
            />

            <IconButton className="download-icon-container">
              <i class="bi bi-arrow-down-circle download-icon"></i>
            </IconButton>
          </div>
        </div>

        {/* table */}
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>No</th>
                {userRole === "admin" && (
                  <>
                    <th>Branch Code</th>
                    <th>Branch Name</th>
                  </>
                )}
               
                <th>Invoice ID</th>
                <th>Customer</th>
                <th>Receipt Type</th>
                <th>Paid Date</th>
                <th>Paid Amount</th>
                <th>Print</th>
                <th>Send</th>
              
              </tr>
            </thead>
             {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={30}
                    />
                  </td>
                </tr>

                <tr>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={30}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={30}
                    />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                

            
                 {paymentsData !== undefined ? (
                  paymentsData
                    ?.slice(0)
                    ?.reverse()
                    ?.map((r, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        {userRole === "admin" && (
                          <>
                            <td>{r?.branchId}</td>
                            <td>{r?.locationName}</td>
                          </>
                        )}
                    
                        <td>{r?.invoiceNo}</td>
                        <td>{r?.customerName}</td>
                        <td>
                          <div>
                          {r?.paymentMethod?.map((k, i) => (
                            <span>
                              {i === r?.paymentMethod.length - 1
                                ? k?.type
                                : k?.type + ","}
                            </span>
                          ))}
                          </div>
                         
                        </td>
                        <td>{convertDateFormat(r?.date)}</td>
                        <td>
                          <span className="sales-order-list-payment-status">
                            {r?.totalAmount}
                          </span>
                        </td>
                        <td>
                          <Tooltip title="Print Invoice">
                            <IconButton
                              onClick={() => paymentsInvoiceClick(r)}
                              sx={{ width: "37px", height: "40px" }}
                            >
                              <img
                                style={{ width: "100%" }}
                                src={printImage}
                                alt=""
                              />
                            </IconButton>
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip title="SMS">
                                <IconButton>
                                  <i class="bi bi-chat-left-dots sms-icon-receipts"></i>
                                </IconButton>
                          </Tooltip>
                          <Tooltip title="Email">
                                <IconButton>
                                  <i class="bi bi-envelope email-icon-receipts"></i>
                                </IconButton>
                          </Tooltip>
                          <Tooltip title="whatsapp">
                                <IconButton>
                                  <i class="bi bi-whatsapp whatsapp-icon-receipts"></i>
                                </IconButton>
                          </Tooltip>

                        </td>
                     
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan={11}>No Data</td>
                  </tr>
                )}   
              </tbody>
            )}  
          </table>
        </div>
      </div>

      {/* payments view */}
      <Dialog open={paymentView} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setPaymentView(false)}>
        <div className="sales-payments-dialog-container">
          <h3>View</h3>
          <div className="sales-payments-dialog-input-container">
            <div className="global-single-input sales-payments-input">
              <p>Branch Name</p>
              <input type="text" />
            </div>
            <div className="global-single-input sales-payments-input">
              <p>Branch Code</p>
              <input type="text" />
            </div>
            <div className="global-single-input sales-payments-input">
              <p>Invoice ID</p>
              <input type="text" />
            </div>
            <div className="global-single-input sales-payments-input">
              <p>Customer</p>
              <input type="text" />
            </div>
            <div className="global-single-input sales-payments-input">
              <p>Payment Type</p>
              <input type="text" />
            </div>
            <div className="global-single-input sales-payments-input">
              <p>Paid Date</p>
              <input type="text" />
            </div>
            <div className="global-single-input sales-payments-input">
              <p>Paid Amount</p>
              <input type="text" />
            </div>
          </div>
          <button
            onClick={() => setPaymentView(false)}
            className="btn btn-secondary sales-payments-view-button"
          >
            Close
          </button>
        </div>
      </Dialog>

      {/* payments print view */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <p></p>
              <p></p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}

              <p>RECEIPTS</p>
            </div>
            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>{convertDateFormat(today)}</p>
              <p>{time}</p>
            </div>
          </div>
          <div className="print-view-table-container report-print-view-table-with-clr">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  {userRole === "admin" && (
                    <>
                      <th>Branch Code</th>
                      <th>Branch Name</th>
                    </>
                  )}
                  {/* <th>Date</th> */}
                  <th>Invoice ID</th>
                  <th>Customer</th>
                  <th>Payment Type</th>
                  <th>Paid Date</th>
                  <th>Paid Amount</th>
                </tr>
              </thead>
              <tbody>
                {paymentsData?.slice(0)?.reverse()?.map((r, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    {userRole === "admin" && (
                      <>
                        <td>{r?.branchId}</td>
                        <td>{r?.locationName}</td>
                      </>
                    )}
                    {/* <td>{r?.paidDate}</td> */}
                    <td>{r?.invoiceNo}</td>
                    <td>{r?.customerName}</td>
                    <td>
                      {r?.paymentMethod?.map((k, i) => (
                        <span>
                          {i === r?.paymentMethod.length - 1
                            ? k?.type
                            : k?.type + ","}
                        </span>
                      ))}
                    </td>
                    <td>{convertDateFormat(r?.date)}</td>
                    <td>
                      <span
                        style={{ color: "#000" }}
                        className="sales-order-list-payment-status"
                      >
                        {r?.totalAmount}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Dialog
        open={openInvoiceDialog}
        onClose={() => setOpenInvoiceDialog(false)}
        onKeyDown={(e)=>e.key === "Escape" && setOpenInvoiceDialog(false)}
      >
        {invoiceType === "alteration" ? (
          <div className="billing-parent-wrapper" onScroll={hideInvoicePrint}>
            <div ref={(el) => (alterationInvoicePrint = el)}>
              <AlterationInvoice
                data={invoiceData}
                isCopy={true}
                // invoiceData={generatedInvoice}
              />
            </div>
            <div className="invoice-print-icon-container" id="invoicePrintIcon">
              {/* <Tooltip title="Print" placement="right" arrow> */}
              <ReactToPrint
                trigger={() => (
                  <IconButton
                    className="invoice-print-btn"
                    sx={{
                      backgroundColor: "#d3e3ec",
                    }}
                    // onClick={invoiceDialogClose}
                  >
                    <PrintOutlined
                      className="print-icon"
                      sx={{ color: "#000" }}
                    />
                  </IconButton>
                )}
                content={() => alterationInvoicePrint}
              />
              {/* </Tooltip> */}
            </div>
          </div>
        ) : (
          <div className="billing-parent-wrapper" onScroll={hideInvoicePrint}>
            <div ref={(el) => (invoicePrint = el)}>
              <Invoice
                data={invoiceData}
                isCopy={true}
                // invoiceData={generatedInvoice}
              />
            </div>
            <div className="invoice-print-icon-container" id="invoicePrintIcon">
              {/* <Tooltip title="Print" placement="right" arrow> */}
              <ReactToPrint
                trigger={() => (
                  <IconButton
                    className="invoice-print-btn"
                    sx={{
                      backgroundColor: "#d3e3ec",
                    }}
                    // onClick={invoiceDialogClose}
                  >
                    <PrintOutlined
                      className="print-icon"
                      sx={{ color: "#000" }}
                    />
                  </IconButton>
                )}
                content={() => invoicePrint}
              />
              {/* </Tooltip> */}
            </div>
          </div>
        )}
      </Dialog>

    </div>
  );
};
