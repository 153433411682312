import { createSlice } from "@reduxjs/toolkit";

export const allReadymadeBrandSlice=createSlice({
    name:'readymadeBrandSlice',
    initialState:{
        value:undefined,
        brandlist:undefined,
        modellist:undefined,
        manufYear:undefined,
        typeList:undefined,
        manufacturelist:undefined,
    },
    reducers:{
        update_readymade_brand_list:(state,action)=>{
            state.value=action.payload.readymadeBrand
        },
        view_raedymade_brandlist:(state,action)=>{
            state.brandlist=action.payload.brandlistData
        },
        view_raedymade_brand_model_list:(state,action)=>{
            state.modellist=action.payload.modellistData
        },
        get_readymade_manuf_year_list:(state,action)=>{
            state.manufYear=action.payload.manufYearData
        },
        view_part_type_list:(state,action)=>{
            state.typeList=action.payload.typeListData
        },
        view_manufacture_list:(state,action)=>{
            state.manufacturelist=action.payload.manufacturelistData
        },

    }
})

export const {update_readymade_brand_list,view_manufacture_list,view_part_type_list,view_raedymade_brandlist,view_raedymade_brand_model_list,get_readymade_manuf_year_list}=allReadymadeBrandSlice.actions
export default allReadymadeBrandSlice.reducer