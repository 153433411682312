import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import store from '../../../../../Redux/store';
import { get_vendor_list_row_data_list } from '../Slice/vendorsListSlice';
import { Skeleton } from '@mui/material';
import { VendorsFilterAPICall, viewVendorListAPICall } from '../API/vendorListAPICall';
import { useState } from 'react';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import CustomPagination from '../../../../Single Components/CustomPagination';
import axios from 'axios';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';

export const Vendors = () => {


  let navigateTo = useNavigate()
  const tableListData = useSelector((state) => state.vendorsListSlice.apiResList);
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const [isLoading, setIsLoading] = useState(false)
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const totalPages = tableListData?.pages;

  // const handleClickRow = (row) => {
  //   store.dispatch(get_vendor_list_row_data_list({ vendorResRowData: row }))
  //   navigateTo("/userdashboard/accounts/vendor/Createvendors")
  // }

  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("SingleViewId", row?._id);
    navigateTo("/userdashboard/accounts/vendor/Createvendors")
    
};
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  useEffect(() => {
    store.dispatch(get_vendor_list_row_data_list({ vendorResRowData: undefined }))
    localStorage.removeItem("SingleViewId");
    // viewVendorListAPICall(setIsLoading)
  }, [])

  useEffect(() => {
  
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData && VendorsFilterAPICall({
        branchList: FormDataInfo.branchList,
        status: FormDataInfo.status.length !== 0 ? (FormDataInfo.status[0] === "ACTIVE" ? true : false) : null,
        search: FormDataInfo.search,
        index: currentPage - 1,
      }, setIsLoading);
    }
  
  }, [FormDataInfo, currentPage
  ])
  return (
    <div className='global-page-parent-container'>
      {/* <p className="breadcrumb">Accounts &gt; Vendors &gt; Vendors List</p> */}

      <div className="global-white-bg-container">
      <div className="justify-space-between"
          style={{ gap: "0 12px",  display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
        >
           {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                <h3>Branch name: {selectedBranch?.storeCode}-{selectedBranch?.branchName}</h3>
              </div>
            )} 
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
          <CategorySearchandFilter
            statusOptions={['ACTIVE', 'INACTIVE']}
            onData={handleDataFromChild}
            isStatus={true}
            isDate={false}
            onBranchSelect={handleBranchSelect}
          />
          <button className='create-button-blue' 
           onClick={()=>navigateTo("/userdashboard/accounts/vendor/Createvendors")}>
            Create</button>
            </div>
        </div>
        <div className="justify-center" style={{ justifyContent: "flex-end" }}>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
        </div>
        <div className="create-button-blue-container">
          {/* <button className='create-button-blue' 
           onClick={()=>navigateTo("/userdashboard/accounts/vendor/Createvendors")}>
            Create</button> */}
        </div>

        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Country</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={5}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : tableListData?.list !== undefined || tableListData?.list?.length === 0 ? (
                tableListData?.list?.slice(0)?.reverse().map((r, i) => (
                  <tr key={i} onClick={() => handleClickRow(r)} style={{ cursor: "pointer" }}>
                    <td>{i + 1}</td>
                    <td>{r?.name}</td>
                    <td>{r?.mobileNo}</td>
                    <td>{r?.email || "-NIL-"}</td>
                    <td>{r?.country === "undefined" ? "-NIL-" : r?.country}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
