import React from "react";
import "../../../../css/Orders/CuttingSlip/jalabiaCuttingSlip.css";
import qrCode from "../../../../Assets/Images/qrCodeImage.png";
import { convertDateFormat } from "../../../../Js/Date";
import Barcode from "react-barcode";
export const JalabiaCuttingSlip = (props) => {
  const { qr, cuttingSlipData, printingObject, copy } = props;
 
  return (
    <div className="thob-cutting-slip-container">
      <div className="thob-cutting-slip-top-container">
        <div className="thob-cutting-slip-top-first-content">
          <p>{cuttingSlipData?.cusMobile || "-Contact NIL-"}</p>
          <p className="main-heading-thob-cutting-slip">JALABIA CUTTING SLIP</p>
          <h3></h3>
          {copy && <p>Copy</p>}
        </div>
        <div className="thob-cutting-slip-top-second-content">
          <h3>
            {printingObject?.relationName === "null"
              ? cuttingSlipData?.cusName
              : printingObject?.relationName}
          </h3>
          <p>Order Date&nbsp;:&nbsp;{convertDateFormat(cuttingSlipData?.ordDate )|| ""}</p>
        </div>
        <div className="thob-cutting-slip-top-third-content">
          <p>{cuttingSlipData?.woNo || ""}</p>
          <p>Delivery Date&nbsp;:&nbsp;{ cuttingSlipData?.delDate !== null ? convertDateFormat(cuttingSlipData?.delDate) : ""}</p>
        </div>
        {/* <div className="thob-cutting-slip-top-third-content">
        <p style={{fontSize:"0.9rem"}}>status:</p>
        </div> */}
      </div>
      <hr className="global-hr" />
      <div className="jalabia-cutting-slip-middle-container">
        <div className="jalabia-cutting-slip-middle-input-container">
          <p className="jalabia-cutting-slip-input-value">
            {printingObject?.length || ""}
          </p>
        </div>
        
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p className="jalabia-cutting-slip-input-key">(Shoulder)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.shoulder || ""}
            </p>
          </div>
       
       
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p className="jalabia-cutting-slip-input-key">(Sleeve)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.sleeve || ""}
            </p>
          </div>
      
       
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p className="jalabia-cutting-slip-input-key">(Chest)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.chest || ""}
            </p>
          </div>
       
      
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p className="jalabia-cutting-slip-input-key">(Stomach)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.stomach || ""}
            </p>
          </div> 

          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p className="jalabia-cutting-slip-input-key">(Chest Loose)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.slChest || ""}
            </p>
        </div>
        <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
          <p className="jalabia-cutting-slip-input-key">(Sto.Loose)</p>
          <p className="jalabia-cutting-slip-input-value">
            {printingObject?.slSto || ""}
          </p>
        </div>
       
          <div className="jalabia-cutting-slip-middle-input-container text-container-jalabial-cutting-slip">
            <p className="jalabia-cutting-slip-input-value-text">MarSize</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.mar || ""}
            </p>
          </div>
       
       
       
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p className="jalabia-cutting-slip-input-key">(Neck)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.neck || ""}
            </p>
          </div>
       
        
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p className="jalabia-cutting-slip-input-key">(Bottom)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.bottom || ""}
            </p>
          </div>
       
      
        
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip notes-level-input">
            <p className="jalabia-cutting-slip-input-key">(Sleeve Size)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.sleeveSize || ""}
            </p>
          </div> 

          <div className="jalabia-cutting-slip-middle-input-container notes-jalabia-cutting-slip">
            <p className="jalabia-cutting-slip-input-key">Notes</p>
            <input type="text" name="" id="" />
          </div>
       
          <div className="jalabia-cutting-slip-middle-input-container text-container-jalabial-cutting-slip">
            <p className="jalabia-cutting-slip-input-value-text"></p>
            <p className="jalabia-cutting-slip-input-value">
              {""}
            </p>
          </div>
       
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip notes-level-input">
            <p className="jalabia-cutting-slip-input-key">(Regal Size)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.regalSize || ''}
            </p>
          </div>
        
       
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip notes-level-input">
            <p className="jalabia-cutting-slip-input-key">(Neck D Button)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.neckDownButton || ""}
            </p>
          </div>
      
      </div>
      <div className="thob-cutting-slip-middle-table-container">
        <table>
          <thead>
            <tr>
              <th>Selected Clothes</th>
              <th>Qty</th>
              <th>Model</th>
              <th>Neck Model</th>
              <th>Mar Model</th>
              <th>FP-Model</th>
              <th>FP-Size</th>
              <th>Stitching</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {printingObject?.materials?.map((r, i) => (
              <tr key={i}>
                <td>{r?.mName}</td>
                <td>{r?.qty}</td>
                <td>{r?.jalabiaModelName}</td>
                <td>{r?.neckName}</td>
                <td>{r?.ModelName || "--"}</td>
                <td>{r?.fpName}</td>
                <td>{`${r?.fpSize1}, ${r?.fpSize2}`}</td>
                <td>{r?.stitchingName}</td>
                <td>{r?.remark}</td>

                {/* <td>{printingObject?.jalabiaModelName}</td>
                <td>{printingObject?.neckName}</td>
                <td>{printingObject?.marModelName !== null ? printingObject?.marModelName : 'NILL'}</td>
                <td>{printingObject?.fpName}</td>
                <td>{`${printingObject?.fpSize1} ${printingObject?.fpSize2}`}</td>
                <td>{printingObject?.stitchingName}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* tailor and commission are product based.. it might be type based */}
      <div className="jalabia-cutting-slip-tailor-qrcode-container">
        <div className="jalabia-cutting-slip-tailor-cuttingMaster-container">
          <div className="jalabia-cutting-slip-tailor-container">
            <p
              style={{
                width: "20%",
                display: "flex",
                alignItems: "center",
                fontSize: "0.9rem",
                fontWeight: 600,
                marginLeft:"1px"
              }}
            >
              Tailor
            </p>
            {printingObject?.tailors?.map((tailor, i) => (
              <div className="jalabia-cutting-slip-tailor-input" key={i}>
                <p>{tailor}</p>
              </div>
            )) || (
              <>
                {" "}
                <div className="jalabia-cutting-slip-tailor-input">
                  <p></p>
                </div>
                <div className="jalabia-cutting-slip-tailor-input">
                  <p></p>
                </div>
                <div className="jalabia-cutting-slip-tailor-input">
                  <p></p>
                </div>
                <div className="jalabia-cutting-slip-tailor-input">
                  <p></p>
                </div>{" "}
              </>
            )}
          </div>
          <div className="jalabia-cutting-slip-cutting-master-container">
            <p
              style={{
                width: "17%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "0.9rem",
                fontWeight: 600,
              }}
            >
              Cutting Master
            </p>
            <div className="jalabia-cutting-slip-cutting-master-input-container">
              {printingObject?.cuttingMasters?.map((cMaster,i)=>(<p>{cMaster}</p>)) || 
                <>
                  <p></p>
                  <p></p>
                  <p></p>
                  <p></p>
                </>
              }
             
            </div>
          </div>
        </div>
        {
           cuttingSlipData?.isCode === true ?
           <div className="jalabia-cutting-slip-qr-code-container">
           <img src={qr} alt="qrCode" />
         </div>
         :
         <div className="jalabia-cutting-slip-qr-code-container" style={{border:"1px solid #fff"}}>
          <Barcode
                value={cuttingSlipData?.barcodeNumber}
                width={1}
                fontSize={16}
                height={60}
                margin={"2px"}
             />
         </div>
        }
       
      </div>
      <div style={{display:"flex",justifyContent:"space-between"}}>
          <p style={{margin:"12px 0",fontSize:"0.8rem",fontWeight:600}}>Booking</p>
          <p style={{margin:"12px 0",fontSize:"0.8rem",fontWeight:600}}>Finished</p>
          <p style={{margin:"12px 0",fontSize:"0.8rem",fontWeight:600}}>Delivered</p>
        </div>
    </div>
  );
};
