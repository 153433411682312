import React, { useEffect, useState } from 'react'
import { categoryListAPICall } from '../../Inventory/Configuration/Category/CategoryAPI';
import { useSelector } from 'react-redux';
import axios from 'axios';
import store from '../../../../Redux/store';
import { get_pos_reports_list } from './posSalesReportAPI';
import { HEADERS } from '../../../../API/UrlAndPaths';
import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter"
import { convertDateFormat } from '../../../../Js/Date';

export const posReportsAPICall=async(body)=>{
  await axios.post("sales/posSaleReport",body,HEADERS)
      .then((res)=>{
          if(res.status===200){
              store.dispatch(get_pos_reports_list({posReportsData:res.data}))
          }}
      )
      .catch((err)=>
          store.dispatch(get_pos_reports_list({posReportsData:undefined}))
      )
}


export const PosSalesReport = () => {

  const CategoryList = useSelector((state) => state.categorySlice.value);
  const posSalesReportsList=useSelector((state)=>state.posReports.reportsList)
  
  const [category,setCategory]=useState(false)
  const [FormDataInfo, setFormDataInfo] = useState([])

  

  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  
  useEffect(()=>{
    categoryListAPICall();
    posReportsAPICall()
  },[])

  useEffect(()=>{
    if(FormDataInfo.length!==0){
      posReportsAPICall({branchId:FormDataInfo?.branchList})
    }
  },[FormDataInfo])
 
  return (
    <div className="global-page-parent-container" style={{margin:"0"}}>

    <div className="global-white-bg-container">
     <div className='justify-space-between' style={{paddingBottom:"22px"}}>
     <div className="sharp-arrow">
       <p>POS Sales Report</p>
        
     </div>
     <CategorySearchandFilter
          onData={handleDataFromChild}
          isDate={true}
        />
    </div>
    <div style={{display:"flex",alignItems:"center",margin:"6px 0"}}>
        <input 
          type="checkbox" 
          style={{accentColor:"#1d1da7",cursor:"pointer"}}
           id="posSalesReportCat"
           onChange={(e)=>setCategory(e.target.checked)}
           disabled={posSalesReportsList === undefined}
           />
        <label htmlFor="posSalesReportCat" style={{color:"#1d1da7",fontSize:"0.8rem",fontWeight:600,cursor:"pointer"}}>Category</label>
    </div>
    <div className='global-report-table-container' style={{maxHeight:"80vh"}}>
    <table>
        <thead>
            <tr>
               <th>Date</th>
               <th>Shift Code</th>
               <th>Invoice No</th>
               <th>Customer</th>
               {
                category === true && CategoryList?.map((r)=>(
                   <th>{r?.categoryName}</th>
                ))
               }
               <th>Stitching</th>
             
               <th>Discount</th>
               <th>Additional Discount</th>
               <th>Paid</th>
               <th>Order Total</th>
               <th>Current Status</th>
            </tr>
        </thead>
        <tbody>
      {
        posSalesReportsList?.report!==undefined ? 
          posSalesReportsList?.report?.map((r, i) => (
            <>

              <tr key={i}>
                <td style={{color:"blue",backgroundColor: "transparent", border: "none",fontWeight:"600",fontSize:"1rem"}}>{r?._id}</td>
              </tr>
              {
                r?.orders.map((k, i) => (
                  <tr style={{ height: "38px" }} key={i}>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{convertDateFormat(k?.date)}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.shiftId}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.orderNo}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.customer}</td>
                    {
                      category &&  CategoryList?.map((category) => (
                        <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>
                          {k.lines.find((line) => line.categoryId === category._id)
                            ? k.lines.find((line) => line.categoryId === category._id).amount
                            : 0}
                        </td>
                      ))
                    }
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.stitchingCharge}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.discount}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}></td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}></td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.orderTotal}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }} className='table-data-box'>
                      {
                        k?.status==="complete"?
                        <span className='post'>Complete</span>
                        :k?.status==="pending"?
                        <span className='draft'>Pending</span>
                        : k?.status
                      }
                     
                      </td>
                  </tr>
               
                ))
              }  
              <tr>
                 <td colSpan="4" style={{textAlign:"right"}}>
                    <p style={{margin:"0 8px",fontSize:"0.9rem",fontWeight:600}}>Total</p>
                 </td>

                 {category &&
            CategoryList?.map((category) => (
              <td key={category._id}>
                {/* Calculate and display the grand total for each category */}
                {r.orders.reduce((orderAcc, k) => {
                  const line = k.lines.find((line) => line.categoryId === category._id);
                  return orderAcc + (line ? line.amount : 0);
                }, 0)}
              </td>
            ))}
               
             
                 <td>{r?.netStitchingCharge}</td>
                 <td>{r?.netDiscountTotal}</td>
                 <td></td>
                 <td></td>
                 <td>{r?.netOrderTotal}</td>
                 <td></td>
              </tr>
             
              </>
            ))

          
          
        
            :
            <tr>
              <td colspan={20}>No Data</td>
            </tr>
       }
        </tbody>
        <tfoot>
      <tr>
        <th colSpan="4">Total</th>
        {category &&
      CategoryList?.map((category) => (
        <th key={category._id}>
          {/* Calculate and display the grand total for each category */}
          {posSalesReportsList?.report?.reduce((acc, r) => {
            return (
              acc +
              r.orders.reduce((orderAcc, k) => {
                const line = k.lines.find((line) => line.categoryId === category._id);
                return orderAcc + (line ? line.amount : 0);
              }, 0)
            );
          }, 0)}
        </th>
      ))}
        <th>{posSalesReportsList?.grandtotal?.grandStitchingCharge}</th>
        <th>{posSalesReportsList?.grandtotal?.grandDiscountTotal}</th>
        <td></td>
        <td></td>
        <th>{posSalesReportsList?.grandtotal?.grandOrderTotal}</th>
        <th></th>
      </tr>
    </tfoot>
     </table>

    </div>
 </div>  
  
</div>
  )
}
