import axios from "axios";
import { HEADERS } from "../../../../../API/UrlAndPaths";
import { get_api_res_list, get_sub_uom_list, get_uom_filter_by_purchaseuom, update_res_list_by_row } from "./unitOfMeasurmentSlice";
import store from "../../../../../Redux/store";
import { readymade } from "../../../../../Js/enum";
import { viewAllReadymadeBrandListAPICall, viewAllReadymadeBrandModelListAPICall, viewManufYearListAPICall, viewManufactureListAPICall, viewPartTypeListAPICall } from "../../../../../API/Product Master/ProductMasterAPI";

// list uom apicall
export const viewUOMListAPICall=async()=>{
    await axios
    .post(`purchase/viewActiveUom`, {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_api_res_list({resData:res.data}))
      }
    })
    .catch((err) => {
        store.dispatch(get_api_res_list({resData:undefined}))
    });
}
// add uom api call

export const addUnitOfMeasurmentAPICall=async(body,setState,state,setLoadingForm,clearFunction)=>{
    setLoadingForm!==undefined && setLoadingForm(true)
    await axios.post("purchase/createNewUom",body,HEADERS)
     .then((res)=>{
        if(res.status===200){
            setState({...state,message:"UOM Added",success:true})
            setLoadingForm!==undefined && setLoadingForm(false)
            clearFunction()
        }
     })
     .catch((err)=>{
        setLoadingForm!==undefined && setLoadingForm(false)

        if(err.response.status===409){
          setState({...state,message:"UOM Category already exist !!",error:true})

        }
        else{
          setState({...state,message:err?.response?.data,error:true})
        }
     })
}
// edit uom api call

export const editUnitOfMeasurmentAPICall=async(body,setState,state,setLoadingForm)=>{
  setLoadingForm!==undefined && setLoadingForm(true)

    await axios.put("purchase/updateUomById",body,HEADERS)
     .then((res)=>{
        if(res.status===200){
            setState({...state,message:"Success",success:true})
            setLoadingForm!==undefined && setLoadingForm(false)
        }
     })
     .catch((err)=>{
        setLoadingForm!==undefined && setLoadingForm(false)

        if(err.response.status===409){
          setState({...state,message:"UOM Category already exist !!",error:true})
        }
        else{
          setState({...state,message:err?.response?.data,error:true})
        }
     })
}

export const viewUOMSubListAPICall=async()=>{
  await axios
  .post(`purchase/listAllUoms`, {}, HEADERS)
  .then((res) => {
    if (res.status === 200) {
      store.dispatch(get_sub_uom_list({subUomData:res.data}))
    }
  })
  .catch((err) => {
      store.dispatch(get_sub_uom_list({subUomData:undefined}))
  });
}

export const filterUomByPurchseUomAPICall=(body)=>{
  axios.post("purchase/filteruombypurchaseuom",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
       store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:res.data}))
    } 
  })
  .catch((err)=>{
    store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:undefined}))
    
  })
}
export const addBrandAPICall=(body)=>{
  axios.post(`/${readymade.mainPathName}/${readymade.addBrand}`,body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      console.log('brand added')
      viewAllReadymadeBrandListAPICall()
      //  store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:res.data}))
    } 
  })
  .catch((err)=>{
    console.log(err)
    // store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:undefined}))
    
  })
}
export const addModelAPICall=(body)=>{
  axios.post('readymade/addBrandModel',body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      console.log('model added')
      viewAllReadymadeBrandModelListAPICall()
      //  store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:res.data}))
    } 
  })
  .catch((err)=>{
    console.log(err)
    // store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:undefined}))
    
  })
}
export const addYearAPICall=(body)=>{
  axios.post('readymade/addManufYear',body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      console.log('year added')
      viewManufYearListAPICall()
      //  store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:res.data}))
    } 
  })
  .catch((err)=>{
    console.log(err)
    // store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:undefined}))
    
  })
}
export const addTypeAPICall=(body)=>{
  axios.post('readymade/addPartType',body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      console.log('type added')
      viewPartTypeListAPICall()
      //  store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:res.data}))
    } 
  })
  .catch((err)=>{
    console.log(err)
    // store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:undefined}))
    
  })
}
export const addManufactureAPICall=(body)=>{
  axios.post('readymade/addManufacture',body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      console.log('manufacture added')
      viewManufactureListAPICall()
      //  store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:res.data}))
    } 
  })
  .catch((err)=>{
    console.log(err)
    // store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:undefined}))
    
  })
}
export const filterUomByPurchseUomEditAPICall=(body,index,filter)=>{
  axios.post("purchase/filteruombypurchaseuom",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      //  store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:res.data}))
       filter[index]=res.data
    } 
  })
  .catch((err)=>{
    store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:undefined}))
    
  })
}

//  view uom by id
export const viewUOMByIdAPICall=(body)=>{
  axios.post("purchase/viewUomById",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(update_res_list_by_row({singleRowData:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(update_res_list_by_row({singleRowData:undefined}))
  })
}