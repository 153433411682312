import { createSlice } from "@reduxjs/toolkit";

export const catalogCategorySlice = createSlice({
  name: "catalogCategorySlice",
  initialState: {
    apiResList: undefined,
    subCat:undefined,
    parts:undefined,
    brand:undefined,
    model:undefined,
    year:undefined
  },
  reducers: {
   get_catalog_category: (state, action) => {
      state.apiResList = action.payload.catalogCategoryData;
    },
    get_catalog_sub_category: (state, action) => {
        state.subCat = action.payload.catalogSubCategoryData;
      },
      get_catalog_parts:(state,action)=>{
        state.parts=action.payload.partsData
      },
      get_catalog_brand:(state,action)=>{
        state.brand=action.payload.brandData
      },
      get_catalog_model:(state,action)=>{
        state.model=action.payload.modelData
      },
      get_catalog_manuf_year:(state,action)=>{
        state.year=action.payload.manufYearData
      }


    
  }
});

export const {
    get_catalog_category,
    get_catalog_sub_category,
    get_catalog_parts,
    get_catalog_brand,
    get_catalog_model,
    get_catalog_manuf_year
} = catalogCategorySlice.actions;

export default catalogCategorySlice.reducer;
