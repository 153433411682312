import React, { useEffect, useRef, useState } from 'react'
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { convertArrayToExcel, filterObjFromList } from '../../../../Js/generalFunctions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import CustomPagination from '../../../Single Components/CustomPagination';
import { convertDateFormat, today } from '../../../../Js/Date';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { DailySalesReportSingleViewAPIcall } from './SalesReportApi';
import ReactToPrint from 'react-to-print';

function DailySalesReportSingleView() {
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  let componentRef = useRef();
    let navigate = useNavigate();
    let location = useLocation()
    const customerName = location.state.customerName;
    
    const currencyListUpdated = useSelector(
      (state) => state.viewUpdatedCurrency.value
    );
    const companyProfileData = useSelector(
      (state) => state?.companyProfileSlice?.userValue)
  
      const SingleListData = useSelector((state) => state?.salesReport?.dailySalesReportSingleView)
 
  const totalJournalKeys = SingleListData !== undefined ? Object.keys(SingleListData?.total?.journal) : [];

    const [FormDataInfo, setFormDataInfo] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [symbol, setSymbol] = useState(null);
    const handleDataFromChild = (data) => {
      setFormDataInfo(data);
    };
    const downloadList = () => {
      convertArrayToExcel(SingleListData?.list, "dailysalesreport");
    };
  
    const singleViewId = localStorage.getItem("SingleViewId")
  
    const totalPages = 10;
    const decimalPosition = localStorage.getItem("decimalPosition");
  
    const handlePageChange = (page) => {
  
      setCurrentPage(page);
    };
  
  
    const handleClick = (row) => {
      localStorage.setItem("SingleViewId", row?._id);
      localStorage.setItem("Ref", Number(row?.ref));
      navigate('/userdashboard/report/CustomerCreditReport/SingleView2', 
      {
         state: { 
          customerName: location.state.customerName,
          invoiceNo: row?.invoiceNo 
          }
     });
    };
    
    
    const handleBackClick = (row) => {
      localStorage.removeItem("SingleViewId", row?._id);
      navigate("/userdashboard/sales/Report/dailySalesReport")
  
    };
    useEffect(() => {
  
        DailySalesReportSingleViewAPIcall({
            date: singleViewId,
  
      })
    }, [singleViewId])
  
    useEffect(() => {
      let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
      setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])

    useEffect(() => {
      viewAllCurrencyUpdated({ status: 0 });
      companyProfileInfoForUser();
    }, [])
  return (
    <div className="global-page-parent-container">

    <div className="global-white-bg-container">
     
      <div className='create-button-blue-container' style={{ margin: "1%", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
        <div>
        <Tooltip title="Share">
              <IconButton>
                <i
                  class="bi bi-share"
                  style={{
                    backgroundColor: "#9797e9",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
            <ReactToPrint
              trigger={() => (
                <Tooltip title="Print">
                  <IconButton
                    className="printer-div"
                    style={{ padding: "1px" }}
                  >
                    <i
                      style={{
                        color: "white",
                        backgroundColor: " #d787e7",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                      class="bi bi-printer"
                    ></i>
                  </IconButton>
                </Tooltip>
              )}
              content={() => componentRef}
            />
            <Tooltip title="Download">
              <IconButton onClick={downloadList}>
                <i
                  class="bi bi-arrow-down-circle"
                  style={{
                    backgroundColor: "rgb(255 118 133 / 69%)",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
          <h3 style={{ display: 'inline-block' }}>
            <span
              style={{ color: 'gray', cursor: 'pointer' }}
              onClick={handleBackClick}
            >
              Daily Sales Report
            </span>
            <ArrowForwardIosIcon style={{ fontSize: '0.9rem' }} />
            {convertDateFormat(singleViewId)}
            
          </h3>
        </div>

        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '50%'
        }}>
          <CategorySearchandFilter
            onData={handleDataFromChild}
            style={{ width: "100%" }}      
            isSearch={false} 
          />
       
        </div>

      </div>

      {/* <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}>
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}

        />
      </div> */}
      <div className="customer-receipt-table-container">
        <table>
          <thead>
            <tr>

              <th>NAME</th>
              <th>CUSTOMER</th>
              <th>EMPLOYEE</th>
              <th>INV AMOUNT</th>
              <th>RETURN AMOUNT</th>
              <th>PAID AMOUNT</th>
              {totalJournalKeys?.map(r =>
                  <th>{r}</th>
                )}
              <th>CREDIT</th>

            </tr>
          </thead>
          <tbody>

         
            {isLoading ? (
              [...Array(10)].map((r, i) => (
                <tr key={i}>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
              ))
            ) : SingleListData?.result !== undefined && SingleListData?.result?.length !== 0 ? (
              SingleListData?.result
                ?.slice(0)?.reverse()
                ?.map((r, i) => (
                  <tr key={i}>
                   
                    <td>{r?.sequenceName}</td>
                    <td>{r?.customerName}</td>
                    <td>{r?.employeeName}</td>
                    <td>{r?.invAmount?.toFixed(decimalPosition)}</td>
                    <td>{r?.returnAmount?.toFixed(decimalPosition)}</td>
                    <td>{r?.paidAmount?.toFixed(decimalPosition)}</td>
                    {totalJournalKeys?.length > 0 && totalJournalKeys.map((key, i) => {
                        const value = r?.journal[key]?.toFixed(decimalPosition) || '0.00';
                        return (
                          <td key={i}>{value}</td>
                        );
                      })}
                    <td>{r?.amountDue?.toFixed(decimalPosition)}</td>
                    
                  </tr>
                ))
            ) : (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan={9}>No Data</td>
              </tr>
            )}

          </tbody>
          <tfoot >
            <tr>
            <th style={{ textAlign: 'start', fontSize: '1rem', paddingLeft: '3%' }} colSpan={3}>TOTAL(AED)</th>
            <th >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.invAmount?.toFixed(decimalPosition)}</th>
            <th >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.returnAmount?.toFixed(decimalPosition)}</th>
            <th >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.paidAmount?.toFixed(decimalPosition)}</th>   
            {totalJournalKeys.map((key) => (

<th key={key}>{symbol}&nbsp;{SingleListData?.total?.journal[key]?.toFixed(decimalPosition) || '0.00'}</th>
))}
            <th >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.amountDue?.toFixed(decimalPosition)}</th>   
            
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div style={{ display: "none" }}>
        <div
          ref={(el) => (componentRef = el)}
          className="print-container-trail-balance"
          style={{ "@media print": { "@page": { size: "landscape" } } }}
        >
          <div className="trailbalnce-print-header">
            <div className="company-logo-container">
              <img
                height={100}
                width={100}
                src={userInfo?.logo || "http://placehold.it/100x100"}
                alt=""
              /> 
            </div>
            <div className="trail-balance-print-title">
              <span>Sales By Sales Person</span>
              <span style={{ fontSize: "13px" }}> As Off {today}</span>
            </div>
            <div className="company-details-container">
              <span>{userInfo?.data?.profile?.companyName} </span>
              <span>{userInfo?.data?.profile?.companyAddress} </span>
              <span>{userInfo?.data?.profile?.companyEmail}</span>
            </div>
          </div>
          <div>
          <div className="customer-receipt-table-container">
        <table>
          <thead>
            <tr>

              <th>NAME</th>
              <th>CUSTOMER</th>
              <th>EMPLOYEE</th>
              <th>INV AMOUNT</th>
              <th>RETURN AMOUNT</th>
              <th>PAID AMOUNT</th>
              {totalJournalKeys?.map(r =>
                  <th>{r}</th>
                )}
              <th>CREDIT</th>

            </tr>
          </thead>
          <tbody>

         
            {isLoading ? (
              [...Array(10)].map((r, i) => (
                <tr key={i}>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
              ))
            ) : SingleListData?.result !== undefined && SingleListData?.result?.length !== 0 ? (
              SingleListData?.result
                ?.slice(0)
                ?.map((r, i) => (
                  <tr key={i}>
                   
                    <td>{r?.sequenceName}</td>
                    <td>{r?.customerName}</td>
                    <td>{r?.employeeName}</td>
                    <td>{r?.invAmount?.toFixed(decimalPosition)}</td>
                    <td>{r?.returnAmount?.toFixed(decimalPosition)}</td>
                    <td>{r?.paidAmount?.toFixed(decimalPosition)}</td>
                    {totalJournalKeys?.length > 0 && totalJournalKeys.map((key, i) => {
                        const value = r?.journal[key]?.toFixed(decimalPosition) || '0.00';
                        return (
                          <td key={i}>{value}</td>
                        );
                      })}
                    <td>{r?.amountDue?.toFixed(decimalPosition)}</td>
                    
                  </tr>
                ))
            ) : (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan={9}>No Data</td>
              </tr>
            )}

          </tbody>
          <tfoot >
            <tr>
            <th style={{ textAlign: 'start', fontSize: '1rem', paddingLeft: '3%' }} colSpan={3}>TOTAL(AED)</th>
            <th >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.invAmount?.toFixed(decimalPosition)}</th>
            <th >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.returnAmount?.toFixed(decimalPosition)}</th>
            <th >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.paidAmount?.toFixed(decimalPosition)}</th>   
            {totalJournalKeys.map((key) => (

<th key={key}>{symbol}&nbsp;{SingleListData?.total?.journal[key]?.toFixed(decimalPosition) || '0.00'}</th>
))}
            <th >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.amountDue?.toFixed(decimalPosition)}</th>   
            
          </tr>
          </tfoot>
        </table>
      </div>
          </div>
        </div>
      </div>
  </div>
  )
}

export default DailySalesReportSingleView