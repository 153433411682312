import React from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import store from '../../../../../Redux/store';
import { useEffect } from 'react';
import { get_customer_invoice_row_data_list } from './customerInvoiceSlice';
import { CustomerInvoiceFilterAPICall, viewAllCustomerInvoicesAPICall } from './customerInvoiceAPI';
import { convertDateFormat, getCurrentTime } from '../../../../../Js/Date';
import { Skeleton } from '@mui/material';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import CustomPagination from '../../../../Single Components/CustomPagination';
import axios from 'axios';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';

export const CustomerInvoice = () => {
  let navigate = useNavigate();
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const userRole = useSelector((state) => state.userRoleSlice.value)
  const userBranchId = localStorage.getItem("branchId")

  const tableListData = useSelector((state) => state.customerInvoiceSlice.apiResList);

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = tableListData?.pages;
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [selectedBranch, setSelectedBranch] = useState(null);


  const handleClickRow = (row) => {
    // store.dispatch(get_customer_invoice_row_data_list({singleCustomerInvoiceData:row}))
    //navigate("/userdashboard/accounts/customers/customerInvoices/create")
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("singleViewById", row?._id)
    navigate("/userdashboard/accounts/customers/customerInvoices/singleView")
  }
  const createButton = () => {
    navigate("/userdashboard/accounts/customers/customerInvoices/create")
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  useEffect(() => {
    localStorage.removeItem("singleViewById")
    store.dispatch(get_customer_invoice_row_data_list({ singleCustomerInvoiceData: undefined }))
    // viewAllCustomerInvoicesAPICall({},setIsLoading)
  }, [])
  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&  CustomerInvoiceFilterAPICall({
        paymentStatus: FormDataInfo.status.length !== 0 ? (FormDataInfo.status[0] === "PAID") : null,
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} ${getCurrentTime()}`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} ${getCurrentTime()}`,
        branchList: FormDataInfo.branchList,
        status: FormDataInfo.status.length !== 0 ? (FormDataInfo.status[0] === "DRAFT" ? FormDataInfo.status : ["COMPLETED"]) : "",
        search: FormDataInfo.search,
        index: currentPage - 1,
      }, setIsLoading);
    }
  
  }, [FormDataInfo, currentPage]);


  return (
    <div className='global-page-parent-container'>

      <div className='global-white-bg-container'>
      <div className="justify-space-between"
        style={{gap:"0 12px",display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}
        >
           {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                <h3>Branch name: {selectedBranch?.storeCode}-{selectedBranch?.branchName}</h3>
              </div>
            )}
             <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
          <CategorySearchandFilter
          statusOptions={['POSTED','DRAFT','PAID']}
          onData={handleDataFromChild}
          isStatus={true}
          onBranchSelect={handleBranchSelect}
          />
           <button  onClick={createButton}  className='create-button-blue'>Create</button>
           </div>
        </div>
        {/* <div className='create-button-blue-container' style={{justifyContent:"flex-end"}}>
        <button  onClick={createButton}  className='create-button-blue'>Create</button>
      </div> */}
        <div className='create-button-blue-container' style={{ margin: "0 1%" }}>
          <h3>Customer Invoices</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
        </div>
        <div className='new-global-table-container'>
          <table>
            <thead>
              <tr>

              <th>No.</th>

                

                <th>Name</th>
                <th>Customer</th>
                <th>Invoice Date</th>
                <th>Invoice Reference</th>
                <th>Total</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody >
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) :

                tableListData?.list !== undefined &&
                  tableListData?.list?.length !== 0 ?
                  tableListData?.list?.slice(0)?.map((r, i) => (
                    <tr onClick={() => handleClickRow(r)}>

                    <td>{i+1}</td>

                     

                      <td>{r?.name}</td>
                      <td>{r?.customerName}</td>
                      <td>{r?.invoiceDate}</td>
                      <td>{r?.invoiceRef}</td>
                      <td>{r?.total || "---"}</td>
                      <td>{r?.CREATEDBY || "---"}</td>
                      <td className='table-data-box'>
                        {r?.status === "DRAFT" ? (
                          <span className='draft'> DRAFT</span>
                        ) : r?.status === "POSTED" ? (
                          <span className='post'> POST</span>
                        ) : r?.status === "PAID" ? (
                          <span className='paid'>PAID</span>
                        ) : (
                          <span>{r?.status}</span>
                        )}
                      </td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td colSpan={8}>No Data</td>
                  </tr>
              }




            </tbody>
          </table>
        </div>
      </div>

    </div>
  )
}
