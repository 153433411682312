import React, { useEffect, useRef, useState } from "react";

import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter";
import { useSelector } from "react-redux";
import { convertArrayToExcel, filterObjFromList } from "../../../../Js/generalFunctions";
import { companyProfileInfoForUser } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { viewAllCurrencyUpdated } from "../../../../API/Settings/Currency/CurrencyAPI";
import { SalesReportAPIcall } from "./SalesReportApi";
import { SalesReport } from "../../Report/SalesReport/SalesReport";
import { getCurrentTime, today } from "../../../../Js/Date";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import ReactToPrint from "react-to-print";
import { setFilterActive } from "../../../Single Components/listSlice/filterCategorySlice";
import store from "../../../../Redux/store";

export const SalesNewReport = () => {
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  // console.log(userInfo);
  let componentRef = useRef();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [symbol, setSymbol] = useState(null);
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const decimalPosition = localStorage.getItem("decimalPosition");
  const decimalPositionStock = localStorage.getItem("decimalPositionStock");
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const SalesReport = useSelector(
    (state) => state?.salesReport?.salesReportlist
  );
  console.log(SalesReport);
  const currencyListUpdated = useSelector(
    (state) => state?.viewUpdatedCurrency?.value
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };

  const handleClickRow = (row) => {
    localStorage.setItem("singleViewById", row?._id);
    localStorage.setItem("fromDate", FormDataInfo?.fromDate);
    localStorage.setItem("toDate", FormDataInfo?.toDate);
    localStorage.setItem("account", row?.itemName);
    store.dispatch( setFilterActive(window.location.pathname))
    navigate("/userdashboard/sales/Report/salesReportSingleView");
  };
  const downloadList=()=>{
    convertArrayToExcel(SalesReport,"payableReport")
  }
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    // console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);
  useEffect(() => {
    if (FormDataInfo?.length !== 0) {
      let body = {
        fromDate:
          FormDataInfo.fromDate === ""
            ? ""
            : `${FormDataInfo.fromDate} 00:00:00`,
        endDate:
          FormDataInfo.toDate === "" ? "" : `${FormDataInfo.toDate} 23:59:59`,
        branchId:
          FormDataInfo?.branchList?.length === 0
            ? ""
            : FormDataInfo?.branchList[0],
        search: FormDataInfo?.search,
      };

      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&   SalesReportAPIcall(body, setIsLoading);
    }
  }, [FormDataInfo]);

  return (
    <div className="global-page-parent-container">
      <div className="global-white-bg-container">
      <div style={{display:"flex", justifyContent:"space-between"}}>
         <div className="justify-space-between" style={{display:"flex", width:"40%",justifyContent:"normal"}}>
           <div className="sharp-arrow"> <p>Sales Report</p></div>
           {/* <h4>Account Payable Report</h4> */}
           <div style={{marginLeft:"19px"}}>
          <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => componentRef}
        />

        <Tooltip title="Download">
          <IconButton onClick={downloadList}>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        </div>
         </div>
         <div>
                <CategorySearchandFilter
                statusOptions={['PURCHASE ORDER','DONE','CANCEL']}
                onData={handleDataFromChild}
                onBranchSelect={handleBranchSelect}
                />
             </div>

        </div>
        {/* <div className="justify-space-between">
        
           
          
          <div className="sharp-arrow" style={{ width: "105Px" }}>
            {" "}
            <p>Sales Report</p>
          </div>
          <div >

         
          <Tooltip title="Share">
              <IconButton>
                <i
                  class="bi bi-share"
                  style={{
                    backgroundColor: "#9797e9",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
        
          
            <ReactToPrint
              trigger={() => (
                <Tooltip title="Print">
                  <IconButton
                    className="printer-div"
                    style={{ padding: "1px" }}
                  >
                    <i
                      style={{
                        color: "white",
                        backgroundColor: " #d787e7",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                      class="bi bi-printer"
                    ></i>
                  </IconButton>
                </Tooltip>
              )}
              content={() => componentRef}
            />
         
            <Tooltip title="Download">
              <IconButton>
                <i
                  class="bi bi-arrow-down-circle"
                  style={{
                    backgroundColor: "rgb(255 118 133 / 69%)",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
            </div>
          <div>
            <CategorySearchandFilter
              statusOptions={["PURCHASE ORDER", "DONE", "CANCEL"]}
              onData={handleDataFromChild}
            />
          </div>
        
        </div>


        <h1 style={{ margin: "1px", fontSize: "large" }}>Sales Report</h1> */}

        <div className="global-report-table-container">
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>UOM</th>
                <th>Opening Qty</th>
                <th>Sold Qty</th>
                <th>Return Qty</th>
                <th>Closing item</th>
                <th>Gross Item Sales</th>
                <th>Total Item Cost</th>
                <th>Item Profit</th>
                <th>Gross Profit Per Unit</th>
              </tr>
            </thead>
            <tbody>
              
              {SalesReport?.report?.map((item, index) => (
                <tr key={index} onClick={() => handleClickRow(item)}>
                  <td>{item.itemName}</td>
                  <td>{item.uom}</td>
                  <td>{item.openingQty?.toFixed(decimalPositionStock)}</td>
                  <td>{item.soldQty?.toFixed(decimalPositionStock)}</td>
                  <td>{item.salesRet?.toFixed(decimalPositionStock)}</td>
                  <td>{item.closingSale?.toFixed(decimalPosition)}</td>
                  <td>{item.grossItemSale?.toFixed(decimalPosition)}</td>
                  <td>{item.grossItemCost?.toFixed(decimalPosition)}</td>
                  <td>{item.grossprofit?.toFixed(decimalPosition)}</td>
                  <td>{item.grossprofitperunit?.toFixed(decimalPosition)}</td>
                </tr>
              ))}
            </tbody>
            <thead>
            <tr>
                <th colSpan={2}>Total</th>
             
                <th>{SalesReport?.total!==undefined && SalesReport?.total?.openingQty?.toFixed(decimalPosition)}</th>
                <th>{SalesReport?.total!==undefined && SalesReport?.total?.soldQty?.toFixed(decimalPosition)}</th>
                <th>{SalesReport?.total!==undefined && SalesReport?.total?.salesRet?.toFixed(decimalPosition)}</th>
                <th>{SalesReport?.total!==undefined && SalesReport?.total?.closingSale?.toFixed(decimalPosition)}</th>
                <th>{SalesReport?.total!==undefined && SalesReport?.total?.grossItemSale?.toFixed(decimalPosition)}</th>
                <th>{SalesReport?.total!==undefined && SalesReport?.total?.grossItemCost?.toFixed(decimalPosition)}</th>
                <th>{SalesReport?.total!==undefined && SalesReport?.total?.grossprofit?.toFixed(decimalPosition)}</th>
                <th>{SalesReport?.total!==undefined && SalesReport?.total?.grossprofitperunit?.toFixed(decimalPosition)}</th>

               
              </tr>
              </thead>
          </table>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div
          ref={(el) => (componentRef = el)}
          className="print-container-trail-balance"
          style={{ "@media print": { "@page": { size: "landscape" } } }}
        >
          <div className="trailbalnce-print-header">
            <div className="company-logo-container">
              <img
                height={100}
                width={100}
                src={userInfo?.logo || "http://placehold.it/100x100"}
                alt=""
              />
            </div>
            <div className="trail-balance-print-title">
              <span>Sales Report</span>
              <span style={{ fontSize: "13px" }}> As Off {today}</span>
            </div>
            <div className="company-details-container">
              <span>{userInfo?.data?.profile?.companyName} </span>
              <span>{userInfo?.data?.profile?.companyAddress} </span>
              <span>{userInfo?.data?.profile?.companyEmail}</span>
            </div>
          </div>
          <div>
            <div className="global-report-table-container">
              <table>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>UOM</th>
                    <th>Opening Qty</th>
                    <th>Sold Qty</th>
                    <th>Return Qty</th>
                    <th>Closing item</th>
                    <th>Gross Item Sales</th>
                    <th>Total Item Cost</th>
                    <th>Item Profit</th>
                    <th>Gross Profit Per Unit</th>
                  </tr>
                </thead>
                <tbody>
                  {SalesReport?.report?.map((item, index) => (
                    <tr key={index} onClick={() => handleClickRow(item)}>
                      <td>{item.itemName}</td>
                      <td>{item.uom}</td>
                      <td>{item.openingQty?.toFixed(decimalPositionStock)}</td>
                      <td>{item.soldQty?.toFixed(decimalPositionStock)}</td>
                      <td>{item.salesRet?.toFixed(decimalPositionStock)}</td>
                      <td>{item.closingSale?.toFixed(decimalPosition)}</td>
                      <td>{item.grossItemSale?.toFixed(decimalPosition)}</td>
                      <td>{item.grossItemCost?.toFixed(decimalPosition)}</td>
                      <td>{item.grossprofit?.toFixed(decimalPosition)}</td>
                      <td>
                        {item.grossprofitperunit?.toFixed(decimalPosition)}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <thead style={{position:"sticky"}}>
            <tr>
                <th colSpan={2}>Total</th>
             
                <th>{SalesReport?.total!==undefined && SalesReport?.total?.openingQty?.toFixed(decimalPosition)}</th>
                <th>{SalesReport?.total!==undefined && SalesReport?.total?.soldQty?.toFixed(decimalPosition)}</th>
                <th>{SalesReport?.total!==undefined && SalesReport?.total?.salesRet?.toFixed(decimalPosition)}</th>
                <th>{SalesReport?.total!==undefined && SalesReport?.total?.closingSale?.toFixed(decimalPosition)}</th>
                <th>{SalesReport?.total!==undefined && SalesReport?.total?.grossItemSale?.toFixed(decimalPosition)}</th>
                <th>{SalesReport?.total!==undefined && SalesReport?.total?.grossItemCost?.toFixed(decimalPosition)}</th>
                <th>{SalesReport?.total!==undefined && SalesReport?.total?.grossprofit?.toFixed(decimalPosition)}</th>
                <th>{SalesReport?.total!==undefined && SalesReport?.total?.grossprofitperunit?.toFixed(decimalPosition)}</th>

               
              </tr>
              </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
