import axios from 'axios'
import {get_credit_list} from '../../Redux/Sales/Credit/viewCreditSlice'
import {HEADERS} from '../../API/UrlAndPaths'
import store from '../../Redux/store'
import { alterationInvoiceAPICall } from '../Orders/AlterationAPI'
import { generateInvoiceAPICall } from '../Orders/workOrderAPI'
import { generateBillingInvoiceAPICall } from './billing'

// get credit api call
export const creditAPICall= async (setIsLoading)=>{
    setIsLoading(true)
    await axios.post("credit/viewCredit",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
          store.dispatch(get_credit_list({creditData:res.data}))
          setIsLoading(false)
        }
    })
    .catch((err)=>{
          store.dispatch(get_credit_list({creditData:undefined}))
          setIsLoading(false)
          console.log(err.response);
    })
}

// credit pay balance api call
export const updateCreditPayBalanceAPICall= async(
  body,
  setIsLoading,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates,
  openInvoiceDialog,
  clearBillingStates
)=>{
  setIsLoading(true);
 await axios.post("credit/updateCredit",body,HEADERS)
 .then((res)=>{
   if(res.status===200){
    setSnackMsg("Payment updated 👍");
    clearStates();
    updateListener();
    setSuccess(true);
    setIsLoading(false);
    clearBillingStates();
    if(body.type==0){
      generateInvoiceAPICall(
        { _id: res.data?.workorder?.purchasePk},
        openInvoiceDialog
      );
      console.log('workorder invoice');
    }
    if(body.type == 1){
      alterationInvoiceAPICall({_id:res?.data?.workorder?.purchasePk},openInvoiceDialog)
      console.log('alteration invoice');
    }
    if(body.type==2){
      generateBillingInvoiceAPICall({_id:res?.data?.workorder?.purchasePk},openInvoiceDialog)
      console.log('bill order');
    }
   
   }
 })
 .catch((err)=>{
  switch (err.response.status) {
    case undefined:
      setSnackMsg("Network error!!");
      break;
    case 404:
      setSnackMsg("Shift is inactive!!");
      break;
    case 401:
      setSnackMsg("Unauthorized!!");
      break;
    case 403:
      setSnackMsg("Forbidden!!");
      break;
    case 422:
      setSnackMsg("Payment Failed!!");
      break;
    case 500:
      setSnackMsg("Server error!!");
      break;

    default:
      setSnackMsg("Something wrong!!");
      break;
  }
  setIsLoading(false);
  setError(true);
  console.error(err.response);
 })
}