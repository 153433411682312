import { createSlice } from "@reduxjs/toolkit";

export const listCategorySlice = createSlice({
    name:"listCategory",
    initialState:{
        filterCategorylist:undefined,
        backButtonClicked:false,
        filterActive:true,
        searchKeyWord:"",
        paginationIndex:1
    },

    reducers: {
        set_Filter_CategoryList:(state,action)=>{
            state.filterCategorylist=action.payload.filterCategorylistData
        },
        set_Back_button_action:(state,action)=>{
            state.backButtonClicked=action.payload.backButtonClickAction
        },
        setFilterActive:(state,action)=>{
            state.filterActive=action.payload
        },
        setFilterSearch:(state,action)=>{
            state.searchKeyWord=action.payload
        },
        setPaginationNumber:(state,action)=>{
            state.paginationIndex=action.payload
        }
    }
})

export const {set_Filter_CategoryList,set_Back_button_action,setPaginationNumber,setFilterActive,setFilterSearch} = listCategorySlice.actions;
export default listCategorySlice.reducer;