import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CustomerReceiptReportAPIcall } from './GeneralReportAPI';
import { Skeleton } from '@mui/material';
import { filterObjFromList } from '../../../../../Js/generalFunctions';

const CustomerReceiptPrint = () => {
    const tableListData = useSelector((state) => state?.generalReport?.customerReceiptReport)
    const totalPaidAmtKeys = tableListData !== undefined ? Object.keys(tableListData?.total?.totalPaidAmount) : [];
    const [isLoading, setIsLoading] = useState(false);
    const decimalPosition = localStorage.getItem("decimalPosition");

    const [symbol, setSymbol] = useState(null);
    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue)

        useEffect(() => {
            let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
            setSymbol(currencyObj?.symbol)
        }, [companyProfileData, currencyListUpdated])
    useEffect(()=>{
        CustomerReceiptReportAPIcall()
    },[])
  return (
<div>
    <div style={{display:"flex",justifyContent:"space-between",padding:"10px 23px 30px 18px",alignItems:"center"}}>
    <div>
        <img style={{height:"6vh"}}
            src={companyProfileData?.logo}
            alt=""
        />
    </div>
    <div>
        <h1  style={{margin:'0',fontSize:"x-large"}}>Customer Receipt Report</h1>
    </div>
    <div>
        <div className="right-side-div" style={{width:"100%"}}>
            <h1 style={{ fontSize: "medium" }}>
                {companyProfileData?.companyName === null ? "" : companyProfileData?.companyName}
            </h1>
            <p style={{ paddingLeft: "30px", paddingTop: "10px" }}>
                {companyProfileData?.companyAddress === null || companyProfileData?.companyAddress === "null" ? "" : companyProfileData?.companyAddress}</p>
            <p style={{ fontWeight: "100" }}>{companyProfileData?.contactNumber === null ? "" : companyProfileData?.contactNumber}</p>
            {companyProfileData?.companyTRN !== "" && (
                <p style={{ fontWeight: "100" }}>TRN: {companyProfileData?.companyTRN}</p>
            )}
        </div>
    </div>

</div>
    <div className="invoice-table-container">
    <div className="invoice-main-div" style={{overflow:"unset"}}>
        
        <table>
            <thead>
                <tr>
                    <th style={{textAlignLast:"center"}}>Customer</th>
                    <th style={{textAlignLast:"center"}}>INV count</th>
                    <th style={{textAlignLast:"center"}}>INV Amount</th>
                    <th style={{textAlignLast:"center"}}>Paid Amount</th>
                    {
                        totalPaidAmtKeys?.map(r =>
                            <th style={{textAlignLast:"center"}}>{r}</th>
                        )
                    }
                    <th style={{textAlignLast:"center"}}>Credit</th>
                </tr>
            </thead>
            <tbody>

                {isLoading ? (
                    [...Array(10)].map((r, i) => (
                        <tr key={i}>
                            <td colSpan={8}>
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width={"100%"}
                                    height={40}
                                />
                            </td>
                        </tr>
                    ))
                ) : tableListData?.report !== undefined && tableListData?.report?.length !== 0 ? (
                    tableListData?.report
                        ?.slice(0)
                        ?.map((r, i) => (
                            <tr key={i} >
                                <td  style={{textAlignLast:"start"}}>{r?.customerName}</td>
                                <td style={{textAlignLast:"center"}}>{r?.invoiceCount}</td>
                                <td style={{textAlignLast:"center"}}>{symbol}&nbsp;{r?.invAmount?.toFixed(decimalPosition)}</td>
                                <td style={{textAlignLast:"center"}}>{symbol}&nbsp;{r?.total?.toFixed(decimalPosition)}</td>
                                {totalPaidAmtKeys?.map((key) => (

                                    <td style={{textAlignLast:"center"}} key={key}>{symbol}&nbsp;{r?.totalPaidAmount[key]?.toFixed(decimalPosition) || '0.00'}</td>
                                ))}


                                <td style={{textAlignLast:"center"}}>{symbol}&nbsp;{r?.amountDue?.toFixed(decimalPosition)}</td>
                            </tr>
                        ))
                ) : (
                    <tr>
                        <td style={{ textAlign: 'center' }} colSpan={8}>No Data</td>
                    </tr>
                )}

            </tbody>
            {tableListData?.total !== undefined &&
                <tr>
                    <th >Total</th>
                    <th style={{textAlignLast:"center"}}>{tableListData?.total?.invoiceCount}</th>
                    <th style={{textAlignLast:"end"}}>{symbol}&nbsp;{tableListData?.total?.invAmount?.toFixed(decimalPosition)}</th>
                    <th style={{textAlignLast:"end"}}>{symbol}&nbsp;{tableListData?.total?.total?.toFixed(decimalPosition)}</th>
                    {totalPaidAmtKeys.map((key) => (

                        <th style={{textAlignLast:"end",paddingRight:"10px"}} key={key}>{tableListData?.total?.totalPaidAmount[key]?.toFixed(decimalPosition) || 0}</th>
                    ))}
                    <th style={{textAlignLast:"end",paddingRight:"10px"}}>{symbol}&nbsp;{tableListData?.total?.amountDue?.toFixed(decimalPosition)}</th>
                </tr>
            }
            {/* <tr>
                <th >Total</th>
                <th >{tableListData?.total !== undefined && tableListData?.total?.invoiceCount}</th>
                <th >{symbol}&nbsp;{tableListData?.total !== undefined && tableListData?.total?.invAmount?.toFixed(decimalPosition)}</th>
                <th >{symbol}&nbsp;{tableListData?.total !== undefined && tableListData?.total?.total?.toFixed(decimalPosition)}</th>
                {totalPaidAmtKeys.map((key) => (

                    <th key={key}>{tableListData?.total?.totalPaidAmount[key] || 0}</th>
                ))}
                <th >{symbol}&nbsp;{tableListData?.total !== undefined && tableListData?.total?.amountDue?.toFixed(decimalPosition)}</th>
            </tr> */}
        </table>
    </div>
</div>
</div>
  )
}

export default CustomerReceiptPrint