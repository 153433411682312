import React, { useEffect, useLayoutEffect } from "react";
import "../../../../css/Orders/CuttingSlip/thobCuttingSlip.css";
import qrCode from "../../../../Assets/Images/qrCodeImage.png";
import { useSelector } from "react-redux";
import { convertDateFormat } from "../../../../Js/Date";
import { useState } from "react";
import { removeDuplicateFromAoO } from "../../../../Js/generalFunctions";
import Barcode from "react-barcode";
export const ThobCuttingSlip = (props) => {
  const { qr, cuttingSlipData, printingObject, copy } = props;
  // const generatedQrCode = useSelector((state) => state.qrForPcsSlice.value);
  // console.log(qr)
  const [bottomTable, setBottomTable] = useState([]);

  //Extract Bottom Table to new State and It must be unique
  const extractBottomTableWithoutDuplication = (printingObject) => {
    let unique=[]
    let bottomTable = printingObject?.materials?.map((r, i) => {
      return {
        marModelName: r.marModelName,
        fpName: r.fpName,
        penName: r.penName,
        mobilePocket: r.mobilePocket,
        buttonNo: r.buttonNo,
        sidePTName: r.sidePTName,
        sidePTSize: r.sidePTSize,
        remark: r.remark,
      };
    });
     unique = removeDuplicateFromAoO(bottomTable);
     setBottomTable(unique)
    // console.log(unique);
  };

  useLayoutEffect(() => {
    extractBottomTableWithoutDuplication(printingObject);
  }, [printingObject]);

  console.log(printingObject);

  return (
    <div className="thob-cutting-slip-container">
      <div className="thob-cutting-slip-top-container">
        <div className="thob-cutting-slip-top-first-content">
        
          
          <p>{cuttingSlipData?.cusMobile || "-Contact NIL-"}</p>
          <p className="main-heading-thob-cutting-slip">THOB CUTTING SLIP</p>
          <h3></h3>
          {copy && <p>Copy</p>}
        </div>
        <div className="thob-cutting-slip-top-second-content">
          <h3 style={{margin:0}}>
            {printingObject?.relationName === "null"
              ? cuttingSlipData?.cusName
              : printingObject?.relationName}
          </h3>
          <p>
            Order Date&nbsp;:&nbsp;
            {convertDateFormat(cuttingSlipData?.ordDate) || "-NIL-"}
          </p>
        </div>
        <div className="thob-cutting-slip-top-third-content">
          <p>{cuttingSlipData?.woNo || "-NIL-"}</p>
          <p>
            Delivery Date&nbsp;:&nbsp;
            {cuttingSlipData?.delDate !== null ? convertDateFormat(cuttingSlipData?.delDate) :"-NIL-"}
          </p>
        </div>
        {/* <div className="thob-cutting-slip-top-third-content">
        <p style={{fontSize:"0.9rem"}}>status:</p>
        </div> */}
      </div>
      <hr className="global-hr" />
      <div className="thob-cutting-slip-middle-container">
        <div className="thob-cutting-slip-middle-top-container">
          <div className="thob-cutting-slip-single-container single-item-input">
            <p style={{ fontSize: "0.9em" }}>
              {printingObject?.length || ""}
            </p>
          </div>
        
            <div className="thob-cutting-slip-single-container">
              <p>(Shoulder)</p>
              <p className="cutting-slip-input-number-text">
                {printingObject?.shoulder || ""}
              </p>
            </div>
          
          
            <div className="thob-cutting-slip-single-container">
              <p>(Sleeve)</p>
              <p className="cutting-slip-input-number-text">
                {printingObject?.sleeve || ""}
              </p>
            </div>
        
          
            <div className="thob-cutting-slip-single-container">
              <p>(Chest)</p>
              <p className="cutting-slip-input-number-text">
                {printingObject?.chest || ""}
              </p>
            </div>
          
          
            <div className="thob-cutting-slip-single-container">
              <p>(Stomach)</p>
              <p className="cutting-slip-input-number-text">
                {printingObject?.stomach || ""}
              </p>
            </div>
          
        
            <div className="thob-cutting-slip-single-container">
              <p>(S-L Chest)</p>
              <p className="cutting-slip-input-number-text">
                {printingObject?.slChest || ""}
              </p>
            </div>
          
         
            <div
              className="thob-cutting-slip-single-container"
              style={{ margin: "1% 0" }}
            >
              <p>(SL S O)</p>
              <p className="cutting-slip-input-number-text">
                {printingObject?.slSo || ""}
              </p>
            </div>
         
        </div>
        <div className="thob-cutting-slip-middle-bottom-container">
          <div className="thob-cutting-slip-middle-bottom-content">
           
              <p>
                Mar Size &nbsp; <span>{printingObject?.marSize || ""}</span>
              </p>
            
         
              <p>
                (Regal Size) &nbsp;{" "}
                <span>{printingObject?.regalSize || ""}</span>
              </p>
           
           
              <p>
                (Knee Loose) &nbsp;{" "}
                <span>{printingObject?.kneeLoose || ""}</span>
              </p>
            
          </div>
          <div style={{ width: "45%", display: "flex" }}>
          <div
                className="thob-cutting-slip-single-container"
                style={{ width: "37%", margin: "1% 2% 1% 3%" }}
              >
                <p>(FP Down)</p>
                <p className="cutting-slip-input-number-text">
                  {printingObject?.fpDown}
                </p>
              </div>
            
              <div
                className="thob-cutting-slip-single-container"
                style={{ width: "37%", margin: "1% 2% 1% 0" }}
              >
                <p>(Bottom)</p>
                <p className="cutting-slip-input-number-text">
                  {printingObject?.bottom}
                </p>
              </div>
           
           
              <div
                className="thob-cutting-slip-single-container"
                style={{ width: "37%", margin: "1% 0% 1% 0" }}
              >
                <p>(Neck)</p>
                <p className="cutting-slip-input-number-text">
                  {printingObject?.neck}
                </p>
              </div>
            
           
             
           
          </div>
        </div>
        <div className="thob-cutting-slip-middle-botton-notes-cotainer">
          <div className="thob-cutting-slip-middle-botton-notes-left-cotainer">
            <p>Notes</p>
            <input type="text" />
          </div>
          <div className="thob-cutting-slip-middle-botton-notes-right-cotainer">
            
              <div
                className="thob-cutting-slip-single-container right-large-container"
                style={{ margin: "1% 3% 1% 0" }}
              >
                <p>(FP Size)</p>
                <p className="cutting-slip-input-number-text">{`${printingObject?.fpSize1||""}, ${printingObject?.fpSize2||""}`}</p>
              </div>
           
            
              <div
                className="thob-cutting-slip-single-container right-large-container"
                style={{ margin: "1% 0" }}
              >
                <p>(Neck D Bottom )</p>
                <p className="cutting-slip-input-number-text">
                  {printingObject?.neckDButton}
                </p>
              </div>
           
          </div>
        </div>
        <div className="thob-cutting-slip-middle-table-container">
          <table>
            <thead>
              <tr>
                <th>Selected Clothes</th>
                <th>Colour</th>
                <th>Qty</th>
                <th>Model</th>
                <th>Cuff</th>
                <th>Size</th>
                <th>Cuff Cloth</th>
                <th>Model</th>
                <th>Collar</th>
                <th>Size</th>
                <th>Cloth</th>
                <th>Model</th>
                <th>Button</th>
                <th>Stitching</th>
              </tr>
            </thead>
            <tbody>
              {printingObject?.materials?.map((r, i) => (
                <tr key={i}>
                  <td>{r?.mName?.toUpperCase()}</td>
                  <td>{r?.color?.toUpperCase()}</td>
                  <td>{r?.qty}</td>
                  <td>{r?.thobModelName}</td>
                  <td>{r?.cuffName}</td>
                  <td>
                    {r?.cuffSize1} &nbsp; {r?.cuffSize2}
                  </td>
                  <td>{r?.cuffClothName}</td>
                  <td>{r?.cuffModelName}</td>
                  <td>{r?.collarName}</td>
                  <td>{r?.collarSize}</td>
                  <td>{r?.collarClothName}</td>
                  <td>{r?.collarModelName}</td>
                  <td>{r?.buttonName}</td>
                  <td>{r?.stitchingName}</td>
                </tr>
              ))}

              {/* <tr>
                <td>MAKKAN CREAM</td>
                <td>1</td>
                <td>SP soft</td>
                <td>SEEDA</td>
                <td>15 &nbsp; 44</td>
                <td>SHIKIBO</td>
                <td>M3</td>
                <td>rrt</td>
                <td>55</td>
                <td>47</td>
                <td>M05</td>
                <td>HB 1/4</td>
                <td>CHAVITT</td>
              </tr>
              <tr>
                <td>MAKKAN CREAM</td>
                <td>1</td>
                <td>SP soft</td>
                <td>SEEDA</td>
                <td>15 &nbsp; 44</td>
                <td>SHIKIBO</td>
                <td>M3</td>
                <td>rrt</td>
                <td>55</td>
                <td>47</td>
                <td>M05</td>
                <td>HB 1/4</td>
                <td>CHAVITT</td>
              </tr>
              <tr>
                <td>MAKKAN CREAM</td>
                <td>1</td>
                <td>SP soft</td>
                <td>SEEDA</td>
                <td>15 &nbsp; 44</td>
                <td>SHIKIBO</td>
                <td>M3</td>
                <td>rrt</td>
                <td>55</td>
                <td>47</td>
                <td>M05</td>
                <td>HB 1/4</td>
                <td>CHAVITT</td>
              </tr> */}
            </tbody>
          </table>
        </div>
        <div className="thob-cutting-slip-table-qrCode-container">
          <div className="thob-cutting-slip-table-qrCode-table">
            <table>
              <thead>
                <tr>
                  <th>MAR Model</th>
                  <th>FP Model</th>
                  <th>Pen</th>
                  <th>Mobile Pocket</th>
                  <th>Button No.</th>
                  <th>Side Pocket Model</th>
                  <th>Side Pocket Size</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                {bottomTable?.map((r, i) => (
                  <tr key={i}>
                    <td>{r?.marModelName}</td>
                    <td>{r?.fpName}</td>
                    <td>{r?.penName}</td>
                    <td>{r?.mobilePocket?.toUpperCase()}</td>
                    <td>{r?.buttonNo}</td>
                    <td>{r?.sidePTName}</td>
                    <td>{r?.sidePTSize}</td>
                    <td>{r?.remark}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {
             cuttingSlipData?.isCode === true ?
             <div className="thob-cutting-slip-table-qrCode-qrcode">
                <img src={qr} alt="qrCode" />
             </div>
             :
             <div className="thob-cutting-slip-table-qrCode-qrcode" style={{border:"1px solid #fff"}}>
             <Barcode
                value={cuttingSlipData?.barcodeNumber}
                width={1}
                fontSize={16}
                height={60}
                margin={"2px"}
             />
             </div>
             
          }
        
        </div>
        <div className="thob-cutting-slip-bottom-container">
          <div className="thob-cutting-slip-bottom-first-container">
            <div className="thob-cutting-slip-bottom-first-left-container">
              {printingObject?.cuttingMasters?.map((cMaster, i) => (
                <p key={i}>{cMaster}</p>
              )) || (
                <>
                  <p></p>
                  <p className="IQBAL-P"></p>
                  <p className="empty-p"></p>
                </>
              )}
            </div>
            <div className="thob-cutting-slip-bottom-first-right-container">
              <p>Remarks</p>
              <input type="text" />
            </div>
          </div>
          <div className="thob-cutting-slip-bottom-second-container">
            {printingObject?.tailors?.map((tailor, i) => (
              <p key={i}>{tailor}</p>
            )) || (
              <>
                <p></p>
                <p className="left-border-p-thob-cutting-slip"></p>
                <p className="left-border-p-thob-cutting-slip"></p>
                <p className="left-border-p-thob-cutting-slip"></p>
                <p className="left-border-p-thob-cutting-slip"></p>
                <p className="left-border-p-thob-cutting-slip"></p>
                <p className="left-border-p-thob-cutting-slip"></p>
                <p className="left-border-p-thob-cutting-slip-last"></p>
              </>
            )}
          </div>
        </div>
        <div style={{display:"flex",justifyContent:"space-between"}}>
          <p style={{margin:"8px 0",fontSize:"0.8rem",fontWeight:600}}>Booking</p>
          <p style={{margin:"8px 0",fontSize:"0.8rem",fontWeight:600}}>Finished</p>
          <p style={{margin:"8px 0",fontSize:"0.8rem",fontWeight:600}}>Delivered</p>
        </div>
      </div>
    </div>
  );
};
