import { createSlice } from "@reduxjs/toolkit";

export const Report = createSlice({
    name:"ReportSlice",
    initialState:{
        StockMove:undefined,
        StockMoveSingle:"hgygtgtpp",
        StockMoveAgingReport:undefined,
    },
    reducers:{
        setStockMove:(state,action)=>{
            state.StockMove = action.payload.stockMoveList
        },
        setStockMoveSigleView:(state,action)=>{
            state.StockMoveSingle = action.payload.stockMoveSingleList
        }, 
        set_Stock_Move_Aging_View:(state,action)=>{
            state.StockMoveAgingReport = action.payload.StockMoveAgingReportList
        }, 

    }
})
export const {set_Stock_Move_Aging_View,setStockMove,setStockMoveSigleView}=Report.actions;
export default Report.reducer;