import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { filterObjFromList } from "../../../../Js/generalFunctions";

function SalesReportBySalesPersonPrint(props) {
    const { data, formValues, total } = props;
    const [symbol, setSymbol] = useState(null);
    const decimalPosition = localStorage.getItem("decimalPosition");
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );

  //company info
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const today = new Date();
  const formattedToday = `${today.getDate().toString().padStart(2, "0")}-${(
    today.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${today.getFullYear()}`;
    useEffect(() => {
        let currencyObj = filterObjFromList(
          "_id",
          currencyListUpdated,
          "currency",
          companyProfileData
        );
        // console.log(currencyObj);
        setSymbol(currencyObj?.symbol);
      }, [companyProfileData, currencyListUpdated]);
  return (
    <div className="print-main-div">
    <div className="header-div">
      <div style={{ maxWidth: "100px", height: "62px" }}>
        <img
          src={loginResponse?.logo}
          alt=""
          style={{ maxWidth: "100%", height: "100%", objectFit: "contain" }}
        />
      </div>
      <div>
        <h3 style={{ fontSize: "1em", margin: "0" }}>{formValues?.label}</h3>
        <span style={{ textAlign: "center", display: "block" }}>
          {formattedToday}
        </span>
      </div>
      <div>
        {/* <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1> */}
        <p style={{ margin: "0", fontSize: "smaller" }}>
          {loginResponse?.data?.profile?.companyName}
        </p>
        <p style={{ margin: "0", fontSize: "smaller" }}>
          {loginResponse?.data?.profile?.companyAddress}
        </p>
        <p style={{ margin: "0", fontSize: "smaller" }}>
          {loginResponse?.data?.profile?.contactNumber}
        </p>
      </div>
    </div>

    <div className="customer-receipt-table-container">
      <table>
        <thead>
          <tr>
          <th>EMPLOYEE ID</th>
                <th>EMPLOYEE</th>
                <th>INVOICE COUNT</th>
                <th>INVOICE AMOUNT</th>
                <th>SALES RETURN COUNT</th>
                <th>SALES RETURN AMOUNT</th>
                <th>TOTAL TAX</th>
                <th>TOTAL DISCOUNT</th>
                <th>RECEIVED AMOUNT</th>
                <th>CREDIT AMOUNT</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>
                {item.emp_id}
              </td>
              <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                {item.staff_name}
              </td>
              <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                {item.invoiceCount.toFixed(decimalPosition)}
              </td>
              <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
             {item.invoiceAmount.toFixed(decimalPosition)}
              </td>
              <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                {item.returnCount}
              </td>
              <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                {item.returnAmount.toFixed(decimalPosition)}
              </td>
              <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
              {item.tax.toFixed(decimalPosition)}
              </td>
              <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
             {item.discount}
              </td>
              <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
             {item.receivedAmount.toFixed(decimalPosition)}
              </td>
              <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
               {item.creditAmount.toFixed(decimalPosition)}
              </td>
            
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th
              style={{
                textAlign: "start",
                fontSize: "1rem",
                paddingLeft: "5%",
              }}
              colSpan={2}
            >
              TOTAL(AED)
            </th>

            <th>
              {formValues?.invoiceCount}
            </th>
            <th>
             {formValues?.invoiceAmount}
            </th>
            <th>
              {formValues?.returnCount}
            </th>
            <th>
            {formValues?.returnAmount}
            </th>
            <th>
             {formValues?.tax}
            </th>
            <th>
          {formValues?.amount}
            </th>
            <th>
           {formValues?.receivedAmount}
            </th>
            <th>
            {formValues?.creditAmount}
            </th>
          
       
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
  )
}

export default SalesReportBySalesPersonPrint