import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { productListSingleViewAPICall } from '../../../../API/Product List/productListAPI'
import { useSelector } from 'react-redux';

export const ProductListSingleView = () => {
    const singleRowId = localStorage.getItem("singleViewById");
    const allProductsListSingle = useSelector(
        (state) => state.allProductsListSlice.singleView
      );
    const navigateTo=useNavigate()
    const backToProduct=()=>{
        navigateTo("/userdashboard/productList")
    }
    console.log(allProductsListSingle);

    useEffect(()=>{
        if (singleRowId) {
        productListSingleViewAPICall({productId:singleRowId})
        }
    },[])
  return (
    <div className="global-page-parent-container">
    <div className='global-white-bg-container'>
        <div className='catelog-header-container'>
            <p onClick={backToProduct} style={{cursor:"pointer"}}>PRODUCT LIST</p>
            <p><i class="bi bi-chevron-right"></i></p>
            <p style={{cursor:"pointer"}}>{allProductsListSingle?.productName}</p>
            
        </div>
        <div className='new-global-table-container'>
            <table>
                <thead>
                    <tr>
                        <th>Branch Name</th>
                        <th style={{color:"red"}}>Closing Stock</th>
                        <th style={{color:"blue"}}>Unit Cost</th>
                        <th>Stock Value</th>
                    </tr>
                </thead>
                <tbody>
                {
                        allProductsListSingle?.list?.map((r)=>(
                            <tr>
                            <td>{r?.branchName}</td>
                            <td style={{color:"red"}}>{r?.stock}</td>
                            <td style={{color:"blue"}}>{r?.unitCost}</td>
                            <td>{r?.stockValue}</td>
                        </tr>
                        ))
                    }
                   
                </tbody>
                <thead>
                    <tr>
                        <th>Total</th>
                        <th style={{color:"red"}}>{allProductsListSingle?.totalClosingStock}</th>
                        <th style={{color:"blue"}}></th>
                        <th>{allProductsListSingle?.totalStockValue}</th>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
    </div>
  )
}
