import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Backdrop, MenuItem, Select } from "@mui/material";
import { getCurrentTime, today } from "../../../Js/Date";

export const EditPayment = (props) => {
  //props
  const {
    closePayments,
    subTotal,
    prevPaymentForm,
    prevPaymentObject = null,
    bodyWoPayment,
    finalAPICall,
    setIsLoading,
    setOpenSuccessSnack,
    setOpenErrorSnack,
    setSnackMsg,
    updateListener,
    clearWorkOrderState,
    clearEditForms,
    left,
    marginTop,
    width,
    right,
    boxShadow,
    zIndex,
    paymentSection,
    returnPaymnetType
  } = props;
// console.log(prevPaymentObject);
  //Edit payment form
  const editPaymentFormInitialState = {
    subTotal: 0,
    shippingCharge: 0,
    couponCode: "",
    rewardPoints: 0,
    discount: 0,
    paidAmount: 0,
    wallet: 0,
    credit: 0,
  };
  const [editPaymentForm, setEditPaymentForm] = useState(
    editPaymentFormInitialState
  );
  //total Amount
  const [total, setTotal] = useState(0.0);
  //change
  const [change, setChange] = useState(0.0);
  //Balance
  const [balance, setBalance] = useState(0.0);
  // wallet
  const [isWallet,setIsWallet]=useState(false)

  //Onchange functions
  const getPaymentsForm = (key) => (e) => {
    const { value } = e.target;

    switch (key) {
      case "shippingCharge":
        setEditPaymentForm({
          ...editPaymentForm,
          shippingCharge: value === "" ? 0 : value,
        });
        break;
      case "couponCode":
        setEditPaymentForm({ ...editPaymentForm, couponCode: value });
        break;
      case "rewardPoints":
        setEditPaymentForm({
          ...editPaymentForm,
          rewardPoints: value === "" ? 0 : value,
        });
        break;
      case "discount":
        setEditPaymentForm({
          ...editPaymentForm,
          discount: value === "" ? 0 : value,
        });
        break;
      case "paidAmount":
        setEditPaymentForm({
          ...editPaymentForm,
          paidAmount: value === "" ? 0 : value,
        });
        break;
      case "wallet":
        setEditPaymentForm({
          ...editPaymentForm,
          wallet: value === "" ? 0 : value,
        });
        break;
      case "credit  ":
        setEditPaymentForm({
          ...editPaymentForm,
          credit: value === "" ? 0 : value,
        });
        break;

      default:
        break;
    }
  };

  //Assign Props to states
  const assignFormPropsToStates = (prevData) => {
    const {
      shipmentCharge,
      prevTotal,
      usedPoints,
      usedWallet,
      discount,
      paidAmount,
    } = prevData;

    setEditPaymentForm({
      ...editPaymentForm,
      shippingCharge: shipmentCharge,
      rewardPoints: usedPoints,
      wallet: usedWallet,
      discount,
      paidAmount: paidAmount.total,
    });
  };

  console.log(editPaymentForm);
  //Calculate total amount
  const calculateTotalAmount = () => {
    let total =
      parseFloat(subTotal) +
      parseFloat(editPaymentForm.shippingCharge) -
      parseFloat(editPaymentForm.discount);
    if (isNaN(total)) setTotal(0);
    else setTotal(total);
  };

  //Calculate Change and Balance
  const calculateChangeOrBalanceAmount = (totalAmt = 0, paidAmt = 0) => {
    let change = 0,
      balance = 0;

    if (paidAmt > totalAmt) {
      change = parseFloat(paidAmt) - parseFloat(totalAmt);
    } else if (paidAmt < totalAmt) {
      balance = parseFloat(totalAmt) - parseFloat(paidAmt);
    }

    return { change, balance };
  };
  //create payment method 
  const createPaymentMethod=()=>{
    let paymentMethod=null
    // console.log(prevPaymentObject);
    if(prevPaymentObject!== null)
    {
      // console.log('Fn entered..');
      paymentMethod=[
        ...prevPaymentObject,
        {
          type: "Cash",
          account: null,
          date: `${today} ${getCurrentTime()}`,
          paidAmount:paymentSection === "salesReturn"? parseFloat(editPaymentForm.paidAmount) : parseFloat(editPaymentForm.paidAmount) - parseFloat(prevPaymentForm.paidAmount.total),
          vendor: null,
        },
      ];
    }
    else
    {
      paymentMethod=[
        {
          type: "Cash",
          account: null,
          date: `${today} ${getCurrentTime()}`,
          paidAmount:parseFloat(editPaymentForm.paidAmount),
          vendor: null,
        },
      ]
    }

    return paymentMethod

  }
  //inject payment method to body

  const createFinalBody =()=>{
    if(bodyWoPayment !== undefined){

      if(paymentSection === "salesReturn"){
        if (returnPaymnetType === "customer") {
          bodyWoPayment.paymentMethod = createPaymentMethod();
          bodyWoPayment.billPaymentMethod=null;
        }
        if(returnPaymnetType === "company") {
          bodyWoPayment.billPaymentMethod=createPaymentMethod();
          bodyWoPayment.paymentMethod = null
        }
      }
      else{
        bodyWoPayment.paymentMethod = createPaymentMethod();
      }
      bodyWoPayment.shipmentCharge= Number(editPaymentForm.shippingCharge)
      bodyWoPayment.discount= Number(editPaymentForm.discount)
      bodyWoPayment.isWallet=isWallet
    }
  }

  createFinalBody()
 
  
  
  //Final Update Btn Fn
  const updatePayment = () => {
    finalAPICall(
      bodyWoPayment,
      setIsLoading,
      setOpenSuccessSnack,
      setOpenErrorSnack,
      setSnackMsg,
      updateListener,
      clearWorkOrderState,
      clearEditForms,
      clearPaymentDialog(),
      closePayments()
    );
    console.log(bodyWoPayment);
  };

  const addToWallet=()=>{
    setIsWallet(true)
    setTimeout(()=>{
      updatePayment()
    },1000)
  }

  const updatePaymentForCustomer=()=>{
    if(returnPaymnetType === "customer"){
       if(window.confirm("Would you like to add amount to customer wallet")===true){
        addToWallet()
       }
       else{
        updatePayment()
       }
    }
  }

  //Call assign prev values to states
  useEffect(() => {
    if (prevPaymentForm !== undefined) {
      assignFormPropsToStates(prevPaymentForm);
    }
  }, [prevPaymentForm]);

  //Calculate Total amount
  useEffect(() => {
    calculateTotalAmount();
  }, [subTotal, editPaymentForm.shippingCharge, editPaymentForm.discount]);

  //Call Calculate change and balance
  useEffect(() => {
    let { change, balance } = calculateChangeOrBalanceAmount(
      total,
      editPaymentForm.paidAmount
    );
    setChange(change);
    setBalance(balance);
  }, [total, editPaymentForm.paidAmount]);

  const clearPaymentDialog=()=>{
    setEditPaymentForm(editPaymentFormInitialState)
  }

  return (
    <>
      <style type="text/css" media="print">
        {
          "\
           @page { size: portrait; }\
          "
        }
      </style>
      <div
        className="payments-parent-overlay"
        // style={{ width: parentWidth, overflowY: "hidden" }}
      >
        <div
          className="work-order-payments"
          id="editPaymentDiv"
            style={{
              marginTop: marginTop,
              width: width,
              left: left,
              right: right,
              boxShadow: boxShadow,
              zIndex: zIndex,
            }}
        >
          <div
            className="work-order-payment-top-container"
            id="editWorkOrderPaymentTop"
          >
            <h3>Payments</h3>
            <div
              onClick={closePayments}
              className="work-order-payments-close-icon"
            >
              <CloseIcon />
            </div>
          </div>
          <div className="work-order-payment-second-container">
            <div className="work-order-payments-input-container sub-total-container">
              <p>Sub Total</p>
              <input
                // value={values?.subTotal}
                value={subTotal || 0}
                // onChange={onChange != undefined && onChange("subTotal")}
                disabled
              />
              {/* {paymentSection === "delivery" && (
                <p className="delivery-total-wo-amount">
                  Total order amount :{" "}
                  <span style={{ color: "#2f0ae9" }}>
                    {totalOrderAmount?.toFixed(2) || 0}
                  </span>
                </p>
              )} */}
            </div>

            <div className="work-order-payments-input-container">
              <p>Shipping Charge</p>
              <input
                type={"text"}
                value={editPaymentForm.shippingCharge}
                onChange={getPaymentsForm("shippingCharge")}
              />
            </div>
            <div className="work-order-payments-input-container inner-btn">
              <p>Coupon Code</p>
              <input
                disabled
                value={editPaymentForm.couponCode}
                onChange={getPaymentsForm("couponCode")}
                placeholder="coupon code..."
                // onChange={onChange != undefined && onChange("couponCode")}
              />
              <button
                className="btn btn-primary payment-coupon-btn"
                // onClick={couponApply}
                disabled
              >
                Apply
              </button>
            </div>
            <div className="work-order-payments-input-container inner-btn">
              <p>Reward Points</p>
              <input
                disabled
                value={editPaymentForm.rewardPoints}
                onChange={getPaymentsForm("rewardPoints")}
                // onChange={onChange != undefined && onChange("rewardPoints")}
              />
              <button
                className="btn btn-primary payment-reward-btn"
                // onClick={rewardAdd}
                disabled
              >
                Redeem
              </button>
            </div>
            <div className="work-order-payments-input-container">
              <p>Discount</p>
              <input
                value={editPaymentForm.discount}
                onChange={getPaymentsForm("discount")}
                // onChange={onChange != undefined && onChange("paidAmount")}
              />
            </div>
            <div className="work-order-payments-input-container">
              <p>Paid Amount</p>
              <input
                value={editPaymentForm.paidAmount}
                onChange={getPaymentsForm("paidAmount")}

                // onChange={onChange != undefined && onChange("paidAmount")}
              />
            </div>
            <div className="work-order-payments-input-container inner-btn">
              <p>Wallet</p>
              <input
                // disabled={isRedeemed}
                value={editPaymentForm.wallet}
                onChange={getPaymentsForm("wallet")}
                // onChange={onChange != undefined && onChange("wallet")}
              />
              <button
                // disabled={isRedeemed}
                className="btn btn-primary payment-wallet-btn"
                // onClick={redeemFromWallet(totalAmount, wallet)}
              >
                Redeem
                {/* {walletBtnTxt} */}
              </button>
            </div>
            <div className="work-order-payments-input-container inner-btn credit-amount-container">
              <p>Credit Amount</p>
              <input
                readOnly
                value={editPaymentForm.credit}
                // onChange={onChange != undefined && onChange("creditAmount")}
              />
            </div>
            <div className="work-order-payments-total-amount-container">
              <p>Total Amount ()</p>
              <p className="payment-total-amount">{total?.toFixed(2)}</p>
            </div>
            <div className="work-order-payments-change-amount-container ">
              <p>Change ()</p>
              <p className="payment-total-amount">{change?.toFixed(2)}</p>
            </div>
            <div className="work-order-payments-change-amount-container ">
              <p>Balance ()</p>
              <p className="payment-total-amount">{balance?.toFixed(2)}</p>
            </div>
            <div className="payment-os-balance">
              <p>
                O/S Balance&nbsp;:&nbsp;0.00
                {/* {osBalance?.toFixed(2)} */}
              </p>
            </div>

            <div className="payment-method-container">
              {/* <div className="payment-method-top-container">
                <p>Payment Method</p>
                <div className="payment-method-checkbox-container">
                  <input
                    // onClick={clickMultipleCheck}
                    // checked={multipleCheck}
                    type="checkbox"
                    // disabled={netCredit > 0 || billingForm?.paidAmount===0||billingForm?.paidAmount===""}
                  />
                  <label>Multiple</label>
                </div>
              </div> */}

              <div className="payment-method-bottom-container">
                <button
                  className="payment-place-order-btn"
                  //   onMouseDown={formPaymentMethodBody}
                  // onMouseUp={createFinalBody}
                  onClick={
                    returnPaymnetType === "customer"?
                    updatePaymentForCustomer
                    :
                    updatePayment
                }
                  // onMouseUp={generateInvoice}
                >
                  {/* {paymentSection === "viewOrders"
                    ? "Pay Balance"
                    : paymentSection === "delivery"
                    ? "Deliver"
                    :paymentSection === "alteration"?
                    "Pay Amount" */}
                    {
                      returnPaymnetType === "customer"?
                      "Pay Customer"
                      :
                      "Update amount"
                    }
                  
                  {/* } */}
                </button>
                {/* <button
                  className="clear-list-button"
                //   onClick={clearBillingForm}
                >
                  Clear List
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
