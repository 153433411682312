import axios from "axios";
import { update_alteration_list } from "../../Redux/Orders/Alteration/allAlterationSlice";
import { update_alteration_id } from "../../Redux/Orders/Alteration/alterationIdSlice";
import { get_single_alteration_list } from "../../Redux/Orders/Alteration/singleAlterationSlice";
import store from "../../Redux/store";
import { HEADERS } from "../UrlAndPaths";
import { update_invoice } from "../../Redux/Orders/Invoice/invoiceSlice";
import { get_all_alteration_id } from "../../Redux/Orders/Alteration/allAlterationIdSlice";
//generate alteration id
export const generateAlterationIdAPICall = async () => {
  await axios
    .get("workorder/generateAlterationId", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_alteration_id({ alterationId: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_alteration_id({ alterationId: undefined }));
      console.error(err.response);
    });
};

// // Get all alterations with filter  and admin view
export const getAllAlterationsAPICall = async (body, setIsLoading) => {
  setIsLoading(true);
  await axios
    .post("workorder/getAllAlterations", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log(res);
        store.dispatch(update_alteration_list({ alterationList: res.data }));
        setIsLoading(false);
      }
    })
    .catch((err) => {
      store.dispatch(update_alteration_list({ alterationList: undefined }));
      console.log(err.response);
      setIsLoading(false);
    });
};

// Get all alterations with filter and without admin view
// export const getAllAlterationsAPICall = async (body,setIsLoading) => {
//   setIsLoading(true)
//   await axios
//     .post("workorder/alterationCommissionList",body,HEADERS)
//     .then((res) => {
//       if (res.status === 200) {
//         store.dispatch(update_alteration_list({ alterationList: res.data }));
//         setIsLoading(false)
//       }
//     })
//     .catch((err) => {
//       store.dispatch(update_alteration_list({ alterationList: undefined }));
//       console.log(err.response);
//       setIsLoading(false)
//     });
// };

//Add Alteration
export const addAlterationAPICall = async (
  body,
  setIsLoading,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg,
  updateListener,
  clearState,
  openInvoiceDialog,
  clearBillingForm,
  setAddAlteration
) => {
  setIsLoading!==undefined && setIsLoading(true)
  await axios
    .post("workorder/addAlteration", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
      setIsLoading!==undefined && setIsLoading(false)

        // console.log("New alteration added successfully");
        setSuccessSnackOpen(true);
        setSnackMsg("Alteration added successfully");
        // setAddAlteration(false);
        updateListener();
        clearState();
        
        clearBillingForm()
        alterationInvoiceAPICall({ _id: res.data?._id },openInvoiceDialog);
        setAddAlteration(false);
      }
    })
    .catch((err) => {
      setIsLoading!==undefined && setIsLoading(false)

      switch (err.response.status) {
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 409:
          setSnackMsg("Conflit!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;

        default:
          setSnackMsg("Something wrong!!");
          break;
      }
      setErrorSnackOpen(true);

      console.error(err.response);
    });
};

// get single alteration api call

export const singleAlterationAPICall = async (body) => {
  await axios
    .post("workorder/getSingleAlteration", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_single_alteration_list({ singleAlterationList: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        get_single_alteration_list({ singleAlterationList: undefined })
      );
      console.log(err.response);
    });
};

// alteration invoice
export const alterationInvoiceAPICall = async (body,InvoiceDialog) => {
  await axios
    .post("workorder/alterationinvoiceView", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_invoice({ invoice: res.data }));
        InvoiceDialog()
      }
    })
    .catch((err) => {
      store.dispatch(update_invoice({ invoice: undefined }));
      console.log(err.response);
    });
};

// get all alteration id
export const getAllAlterationAPICall = async (body) => {
  await axios
    .post("workorder/getalterationno", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_all_alteration_id({ allAlterationIdData: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(get_all_alteration_id({ allAlterationIdData: undefined }));
      console.log(err.response);
    });
};

//Edit alteration
export const editAlterationAPICall = async (
  body,
  setIsLoading,
  setOpenSuccess,
  setOpenErrorSnack,
  setSnackMsg,
  updateListener,
  clearState
) => {
  setIsLoading(true);
  await axios
    .post("workorder/editAlteration", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setIsLoading(false);
        setSnackMsg("Alteration Edit Success..");
        setOpenSuccess(true);
        updateListener();
        clearState();
      }
    })
    .catch((err) => {
      setSnackMsg(err.response.data?.msg);
      switch (err.response.status) {
        case 500:
            setSnackMsg('Server Error!!')
          break;
        case 409:
            setSnackMsg('Conflict!!')
          break;
      
        default:
          break;
      }
      setIsLoading(false);
      setOpenErrorSnack(true);
      console.error(err.response);
    });
};

// alteration pay  balance api call
export const alterationPayBalanceAPICall=  async (
  body,
  setIsLoading,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates,
  openInvoiceDialog,
  clearBillingStates
)=>{
  setIsLoading(true);
  await axios.post("workorder/updatealtpayment",body,HEADERS)
  .then((res)=>{
    if(res.status === 200){
      console.log("Payment Completed");
        setSnackMsg("Payment updated 👍");
        clearStates();
        updateListener();
        setSuccess(true);
        setIsLoading(false);
        clearBillingStates();
        alterationInvoiceAPICall({_id:res?.data?.workorder?._id},openInvoiceDialog)
    }
  })
  .catch((err)=>{
    switch (err.response.status) {
      case undefined:
        setSnackMsg("Network error!!");
        break;
      case 404:
        setSnackMsg("Shift is inactive!!");
        break;
      case 401:
        setSnackMsg("Unauthorized!!");
        break;
      case 403:
        setSnackMsg("Forbidden!!");
        break;
      case 422:
        setSnackMsg("Payment Failed!!");
        break;
      case 500:
        setSnackMsg("Server error!!");
        break;

      default:
        setSnackMsg("Something wrong!!");
        break;
    }
    setIsLoading(false);
    setError(true);
    console.error(err.response);
  })
}
