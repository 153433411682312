import React, { useEffect, useRef, useState } from "react";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { accountRecievableAgingReport } from "./partnerReportApi";
import { useDispatch, useSelector } from "react-redux";
import { today } from "../../../../../Js/Date";
import { useNavigate } from "react-router-dom";
import { setAccountReceivableAgingReportSingleView } from "./partnerReportSlice";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import { convertArrayToExcel, filterObjFromList } from "../../../../../Js/generalFunctions";
import ReactToPrint from "react-to-print";
import Template from "./AccountAgingPrint"
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import store from "../../../../../Redux/store";

const AccountReceivableAgingReport = () => {
  let paymentsPrintNew = useRef();
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue)
const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
);
const [selectedBranch, setSelectedBranch] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [FormDataInfo, setFormDataInfo] = useState([]);
 const [isLoading,setIsLoading] = useState(true)
 const [symbol, setSymbol] = useState(null);

  const { AccountReceivableAgingReportList } = useSelector(
    (res) => res.PartnerReport
  );

  const decimalPosition = localStorage.getItem("decimalPosition");

  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  const initialvalue ={
    label:"Account Payable Aging Report",
    sumOfcurrent:0,
    sumOf31:0,
    sumOf61:0,
    sumOf91:0,
    sumOf121:0,
    sumOf151:0,
    sumOfAbove151:0,
    sumOfTotal:0,
  
  companyLogo: "",
  companyName: "",
  companyAddress: "",
  companyContact: "",
  }
    
    const [data,setData]=useState([])
    const [formValues,setFormValues]=useState(initialvalue)
  
  useEffect(()=>{
    setFormValues({...formValues,
      sumOfcurrent:AccountReceivableAgingReportList?.sumOfcurrent||0,
    sumOf31:AccountReceivableAgingReportList?.sumOf31||0,
    sumOf61:AccountReceivableAgingReportList?.sumOf61||0,
    sumOf91:AccountReceivableAgingReportList?.sumOf91||0,
    sumOf121:AccountReceivableAgingReportList?.sumOf121||0,
    sumOf151:AccountReceivableAgingReportList?.sumOf151||0,
    sumOfAbove151:AccountReceivableAgingReportList?.sumOfAbove151||0,
    sumOfTotal:AccountReceivableAgingReportList?.sumOfTotal||0,
  
      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",
  
    });
    setData(AccountReceivableAgingReportList?.list)
  },[AccountReceivableAgingReportList,companyProfileData])

  const downloadList=()=>{
    convertArrayToExcel(AccountReceivableAgingReportList?.list,"payableReport")
  }
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  useEffect(() => {
    dispatch(setAccountReceivableAgingReportSingleView([]));
    if (FormDataInfo?.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData && accountRecievableAgingReport({
      todayDate: FormDataInfo?.toDate === '' ?`${today} 23:59:59` : `${FormDataInfo.toDate} 23:59:59`,
      // fromDate: FormDataInfo.fromDate,
      // toDate: FormDataInfo.toDate,
      search: FormDataInfo?.search,
    },setIsLoading);
  }
  }, [FormDataInfo]);
  useEffect(() => {
    let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
    setSymbol(currencyObj?.symbol)
}, [companyProfileData, currencyListUpdated])

  const handleRowClick = (value) => {
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("toDate", FormDataInfo.toDate === '' ?`${today} 23:59:59` : `${FormDataInfo.toDate} 23:59:59`);
    if (value) {
      navigate(
        "/userdashboard/accounts/report/partnerReport/AccountReceivableAgingReportSingleView"
      );
      localStorage.setItem("singleViewById", value);
    }
  };

  return (
    <div className="in-or-out-container">
      <div className="in-or-out-report-wrapper">
        <div className="sub-nav-head">
          <div style={{display:"flex",alignItems:"center"}}>
            <div className="path-name-wrapper" style={{ maxWidth: "17em" }}>
              <h3 className="path-name">Account Receivable Aging Report</h3>
            </div>
            <div >
          <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrintNew}
        />

        <Tooltip title="Download">
          <IconButton onClick={downloadList}>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        </div>
          </div>
          <div>
            <CategorySearchandFilter
              onData={handleDataFromChild}
              // isType={true}
              isFromDate={false}
              isBranch={false}
              onBranchSelect={handleBranchSelect}
              // typeList={taxTypeList}
            />
          </div>
        </div>
        <div className="table-wrapper-container">
          <TableContainer sx={{ maxHeight: "75vh" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              className="transaction-table"
              sx={{ padding: 0 }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Customers</TableCell>
                  <TableCell>Current</TableCell>
                  <TableCell>1 to 30</TableCell>
                  <TableCell>31 to 60</TableCell>
                  <TableCell>61 to 90</TableCell>
                  <TableCell>91 to 120</TableCell>
                  <TableCell>121 to 150</TableCell>
                  <TableCell>150 Above</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {AccountReceivableAgingReportList?.list?.length > 0 ? (
                  AccountReceivableAgingReportList?.list?.map((item, index) => (
                    <TableRow
                      key={index}
                      className={index % 2 === 0 ? "even-row " : ""}
                      onClick={() => handleRowClick(item.customerId)}
                    >
                     <TableCell>{item?.customerName}</TableCell>
                      <TableCell className="amount-td">{symbol}&nbsp;{item?.categories?.current?.toFixed(decimalPosition)}</TableCell>
                      <TableCell className="amount-td">{symbol}&nbsp;{item?.categories?.upto30days?.toFixed(decimalPosition)}</TableCell>
                      <TableCell className="amount-td">{symbol}&nbsp;{item?.categories?.upto60days?.toFixed(decimalPosition)}</TableCell>
                      <TableCell className="amount-td">{symbol}&nbsp;{item?.categories?.upto90days?.toFixed(decimalPosition)}</TableCell>
                      <TableCell className="amount-td">{symbol}&nbsp;{item?.categories?.upto120days?.toFixed(decimalPosition)}</TableCell>
                      <TableCell className="amount-td">{symbol}&nbsp;{item?.categories?.upto150days?.toFixed(decimalPosition)}</TableCell>
                      <TableCell className="amount-td">{symbol}&nbsp;{item?.categories?.morethan150days?.toFixed(decimalPosition)}</TableCell>
                      <TableCell className="amount-td">{symbol}&nbsp;{item?.total?.toFixed(decimalPosition)}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell className="no-data-td" colSpan={9}>
                      NO DATA
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter style={{ position: "sticky", bottom: 0 }}>
                <TableRow className="input-and-output-total">
                  <TableCell>Total </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportList?.sumOfcurrent}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportList?.sumOf31}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportList?.sumOf61}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportList?.sumOf91}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportList?.sumOf121}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportList?.sumOf151}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportList?.sumOfAbove151}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportList?.currencySymbol}{" "}
                    {AccountReceivableAgingReportList?.sumOfTotal}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
      <LoadingForm loading={isLoading} />

      <div style={{ display: "none" }}>
  <div ref={(el) => (paymentsPrintNew = el)}>
      <Template  data={data} formValues={formValues}/> 
  </div>
</div>
    </div>
  );
};

export default AccountReceivableAgingReport;
