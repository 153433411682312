import React from "react";
import qrCode from "../../../../Assets/Images/qrCodeImage.png";
import { convertDateFormat } from "../../../../Js/Date";
import Barcode from "react-barcode";

export const SharwaniCuttingSlip = (props) => {
  const { qr, cuttingSlipData, printingObject, copy } = props;
  return (
    <div className="thob-cutting-slip-container">
      <div className="thob-cutting-slip-top-container">
        <div className="thob-cutting-slip-top-first-content">
          <p>{cuttingSlipData?.cusMobile}</p>
          <p className="main-heading-thob-cutting-slip">
            SHARVANI CUTTING SLIP
          </p>
          <h3></h3>
          {copy && <p>Copy</p>}
        </div>
        <div className="thob-cutting-slip-top-second-content">
          <h3>
            {printingObject?.relationName === "null"
              ? cuttingSlipData?.cusName
              : printingObject?.relationName}
          </h3>
          <p>Order Date&nbsp;:&nbsp;{convertDateFormat(cuttingSlipData?.ordDate) || ""}</p>
        </div>
        <div className="thob-cutting-slip-top-third-content">
          <p>{cuttingSlipData?.woNo || ""}</p>
          <p>Delivery Date&nbsp;:&nbsp;{cuttingSlipData?.delDate !== null ? convertDateFormat(cuttingSlipData?.delDate) : ""}</p>
        </div>
        {/* <div className="thob-cutting-slip-top-third-content">
        <p style={{fontSize:"0.9rem"}}>status:</p>
        </div> */}
      </div>
      <hr className="global-hr" />

      {/* input container */}
      <div className="jalabia-cutting-slip-middle-container">
        <div className="jalabia-cutting-slip-middle-input-container">
          <p className="jalabia-cutting-slip-input-value">
            {printingObject?.length || ""}
          </p>
        </div>
        
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p>(Shoulder)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.shoulder || ""}
            </p>
          </div>
       
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p>(Sleeve)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.sleeveFull || ""}
            </p>
          </div>
       
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p>(Chest)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.chest || ""}
            </p>
          </div>
      
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p>(Waist)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.waist || ""}
            </p>
          </div>
       
        <div
          style={{
            flexDirection: "column",
            padding: "0",
            alignItems: "flex-start",
          }}
          className="jalabia-cutting-slip-middle-input-container text-container-jalabial-cutting-slip"
        >
          <p style={{ margin: "0", fontSize: "0.9rem" }}>Color</p>
          <p style={{ margin: "0 auto", fontSize: "1.2rem", fontWeight: 600 }}>
            --
          </p>
        </div>
        
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p>(Seat)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.seat1 || ""}
            </p>
          </div>
       
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p>(Side Loose)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.seat2},{printingObject?.seat3},
              {printingObject?.body || ""}
            </p>
          </div>
       
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p>(Neck)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.neck || ""}
            </p>
          </div>
        
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p>(Cup)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.cupModelName || ""}
            </p>
          </div>
       
        <div className="jalabia-cutting-slip-middle-input-container notes-jalabia-cutting-slip">
          <p>Notes</p>
          <input type="text" name="" id="" />
        </div>
       
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip notes-level-input">
            <p>(Regal Size)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.regal || ""}
            </p>
          </div>
        
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip notes-level-input">
            <p>(Disco)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.disco || ""}
            </p>
          </div>
       
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip notes-level-input">
            <p>(Canvas)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.canvas || ""}
            </p>
          </div>
      
      </div>

      {/* table container */}
      <div className="thob-cutting-slip-middle-table-container">
        <table>
          <thead>
            <tr>
              <th>Selected Cloths</th>
              <th>Qty</th>
              <th>Colour</th>
              <th>Type</th>
              <th>Bottom Model</th>
              <th>Mar Model</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {printingObject?.materials?.map((r, i) => (
              <tr key={i}>
                <td>{r?.mName}</td>
                <td>{r?.qty}</td>
                <td>{r?.color}</td>
                <td>{printingObject?.type?.toUpperCase()}</td>
                <td>{r?.bottomModelName}</td>
                <td>{r?.marModelName}</td>
                <td>{r?.remarks}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* bottom container */}
      <div className="jalabia-cutting-slip-tailor-qrcode-container">
        <div className="jalabia-cutting-slip-tailor-cuttingMaster-container">
          <div className="jalabia-cutting-slip-tailor-container">
            <p
              style={{
                width: "17%",
                display: "flex",
                alignItems: "center",
                fontSize: "0.9rem",
                fontWeight: 600,
              }}
            >
              Tailor
            </p>
            {printingObject?.tailors?.map((tailor,i)=>(
              <div key={i} className="jalabia-cutting-slip-tailor-input">
              <p>{tailor}</p>
            </div>
            ))}
            
          </div>
          <div className="jalabia-cutting-slip-cutting-master-container">
            <p
              style={{
                width: "17%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "0.9rem",
                fontWeight: 600,
              }}
            >
              Cutting Master
            </p>
            <div className="jalabia-cutting-slip-cutting-master-input-container">
              {printingObject?.cuttingMasters?.map((cMaster,i)=><p key={i}>{cMaster}</p>)}
              
            </div>
          </div>
        </div>
        {
           cuttingSlipData?.isCode === true ?
             <div className="jalabia-cutting-slip-qr-code-container">
               <img src={qr} alt="qrCode" />
              </div>
              :
              <div className="jalabia-cutting-slip-qr-code-container" style={{border:"1px solid #fff"}}>
             <Barcode
                value={cuttingSlipData?.barcodeNumber}
                width={1}
                fontSize={16}
                height={60}
                margin={"2px"}
             />
             </div>
        }
        
      </div>
    </div>
  );
};
