import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PurchaseReportNewAPI } from './purchaseReportNewAPI';

const PurchaseReportPrint = (props) => {
    const {  formValues } = props

    const purchaseList = useSelector((state) => state.purchaseRport.purhaseReportNwList);
    const decimalPosition = localStorage.getItem("decimalPosition");

    useEffect(()=>{
        PurchaseReportNewAPI()
    },[])
    return (
        <div className="print-main-div">
             <div className='header-div'>
        <div>
          <img src={formValues?.companyLogo} alt="" style={{height:"62px"}}/>
        </div>
        <div>
          <h1 style={{ fontSize: "larger", margin: "0" }}>Purchase Report</h1>
          {/* <p style={{margin:"0"}}>aaaaaaa</p> */}
        </div>
        <div>
          <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1>
          <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyName}</p>
          <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyAddress}</p>
          <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyContact}</p>
        </div>
      </div>
      
      <div className="global-report-table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th >UOM</th>
                               
                                <th >Purchase Qty</th>
                                <th >Purchase Return Qty</th>
                                <th >Total Quatity</th>
                                <th >Average Unit Cost</th>
                                <th >Average Total Value</th>
                               
                            </tr>
                        </thead>
                        <tbody>
                            {purchaseList?.map((item,index)=>(
                            <tr key={index} >
                                <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.itemName}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.uom}</td>

                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.purchaseQty?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.purchaseRet?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalQuantity?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.cost?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalValue?.toFixed(decimalPosition)}</td>
                            </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={6}>Total</th>
                                <th >{purchaseList?.reduce((acc, row) => acc + row.totalValue, 0)?.toFixed(decimalPosition)}</th>
                              
                            </tr>
                        </tfoot>
                    </table>
                </div>
        </div>
    );
};

export default PurchaseReportPrint;
