import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { Skeleton } from '@mui/material';
import store from '../../../../../Redux/store';
import { get_customer_credit_note_row_data_list } from './customerCreditNotesSlice';
import { CreditNoteFilterAPICall, viewCreditNotesAPICall } from './customerCreditNotesAPI';
import { convertDateFormat, getCurrentTime } from '../../../../../Js/Date';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import CustomPagination from '../../../../Single Components/CustomPagination';
import axios from 'axios';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';

export const CustomerCreditNotes = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const userRole = useSelector((state) => state.userRoleSlice.value)
  const userBranchId = localStorage.getItem("branchId")
  let navigate = useNavigate();
  const tableListData = useSelector((state) => state.customerCreditNoteSlice.apiResList);

  const [isLoading, setIsLoading] = useState(false)
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages =  tableListData?.pages;
  const [selectedBranch, setSelectedBranch] = useState(null);


  const createButton = () => {
    navigate("/userdashboard/accounts/customers/creditNotes/create")

  }
  const handleClickRow = (row) => {
    // store.dispatch(get_customer_credit_note_row_data_list({customerCreditNoteSingleData:row}))
    // navigate("/userdashboard/accounts/customers/creditNotes/create")
    localStorage.setItem("singleViewById", row?._id)
    store.dispatch( setFilterActive(window.location.pathname))
    navigate("/userdashboard/accounts/customers/creditNotes/SingleView")

  }
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };


  useEffect(() => {
    localStorage.removeItem("singleViewById")
    store.dispatch(get_customer_credit_note_row_data_list({ customerCreditNoteSingleData: undefined }))
    // if (userRole === "user") {
    //   viewCreditNotesAPICall({ branchId: userBranchId }, setIsLoading)
    // }
    // else {
    //   viewCreditNotesAPICall({}, setIsLoading)
    // }
  }, [])
  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData && CreditNoteFilterAPICall({
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        branchList: FormDataInfo.branchList,
        status: FormDataInfo.status,
        search: FormDataInfo.search,
        index: currentPage-1,
      }, setIsLoading);
    }
  
  }, [FormDataInfo, currentPage]);


  return (
    <div className='global-page-parent-container'>
      {/* table */}
      <div className='global-white-bg-container'>
      <div className="justify-space-between"
          style={{ gap: "0 12px", display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}
        >
           {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                <h3>Branch name: {selectedBranch?.storeCode}-{selectedBranch?.branchName}</h3>
              </div>
            )} 
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
          <CategorySearchandFilter
            statusOptions={['DRAFT', 'POSTED']}
            onData={handleDataFromChild}
            isStatus={true}
            onBranchSelect={handleBranchSelect}
          />
          <button onClick={createButton} className='create-button-blue'>Create</button>
          </div>
        </div>
        {/* <div className='create-button-blue-container' style={{justifyContent:"flex-end"}}>
        <button onClick={createButton}  className='create-button-blue'>Create</button>
      </div> */}
        <div className='create-button-blue-container' style={{ margin: "0 1%" }}>
          <h3>Credit Notes</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
        </div>
        <div className='new-global-table-container'>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Customer</th>
                <th>Invoice Date</th>
                <th>Invoice Reference</th>
                <th>Total</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody >
              {

                isLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={8}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) :


                tableListData?.list!==undefined && tableListData?.list?.length !==0 ?  tableListData?.list?.slice(0)?.map((r,i)=>(
                  <tr onClick={()=>handleClickRow(r)}>
                    <td>{convertDateFormat(r?.accountingDate)}</td>
                    <td>{r?.seqName}</td>
                    <td>{r?.vendorName}</td>
                    <td>{convertDateFormat(r?.invoiceDate)}</td>
                    <td>{r?.invoiceRef}</td>
                    <td>{r?.total || "---"}</td>
                    <td>{r?.createdBy || "---"}</td>
                    <td className='table-data-box'>
                      {r?.status === "DRAFT" ? (
                        <span className='draft'> DRAFT</span>
                      ) : r?.status==="POSTED"? (
                        <span className='post'> POST</span>
                      ) : (
                        <span>{r?.status}</span>
                      )}
                    </td>
                  </tr>
                ))
                :
                <tr>
                <td colSpan={8}>No Data</td>
              </tr>

              }




            </tbody>

          </table>
        </div>
      </div>

    </div>
  )
}
