import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import store from "../../../../../Redux/store";
import { salesQuotationFilterAPICall } from "./salesQuatationAPI";
import { getAllActiveEmployeesAPICall } from "../../../../../API/Staff/HRM/employeeAPI";
import { viewAllAllowedBranchesAPICall } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import {Skeleton} from "@mui/material";
import { get_sales_order_quot_row_data_list } from "./salesQuatationSlice";
import {
  viewSalesOrdersQuatationAPICall,
} from "./salesQuatationAPI";
import { convertDateFormat, getCurrentTime, previousDate, today } from "../../../../../Js/Date";

import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";

import CustomPagination from "../../../../Single Components/CustomPagination";
import axios from "axios";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";

export const SalesQuotation = () => {
  // State variables
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const [viewOrderStatus, setViewOrderStatus] = useState("select");
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const userBranchId = localStorage.getItem("branchId");
  const filteredItemsInitial = {
    fromDate: previousDate,
    toDate: today,
    branchList: [],
    createdBy: [],
    status: '',
    search: '',
  };
  const [filteredItems, setfilteredItems] = useState(filteredItemsInitial);
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  
  const handlePageChange = (page) => {
    
    setCurrentPage(page);
  };

  // Hooks
  let navigate = useNavigate();
  const tableListData = useSelector((state) => state.salesQuatationSlice.apiResList);
  const filteredData = useSelector((state) => state.salesQuatationSlice.quotationFilter);
  const totalPages = filteredData?.pages; 
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  // Navigation function
  const createBranchTransfer = () => {
    navigate("/userdashboard/sales/orders/quotation/create");
  };

  // Row click handler
  const handleClickRow = (row) => {
    localStorage.setItem("singleViewById", row?._id);
    store.dispatch( setFilterActive(window.location.pathname))
    navigate("/userdashboard/sales/orders/quotation/singleView");
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };



  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&
      salesQuotationFilterAPICall({
        statusType: 1,
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} ${getCurrentTime()}`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} ${getCurrentTime()}`,
        createdBy: FormDataInfo?.createdBy,
        branchList: FormDataInfo?.branchList,
        status: FormDataInfo?.status?.length === 0 ? '' : FormDataInfo?.status[0],
        search: FormDataInfo?.search,
        index: currentPage-1,
      },setIsLoading);
    }
  
  }, [FormDataInfo, currentPage]);

  useEffect(() => {
    getAllActiveEmployeesAPICall();
    viewAllAllowedBranchesAPICall();
    setViewOrderStatus("select");
    // if (userRole === "user") {
    //   viewSalesOrdersQuatationAPICall({ branchId: userBranchId }, setIsLoading);
    // } else {
    //   viewSalesOrdersQuatationAPICall({}, setIsLoading);
    // }
    localStorage.setItem("singleViewById", "");
    store.dispatch(
      get_sales_order_quot_row_data_list({
        orderQuotationSingleData: undefined,
      })
    );
  }, []);
  return (
    <div className="global-page-parent-container">
      {/* table */}
      <div className="global-white-bg-container">
      <div className="justify-space-between"
          style={{ gap: "0 12px",  display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
        >
           {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                <h3>Branch name: {selectedBranch?.storeCode}-{selectedBranch?.branchName}</h3>
              </div>
            )}
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
          <CategorySearchandFilter
            statusOptions={[ 'DRAFT','CANCEL']}
            onData={handleDataFromChild}
            isCreatedBy={true}
            isStatus={true}
            onBranchSelect={handleBranchSelect} 
            />
          <button
            onClick={createBranchTransfer}
            className="create-button-blue"
          >
            Create
          </button>
          </div>
        </div>
        <div className="create-button-blue-container">
          <h3>Quotation</h3>
          <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          setFormDataInfo={setFormDataInfo}
        />
        </div>
        <div className="new-global-table-container">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Customer</th>
                <th>Total</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : filteredData?.list !== undefined && filteredData?.list?.length !== 0 ? (
                filteredData?.list
                  ?.slice(0)
                  
                  ?.map((r, i) => (
                    <tr onClick={() => handleClickRow(r)}>
                      <td>{r?.NAME}</td>
                      <td>{convertDateFormat(r?.quotationDate)}</td>
                      <td>{r?.CUSTOMER}</td>
                      <td>{r?.total}</td>
                      <td>{r?.CREATEDBY || ""}</td>
                      <td className="table-data-box">
                        {r?.status === "DRAFT" ? (
                          <span className="draft"> DRAFT</span>
                        ) : r?.status === "POSTED" ? (
                          <span className="post"> POST</span>
                        ) : r?.status === "CANCEL" ? (
                          <span className="cancel">CANCEL</span>
                        ) : (
                          <span>{r?.status}</span>
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={6}>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
