import axios from "axios"
import { HEADERS, HEADER_FORM_DATA } from "../../../../../API/UrlAndPaths"
import store from "../../../../../Redux/store"
import { get_inventory_adjustment_list, get_inventory_adjustment_single_view, get_inventory_adjustment_table_data } from "./InventoryAdjustmentSlice"

export const viewInventoryAdjustmentAPICall=(body,setIsLoading)=>{
    setIsLoading!==undefined&&setIsLoading(true)
          axios.post("inventory/viewInventoryAdjustment",body,HEADERS)
          .then((res)=>{
            if(res.status===200){
              store.dispatch(get_inventory_adjustment_list({inventoryAdjustmentData:res.data}))
              setIsLoading!==undefined&&setIsLoading(false)
            }
          })
          .catch((err)=>{
            store.dispatch(get_inventory_adjustment_list({inventoryAdjustmentData:undefined}))
            setIsLoading!==undefined&&setIsLoading(false)
          })
}
// export const InventoryAdjustmentFilterAPICall =(body,setIsLoading)=>{

//     setIsLoading!==undefined&&setIsLoading(true)
//       axios.post("inventory/viewInventoryAdjustment",body,HEADERS)
//       .then((res)=>{
//         if(res.status===200){
//           store.dispatch(get_inventory_adjustment_list({inventoryAdjustmentData:res.data}))
//           setIsLoading!==undefined&&setIsLoading(false)
//         }
//       })
//       .catch((err)=>{
//         store.dispatch(get_inventory_adjustment_list({inventoryAdjustmentData:undefined}))
//         setIsLoading!==undefined&&setIsLoading(false)
//       })
    
//     }

// inventory adjustment single view api call 
export const viewSingleInventoryAdjustmentAPICall=(body)=>{
    axios.post("inventory/singleViewInventoryAdjustment",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_inventory_adjustment_single_view({singleinventoryAdjustmentrData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_inventory_adjustment_single_view({singleinventoryAdjustmentrData:undefined}))
    })
}
export const startInventoryAdjuustmentAPICall=(
    body,
    setInventoryAdjForm,
    inventoryAdjForm,setState,state,setStartClicked,setLoadingForm
    )=>{
        setLoadingForm!==undefined && setLoadingForm(true)

    axios.post("inventory/invAdjProductListing",body,HEADERS)
    .then((res)=>{
        if(res.status==200){
            setLoadingForm!==undefined && setLoadingForm(false)

            store.dispatch(get_inventory_adjustment_table_data({inventoryAdjTableData:res.data}))
            setInventoryAdjForm({...inventoryAdjForm,status:"INPROGRESS",inProgressEdited:true})
            setStartClicked(true)
        }
    })
    .catch((err)=>{
        setLoadingForm!==undefined && setLoadingForm(false)

        if (err.response.status === 500) {
            setState({ ...state, message: "Internal Server error !", error: true })
        } else {
            setState({ ...state, message: err?.response?.data, error: true })
        }
    })
}

export const addInventoryAdjustmentAPICall=(body,draftFn,setState,state,setEditDataId,setLoadingForm)=>{
    setLoadingForm!==undefined && setLoadingForm(true)

    axios.post("inventory/addInventoryAdjustment",body,HEADER_FORM_DATA)
    .then((res)=>{
        if(res.status===200){
            setState({...state,error:false,message:"Inventory Adjustment Drafted",success:true})
            draftFn()
            setEditDataId(res.data?._id)
            localStorage.setItem("singleViewById",res.data?._id)
            setLoadingForm!==undefined && setLoadingForm(false)

        }
    })
    .catch((err)=>{
        if (err.response.status === 500) {
            setState({ ...state, message: "Internal Server error !", error: true })
          } else {
            setState({ ...state, message: err?.response?.data, error: true })
          }
        setLoadingForm!==undefined && setLoadingForm(false)
    })
}
export const postInventoryAdjustmentApicall=(body,postFn,setState,state,setLoadingForm)=>{
    setLoadingForm!==undefined && setLoadingForm(true)

   axios.post("inventory/postInventoryAdjustment",body,HEADER_FORM_DATA)
   .then((res)=>{
    if(res.status===200){
        setState({...state,error:false,message:"Inventory Adjustment Posted",success:true})
        postFn()
        setLoadingForm!==undefined && setLoadingForm(false)
    }
   })
   .catch((err)=>{
       if (err.response.status === 500) {
           setState({ ...state, message: "Internal Server error !", error: true })
       } else {
           setState({ ...state, message: err?.response?.data, error: true })
       }
        setLoadingForm!==undefined && setLoadingForm(false)
   })
}
export const requestInventoryAdjustmentApicall=(body,postFn,setState,state,setLoadingForm)=>{
    setLoadingForm!==undefined && setLoadingForm(true)

   axios.post("inventory/requestInventoryAdjustment",body,HEADERS)
   .then((res)=>{
    if(res.status===200){
        setState({...state,error:false,message:"Request sended",success:true})
        postFn()
        setLoadingForm!==undefined && setLoadingForm(false)

    }
   })
   .catch((err)=>{
    setLoadingForm!==undefined && setLoadingForm(false)

    if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
   })
}
export const editInventoryAdjustmentAPICall=(body,postFn,setState,state,setLoadingForm)=>{
    setLoadingForm!==undefined && setLoadingForm(true)
    axios.post("inventory/editInventoryAdjustment",body,HEADER_FORM_DATA)
    .then((res)=>{
        if(res.status===200){
            setState!==undefined && setState({...state,error:false,message:"Inventory Adjustment edited",success:true})
            postFn!==undefined &&  postFn()
            setLoadingForm!==undefined && setLoadingForm(false)
        }
    })
    .catch((err)=>{
        if (err.response.status === 500) {
            setState({ ...state, message: "Internal Server error !", error: true })
          } else {
            setState({ ...state, message: err?.response?.data, error: true })
          }
        setLoadingForm!==undefined && setLoadingForm(false)
    })
}


export const cancelInventoryAdjAPICall=(body,postFn,setState,state,setLoadingForm)=>{
    setLoadingForm!==undefined && setLoadingForm(true)

    axios.post("inventory/cancelInventoryAdjustment",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            setLoadingForm!==undefined && setLoadingForm(false)

            setState({...state,error:false,message:"Inventory Adjustment Cancelled",success:true})
            postFn()
        }
    })
    .catch((err)=>{
        setLoadingForm!==undefined && setLoadingForm(false)

        if (err.response.status === 500) {
            setState({ ...state, message: "Internal Server error !", error: true })
          } else {
            setState({ ...state, message: err?.response?.data, error: true })
          }
    })
}

// api to import inv adjustment

export const invAdjustmentImportAPICall = async (body,setSnackBarStates,snackBarStates,closeImportPopup,setLoadingForm) => {
    setLoadingForm!==undefined && setLoadingForm(true)

    try {
      const response = await axios.post("inventory/exportInventoryAdjustment", body, {
        ...HEADERS,
        responseType: 'blob', // Specify response type as blob
      });
  
      // Check if the response is successful
      if (response.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)

        // Convert response to blob
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
  
        // Create a temporary URL for the blob
        const url = URL.createObjectURL(blob);
  
        // Create a link element and trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'inventoryadjustment.xlsx'; // Set the desired filename
        document.body.appendChild(a);
        a.click();
  
        // Cleanup: remove the link and revoke the URL
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        closeImportPopup()
        setSnackBarStates({...snackBarStates,message:"File exported successfully",success:true})
      }
    } catch (error) {
        setLoadingForm!==undefined && setLoadingForm(false)

        if (error.response.status === 500) {
            setSnackBarStates({ ...snackBarStates, message: "Internal Server error !", error: true })
          } else {
            setSnackBarStates({ ...snackBarStates, message: error?.response?.data, error: true })
          }
        }
  };


//   export a file
export const exportInvAdjAPICall=async(body,setSnackBarStates,snackBarStates,closeFn,setStartClicked,setLoadingForm)=>{
    setLoadingForm!==undefined && setLoadingForm(true)
    
    await axios.post("inventory/importInventoryAdjustment",body,HEADER_FORM_DATA)

    .then((res)=>{
        if(res.status===200){
            setSnackBarStates({...snackBarStates,message:"File imported successfully",success:true})
            closeFn()
            // setTableData(res?.data?.productInfo || [])
            store.dispatch(get_inventory_adjustment_table_data({inventoryAdjTableData:res.data?.productInfo}))
            setStartClicked(true)
            setLoadingForm!==undefined && setLoadingForm(false)

        }
    })
    .catch((error)=>{
        if (error.response.status === 500) {
            setSnackBarStates({ ...snackBarStates, message: "Internal Server error !", error: true })
          } else {
            setSnackBarStates({ ...snackBarStates, message: error?.response?.data, error: true })
          }
        
        setLoadingForm!==undefined && setLoadingForm(false)

    })
}

export const deleteInventaryAdjustmentAPICall=async(body)=>{
    await axios.post("inventory/deleteInventoryAdjustment",body,HEADERS)
      .then((res)=>{
        if(res.status===200){
            viewInventoryAdjustmentAPICall()
        }
      })
      .catch((err)=>{
        console.error(err.response);
      })
  }