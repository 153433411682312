import React, { useEffect, useRef } from "react";
import "./posReaderPreviewStyle.css";
import DownloadSvg from "./components/icon/DownloadSvg";
import PrintSvg from "./components/icon/PrintSvg";

import textileErpImg from "../../../../Assets/Images/textile-erp-img.png";
import restaurantErpImg from "../../../../Assets/Images/restaurant-erp-img.png";
import supermarketErpImg from "../../../../Assets/Images/supermarket-erp-img.png";
import autopartsErpImg from "../../../../Assets/Images/autoparts-erp-img.png";
import PosReceiptTemplate from "../../components/template/posReceipt/PosReceiptTemplate";
// import { switch_to_billing_screen } from "../../Sales/Billing/newBillingSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Template1 from "../../SalesNew/SalesOrders/SalesQuotation/Template1";
import Template2 from "../../SalesNew/SalesOrders/SalesQuotation/Template2";
import { useState } from "react";
import { getQrCodeData } from "./posReaderPreviewApi";
import { filterObjFromList } from "../../../../Js/generalFunctions";
import ReactToPrint from "react-to-print";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { getTemplateAPI } from "../../SalesNew/SalesOrders/SalesQuotation/salesQuatationAPI";

const PosReaderPreview = () => {
  const staticData = [
    {
      name: "SUPERMARKET ERP",
      url: supermarketErpImg,
      description: `EYE-T ERP An Innovative software is a comprehensive solution that
      integrates all the aspects of your supermarket business, such as
      procurement, inventory, warehouse, sales, accounting, marketing, and more.`,
    },
    {
      name: "AUTOPARTS ERP",
      url: autopartsErpImg,
      description: `Enhance the perforr-nance of vour restaurant with our Restaurant E Ettorttosstv manage orders.
      inventory. handle billing and maintain customar relationships to ensure smooth
      operations and outstanding service.`,
    },
    {
      name: "TEXTILE ERP",
      url: textileErpImg,
      description: `Optimize your textile manufacturing processes with our Textile ERP,
      tailored to meet the unique demands of the textile industry.`,
    },
    {
      name: "RESTAURANT ERP",
      url: restaurantErpImg,
      description: `Enhance the performance of your restaurant with our Restaurant ERP.
      Effortlessly manage orders, track inventory, handle billing. and maintain
      customer relationships to ensure smooth operations and outstanding service-`,
    },
  ];
  let componentRef = React.createRef();
  const downloadPdfRef = useRef();

  // const dispatch = useDispatch();
  const [symbol, setSymbol] = useState(null);
  useEffect(() => {
    // dispatch(switch_to_billing_screen({ billingBoolean: true }));
  }, []);

  const onProductClick = () => {
    window.open("https://eyeterp.ae/", "_blank");
  };

  const currencyListUpdated = useSelector(
    (state) => state?.viewUpdatedCurrency?.value
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const { qrCodeData } = useSelector((state) => state?.posReaderPreviewSlice);

  const formValuesInitialState = {
    status: "",
    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
    companyTRN: "",

    customerName: "",
    customerAddress: "",
    customerContact: "",

    dateN1: "Quotation Date",
    dateN2: "Expiry Date",

    lpo: "",
    ref: "",
    name: "",
    firstDate: "",
    secondDate: "",

    termsAndCondition: "",

    address: "Vendor ",
    sequence: "PO No",
    subtotal: 0,
    inclusiveTax: 0,
    exclusiveTax: 0,
    total: 0,
    totalAmount: "",
    payTerm: "",
    label: "",
  };
  const [formValues, setFormValues] = useState(formValuesInitialState);
  const [data, setData] = useState();
  const [tax, setTax] = useState([]);
  const [domain, setDomain] = useState("");

  useEffect(() => {
    if (qrCodeData) {
      let invoiceData = qrCodeData?.datatoinvoice;
      let adminData = qrCodeData?.admin;
      let customer = qrCodeData?.customer;

      setFormValues({
        ...formValues,
        label: qrCodeData?.type,
        name:
          qrCodeData?.NAME || invoiceData?.NAME || invoiceData?.sequence || "",
        status: invoiceData?.status,
        companyLogo: adminData?.imageUrl || "",
        companyName: adminData?.companyName || "",
        companyAddress: companyProfileData?.companyAddress || "",
        companyContact: adminData?.contactNo || "",
        companyTRN: adminData?.trn || "",

        ref: invoiceData?.reference || "",
        lpo: invoiceData?.LpoNo || "",

        customerName: qrCodeData?.customer?.name || "",
        customerAddress: invoiceData?.vendorAddress?.streetName || "",
        customerContact: invoiceData?.vendorAddress?.mobileNo || "",
        trn: invoiceData?.vendorAddress?.TRN || "",
        // name: invoiceData?.sequence || "",
        firstDate: invoiceData?.quotationDate || "",
        secondDate: invoiceData?.expiryDate || "",
        payTerm: invoiceData?.PAYMENTTERM || "",

        termsAndCondition: invoiceData?.termsAndConditions || "",

        subtotal: invoiceData?.subTotal || 0,
        inclusiveTax: invoiceData?.totalTaxInclusive || 0,
        exclusiveTax: invoiceData?.totalTaxExclusive || 0,
        total: invoiceData?.total || 0,
        totalAmount: invoiceData?.totalAmount || "",
        qrcodetype: invoiceData?.qrcodetype || "",
        supplierId: invoiceData?.supplierId || invoiceData?.customerId || "",
        db: invoiceData?.db || "",
        _id: invoiceData?._id || "",
        address: "",
        sequence: "Order No",
        // sequence: invoiceData?.NAME || invoiceData?.sequence,
        // dateN1: invoiceData?.dateN1||"",
        // dateN2: invoiceData?.dateN2||"",
      });
      setData(invoiceData?.productInfo || invoiceData?.salesInfo);
      setTax(invoiceData?.taxDetails);
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      const db = queryParams.get("db");
      const vendororcusid = queryParams.get("vendororcusid");
      const type = queryParams.get("type");
      const orderId = queryParams.get("orderId");

      const domainAddress = `${window.location.protocol}//${
        window.location.host
      }/posReaderPreview?path=admin/viewqrcodedata&db=${encodeURIComponent(
        db
      )}&vendororcusid=${encodeURIComponent(
        vendororcusid
      )}&type=${encodeURIComponent(type)}&orderId=${encodeURIComponent(
        orderId
      )}`;

      setDomain(domainAddress);

      const body = {
        param: `db=${db}&vendororcusid=${vendororcusid}&type=${type}&orderId=${orderId}`,
      };
      // console.log(body);
      getQrCodeData(body);
    }
  }, [qrCodeData]);

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    setSymbol(currencyObj?.symbol);
    // setUnit(currencyObj?.currencyUnit);
  }, [companyProfileData, currencyListUpdated]);

  const handleDownload = () => {
    const content = downloadPdfRef.current;

    html2canvas(content, { scale: 2 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: "a4",
        });

        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save(`${qrCodeData?.type}.pdf`);
      })
      .catch((error) => {
        console.error("Error generating canvas:", error);
      });
  };

  return (
    <div className="pos-reader-preview-container">
      <div className="pos-reader-wrapper">
        {/* <--download file--> */}
        <div>
          <div className="r-download-container">
            <div>
              <span className="r-amount-h">
                {qrCodeData?.customer?.name ||
                  qrCodeData?.datatoinvoice?.CUSTOMER ||
                  qrCodeData?.datatoinvoice?.VENDOR}
              </span>
              <span className="r-amount-h">
                {qrCodeData?.admin?.trn || qrCodeData?.datatoinvoice?.NAME}
              </span>
              <span className="r-amount-h">
                {symbol} &nbsp;
                {qrCodeData?.datatoinvoice?.totalAmount}
              </span>
            </div>
            <div>
              <button
                onClick={() => handleDownload()}
                className="r-btn-download"
              >
                <DownloadSvg /> <span>DOWNLOAD</span>
              </button>

              <ReactToPrint
                trigger={() => (
                  <button className="r-btn-print">
                    <PrintSvg /> <span>PRINT</span>
                  </button>
                )}
                content={() => componentRef}
              />
            </div>
          </div>
          <div className="r-list-sale-product">
            {staticData.map((res, i) => (
              <div
                key={i}
                onClick={() => onProductClick()}
                className="r-product-container"
              >
                <div className="r-p-img">
                  <img src={res.url} alt="" />
                </div>
                <div className="r-p-content">
                  <span className="r-content-head">{res.name}</span>
                  <span className="r-content-body">{res.description}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <--pos-receipt-preview--> */}
        <div>
          <div className="r-preview-pos-receipt">
            {/* <PosReceiptTemplate receiptData={[]} ref={componentRef} /> */}
            <div ref={(el) => (componentRef = el)}>
              <div ref={downloadPdfRef}>
                {qrCodeData?.admin?.templateModel === "template1" ? (
                  <Template1
                    data={data}
                    formValues={formValues}
                    tax={tax}
                    domain={domain}
                    // ref={componentRef}
                  />
                ) : (
                  <Template2
                    data={data}
                    formValues={formValues}
                    tax={tax}
                    domain={domain}
                    // ref={componentRef}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosReaderPreview;
