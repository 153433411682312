import React, { useState } from "react";
import {
  IconButton,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  Menu,
  Dialog,
  createFilterOptions,
  Skeleton,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  deleteCustomerAPICall,
  viewAllCustomersAPICall,
  viewAllCustomerWithFilterAPICall,
} from "../../../API/Customer/customerAPI";
import { KeyboardArrowDown } from "@mui/icons-material";
import { AddCustomer } from "./AddCustomer";
import { viewAllBranchesAPICall } from "../../../API/Settings/Company Settings/companySettingsAPI";
import ErrorSnackbar from "../../Single Components/SnackBars/ErrorSnackbar";
import Swal from "sweetalert2";
import CustomPagination from "../../Single Components/CustomPagination";

export const AllCustomer = () => {
  //*Redux States
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  //List of All Customers
  const customerList = useSelector(
    (state) => state.allCustomerWithFilter.value
  );
  //List of all Branches
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  //store code
  const userLoginMeta = localStorage.getItem('login_meta')

  const storeCode=JSON.parse(userLoginMeta)?.branchPk;




  const AllCustomerForm = {
    status: "null",
    customerId: null,
    mobileNo: "",
    branch: null,
    fromDate: "",
    toDate: "",
  };
  const [customerFilterForm, setCustomerFilterForm] = useState(AllCustomerForm);
  
  const [editCustomerDialog, setEditCustomerDialog] = useState(false);
  const [isDialog, setIsDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editingCusObj,setEditingCusObj]=useState(null)
  const [customerId, setCustomerId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!!");
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const closeErrorSnack = () => {
    setErrorSnackOpen(false);
  };
  // contact dialog
  const [singleCustomerObj, setSingleCustomerObj] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isModify,setIsModify]=useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = customerList?.pages;

  const handlePageChange = (page) => {

    setCurrentPage(page);
  };


  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //updateListener
const updateListener=()=>{
  setIsModify(!isModify)
}


  // extract single customer obj
  const extractSingleCustomerData = (id) => () => {
    let singleCustomerObjArr = [];
    singleCustomerObjArr = customerList?.filter((obj) => {
      if (obj._id === id) {
        return obj;
      }
    });
    setSingleCustomerObj(singleCustomerObjArr);
  };

  //  console.log(singleCustomerObj);

  let navigate = useNavigate();

  const customerSingleViewFn = (custId,branch) => () => {
    localStorage.setItem("custId", custId);
    localStorage.setItem("cusBranchId", branch);
    navigate("/userdashboard/customer/allCustomer/singleCustomer");
  };

  // view all customer with filter api call
  const bodyToApi = {
    status:
      customerFilterForm.status === "null" ? null : customerFilterForm.status,
    cusId: customerFilterForm?.customerId,
    mobileNo:
      customerFilterForm?.mobileNo === "" ? null : customerFilterForm?.mobileNo,
    branchId: customerFilterForm?.branch,
  };

  //*useEffect
  useEffect(() => {
    viewAllCustomerWithFilterAPICall(
      {branchId:userRole==="user"?storeCode:null,cusId:null,status:null,skip:currentPage-1},
      setIsLoading,
      setErrorSnackOpen,
      setSnackMsg
    );
    viewAllBranchesAPICall();
  }, [isModify, currentPage]);

  // submit button function
  const submitBtnFn = () => {
    viewAllCustomerWithFilterAPICall(bodyToApi);
  };

  // edit customer
  const openEditCustomerDialog = (cusData) => {
    setEditCustomerDialog(true);
    setIsDialog(true);
    setIsEdit(true);
    setEditingCusObj(cusData);
  };
  const closeEditDialog = () => {
    setEditCustomerDialog(false);
    setIsDialog(false);
    setIsEdit(false);
  };

  const deletCustomerDataClick=(id)=>()=>{
    Swal.fire({
      title:'Are you sure?',
      text:"You won't be able to revert this!",
      icon: 'warning',
          showCancelButton: true,
         confirmButtonColor: '#f80000',
         confirmButtonText: 'Submit',
         customClass:{
           cancelButton:'sweet-alert-cancel-button',
           confirmButton:'sweet-alert-submit-button',
         }
    }).then((result)=>{
      if(result.isConfirmed){
        console.log(id);
        // deleteCustomerAPICall({_id:id})
         Swal.fire(
            'Deleted!',
            'Customer deleted.',
            'error'
          )
      }
   })
  }




  // extract customer mobileno
  useEffect(() => {
    let singleCustomer = customerId?.filter((obj) => {
      if (obj?._id === customerFilterForm?.customerId) {
        return obj;
      }
    });
    setCustomerFilterForm({
      ...customerFilterForm,
      mobileNo: singleCustomer !== undefined && singleCustomer[0]?.mobileNo,
    });
  }, [customerFilterForm?.customerId]);

  // map customer id to state
  useEffect(() => {
    if (customerId?.length > 0) {
      setCustomerId([...customerId]);
    } else {
      setCustomerId(
        customerList?.map((obj) => {
          return {
            ...obj,
          };
        })
      );
    }
  }, [customerList]);

  //Filter by name and mob no in same input field
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option?.name + option.mobileNo,
  });

  console.log(editingCusObj);


  return (
    <div className="global-page-parent-container">
      {/* <p className="breadcrumb">Customer &gt; All Customer</p> */}


      <div className="global-white-bg-container">
        <div className="all-customer-top-container">
          <h3>All Customer</h3>
          <hr className="global-hr" />
          <div className="add-customer-input-container">
            <div className="global-single-input">
              <p>Status</p>
              <Select
                value={customerFilterForm.status}
                onChange={(e) =>
                  setCustomerFilterForm({
                    ...customerFilterForm,
                    status: e.target.value,
                  })
                }
              >
                <MenuItem value="null">All</MenuItem>
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </div>
            <div className="global-single-input auto-complete">
              <p>Customer ID</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={customerId || [{cusId:'-No data',name:''}]}
                getOptionLabel={(option) => `${option?.cusId}-${option?.name}`}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select CustomerID" />
                )}
                // value={customerFilterForm?.customerId}
                onChange={(e, newValue) =>
                  setCustomerFilterForm({
                    ...customerFilterForm,
                    customerId: newValue?._id,
                  })
                }
                filterOptions={filterOptions}
              />
            </div>
            <div className="global-single-input">
              <p>Mobile No</p>
              <input
                value={
                  customerFilterForm?.mobileNo
                    ? customerFilterForm?.mobileNo
                    : ""
                }
                onChange={(e) =>
                  setCustomerFilterForm({
                    ...customerFilterForm,
                    mobileNo: e.target.value,
                  })
                }
                maxLength="15"
              />
            </div>
            {userRole === "admin" && (
              <div className="global-single-input auto-complete">
                <p>Branch</p>
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={allBranchesList || [""]}
                  getOptionLabel={(option) =>
                    `${option?.storeCode}-${option?.branchName}`
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Branch" />
                  )}
                  onChange={(e, newValue) =>
                    setCustomerFilterForm({
                      ...customerFilterForm,
                      branch: newValue?.storeCode,
                    })
                  }
                />
              </div>
            )}

            {/* <div className="global-single-input">
            <p>From</p>
            <input 
            type="date"
            onChange={(e)=>setCustomerFilterForm({
              ...customerFilterForm,fromDate:e.target.value
            })}
            />
         </div>
         <div className="global-single-input">
            <p>To</p>
            <input 
            type="date"
            onChange={(e)=>setCustomerFilterForm({
              ...customerFilterForm,toDate:e.target.value
            })}
            />
         </div> */}
          </div>
          <div className="add-customer-button-container">
            <button className="btn btn-secondary-outlined">Cancel</button>
            <button onClick={submitBtnFn} className="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </div>

      


      <div className="global-white-bg-container">
      <div className="justify-center" style={{ justifyContent: "flex-end" }}>
                        <CustomPagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}

                        />
                    </div>
        <div className="all-customer-table-top-container">
          <h4>
            {customerFilterForm.status === "All"
              ? "All Customers"
              : customerFilterForm.status === "Active"
              ? "Active Customers"
              : customerFilterForm.status === "Inactive"
              ? "Inactive Customers"
              : undefined}
          </h4>
          {customerFilterForm.status === "Inactive" && (
            <button className="btn btn-primary">Send Message</button>
          )}
        </div>
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>No</th>
                {userRole === "admin" && (
                  <>
                    <th>Branch Code</th>
                    <th>Branch Name</th>
                  </>
                )}

                <th>Customer ID</th>
                <th>Name</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Relationship</th>
                <th>View</th>
                <th>Edit</th>
                <th>Delete</th>
                {customerFilterForm.status === "Inactive" && (
                  <th>
                    Select All{" "}
                    <input
                      type="checkbox"
                      style={{ accentColor: "black", cursor: "pointer" }}
                    />
                  </th>
                )}
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={11}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={11}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={11}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={11}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={11}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={11}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={11}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={11}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {customerList
                  ?.slice(0)
                  ?.reverse()
                  ?.map((r, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      {userRole === "admin" && (
                        <>
                          <td>{r?.storeCode}</td>
                          <td>{r?.BRANCHID}</td>
                        </>
                      )}

                      <td>{r?.cusId}</td>
                      <td>{r?.name}</td>
                      <td>
                        <div className="add-document-documents-btn">
                          <button
                            onMouseDown={extractSingleCustomerData(r._id)}
                            onClick={r?.alternateNumber !== null && handleClick}
                          >
                            {r?.mobileNo}
                            {r?.alternateNumber !== null && (
                              <span style={{ marginTop: "4px" }}>
                                <KeyboardArrowDown />
                              </span>
                            )}
                          </button>
                        </div>
                      </td>
                      <td>{r?.email || "-NIL"}</td>
                      <td>
                        {r?.relation .length!== 0 ? r?.relation[0]?.name : "-NIL-"}
                      </td>
                      <td>
                        <IconButton onClick={customerSingleViewFn(r?._id,r?.branchId)}>
                          <i class="bi bi-eye visibility-icon"></i>
                        </IconButton>
                        </td>
                        <td>
                        <IconButton onClick={() => openEditCustomerDialog(r)}>
                          <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton>
                        </td>
                        <td>
                        <IconButton 
                          onClick={deletCustomerDataClick(r?._id)}
                        >
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                      {customerFilterForm.status === "Inactive" && (
                        <td>
                          <input
                            type="checkbox"
                            style={{ accentColor: "black", cursor: "pointer" }}
                          />
                        </td>
                      )}
                    </tr>
                  )) || (
                  <tr>
                    <td colSpan={9}>No data</td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>

      {/* customer number list */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem>
          {singleCustomerObj !== undefined && singleCustomerObj[0]?.mobileNo}
        </MenuItem>
        <MenuItem>
          {singleCustomerObj !== undefined &&
            singleCustomerObj[0]?.alternateNumber}
        </MenuItem>
      </Menu>

      {/* edit customer */}
      <Dialog
        open={editCustomerDialog}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setEditCustomerDialog(false)}
      >
        <AddCustomer
          isDialog={isDialog}
          isEdit={isEdit}
          closeDialog={closeEditDialog}
          customerPrevData={editingCusObj}
          updateListenerProp={updateListener}
        />
      </Dialog>
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnack}
        message={snackMsg}
      />
    </div>
  );
};
