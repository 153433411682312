import React, { useLayoutEffect, useState } from 'react'
import { convertDateFormat } from '../../../../Js/Date';
import barcode from '../../../../Assets/Images/barcode.png'
import Barcode from 'react-barcode';

export const SalwarCuttingSlip1 = (props) => {
    const {qr,cuttingSlipData,printingObject,copy}=props;
    const [totalQty,setTotalQty]=useState()

    useLayoutEffect(()=>{
       let total=0;
       printingObject?.materials?.forEach((r,i)=>{
        total +=r?.qty
       })
       setTotalQty(total)

    },[printingObject])

    console.log(printingObject);
  return (
    <div className="thob-cutting-slip-container">
      <div className="thob-cutting-slip-top-container">
        <div className="thob-cutting-slip-top-first-content">
          <p></p>
          <p className="main-heading-thob-cutting-slip1">SALWAR CUTTING SLIP</p>
          <h3></h3>
        </div>
        <div className="thob-cutting-slip-top-second-content">
          <div className='thob1-cutting-slip-top-second-content-left'>
            {
              cuttingSlipData?.isCode === false && 
              <Barcode
              value={cuttingSlipData?.barcodeNumber}
              width={1}
              fontSize={16}
              height={30}
              margin={"2px"}
            />
            }
            <p>CUSTOMER:&nbsp;
              {printingObject?.relationName === "null"
              ? cuttingSlipData?.cusName
              : printingObject?.relationName
              ||printingObject === undefined? "ABC":undefined
              }
             </p>
            <p>{cuttingSlipData?.woNo || "WO1234"}</p>
            <p>EMPLOYEE:&nbsp;{cuttingSlipData?.employee||'ABC'}</p>
          </div>
          <div className='thob1-cutting-slip-top-second-content-right'>
            <div></div>
            <div style={{display:"flex",justifyContent:"space-between",height:"25%"}}>
            <p>ORDER DATE:</p>
            <p>{convertDateFormat(cuttingSlipData?.ordDate) || "-NIL-"}</p>
            </div>
            <div style={{display:"flex",justifyContent:"space-between",height:"25%"}}>
            <p>DELIVERY DATE:</p>
            <p>{cuttingSlipData?.delDate !== null ? convertDateFormat(cuttingSlipData?.delDate) : "-NIL-"}</p>
            </div>
          
            
          </div>
        </div>
      </div>
      <hr className='thob1-cutting-slip-border'/>
      <div className="thob-cutting-slip-middle-container">
      <div className="salwar1-cutting-slip-middle-top-container">
         <div className='thob1-cuttinG-slip-single-border-div' style={{width:"20%"}}>
          <p className='salwar1-cutting-slip-left-p'>Length</p>
          <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{printingObject?.length || ""}</p>
         </div>
         <div className='thob1-cuttinG-slip-single-border-div' style={{width:"20%"}}>
          <p className='salwar1-cutting-slip-left-p'>Waist</p>
         <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{printingObject?.waist || ""}</p>
         </div>
         <div className='thob1-cuttinG-slip-single-border-div' style={{width:"20%"}}>
          <p className='salwar1-cutting-slip-left-p'>Bottom</p>
         <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{printingObject?.bottom || ""}</p>
         </div>
         <div className='thob1-cuttinG-slip-single-border-div' style={{width:"20%"}}>
          <p className='salwar1-cutting-slip-left-p salwar-elastic-size'>Elastic Size</p>
         <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{printingObject?.elasticSize || ""}</p>
         </div>
        
       </div>
       <div  className="salwar1-cutting-slip-middle-top-container">
       <div className='thob1-cuttinG-slip-single-border-div' style={{width:"20%"}}>
          <p className='salwar1-cutting-slip-left-p salwar-elastic-size'>Inside Loose</p>
         <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{printingObject?.materials[0]?.remarks}</p>
         </div>
         <div className='thob1-cuttinG-slip-single-border-div' style={{width:"20%"}}>
          <p className='salwar1-cutting-slip-left-p salwar-elastic-size'>Thigh Loose</p>
         <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{ printingObject?.ply || ""}</p>
         </div>
         <div className='thob1-cuttinG-slip-single-border-div' style={{width:"20%"}}>
          <p className='salwar1-cutting-slip-left-p salwar-elastic-size'>Notes</p>
         <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{ printingObject?.thighLoose || ""}</p>
         </div>
         <div className='thob1-cuttinG-slip-single-border-div' style={{width:"20%",border:"none"}}>
         
         </div>
       </div>
       <div className="salwar1-cutting-slip-middle-top-container">
       </div>
       <div className="salwar1-cutting-slip-middle-top-container salwar1-two">
       <div className='thob1-cuttinG-slip-single-border-div' style={{width:"15%"}}>
          <p className='salwar1-cutting-slip-left-p'>QTY</p>
         <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{totalQty}</p>
         </div>
       </div>
       <div className="thob-cutting-slip-middle-table-container salwar-cutting-slip-table-container">
        <table>
          <thead>
            <tr>
              <th style={{ width: "50%" }}>Description</th>
              <th>Qty</th>
              <th>Type</th>
              <th>Model</th>
              <th>MTR per Quantity</th>
              <th>MTR</th>
            </tr>
          </thead>
          <tbody>
            {printingObject?.materials?.map((r, i) => (
              <tr key={i}>
                <td>{r?.mName}</td>
                <td>{r?.qty}</td>
                <td>{r?.elasticWidthNumber}</td>
                <td>SALWAR</td>
                <td>---</td>
                <td>---</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='thob1-cutting-slip-middle-top-nine-container'>
            <p>Cutter:
              {
                printingObject?.cuttingMasters?.map((cMaster, i)=>(
                  <span key={i}>{i ===printingObject?.cuttingMasters?.length-1? cMaster : cMaster +","}</span>
                ))
              } 
              </p>
             
        </div>
      </div>
    </div>
  )
}
