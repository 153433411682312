import React, { useEffect, useState, useRef } from "react";
import "../../../css/Sales/credit.css";
import { CreditAndWallet } from "../../Single Components/CreditAndWallet";
import {
  Autocomplete,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Carousel } from "react-responsive-carousel";
import { CallMadeOutlined, CallReceivedOutlined } from "@mui/icons-material";
import {
  creditAPICall,
  updateCreditPayBalanceAPICall,
} from "../../../API/Sales/creditAPI";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { convertDateFormat, today } from "../../../Js/Date";
import {
  viewAllCreditAPICall,
  viewAllCreditSummary,
} from "../../../API/Credit and Wallet/creditAndWalletAPI";
import { PaymentsWO } from "../Orders/WorkOrder/PaymentsWO";
import SuccessSnackbar from "../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../Single Components/SnackBars/ErrorSnackbar";
import { companyProfileInfoForUser, viewAllBranchesAPICall } from "../../../API/Settings/Company Settings/companySettingsAPI";
import { viewAllCustomersAPICall } from "../../../API/Customer/customerAPI";
import { viewAllCurrencyUpdated } from "../../../API/Settings/Currency/CurrencyAPI";
import { filterObjFromList } from "../../../Js/generalFunctions";
export const Credit = () => {
  let creditPrint = useRef();
  let creditSummaryPrint = useRef();
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  
  // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  //List of All Customers
  const customerList = useSelector((state) => state.allCustomerListSlice.value);

  const userLoginMeta = localStorage.getItem('login_meta')
  const userBranchId=JSON.parse(userLoginMeta)?.branchPk;
  const userRole=useSelector((state)=>state.userRoleSlice.value)
    
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );

  const companyProfileData =useSelector(
    (state) => state?.companyProfileSlice?.userValue)






  // all  credit list
  // const creditList = useSelector((state)=>state.creditSlice.value)
  const creditList = useSelector((state) => state.creditToSupplierSlice.value);
  // credit summary
  const creditSummary = useSelector((state) => state.creditSummary.value);

  // const storeCode = localStorage.getItem("branchId");
  // const userLoginMeta = localStorage.getItem("login_meta");
  // const userBranchpk = JSON.parse(userLoginMeta)?.branchPk;
  const [branch, setBranch] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [filterSelect, setFilterSelect] = useState("null");
  const [toDate, setToDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [time, setTime] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [charFilterData, setCharFilterData] = useState([]);

  const [paymentDialog, setPaymentDialog] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [summary, setSummary] = useState(false);

  // payment dialog states
  const [isMultiplePayment, setIsMultiplePayment] = useState(false);
  //Update Listener state
  const [isModify, setIsModify] = useState(false);
  const [symbol, setSymbol] = useState(null);
  // snakcbar states
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");

  //Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };
  //Close Success Snackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnack(false);
  };
  //Close Error Snackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };

  // get current time fn
  useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }, 1000);
  }, [time]);

  useEffect(() => {
    // creditAPICall(setIsLoading)
    viewAllCreditAPICall({branchId:userBranchId}, setIsLoading);
    viewAllCustomersAPICall({branchId:userRole==="admin"?null:userBranchId})

    viewAllBranchesAPICall();
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);
  useEffect(()=>{
    let currencyObj=filterObjFromList("_id",currencyListUpdated,"currency",companyProfileData)

    setSymbol(currencyObj?.symbol || "")
  },[companyProfileData,currencyListUpdated])
  useEffect(() => {
    if (creditList !== undefined) {
      let charFilterData = creditList?.list?.filter((obj) => {
        if (
          obj.purchaseId?.toLowerCase()?.includes(searchInput.toLowerCase())
        ) {
          return obj;
        }
        if (obj.supplier?.toLowerCase()?.includes(searchInput.toLowerCase())) {
          return obj;
        }
        if (obj.mobileNo?.toLowerCase()?.includes(searchInput.toLowerCase())) {
          return obj;
        } else if (searchInput === "") {
          return obj;
        }
      });
      setFilteredData(charFilterData);
    }
  }, [creditList, searchInput]);

  // open payment dialog
  const openPaymentDialog = (obj) => {
    setPaymentData(obj);
    setPaymentDialog(true);
  };
  const closePaymentDialog = () => {
    setPaymentData(null);
    setPaymentDialog(false);
  };
  const clearState = () => {
    updateListener();
  };
  const getFromDate = (e) => {
    setFromDate(e.target.value);
  };
  const getToDate = (e) => {
    setToDate(e.target.value);
  };

  // sunbmit button function
  const bodyToApi = {
    fromDate: fromDate === "" ? null : fromDate,
    toDate: toDate === "" ? null : toDate,
    branchId:userRole==="admin"?branch:userBranchId,
    cusId: customer,
    status:filterSelect==="null"?null:filterSelect
  };
  const submitButtonFn = () => {
    viewAllCreditAPICall(bodyToApi, setIsLoading);
  };

  const summaryCheckboxCheckFn = () => {
    setSummary(!summary);
  };
  const summaryApiCallFn = () => {
    if (summary === true) {
      viewAllCreditSummary(bodyToApi);
    }
   }
 

  

  
  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">Sales &gt; Credit</p>
      {/* <CreditAndWallet label="Credit"/> */}
      <div className="credit-container-top">
        <div className="credit-container-top-left">
          <Carousel
            useKeyboardArrows={true}
            showStatus={false}
            showThumbs={false}
            //  autoPlay={true}
            // auto
          >
            {/* <CreditAndWallet 
          label="Total" 
          width="100%"
       
          /> */}

            <CreditAndWallet
              label="Work Order Total"
              width="100%"
              background="linear-gradient(148deg, #33528d,#3d76d5)"
              amount={creditList?.totalworkOrder?.toFixed(2)}
            />
            <CreditAndWallet
              label="Sales Total"
              width="100%"
              background="linear-gradient(148deg, #205441,#21a569)"
              amount={creditList?.totalsales?.toFixed(2)}
            />
            <CreditAndWallet
              label="Alteration Total"
              width="100%"
              background="linear-gradient(148deg, #de2235,#a42823)"
              amount={creditList?.totalalteration?.toFixed(2)}
            />
            {/* <CreditAndWallet 
          label="Delivery Total" 
          width="100%"
          background="linear-gradient(148deg, #919fe8,#5347aa)"
          amount={creditList?.totalDelivery?.toFixed(2)}
          /> */}
          </Carousel>
          {/* <CreditAndWallet label="Credit"/> */}
        </div>
        <div className="credit-container-top-right">
          <h4>Total Credit</h4>
          <p>{symbol} {""} {creditList?.totalCredit.toFixed(2)}</p>
          <div className="credit-container-top-right-amount-container">
            <div className="credit-single-amount-container">
              <CallMadeOutlined sx={{ color: "#eb0000", fontSize: "1.3rem" }} />
              <p>{symbol} {""} {creditList?.supplierCreditTotal?.toFixed(2)}</p>
            </div>
            <div className="credit-single-amount-container second-amt-container">
              <CallReceivedOutlined
                sx={{ color: "#00c965", fontSize: "1.3rem" }}
              />

              <p>{symbol} {""} {creditList?.customerCreditTotal?.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="credit-content-container">
        <h3 style={{ margin: "0 1%" }}>Credit</h3>
        <Divider />
        <div className="credit-content-top-container">
          {userRole === "admin" && (
            <div className="global-single-input auto-complete">
              <p>Branch</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={allBranchesList || [""]}
                renderInput={(params) => <TextField {...params} />}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                onChange={(e, newValue) => setBranch(newValue?._id)}
              />
            </div>
          )}

          <div className="global-single-input auto-complete">
            <p>Name</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={customerList || [""]}
              renderInput={(params) => <TextField {...params} />}
              getOptionLabel={(option) => `${option?.cusId}-${option?.name}`}
              onChange={(e, newValue) => setCustomer(newValue?._id)}
            />
          </div>
          <div className="global-single-input">
            <p>Status</p>
            <Select value={filterSelect} onChange={(e)=>setFilterSelect(e.target.value)}>
              <MenuItem value="null">-select-</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="printing">Printing</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="booking">Booking</MenuItem>
              <MenuItem value="delivered">Delivered</MenuItem>
              <MenuItem value="orderReturn">Order Return</MenuItem>
              <MenuItem value="deliveryReturn">Delivery Return</MenuItem>
            </Select>
          </div>
          <div className="global-single-input">
            <p>From</p>
            <input type="date" onChange={getFromDate} />
          </div>
          <div className="global-single-input">
            <p>To</p>
            <input type="date" onChange={getToDate} />
          </div>
          <button
            onClick={submitButtonFn}
            className="btn btn-primary"
            style={{ margin: "3% 1% 1% 1%" }}
          >
            Submit
          </button>
        </div>
      </div>

      <div className="credit-content-container">
        <div className="credit-content-top-container">
          <h3>Payment History</h3>
          <div className="credit-content-top-right-container">
            <div style={{ margin: "1%", width: "100%", display: "flex" }}>
              <ReactToPrint
                trigger={() => (
                  <IconButton className="print-icon-container credit-print-icon">
                    <i class="bi bi-printer printer-icon"></i>
                  </IconButton>
                )}
                content={() =>
                  summary === true ? creditSummaryPrint : creditPrint
                }
                pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
              />

              <div className="credit-search-container">
                <input
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  type="text"
                  placeholder="Order No,customer,Mob..."
                />
                <IconButton className="credit-search-icon">
                  <SearchIcon />
                </IconButton>
              </div>
            </div>
            <div className="wallet-summary-container">
              <input
                type="checkbox"
                id="wallet-summary"
                //  value={summary}
                checked={summary}
                onMouseDown={summaryCheckboxCheckFn}
                onClick={summaryApiCallFn}
              />
              <label htmlFor="wallet-summary">Summary</label>
            </div>
          </div>
        </div>
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>Order No</th>
                <th>Customer Name</th>
                <th>Mobile No</th>
                <th>Order Date</th>
                <th>Net Amount</th>
                <th>Discount</th>
                <th>Last Paid Date</th>
                <th>Paid Amount</th>
                <th>Balance</th>
                <th>Status</th>
                <th style={{ width: "7%" }}>&nbsp;&nbsp;&nbsp;</th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={11}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={30}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={11}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={30}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={11}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={30}
                    />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {filteredData?.length !== 0 ? (
                  filteredData?.map((r, i) => {

                    if (r?.balance > 0) {
                      return (
                        <tr>
                          <td>{r?.purchaseId}</td>
                          <td>{r?.supplier}</td>
                          <td>{r?.mobileNo}</td>
                          <td>{convertDateFormat(r?.purchaseDate)}</td>
                          <td>{r?.netAmount?.toFixed(2)}</td>
                          <td>{r?.discount?.toFixed(2)}</td>
                          <td>{convertDateFormat(r?.lastPaidDate)}</td>
                          <td>{r?.paidAmount?.toFixed(2)}</td>
                          <td>{r?.balance?.toFixed(2)}</td>
                          <td>
                            <p
                              className={
                                r?.orderstatus === "completed"
                                  ? "status-completed"
                                  : r?.orderstatus === "pending"
                                  ? "status-confirm"
                                  : r?.orderstatus === "printing"
                                  ? "status-confirm purchase-return-status-printing"
                                  : r?.orderstatus === "booking"
                                  ? "status-confirm purchase-return-status-booking"
                                  : r?.orderstatus === "delivered"
                                  ? "status-confirm purchase-return-status-delivered"
                                  : r?.orderstatus === "orderReturned"
                                  ? "status-confirm purchase-return-status-order-return"
                                  : r?.orderstatus === "deliveryReturned"
                                  ? "status-confirm purchase-return-status-delivery-return"
                                  : undefined
                              }
                              style={{ padding: "0", height: "20px" }}
                            >
                              {r?.orderstatus}
                            </p>
                          </td>
                          <td>
                            <button
                              disabled={r?.balance <= 0}
                              className="credit-pay-button"
                              onClick={() => openPaymentDialog(r)}
                            >
                              Pay
                            </button>
                          </td>
                        </tr>
                      );
                    }
                  })
                ) : (
                  <tr>
                    <td colSpan={11}>No Data</td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>

      {/* credit payment */}
      {paymentDialog && (
        <PaymentsWO
          closePayments={closePaymentDialog}
          parentWidth="95%"
          right="17%"
          subTotal={paymentData?.balance} //imp
          bodyWOPayment={{
            purchasePk: paymentData?.purchasePk,
            type: paymentData?.type,
            branchId: paymentData?.branchId,
          }}
          finalAPI={updateCreditPayBalanceAPICall} //API call
          setOpenSuccessSnack={setOpenSuccessSnack}
          setOpenErrorSnack={setOpenErrorSnack}
          setSnackMsg={setSnackMsg}
          clearWOState={clearState} //Clear fn
          updateListenerProp={updateListener}
          cusId={paymentData?.supplierId} // imp
          // placeOrderClick={undefined}
          isMultiplePayment={setIsMultiplePayment}
          // paidAmount={undefined}
          // shipmentCharge={undefined}
          paymentSection={paymentData?.type == 1 ? "alteration" : "viewOrders"}
          woId={paymentData?.purchasePk}
        />
      )}

      <SuccessSnackbar
        open={openSuccessSnack}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnack}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />

      {/* credit print */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (creditPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <h6>
                Printed On : {convertDateFormat(today)}&nbsp;{time}
              </h6>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}

              <p>PAYMENT HISTORY</p>
            </div>
            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>
                {loginResponse?.branchId}-{loginResponse?.branchName}
              </p>
            </div>
          </div>
          <div className="print-view-table-container report-print-view-table-with-clr">
            <table>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Customer</th>
                  <th>Mobile No</th>
                  <th>Order Date</th>
                  <th>Net Amount</th>
                  <th>Discount</th>
                  <th>Last Paid Date</th>
                  <th>Paid Amount</th>
                  <th>Balance</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredData?.length !== 0 ? (
                  filteredData?.map((r, i) => {

                    if (r?.balance > 0) {
                      return (
                        <tr>
                          <td>{r?.purchaseId}</td>
                          <td>{r?.supplier}</td>
                          <td>{r?.mobileNo}</td>
                          <td>{r?.purchaseDate}</td>
                          <td>{r?.netAmount}</td>
                          <td>{r?.discount}</td>
                          <td>{r?.lastPaidDate}</td>
                          <td>{r?.paidAmount}</td>
                          <td>{r?.balance?.toFixed(2)}</td>
                          <td>{r?.orderstatus}</td>
                        </tr>
                      );
                    }
                  })
                ) : (
                  <tr>
                    <td colSpan={11}>No Data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* credit summary print */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (creditSummaryPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <h6>
                Printed On : {convertDateFormat(today)}&nbsp;{time}
              </h6>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}
              <p>TRANSACTION HISTORY</p>
            </div>
            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>
                {loginResponse?.branchId}-{loginResponse?.branchName}
              </p>
            </div>
          </div>

          {creditSummary?.list?.map((r, i) => (
            <div className="commission-report-summary-container">
              <div className="commission-report-summary-top-container">
                <h3 style={{ width: "20%" }}>{r?.branchCode}</h3>
                <h3>{r?.branchName}</h3>
              </div>
              <div
                className="global-table-container"
                style={{
                  margin: "1% 0",
                  maxHeight: "none",
                  overflowY: "hidden",
                }}
              >
                <table className="global-table report-print-view-table-with-clr">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Customer ID</th>
                      <th>Name</th>
                      <th>Mobile</th>
                      <th>Paid Amount</th>
                      <th>Balance</th>
                      <th>Last Paid Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {r?.list?.map((k, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{k?.id}</td>
                        <td>{k?.name}</td>
                        <td>{k?.mobile}</td>
                        <td>{k?.paidAmount}</td>
                        <td>{k?.balance}</td>
                        <td>{k?.lastPaidDate}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody>
                    <tr>
                      <td style={{ borderTop: "1px solid #eee" }}>&nbsp;</td>
                      <td style={{ borderTop: "1px solid #eee" }}>&nbsp;</td>
                      <td style={{ borderTop: "1px solid #eee" }}>&nbsp;</td>
                      <td style={{ borderTop: "1px solid #eee" }}>&nbsp;</td>

                      <td style={{ borderTop: "1px solid #eee" }}>Total</td>
                      <td style={{ borderTop: "1px solid #eee" }}>
                        {parseFloat(r?.supplierTotalCredit) +
                          parseFloat(r?.customerTotalCredit)}
                      </td>
                      <td style={{ borderTop: "1px solid #eee" }}>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))}
          <div className="wallet-summary-print-bottom-conntainer">
            <h4 style={{ width: "9%" }}>&nbsp;</h4>
            <h4 style={{ width: "33%" }}>&nbsp;</h4>
            <h4 style={{ width: "26%" }}>Grand Total</h4>
            <h4 style={{ width: "17%" }}>
              {parseFloat(creditSummary?.grandCustomerTotalCredit) +
                parseFloat(creditSummary?.grandSupplierTotalCredit)}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
