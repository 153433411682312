import { IconButton, Tooltip } from "@mui/material";
//import  "../../../../css/Purchase Manager/StockAdjustment/requestForQuatationNw.css"
import React, { useRef } from "react";
import postRibbon from "../../../../../Assets/statusRibbon/post.png";
import Draft from "../../../../../Assets/statusRibbon/draft.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import {
  companyProfileInfoForUser,
  viewCompanyProfileInfoAPICall,
} from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import ReactToPrint from "react-to-print";
import { convertDateFormat, today } from "../../../../../Js/Date";
import { vendorBillSingleViewApi } from "../API/vendorBillAPI";
import { getTemplateAPI } from "../../../SalesNew/SalesOrders/SalesQuotation/salesQuatationAPI";
import Template1 from "../../../SalesNew/SalesOrders/SalesQuotation/Template1";
import Template2 from "../../../SalesNew/SalesOrders/SalesQuotation/Template2";
import { set_Back_button_action } from "../../../../Single Components/listSlice/filterCategorySlice";

export const VendorBillSingleView = () => {
  let singleViewID = localStorage.getItem("singleViewById");
const singleViewId = localStorage.getItem("singleVendorById")
  let navigateTo = useNavigate();
  let paymentsPrint = useRef();
  let paymentsPrintNew = useRef();
  const[path,setPath] = useState(false);
  const [head,setHead] = useState("")
  const [selectedHeading, setSelectedHeading] = useState("Single View");
  const sequence = localStorage.getItem("sequence")
  const vendorid =localStorage.getItem("singleViewByIdgrn")
  // useEffect(() => {
  //   if (singleViewId) {
  //     vendorBillSingleViewApi({ _id: singleViewId });
  //   }
  // }, [singleViewId]);

  //data of selected List
  const singleList = useSelector((state) => state?.vendorBillSlice?.rowData);
 console.log(singleList);
  // company details and info
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const decimalPosition = localStorage.getItem("decimalPosition");

  const template =useSelector((state)=>state?.salesQuatationSlice?.setTemplate)
  const dispatch = useDispatch();

  const changeColor = (heading) => {
    setSelectedHeading(heading);
  };

  const handleBackpage=()=>{
    localStorage.removeItem("singleViewByIdgrn")
    navigateTo("/userdashboard/purchaseManager/NewPurchaseOrder");
    dispatch(set_Back_button_action({ backButtonClickAction: true }));
  }
  useEffect(() => {
    setHead(sequence);
    companyProfileInfoForUser();
    const idToUse = singleViewID || singleViewId; // Use singleViewId if singleViewID is undefined
    vendorBillSingleViewApi({ _id: idToUse });

    
  }, [singleViewID, singleViewId]);
  const formValuesInitialState = {
    status: "",
    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
    companyTRN: "",

    customerName: "",
    customerAddress: "",
    customerContact: "",
    trn:"",

    dateN1:"Return Date",
    dateN2:"Invoice Date",

    termsAndCondition:"",

    expiry:"",
    refN:"PO NO",
    address:"Vendor ",
    lpo:"",
    ref:"",
    sequence:"Bill No",
    name: "",
    firstDate: "",
    secondDate: "",
    amountDue:"",
    subtotal: "",
    total: "",
    totalAmount:"",
    payTerm:"",
    label:"VENDOR BILL"
  };
  const [formValues, setFormValues] = useState(formValuesInitialState);
  const [data, setData] = useState([]);
  const [dates,setDates] =useState([]);
  const [tax, setTax] = useState([]);

  // api to fetch companyprofile data
  useEffect(() => {
    companyProfileInfoForUser();
    getTemplateAPI();
  }, []);

  const handleEdit=()=>{
    localStorage.setItem("singleViewById",singleList?.purchaseId)
    navigateTo("/userdashboard/purchaseManager/CreateNewPurchaseOrder")
  }
  useEffect(() => {
    setFormValues({
      ...formValues,
      status: singleList?.status,
      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",

      customerName: singleList?.vendorAddress?.name || "",
      customerAddress: singleList?.vendorAddress?.email || "",
      customerContact: singleList?.vendorAddress?.mobileNo || "",
      ref: singleList?.billreference || "",

      termsAndCondition:singleList?.termsAndCondition || "",

      
      subtotal: singleList?.subTotal || "",
      lpo: singleList?.LpoNo ||"",
      name: singleList?.name || "",
      expiry: singleList?.billDate || "",
      secondDate: singleList?.dueDate || "",
      totalAmount: singleList?.total || "",
      total: singleList?.total || "",
      payTerm: singleList?.PAYMENTTERM || "",
      amountDue: singleList?.amountDue || "",
    });
    setData(singleList?.itemInfo);
    setDates(singleList?.paymentsDone);
    setTax(singleList?.taxDetails)
  }, [singleList, companyProfileData]);
  
  useEffect(() => {
    if (singleViewId !== null || vendorid !== null) {
      setPath(true)
    }
    else{
      setPath(false)
    }
    //console.log(singleViewId);
  }, []);
  const [domain, setDomain] = useState("");
  useEffect(() => {
    if (formValues) {
      let element = formValues;
      // console.log(element);
      // const domainAddress = `${window.location.protocol}//${window.location.host}/posReaderPreview?path=admin/viewqrcodedata&db=${element?.db}&vendororcusid=${element?.supplierId}&type=${element?.qrcodetype}&orderId=${element?._id}`;
      const domainAddress = `${window.location.protocol}//${window.location.host}/posReaderPreview?path=admin/viewqrcodedata&db=${encodeURIComponent(element?.db)}&vendororcusid=${encodeURIComponent(element?.supplierId)}&type=${encodeURIComponent(element?.qrcodetype)}&orderId=${encodeURIComponent(element?._id)}`;


      setDomain(domainAddress);
    }
  }, [formValues]);
  return (
    <div className="main-container">
      {path && (
       <div className="first-main-container-div">
        <div className="first-main-heading-div" id="headingDiv">
          <p
            style={{
              marginLeft: "14px",borderRight:"2px solid #abaebb",
              color: selectedHeading === "Vendor Bill" ?"black" : "gray",
            }}
            onClick={() => {changeColor("Vendor Bill");handleBackpage()}}
          >
            Purchase Order
          </p>
          {/* <p
            style={{
              color: selectedHeading === "purchase Order GRN" ? "black" : "gray",
            }}
           // onClick={() => changeColor("purchase Order GRN")}
          >
           GRN purchase Order
          </p> */}
          <p
            style={{borderRight:"2px solid #abaebb",
              color: selectedHeading === "purchase" ?  "black" : "gray",
            }}
            onClick={() => {changeColor("purchase");handleEdit()}}
          >
          {head}
          </p>
          <p
            style={{borderRight:"2px solid #abaebb",
              color: selectedHeading === "Single View" ? "black" : "gray",
            }}
           // onClick={() => changeColor("purchase Order GRN")}
          >
         {formValues?.name}
          </p>
        </div>
        </div>
      )}
      <div className="first-div-container">
        <div className="post-ribbon">
          {formValues?.status === "DRAFT" && <img src={Draft} alt="" />}
          {formValues?.status === "COMPLETED" && (
            <img src={postRibbon} alt="" />
          )}
        </div>
        <i
          className="bi bi-chevron-left"
          style={{ fontSize: "30px", color: "black", cursor: "pointer" }}
          onClick={() => {
            localStorage.removeItem("singleViewById");

            navigateTo("/userdashboard/accounts/vendor/vendorBills");
            // dispatch(set_Back_button_action({ backButtonClickAction: true }));
          }}
        ></i>
        <Tooltip title="Edit">
          <IconButton>
            <i
              class="bi bi-pencil-square edit-icon1"
              onClick={() =>
                navigateTo("/userdashboard/accounts/vendor/createvendorBills")
              }
            ></i>
          </IconButton>
        </Tooltip>
        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div">
              <i style={{ color: "black" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrint}
        />
         <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrintNew}
        />

        <Tooltip title="Download">
          <IconButton>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>
      </div>
      <hr className="line" />
      <div className="second-main-container">
        <div className="left-side-div">
          <img src={formValues?.companyLogo} alt={formValues?.companyName} />
          <p>{formValues?.companyAddress}</p>
          <p>{formValues?.companyContact}</p>

          <h1 className="vendor-head">Vendor Address</h1>
             <p style={{margin:"0" }}>{formValues?.customerName}</p>
             <p style={{margin:"0"}}>{formValues?.customerAddress}</p>
             <p style={{ fontWeight: "100",margin:"0" }}>{formValues?.customerContact}</p>
          {/* <p>--</p>
          <p>--</p> */}
        </div>
        <div className="right-side-div">
          <h1> Vendor Bill</h1>
          <p>{formValues?.name}</p>
          <div className="date-div">
            <p>
              {" "}
              <span class="date-container-single">Date:</span>
              {formValues?.expiry}
            </p>
            {/* <p>Order Date:{formValues?.secondDate} </p> */}
          </div>
        </div>
      </div>

      <div className="Table-container">
        <div className="Table-data">
          <table>
            <thead>
              <tr style={{ backgroundColor: "#eaeef9" }}>
              <th>SI NO</th>
                <th>PART NO:</th>
                <th>DESCRIPTION</th>
                <th>QTY</th>
                <th>UOM</th>
                <th>RATE</th>
                <th>AMOUNT</th>
                <th>DISC%</th>
                <th>VAT% </th>
                <th>VAT</th>
                <th>NET AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                  }}
                >
                 <td>{index + 1}</td>
                  <td>{item?.label}</td>
                  <td>{item.product}</td>
                  <td>{item.qty}</td>
                  <td>{item.uomName}</td>
                  <td>{item.unitPrice?.toFixed(decimalPosition)}</td>
                  <td>{(item.qty * item.unitPrice)?.toFixed(decimalPosition)}</td>
                  <td>{item.discount}%</td>
                  <td

                  >
                    {item.taxes?.map((r, i) => (
                      <p key={i} className="tax" style={{ margin: "1px", border: "none" }}>
                        {r?.amount}%
                      </p>
                    ))}
                  </td>
                  <td>{item.taxAmt?.toFixed(decimalPosition)}</td>
                  <td>
                    {item.includedPrice === false ?
                      ((item.lineTotal + item.taxAmt)?.toFixed(decimalPosition)) :
                      (item.lineTotal?.toFixed(decimalPosition))
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ backgroundColor: "#e9e9e9", display: "flex", textAlign: "end", paddingInline: "86px" }}>
            <p style={{ margin: "0" }}>Total</p>
            {data && data.length > 0 && (
              <p style={{ width: "64%", margin: "0" }}>
                {" "}
                {data?.reduce((total, item) => {
                  const qty = typeof item.qty === 'number' ? item.qty : 0; // Check if item.qty is a number, otherwise default to 0
                  const unitPrice = typeof item.unitPrice === 'number' ? item.unitPrice : 0; // Check if item.unitPrice is a number, otherwise default to 0
                  return total + qty * unitPrice;
                }, 0)?.toFixed(decimalPosition)}
              </p>
            )}


            <p style={{ width: "27%", margin: "0" }}> {(data?.reduce((acc, row) => acc + row?.taxAmt, 0.00))?.toFixed(decimalPosition)}</p>
            <p style={{ width: "14%", padding: "3px", margin: "0", fontSize: "smaller" }}>
              {data?.reduce((acc, row) => {
                if (row.includedPrice === false) {
                  return acc + (row.lineTotal + row.taxAmt);
                } else {
                  return acc + row.lineTotal;
                }
              }, 0.00)?.toFixed(decimalPosition)}
            </p>

          </div>

          <div className="last-total-div">
            <div className="sub-total">
              {/* <h1>Sub Total</h1>
            <p>Discount(2.00%)</p>
            <p>standard Rate(2.00%)</p>
            <hr className='line-2' /> */}
              <h1>Total</h1>
              {dates?.map((date,index)=>(
              <p key={index}>{date.name}</p>
              ))}
              <h1>Amount Due</h1>
            </div>
            <div className="sub-total-amount">
              {/* <h1>$124,754.00</h1>
            <p>4000</p>
            <p>5000</p>
            <hr className='line-3' /> */}
              <h1>{formValues?.total}</h1>
              {dates?.map((date,index)=>(
              <p key={index}>{date.totalAmount}</p>
              ))}
              <h1>{formValues?.amountDue}</h1>
            </div>
          </div>
        </div>
      </div>

      {/* payments print view */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <p></p>
              <p></p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {/* <h3>--</h3>

              <h3>--</h3> */}

              <p>Vendor Bill</p>
            </div>

            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>{convertDateFormat(today)}</p>
              <p>{""}</p>
            </div>
          </div>

          <div className="second-main-container">
            <div className="left-side-div">
              <img
                src={formValues?.companyLogo}
                alt={formValues?.companyName}
              />
              <p>{formValues?.companyAddress}</p>
              <p>{formValues?.companyContact}</p>

              <h1 className="vendor-head">Vendor Address</h1>
              <p>{formValues?.vendorName}</p>
              {/* <p>--</p>
              <p>--</p> */}
            </div>
            <div className="right-side-div">
              <h1>Vendor Bill </h1>
              <p>{formValues?.name}</p>
              <div className="date-div">
                <p>
                  {" "}
                  <span class="date-container-single">Date:</span>
                  {formValues?.firstDate}
                </p>
                {/* <p>Order Date:{formValues?.secondDate} </p> */}
              </div>
            </div>
          </div>

          <div className="Table-container" style={{ padding: "0px 35px" }}>
            <div className="Table-datas">
              <table style={{ overflow: "none" }}>
                <thead>
                  <tr style={{ backgroundColor: "#eaeef9" }}>
                  <th>SI NO</th>
                <th>PART NO:</th>
                <th>DESCRIPTION</th>
                <th>QTY</th>
                <th>UOM</th>
                <th>RATE</th>
                <th>AMOUNT</th>
                <th>DISC%</th>
                <th>VAT% </th>
                <th>VAT</th>
                <th>NET AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                      }}
                    >
                       <td>{index + 1}</td>
                  <td>{item?.label}</td>
                  <td>{item.product}</td>
                  <td>{item.qty}</td>
                  <td>{item.uomName}</td>
                  <td>{item.unitPrice?.toFixed(decimalPosition)}</td>
                  <td>{(item.qty * item.unitPrice)?.toFixed(decimalPosition)}</td>
                  <td>{item.discount}%</td>
                  <td

                  >
                    {item.taxes?.map((r, i) => (
                      <p key={i} className="tax" style={{ margin: "1px", border: "none" }}>
                        {r?.amount}%
                      </p>
                    ))}
                  </td>
                  <td>{item.taxAmt?.toFixed(decimalPosition)}</td>
                  <td>
                    {item.includedPrice === false ?
                      ((item.lineTotal + item.taxAmt)?.toFixed(decimalPosition)) :
                      (item.lineTotal?.toFixed(decimalPosition))
                    }
                  </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div style={{ backgroundColor: "#e9e9e9", display: "flex", textAlign: "end", paddingInline: "86px" }}>
            <p style={{ margin: "0" }}>Total</p>
            {data && data.length > 0 && (
              <p style={{ width: "58%", margin: "0" }}>
                {" "}
                {data?.reduce((total, item) => {
                  const qty = typeof item.qty === 'number' ? item.qty : 0; // Check if item.qty is a number, otherwise default to 0
                  const unitPrice = typeof item.unitPrice === 'number' ? item.unitPrice : 0; // Check if item.unitPrice is a number, otherwise default to 0
                  return total + qty * unitPrice;
                }, 0)?.toFixed(decimalPosition)}
              </p>
            )}


            <p style={{ width: "27%", margin: "0" }}> {(data?.reduce((acc, row) => acc + row?.taxAmt, 0.00))?.toFixed(decimalPosition)}</p>
            <p style={{ width: "14%", padding: "3px", margin: "0", fontSize: "smaller" }}>
              {data?.reduce((acc, row) => {
                if (row.includedPrice === false) {
                  return acc + (row.lineTotal + row.taxAmt);
                } else {
                  return acc + row.lineTotal;
                }
              }, 0.00)?.toFixed(decimalPosition)}
            </p>

          </div>
              <div className="last-total-div">
            <div className="sub-total">
              {/* <h1>Sub Total</h1>
            <p>Discount(2.00%)</p>
            <p>standard Rate(2.00%)</p>
            <hr className='line-2' /> */}
              <h1>Total</h1>
              {dates?.map((date,index)=>(
              <p key={index}>{date.name}</p>
              ))}
              <h1>Amount Due</h1>
            </div>
            <div className="sub-total-amount">
              {/* <h1>$124,754.00</h1>
            <p>4000</p>
            <p>5000</p>
            <hr className='line-3' /> */}
              <h1>{formValues?.total}</h1>
              {dates?.map((date,index)=>(
              <p key={index}>{date.totalAmount}</p>
              ))}
              <h1>{formValues?.amountDue}</h1>
            </div>
          </div>
            </div>
          </div>
          <div className="terms">
            <h1>Terms & Conditions</h1>
            <hr className="lines" />
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
  <div ref={(el) => (paymentsPrintNew = el)}>
    {template?.templateModel === 'template1' ? (
      <Template1 data={data} formValues={formValues} tax={tax}   domain={domain} />
    ) : (
      <Template2 data={data} formValues={formValues} tax={tax}   domain={domain}/>
    )}
  </div>
</div>
    </div>
  );
};
