import React, { useRef } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import { useSelector } from 'react-redux';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import SearchIcon from "@mui/icons-material/Search";
import { BankAndCashReportAPIcall } from './GeneralReportAPI';
import { useNavigate } from 'react-router-dom';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import { getCurrentTime } from '../../../../../Js/Date';
import { IconButton, Tooltip } from '@mui/material';
import ReactToPrint from 'react-to-print';
import Template from "./BankAndCashPrint"
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../../Redux/store';

export const BankAndCashReport = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  let paymentsPrintNew = useRef();
    const [symbol, setSymbol] = useState(null);
    let navigate = useNavigate();
    const [FormDataInfo,setFormDataInfo]=useState([])
    const [selectedFromDate, setSelectedFromDate] = useState('');
    const [selectedToDate, setSelectedToDate] = useState('');
    const bankAndCash =useSelector((state)=>state?.generalReport?.BankAndCash)
    const [selectedBranch, setSelectedBranch] = useState(null);
    const decimalPosition = localStorage.getItem("decimalPosition");
    
     console.log(bankAndCash);
    const currencyListUpdated = useSelector(
        (state) => state?.viewUpdatedCurrency?.value
      );
      const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue
      );
      const handleBranchSelect = (selectedBranch) => {
        setSelectedBranch(selectedBranch); 
      
      };
      const handleClickRow=(row)=>{
        
        store.dispatch( setFilterActive(window.location.pathname))
        localStorage.setItem("singleViewById",row?._id);
        // localStorage.setItem("fromDate", `${FormDataInfo?.fromDate} 00:00:00`);
        // localStorage.setItem("toDate", `${FormDataInfo?.toDate} 23:59:59 `);
        localStorage.setItem("account",row?.accountName)
        localStorage.setItem("selectedFromDate", selectedFromDate);
        localStorage.setItem("selectedToDate", selectedToDate);
    
        // setSelectedHeading("Ledger Balance");
    
        navigate("/userdashboard/accounts/report/generalReport/bankAndCashReport/singleView")
      };

      const initialvalue ={
        label:"Bank and Cash",
        totalBalance:0,
        totalCredit:0,
        totalDebit:0,
        
        companyLogo: "",
        companyName: "",
        companyAddress: "",
        companyContact: "",
        }
          
          const [data,setData]=useState([])
          const [formValues,setFormValues]=useState(initialvalue)
        
        useEffect(()=>{
          setFormValues({...formValues,
            totalBalance:bankAndCash !== undefined && bankAndCash?.total?.balance || 0,
            totalCredit:bankAndCash !== undefined &&  bankAndCash?.total?.credit || 0,
            totalDebit:bankAndCash !== undefined &&   bankAndCash?.total?.debit || 0,
        
            companyLogo: companyProfileData?.logo || "",
            companyName: companyProfileData?.companyName || "",
            companyAddress: companyProfileData?.companyAddress || "",
            companyContact: companyProfileData?.contactNumber || "",
        
          });
          setData(bankAndCash !== undefined ? bankAndCash?.report : [])
        },[bankAndCash,companyProfileData])

      const handleDataFromChild = (data) => {
        setFormDataInfo(data);
        // Store selected dates
        setSelectedFromDate(data.fromDate);
        setSelectedToDate(data.toDate);
      };

      useEffect(() => {
        let currencyObj = filterObjFromList(
          "_id",
          currencyListUpdated,
          "currency",
          companyProfileData
        );
        // console.log(currencyObj);
        setSymbol(currencyObj?.symbol);
      }, [companyProfileData, currencyListUpdated]);
    
      useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
      }, []);

      useEffect(()=>{
        if(FormDataInfo?.length!==0)
        { let body ={
          startDate:FormDataInfo?.fromDate===''?'':`${FormDataInfo?.fromDate} 00:00:00`,
          endDate:FormDataInfo?.toDate===''?'':`${FormDataInfo?.toDate} 23:59:59 `,
          branchId: FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0],
          search:FormDataInfo?.search,
      }
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData &&   BankAndCashReportAPIcall(body);
    }
      },[FormDataInfo]);
  return (
    <div className="global-page-parent-container"  style={{margin:"0"}}>
      <div className="global-white-bg-container">
        <div className="justify-space-between" >
         <div style={{alignItems:"center",display:"flex"}}>
          <div className="sharp-arrow" style={{ width: "145Px" }}> <p>Bank & Cash Report</p></div>
          <div style={{marginLeft:"19px"}}>
          <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrintNew}
        />

        <Tooltip title="Download">
          <IconButton>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        </div>
        </div>
          <div>
                <CategorySearchandFilter
                statusOptions={['PURCHASE ORDER','DONE','CANCEL']}
                onData={handleDataFromChild}
                onBranchSelect={handleBranchSelect}
                />
                
             </div>
         
          {/* <div className="view-order-search-container" style={{ width: "17%", position: "relative", margin: "7px" }}>
            <p className="search-head">Search</p>
            <input
              value={''}
            />
            <SearchIcon className="billing-search-icon" style={{ color: "#736ff3" }} />
          </div> */}
        </div>
        <h1 style={{margin:"1px",fontSize:"large"}}>Bank & Cash Report</h1>
     
      <div className="global-report-table-container">
        <table>
          <thead>
            <tr>
              <th>Bank</th>
              <th style={{width:"22%"}}>Debit</th>
              <th style={{width:"22%"}}>Credit</th>
              <th style={{width:"22%"}}>Balance</th>
            </tr>
          </thead>
          <tbody>
           {bankAndCash !== undefined && bankAndCash?.report?.map((item,index)=>(
            <tr key={index} onClick={()=>handleClickRow(item)}>
              <td style={{textAlignLast:"start",paddingLeft:"10px"}}>{item.accountName}</td>
              <td style={{textAlignLast:"end",paddingRight:"10px"}}>{symbol}&nbsp;{item.debit?.toFixed(decimalPosition)}</td>
              <td style={{textAlignLast:"end",paddingRight:"10px"}}>{symbol}&nbsp;{item.credit?.toFixed(decimalPosition)}</td>
              <td style={{textAlignLast:"end",paddingRight:"10px"}}>{symbol}&nbsp;{item.balance?.toFixed(decimalPosition)}</td>
            </tr>
          ))}
          </tbody>
          <tfoot>
              <tr>
                <th>Total</th>
                <th >{symbol}&nbsp;{bankAndCash !== undefined &&bankAndCash?.total?.debit?.toFixed(decimalPosition)}</th>
                <th>{symbol}&nbsp;{bankAndCash !== undefined && bankAndCash?.total?.credit?.toFixed(decimalPosition)}</th>
                <th>{symbol}&nbsp;{bankAndCash !== undefined && bankAndCash?.total?.balance?.toFixed(decimalPosition)}</th>
              </tr>
          </tfoot>
        </table>
        </div>
        </div>
        <div style={{ display: "none" }}>
  <div ref={(el) => (paymentsPrintNew = el)}>
      <Template  data={data} formValues={formValues}/> 
  </div>
</div>
    </div>
  )
}