import React,{useState,useEffect} from 'react'
import '../../../css/Sales/orderList.css'
import {Datepicker} from '../../Single Components/Datepicker'
import SearchIcon from '@mui/icons-material/Search';
import {IconButton,Dialog, Skeleton, Autocomplete, TextField} from '@mui/material'
import {deleteOrderListAPICall, salesOrderListAPICall,singleViewSalesOrderListAPICall} from '../../../API/Sales/orderListAPI'
import {useSelector} from 'react-redux'
import {convertDateFormat, today} from '../../../Js/Date'
import ErrorSnackbar from '../../Single Components/SnackBars/ErrorSnackbar';
import { deleteAlertMessage } from '../../../Js/generalFunctions';
import SuccessSnackbar from '../../Single Components/SnackBars/SuccessSnackbar';
import { viewAllBranchesAPICall } from '../../../API/Settings/Company Settings/companySettingsAPI';

export const OrderList = (props) => {
  const {isDialog}=props
  const storeCode = localStorage.getItem('branchId')
   // login response
   const loginResponse = useSelector((state) => state.loginResponseSlice.value);
   // role based rendering
   const userRole = useSelector((state) => state.userRoleSlice.value);
   // order list data
   const orderListData=useSelector((state)=>state.salesOrderList.value)
   // order list single view
   const orderListSingleViewData=useSelector((state)=>state.singleViewOrderList.value)

   const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const [orderListView,setOrderListView]=useState(false)
  const [searchInput,setSearchInput]=useState("")
  const [fromDate,setFromDate]=useState(today)
  const [toDate,setToDate]=useState(today)
  const [branchId,setBranchId]=useState(null)
  // loading states
  const [isLoading,setIsLoading]=useState(false)
  const [snackMsg, setSnackMsg] = useState("oops!!!");
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [successSnackOpen,setSuccesSnackOpen]=useState(false)
  const [isModify,setIsModify]=useState(false)

  const updateListner=()=>{
    setIsModify(!isModify)
  }

  const closeErrorSnack = () => {
    setErrorSnackOpen(false);
  };
  useEffect(()=>{
    
    singleViewSalesOrderListAPICall()
    viewAllBranchesAPICall()
    salesOrderListAPICall(setIsLoading,setErrorSnackOpen,setSnackMsg,
      {branchId:loginResponse?.data?.branchId ===undefined ? branchId:loginResponse?.data?.branchId})
  },[isModify])

  const openOrderListSingleView=(id)=>{
    setOrderListView(true)
    singleViewSalesOrderListAPICall({_id:id})
  }
 
  
  const [filteredData,setFilteredData]=useState([])
  useEffect(()=>{
    let filteredArray= orderListData?.list?.filter((obj)=>{
      if(obj.orderId?.toLowerCase()?.includes(searchInput.toLowerCase())){
        return obj
      }
      if(obj.customerName?.toLowerCase()?.includes(searchInput.toLowerCase())){
        return obj
      }
    
      
    })
    // console.log(filteredArray);
    setFilteredData(filteredArray)

  },[orderListData,searchInput])

 const deleteOrderList=(id)=>{
  deleteOrderListAPICall(
    id,
    updateListner,
    setSuccesSnackOpen,
    setErrorSnackOpen,
    setSnackMsg
    )
  }
 const clickOrderListSubmit=()=>{
  salesOrderListAPICall(setIsLoading,setErrorSnackOpen,setSnackMsg,
                        {branchId:branchId})
  }

  return (
    <div className="global-page-parent-container">
      {!isDialog&& userRole==="admin" &&<>
      <p className="breadcrumb">Sales &gt; Order List</p>
      
      <div className="global-white-bg-container">
        <h4>Order List</h4>
        <div className="order-list-filter-submit-ctn">
          {
            userRole==="admin" &&
            <div className="global-single-input auto-complete">
              <p>Branch</p>
              <Autocomplete
                sx={{width:"100%"}}
                options={allBranchesList || [{storeCode:"no",branchName:"data"}]}
                getOptionLabel={(option)=>`${option?.storeCode}-${option?.branchName}`}
                renderInput={(params)=>(
                    <TextField {...params} placeholder="Select a Branch"/>
                )}
                onChange={(e,newValue)=>{
                      if(newValue===null){
                        setBranchId(null)
                      }
                      else{
                        setBranchId(newValue?.storeCode)
                      }
                }}
              />
            </div>
          }
          
          <div className="global-single-input">
            <p>From</p>
            <input type="date" value={fromDate} onChange={(e)=>setFromDate(e.target.value)}/>
          </div>
          <div className="global-single-input">
            <p>To</p>
            <input type="date" value={toDate} onChange={(e)=>setToDate(e.target.value)}/>
          </div>
          <button className="btn btn-primary" style={{margin:"1%"}} onClick={clickOrderListSubmit}>Submit</button>
        </div>
      </div>
      </>
      }

      <div className="global-white-bg-container" >
         <div className="order-list-top-container" style={{justifyContent:isDialog || userRole==="user"?"space-between":"flex-end",marginRight:"0"}}>

          {
             userRole==="user" &&
            <div style={{display:"flex",width:"30%",justifyContent:"space-between"}}>
              <Datepicker type="date" boxShadow="0 0 8px 2px #eee" value={fromDate} onChange={(e)=>setFromDate(e.target.value)}/>
              <Datepicker type="date" boxShadow="0 0 8px 2px #eee" value={toDate} onChange={(e)=>setToDate(e.target.value)}/>
           </div>
          }
           
           <div style={{display:"flex",width:'60%'}}>
            <div className="sales-order-list-total-amt-container">
              <p>Total Amt</p>
              <p>{orderListData?.totalPaidAmount?.toFixed(2)}</p>
            </div>
           <div className="sales-order-list-total-amt-container" style={{backgroundColor:"#e5ebff",color:"#1166ff"}}>
              <p>Total Disc</p>
              <p>{orderListData?.TotalDiscount?.toFixed(2)}</p>
           </div>
           <div className="sales-order-list-search-container">
            <input value={searchInput} onChange={(e)=>setSearchInput(e.target.value)} type="text" placeholder="Search Order ID,Customer..."/>
            <SearchIcon className="search-icon" />
            </div>
           </div>
          
         </div> 

         {/* table */}
         <div className="global-table-container">
           <table className="global-table">
             <thead>
               <tr>
                <th>No</th>
                {
                  userRole === 'admin' &&
                  <>
                  <th>Branch Code</th>
                  <th>Branch Name</th>
                 </>
                }
               
                <th>Order ID</th>
                <th>Date</th>
                <th>Customer</th>
                {/* <th>Payment Status</th> */}
                <th>Amount</th>
                <th>Paid amount</th>
                <th>Discount</th>
                <th>Return Amount</th>
                <th>Balance</th>
                <th>Status</th>
                
                <th>View</th>
                {
                  userRole==="admin" &&
                  <th>Delete</th>
                }
               </tr>
             </thead>
             {
              isLoading?
              <tbody>
                  <tr>
                          <td colSpan={14}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={14}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={14}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={14}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={14}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr> 
                          <tr>
                          <td colSpan={14}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr> 
                          <tr>
                          <td colSpan={14}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr> 
                          <tr>
                          <td colSpan={14}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
              </tbody>:
             <tbody>
              {
                filteredData?.length !== 0 ? filteredData?.slice(0)?.reverse()?.map((r,i)=>(
                  <tr>
                  <td>{i+1}</td>
                  {
                    userRole === 'admin' &&
                    <>
                     <td>{r?.branchId}</td>
                     <td>{r?.branchName}</td>
                    </>
                  }
                 
                  <td>{r?.orderId}</td>
                  <td>{convertDateFormat(r?.orderDate)}</td>
                  <td>{r?.customerName}</td>
                  <td>{r?.totalAmount}</td>
                  <td>{r?.paidAmount}</td>
                  <td>{r?.discount}</td>
                  <td>{r?.returnAmount}</td>
                  <td>{r?.balanceAmount?.toFixed(2)}</td>
                  {/* <td><span className="sales-order-list-payment-status">{r?.paymentstatus}</span></td> */}
                  <td><span className={
                        r?.status==="pending"?
                        "sales-order-list-status-pending":
                        r?.status==="INVOICED"?
                        "sales-order-list-status"
                        :r?.status==="returned"?
                        "sales-order-list-status-returned"

                        :undefined
                    }>{r?.status.toUpperCase()}</span></td>              
                  <td>
                  <IconButton onClick={()=>openOrderListSingleView(r?._id)}>
                    <i class="bi bi-eye visibility-icon"></i>
                  </IconButton>
                 
                  </td>
                  {
                    userRole ==="admin"&&
                   <td>
                    <IconButton onClick={deleteAlertMessage(r._id, deleteOrderList)}>
                      <i class="bi bi-trash3 delete-icon"></i>
                    </IconButton>
                  </td>
                  }
                  
                </tr>
                ))
                :
                <tr>
                   <td colSpan={14}>No Data</td>
                </tr>
              }
             
             </tbody>}
           </table>
         </div>
      </div>

      {/* order list single view */}
      <Dialog open={orderListView} maxWidth="lg" onClose={()=>setOrderListView(false)} onKeyDown={(e)=>e.key === "Escape" && setOrderListView(false)}>
        <div className="sales-order-list-view-container">
          <h2>Sales Order</h2>
          <div className="sales-order-list-view-top-container">
            <div className="sales-order-list-view-top-left">
              <div className="sales-order-list-view-top-left-first">
                <p>Order ID&nbsp;:&nbsp;{orderListSingleViewData?.orderId}</p>
                <p>Order Date&nbsp;:&nbsp;{orderListSingleViewData?.orderDate}</p>
              </div>
              <div className="sales-order-list-view-top-left-Second">
                {/* <p>Expected Shipment Date &nbsp;&nbsp; 12-02-2022</p>
                <p>Delivery Method &nbsp;&nbsp; Bike</p> */}
                <p>Sales Person &nbsp;&nbsp; :&nbsp;{orderListSingleViewData?.salesman}</p>
              </div>
             </div>
            <div className="sales-order-list-view-top-right">
               <div className='sales-order-list-view-top-right-address'>
                 <h4>Billing Address:</h4>
                 <p>{orderListSingleViewData?.billingAddress}</p>
                
               </div>
               {/* <div className='sales-order-list-view-top-right-address'>
                 <h4>Shipping  Address:</h4>
                 <p>Deepak Dinesh</p>
                 <p>Cyber Park kozhikode,Kerala</p>
               </div> */}
                 <p style={{fontSize:"0.9rem",textAlign:"right"}}>{orderListSingleViewData?.gstin?.includes("No gst")?"":`GSTIN - ${orderListSingleViewData?.gstin}`}</p>
            </div>
          </div>

          {/* TABLE */}
             <div className='sales-order-list-view-table'>
                <table>
                   <thead>
                     <tr>
                       <th>Product</th>
                       <th>Qty</th>
                       <th>Gross Amount</th>
                       <th>Discount</th>
                       <th>Taxable Value</th>
                       <th>Total</th>
                     </tr>
                   </thead>
                   <tbody>
                    {
                      orderListSingleViewData?.list?.map((r,i)=>(
                        <tr>
                        <td>{r?.itemName}</td>
                        <td>{r?.quantity}</td>
                        <td>{r?.grossAmount}</td>
                        <td>{r?.discount}</td>
                        <td>{r?.taxableavalue}</td>
                        <td>{r?.totalAmount}</td>
                      </tr>
                      ))
                    }
                   
                   </tbody>
                </table>
             </div>


             {/* bottom container */}
             <div className='sales-order-list-view-bottom-container'>
                <div className='sales-order-list-view-bottom-left-container'>
                  {/* <p>Customer Notes</p> */}
                  {/* <p>More Information</p> */}
                </div>
                <div className='sales-order-list-view-bottom-right-container'>
                  <div className='sales-order-list-view-bottom-right-single-container'>
                    <h4 style={{width:"44%",textAlign:"right"}}>Grand Total</h4>
                    <h4 style={{textAlign:"end"}}>{orderListSingleViewData?.grandTotal}</h4>
                  </div>
                  <div className='sales-order-list-view-bottom-right-single-container'>
                    <p style={{width:"44%",textAlign:"right"}}>Shipping Charges</p>
                    <p>{orderListSingleViewData?.shippingTotal}</p>
                  </div>
                  <div className='sales-order-list-view-bottom-right-single-container'>
                    <p style={{width:"44%",textAlign:"right"}}>Discount</p>
                    <p>{orderListSingleViewData?.discount}</p>
                  </div>
                  {/* <div className='sales-order-list-view-bottom-right-single-container'>
                    <p style={{width:"44%",textAlign:"right"}}>Adjustment</p>
                    <p>---</p>
                  </div> */}
                  <hr className='global-hr'/>
                  <div className='sales-order-list-view-bottom-right-single-container'>
                    <h4 style={{width:"44%",textAlign:"right"}}>Total</h4>
                    <h4 style={{textAlign:"end"}}>{orderListSingleViewData?.subTotal}</h4>
                  </div>
                </div>
             </div>
             <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
              <button onClick={()=>setOrderListView(false)} className="btn btn-secondary-outlined">Close</button>
             </div>
        </div>
      </Dialog>
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnack}
        message={snackMsg}
      />
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={()=>setSuccesSnackOpen(false)}
        message={snackMsg}
      />
    </div>
  )
}
