import axios from "axios";

////*BASE URL LOCAL
// SARANG 34

// axios.defaults.baseURL= process.env.REACT_APP_LOCAL_BASE_URL_SARANG;

// JOWHAR 30

// test server build ip jowhar 30


// axios.defaults.baseURL = process.env.REACT_APP_LOCAL_BASE_URL_JOWHAR;

// SINAN 13

// axios.defaults.baseURL= process.env.REACT_APP_LOCAL_BASE_URL_SINAN;


// SAVAD 28
// axios.defaults.baseURL= process.env.REACT_APP_LOCAL_BASE_URL_SAVAD;

//*BASE URL PRODUCTION
 axios.defaults.baseURL = process.env.REACT_APP_PRODUCTION_BASE_URL;

// *** BASE URL PRODUCTION UAE
//  axios.defaults.baseURL=process.env.REACT_APP_PRODUCTION_BASE_URL_UAE

//  admin  base url Local
// export const ADMINBASEURL = axios.create({
//   baseURL: "http://192.168.1.30:3004/",
// });


// admin base url production .com
export const ADMINBASEURL=axios.create({
  baseURL:'https://apiadmin.eyeterp.com/'
})

// admin base url production .ae

// export const ADMINBASEURL=axios.create({
//   baseURL:'https://aeapiadmin.eyeterp.ae/'
// })

//*HEADERS:
//json Body
export const HEADERS = {
  headers: {
    "Content-Type": "application/json",
  },
};
//Query
export const HEADER_QUERY = {
  "Content-Type": "application/json",
};
//FormData
export const HEADER_FORM_DATA = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};
