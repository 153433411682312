import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import { PurchaseReportNewAPI } from './purchaseReportNewAPI';
import { getCurrentTime } from '../../../../../Js/Date';
import ReactToPrint from "react-to-print";
import Template from "./purchaseReportPrint"
import { IconButton, Tooltip } from "@mui/material";
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../../Redux/store';


const PurchaseReportNew = () => {
    let navigate = useNavigate();
    let paymentsPrintNew = useRef();
    const [symbol, setSymbol] = useState(null);
    const [FormDataInfo, setFormDataInfo] = useState([])
    const filtereDataOptions = useSelector((state) => state.listCategory);

    const [selectedBranch, setSelectedBranch] = useState(null);
    const decimalPosition = localStorage.getItem("decimalPosition");

    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );

    const purchaseList = useSelector((state => state?.purchaseRport?.purhaseReportNwList))

    console.log(purchaseList);
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue
    );

    const handleBranchSelect = (selectedBranch) => {
        setSelectedBranch(selectedBranch); 
      
      };
    const initialvalue ={
      
        

        companyLogo: "",
        companyName: "",
        companyAddress: "",
        companyContact: "",
    }


    const [formValues, setFormValues] = useState(initialvalue)

    useEffect(() => {
        setFormValues({
            ...formValues,
            companyLogo: companyProfileData?.logo || "",
            companyName: companyProfileData?.companyName || "",
            companyAddress: companyProfileData?.companyAddress || "",
            companyContact: companyProfileData?.contactNumber || "",

        });

    }, [companyProfileData])
    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
    };
    const handleRow = (row) => {

        store.dispatch( setFilterActive(window.location.pathname))
        localStorage.setItem("singleViewId",row._id)
        localStorage.setItem("name",row.itemName)

        localStorage.setItem("fromDate", FormDataInfo?.fromDate);
        localStorage.setItem("toDate", FormDataInfo?.toDate);
        localStorage.setItem("branch", FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0])
        navigate("/userdashboard/purchasemanager/PurchaseReport/PurchaseReportNew/SingleView")
    }

    useEffect(() => {
        let currencyObj = filterObjFromList(
            "_id",
            currencyListUpdated,
            "currency",
            companyProfileData
        );
        // console.log(currencyObj);
        setSymbol(currencyObj?.symbol);
    }, [companyProfileData, currencyListUpdated]);

    useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
    }, []);


useEffect(()=>{
    if(FormDataInfo?.length!==0){ let body ={
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} ${getCurrentTime()}`,
        endDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} ${getCurrentTime()}`,
        branchId: FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0],
        search:FormDataInfo?.search,
    }
    let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData &&  PurchaseReportNewAPI(body);
  }
    },[FormDataInfo]);
   


    return (
        <div className="global-page-parent-container" style={{ margin: "0" }}>
            <div className="global-white-bg-container">
                <div className="justify-space-between" >
                    <div>
                        <div className="sharp-arrow" style={{ width: "125Px" }}> <p>Purchase Report</p></div>
                        <Tooltip title="Share" style={{ marginLeft: "24px" }}>
                            <IconButton>
                                <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                            </IconButton>
                        </Tooltip>

                        <ReactToPrint
                            trigger={() => (
                                <IconButton className="printer-div" style={{ padding: "1px" }}>
                                    <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
                                </IconButton>
                            )}
                            content={() => paymentsPrintNew}
                        />

                        <Tooltip title="Download">
                            <IconButton>
                                <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div>
                        <CategorySearchandFilter
                            statusOptions={['PURCHASE ORDER', 'DONE', 'CANCEL']}
                            onData={handleDataFromChild}
                            onBranchSelect={handleBranchSelect}
                        />

                    </div>
                </div>
                <h1 style={{ margin: "1px", fontSize: "large" }}>Purchase Report</h1>

                <div className="global-report-table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th >UOM</th>

                                <th >Purchase Qty</th>
                                <th >Purchase Return Qty</th>
                                <th >Total Quatity</th>
                                <th >Average Unit Cost</th>
                                <th >Average Total Value</th>

                            </tr>
                        </thead>
                        <tbody>
                            {purchaseList?.map((item, index) => (
                                <tr key={index} onClick={() => handleRow(item)}>
                                    <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.itemName}</td>
                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.uom}</td>

                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.purchaseQty?.toFixed(decimalPosition)}</td>
                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.purchaseRet?.toFixed(decimalPosition)}</td>
                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalQuantity?.toFixed(decimalPosition)}</td>
                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.cost?.toFixed(decimalPosition)}</td>
                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.totalValue?.toFixed(decimalPosition)}</td>
                                </tr>
                            ))}
                        </tbody>
                        {
                        purchaseList &&    
                        <tfoot>
                            <tr>
                                <th colSpan={2}>Total</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.purchaseQty, 0)?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.purchaseRet, 0)?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.totalQuantity, 0)?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.cost, 0)?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.totalValue, 0)?.toFixed(decimalPosition)}</th>
                            </tr>
                        </tfoot>}
                    </table>
                </div>
            </div>
            <div style={{ display: "none" }}>
                <div ref={(el) => (paymentsPrintNew = el)}>

                    <Template formValues={formValues} />



                </div>
            </div>
        </div>

    )
}

export default PurchaseReportNew