import React, { useEffect, useState } from "react";
import "./inputOrOutputReport.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { today } from "../../../../../Js/Date";
import CustomPagination from "../../../../Single Components/CustomPagination";
import { listInputOrOutputAPI } from "../vatReportApis";
import { useSelector } from "react-redux";

const InputOrOutputReport = () => {
  const [currentPage, setCurrentPage] = useState(1);
const {listInputOrOutput} = useSelector((res)=>res.vatReportSlice)

const handlePageChange = (page) => {
  setCurrentPage(page);
};
  useEffect(() => {
    listInputOrOutputAPI();
    if(currentPage){

    }
  }, [currentPage]);

  // useEffect(() => {
  //   if (FormDataInfo.length !== 0) {
  //     sortViewStockMovesListAPICall(
  //       {
  //         fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} ${getCurrentTime()}`,
  //       toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} ${getCurrentTime()}`,
  //         search: FormDataInfo?.search,
  //         index: currentPage - 1,
  //         type: FormDataInfo?.type,
  //         fromLocation: FormDataInfo?.fromLocation,
  //         toLocation: FormDataInfo?.toLocation,
  //         product: FormDataInfo?.product?._id,
  //       },
  //       setIsLoading
  //     );
  //   }
  // }, [FormDataInfo, currentPage]);

  return (
    <div className="in-or-out-container">
      <div className="in-or-out-report-wrapper">
        <div className="sub-nav-head">
          <div>
            <div className="path-name-wrapper">
              <h3 className="path-name">Input/Output Report</h3>
            </div>
          </div>
          <div>
            <div className="date-picker-input-wrapper">
              <TextField
                id="outlined-size-small"
                defaultValue="Small"
                size="small"
                label="Date*"
                variant="outlined"
                type="date"
                focused
                value={today}
                //   onChange={getFormInfo("chequeDate")}
                //   disabled={formValues?.isEdit}
              />
            </div>
          </div>
        </div>
        <div className="pagination-wrapper">
          <CustomPagination
            currentPage={currentPage}
            totalPages={listInputOrOutput?.pages}
            onPageChange={handlePageChange}
          />
        </div>
        <div className="table-wrapper-container">
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              className="transaction-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Transaction Number</TableCell>
                  <TableCell>Transaction Type</TableCell>
                  <TableCell>Debit</TableCell>
                  <TableCell>Credit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listInputOrOutput?.list?.length > 0 ? (
                  listInputOrOutput?.list?.map((item, index) => (
                    <TableRow
                      key={index}
                      className={index % 2 === 0 ? "even-row " : ""}
                    >
                      <TableCell>{item?.accountingDate}</TableCell>
                      <TableCell>{item?.reference}</TableCell>
                      <TableCell>{item?.createdFrom}</TableCell>
                      <TableCell>{item?.debit}</TableCell>
                      <TableCell>{item?.credit}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell className="no-data-td" colSpan={5}>
                      NO DATA
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter style={{ position: "sticky", bottom: 0 }}>
                <TableRow className="total-amount-cr-dr">
                  <TableCell colSpan={3}>Total</TableCell>
                  <TableCell>
                    {listInputOrOutput?.currencySymbol}{" "}
                    {listInputOrOutput?.totalDebit}
                  </TableCell>
                  <TableCell>
                    {listInputOrOutput?.currencySymbol}{" "}
                    {listInputOrOutput?.totalCredit}
                  </TableCell>
                </TableRow>
                <TableRow className="input-and-output-total">
                  <TableCell colSpan={4}>Total Input Tax</TableCell>
                  <TableCell>
                    {listInputOrOutput?.currencySymbol}{" "}
                    {listInputOrOutput?.totalInputTax}
                  </TableCell>
                </TableRow>
                <TableRow className="input-and-output-total">
                  <TableCell colSpan={4}>Total Output Tax</TableCell>
                  <TableCell>
                    {listInputOrOutput?.currencySymbol}{" "}
                    {listInputOrOutput?.totalOutputTax}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          {/* <Table className="transaction-table total-table">
            {listInputOrOutput?.list?.length > 0 && (
              <>
                <TableRow className="head">
                  <TableCell>Date</TableCell>
                  <TableCell>Transaction Number</TableCell>
                  <TableCell>Transaction Type</TableCell>
                  <TableCell>Debit</TableCell>
                  <TableCell>Credit</TableCell>
                </TableRow>
                <TableRow className="total-amount-cr-dr">
                  <TableCell colSpan={3}>Total</TableCell>
                  <TableCell>0</TableCell>
                  <TableCell>0</TableCell>
                </TableRow>
                <TableRow className="input-and-output-total">
                  <TableCell colSpan={4}>Total Input Tax</TableCell>
                  <TableCell>0</TableCell>
                </TableRow>
                <TableRow className="input-and-output-total">
                  <TableCell colSpan={4}>Total Output Tax</TableCell>
                  <TableCell>0</TableCell>
                </TableRow>
              </>
            )}
          </Table> */}
        </div>
      </div>
    </div>
  );
};

export default InputOrOutputReport;
