import React, { useEffect, useRef, useState } from 'react'
import { IconButton, Skeleton } from '@mui/material';
import './CustomerReceiptReport.css'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { convertDateFormat } from '../../../../../Js/Date';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomPagination from '../../../../Single Components/CustomPagination';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import { CustomerReceiptReportSingleViewAPIcall } from './GeneralReportAPI';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';

import { Tooltip } from '@mui/material';
import ReactToPrint from 'react-to-print';
import PrintTemplate from "./CustomerReceiptSingleViewPrint"

import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';

function CustomerReceiptReportSingleView() {
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  // let componentRef = useRef();
  let navigate = useNavigate();
  let location = useLocation()
  let paymentsPrintNew = useRef();

  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue)

  const SingleListData = useSelector((state) => state?.generalReport?.customerReceiptReportSingleView)
  console.log(SingleListData)

  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [symbol, setSymbol] = useState(null);
  const [FormDataInfo, setFormDataInfo] = useState([])
  const singleViewId = localStorage.getItem("customerReceiptSingleViewId")

  const totalPages = 10;
  const decimalPosition = localStorage.getItem("decimalPosition");

  const totalJournalKeys = SingleListData !== undefined ? Object.keys(SingleListData?.total?.journal) : [];

  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handleClick = () => {
    navigate('/userdashboard/sales/Report/CustomerReceiptReport');
  };
  useEffect(()=>{
    if(FormDataInfo?.length!==0){ 
      let body ={
      
       _id:singleViewId,
       search:FormDataInfo?.search,
   }
   CustomerReceiptReportSingleViewAPIcall(body)
  }
   },[FormDataInfo,singleViewId])
  // useEffect(() => {

  //   CustomerReceiptReportSingleViewAPIcall({
  //     _id: singleViewId,

  //   })
  // }, [singleViewId])

  useEffect(() => {
    let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
    setSymbol(currencyObj?.symbol)
  }, [companyProfileData, currencyListUpdated])
  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, [])

  return (
    <div className="global-page-parent-container">
      {/* table */}
      <div className="global-white-bg-container">
        <div className='create-button-blue-container' style={{ margin: "1%", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
          <div>
          <Tooltip title="Share">
              <IconButton>
                <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
              </IconButton>
            </Tooltip>

            <ReactToPrint
              trigger={() => (
                <IconButton className="printer-div" style={{ padding: "1px" }}>
                  <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
                </IconButton>
              )}
              content={() => paymentsPrintNew}
            />

            <Tooltip title="Download">
              <IconButton>
                <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
              </IconButton>
            </Tooltip>
            <h3 style={{ display: 'inline-block' }}>
              <span
                style={{ color: 'gray', cursor: 'pointer' }}
                onClick={handleClick}
              >
                Customer Receipt Report
              </span>
              <ArrowForwardIosIcon style={{ fontSize: '0.9rem' }} />
              {location.state.customerName}
            </h3>
          </div>

          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '50%'
          }}>

        

            <CategorySearchandFilter
              onData={handleDataFromChild}
              isFilter={false}
            // isCustomer={true}
            // isJournal={true}
            />


            {/* <CategorySearchandFilter
              // statusOptions={['POSTED', 'DRAFT']}
              onData={handleDataFromChild}
              style={{ width: "100%" }}
              isLocation={true}
              isCategory={true}
              isposCategory={true}
              isDate={false}

            // isStatus={true}
            /> */}
            {/* <IconButton
              style={{ width: "100px" }}

              className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
            <IconButton
              style={{ width: "100px" }}
              className="download-icon-container">
              <i class="bi bi-arrow-down-circle download-icon"></i>
            </IconButton> */}
          </div>

        </div>


        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}

          />
        </div>

        <div className="customer-receipt-table-container">
          <table>
            <thead>
              <tr>

                <th>Date</th>
                <th>Name</th>
                <th>Discount</th>
                <th>Tax Value</th>
                <th>Invoice Amount</th>
                <th>Paid Amount</th>
                {totalJournalKeys?.map(r =>
                  <th>{r}</th>
                )}
                <th>Credit</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : SingleListData?.result !== undefined && SingleListData?.result?.length !== 0 ? (
                SingleListData?.result
                  ?.slice(0)
                  ?.map((r, i) => (
                    <tr key={i}>
                      <td>{convertDateFormat(r?.invoiceDate)}</td>
                      <td>{r?.invoiceNo}</td>
                      <td>{symbol}&nbsp;{r?.discount?.toFixed(decimalPosition)}</td>
                      <td>{symbol}&nbsp;{r?.tax?.toFixed(decimalPosition)}</td>
                      <td>{symbol}&nbsp;{r?.invAmount?.toFixed(decimalPosition)}</td>
                      <td>{symbol}&nbsp;{r?.paidAmount?.toFixed(decimalPosition)}</td>
                      {totalJournalKeys?.length > 0 && totalJournalKeys.map((key, i) => {
                        const value = r?.journal[key]?.toFixed(decimalPosition) || '0.00';
                        return (
                          <td key={i}>{symbol}&nbsp;{value}</td>
                        );
                      })}

                      <td>{symbol}&nbsp;{r?.amountDue?.toFixed(decimalPosition)}</td>

                    </tr>
                  ))
              ) : (
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan={9}>No Data</td>
                </tr>
              )}

            </tbody>
            <tfoot >
              <tr>
                <th style={{ textAlign: 'start', fontSize: '1rem', paddingLeft: '3%' }} colSpan={4}>TOTAL</th>
                <th >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.invAmount?.toFixed(decimalPosition)}</th>
                <th >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.paidAmount?.toFixed(decimalPosition)}</th>
                {totalJournalKeys.map((key) => (

                  <th key={key}>{symbol}&nbsp;{SingleListData?.total?.journal[key]?.toFixed(decimalPosition) || '0.00'}</th>
                ))}
                <th >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.amountDue?.toFixed(decimalPosition)}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>

          <PrintTemplate />

        </div>
      </div>
    </div>

  )
}

export default CustomerReceiptReportSingleView