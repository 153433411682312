import { createSlice } from "@reduxjs/toolkit";

export const chartOfAccountsSlice = createSlice({
  name: "chartOfAccountsSlice",
  initialState: {
    value: undefined,
    singleValue:undefined,
    tableList:undefined
  },
  reducers: {
    update_chart_of_accounts: (state, action) => {
      state.value = action.payload.chartOfAccountData;
    },
    chart_of_accounts_singleview:(state,action)=>{
      state.singleValue=action.payload.chartOfAccountsSingle
    },
    chart_of_accounts_table_filter:(state,action)=>{
      state.tableList=action.payload.chartOfAccountsTableFilter
    }
  },
});

export const { update_chart_of_accounts,chart_of_accounts_singleview,chart_of_accounts_table_filter} = chartOfAccountsSlice.actions;

export default chartOfAccountsSlice.reducer;