import React, { useEffect, useState } from "react";
import { viewAllCurrencyUpdated } from "../../../../API/Settings/Currency/CurrencyAPI";
import { companyProfileInfoForUser } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import {
  convertArrayToExcel,
  filterObjFromList,
} from "../../../../Js/generalFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import CustomPagination from "../../../Single Components/CustomPagination";
import { convertDateFormat } from "../../../../Js/Date";
import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter";
import { SalesReportBySalesPersonAPIcall } from "./SalesReportApi";
import ReactToPrint from "react-to-print";
import Template from "./SalesReportBySalesPersonPrint";
import { useRef } from "react";
import { setFilterActive } from "../../../Single Components/listSlice/filterCategorySlice";
import store from "../../../../Redux/store";

function SalesReportBySalesPerson() {
  let SalesbySalesReportPrint = useRef();
  let navigate = useNavigate();
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const SingleListData = useSelector(
    (state) => state?.salesReport?.salesReportBySalesPerson
  );
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const initialvalue = {
    label: "Daily Sales Report",
    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
  };

  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const [formValues, setFormValues] = useState(initialvalue);
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  useEffect(() => {
    setFormValues({
      ...formValues,

      invoiceCount: SingleListData?.total?.invoiceCount || "0",
      invoiceAmount: SingleListData?.total?.invoiceAmount || "0",
      returnCount: SingleListData?.total?.returnCount || "0.00",
      returnAmount: SingleListData?.total?.returnAmount || "0.00",
      tax: SingleListData?.total?.tax || "0.00",
      amount: SingleListData?.total?.amount || "0.00",
      receivedAmount: SingleListData?.total?.receivedAmount || "0.00",
      creditAmount: SingleListData?.total?.creditAmount || "0.00",

      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",
    });
    setData(SingleListData?.report);
  }, [SingleListData, companyProfileData]);
  const downloadList = () => {
    convertArrayToExcel(SingleListData?.report, "SalesbysalesReport");
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
const currencySymbol=SingleListData?.currency?.currencyCode;

  const singleViewId = localStorage.getItem("customerReceiptSingleViewId");

  const totalPages = 10;
  const decimalPosition = localStorage.getItem("decimalPosition");

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowClick = (row) => {
    localStorage.setItem("SingleViewId", row?._id);
    store.dispatch( setFilterActive(window.location.pathname))
    navigate("/userdashboard/sales/Report/salesReportBySalesSingleView", { state: { staff_name: row?.staff_name } });
  };

  useEffect(() => {
    if (FormDataInfo && FormDataInfo?.length !== 0) {
      let body = {
        startDate:
          FormDataInfo?.fromDate === ""
            ? ""
            : `${FormDataInfo?.fromDate} 00:00:00`,
        endDate:
          FormDataInfo?.toDate === "" ? "" : `${FormDataInfo?.toDate} 23:59:59`,
        branchId: FormDataInfo?.branchList,
        search: FormDataInfo?.search,
      };
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&  SalesReportBySalesPersonAPIcall(body, setIsLoading);
    }
  }, [FormDataInfo]);


  return (
    <div className="global-page-parent-container">
      {/* table */}
      <div className="global-white-bg-container">
        <div
          className="create-button-blue-container"
          style={{
            margin: "1%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <div style={{ marginLeft: "19px" }}>
            <Tooltip title="Share">
              <IconButton>
                <i
                  class="bi bi-share"
                  style={{
                    backgroundColor: "#9797e9",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>

            <ReactToPrint
              trigger={() => (
                <IconButton className="printer-div" style={{ padding: "1px" }}>
                  <i
                    style={{
                      color: "white",
                      backgroundColor: " #d787e7",
                      fontSize: "medium",
                      padding: "8px",
                      borderRadius: "2px",
                    }}
                    class="bi bi-printer"
                  ></i>
                </IconButton>
              )}
              content={() => SalesbySalesReportPrint}
            />

            <Tooltip title="Download">
              <IconButton onClick={downloadList}>
                <i
                  class="bi bi-arrow-down-circle"
                  style={{
                    backgroundColor: "rgb(255 118 133 / 69%)",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              // width: '100%'
            }}
          >
            <CategorySearchandFilter
              // statusOptions={['POSTED', 'DRAFT']}
              onData={handleDataFromChild}
              style={{ width: "100%" }}
              onBranchSelect={handleBranchSelect}
              // isLocation={true}
              // isCategory={true}
              // isposCategory={true}
              // isDate={false}

              // isStatus={true}
            />
            {/* <IconButton
              style={{ width: "100px" }}

              className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
            <IconButton
              style={{ width: "100px" }}
              className="download-icon-container">
              <i class="bi bi-arrow-down-circle download-icon"></i>
            </IconButton> */}
          </div>
        </div>

        <div className="create-button-blue-container">
          <h3> Sales Report By Sales Person</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
        <div className="customer-receipt-table-container">
          <table>
            <thead>
              <tr>
                <th>EMPLOYEE ID</th>
                <th>EMPLOYEE</th>
                <th>INVOICE COUNT</th>
                <th>INVOICE AMOUNT</th>
                <th>SALES RETURN COUNT</th>
                <th>SALES RETURN AMOUNT</th>
                <th>TOTAL TAX</th>
                <th>TOTAL DISCOUNT</th>
                <th>RECEIVED AMOUNT</th>
                <th>CREDIT AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : SingleListData?.report !== undefined &&
                SingleListData?.report?.length !== 0 ? (
                SingleListData?.report
                  ?.slice(0)
                  ?.reverse()
                  ?.map((r, i) => (
                    <tr key={i} onClick={() => handleRowClick(r)}>
                      <td>{r?.emp_id}</td>
                      <td>{r?.staff_name}</td>
                      <td>{r?.invoiceCount}</td>
                      <td style={{ textAlign: "end" }}>
                      {currencySymbol}&nbsp;
                        {r?.invoiceAmount?.toFixed(decimalPosition)}
                      </td>
                      <td>{r?.returnCount}</td>
                      <td style={{ textAlign: "end", paddingRight: "2px" }}>
                      {currencySymbol}&nbsp;  {r?.returnAmount?.toFixed(decimalPosition) || "0.00"}
                      </td>
                      <td style={{ textAlign: "end" }}>
                      {currencySymbol}&nbsp;{r?.tax?.toFixed(decimalPosition)}
                      </td>
                      <td style={{ textAlign: "end" }}>
                      {currencySymbol}&nbsp;{r?.discount?.toFixed(decimalPosition)}
                      </td>
                      <td style={{ textAlign: "end" }}>
                      {currencySymbol}&nbsp;
                        {r?.receivedAmount?.toFixed(decimalPosition)}
                      </td>
                      <td style={{ textAlign: "end" }}>
                      {currencySymbol}&nbsp;
                        {r?.creditAmount?.toFixed(decimalPosition)}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td style={{ textAlign: "center" }} colSpan={9}>
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              {/* <tr>
                <th style={{ paddingLeft: '2%',textAlign: 'start', fontSize: '0.8rem'}} colSpan={2}>TOTAL</th>
                <th>60</th>
                <th>7500.00</th>
                <th>4</th>
                <th>5200.00</th>
                <th>960.00</th>
                <th>1800.00</th>
                <th>600.00</th>
                <th>900.00</th>
           
              </tr> */}
              <tr>
                <th
                  style={{
                    paddingLeft: "2%",
                    textAlign: "start",
                    fontSize: "0.8rem",
                  }}
                  colSpan={2}
                >
                  TOTAL
                </th>
                <th>
                  {SingleListData?.total !== undefined &&
                    SingleListData?.total?.invoiceCount}
                </th>
                <th style={{ textAlign: "end" }}>
                {currencySymbol}&nbsp;
                  {SingleListData?.total !== undefined &&
                    SingleListData?.total?.invoiceAmount?.toFixed(
                      decimalPosition
                    )}
                </th>
                <th>
                  {SingleListData?.total !== undefined &&
                    SingleListData?.total?.returnCount}
                </th>
                <th style={{ textAlign: "end" }}>
                {currencySymbol}&nbsp;
                  {SingleListData?.total !== undefined &&
                    SingleListData?.total?.returnAmount?.toFixed(
                      decimalPosition
                    )}
                </th>
                <th style={{ textAlign: "end" }}>
                {currencySymbol}&nbsp;
                  {SingleListData?.total !== undefined &&
                    SingleListData?.total?.tax?.toFixed(decimalPosition)}
                </th>
                <th style={{ textAlign: "end" }}>
                {currencySymbol}&nbsp;
                  {(SingleListData?.total !== undefined &&
                    SingleListData?.total?.discount?.toFixed(
                      decimalPosition
                    )) ||
                    "0.00"}
                </th>
                <th style={{ textAlign: "end" }}>
                {currencySymbol}&nbsp;
                  {SingleListData?.total !== undefined &&
                    SingleListData?.total?.receivedAmount?.toFixed(
                      decimalPosition
                    )}
                </th>

                <th style={{ textAlign: "end" }}>
                {currencySymbol}&nbsp;
                  {SingleListData?.total !== undefined &&
                    SingleListData?.total?.creditAmount?.toFixed(
                      decimalPosition
                    )}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={(el) => (SalesbySalesReportPrint = el)}>
          <Template data={data} formValues={formValues} total={total} />
        </div>
      </div>
    </div>
  );
}

export default SalesReportBySalesPerson;
