import React, { useEffect, useLayoutEffect, useState } from 'react'
import barcode from '../../../../Assets/Images/barcode.png'
import { convertDateFormat } from '../../../../Js/Date';
import { removeDuplicateFromAoO } from '../../../../Js/generalFunctions';
import Barcode from 'react-barcode';

export const ThobCuttingSlip1 = (props) => {
    const {qr,cuttingSlipData,printingObject,copy}=props;

    const [bottomTable, setBottomTable] = useState([]);
    const [totalQty,setTotalQty]=useState()
    // printing object table without duplicate obj
    const [filterTable,setFilterTable]=useState([])

  
    const seenStitchingNames = new Set();
 //Extract Bottom Table to new State and It must be unique
//  const extractBottomTableWithoutDuplication = (printingObject) => {
//   let unique=[]
//   let bottomTable = printingObject?.materials?.map((r, i) => {
//     return {
//       marModelName: r.marModelName,
//       fpName: r.fpName,
//       penName: r.penName,
//       mobilePocket: r.mobilePocket,
//       buttonNo: r.buttonNo,
//       sidePTName: r.sidePTName,
//       sidePTSize: r.sidePTSize,
//       remark: r.remark,
//     };
//   });
//   unique = removeDuplicateFromAoO(bottomTable);
//    setBottomTable(unique)

// };

// useLayoutEffect(() => {
//   extractBottomTableWithoutDuplication(printingObject);
// }, [printingObject]);



useLayoutEffect(()=>{
     let total=0
      printingObject?.materials?.forEach((r,i)=>{
      total +=r?.qty
      })
      setTotalQty(total)
     
},[printingObject])
   

// remove duplicate item from printing object table 
const handleRemoveDuplicates = () => {
  if(printingObject?.materials !== undefined && printingObject?.materials !== null ){
    let result = Object.values(printingObject?.materials.reduce((acc, obj) => {
      let key = `${obj.mName ?? ""}-${obj.collarName ?? ""}-${obj.thobModelName ?? ""}-${obj.marModelName ?? ""}-${obj.stitchingName ?? ""}-${obj.cuffClothName??""}-${obj.cuffModelName??""}-${obj.penName??""}-${obj.collarModel??""}`; // handle null or undefined values
      if (acc[key]) {
        acc[key].qty += obj.qty;
      } else {
        acc[key] = { ...obj };
      }
      return acc;
    }, {}));
    setFilterTable(result);
  }
}

useLayoutEffect(()=>{
  handleRemoveDuplicates()
},[printingObject])

 console.log(printingObject);





  return (
    <div className="thob-cutting-slip-container">
      <div className="thob-cutting-slip-top-container">
        <div className="thob-cutting-slip-top-first-content">
          <p></p>
          <p className="main-heading-thob-cutting-slip1">THOB CUTTING SLIP</p>
          <h3></h3>
        </div>
        <div className="thob-cutting-slip-top-second-content">
          <div className='thob1-cutting-slip-top-second-content-left'>
            {
              cuttingSlipData?.isCode === false && 
              <Barcode
              value={cuttingSlipData?.barcodeNumber}
              width={1}
              fontSize={16}
              height={30}
              margin={"2px"}
            />
            }
             
            {/* <img src={barcode} alt="" /> */}

            <p>CUSTOMER:&nbsp;
              {printingObject?.relationName === "null"
              ? cuttingSlipData?.cusName
              : printingObject?.relationName
              ||printingObject === undefined? "":undefined
              }
             </p>
            <p>{cuttingSlipData?.woNo || ""}</p>
            <p>EMPLOYEE:&nbsp;{cuttingSlipData?.employee||''}</p>
          </div>
          <div className='thob1-cutting-slip-top-second-content-right'>
            <div></div>
            <div style={{display:"flex",justifyContent:"space-between",height:"25%"}}>
            <p>ORDER DATE:</p>
            <p>{convertDateFormat(cuttingSlipData?.ordDate) || "-NIL-"}</p>
            </div>
            <div style={{display:"flex",justifyContent:"space-between",height:"25%"}}>
            <p>DELIVERY DATE:</p>
            <p>{convertDateFormat(cuttingSlipData?.delDate) || "-NIL-"}</p>
            </div>
          
            
          </div>
        </div>
      </div>
      <hr className='thob1-cutting-slip-border'/>
      <div className="thob-cutting-slip-middle-container">
       <div className="thob1-cutting-slip-middle-top-container">
          <div className='thob1-cuttinG-slip-single-border-div'>
            <p className='thob1-cutting-slip-single-left-p' >Length</p>
            {/* length */}
            <p style={{fontSize:"0.9rem",fontWeight:600}}>{printingObject?.length || ""}</p>
          </div>
          <div className='thob1-cuttinG-slip-single-border-div' style={{width:"50%"}}>
            <p className='thob1-cutting-slip-single-left-p'>Shoulder</p>
            {/* shoulder  -dbt */}
            <p style={{fontSize:"0.9rem",fontWeight:600}}>{printingObject?.neckDButton || ""}</p>
          </div>
       </div>
       <div className="thob1-cutting-slip-middle-top-second-container">
           <div className='thob1-cuttinG-slip-single-border-div' style={{width:"18%",borderRight:"1px solid #0000",borderTop:"1px solid #0000"}}>
              <p className='thob1-cutting-slip-single-left-p'>L Shape</p>
              {/* l shape */}
             <p style={{fontSize:"0.9rem",fontWeight:600}}>{printingObject?.materials[0]?.collarSize || ""}</p>
           </div>
           {/* sleeve */}
           <div className='thob1-cuttinG-slip-single-border-div' style={{width:"18%"}}>
              <p className='thob1-cutting-slip-single-left-p'>Sleeve</p>
               {/* sleeve */}
               <div style={{display:"flex",flexDirection:"column"}}>
               <p style={{fontSize:"0.9rem",fontWeight:600}}>{printingObject?.shoulder || ""}</p>
               {/* <p style={{fontSize:"0.9rem",fontWeight:600}}>{filterTable !== undefined && filterTable[0]?.collarCloth}</p> */}
                </div>
           
           </div>
            {/* sada */}
            <div className='thob1-cuttinG-slip-single-border-div' style={{width:"18%"}}>
              <p className='thob1-cutting-slip-single-left-p'>Sada</p>
              {/* sada*/}
              <p style={{fontSize:"0.9rem",fontWeight:600}}>{printingObject?.sleeve || ""}</p>
           </div>
           {/* sleeve */}
           <div className='thob1-cuttinG-slip-single-border-div' style={{width:"18%"}}>
              <p className='thob1-cutting-slip-single-left-p button-type'>Sleeve </p>
            {/* mar model */}
            <div style={{display:"flex",flexDirection:"column"}}>
                 {/* {filterTable?.map((r)=>(
                  <p style={{fontSize:"0.9rem",fontWeight:600}}>{r?.collarCloth}</p>
                ))} */}
                 {filterTable?.map((r, i) => {
                if (!seenStitchingNames.has(r?.collarCloth)) {
                  seenStitchingNames.add(r?.collarCloth);
                  return (
                    <p key={i} style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                      {r?.collarCloth}
                    </p>
                  );
                }
                return null;
              })}
                
            </div>
           </div>
           {/* sleeve model */}
           <div className='thob1-cuttinG-slip-single-border-div' style={{width:"18%"}}>
              <p className='thob1-cutting-slip-single-left-p button-type'>Sleeve Model</p>
            {/* mar model */}
            <div style={{display:"flex",flexDirection:"column"}}>
                 {/* {filterTable?.map((r)=>(
                  <p style={{fontSize:"0.9rem",fontWeight:600}}>{r?.collarModel}</p>
                ))} */}
                 {filterTable?.map((r, i) => {
                if (!seenStitchingNames.has(r?.collarModel)) {
                  seenStitchingNames.add(r?.collarModel);
                  return (
                    <p key={i} style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                      {r?.collarModel}
                    </p>
                  );
                }
                return null;
              })}
                
            </div>
           </div>
          
         
           
       </div>
       <div className="thob1-cutting-slip-middle-top-third-container">
           <div className='thob1-cuttinG-slip-single-border-div' style={{width:"15%"}}>
              <p className='thob1-cutting-slip-single-left-p'>Regal</p>
              {/* regal */}
             <p style={{fontSize:"0.9rem",fontWeight:600}}>{printingObject?.chest || ""}</p>
           </div>
           <div className='thob1-cuttinG-slip-single-border-div' style={{width:"15%"}}>
              <p className='thob1-cutting-slip-single-left-p'>Body</p>
              {/* regal */}
             <p style={{fontSize:"0.9rem",fontWeight:600}}>{printingObject?.stomach || ""}</p>
           </div>
           
           <div className='thob1-cuttinG-slip-single-border-div' style={{width:"15%"}}>
              <p className='thob1-cutting-slip-single-left-p'>Side</p>
             {/* side */}
             <p style={{fontSize:"0.9rem",fontWeight:600}}>{printingObject?.regalSize || ""}</p>
           </div>
           <div className='thob1-cuttinG-slip-single-border-div' style={{width:"15%"}}>
              <p className='thob1-cutting-slip-single-left-p'>Centre</p>
             {/* centre */}
            <p style={{fontSize:"0.9rem",fontWeight:600}}>{printingObject?.kneeLoose || ""}</p>
           </div>
           <div className='thob1-cuttinG-slip-single-border-div' style={{width:"15%"}}>
              <p className='thob1-cutting-slip-single-left-p thob1-marsize'>Mar Size</p>
            {/* mar size */}
            <p style={{fontSize:"0.9rem",fontWeight:600}}>{printingObject?.fpDown}</p>
           </div>
           <div className='thob1-cuttinG-slip-single-border-div' style={{width:"18%"}}>
              <p className='thob1-cutting-slip-single-left-p thob1-marsize'>Mar Model</p>
            {/* mar model */}
            <div style={{display:"flex",flexDirection:"column"}}>
                {/* {printingObject?.materials?.map((r)=>(
                  <p style={{fontSize:"0.9rem",fontWeight:600}}>{r?.cuffName}</p>
                ))} */}
                 {/* {filterTable?.map((r)=>(
                  <p style={{fontSize:"0.9rem",fontWeight:600}}>{r?.marModelName}</p>
                ))} */}
                 {filterTable?.map((r, i) => {
                if (!seenStitchingNames.has(r?.marModel)) {
                  seenStitchingNames.add(r?.marModel);
                  return (
                    <p key={i} style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                      {r?.marModelName}
                    </p>
                  );
                }
                return null;
              })}
                
            </div>
           </div>
      </div>
       <div className="thob1-cutting-slip-middle-top-four-container">
        <div className='thob1-cuttinG-slip-single-border-div' style={{width:"18%"}}>
           <p className='thob1-cutting-slip-single-left-p'>PT Down</p>
          {/* pt down */}
          {/* <div style={{display:"flex",flexDirection:"column"}}>
            {bottomTable?.map((r)=>(
               <p style={{fontSize:"0.84rem"}}>{r?.fpName}</p>
            ))}
         </div> */}
           <p style={{fontSize:"0.9rem",fontWeight:600}}>{printingObject?.materials[0]?.cuffSize1}</p>
        </div>
        <div className='thob1-cuttinG-slip-single-border-div' style={{width:"25%"}}>
          <p className='thob1-cutting-slip-single-left-p'>Size</p>
          {/* size */}
          <p style={{fontSize:"0.9rem",fontWeight:600}}>{printingObject?.fpSize1||""}</p>
        </div>
        <div className='thob1-cuttinG-slip-single-border-div' style={{width:"18%"}}>
           <p className='thob1-cutting-slip-single-left-p'>Pen</p>
            {/* pen */}
            <div style={{display:"flex",flexDirection:"column"}}>
                {/* {filterTable?.map((r)=>(
                  <p style={{fontSize:"0.9rem",fontWeight:600}}>{r?.penName}</p>
                ))} */}
                  {filterTable?.map((r, i) => {
                if (!seenStitchingNames.has(r?.pen)) {
                  seenStitchingNames.add(r?.pen);
                  return (
                    <p key={i} style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                      {r?.penName}
                    </p>
                  );
                }
                return null;
              })}
            </div>
        </div>
        <div className='thob1-cuttinG-slip-single-border-div' style={{width:"36%"}}>
          <p className='thob1-cutting-slip-single-left-p thob1-patcanvas'>PT Canvas</p>
          <div style={{display:"flex",flexDirection:"column"}}>
                {/* {filterTable?.map((r)=>(
                  <p style={{fontSize:"0.9rem",fontWeight:600}}>{r?.stitchingName}</p>
                ))} */}
                 {filterTable?.map((r, i) => {
                if (!seenStitchingNames.has(r?.stitching)) {
                  seenStitchingNames.add(r?.stitching);
                  return (
                    <p key={i} style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                      {r?.stitchingName}
                    </p>
                  );
                }
                return null;
              })}
            </div>
        </div>
      
       </div>
       <div className="thob1-cutting-slip-middle-top-four-container">
         <div className='thob1-cuttinG-slip-single-border-div' style={{width:"55%"}}>
           <p className='thob1-cutting-slip-single-left-p thob1-pc'>Neck</p>
           {/* pc */}
           <p style={{fontSize:"0.9rem",fontWeight:600}}> {printingObject?.neck||""}</p>
          </div>
       </div>
       <div className="thob1-cutting-slip-middle-top-four-container">
        <div className='thob1-cuttinG-slip-single-border-div' style={{width:"55%"}}>
           <p className='thob1-cutting-slip-single-left-p thob1-pc'>PC</p>
           {/* pc */}
           <p style={{fontSize:"0.9rem",fontWeight:600}}> {printingObject?.bottom||""}</p>
        </div>
        
       </div>
       <div className='thob1-cutting-slip-middle-top-five-container'>
         <div className='thob1-cutting-slip-middle-top-five-left-container'>
           <div className='thob1-cuttinG-slip-single-border-div' style={{width:"45%"}}>
             <p className='thob1-cutting-slip-single-left-p'>Bottom</p>
             {/* bOTTOM */}
            <p style={{fontSize:"0.9rem",fontWeight:600}}>{printingObject?.marSize || ""}</p>
           </div>
           <div className='thob1-cuttinG-slip-single-border-div' style={{width:"49%"}}>
             <p className='thob1-cutting-slip-single-left-p thob1-pc'>FB</p>
             {/* FB */}
             <p style={{fontSize:"0.9rem",fontWeight:600}}>{filterTable[0]?.sidePTSize}</p>
              
           </div>
           
         
         </div>
         <div className='thob1-cuttinG-slip-single-border-div' style={{width:"40%"}}>
           <p className='thob1-cutting-slip-single-left-p thob1-patcanvas'>Button No</p>
           <div style={{display:"flex",flexDirection:"column"}}>
          
               <p style={{fontSize:"0.9rem",fontWeight:600}}>{filterTable[0]?.buttonNo}</p>
          
          </div>
         </div>
        
       </div>
       <div className='thob1-cutting-slip-middle-top-six-container' style={{width:"70%"}}>
         <div className='thob1-cuttinG-slip-single-border-div' style={{width:"25%"}}>
            <p className='thob1-cutting-slip-single-left-p'>Stitching</p>
            <div style={{display:"flex",flexDirection:"column"}}>
            {/* {filterTable?.map((r)=>(
               <p style={{fontSize:"0.9rem",fontWeight:600}}>{r?.cuffClothName}</p>
            ))} */}
             {filterTable?.map((r, i) => {
                if (!seenStitchingNames.has(r?.cuffCloth)) {
                  seenStitchingNames.add(r?.cuffCloth);
                  return (
                    <p key={i} style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                      {r?.cuffClothName}
                    </p>
                  );
                }
                return null;
              })}
            </div>
         </div>
         <div className='thob1-cuttinG-slip-single-border-div' style={{width:"27%"}}>
          <p className='thob1-cutting-slip-single-left-p button-type'>Button Type</p>
          <div style={{display:"flex",flexDirection:"column"}}>
            {/* {filterTable?.map((r)=>(
               <p style={{fontSize:"0.9rem",fontWeight:600}}>{r?.cuffModelName}</p>
            ))} */}
            {filterTable?.map((r, i) => {
                if (!seenStitchingNames.has(r?.cuffModel)) {
                  seenStitchingNames.add(r?.cuffModel);
                  return (
                    <p key={i} style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                      {r?.cuffModelName}
                    </p>
                  );
                }
                return null;
              })}
            </div>
         </div>
         <div className='thob1-cuttinG-slip-single-border-div' style={{width:"40%"}}>
          <p className='thob1-cutting-slip-single-left-p button-type'>Notes</p>
          <div style={{display:"flex",flexDirection:"column"}}>
              {printingObject?.slSo}
            </div>
         </div>
       
            
            
       
        
            
       </div>
       {/* <div className='thob1-cutting-slip-middle-top-seven-container'>
        <div className='thob1-cuttinG-slip-single-border-div' style={{width:"10%"}}>
          <p className='thob1-cutting-slip-single-left-p thob1-pc'>QTY</p>
          <p style={{fontSize:"0.9rem",fontWeight:600}}>{totalQty}</p>
        </div>
       
       </div> */}
       <div className='thob1-cutting-slip-middle-top-eight-container'>
        <div style={{width:"6%"}}></div>
        <div className='thob1-cuttinG-slip-single-border-div' style={{width:"21%"}}>
          <p className='thob1-cutting-slip-single-left-p thob1-pc'>Down</p>
          <div style={{width:"60%",display:"flex",justifyContent:"space-evenly"}}>
            <p style={{fontSize:"0.9rem",fontWeight:600}}>{printingObject?.down}</p>
          </div>
        </div>
        <div className='thob1-cuttinG-slip-single-border-div' style={{width:"21%"}}>
          <p className='thob1-cutting-slip-single-left-p thob1-pc'>Veedi</p>
          <div style={{width:"60%",display:"flex",justifyContent:"space-evenly"}}>
            <p style={{fontSize:"0.9rem",fontWeight:600}}>{printingObject?.veedi}</p>
          </div>
        </div>
        <div className='thob1-cuttinG-slip-single-border-div' style={{width:"21%"}}>
          <p className='thob1-cutting-slip-single-left-p thob1-pc'>Bag</p>
          <div style={{width:"60%",display:"flex",justifyContent:"space-evenly"}}>
            <p style={{fontSize:"0.9rem",fontWeight:600}}>{printingObject?.bag}</p>
          </div>
        </div>
        <div className='thob1-cuttinG-slip-single-border-div' style={{width:"21%"}}>
          <p className='thob1-cutting-slip-single-left-p thob1-pc'>Open</p>
          <div style={{width:"60%",display:"flex",justifyContent:"space-evenly"}}>
            <p style={{fontSize:"0.9rem",fontWeight:600}}>{printingObject?.open}</p>
          </div>
        </div>
        <div className='thob1-cuttinG-slip-single-border-div' style={{width:"8%"}}>
          <p className='thob1-cutting-slip-single-left-p thob1-pc'>QTY</p>
          <div style={{width:"60%",display:"flex",justifyContent:"space-evenly"}}>
          <p style={{fontSize:"0.9rem",fontWeight:600}}>{totalQty}</p>
          </div>
        </div>
       
        
       </div>
       {/* <div className="thob-cutting-slip-middle-table-container">
          <table>
            <thead>
              <tr>
                <th>Selected Clothes</th>
                <th>Colour</th>
                <th>Qty</th>
                <th>Model</th>
                <th>Cuff</th>
                <th>Size</th>
                <th>Cuff Cloth</th>
                <th>Model</th>
                <th>Collar</th>
                <th>Size</th>
                <th>Cloth</th>
                <th>Model</th>
                <th>Button</th>
                <th>Stitching</th>
              </tr>
            </thead>
            <tbody>
              {printingObject?.materials?.map((r, i) => (
                <tr key={i}>
                  <td>{r?.mName?.toUpperCase()}</td>
                  <td>{r?.color?.toUpperCase()}</td>
                  <td>{r?.qty}</td>
                  <td>{r?.thobModelName}</td>
                  <td>{r?.cuffName}</td>
                  <td>
                    {r?.cuffSize1} &nbsp; {r?.cuffSize2}
                  </td>
                  <td>{r?.cuffClothName}</td>
                  <td>{r?.cuffModelName}</td>
                  <td>{r?.collarName}</td>
                  <td>{r?.collarSize}</td>
                  <td>{r?.collarClothName}</td>
                  <td>{r?.collarModelName}</td>
                  <td>{r?.buttonName}</td>
                  <td>{r?.stitchingName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
        <div style={{display:"flex",width:"100%",justifyContent:"space-between",margin:"1% 0"}}> 
        <div className="thob-cutting-slip-middle-table-container" style={{width:"80%"}}>
          <table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Qty</th>
                <th>Type</th>
                <th>Model</th>
                <th>MTR</th>
                <th>MTR Used</th>
               </tr>
            </thead>
            <tbody>
              {filterTable?.map((r, i) => (
                <tr key={i}>
                  <td>{r?.mName?.toUpperCase()}</td>
                  <td>{r?.qty}</td>
                  <td>{r?.collarName}</td>
                  <td>{r?.thobModelName}</td> 
                  <td>{r?.mtr}</td>
                  <td>{r?.mtrUsed}</td>
                 
                  
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {
          cuttingSlipData?.isCode === true && 
           <div className="thob-cutting-slip-table-qrCode-qrcode" style={{width:"18%"}}>
              <img src={qr} alt="qrCode" />
           </div>
        }
       
        </div> 
        

        {/* <div className="thob-cutting-slip-table-qrCode-container">
          <div className="thob-cutting-slip-table-qrCode-table">
            <table>
              <thead>
                <tr>
                  <th>MAR Model</th>
                  <th>FP Model</th>
                  <th>Pen</th>
                  <th>Mobile Pocket</th>
                  <th>Button No.</th>
                  <th>Side Pocket Model</th>
                  <th>Side Pocket Size</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                {bottomTable?.map((r, i) => (
                  <tr key={i}>
                    <td>{r?.marModelName}</td>
                    <td>{r?.fpName}</td>
                    <td>{r?.penName}</td>
                    <td>{r?.mobilePocket?.toUpperCase()}</td>
                    <td>{r?.buttonNo}</td>
                    <td>{r?.sidePTName}</td>
                    <td>{r?.sidePTSize}</td>
                    <td>{r?.remark}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> 
          <div className="thob-cutting-slip-table-qrCode-qrcode">
            <img src={qr} alt="qrCode" />
          </div> 
        </div> */}
        <div className='thob1-cutting-slip-middle-top-nine-container'>
            <p>Cutter:
              {
                printingObject?.cuttingMasters?.map((cMaster, i)=>(
                  <span key={i}>{i ===printingObject?.cuttingMasters?.length-1? cMaster : cMaster +","}</span>
                ))
              }
              </p>
             
        </div>
      </div>
    </div>
  )
}
