import { createSlice } from "@reduxjs/toolkit";

export const posReaderPreviewSlice = createSlice({
  name: "posReaderPreviewSlice",
  initialState: {
    qrCodeData: null,
  },
  reducers: {
    setQrCodeData: (state, action) => {
      state.qrCodeData = action.payload;
    },
  },
});

export const { setQrCodeData } = posReaderPreviewSlice.actions;

export default posReaderPreviewSlice.reducer;
