import axios from "axios";
import { HEADERS } from "../../../../API/UrlAndPaths";
import store from "../../../../Redux/store";
import { setStockMove, setStockMoveSigleView ,set_Stock_Move_Aging_View} from "./ReportSlice";

export const StockMoveAPIcall=async(body)=>{
    await axios
    .post("inventory/stockMoveReport",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(setStockMove({stockMoveList:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(setStockMove({stockMoveList:undefined}))
        console.error(err.response);
    })
}
export const StockMoveAgingReportAPIcall=async(body)=>{
    await axios
    .post("inventory/stockAgingReport",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(set_Stock_Move_Aging_View({StockMoveAgingReportList:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(set_Stock_Move_Aging_View({StockMoveAgingReportList:undefined}))
        console.error(err.response);
    })
}

export const StockMoveSingleAPIcall=async(body)=>{
    await axios
    .post("inventory/stockMoveReportSingleView",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            console.log("hellooo",res.data);
            store.dispatch(setStockMoveSigleView({stockMoveSingleList:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch( setStockMoveSigleView({stockMoveSingleList:undefined}))
        console.error(err.response);
    })
}