import { createSlice } from "@reduxjs/toolkit";

export const allProductsListSlice=createSlice({
    name:'allProductsListSlice',
    initialState:{
        value:undefined,
        index:0,
        singleView:undefined
    },
    reducers:{
        update_all_products_list:(state,action)=>{
            state.value=action.payload.allProductsList
        },
        update_product_list_single_view:(state,action)=>{
            state.singleView=action.payload.productListSingleView
        },
        incrementProductListIndex:(state,action)=>{
            console.log(action.payload.allProductsList.length)
            if(action.payload.allProductsList.length >=30){
                state.index +=1;
            }

        },
        decrementProductListIndex:(state,action)=>{
            if(state.index > 0){
                 state.index -=1
            }
        },
        resetProductListIndex:(state,action)=>{
               state.index = 0
        }
    }
})

export const {update_all_products_list,update_product_list_single_view,incrementProductListIndex,decrementProductListIndex,resetProductListIndex}=allProductsListSlice.actions
export default allProductsListSlice.reducer
