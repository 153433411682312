import { Boy } from "@mui/icons-material";
import axios from "axios";
import { update_heldWrokOrder_list } from "../../Redux/Orders/Held work order/heldWorkOrderSlice";
import { update_invoice } from "../../Redux/Orders/Invoice/invoiceSlice";
import { update_work_order_nos } from "../../Redux/Orders/Print Cutting slip/workOrderNosSlice";
import { update_branchWise_rackList } from "../../Redux/Orders/Rack/branchWiseRackList";
import { update_rackList } from "../../Redux/Orders/Rack/rackListSlice";
import { update_bottom_model } from "../../Redux/Orders/WorkOrder/bottomModelSlice";
import { update_button } from "../../Redux/Orders/WorkOrder/buttonSlice";
import { update_collar_cloth } from "../../Redux/Orders/WorkOrder/collarClothSlice";
import { update_collar_model } from "../../Redux/Orders/WorkOrder/collarModelSlice";
import { update_collar } from "../../Redux/Orders/WorkOrder/collarSlice";
import { update_cuff_cloth } from "../../Redux/Orders/WorkOrder/cuffClothSlice";
import { update_cuff } from "../../Redux/Orders/WorkOrder/cuffDataSlice";
import { update_cuff_model } from "../../Redux/Orders/WorkOrder/cuffModelSlice";
import { update_cup } from "../../Redux/Orders/WorkOrder/cupSlice";
import { update_customer_measurements } from "../../Redux/Orders/WorkOrder/customerMeasurmentsSlice";
import { update_elastic_width } from "../../Redux/Orders/WorkOrder/elasticWidthSlice";
import { update_fpModel } from "../../Redux/Orders/WorkOrder/fpModelSlice";
import { update_jalabia_model } from "../../Redux/Orders/WorkOrder/jalabiaModelSlice";
import { update_length_commission } from "../../Redux/Orders/WorkOrder/lengthCommissionSlice";
import { update_marModel } from "../../Redux/Orders/WorkOrder/marModelDataSlice";
import { update_models_from_userSide } from "../../Redux/Orders/WorkOrder/modelsFromUserSide";
import { update_model } from "../../Redux/Orders/WorkOrder/modelSlice";
import { update_neck_model } from "../../Redux/Orders/WorkOrder/neckModelSlice";
import { update_new_work_order_response } from "../../Redux/Orders/WorkOrder/newWorkOrderResponse";
import { update_pen } from "../../Redux/Orders/WorkOrder/penSlice";
import { update_sidePT_model } from "../../Redux/Orders/WorkOrder/sidePtModelSlice";
import { update_stitching } from "../../Redux/Orders/WorkOrder/stitchingSlice";
import { update_thobModel } from "../../Redux/Orders/WorkOrder/thobModelDataSlice";
import { update_work_order_id } from "../../Redux/Orders/WorkOrder/workOrderId";
import store from "../../Redux/store";
import { HEADERS, HEADER_QUERY } from "../UrlAndPaths";
import { view_work_order_settings } from "../../Redux/Orders/WorkOrder/viewWorkOrderSettingsSlice";
//*Thob Model
//Add Thob Model
export const addThobModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addThobModel", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Thob Model added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//View Thob Model
export const viewThobModelAPICall = async () => {
  await axios
    .get("workorder/getAllThobModels", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_thobModel({ thobModel: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_thobModel({ thobModel: undefined }));
      console.error(err.response);
    });
};

//Delete Thob Model
export const deleteThobModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deleteThobModel", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Thob Model Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//*Length Commission
//Add Length Commission
export const addLengthCommissionAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addLengthCommission", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Length commission added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//View Length Commission
export const viewLengthCommissionAPICall = async () => {
  await axios
    .get("workorder/getAllLengthCommissions", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_length_commission({ lengthCommission: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(update_length_commission({ lengthCommission: undefined }));
      console.error(err.response);
    });
};
//Delete Length Commission
export const deleteLengthCommissionAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deleteLengthCommission", {
      headers: HEADER_QUERY,
      data: body,
    })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Length Commission Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//*Mar Model
//Add mar model
export const addMarModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addMarModel", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Mar model added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//View mar Model
export const viewMarModelAPICall = async () => {
  await axios
    .get("workorder/getAllMarModels", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_marModel({ marModel: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_marModel({ marModel: undefined }));
      console.error(err.response);
    });
};
//Delete Mar model
export const deleteMarModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deleteMarModel", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Mar model Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//*Cuff
//Add Cuff
export const addCuffAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addCuff", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Cuff added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//View Cuff
export const viewCuffAPICall = async () => {
  await axios
    .get("workorder/getAllCuffs", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_cuff({ cuff: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_cuff({ cuff: undefined }));
      console.error(err.response);
    });
};
//Delete Cuff
export const deleteCuffAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deleteCuff", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Cuff Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//*Cuff Cloth
//Add Cuff Cloth
export const addCuffClothAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addCuffCloth", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Cuff Cloth added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//View Cuff Cloth
export const viewCuffClothAPICall = async () => {
  await axios
    .get("workorder/getAllCuffCloths", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_cuff_cloth({ cuffCloth: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_cuff_cloth({ cuffCloth: undefined }));
      console.error(err.response);
    });
};
//Delete Cuff Cloth
export const deleteCuffClothAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deleteCuffCloth", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Cuff Cloth Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//*Cuff Model
//Add Cuff Model
export const addCuffModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addCuffModel", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Cuff Model added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//View Cuff Model
export const viewCuffModelAPICall = async () => {
  await axios
    .get("workorder/getAllCuffModels", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_cuff_model({ cuffModel: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_cuff_model({ cuffModel: undefined }));
      console.error(err.response);
    });
};
//Delete Cuff Model
export const deleteCuffModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deleteCuffModel", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Cuff Model Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//*Collar
//Add Collar
export const addCollarAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addCollar", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Collar added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//View Collar
export const viewCollarAPICall = async () => {
  await axios
    .get("workorder/getAllCollars", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_collar({ collar: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_collar({ collar: undefined }));
      console.error(err.response);
    });
};
//Delete Collar
export const deleteCollarAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deleteCollar", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Collar Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//*Collar Cloth
//Add Collar Cloth
export const addCollarClothAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addCollarCloth", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Collar Cloth added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//View Collar Cloth
export const viewCollarClothAPICall = async () => {
  await axios
    .get("workorder/getAllCollarCloths", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_collar_cloth({ collarCloth: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_collar_cloth({ collarCloth: undefined }));
      console.error(err.response);
    });
};
//Delete Collar Cloth
export const deleteCollarClothAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deleteCollarCloth", {
      headers: HEADER_QUERY,
      data: body,
    })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Collar Cloth Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//*Collar Model
//Add Collar Model
export const addCollarModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addCollarModel", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Collar Model added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//View Collar Model
export const viewCollarModelAPICall = async () => {
  await axios
    .get("workorder/getAllCollarModels", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_collar_model({ collarModel: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_collar_model({ collarModel: undefined }));
      console.error(err.response);
    });
};
//Delete Collar Model
export const deleteCollarModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deleteCollarModel", {
      headers: HEADER_QUERY,
      data: body,
    })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Collar Model Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//*Pen
//Add Pen
export const addPenAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addPen", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Pen added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//View Pen
export const viewPenAPICall = async () => {
  await axios
    .get("workorder/getAllPens", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_pen({ pen: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_pen({ pen: undefined }));
      console.error(err.response);
    });
};
//Delete Pen
export const deletePenAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deletePen", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Pen Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//*Side PT Model
//Add Side PT Model
export const addSidePtModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addSidePT", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Side PT Model added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//View Side PT Model
export const viewSidePtModelAPICall = async () => {
  await axios
    .get("workorder/getAllSidePTs", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_sidePT_model({ sidePtModel: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_sidePT_model({ sidePtModel: undefined }));
      console.error(err.response);
    });
};
//Delete Side PT Model
export const deleteSidePtModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deletesidePT", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Side PT Model Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//*Stitching
//Add Stitching
export const addStitchingAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addStitching", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Stitching added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//View Stitching
export const viewStitchingAPICall = async () => {
  await axios
    .get("workorder/getAllStitchings", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_stitching({ stitching: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_stitching({ stitching: undefined }));
      console.error(err.response);
    });
};
//Delete Stitching
export const deleteStitchingAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deleteStitching", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Stitching Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//*Button
//Add Button
export const addButtonAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addButton", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Button added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//View Button
export const viewButtonAPICall = async () => {
  await axios
    .get("workorder/getAllButtons", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_button({ button: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_button({ button: undefined }));
      console.error(err.response);
    });
};
//Delete Button
export const deleteButtonAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deleteButton", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Button Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//* Jalabia Model
//Add Jalabia Model
export const addJalabiaModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addJalabiaModel", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Jalabia Model added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//View Jalabia Model
export const viewJalabiaModelAPICall = async () => {
  await axios
    .get("workorder/getAllJalabiaModels", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_jalabia_model({ jalabiaModel: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_jalabia_model({ jalabiaModel: undefined }));
      console.error(err.response);
    });
};

//Delete Jalabia Model
export const deleteJalabiaModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deleteJalabiaModel", {
      headers: HEADER_QUERY,
      data: body,
    })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Jalabia Model Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//* Neck Model
//Add Neck Model
export const addNeckModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addNeckModel", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Neck Model added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//View Neck Model
export const viewNeckModelAPICall = async () => {
  await axios
    .get("workorder/getAllNeckModels", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_neck_model({ neckModel: res.data }));
      }
    })
    .catch((err) => {
      console.error(err.response);
      store.dispatch(update_neck_model({ neckModel: undefined }));
    });
};

//Delete Neck Model
export const deleteNeckModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deleteNeckModel", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Neck Model Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//* Model
//Add  Model
export const addModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addModel", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Model added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//View  Model
export const viewModelAPICall = async () => {
  await axios
    .get("workorder/getAllModels", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_model({ model: res.data }));
      }
    })
    .catch((err) => {
      console.error(err.response);
      store.dispatch(update_model({ model: undefined }));
    });
};
//Delete Model
export const deleteModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deleteModel", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Model Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//* FP Model
//Add  Model
export const addFpModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addFPModel", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("FP Model added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//View  Model
export const viewFpModelAPICall = async () => {
  await axios
    .get("workorder/getallFPModels", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_fpModel({ fpModel: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_fpModel({ fpModel: undefined }));
      console.error(err.response);
    });
};
//Delete Model
export const deleteFpModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deleteFPModel", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("FP Model Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//* Elastic Width
//Add  Elastic width
export const addElasticWidthAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addElasticWidth", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Elastic width added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//View  Elastic width
export const viewElasticWidthAPICall = async () => {
  await axios
    .get("workorder/getAllElasticWidths", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_elastic_width({ elasticWidth: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_elastic_width({ elasticWidth: undefined }));
      console.error(err.response);
    });
};
//Delete Elastic Width
export const deleteElasticWidthAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deleteElasticWidth", {
      headers: HEADER_QUERY,
      data: body,
    })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Elastic width Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//* Cup
//Add  Cup
export const addCupAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addCup", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Cup added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//View  Cup
export const viewCupAPICall = async () => {
  await axios
    .get("workorder/getAllCups", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_cup({ cup: res.data }));
      }
    })
    .catch((err) => {
      console.error(err.response);
      store.dispatch(update_cup({ cup: undefined }));
    });
};
//Delete Cup
export const deleteCupAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deleteCup", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Cup Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//* Bottom Model
//Add  Bottom model
export const addBottomModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addbottomModel", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Bottom Model added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//View  Bottom model
export const viewBottomModelAPICall = async () => {
  await axios
    .post("workorder/viewbottomModel", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_bottom_model({ bottomModel: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_bottom_model({ bottomModel: undefined }));
      console.error(err.response);
    });
};
//Delete Bottom model
export const deleteBottomModelAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("workorder/deletebottomModel", {
      headers: HEADER_QUERY,
      data: body,
    })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Bottom Model Deleted 👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//*-----------------------------------------------------------
//Generate Work order no
export const generateWoNoAPICall = async (body) => {
  await axios
    .post("workorder/generateWoNo", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_work_order_id({ workOrderId: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_work_order_id({ workOrderId: undefined }));
      console.error(err.response);
    });
};
//Create Rack
export const createRackAPICall = async (
  body,
  successSnack,
  errorSnack,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/addRack", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("New Rack Created 👍");
        updateListener();
        successSnack(true);
        clearStates();
      }
    })
    .catch((err) => {
      switch (err.response.status) {
        case undefined:
          setSnackMsg("Network Error!!");
          break;
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;
        case 409:
          setSnackMsg("Item already exist!!");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;

        default:
          break;
      }
      errorSnack(true);
    });
};
//View Rack List
export const viewRackListAPICall = async () => {
  await axios
    .get("workorder/getAllRacks", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_rackList({ rackList: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_rackList({ rackList: undefined }));
      console.error(err.response);
    });
};

//Add Model to Outlets (by admin)
export const addModelsToOutletsAPICall = async (
  body,
  setIsApiSuccess,
  setSuccess,
  setError,
  setSnackMsg,
  clearStates
) => {
  await axios
    .post("admin/addCategoryToLocation", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setIsApiSuccess(true);
        setSnackMsg("Categories updated in outlets 👍");
        setSuccess(true);
        // clearStates();
        console.log("Models updated in outlets 👍");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server error!!");
      }
      setIsApiSuccess(false);
      setError(true);
      console.error(err.response);
    });
};
//Get All Racks in branch
export const viewBranchWiseRackListAPICall = async () => {
  await axios
    .get("workorder/getAllRacksInBranch", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_branchWise_rackList({ branchWiseRackList: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        update_branchWise_rackList({ branchWiseRackList: undefined })
      );
      console.error(err.response);
    });
};

//Get Models from Branches (by user)
export const getModelsFromOutletAPICall = async (body) => {
  await axios
    .post("workorder/viewcategoryBranchById", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_models_from_userSide({ modelsForUser: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(update_models_from_userSide({ modelsForUser: undefined }));
      console.error(err.response);
    });
};

//create a workOrder
export const createNewWorkOrderAPICall = async (
  body,
  setIsLoading,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates,
  openInvoiceDialog,
  // generateInvoice
) => {
  setIsLoading(true);
  await axios
    .post("workorder/addWorkorder", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("New work order created 👍");
        store.dispatch(update_new_work_order_response({ newWORes: res.data }));
        clearStates();
        updateListener();
        setSuccess(true);
        // generateInvoice()
        generateInvoiceAPICall({ _id: res.data?._id }, openInvoiceDialog);
        setIsLoading(false);
        // clearBillingStates();
      }
    })
    .catch((err) => {
      store.dispatch(update_new_work_order_response({ newWORes: undefined }));
      switch (err.response.status) {
        case undefined:
          setSnackMsg("Network error!!");
          break;
        case 404:
          setSnackMsg(err.response.data?.msg);
          break;
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;
        case 406:
          setSnackMsg(`${err.response.data?.msg}!! Refresh and try again!!`);
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;

        default:
          setSnackMsg("Something wrong!!");
          break;
      }
      setIsLoading(false);
      setError(true);
    });
};

//Get Work order numbers
export const getWorkOrderNosAPICall = async (body) => {
  await axios
    .post("workorder/getWorkorderNos", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_work_order_nos({ orkOrderNos: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_work_order_nos({ orkOrderNos: undefined }));
      console.error(err.response);
    });
};

//Generate invoice after successful place of order
export const generateInvoiceAPICall = async (body, invoiceDialog) => {
  await axios
    .post("workorder/invoiceview", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_invoice({ invoice: res.data }));
        invoiceDialog();
        console.log("invoice generated");
      }
    })
    .catch((err) => {
      store.dispatch(update_invoice({ invoice: undefined }));
    });
};

//Update Payment (Intermediate payment in view order)
export const updateOrderPaymentAPICall = async (
  body,
  setIsLoading,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates,
  openInvoiceDialog,
  clearBillingStates
) => {



  setIsLoading(true);
  await axios
    .post("workorder/updatePayment", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("Payment Completed");
        setSnackMsg("Payment updated 👍");
        clearStates();
        updateListener();
        setSuccess(true);
        setIsLoading(false);
        clearBillingStates();
        // generateInvoice()
        generateInvoiceAPICall(
          { _id: res.data?.workorder?._id },
          openInvoiceDialog
        );
      }
    })
    .catch((err) => {
      switch (err.response.status) {
        case undefined:
          setSnackMsg("Network error!!");
          break;
        case 404:
          setSnackMsg("Shift is inactive!!");
          break;
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;
        case 422:
          setSnackMsg("Payment Failed!!");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;

        default:
          setSnackMsg("Something wrong!!");
          break;
      }
      setIsLoading(false);
      setError(true);
      console.error(err.response);
    });
};

//Fetch Customer measurements
export const getCustomerMeasurements = async (body) => {
  await axios
    .post("workorder/getMeasurements", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_customer_measurements({ customerMeasurements: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        update_customer_measurements({ customerMeasurements: undefined })
      );
      console.error(err.response);
    });
};

//Edit WorkOrder
export const editWorkOrderAPICall = async (
  body,
  setIsLoading,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates,
  revertToAddForm
) => {
  setIsLoading(true);
  await axios
    .put("workorder/editWorkorder", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Work order edited successfully..");
        setSuccess(true);
        setIsLoading(false);
        updateListener();
        clearStates();
        revertToAddForm();
      }
    })
    .catch((err) => {
      switch (err.response.status) {
        case undefined:
          setSnackMsg("Network error!!");
          break;
        case 404:
          setSnackMsg("Shift is inactive!!");
          break;
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;
        case 422:
          setSnackMsg("Payment Failed!!");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;

        default:
          setSnackMsg("Something wrong!!");
          break;
      }
      setIsLoading(false);
      setError(true);
      console.error(err.response);
    });
};

//Delete work order
export const deleteWorkOrderAPICall = async (
  body,
  setSnackMsg,
  setSuccess,
  setError,
  updateListener
) => {
  await axios
    .post("workorder/deleteWorkorder", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        //todo
        setSnackMsg("Order Deleted..");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      switch (err.response.status) {
        case undefined:
          setSnackMsg("Network error!!");
          break;
        case 404:
          setSnackMsg("Shift is inactive!!");
          break;
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;
        case 422:
          setSnackMsg("Payment Failed!!");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;

        default:
          setSnackMsg("Something wrong!!");
          break;
      }
      setError(true);
      console.error(err.response);
    });
};

//Work Order Add Hold API
export const workOrderHoldAPICall = async (
  body,
  successSnack,
  errorSnack,
  setSnackMsg,
  clearStates,
  closePaymentWindow
) => {
  await axios
    .post("workorder/addHoldorder", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Order held successfully..");
        successSnack(true);
        clearStates();
        closePaymentWindow()
      }
    })
    .catch((err) => {
      setSnackMsg(err.response.data.msg);
      errorSnack(true);
    });
};

//Get work order held list
export const getHeldWorkOrdersAPICall = async (body) => {
  await axios
    .post("workorder/getHeldorder", body, HEADERS)
    .then((res) => {
      store.dispatch(update_heldWrokOrder_list({ data: res.data }));
    })
    .catch((err) => {
      console.log(err.response);
      store.dispatch(update_heldWrokOrder_list({ data: undefined }));
    });
};

// get workorder outcloth status api call
export const viewWoSettingsAPICall=(body)=>{
   axios.post("workorder/viewwosettings",body,HEADERS)
   .then((res)=>{
    if(res.status===200){
      store.dispatch(view_work_order_settings({viewWorkOrderSettingsData:res.data}))
    }
   })
   .catch((err)=>{
      console.log(err.response)
      store.dispatch(view_work_order_settings({viewWorkOrderSettingsData:undefined}))

   })
}
