import { createSlice } from "@reduxjs/toolkit";

export const workOrdersListSlice=createSlice({
    name:'workOrdersListSlice',
    initialState:{
        value:undefined,
        index:0
    },
    reducers:{
        update_work_orders_list:(state,action)=>{
            state.value=action.payload.workOrdersList
        },
        incrementIndex:(state,action)=>{
            console.log("incrementIndex.....+++")
            console.log(action.payload.workOrderList.orderList.length)

            if(action.payload.workOrderList.orderList.length >= 29){
                state.index +=1
            }

          
        },
        decrementIndex:(state,action)=>{
            console.log("decrementIndex ...... ---")
            if(state.index > 0){
                console.log("index amount...",state.index)
                state.index -=1
            }
        },
        resetIndex:(state,action)=>{
            state.index = 0
        }

    }
})
export const {update_work_orders_list,incrementIndex,decrementIndex,resetIndex} = workOrdersListSlice.actions
export default workOrdersListSlice.reducer