import React from "react";
import "../../../../css/Orders/CuttingSlip/churidharCuttingSlip.css";
import qrCode from "../../../../Assets/Images/qrCodeImage.png";
import { convertDateFormat } from "../../../../Js/Date";
import Barcode from "react-barcode";

export const ChuridharCuttingSlip = (props) => {
  const { qr, cuttingSlipData, printingObject, copy } = props;
  return (
    <div className="thob-cutting-slip-container">
      <div className="thob-cutting-slip-top-container">
        <div className="thob-cutting-slip-top-first-content">
          <p>{cuttingSlipData?.cusMobile || "-Contact NIL-"}</p>
          <p className="main-heading-thob-cutting-slip">
            CHURIDHAR CUTTING SLIP
          </p>
          <h3></h3>
          {copy && <p>Copy</p>}
        </div>
        <div className="thob-cutting-slip-top-second-content">
          <h3>
            {printingObject?.relationName === "null"
              ? cuttingSlipData?.cusName
              : printingObject?.relationName}
          </h3>
          <p>Order Date&nbsp;:&nbsp;{convertDateFormat(cuttingSlipData?.ordDate) || ""}</p>
        </div>
        <div className="thob-cutting-slip-top-third-content">
          <p>{cuttingSlipData?.woNo || ""}</p>
          <p>Delivery Date&nbsp;:&nbsp;{cuttingSlipData?.delDate !== null ? convertDateFormat(cuttingSlipData?.delDate) : ""}</p>
        </div>
        {/* <div className="thob-cutting-slip-top-third-content">
        <p style={{fontSize:"0.9rem"}}>status:</p>
        </div> */}
      </div>
      <hr className="global-hr" />
      <div className="jalabia-cutting-slip-middle-container">
        {/* <div className="jalabia-cutting-slip-middle-input-container">
          <p className="jalabia-cutting-slip-input-value">
            {printingObject?.fullLength || "-NIL-"}
          </p>
        </div>  */}
        
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p>(Shoulder)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.shoulder || ""}
            </p>
          </div>
       
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p>(Front Neck)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.frontNeck || ""}
            </p>
          </div>
       
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p>(Back Neck)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.backNeck || ""}
            </p>
          </div>
       
          <div className="jalabia-cutting-slip-middle-input-container large-input-jalabia-cutting-slip">
            <p>(Waist)</p>
            <p className="jalabia-cutting-slip-input-value">
              {printingObject?.waist || ""}
            </p>
          </div>
       

        <div className="salwar-cutting-slip-input-container">
          <div
            style={{ height: "100px", margin: "1% 0 0 0" }}
            className="jalabia-cutting-slip-middle-input-container notes-jalabia-cutting-slip"
          >
            <p>Notes</p>
            <input type="text" name="" id="" />
          </div>
          <div className="salwar-cutting-slip-sub-input-container churidhar-cutting-slip-input-container">
           
              <div className="jalabia-cutting-slip-middle-input-container large-input-churidhar-cutting-slip">
                <p>(Hip)</p>
                <p className="jalabia-cutting-slip-input-value">
                  {printingObject?.hip || ""}
                </p>
              </div>
            
           
              <div className="jalabia-cutting-slip-middle-input-container large-input-churidhar-cutting-slip">
                <p>(Full Length)</p>
                <p className="jalabia-cutting-slip-input-value">
                  {printingObject?.fullLength || ""}
                </p>
              </div>
           
              <div className="jalabia-cutting-slip-middle-input-container large-input-churidhar-cutting-slip">
                <p>(Arm Around)</p>
                <p className="jalabia-cutting-slip-input-value">
                  {printingObject?.armRound || ""}
                </p>
              </div>
          
            <div
              style={{ border: "none" }}
              className="jalabia-cutting-slip-middle-input-container large-input-churidhar-cutting-slip"
            ></div>
          
              <div className="jalabia-cutting-slip-middle-input-container large-input-churidhar-cutting-slip">
                <p>(Arm Hole)</p>
                <p className="jalabia-cutting-slip-input-value">
                  {printingObject?.armHole || ""}
                </p>
              </div>
            
              <div className="jalabia-cutting-slip-middle-input-container large-input-churidhar-cutting-slip">
                <p>(Sleeve Length)</p>
                <p className="jalabia-cutting-slip-input-value">
                  {printingObject?.sleeveLength || ""}
                </p>
              </div>
           
          </div>
        </div>
      </div>

      {/* table container */}
      <div className="thob-cutting-slip-middle-table-container">
        <table>
          <thead>
            <tr>
              <th style={{ width: "50%" }}>Type</th>
              <th>Qty</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {printingObject?.materials?.map((r, i) => (
              <tr>
                <td>{r?.mName}</td>
                <td>{r?.qty}</td>
                <td>{r?.remarks}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* bottom container */}
      <div className="jalabia-cutting-slip-tailor-qrcode-container">
        <div className="jalabia-cutting-slip-tailor-cuttingMaster-container">
          <div className="jalabia-cutting-slip-tailor-container">
            <p
              style={{
                width: "17%",
                display: "flex",
                alignItems: "center",
                fontSize: "0.9rem",
                fontWeight: 600,
              }}
            >
              Tailor
            </p>
            {printingObject?.tailors?.map((tailor, i) => (
              <div key={i} className="jalabia-cutting-slip-tailor-input">
                <p>{tailor}</p>
              </div>
            ))}
          </div>
          <div className="jalabia-cutting-slip-cutting-master-container">
            <p
              style={{
                width: "17%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "0.9rem",
                fontWeight: 600,
              }}
            >
              Cutting Master
            </p>
            <div className="jalabia-cutting-slip-cutting-master-input-container">
              {printingObject?.cuttingMasters?.map((cMaster, i) => (
                <p key={i}>{cMaster}</p>
              ))}
            </div>
          </div>
        </div>
        {
           cuttingSlipData?.isCode === true ?
           <div className="jalabia-cutting-slip-qr-code-container">
           <img src={qr} alt="qrCode" />
         </div>
         :
         <div className="jalabia-cutting-slip-qr-code-container" style={{border:"1px solid #fff"}}>
          <Barcode
                value={cuttingSlipData?.barcodeNumber}
                width={1}
                fontSize={16}
                height={60}
                margin={"2px"}
             />
         </div>
        }
       
      </div>
    </div>
  );
};
