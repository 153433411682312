import { Autocomplete, IconButton, Skeleton, TextField, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ProductMasterFilterAPICall, deleteProductMasterAPICall, viewInventoryProductMasterListAPICall } from "../inventoryProductsAPI";
import { get_inventory_product_master_row_list } from "../inventoryProductsSlice";
import store from "../../../../../Redux/store";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import BrokenImageIcon from '../../../../../Assets/Images/brokenImg.png';
import { categoryListAPICall } from "../../Configuration/Category/CategoryAPI";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import { getCurrentTime } from "../../../../../Js/Date";
import CustomPagination from "../../../../Single Components/CustomPagination";
import axios from "axios";
export const ProductMasterUpdated = () => {
   

    let navigateTo = useNavigate()
    const tableData = useSelector((state) => state.inventoryProductSlice.apiResList)
    const CategoryList = useSelector((state) => state.categorySlice.value);

    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = tableData?.pages;
    const [FormDataInfo, setFormDataInfo] = useState([])
    const [snackBarStates, setSnackBarStates] = useState({
        success: false,
        error: false,
        message: "",
        alert: false
    });

    const closeSnackbar = () => {
        setSnackBarStates({
            ...snackBarStates,
            success: false,
            error: false,
            alert: false,
            message: "",
        });
    };
    const handlePageChange = (page) => {

        setCurrentPage(page);
    };

    const handleDelete = (id) => {
        deleteProductMasterAPICall({ id }, setSnackBarStates)
    }
    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
    };
    const singleViewFn = (data) => {
        navigateTo("/userdashboard/inventory/products/productmaster/create")
        localStorage.setItem("singleViewById", data?._id)
        // store.dispatch(get_inventory_product_master_row_list({inventoryProductMasterSingleData:data}))
    }

    useEffect(() => {
        localStorage.removeItem("singleViewById")
        // viewInventoryProductMasterListAPICall({}, setIsLoading, setSnackBarStates, snackBarStates)
        categoryListAPICall()
        store.dispatch(get_inventory_product_master_row_list({ inventoryProductMasterSingleData: undefined }))

    }, [])

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (FormDataInfo.length !== 0) {
            ProductMasterFilterAPICall({
                branchList: FormDataInfo.branchList,
                mainCategory:FormDataInfo.category,
                posCategory:FormDataInfo.posCategory,
                search: FormDataInfo.search,
                index: currentPage - 1,
            }, setIsLoading,source);
        }
        return () => {
            source.cancel('Request canceled by cleanup');
          };
    }, [FormDataInfo, currentPage]);
    return (
        <>
            <div className="global-page-parent-container">
                <div className="global-white-bg-container">
                    <div className="justify-space-between"
                        style={{ gap: "0 12px", justifyContent: "flex-end" }}
                    >
                        <CategorySearchandFilter
                            onData={handleDataFromChild}
                            isDate={false}
                            isCategory={true}
                            isposCategory={true}
                        />
                        <button
                            className='create-button-blue'
                            onClick={() => navigateTo("/userdashboard/inventory/products/productmaster/create")}>
                            Create</button>
                    </div>
                    <div className="justify-center" style={{ justifyContent: "flex-end" }}>
                        <CustomPagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            setFormDataInfo={setFormDataInfo}
                        />
                    </div>
                    <div className="create-button-blue-container" style={{ justifyContent: "flex-end", alignItems: "center" }}>
                        {/* <div className="global-single-input auto-complete" style={{margin:"0"}}>
                            <Autocomplete
                                sx={{ height: "40px", paddingTop: "4px" }}
                                options={CategoryList || []}
                                getOptionLabel={(option) => option?.categoryName}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="select a category" />
                                )}
                            />
                        </div> */}
                        {/* <button
                            className='create-button-blue'
                            onClick={() => navigateTo("/userdashboard/inventory/products/productmaster/create")}>
                            Create</button> */}

                    </div>
                    <div className="global-table-container">
                        <table className="global-table">
                            <thead>
                                <tr>
                                    <th>Product Id</th>
                                    <th>Product Name</th>
                                    <th>Product Image</th>
                                    <th>UOM</th>
                                    {/* <th>Delete</th> */}
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    isLoading ? (
                                        [...Array(10)].map((r, i) => (
                                            <tr key={i}>
                                                <td colSpan={8}>
                                                    <Skeleton
                                                        variant="rectangular"
                                                        animation="wave"
                                                        width={"100%"}
                                                        height={40}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    )
                                        :
                                        (tableData?.list !== undefined && tableData?.list?.length !== 0) ?
                                            tableData?.list?.slice(0)?.reverse()?.map((r, i) => (
                                                <tr key={i} >
                                                    <td onClick={() => singleViewFn(r)}>{r?.prodId}</td>
                                                    <td onClick={() => singleViewFn(r)}>{r?.name}</td>
                                                    <td onClick={() => singleViewFn(r)}>
                                                        <img style={{ width: "40px", height: "40px", }}
                                                            src={(r?.imageUrl !== null && r?.imageUrl[0]) || BrokenImageIcon}
                                                            alt="no img" /></td>
                                                    <td onClick={() => singleViewFn(r)}>{r?.UOMNAME || "---"}</td>
                                                    {/* <td>
                                                <Tooltip title="delete">
                                                    <IconButton>
                                                        <i className="bi bi-trash3 delete-icon"></i>
                                                    </IconButton>
                                                </Tooltip>
                                            </td> */}
                                                    <td>
                                                        <IconButton
                                                            onClick={() => handleDelete(r?._id)}
                                                        >
                                                            <i
                                                                class="bi bi-trash3 delete-icon"
                                                                style={{ fontSize: "15px" }}
                                                            >     </i>
                                                        </IconButton>
                                                    </td>
                                                </tr>
                                            )) :
                                            <tr>
                                                <td colSpan={5}>No data</td>
                                            </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <ErrorSnackbar
                    open={snackBarStates.error}
                    handleClose={closeSnackbar}
                    message={snackBarStates.message}
                />
                <SuccessSnackbar
                    open={snackBarStates.success}
                    handleClose={closeSnackbar}
                    message={snackBarStates.message}
                />
            </div>


        </>
    )
}