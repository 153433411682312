import axios from "axios";
import { update_all_active_employee_list } from "../../../Redux/Staff/HRM/Employee/allActiveEmployeesSlice";
import { update_all_inactive_employee_list } from "../../../Redux/Staff/HRM/Employee/allIncativeEmployeeSlice";
import { update_emp_qr } from "../../../Redux/Staff/HRM/Employee/empQrSlice";
import { update_single_employee } from "../../../Redux/Staff/HRM/Employee/singleEmployeeSlice";
import store from "../../../Redux/store";
import { HEADERS, HEADER_FORM_DATA } from "../../UrlAndPaths";

//Create New Employee Personal Info
export const addNewEmployeeAPICall = async (
  formData,
  setSuccess,
  setError,
  setSnackMsg,
  moveToNextTab,
  clearStates
) => {
  await axios
    .post("employee/addEmployee", formData, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        console.log("New Employee Created Successfully...");
        setSnackMsg("New employee created 👍");
        setSuccess(true);
        moveToNextTab();
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Employee Mobile Already Exist");
      }

      if (err.response.status === 401) {
        setSnackMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }

      setError(true);
      console.error(err.response);
    });
};

//View All inactive employees(just created employees)
export const getAllInactiveEmployeesAPICall = async (body) => {
  await axios
    .post(`employee/viewallemployeesinbranch`, body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("áll inactive employees fetched");
        store.dispatch(
          update_all_inactive_employee_list({ inactiveEmployees: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        update_all_inactive_employee_list({ inactiveEmployees: undefined })
      );
      console.error(err.response);
    });
};

//View All active employees
export const getAllActiveEmployeesAPICall = async (setIsLoading,setErrorSnack,setSnackMsg) => {
  if(setIsLoading !== undefined) setIsLoading(true)

  await axios
    .get(`employee/getAllEmployees`, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("áll active employees fetched");
        store.dispatch(
          update_all_active_employee_list({ activeEmployees: res.data })
        );
        if(setIsLoading !== undefined)  setIsLoading(false)
      }
    })
    .catch((err) => {
      store.dispatch(
        update_all_active_employee_list({ activeEmployees: undefined })
      );
      console.error(err.response);
      switch (err.response.status) {
        case 404:
            setSnackMsg('No data available..')
          break;
        case 500:
            setSnackMsg('Server error!!')
          break;
        case 401:
            setSnackMsg('Unauthorized!!')
          break;
        case 403:
            setSnackMsg('Forbidden!!')
          break;
      
        default:
            setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
          break;
      }
      setErrorSnack(true)
      if(setIsLoading !== undefined)  setIsLoading(false)
    });
};

//Add Employee Company Settings
export const addEmployeeCompanyAndBankSettingsAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .put(`employee/addcompanyInfo`, body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSuccess(true);
        setSnackMsg("Employee details updated 👍");
        console.log("Employee Company Info Updated Successfully...");
        updateListener()
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        setSnackMsg("UnAuthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//Generate Employee QR code
export const addEmployeeQrCodeGenerationAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  clearStates
) => {
  await axios
    .post(`employee/clickQrcode`, body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        // setSuccess(true)
        // setSnackMsg('Qr code generated 👍')
        console.log("Employee Qr generated Successfully...");
        store.dispatch(update_emp_qr({ empQr: res.data }));
        // clearStates()
        console.log("then block entered");
      }
    })
    .catch((err) => {
      store.dispatch(update_emp_qr({ empQr: null }));
      if (err.response.status === 401) {
        setSnackMsg("UnAuthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//View single Employee
export const getSingleEmployeeAPICall = async (body) => {
  await axios
    .post(`employee/getEmployeeById`, body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("áll active employees fetched");
        store.dispatch(update_single_employee({ singleEmp: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_single_employee({ singleEmp: undefined }));
      console.error(err.response);
    });
};

//Edit Employee Personal Info
export const editEmployeePersonalInfoAPICall = async (
  formData,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .put("employee/editEmployee", formData, )
    .then((res) => {
      if (res.status === 200) {
        console.log("Employee personal info updated...");
        setSnackMsg("Personal information updated👍");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Already Exist");
      }

      if (err.response.status === 401) {
        setSnackMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }

      setError(true);
      console.error(err.response);
    });
};
