import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { addDiscountAPICall, getDiscountAPICall } from '../../../../API/Settings/Discount/discountAPI'
import "../../../../css/Settings/Discount/discountSettings.css"
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar'
import SuccessSnackbar from '../../../Single Components/SnackBars/SuccessSnackbar'
export const Discount = () => {
  const discountData=useSelector((state)=>state.discountSlice.value)
  const [discount,setDiscount]=useState('')
  const [isModify,setIsModify]=useState(false)

    // snackbar states
    const [successSnackbar,setSuccessSnackbar]=useState(false)
    const [errorSnackbar,setErrorSnackbar]=useState(false)
    const [snackMsg,setSnackMsg]=useState('Error!!')

  ///updateListener
  const updateListner=()=>{
    setIsModify(!isModify)
  }
   // snackbar functions
   const closeSuccessSnackbar=()=>{
    setSuccessSnackbar(false)
   }
 const closeErrorSnackbar=()=>{
    setErrorSnackbar(false)
   }

  const getDiscount=(e)=>{
    setDiscount(e.target.value)
  }
  
  const submitBtnFunction=()=>{
    addDiscountAPICall({discountAmount:parseFloat(discount)},
      updateListner,
      setSuccessSnackbar,
      setErrorSnackbar,
      setSnackMsg,
      setDiscount
      )
  }
  // get discount api call
  useEffect(()=>{
    getDiscountAPICall()
  },[isModify])


  
  return (
      <div>
          <div className="discount-settings-container">
              <div className="discount-settings-container-body">
                <div className="discount-settings-container-body-section">
                  <p>Discount Amount</p>
                    <input type="number" value={discount} onChange={getDiscount}/>
                  <p className="discount-settings-container-body-section-percentage">Discount :{discountData?.discountAmount}%</p>
                </div>  
                <div className="discount-settings-container-button">
                  <button onClick={submitBtnFunction} className="btn btn-primary">Submit</button>
                </div>   
              </div>
                
          </div>
          <SuccessSnackbar
       open={successSnackbar}
       handleClose={closeSuccessSnackbar}
       message={snackMsg}
     />
     
     <ErrorSnackbar
       open={errorSnackbar}
       handleClose={closeErrorSnackbar}
       message={snackMsg}
     />
        
      </div>
  )
}
