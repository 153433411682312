import axios from "axios"
import store from "../../Redux/store"
import { HEADERS } from "../UrlAndPaths"
import { filtered_table_list, update_journal_slice } from "../../Redux/AccountUpdated/journalSlice"
import { update_chart_of_accounts } from "../../Redux/AccountUpdated/chartOfAccountsSlice"

// list journal
export const journalListApiCall=()=>{
    axios.post("account/listjournalcategory",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(update_journal_slice({journalData:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}
export const JournalFilterAPICall =(body,setIsLoading)=>{
    axios.post("account/searchJournal",body,HEADERS)
    .then((res)=>{
      if(res.status===200){
        store.dispatch(filtered_table_list({tableListJournal:res.data}))
        setIsLoading!==undefined&&setIsLoading(false)
      }
    })
    .catch((err)=>{
      store.dispatch(filtered_table_list({tableListJournal:undefined}))
      setIsLoading!==undefined&&setIsLoading(false)
    })
  
  }
// add journal 
export const addJournalApiCall=(body,setSuccessSnackOpen,setErrorSnackOpen,setSnackMsg,clearState,setLoadingForm)=>{
   
  setLoadingForm!==undefined && setLoadingForm(true)
    axios.post("account/addjournalcategory",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
           setSnackMsg('Journal Added')
           setSuccessSnackOpen(true)
           clearState()
          setLoadingForm!==undefined && setLoadingForm(false)
        }
    })
    .catch((err)=>{

        setLoadingForm!==undefined && setLoadingForm(false)
        if (err.response.status === 500) {
          setSnackMsg("Internal Server Error!!")
        } else {
          setSnackMsg(err.response.data)
        }
        setErrorSnackOpen(true)
    })
}

// edit journal
export const editJournalApiCall=(
    body,
    setSuccessSnackOpen,
    setErrorSnackOpen,
    setSnackMsg,setLoadingForm
    )=>{
    setLoadingForm!==undefined && setLoadingForm(true)
    axios.post("account/editjournalcategory",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            setLoadingForm!==undefined && setLoadingForm(false)
            setSnackMsg('Journal Edited')
            setSuccessSnackOpen(true)
        }
    })
    .catch((err)=>{
        setLoadingForm!==undefined && setLoadingForm(false)
        switch (err.response.status) {
            case 404:
                setSnackMsg(err?.response?.data)
              break;
            case 500:
                setSnackMsg('Server error!!')
              break;
            case 401:
                setSnackMsg('Unauthorized!!')
              break;
            case 403:
                setSnackMsg('Forbidden!!')
              break;
            case 409:
                setSnackMsg("Duplicated")
            default:
                setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
              break;
          }
        setErrorSnackOpen(true)
    })
}