import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import "../../../../css/Account/Accounting/createJournal.css"
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { chartOfAccountListApiCall } from '../../../../API/AccountUpdated/chartOfAccountAPI';
import { useState } from 'react';
import { viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import SuccessSnackbar from '../../../Single Components/SnackBars/SuccessSnackbar';
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar';
import { addJournalApiCall, editJournalApiCall, journalListApiCall } from '../../../../API/AccountUpdated/journalAPI';
import { LoadingForm } from '../../../Single Components/LoadingForm';
import { setFilterActive } from '../../../Single Components/listSlice/filterCategorySlice';
export const CreateJournal = () => {
  const journalList=useSelector((state)=>state.journalSlice.value)
    let navigate = useNavigate();

    const dispatch = useDispatch();

    const journalTypes=[
        {
            type:"sales"
        },
        {
            type:"purchase"
        },
        {
            type:"cash"
        },
        {
            type:"bank"
        },
        {
            type:"miscellaneous"
        },
        // {
        //   type:"income"
        // },
        // {
        //   type:"wallet"
        // },
        // {
        //   type:"loyalty"
        // },
    ]

    const chartOfAccountList=useSelector((state)=>state.chartOfAccountsSlice.value)
     // all branch list
    const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
    // single journal list data
    const journalListSingle=useSelector((state)=>state.journalSlice.singleValue)

    // suspense account dropdown
    const suspenseAccountList=chartOfAccountList?.filter((item)=>item?.type==="Bank and cash")

    const createJournalInitialState={
       journalName:"",
       type:null,
       branch:null,
       shortCode:"",
       defaultAccount:null,
       supenseAccount:null
    }
    const [loadingForm, setLoadingForm] = useState(false);
    const [createJournalForm,setCreateJournalForm]=useState(createJournalInitialState)
    const [successSnackOpen, setSuccessSnackOpen] = useState(false);
    const [errorSnackOpen, setErrorSnackOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState("Error!!");
    const [editClicked,setEditClicked]=useState(false)
    const [validationAlert,setValidationAlert]=useState({})

    useEffect(()=>{
        chartOfAccountListApiCall()
        viewAllBranchesAPICall();
    },[])

    const backButtonFn=()=>{
      if (createJournalForm?.isEdit === false) {
        const userConfirmed = window.confirm("Changes you made may not be saved.");
        
        if (userConfirmed) {
          navigate("/userdashboard/accounts/accounting/journal")
        }
    }
    else{
      navigate("/userdashboard/accounts/accounting/journal")
    }
      
    }

    // snackbar function
    const closeSuccessSnackbar=()=>{
      setSuccessSnackOpen(false)
    }

    const closeErrorSnackbar=()=>{
      setErrorSnackOpen(false)
    }


    
    const createJournalOnChange=(key)=>(e,newValue)=>{
    
      setValidationAlert({})
      if(key==="journalName"){
        setCreateJournalForm({...createJournalForm,journalName:e.target.value})
      }
      if(key==="type"){
        setCreateJournalForm({...createJournalForm,type:newValue,defaultAccount:null})
      }
      if(key==="branch"){
        setCreateJournalForm({...createJournalForm,branch:newValue})
      }
      if(key==="shortCode"){
        if(journalList?.some(obj=>obj.shortCode===e.target.value)){
          setCreateJournalForm({...createJournalForm,shortCode:e.target.value})
          setValidationAlert({...validationAlert,shortCode:"This Code is Already Used"})
          }
          else{
          setCreateJournalForm({...createJournalForm,shortCode:e.target.value})
          setValidationAlert({...validationAlert,shortCode:""})
          }
       
      }
      if(key==="defaultAccount"){
        setCreateJournalForm({...createJournalForm,defaultAccount:newValue})
      }
      if(key==="supenseAccount"){
        setCreateJournalForm({...createJournalForm,supenseAccount:newValue})
      }
    }

    useEffect(()=>{
      journalListApiCall()
    },[createJournalForm?.shortCode])

    // create journal body
    const createJournalBody={
      journalName:createJournalForm?.journalName,
      type:createJournalForm?.type?.type || null,
      branchId:createJournalForm?.branch?._id || null,
      shortCode:createJournalForm?.shortCode,
      defaultAccount:createJournalForm?.defaultAccount?._id || null,
      supenseAccount:createJournalForm?.supenseAccount?._id || null,

    }
    // save function
    const createJournalSaveFn=()=>{
      dispatch( setFilterActive(false))
      if(createJournalForm?.journalName===""){
        setValidationAlert({...validationAlert,journalName:"fill this field"})
      }
      else if(createJournalForm?.type===null){
        setValidationAlert({...validationAlert,type:"fill this field"})
      }
      else if(createJournalForm?.shortCode===""){
        setValidationAlert({...validationAlert,shortCode:"fill this field"})
      }
      else if(journalList?.some(obj=>obj.shortCode===createJournalForm?.shortCode)){
        setValidationAlert({...validationAlert,shortCode:"This Code is Already Used"})
      }
      else if(createJournalBody?.type!=="miscellaneous" && createJournalBody?.defaultAccount===null){
        setValidationAlert({...validationAlert,defaultAccount:"fill this field"})
      }
      // else if(createJournalBody?.type==="bank" && createJournalBody?.supenseAccount===null){
      //   setValidationAlert({...validationAlert,supenseAccount:"fill this field"})
      // }
      else{
        addJournalApiCall(
          createJournalBody,
          setSuccessSnackOpen,
          setErrorSnackOpen,
          setSnackMsg,
          clearState,setLoadingForm)
      }
    }

    const clearState=()=>{
      setCreateJournalForm(createJournalInitialState)
    }

    

    const filterObjFromList=(key,list,keyTomatch,listToFilter)=>{
      let filteredArray=[]
      filteredArray=list?.filter((obj)=>
          obj[key]===listToFilter[keyTomatch]
      )
      return filteredArray?.length!==0&& filteredArray !== undefined ? filteredArray[0]:[];
    }

    useEffect(()=>{
      if(journalListSingle !== undefined){
        setEditClicked(true)
        setCreateJournalForm({...createJournalForm,
          journalName:journalListSingle?.journalName,
          type:filterObjFromList("type",journalTypes,"type",journalListSingle),
          branch:filterObjFromList("storeCode",allBranchesList,"branchId",journalListSingle) || null,
          shortCode:journalListSingle?.shortCode,
          defaultAccount:filterObjFromList("_id",chartOfAccountList,"defaultAccount",journalListSingle),
          supenseAccount:filterObjFromList("_id",chartOfAccountList,"supenseAccount",journalListSingle)||null,
          isEdit:true,
          editBtn:true
        })
      }
    },[journalListSingle])
    const editBtnFunction=()=>{
      setCreateJournalForm({...createJournalForm,
        // journalName:journalListSingle?.journalName,
        // type:filterObjFromList("type",journalTypes,"type",journalListSingle),
        // branch:filterObjFromList("storeCode",allBranchesList,"branchId",journalListSingle),
        // shortCode:journalListSingle?.shortCode,
        // defaultAccount:filterObjFromList("_id",chartOfAccountList,"defaultAccount",journalListSingle),
        isEdit:false
      })
    }

    const editsaveBtnFn=()=>{
      setCreateJournalForm({...createJournalForm,
        isEdit:true
      })
    }
  

    const editBody={
      journalName:createJournalForm?.journalName,
      type:createJournalForm?.type?.type || null,
      branchId:createJournalForm?.branch?._id || null,
      shortCode:createJournalForm?.shortCode,
      defaultAccount:createJournalForm?.defaultAccount?._id || null,
      supenseAccount:createJournalForm?.supenseAccount?._id || null,
      _id:journalListSingle?._id,
    }

    const editJournalFn=()=>{
      if(createJournalForm?.journalName===""){
        setValidationAlert({...validationAlert,journalName:"fill this field"})
      }
      else if(createJournalForm?.type===null){
        setValidationAlert({...validationAlert,type:"fill this field"})
      }
      else if(createJournalForm?.shortCode===""){
        setValidationAlert({...validationAlert,shortCode:"fill this field"})
      }
      else if(createJournalBody?.type!=="miscellaneous" && createJournalBody?.defaultAccount===null){
        setValidationAlert({...validationAlert,defaultAccount:"fill this field"})
      }
      else if(createJournalBody?.type==="bank" && createJournalBody?.supenseAccount===null){
        setValidationAlert({...validationAlert,supenseAccount:"fill this field"})
      }
      else{
        editJournalApiCall(
          editBody,
          setSuccessSnackOpen,
          setErrorSnackOpen,
          setSnackMsg,setLoadingForm
          )
      }
    }
    
    useEffect(() => {
      const handleBeforeUnload = (event) => {
        const shouldShowAlert = !createJournalForm?.isEdit;
        if (shouldShowAlert) {
          event.preventDefault();
        }
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [createJournalForm?.isEdit]);
  return (
    <div className='global-page-parent-container'>
        <div className="global-white-bg-container">

        <Tooltip title="Back">
           <IconButton onClick={backButtonFn}>
            <i class="bi bi-chevron-left"></i>
          </IconButton>
        </Tooltip>
          {
            createJournalForm?.editBtn=== true &&
            <Tooltip title={ createJournalForm?.isEdit?"Edit":"Save"}>
            <IconButton onClick={
               createJournalForm?.isEdit?
              editBtnFunction
              :
              editJournalFn
            }
               >
              {
                createJournalForm?.isEdit?
                <i class="bi bi-pencil-square edit-icon1"></i>
                :
                <i class="bi bi-check2-square edit-icon" style={{fontSize:"1.5rem"}}></i>
              }
            
            </IconButton>
          </Tooltip>
          }
       { createJournalForm?.editBtn !==true &&  
          <Tooltip title="Save">
          <IconButton onClick={createJournalSaveFn}>
            <i class="bi bi-floppy"></i>
          </IconButton>
        </Tooltip>
       }
     
      </div>
        <div className='global-white-bg-container create-journal-container'>
           <div className='global-single-input create-joutnal-input'>
             <p>Journal Name</p>
             <input 
               type="text"
               value={createJournalForm?.journalName}
               onChange={createJournalOnChange("journalName")}
               disabled={createJournalForm?.isEdit}
               />
                <p className="doc-validation-alert">{validationAlert?.journalName}</p>
           </div>
           <div className='global-single-input auto-complete create-joutnal-input'>
             <p>Type</p>
             <Autocomplete
                sx={{ width: "100%" }}
                options={journalTypes||["No Data"]}
                getOptionLabel={(option) =>option?.type}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Type" />
                )}
                onChange={createJournalOnChange("type")}
                value={createJournalForm?.type}
                disabled={createJournalForm?.isEdit}
               
             />
              <p className="doc-validation-alert">{validationAlert?.type}</p>
           </div>
           <div className='global-single-input auto-complete create-joutnal-input'>
             <p>Branch</p>
             <Autocomplete
                sx={{ width: "100%" }}
                options={allBranchesList||[""]}
                getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Branch" />
                )}
                onChange={createJournalOnChange("branch")}
                value={createJournalForm?.branch}
                disabled={createJournalForm?.isEdit}
                
                
             />
              {/* <p className="doc-validation-alert">{validationAlert?.branch}</p> */}
           </div>
           <div className='global-single-input create-joutnal-input'>
             <p>Short Code</p>
             <input 
               type="text"
               value={createJournalForm?.shortCode}
               onChange={createJournalOnChange("shortCode")}
               maxLength={3}
               disabled={createJournalForm?.isEdit}
             
               />
                <p className="doc-validation-alert">{validationAlert?.shortCode}</p>
           </div>
           {
              createJournalBody?.type!=="miscellaneous" &&
            <div className='global-single-input auto-complete create-joutnal-input'>
             <p>{createJournalBody?.type  ==="bank" ?"Bank Account" : createJournalBody?.type  ==="cash" ? "Cash Account": "Default Account"}</p>
             <Autocomplete
                sx={{ width: "100%" }}
                options={createJournalBody?.type  ==="bank" || createJournalBody?.type  ==="cash" ?suspenseAccountList :chartOfAccountList||[""]}  
                getOptionLabel={(option) =>option?.accountName} 
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Default Account" />
                )}
                onChange={createJournalOnChange("defaultAccount")}
                value={createJournalForm?.defaultAccount}
                disabled={createJournalForm?.isEdit}
             />
                <p className="doc-validation-alert">{validationAlert?.defaultAccount}</p>
           </div>}
           {/* {
            createJournalBody?.type==="bank" &&
            <div className='global-single-input auto-complete create-joutnal-input'>
             <p>Suspense Account</p>
             <Autocomplete
                sx={{ width: "100%" }}
                options={suspenseAccountList||[]}
                getOptionLabel={(option) =>option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Default Account" />
                )}
                onChange={createJournalOnChange("supenseAccount")}
                value={createJournalForm?.supenseAccount}
                disabled={createJournalForm?.isEdit}
             />
                <p className="doc-validation-alert">{validationAlert?.supenseAccount}</p>
           </div>} */}
         
        </div>
        
      {/* snackbar */}
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
          <LoadingForm loading={loadingForm} />
    </div>

  )
}
