import React, { useState, useRef, useEffect } from "react";
import { Select, IconButton, Autocomplete, TextField } from "@mui/material";
import "../../../../css/Report/dailyCashCardSummary.css";
import ReactToPrint from "react-to-print";
import { dailyCashCardAPICall } from "../../../../API/Report/dailyCashCardAPICall";
import { useSelector } from "react-redux";
import {
  viewAllBranchesAPICall,
  viewCompanyProfileInfoAPICall,
} from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { viewShiftAPICall } from "../../../../API/Settings/Shift/viewShiftAPI";
import { convertDateFormat, today } from "../../../../Js/Date";
import { categoryListAPICall } from "../../Inventory/Configuration/Category/CategoryAPI";
export const DailyCashCardSummary = () => {
  let dailyCashCardReportPrint = useRef();
  let dailyCashCardDateFilterPrint = useRef();
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // daily cash card summary
  const dailyCashCardData = useSelector(
    (state) => state.dailyCashCardSummary.value
  );
  // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // all shift list
  const allShiftList = useSelector((state) => state.viewShiftSlice.value);
  //Company Profile Data
  const companyProfileData = useSelector(
    (state) => state.companyProfileSlice.value
  );
  const CategoryList = useSelector((state) => state.categorySlice.value);
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const branchId = localStorage.getItem("branchId");

  const [details, setDetails] = useState(false);
  const [expense, setExpense] = useState(false);
  const [category, setCategory] = useState(false);
  //Material, Readymade,Accessory Checkbox checked state
  const [isMaterialChecked,setIsMaterialChecked]=useState(false)
  const [isReadymadeChecked,setIsReadymadeChecked]=useState(false)
  const [isAccessoryChecked,setIsAccessoryChecked]=useState(false)
  const [location, setLocation] = useState();
  const [shift, setShift] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isSubmitBtnClick, setIsSubmitBtnClick] = useState(false);
  const [filteredResponse, setFilteredResponse] = useState([]);
  const [vatTotal,setVatTotal]=useState()
  const [isFilterClick,setIsFilterClick]=useState(false)
  const loginMeta=localStorage.getItem("login_meta")
  const branchPk=JSON.parse(loginMeta)?.branchPk;
  // filtered shift data
  const [shiftFilteredData, setShiftFilteredData] = useState([]);
  const [time, setTime] = useState(null);

  // get current time fn
  useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }, 1000);
  }, [time]);

  const detailsChange = (e) => {
    const { checked } = e.target;
    setDetails(checked);
  };

  const expenseCheck = (e) => {
    const { checked } = e.target;
    setExpense(checked);
  };
  const categoryCheck = (e) => {
    setCategory(e.target.checked);
  };
  

const [filteredDailyCashCard,setFilteredDailyCashCard]=useState([])
useEffect(()=>{
  let filterdArray=[]
  let typeCashArray=[]
  if(shift!==null && location!=="null" && location!==undefined){
    setFilteredDailyCashCard([])
    filterdArray=dailyCashCardData!==undefined && dailyCashCardData.paidSummary.orderList.filter(obj=>obj.type ==='cash')
    typeCashArray=dailyCashCardData!==undefined && dailyCashCardData.paidSummary.orderList.filter(obj=>obj.type !=='cash').concat(filterdArray)
    setFilteredDailyCashCard(typeCashArray)
    }
  // if(filteredResponse!==undefined && filteredResponse.length!==0){
  //   setFilteredDailyCashCard([])
  //   filterdArray=filteredResponse.paidSummary.orderList.filter(obj=>obj.type ==='cash')
  //   typeCashArray=filteredResponse.paidSummary.orderList.filter(obj=>obj.type !=='cash').concat(filterdArray)
  //   setFilteredDailyCashCard(typeCashArray)
  // }
},[dailyCashCardData])

console.log(filteredDailyCashCard);

  useEffect(() => {
   
    viewShiftAPICall({branchId:location});
   
  }, [location]);

  useEffect(()=>{
    viewCompanyProfileInfoAPICall();
    viewAllBranchesAPICall();
    categoryListAPICall()
  },[])
  // shift filtering
  useEffect(() => {
    let filteredArray = allShiftList?.filter((obj) => {
      return obj.branchId === location;
    });
    setShiftFilteredData(filteredArray);
  }, [location, allShiftList]);

  useEffect(() => {
    let filteredArray = allShiftList?.filter((obj) => {
      return obj.branchId === location;
    });
    setShiftFilteredData(filteredArray);
  }, [location]);
  
  useEffect(() => {
    setLocation(branchPk);
  }, []);

  const [categories,setCategories]=useState([])
  const checkCategories=(e)=>{
    const categoryId = e.target.value;
    if (categories.includes(categoryId)) {
      // If it is selected, remove it from the array
      setCategories((prevSelectedCategories) =>
        prevSelectedCategories.filter((id) => id !== categoryId)
      );
    } else {
      // If it is not selected, add it to the array
      setCategories((prevSelectedCategories) => [
        ...prevSelectedCategories,
        categoryId,
      ]);
    }
  }
  useEffect(() => {
    const defaultCategoryNames = ["material", "readymade","accessories",'Material','Accessory','Readymade'];
    const defaultCategoryIds = CategoryList
      ?.filter(category => defaultCategoryNames.includes(category?.categoryName))
      .map(category => category?._id)
      .filter(Boolean);
  
    if (defaultCategoryIds && defaultCategoryIds.length > 0) {
      setCategories(prevSelectedCategories => {
        // Create a new Set to ensure uniqueness
        const updatedCategoriesSet = new Set([...prevSelectedCategories, ...defaultCategoryIds]);
  
        // Convert the Set back to an array
        return Array.from(updatedCategoriesSet);
      });
    }
  }, [CategoryList]);

  // submit btn api call
  const bodyToApi = {
    branchId: location !== "null" ? location : null,
    shiftPk: shift !== null ? shift?._id : null,
    fromDate: fromDate !== "" ? `${fromDate} 00:00:00` : null,
    endDate: toDate !== "" ? `${toDate} 23:59:59` : null,
    isDetails: details === true ? details : null,
    isExpense: expense === true ? expense : null,
    // isCategory: category === true ? category : null,
    // isCategory: true ,
    categories :categories
  };
  const dailyCashCardSubmitBtnFn = () => {
    dailyCashCardAPICall(bodyToApi);
    setIsSubmitBtnClick(true);
  };
  useEffect(() => {
    dailyCashCardAPICall(bodyToApi);
  }, [details, expense]);

  // filter for print view
  useEffect(() => {
    let filteredArray = dailyCashCardData?.list?.filter((obj) => {
      if (obj?.paidSummary?.orderList?.length !== 0) {
        return obj;
      }
    });
    setFilteredResponse(filteredArray);
  }, [dailyCashCardData]);


  

  // useEffect(()=>{
  //   let vatTot=0
  //   // if(shift !== undefined){
  //   //   filteredDailyCashCard?.forEach((r,i)=>{
  //   //     vatTot+=r?.vatTotal
  //   //   })
  //   // }
  //   // else{
  //     filteredDailyCashCard?.map((r,i)=>{
  //       r?.paidSummary?.orderList?.forEach((k)=>{
  //         vatTot+=k?.vatTotal
  //       })
  //      console.log(r);
  //     })
  //   // }
   
  //   setVatTotal(vatTot)
  // },[filteredDailyCashCard])
  // console.log(vatTotal);

  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">Report &gt; Daily Cash/Card Summary</p>
      {/* Top parent container  */}
      <div className="daily-report-top-container">
        <h3>Daily Cash/Card Summary</h3>
        <hr className="global-hr" />

        {/* Top Filter Container  */}
        <div className="daily-report-top-input-container">
          {userRole === "admin" && (
            <div className="global-single-input auto-complete">
              <p>Location</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={allBranchesList || []}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Branch" />
                )}
                onChange={(e, newValue) =>{ 
                  setLocation(newValue?._id)
                  setShift(null)
                }}
              />
            </div>
          )}

          <div className="global-single-input auto-complete">
            <p>Shift</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={shiftFilteredData?.slice(0)?.reverse() || []}
              getOptionLabel={(option) => option?.SHIFID}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Shift" />
              )}
              onChange={(e, newValue) => setShift(newValue)}
              value={shift}
              disabled={fromDate !== "" || toDate !== ""}
            />
          </div>

          <div className="global-single-input">
            <p>From</p>
            <input
              type="date"
              onChange={(e) => setFromDate(e.target.value)}
              disabled={shift !== null}
            />
          </div>
          <div className="global-single-input">
            <p>To</p>
            <input
              type="date"
              onChange={(e) => setToDate(e.target.value)}
              disabled={shift !== null}
            />
          </div>
          <button
            className={
              (location === null ||
                location === undefined ||
                shift === null) &&
              (location === null ||
                location === undefined ||
                fromDate === "" ||
                toDate === "")
                ? "btn btn-primary-disabled report-submit-btn"
                : "btn btn-primary report-submit-btn"
            }
            disabled={
              (location === null ||
                location === undefined ||
                shift === null) &&
              (location === null ||
                location === undefined ||
                fromDate === "" ||
                toDate === "")
            }
            // onClick={dateFilterFn}
            // onMouseDown={dailyCashCardSubmitBtnFn}
            onClick={dailyCashCardSubmitBtnFn}
          >
            Submit
          </button>
        </div>

        {/* Check box container [details, expense, Category] */}
        <div className="report-checkbox-container" >
          <div className="report-single-checkbox-container">
            <input
              type="checkbox"
              id="reportDetails"
              value={details}
              onChange={detailsChange}
            />
            <label htmlFor="reportDetails">Details</label>
          </div>
          <div className="report-single-checkbox-container">
            <input
              type="checkbox"
              id="reportExpense"
              value={expense}
              onChange={expenseCheck}
            />
            <label htmlFor="reportExpense">Expense</label>
          </div>
          <div className="daily-repirt-filter-container" onClick={()=>setIsFilterClick(!isFilterClick)}>
              <i class="bi bi-funnel filter-icon"></i>
              {
                isFilterClick ?
                  <i class="bi bi-caret-up-fill aarow-down-filled"></i>
                  :
                  <i class="bi bi-caret-down-fill aarow-down-filled"></i>
              }
            
          </div>

          {/* <div className="report-single-checkbox-container">
            <input
              type="checkbox"
              id="reportCategory"
              value={category}
              onChange={categoryCheck}
            />
            <label htmlFor="reportCategory">Category</label>
          </div> */}
          {/* <div className="report-single-checkbox-container">
            <input
              type="checkbox"
              id="materialCategory"
              checked={isMaterialChecked}
              onClick={()=>setIsMaterialChecked(!isMaterialChecked)}
            />
            <label htmlFor="reportCategory">Material</label>
          </div>
          <div className="report-single-checkbox-container">
            <input
              type="checkbox"
              id="readymadeCategory"
              checked={isReadymadeChecked}
              onClick={()=>{setIsReadymadeChecked(!isReadymadeChecked)}}
              // value={category}
              // onChange={categoryCheck}
            />
            <label htmlFor="reportCategory">Readymade</label>
          </div>
          <div className="report-single-checkbox-container">
            <input
              type="checkbox"
              id="accessoryCategory"
              checked={isAccessoryChecked}
              onClick={()=>{setIsAccessoryChecked(!isAccessoryChecked)}}
              // value={category}
              // onChange={categoryCheck}
            />
            <label htmlFor="reportCategory">Accessory</label>
          </div> */}
        </div>
        {
            isFilterClick && 
            <div className="daily-repirt-filter-content"> 
            {
               CategoryList?.map((r)=>(
                <div className="daily-repirt-filter-content-checkbox">
                   <input 
                      type="checkbox"
                      value={r?._id} 
                      onClick={checkCategories}
                      checked={categories.includes(r?._id)}
                      />
                   <label htmlFor="">{r?.categoryName}</label>
                </div>
                ))
            }
              
            </div>
} 
      </div>

      {dailyCashCardData !== undefined ? (
        <div className="daily-report-bottom-container">
          {
            // shift filter
            shift !== null && isSubmitBtnClick === true ? (
              <>
                <div className="daily-report-bottom-date-location-container">
                  <div className="daily-report-bottom-date-location-left-container">
                    <div className="daily-report-bottom-date-location-left-single-container">
                      <p>Shift No</p>
                      <h4>:&nbsp; {dailyCashCardData?.shiftId}</h4>
                    </div>
                    <div className="daily-report-bottom-date-location-left-single-container">
                      <p>Shift Open Time </p>
                      <h4>:&nbsp; {`${convertDateFormat(dailyCashCardData?.shiftopeningTime)} ${dailyCashCardData?.shiftopeningTime?.split(" ")[1]}`}</h4>
                    </div>
                    <div className="daily-report-bottom-date-location-left-single-container">
                      <p>Shift Closing Time</p>
                      <h4>:&nbsp; {dailyCashCardData?.shiftclosingTime !== "Active Shift"?`${convertDateFormat(dailyCashCardData?.shiftclosingTime)} ${dailyCashCardData?.shiftclosingTime?.split(" ")[1]}`:dailyCashCardData?.shiftclosingTime}</h4>
                    </div>
                  </div>
                  <div className="daily-report-bottom-date-location-right-container">
                    <div className="daily-report-bottom-date-location-left-single-container">
                      <p>Location</p>
                      <h4>:&nbsp; {dailyCashCardData?.location}</h4>
                    </div>
                    <div className="report-print-download-icon-container">
                      <ReactToPrint
                        trigger={() => (
                          <IconButton className="print-icon-container">
                            <i class="bi bi-printer printer-icon"></i>
                          </IconButton>
                        )}
                        content={() => dailyCashCardReportPrint}
                        pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
                      />
                      <IconButton className="download-icon-container">
                        <i class="bi bi-arrow-down-circle download-icon"></i>
                      </IconButton>
                    </div>
                  </div>
                </div>

                {/* daily cash card table */}
                {isSubmitBtnClick === true ? (
                  // shift based table without details
                  <>
                    {details === false ? (
                      <>
                        <div className="daily-cash-card-summary-table">
                          <table>
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>Order No</th>
                                <th>Amount</th>
                                <th>Commission</th>
                                <th>Balance</th>
                                <th>Wallet</th>
                                <th>VAT</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                              filteredDailyCashCard!== undefined&&
                              filteredDailyCashCard?.map(
                                (r, i) => (
                                  <tr>
                                    <td>{r?.type.toUpperCase()}</td>
                                    <td>&nbsp;</td>
                                    <td>{r?.paidAmount?.toFixed(2)}</td>
                                    <td>{r?.commissionTotal?.toFixed(2)}</td>
                                    <td>{r?.balanceTotal?.toFixed(2)}</td>
                                    <td>{r?.walletTotal}</td>
                                    <td>{r?.vatTotal}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                        {/* return table */}
                        {dailyCashCardData?.paidSummary?.returnList?.length !==
                          0 && (
                          <div className="daily-cash-card-expense-table-container">
                            <h4>Return</h4>
                            <div className="daily-cash-card-summary-table">
                              <table>
                                <tbody>
                                  {dailyCashCardData?.paidSummary?.returnList?.map(
                                    (r) => (
                                      <tr>
                                        <td style={{ width: "19%" }}>
                                          {r?.type.toUpperCase()}
                                        </td>
                                        <td style={{ width: "16%" }}>&nbsp;</td>
                                        <td style={{ width: "14%" }}>
                                          {r?.paidAmount?.toFixed(2)}
                                        </td>
                                        <td style={{ width: "21%" }}>
                                          {r?.commissionTotal?.toFixed(2)}
                                        </td>
                                        <td style={{ width: "14%" }}>
                                          {r?.balanceTotal?.toFixed(2)}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                        {/* cheque list */}
                        {
                          dailyCashCardData?.paidSummary?.chequeList?.list?.length !==0 &&
                        
                          <div className="daily-cash-card-expense-table-container">
                             <div style={{display:"flex",justifyContent:"space-between"}}>
                             <h4>Cheque</h4>
                             <h4 style={{margin:"2% 2% 0 0"}}>Cheque Total : {dailyCashCardData?.paidSummary?.chequeList?.total}</h4>
                             </div>
                            
                            <div className="daily-cash-card-summary-table">
                              <table>
                                <thead>
                                  <tr>
                                    <th>No</th>
                                    <th>Order No</th>
                                    <th>Bank Name</th>
                                    <th>Date</th>
                                    <th>cheque Date</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                 {
                                  dailyCashCardData?.paidSummary?.chequeList?.list?.map((r,i)=>(
                                    <tr>
                                      <td>{i+1}</td>
                                      <td>{r?.orderId}</td>
                                      <td>{r?.bankName}</td>
                                      <td>{r?.date?.split(" ")[0]}</td>
                                      <td>{r?.chequedate?.split(" ")[0]}</td>
                                      <td>{r?.paidAmount}</td>
                                    </tr>
                                  ))
                                 }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        }
                      </>
                    ) : (
                      // shift based details table
                      <>
                        <div className="daily-cash-card-summary-table daily-cash-expand-table">
                          <table>
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>Order No</th>
                                <th>Amount</th>
                                <th>Commission</th>
                                <th>Balance</th>
                                <th>Wallet</th>
                                <th>VAT</th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredDailyCashCard!== undefined&&
                                filteredDailyCashCard?.map(
                                (r, i) => (
                                  <tr>
                                    <td>
                                      <h4>{r?.type.toUpperCase()}</h4>
                                      {r?.orderInfo?.map((k) => (
                                        <tr className="report-details-table">
                                          <td></td>
                                        </tr>
                                      ))}
                                    </td>
                                    <td>
                                      <h4>&nbsp;</h4>
                                      {r?.orderInfo?.map((k) => (
                                        <tr className="report-details-table">
                                          <td>{k?.orderId}</td>
                                        </tr>
                                      ))}
                                    </td>
                                    <td>
                                      <h4>{r?.paidAmount?.toFixed(2)}</h4>
                                      {r?.orderInfo?.map((k) => (
                                        <tr className="report-details-table">
                                          <td>{k?.paidAmount?.toFixed(2)}</td>
                                        </tr>
                                      ))}
                                    </td>
                                    <td>
                                      <h4>{r?.commissionTotal?.toFixed(2)}</h4>
                                      {r?.orderInfo?.map((k) => (
                                        <tr className="report-details-table">
                                          <td>{k?.commission?.toFixed(2)}</td>
                                        </tr>
                                      ))}
                                    </td>
                                    <td>
                                      <h4>{r?.balanceTotal?.toFixed(2)}</h4>
                                      {r?.orderInfo?.map((k) => (
                                        <tr className="report-details-table">
                                          <td>{k?.balance.toFixed(2)}</td>
                                        </tr>
                                      ))}
                                    </td>
                                    <td>
                                      <h4>{r?.walletTotal?.toFixed(2)}</h4>
                                      {
                                        r?.orderInfo?.map((k)=>(
                                          <tr className="report-details-table">
                                            <td>{k?.wallet?.toFixed(2)}</td>
                                          </tr>
                                        ))
                                      }
                                    </td>
                                    <td>
                                      <h4>{r?.vatTotal?.toFixed(2)}</h4>
                                      {
                                        r?.orderInfo?.map((k)=>(
                                          <tr className="report-details-table">
                                            <td>{k?.vatTotal?.toFixed(2)}</td>
                                          </tr>
                                        ))
                                      }
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                        {/* return table */}
                        {
                          dailyCashCardData?.paidSummary?.returnList?.length !==0 &&
                        <div className="daily-cash-card-expense-table-container">
                          <h4>Return</h4>
                          <div className="daily-cash-card-summary-table">
                            <table>
                              <tbody>
                                {dailyCashCardData?.paidSummary?.returnList?.map(
                                  (r) => (
                                    <tr>
                                      <td>
                                        <h4>{r?.type.toUpperCase()}</h4>
                                        <br />
                                        {r?.orderInfo?.map((k) => (
                                          <tr className="report-details-table">
                                            <td>&nbsp;</td>
                                          </tr>
                                        ))}
                                      </td>
                                      <td>
                                        &nbsp;
                                        <br />
                                        {r?.orderInfo?.map((k) => (
                                          <tr className="report-details-table">
                                            <td>{k?.orderId}</td>
                                          </tr>
                                        ))}
                                      </td>

                                      <td>
                                        <h4>{r?.paidAmount?.toFixed(2)}</h4>
                                        <br />
                                        {r?.orderInfo?.map((k) => (
                                          <tr className="report-details-table">
                                            <td>{k?.paidAmount?.toFixed(2)}</td>
                                          </tr>
                                        ))}
                                      </td>
                                      <td>
                                        <h4>{r?.commissionTotal.toFixed(2)}</h4>
                                        <br />
                                        {r?.orderInfo?.map((k) => (
                                          <tr className="report-details-table">
                                            <td>{k?.commission?.toFixed(2)}</td>
                                          </tr>
                                        ))}
                                      </td>
                                      <td>
                                        <h4>{r?.balanceTotal?.toFixed(2)}</h4>
                                        <br />
                                        {r?.orderInfo?.map((k) => (
                                          <tr className="report-details-table">
                                            <td>{k?.balance?.toFixed(2)}</td>
                                          </tr>
                                        ))}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                         }
                        {/* cheque list table */}
                        {
                          dailyCashCardData?.paidSummary?.chequeList?.list?.length !==0 &&
                        
                          <div className="daily-cash-card-expense-table-container">
                             <div style={{display:"flex",justifyContent:"space-between"}}>
                             <h4>Cheque</h4>
                             <h4 style={{margin:"2% 2% 0 0"}}>Cheque Total : {dailyCashCardData?.paidSummary?.chequeList?.total}</h4>
                             </div>
                            
                            <div className="daily-cash-card-summary-table">
                              <table>
                                <thead>
                                  <tr>
                                    <th>No</th>
                                    <th>Order No</th>
                                    <th>Bank Name</th>
                                    <th>Date</th>
                                    <th>cheque Date</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                 {
                                  dailyCashCardData?.paidSummary?.chequeList?.list?.map((r,i)=>(
                                    <tr>
                                      <td>{i+1}</td>
                                      <td>{r?.orderId}</td>
                                      <td>{r?.bankName}</td>
                                      <td>{r?.date?.split(" ")[0]}</td>
                                      <td>{r?.chequedate?.split(" ")[0]}</td>
                                      <td>{r?.paidAmount}</td>
                                    </tr>
                                  ))
                                 }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        }
                      </>
                    )}
                  </>
                ) : (
                  <h3 className="no-data">No Data</h3>
                )}
              </>
            ) : // date filter container
            fromDate !== "" && toDate !== "" && isSubmitBtnClick === true ? (
              <div className="daily-cash-card-date-filter-container">
                <div className="daily-cash-card-date-filter-top-container">
                  <p>Location : {dailyCashCardData.location}</p>
                  <div
                    className="report-print-download-icon-container date-filter-icon"
                    style={{ width: "215px", margin: "0" }}
                  >
                    <ReactToPrint
                      trigger={() => (
                        <IconButton className="print-icon-container">
                          <i class="bi bi-printer printer-icon"></i>
                        </IconButton>
                      )}
                      content={() => dailyCashCardDateFilterPrint}
                      pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
                    />

                    <IconButton className="download-icon-container">
                      <i class="bi bi-arrow-down-circle download-icon"></i>
                    </IconButton>
                  </div>
                </div>
                {/* map */}
                {dailyCashCardData?.list?.map((r) => (
                  <div>
                    <div className="daily-report-date-filter-middle-container">
                      <h4>Shift No : {r?.shiftId}</h4>
                      <div>
                        <p>Shift Starting Time : {`${convertDateFormat(r?.shiftopeningTime)} ${r?.shiftopeningTime?.split(" ")[1]}`} </p>
                        <p>Shift Closing Time : {r?.shiftclosingTime !== "Active Shift" ? `${convertDateFormat(r?.shiftclosingTime)} ${r?.shiftclosingTime?.split(" ")[1]}`:r?.shiftclosingTime}</p>
                      </div>
                    </div>
                    {/* table */}
                    {details === false ? (
                      // date filter table without details
                      <>
                        <div className="daily-cash-card-summary-table">
                          <table>
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>Order No</th>
                                <th>Amount</th>
                                <th>Commission</th>
                                <th>Balance</th>
                                <th>Wallet</th>
                                <th>VAT</th>
                              </tr>
                            </thead>
                            <tbody>
                              {r?.paidSummary?.orderList?.length !== 0 ? (
                                r?.paidSummary?.orderList?.map((k, i) => (
                                  <tr>
                                    <td>{k?.type?.toUpperCase()}</td>
                                    <td>&nbsp;</td>
                                    <td>{k?.paidAmount?.toFixed(2)}</td>
                                    <td>{k?.commissionTotal?.toFixed(2)}</td>
                                    <td>{k?.balanceTotal?.toFixed(2)}</td>
                                    <td>{k?.walletTotal}</td>
                                    <td>{k?.vatTotal}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6}>No Records</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        {/* return list */}
                        {r?.paidSummary?.returnList?.length !== 0 && (
                          <div className="daily-cash-card-expense-table-container">
                            <h4>Return</h4>
                            <div className="daily-cash-card-summary-table">
                              <table>
                                <tbody>
                                  {r?.paidSummary?.returnList?.length !== 0 ? (
                                    r?.paidSummary?.returnList?.map((m) => (
                                      <tr>
                                        <td style={{ width: "10%" }}>
                                          {m?.type.toUpperCase()}
                                        </td>
                                        <td style={{ width: "18%" }}>&nbsp;</td>
                                        <td style={{ width: "17%" }}>
                                          {m?.paidAmount?.toFixed(2)}
                                        </td>
                                        <td style={{ width: "25%" }}>
                                          {m?.commissionTotal?.toFixed(2)}
                                        </td>
                                        <td style={{ width: "16%" }}>
                                          {m?.balanceTotal?.toFixed(2)}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={6}>No Records</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}

                        {/* check list table */}
                        {
                          r?.paidSummary?.chequeList?.list !== 0 && (
                          <div className="daily-cash-card-expense-table-container">
                             <div style={{display:"flex",justifyContent:"space-between"}}>
                             <h4>Cheque</h4>
                             <h4 style={{margin:"2% 2% 0 0"}}>Cheque Total : {r?.paidSummary?.chequeList?.total}</h4>
                             </div>
                            
                            <div className="daily-cash-card-summary-table">
                              <table>
                                <thead>
                                  <tr>
                                    <th>No</th>
                                    <th>Order No</th>
                                    <th>Bank Name</th>
                                    <th>Date</th>
                                    <th>cheque Date</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                 {
                                  r?.paidSummary?.chequeList?.list?.map((k,i)=>(
                                    <tr>
                                      <td>{i+1}</td>
                                      <td>{k?.orderId}</td>
                                      <td>{k?.bankName}</td>
                                      <td>{k?.date?.split(" ")[0]}</td>
                                      <td>{k?.chequedate?.split(" ")[0]}</td>
                                      <td>{k?.paidAmount}</td>
                                    </tr>
                                  ))
                                 }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}

                      </>
                    ) : (
                      // date filter table with details
                      <>
                        <div className="daily-cash-card-summary-table daily-cash-expand-table">
                          <table>
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>Order No</th>
                                <th>Amount</th>
                                <th>Commission</th>
                                <th>Balance</th>
                                <th>Wallet</th>
                                <th>VAT</th>
                              </tr>
                            </thead>
                            <tbody>
                              {r?.paidSummary?.orderList?.length !== 0 ? (
                                r?.paidSummary?.orderList?.map((k) => (
                                  <tr>
                                    <td>
                                      <h4>{k?.type.toUpperCase()}</h4>
                                      {k?.orderInfo?.map((m) => (
                                        <tr className="report-details-table">
                                          <td>&nbsp;</td>
                                        </tr>
                                      ))}
                                    </td>
                                    <td>
                                      <h4>&nbsp;</h4>
                                      {k?.orderInfo?.map((m) => (
                                        <tr className="report-details-table">
                                          <td>{m?.orderId}</td>
                                        </tr>
                                      ))}
                                    </td>
                                    <td>
                                      <h4>{k?.paidAmount?.toFixed(2)}</h4>
                                      {k?.orderInfo?.map((m) => (
                                        <tr className="report-details-table">
                                          <td>{m?.paidAmount?.toFixed(2)}</td>
                                        </tr>
                                      ))}
                                    </td>
                                    <td>
                                      <h4>{k?.commissionTotal?.toFixed(2)}</h4>
                                      {k?.orderInfo?.map((m) => (
                                        <tr className="report-details-table">
                                          <td>{m?.commission?.toFixed(2)}</td>
                                        </tr>
                                      ))}
                                    </td>
                                    <td>
                                      <h4>{k?.balanceTotal.toFixed(2)}</h4>
                                      {k?.orderInfo?.map((m) => (
                                        <tr className="report-details-table">
                                          <td>{m?.balance?.toFixed(2)}</td>
                                        </tr>
                                      ))}
                                    </td>
                                    <td>
                                      <h4>{k?.walletTotal?.toFixed(2)}</h4>
                                      {k?.orderInfo?.map((m) => (
                                        <tr className="report-details-table">
                                          <td>{m?.wallet?.toFixed(2)}</td>
                                        </tr>
                                      ))}
                                    </td>
                                    <td>
                                      <h4>{k?.vatTotal?.toFixed(2)}</h4>
                                      {k?.orderInfo?.map((m) => (
                                        <tr className="report-details-table">
                                          <td>{m?.vatTotal?.toFixed(2)}</td>
                                        </tr>
                                      ))}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6}>No Records</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        {/* return list table */}
                        {r?.paidSummary?.returnList?.length !== 0 && (
                          <div className="daily-cash-card-expense-table-container">
                            <h4>Return</h4>
                            <div className="daily-cash-card-summary-table">
                              <table>
                                <tbody>
                                  {r?.paidSummary?.returnList?.map((m) => (
                                    <tr>
                                      <td>
                                        <h4>{m?.type.toUpperCase()}</h4>
                                        <br />
                                        {m?.orderInfo?.map((k) => (
                                          <tr className="report-details-table">
                                            <td>&nbsp;</td>
                                          </tr>
                                        ))}
                                      </td>
                                      <td>
                                        <h4>&nbsp;</h4>
                                        <br />
                                        {m?.orderInfo?.map((k) => (
                                          <tr className="report-details-table">
                                            <td>{k?.orderId}</td>
                                          </tr>
                                        ))}
                                      </td>
                                      <td>
                                        <h4>{m?.paidAmount?.toFixed(2)}</h4>
                                        <br />
                                        {m?.orderInfo?.map((k) => (
                                          <tr className="report-details-table">
                                            <td>{k?.paidAmount?.toFixed(2)}</td>
                                          </tr>
                                        ))}
                                      </td>
                                      <td>
                                        <h4>
                                          {m?.commissionTotal?.toFixed(2)}
                                        </h4>
                                        <br />
                                        {m?.orderInfo?.map((k) => (
                                          <tr className="report-details-table">
                                            <td>{k?.commission?.toFixed(2)}</td>
                                          </tr>
                                        ))}
                                      </td>
                                      <td>
                                        <h4>{m?.balanceTotal?.toFixed(2)}</h4>
                                        <br />
                                        {m?.orderInfo?.map((k) => (
                                          <tr className="report-details-table">
                                            <td>{k?.balance?.toFixed(2)}</td>
                                          </tr>
                                        ))}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                        {/* check list table */}
                        {
                          r?.paidSummary?.chequeList?.list !== 0 && (
                          <div className="daily-cash-card-expense-table-container">
                             <div style={{display:"flex",justifyContent:"space-between"}}>
                             <h4>Cheque</h4>
                             <h4 style={{margin:"2% 2% 0 0"}}>Cheque Total : {r?.paidSummary?.chequeList?.total}</h4>
                             </div>
                            
                            <div className="daily-cash-card-summary-table">
                              <table>
                                <thead>
                                  <tr>
                                    <th>No</th>
                                    <th>Order No</th>
                                    <th>Bank Name</th>
                                    <th>Date</th>
                                    <th>cheque Date</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                 {
                                  r?.paidSummary?.chequeList?.list?.map((k,i)=>(
                                    <tr>
                                      <td>{i+1}</td>
                                      <td>{k?.orderId}</td>
                                      <td>{k?.bankName}</td>
                                      <td>{k?.date?.split(" ")[0]}</td>
                                      <td>{k?.chequedate?.split(" ")[0]}</td>
                                      <td>{k?.paidAmount}</td>
                                    </tr>
                                  ))
                                 }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))}

                {/* map end */}
              </div>
            ) : undefined
          }

          {/* expense table */}
          {expense === true && (
            <div className="daily-cash-card-expense-table-container">
              <h4>Expense</h4>
              <div className="daily-cash-card-summary-table">
                <table>
                  <tbody>
                    {dailyCashCardData?.expensetable?.length !== 0 ? (
                      dailyCashCardData?.expensetable?.map((r) => (
                        <tr className="table-cell-border-none">
                          <td style={{ width: "20%" }}>{r?.expenseName}</td>
                          <td>&nbsp;</td>
                          <td>{r?.amount}</td>
                          <td style={{ width: "20%" }}>
                            {r?.commission?.toFixed(2)}
                          </td>
                          <td style={{ width: "20%" }}>
                            {r?.balance?.toFixed(2)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>No Records</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {/* category table */}
          
            {/* <div className="daily-cash-card-category-container">
            {isReadymadeChecked &&  <> {dailyCashCardData?.readymade?.list?.length !== 0 ? (
                <div className="daily-cash-card-table-container">
                  <p>Readymade</p>
                  <div className="daily-cash-card-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Unit</th>
                          <th>Qty</th>
                          <th>Rate</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dailyCashCardData?.readymade?.list?.length !== 0 ? (
                          dailyCashCardData?.readymade?.list?.map((r, i) => (
                            <tr>
                              <td>{r.productName}</td>
                              <td>{r?.unit}</td>
                              <td>{r?.qty}</td>
                              <td>{r?.rate}</td>
                              <td>{r?.amount?.toFixed(2)}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8}>No Data</td>
                          </tr>
                        )}

                      
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : undefined}</>}
              {isMaterialChecked && <>{dailyCashCardData?.material?.list?.length !== 0 ? (
                <div className="daily-cash-card-table-container">
                  <p>Material</p>
                  <div className="daily-cash-card-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Unit</th>
                          <th>Qty</th>
                          <th>Rate</th>
                          <th>Amount</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {dailyCashCardData?.material?.list?.length !== 0 ? (
                          dailyCashCardData?.material?.list?.map((r, i) => (
                            <tr>
                              <td>{r?.productName}</td>
                              <td>{r?.unit}</td>
                              <td>{r?.qty}</td>
                              <td>{r?.rate}</td>
                              <td>{r?.amount?.toFixed(2)}</td>
                             
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8}>No Data</td>
                          </tr>
                        )}

                       
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : undefined}</>}
            {isAccessoryChecked && <> {dailyCashCardData?.accessories?.list?.length !== 0 ? (
                <div className="daily-cash-card-table-container">
                  <p>Accessories</p>
                  <div className="daily-cash-card-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Unit</th>
                          <th>Qty</th>
                          <th>Rate</th>
                          <th>Amount</th>
                       
                        </tr>
                      </thead>
                      <tbody>
                        {dailyCashCardData?.accessories?.list?.length !== 0 ? (
                          dailyCashCardData?.accessories?.list?.map((r, i) => (
                            <tr>
                              <td>{r.productName}</td>
                              <td>{r?.unit}</td>
                              <td>{r?.qty}</td>
                              <td>{r?.rate}</td>
                              <td>{r?.amount?.toFixed(2)}</td>
                             
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8}>No Data</td>
                          </tr>
                        )}

                      
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : undefined}</>}
            </div>  */}

            <div className="daily-cash-card-category-container">
              {
                dailyCashCardData?.product?.map((r,i)=>(
                  <div className="daily-cash-card-table-container">
                  <p>{r?.catName}</p>
                  <div className="daily-cash-card-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Unit</th>
                          <th>Qty</th>
                          <th>Rate</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          r?.list?.map((k,i)=>(
                            <tr>
                            <td>{k?.productName}</td>
                            <td>{k?.unit}</td>
                            <td>{k?.qty}</td>
                            <td>{k?.rate}</td>
                            <td>{k?.amount}</td>
                          </tr>
                       
                          ))
                        }
                        
                         
                        

                       
                      </tbody>
                    </table>
                  </div>
                </div>
                ))
              }
            
            </div>


         
          <div style={{display:"flex"}}>
          {dailyCashCardData !== undefined && (
            <div className="daily-cash-card-summary-bottom-container" style={{width:"50%"}}>
               <div className="daily-cash-card-summary-bottom-single-container">
                <p className="second-text">Amount After Return</p>
                <p className="second-text">
                  {dailyCashCardData?.amountafterreturn?.toFixed(2)}
                </p>
              </div>
              <div className="daily-cash-card-summary-bottom-single-container">
                <p className="first-text">Cash in Hand After Expense</p>
                <p className="first-text">
                  {dailyCashCardData?.cashInHandAfterExpense?.toFixed(2)}
                </p>
              </div>
              <div className="daily-cash-card-summary-bottom-single-container">
                <p className="second-text">Total Sale Without Expenses</p>
                <p className="second-text">
                  {dailyCashCardData?.totalSaleWoExpense?.toFixed(2)}
                </p>
              </div>
            </div>
           
          )}
          {
             (dailyCashCardData?.cashOut?.entries?.length !==0 ||  dailyCashCardData?.cashIn?.entries?.length !==0) &&
             <div style={{width:"50%",margin:"1%",padding:"1%",borderRadius:"2%",border:"1px solid rgb(221 216 216)"}}>
             {
              dailyCashCardData?.cashOut?.entries?.length !==0 &&
                <div className="daily-report-cash-in-or-out">
              <p>CASH OUT</p>
                 <table>
                 <thead>
                    <tr>
                       <th>Date</th>
                       <th>Time</th>
                       <th>Cash</th>
                       <th>Collected</th>
                    </tr>
                 </thead>
                 <tbody>
                 {
                     dailyCashCardData?.cashOut?.entries?.map((r,i)=>(
                       <tr>
                        <td>{r?.date}</td>
                        <td>{r?.time}</td>
                        <td>{r?.cash}</td>
                        <td>{r?.collectedBy}</td>
                      </tr>
                    
                     ))
                   }
                  
                 </tbody>
              </table>
                </div>
             }
             {
              dailyCashCardData?.cashIn?.entries?.length !==0 &&
               <div className="daily-report-cash-in-or-out">
              <p>CASH IN</p>
                 <table>
                 <thead>
                    <tr>
                       <th>Date</th>
                       <th>Time</th>
                       <th>Cash</th>
                       <th>Collected</th>
                    </tr>
                 </thead>
                 <tbody>
                   {
                     dailyCashCardData?.cashIn?.entries?.map((r,i)=>(
                       <tr>
                        <td>{r?.date}</td>
                        <td>{r?.time}</td>
                        <td>{r?.cash}</td>
                        <td>{r?.collectedBy}</td>
                      </tr>
                    
                     ))
                   }
                  
                 </tbody>
              </table>
               </div>
             }
              
             </div>
          }
          
           </div>
          {dailyCashCardData !== undefined && (
            <div className="daily-cash-session-total-container">
              <p>Session Total</p>
              <p style={{ marginLeft: "4%" }}>
                {dailyCashCardData?.sessionTotal?.amount?.toFixed(2)}
              </p>
              <p>{dailyCashCardData?.sessionTotal?.commission?.toFixed(2)}</p>
              <p>{dailyCashCardData?.sessionTotal?.total?.toFixed(2)}</p>
            </div>
          )}
          
        </div>
      ) : (
        <div className="daily-report-bottom-container">
          <h3 className="no-data">No Records</h3>
        </div>
      )
      
      
    
      }
      {/* daily cash card report print view */}
      <div 
        style={{ display: "none" }}
      >
        <div ref={(el) => (dailyCashCardReportPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "30%" }}
            >
               <h6 style={{margin:"0 0 0 3%"}}>Printed On {convertDateFormat(today)}&nbsp;{time}</h6>
              <p>Shift No&nbsp;:&nbsp;{dailyCashCardData?.shiftId}</p>
              <p>Location&nbsp;:&nbsp;{dailyCashCardData?.location}</p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "35%" }}
            >
              <h3>{loginResponse?.storeName}</h3>
              <p>DAILY CASH CARD SUMMARY</p>
              <h5 style={{margin:"0",textAlign:"center"}}>Report On {dailyCashCardData?.shiftclosingTime==="Active Shift"?convertDateFormat(today):`${convertDateFormat(dailyCashCardData?.shiftclosingTime)} ${dailyCashCardData?.shiftclosingTime?.split(" ")[1]}`}</h5>
            </div>
            <div
              className="print-view-top-right-container"
              style={{ width: "35%" }}
            >
              <p style={{ fontSize: "0.8rem" }}>
                Shift Open Time&nbsp;:&nbsp;
                {`${convertDateFormat(dailyCashCardData?.shiftopeningTime)} ${dailyCashCardData?.shiftopeningTime?.split(" ")[1]}`}
              </p>
              <p style={{ fontSize: "0.8rem" }}>
                Shift Closing Time&nbsp;:&nbsp;
                {dailyCashCardData?.shiftclosingTime !== "Active Shift"?`${convertDateFormat(dailyCashCardData?.shiftclosingTime)} ${dailyCashCardData?.shiftclosingTime?.split(" ")[1]}`:dailyCashCardData?.shiftclosingTime}
              </p>
            </div>
          </div>
          {details === false ? (
            <>
              <div
                className="daily-cash-card-summary-table"
                style={{
                  maxHeight: "none",
                  margin: "1% 6%",
                  overflowY: "hidden",
                }}
              >
                <table>
                  <thead style={{height:"15px"}}>
                    <tr 
                    style={{border:"1px solid #000",borderLeft:"0",borderRight:"0"}}
                    >
                      <th>Type</th>
                      <th>Order No</th>
                      <th>Amount</th>
                      <th>Commission</th>
                      <th>Balance</th>
                      <th>Wallet</th>
                      <th>VAT</th>
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: "#fff" }}>
                    {filteredDailyCashCard!== undefined&&
                              filteredDailyCashCard?.map((r, i) => (
                      <tr>
                        <td>{r?.type.toUpperCase()}</td>
                        <td>&nbsp;</td>
                        <td>{r?.paidAmount?.toFixed(2)}</td>
                        <td>{r?.commissionTotal?.toFixed(2)}</td>
                        <td>{r?.balanceTotal?.toFixed(2)}</td>
                        <td>{r?.walletTotal}</td>
                        <td>{r?.vatTotal}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {dailyCashCardData?.paidSummary?.returnList?.length !== 0 && (
                <div
                  className="daily-cash-card-expense-table-container"
                  style={{ margin: "1% 6%" }}
                >
                  <h4>Return</h4>
                  <div
                    className="daily-cash-card-summary-table"
                    style={{
                      maxHeight: "none",
                      overflowY: "hidden",
                      backgroundColor: "#fff",
                    }}
                  >
                    <table>
                      <tbody style={{ backgroundColor: "#fff" }}>
                        {dailyCashCardData?.paidSummary?.returnList?.map(
                          (r) => (
                            <tr>
                              <td style={{ width: "19%" }}>
                                {r?.type.toUpperCase()}
                              </td>
                              <td style={{ width: "16%" }}>&nbsp;</td>
                              <td style={{ width: "14%" }}>
                                {r?.paidAmount?.toFixed(2)}
                              </td>
                              <td style={{ width: "21%" }}>
                                {r?.commissionTotal?.toFixed(2)}
                              </td>
                              <td style={{ width: "14%" }}>
                                {r?.balanceTotal?.toFixed(2)}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
               {
                          dailyCashCardData?.paidSummary?.chequeList?.list?.length !==0 &&
                        
                          <div className="daily-cash-card-expense-table-container">
                             <div style={{display:"flex",justifyContent:"space-between",padding:"1% 6%"}}>
                             <h4>Cheque</h4>
                             <h4 style={{margin:"2% 2% 0 0"}}>Cheque Total : {dailyCashCardData?.paidSummary?.chequeList?.total}</h4>
                             </div>
                            
                            <div 
                            className="daily-cash-card-summary-table"
                            style={{
                              maxHeight: "none",
                              margin: "1% 6%",
                              overflowY: "hidden",
                            }}
                            >
                              <table>
                                <thead>
                                  <tr>
                                    <th>No</th>
                                    <th>Order No</th>
                                    <th>Bank Name</th>
                                    <th>Date</th>
                                    <th>cheque Date</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody style={{ backgroundColor: "#fff" }}>
                                 {
                                  dailyCashCardData?.paidSummary?.chequeList?.list?.map((r,i)=>(
                                    <tr>
                                      <td>{i+1}</td>
                                      <td>{r?.orderId}</td>
                                      <td>{r?.bankName}</td>
                                      <td>{r?.date?.split(" ")[0]}</td>
                                      <td>{r?.chequedate?.split(" ")[0]}</td>
                                      <td>{r?.paidAmount}</td>
                                    </tr>
                                  ))
                                 }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        }
            </>
          ) : (
            <>
              <div
                className="daily-cash-card-summary-table daily-cash-expand-table"
                style={{
                  backgroundColor: "#fff",
                  maxHeight: "none",
                  margin: "1% 6%",
                  overflowY: "hidden",
                }}
              >
                <table style={{ backgroundColor: "#fff" }}>
                <thead style={{height:"15px"}}>
                    <tr 
                    style={{border:"1px solid #000",borderLeft:"0",borderRight:"0"}}
                    >
                      <th>Type</th>
                      <th>Order No</th>
                      <th>Amount</th>
                      <th>Commission</th>
                      <th>Balance</th>
                      <th>Wallet</th>
                      <th>VAT</th>
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: "#fff" }}>
                    {filteredDailyCashCard!== undefined &&
                      filteredDailyCashCard?.map((r, i) => (
                      <tr>
                        <td>
                          <h4>{r?.type.toUpperCase()}</h4>
                          {r?.orderInfo?.map((k) => (
                            <tr className="report-details-table">
                              <td></td>
                            </tr>
                          ))}
                        </td>
                        <td>
                          <h4>&nbsp;</h4>
                          {r?.orderInfo?.map((k) => (
                            <tr className="report-details-table">
                              <td>{k?.orderId}</td>
                            </tr>
                          ))}
                        </td>
                        <td>
                          <h4>{r?.paidAmount?.toFixed(2)}</h4>
                          {r?.orderInfo?.map((k) => (
                            <tr className="report-details-table">
                              <td>{k?.paidAmount?.toFixed(2)}</td>
                            </tr>
                          ))}
                        </td>
                        <td>
                          <h4>{r?.commissionTotal?.toFixed(2)}</h4>
                          {r?.orderInfo?.map((k) => (
                            <tr className="report-details-table">
                              <td>{k?.commission?.toFixed(2)}</td>
                            </tr>
                          ))}
                        </td>
                        <td>
                          <h4>{r?.balanceTotal?.toFixed(2)}</h4>
                          {r?.orderInfo?.map((k) => (
                            <tr className="report-details-table">
                              <td>{k?.balance?.toFixed(2)}</td>
                            </tr>
                          ))}
                        </td>
                        <td>
                          <h4>{r?.walletTotal?.toFixed(2)}</h4>
                          {r?.orderInfo?.map((k) => (
                            <tr className="report-details-table">
                              <td>{k?.wallet?.toFixed(2)}</td>
                            </tr>
                          ))}
                        </td>
                        <td>
                          <h4>{r?.vatTotal?.toFixed(2)}</h4>
                          {r?.orderInfo?.map((k) => (
                            <tr className="report-details-table">
                              <td>{k?.vatTotal?.toFixed(2)}</td>
                            </tr>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {
                dailyCashCardData?.paidSummary?.returnList?.length !==0 &&
                <div
                className="daily-cash-card-expense-table-container"
                style={{ margin: "1% 6%" }}
              >
                <h4>Return</h4>
                <div
                  className="daily-cash-card-summary-table"
                  style={{
                    maxHeight: "none",
                    overflowY: "hidden",
                    backgroundColor: "#fff",
                  }}
                >
                  <table>
                    <tbody style={{ backgroundColor: "#fff" }}>
                      {dailyCashCardData?.paidSummary?.returnList?.map((r) => (
                        <tr>
                          <td>
                            <h4>{r?.type.toUpperCase()}</h4>
                            <br />
                            {r?.orderInfo?.map((k) => (
                              <tr className="report-details-table">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                          </td>
                          <td>
                            &nbsp;
                            <br />
                            {r?.orderInfo?.map((k) => (
                              <tr className="report-details-table">
                                <td>{k?.orderId}</td>
                              </tr>
                            ))}
                          </td>

                          <td>
                            <h4>{r?.paidAmount?.toFixed(2)}</h4>
                            <br />
                            {r?.orderInfo?.map((k) => (
                              <tr className="report-details-table">
                                <td>{k?.paidAmount?.toFixed(2)}</td>
                              </tr>
                            ))}
                          </td>
                          <td>
                            <h4>{r?.commissionTotal?.toFixed(2)}</h4>
                            <br />
                            {r?.orderInfo?.map((k) => (
                              <tr className="report-details-table">
                                <td>{k?.commission?.toFixed(2)}</td>
                              </tr>
                            ))}
                          </td>
                          <td>
                            <h4>{r?.balanceTotal?.toFixed(2)}</h4>
                            <br />
                            {r?.orderInfo?.map((k) => (
                              <tr className="report-details-table">
                                <td>{k?.balance?.toFixed(2)}</td>
                              </tr>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              }
              {/* checklist */}
              {
                          dailyCashCardData?.paidSummary?.chequeList?.list?.length !==0 &&
                        
                          <div className="daily-cash-card-expense-table-container">
                             <div style={{display:"flex",justifyContent:"space-between",padding:"1% 6%"}}>
                             <h4>Cheque</h4>
                             <h4 style={{margin:"2% 2% 0 0"}}>Cheque Total : {dailyCashCardData?.paidSummary?.chequeList?.total}</h4>
                             </div>
                            
                            <div 
                            className="daily-cash-card-summary-table"
                            style={{
                              maxHeight: "none",
                              margin: "1% 6%",
                              overflowY: "hidden",
                            }}
                            >
                              <table>
                                <thead>
                                  <tr>
                                    <th>No</th>
                                    <th>Order No</th>
                                    <th>Bank Name</th>
                                    <th>Date</th>
                                    <th>cheque Date</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody style={{backgroundColor:"#fff"}}>
                                 {
                                  dailyCashCardData?.paidSummary?.chequeList?.list?.map((r,i)=>(
                                    <tr>
                                      <td>{i+1}</td>
                                      <td>{r?.orderId}</td>
                                      <td>{r?.bankName}</td>
                                      <td>{r?.date?.split(" ")[0]}</td>
                                      <td>{r?.chequedate?.split(" ")[0]}</td>
                                      <td>{r?.paidAmount}</td>
                                    </tr>
                                  ))
                                 }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        }
             
            </>
          )}
          {expense === true && (
            <div
              className="daily-cash-card-expense-table-container"
              style={{ margin: "1% 6%" }}
            >
              <h4>Expense</h4>
              <div
                className="daily-cash-card-summary-table"
                style={{ maxHeight: "none", overflowY: "hidden" }}
              >
                <table>
                  <tbody style={{ backgroundColor: "#fff" }}>
                    {dailyCashCardData?.expensetable?.length !== 0 ? (
                      dailyCashCardData?.expensetable?.map((r) => (
                        <tr className="table-cell-border-none">
                          <td style={{ width: "20%" }}>{r?.expenseName}</td>
                          <td>&nbsp;</td>
                          <td>{r?.amount?.toFixed(2)}</td>
                          <td style={{ width: "20%" }}>
                            {r?.commission?.toFixed(2)}
                          </td>
                          <td style={{ width: "20%" }}>
                            {r?.balance?.toFixed(2)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td style={{ backgroundColor: "#fff" }} colSpan={5}>
                          No Records
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

         
          <div style={{display:"flex"}}>
          <div
            className="daily-cash-card-summary-bottom-container"
            style={{ margin: "1% 6%" }}
          >
             <div className="daily-cash-card-summary-bottom-single-container">
                <p className="second-text">Amount After Return</p>
                <p className="second-text">
                  {dailyCashCardData?.amountafterreturn?.toFixed(2)}
                </p>
              </div>
            <div className="daily-cash-card-summary-bottom-single-container">
              <p className="first-text">Cash in Hand After Expense</p>
              <p className="first-text">
                {dailyCashCardData?.cashInHandAfterExpense}
              </p>
            </div>
            <div className="daily-cash-card-summary-bottom-single-container">
              <p className="second-text">Total Sale Without Expenses</p>
              <p className="second-text">
                {dailyCashCardData?.totalSaleWoExpense}
              </p>
            </div>
          </div>
          {
             (dailyCashCardData?.cashOut?.entries?.length !==0 ||  dailyCashCardData?.cashIn?.entries?.length !==0) &&

          
          <div style={{width:"50%",margin:"1%",padding:"1%",borderRadius:"2%",border:"1px solid rgb(221 216 216)"}}>
             {
                dailyCashCardData?.cashOut?.entries?.length !==0 &&
                <div className="daily-report-cash-in-or-out">
                       <p>CASH OUT</p>
                          <table>
                          <thead>
                             <tr>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Cash</th>
                                <th>Collected</th>
                             </tr>
                          </thead>
                          <tbody>
                          {
                              dailyCashCardData?.cashOut?.entries?.map((r,i)=>(
                                <tr>
                                 <td>{r?.date}</td>
                                 <td>{r?.time}</td>
                                 <td>{r?.cash}</td>
                                 <td>{r?.collectedBy}</td>
                               </tr>
                             
                              ))
                            }
                           
                          </tbody>
                       </table>
              </div>
            }
            {
               dailyCashCardData?.cashIn?.entries?.length !==0 &&
               <div className="daily-report-cash-in-or-out">
               <p>CASH IN</p>
                  <table>
                  <thead>
                     <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Cash</th>
                        <th>Collected</th>
                     </tr>
                  </thead>
                  <tbody>
                    {
                      dailyCashCardData?.cashIn?.entries?.map((r,i)=>(
                        <tr>
                         <td>{r?.date}</td>
                         <td>{r?.time}</td>
                         <td>{r?.cash}</td>
                         <td>{r?.collectedBy}</td>
                       </tr>
                     
                      ))
                    }
                   
                  </tbody>
               </table>
               </div>
            }
              
            
          </div>
         }

          </div>

          <div
            className="daily-cash-session-total-container"
            style={{ margin: "1% 6%" }}
          >
            <p>Session Total</p>
            <p style={{ marginLeft: "4%" }}>
              {dailyCashCardData?.sessionTotal?.amount?.toFixed(2)}
            </p>
            <p>{dailyCashCardData?.sessionTotal?.commission?.toFixed(2)}</p>
            <p>{dailyCashCardData?.sessionTotal?.total?.toFixed(2)}</p>
          </div>
          {/* category table */}
         
            {/* <div className="daily-cash-card-category-container">
             {isReadymadeChecked && <> {dailyCashCardData?.readymade?.list?.length !== 0 ? (
                <div className="daily-cash-card-table-container">
                  <p>Readymade</p>
                  <div className="daily-cash-card-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Unit</th>
                          <th>Qty</th>
                          <th>Rate</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dailyCashCardData?.readymade?.list?.length !== 0 ? (
                          dailyCashCardData?.readymade?.list?.map((r, i) => (
                            <tr>
                              <td>{r.productName}</td>
                              <td>{r?.unit}</td>
                              <td>{r?.qty}</td>
                              <td>{r?.rate}</td>
                              <td>{r?.amount?.toFixed(2)}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8}>No Data</td>
                          </tr>
                        )}

                      
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : undefined}</>}
             {isMaterialChecked && <>{dailyCashCardData?.material?.list?.length !== 0 ? (
                <div className="daily-cash-card-table-container">
                  <p>Material</p>
                  <div className="daily-cash-card-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Unit</th>
                          <th>Qty</th>
                          <th>Rate</th>
                          <th>Amount</th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        {dailyCashCardData?.material?.list?.length !== 0 ? (
                          dailyCashCardData?.material?.list?.map((r, i) => (
                            <tr>
                              <td>{r?.productName}</td>
                              <td>{r?.unit}</td>
                              <td>{r?.qty}</td>
                              <td>{r?.rate}</td>
                              <td>{r?.amount?.toFixed(2)}</td>
                             
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8}>No Data</td>
                          </tr>
                        )}

                    
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : undefined}</>}
             {isAccessoryChecked && <> {dailyCashCardData?.accessories?.list?.length !== 0 ? (
                <div className="daily-cash-card-table-container">
                  <p>Accessories</p>
                  <div className="daily-cash-card-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Unit</th>
                          <th>Qty</th>
                          <th>Rate</th>
                          <th>Amount</th>
                      
                        </tr>
                      </thead>
                      <tbody>
                        {dailyCashCardData?.accessories?.list?.length !== 0 ? (
                          dailyCashCardData?.accessories?.list?.map((r, i) => (
                            <tr>
                              <td>{r.productName}</td>
                              <td>{r?.unit}</td>
                              <td>{r?.qty}</td>
                              <td>{r?.rate}</td>
                              <td>{r?.amount?.toFixed(2)}</td>
                            
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8}>No Data</td>
                          </tr>
                        )}

                        
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : undefined}</>}
            </div>  */}
              <div className="daily-cash-card-category-container">
              {
                dailyCashCardData?.product?.map((r,i)=>(
                  <div className="daily-cash-card-table-container">
                  <p>{r?.catName}</p>
                  <div className="daily-cash-card-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Unit</th>
                          <th>Qty</th>
                          <th>Rate</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          r?.list?.map((k,i)=>(
                            <tr>
                            <td>{k?.productName}</td>
                            <td>{k?.unit}</td>
                            <td>{k?.qty}</td>
                            <td>{k?.rate}</td>
                            <td>{k?.amount}</td>
                          </tr>
                       
                          ))
                        }
                        
                         
                        

                       
                      </tbody>
                    </table>
                  </div>
                </div>
                ))
              }
            
            </div>
           
        </div>
      </div>

      {/* date filter print */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (dailyCashCardDateFilterPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "30%" }}
            >
              <h6 style={{margin:"0 0 0 3%"}}>Printed On {convertDateFormat(today)}&nbsp;{time}</h6>
              <p>Location&nbsp;:&nbsp;{dailyCashCardData?.location}</p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "35%" }}
            >
              <h3>{loginResponse?.storeName}</h3>
              <p>DAILY CASH CARD SUMMARY</p>
              <h5 style={{margin:"0",textAlign:"center"}}>{`(${convertDateFormat(fromDate)} - ${convertDateFormat(toDate)})`}</h5>
            </div>
            <div
              className="print-view-top-right-container"
              style={{ width: "35%" }}
            >
              <p></p>
              <p></p>
            </div>
          </div>
          {/* table */}
          {filteredResponse?.map((r) => (
            <div>
              <div
                className="daily-report-date-filter-middle-container"
                style={{ margin: "0 4%", backgroundColor: "#fff" }}
              >
                <h4>Shift No : {r?.shiftId}</h4>
                <div>
                  <p>Shift Starting Time : {`${convertDateFormat(r?.shiftopeningTime)} ${r?.shiftopeningTime?.split(" ")[1]}`} </p>
                  <p>Shift Closing Time : {r?.shiftclosingTime !== "Active Shift"?`${convertDateFormat(r?.shiftclosingTime)} ${r?.shiftclosingTime?.split(" ")[1]}`:r?.shiftclosingTime}</p>
                </div>
              </div>
              {/* table */}
              {details === false ? (
                <>
                  <div
                    className="daily-cash-card-summary-table"
                    style={{
                      maxHeight: "none",
                      overflowY: "hidden",
                      margin: "1% 6%",
                    }}
                  >
                    <table>
                    <thead style={{height:"15px"}}>
                    <tr 
                      style={{border:"1px solid #000",borderLeft:"0",borderRight:"0"}}
                      >
                          <th>Type</th>
                          <th>Order No</th>
                          <th>Amount</th>
                          <th>Commission</th>
                          <th>Balance</th>
                          <th>Wallet</th>
                          <th>VAT</th>
                        </tr>
                      </thead>
                      <tbody style={{ backgroundColor: "#fff" }}>
                        {r?.paidSummary?.orderList?.map((k, i) => (
                          <tr>
                            <td>{k?.type.toUpperCase()}</td>
                            <td>&nbsp;</td>
                            <td>{k?.paidAmount?.toFixed(2)}</td>
                            <td>{k?.commissionTotal?.toFixed(2)}</td>
                            <td>{k?.balanceTotal?.toFixed(2)}</td>
                            <td>{k?.walletTotal}</td>
                            <td>{k?.vatTotal}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {r?.paidSummary?.returnList?.length !== 0 && (
                    <div
                      className="daily-cash-card-expense-table-container"
                      style={{ margin: "1% 6%" }}
                    >
                      <h4>Return</h4>
                      <div
                        className="daily-cash-card-summary-table"
                        style={{
                          maxHeight: "none",
                          overflowY: "hidden",
                        }}
                      >
                        <table>
                          <tbody style={{ backgroundColor: "#fff" }}>
                            {r?.paidSummary?.returnList?.map((m) => (
                              <tr>
                                <td style={{ width: "10%" }}>
                                  {m?.type.toUpperCase()}
                                </td>
                                <td style={{ width: "18%" }}>&nbsp;</td>
                                <td style={{ width: "17%" }}>
                                  {m?.paidAmount?.toFixed(2)}
                                </td>
                                <td style={{ width: "25%" }}>
                                  {m?.commissionTotal?.toFixed(2)}
                                </td>
                                <td style={{ width: "16%" }}>
                                  {m?.balanceTotal?.toFixed(2)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  {/* checklist  */}
                  {
                          r?.paidSummary?.chequeList?.list !== 0 && (
                          <div className="daily-cash-card-expense-table-container">
                             <div style={{display:"flex",justifyContent:"space-between",padding:"1% 6%"}}>
                             <h4>Cheque</h4>
                             <h4 style={{margin:"2% 2% 0 0"}}>Cheque Total : {r?.paidSummary?.chequeList?.total}</h4>
                             </div>
                            
                            <div 
                            className="daily-cash-card-summary-table"
                            style={{
                              maxHeight: "none",
                              overflowY: "hidden",
                              margin: "1% 6%",
                            }}
                            >
                              <table>
                                <thead>
                                  <tr>
                                    <th>No</th>
                                    <th>Order No</th>
                                    <th>Bank Name</th>
                                    <th>Date</th>
                                    <th>cheque Date</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody style={{backgroundColor:"#fff"}}>
                                 {
                                  r?.paidSummary?.chequeList?.list?.map((k,i)=>(
                                    <tr>
                                      <td>{i+1}</td>
                                      <td>{k?.orderId}</td>
                                      <td>{k?.bankName}</td>
                                      <td>{k?.date?.split(" ")[0]}</td>
                                      <td>{k?.chequedate?.split(" ")[0]}</td>
                                      <td>{k?.paidAmount}</td>
                                    </tr>
                                  ))
                                 }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                </>
              ) : (
                <>
                  <div
                    className="daily-cash-card-summary-table daily-cash-expand-table"
                    style={{
                      maxHeight: "none",
                      margin: "1% 6%",
                      overflowY: "hidden",
                    }}
                  >
                    <table>
                    <thead style={{height:"15px"}}>
                    <tr 
                    style={{border:"1px solid #000",borderLeft:"0",borderRight:"0"}}
                    >
                          <th>Type</th>
                          <th>Order No</th>
                          <th>Amount</th>
                          <th>Commission</th>
                          <th>Balance</th>
                          <th>Wallet</th>
                          <th>VAT</th>
                        </tr>
                      </thead>
                      <tbody style={{ backgroundColor: "#fff" }}>
                        {r?.paidSummary?.orderList?.map((k) => (
                          <tr>
                            <td>
                              <h4>{k?.type.toUpperCase()}</h4>
                              {k?.orderInfo?.map((m) => (
                                <tr className="report-details-table">
                                  <td>&nbsp;</td>
                                </tr>
                              ))}
                            </td>
                            <td>
                              <h4>&nbsp;</h4>
                              {k?.orderInfo?.map((m) => (
                                <tr className="report-details-table">
                                  <td>{m?.orderId}</td>
                                </tr>
                              ))}
                            </td>
                            <td>
                              <h4>{k?.paidAmount?.toFixed(2)}</h4>
                              {k?.orderInfo?.map((m) => (
                                <tr className="report-details-table">
                                  <td>{m?.paidAmount?.toFixed(2)}</td>
                                </tr>
                              ))}
                            </td>
                            <td>
                              <h4>{k?.commissionTotal?.toFixed(2)}</h4>
                              {k?.orderInfo?.map((m) => (
                                <tr className="report-details-table">
                                  <td>{m?.commission?.toFixed(2)}</td>
                                </tr>
                              ))}
                            </td>
                            <td>
                              <h4>{k?.balanceTotal?.toFixed(2)}</h4>
                              {k?.orderInfo?.map((m) => (
                                <tr className="report-details-table">
                                  <td>{m?.balance?.toFixed(2)}</td>
                                </tr>
                              ))}
                            </td>
                            <td>
                                      <h4>{k?.walletTotal?.toFixed(2)}</h4>
                                      {k?.orderInfo?.map((m) => (
                                        <tr className="report-details-table">
                                          <td>{m?.wallet?.toFixed(2)}</td>
                                        </tr>
                                      ))}
                                    </td>
                                    <td>
                                      <h4>{k?.vatTotal?.toFixed(2)}</h4>
                                      {k?.orderInfo?.map((m) => (
                                        <tr className="report-details-table">
                                          <td>{m?.vatTotal?.toFixed(2)}</td>
                                        </tr>
                                      ))}
                                    </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* return list */}
                  
                  {r?.paidSummary?.returnList?.length !== 0 && (
                    <div
                      className="daily-cash-card-expense-table-container"
                      style={{ margin: "1% 6%" }}
                    >
                      <h4>Return</h4>
                      <div
                        className="daily-cash-card-summary-table"
                        style={{
                          maxHeight: "none",
                          overflowY: "hidden",
                        }}
                      >
                        <table>
                          <tbody style={{ backgroundColor: "#fff" }}>
                            {r?.paidSummary?.returnList?.map((m) => (
                              <tr>
                                <td>
                                  <h4>{m?.type.toUpperCase()}</h4>
                                  <br />
                                  {m?.orderInfo?.map((k) => (
                                    <tr className="report-details-table">
                                      <td>&nbsp;</td>
                                    </tr>
                                  ))}
                                </td>
                                <td>
                                  <h4>&nbsp;</h4>
                                  <br />
                                  {m?.orderInfo?.map((k) => (
                                    <tr className="report-details-table">
                                      <td>{k?.orderId}</td>
                                    </tr>
                                  ))}
                                </td>
                                <td>
                                  <h4>{m?.paidAmount}</h4>
                                  <br />
                                  {m?.orderInfo?.map((k) => (
                                    <tr className="report-details-table">
                                      <td>{k?.paidAmount?.toFixed(2)}</td>
                                    </tr>
                                  ))}
                                </td>
                                <td>
                                  <h4>{m?.commissionTotal?.toFixed(2)}</h4>
                                  <br />
                                  {m?.orderInfo?.map((k) => (
                                    <tr className="report-details-table">
                                      <td>{k?.commission?.toFixed(2)}</td>
                                    </tr>
                                  ))}
                                </td>
                                <td>
                                  <h4>{m?.balanceTotal?.toFixed(2)}</h4>
                                  <br />
                                  {m?.orderInfo?.map((k) => (
                                    <tr className="report-details-table">
                                      <td>{k?.balance?.toFixed(2)}</td>
                                    </tr>
                                  ))}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  {/* check list */}
                  {
                          r?.paidSummary?.chequeList?.list !== 0 && (
                          <div className="daily-cash-card-expense-table-container">
                             <div style={{display:"flex",justifyContent:"space-between",padding:"1% 6%"}}>
                             <h4>Cheque</h4>
                             <h4 style={{margin:"2% 2% 0 0"}}>Cheque Total : {r?.paidSummary?.chequeList?.total}</h4>
                             </div>
                            
                            <div 
                            className="daily-cash-card-summary-table"
                            style={{
                              maxHeight: "none",
                              margin: "1% 6%",
                              overflowY: "hidden",
                            }}
                            >
                              <table>
                                <thead>
                                  <tr>
                                    <th>No</th>
                                    <th>Order No</th>
                                    <th>Bank Name</th>
                                    <th>Date</th>
                                    <th>cheque Date</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody style={{backgroundColor:"#fff"}}>
                                 {
                                  r?.paidSummary?.chequeList?.list?.map((k,i)=>(
                                    <tr>
                                      <td>{i+1}</td>
                                      <td>{k?.orderId}</td>
                                      <td>{k?.bankName}</td>
                                      <td>{k?.date?.split(" ")[0]}</td>
                                      <td>{k?.chequedate?.split(" ")[0]}</td>
                                      <td>{k?.paidAmount}</td>
                                    </tr>
                                  ))
                                 }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                </>
              )}
            </div>
          ))}
          {/* map end */}{" "}
          {expense === true && (
            <div
              className="daily-cash-card-expense-table-container"
              style={{ margin: "1% 6%" }}
            >
              <h4>Expense</h4>
              <div
                className="daily-cash-card-summary-table"
                style={{ maxHeight: "none", overflowY: "hidden" }}
              >
                <table>
                  <tbody style={{ backgroundColor: "#fff" }}>
                    {dailyCashCardData?.expensetable?.length !== 0 ? (
                      dailyCashCardData?.expensetable?.map((r) => (
                        <tr className="table-cell-border-none">
                          <td style={{ width: "20%" }}>{r?.expenseName}</td>
                          <td>&nbsp;</td>
                          <td>{r?.amount?.toFixed(2)}</td>
                          <td style={{ width: "20%" }}>
                            {r?.commission?.toFixed(2)}
                          </td>
                          <td style={{ width: "20%" }}>
                            {r?.balance?.toFixed(2)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td style={{ backgroundColor: "#fff" }} colSpan={5}>
                          No Records
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {/* {category === true && (
            <div
              // className="daily-cash-card-category-container"
              style={{ margin: "1% 4%" }}
            >
              <h4>Caterory</h4>
              <div>
                <h4 style={{ margin: "0 6%" }}>Readymade</h4>
                <div
                  className="global-table-container"
                  style={{
                    maxHeight: "none",
                    overflowY: "hidden",
                  }}
                >
                  <table className="global-table">
                    <thead>
                      <tr>
                        <th style={{ width: "30%" }}>P.Name</th>
                        <th>Unit</th>
                        <th>Qty</th>
                        <th>Rate</th>
                        <th>Amt</th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "#fff" }}>
                      {dailyCashCardData?.readymade?.list?.length !== 0 ? (
                        dailyCashCardData?.readymade?.list?.map((r, i) => (
                          <tr>
                            <td
                              style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid rgb(221 216 216",
                              }}
                            >
                              {r?.productName}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid rgb(221 216 216",
                              }}
                            >
                              {r?.unit}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid rgb(221 216 216",
                              }}
                            >
                              {r?.qty}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid rgb(221 216 216",
                              }}
                            >
                              {r?.rate}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid rgb(221 216 216",
                              }}
                            >
                              {r?.amount.toFixed(2)}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td style={{ backgroundColor: "#fff" }} colSpan={7}>
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <h4 style={{ margin: "0 6%" }}>Material</h4>
                <div
                  className="global-table-container"
                  style={{
                    maxHeight: "none",
                    overflowY: "hidden",
                  }}
                >
                  <table className="global-table">
                    <thead>
                      <tr>
                        <th style={{ width: "30%" }}>P.Name</th>
                        <th>Unit</th>
                        <th>Qty</th>
                        <th>Rate</th>
                        <th>Amt</th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "#fff" }}>
                      {dailyCashCardData?.material?.list?.length !== 0 ? (
                        dailyCashCardData?.material?.list?.map((r, i) => (
                          <tr>
                            <td
                              style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid rgb(221 216 216",
                              }}
                            >
                              {r?.productName}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid rgb(221 216 216",
                              }}
                            >
                              {r?.unit}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid rgb(221 216 216",
                              }}
                            >
                              {r?.qty}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid rgb(221 216 216",
                              }}
                            >
                              {r?.rate}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid rgb(221 216 216",
                              }}
                            >
                              {r?.amount.toFixed(2)}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td style={{ backgroundColor: "#fff" }} colSpan={7}>
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <h4 style={{ margin: "0 6%" }}>Accessories</h4>
                <div
                  className="global-table-container"
                  style={{
                    maxHeight: "none",
                    overflowY: "hidden",
                  }}
                >
                  <table className="global-table">
                    <thead>
                      <tr>
                        <th style={{ width: "30%" }}>P.Name</th>
                        <th>Unit</th>
                        <th>Qty</th>
                        <th>Rate</th>
                        <th>Amt</th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "#fff" }}>
                      {dailyCashCardData?.accessories?.list?.length !== 0 ? (
                        dailyCashCardData?.accessories?.list?.map((r, i) => (
                          <tr>
                            <td
                              style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid rgb(221 216 216",
                              }}
                            >
                              {r?.productName}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid rgb(221 216 216",
                              }}
                            >
                              {r?.unit}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid rgb(221 216 216",
                              }}
                            >
                              {r?.qty}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid rgb(221 216 216",
                              }}
                            >
                              {r?.rate}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid rgb(221 216 216",
                              }}
                            >
                              {r?.amount.toFixed(2)}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td style={{ backgroundColor: "#fff" }} colSpan={7}>
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )} */}
          
         <div style={{display:"flex"}}>
          <div
            className="daily-cash-card-summary-bottom-container"
            style={{ margin: "1% 6%" }}
          >
             <div className="daily-cash-card-summary-bottom-single-container">
                <p className="second-text">Amount After Return</p>
                <p className="second-text">
                  {dailyCashCardData?.amountafterreturn?.toFixed(2)}
                </p>
              </div>
            <div className="daily-cash-card-summary-bottom-single-container">
              <p className="first-text">Cash in Hand After Expense</p>
              <p className="first-text">
                {dailyCashCardData?.cashInHandAfterExpense?.toFixed(2)}
              </p>
            </div>
            <div className="daily-cash-card-summary-bottom-single-container">
              <p className="second-text">Total Sale Without Expenses</p>
              <p className="second-text">
                {dailyCashCardData?.totalSaleWoExpense?.toFixed(2)}
              </p>
            </div>
          </div>
          {
             (dailyCashCardData?.cashOut?.entries?.length !==0 ||  dailyCashCardData?.cashIn?.entries?.length !==0) &&

          <div style={{width:"50%",margin:"1%",padding:"1%",borderRadius:"2%",border:"1px solid rgb(221 216 216)"}}>
              {
                 dailyCashCardData?.cashOut?.entries?.length !==0 &&
                 <div className="daily-report-cash-in-or-out">
                 <p>CASH OUT</p>
                    <table>
                    <thead>
                       <tr>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Cash</th>
                          <th>Collected</th>
                       </tr>
                    </thead>
                    <tbody>
                    {
                        dailyCashCardData?.cashOut?.entries?.map((r,i)=>(
                          <tr>
                           <td>{r?.date}</td>
                           <td>{r?.time}</td>
                           <td>{r?.cash}</td>
                           <td>{r?.collectedBy}</td>
                         </tr>
                       
                        ))
                      }
                     
                    </tbody>
                 </table>
                 </div>
              }
              {
                dailyCashCardData?.cashIn?.entries?.length !==0 &&
                <div className="daily-report-cash-in-or-out">
                <p>CASH IN</p>
                   <table>
                   <thead>
                      <tr>
                         <th>Date</th>
                         <th>Time</th>
                         <th>Cash</th>
                         <th>Collected</th>
                      </tr>
                   </thead>
                   <tbody>
                     {
                       dailyCashCardData?.cashIn?.entries?.map((r,i)=>(
                         <tr>
                          <td>{r?.date}</td>
                          <td>{r?.time}</td>
                          <td>{r?.cash}</td>
                          <td>{r?.collectedBy}</td>
                        </tr>
                      
                       ))
                     }
                    
                   </tbody>
                </table>
                </div>
          }
             
             
          </div>
}
          </div>
         
          <div
            className="daily-cash-session-total-container"
            style={{ margin: "1% 6%" }}
          >
            <p>Session Total</p>
            <p style={{ marginLeft: "4%" }}>
              {dailyCashCardData?.sessionTotal?.amount?.toFixed(2)}
            </p>
            <p>{dailyCashCardData?.sessionTotal?.commission?.toFixed(2)}</p>
            <p>{dailyCashCardData?.sessionTotal?.total?.toFixed(2)}</p>
          </div>

          {/* category table */}
         
          {/* <div className="daily-cash-card-category-container">
             {isReadymadeChecked &&  <>{dailyCashCardData?.readymade?.list?.length !== 0 ? (
                <div className="daily-cash-card-table-container">
                  <p>Readymade</p>
                  <div className="daily-cash-card-table">
                    <table>
                    <thead style={{height:"15px"}}>
                    <tr 
                    style={{border:"1px solid #000",borderLeft:"0",borderRight:"0"}}
                    >
                          <th>Product Name</th>
                          <th>Unit</th>
                          <th>Qty</th>
                          <th>Rate</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dailyCashCardData?.readymade?.list?.length !== 0 ? (
                          dailyCashCardData?.readymade?.list?.map((r, i) => (
                            <tr>
                              <td>{r.productName}</td>
                              <td>{r?.unit}</td>
                              <td>{r?.qty}</td>
                              <td>{r?.rate}</td>
                              <td>{r?.amount?.toFixed(2)}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8}>No Data</td>
                          </tr>
                        )}

                       
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : undefined}</>}
             {isMaterialChecked&& <>{dailyCashCardData?.material?.list?.length !== 0 ? (
                <div className="daily-cash-card-table-container">
                  <p>Material</p>
                  <div className="daily-cash-card-table">
                    <table>
                    <thead style={{height:"15px"}}>
                    <tr 
                    style={{border:"1px solid #000",borderLeft:"0",borderRight:"0"}}
                    >
                          <th>Product Name</th>
                          <th>Unit</th>
                          <th>Qty</th>
                          <th>Rate</th>
                          <th>Amount</th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        {dailyCashCardData?.material?.list?.length !== 0 ? (
                          dailyCashCardData?.material?.list?.map((r, i) => (
                            <tr>
                              <td>{r?.productName}</td>
                              <td>{r?.unit}</td>
                              <td>{r?.qty}</td>
                              <td>{r?.rate}</td>
                              <td>{r?.amount?.toFixed(2)}</td>
                             
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8}>No Data</td>
                          </tr>
                        )}

                     
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : undefined}</>}
              {isAccessoryChecked && <>{dailyCashCardData?.accessories?.list?.length !== 0 ? (
                <div className="daily-cash-card-table-container">
                  <p>Accessories</p>
                  <div className="daily-cash-card-table">
                    <table>
                    <thead style={{height:"15px"}}>
                    <tr 
                      style={{border:"1px solid #000",borderLeft:"0",borderRight:"0"}}
                      >
                          <th>Product Name</th>
                          <th>Unit</th>
                          <th>Qty</th>
                          <th>Rate</th>
                          <th>Amount</th>
                       
                        </tr>
                      </thead>
                      <tbody>
                        {dailyCashCardData?.accessories?.list?.length !== 0 ? (
                          dailyCashCardData?.accessories?.list?.map((r, i) => (
                            <tr>
                              <td>{r.productName}</td>
                              <td>{r?.unit}</td>
                              <td>{r?.qty}</td>
                              <td>{r?.rate}</td>
                              <td>{r?.amount?.toFixed(2)}</td>
                             
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8}>No Data</td>
                          </tr>
                        )}

                       
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : undefined}</>}
            </div> */}
             <div className="daily-cash-card-category-container">
              {
                dailyCashCardData?.product?.map((r,i)=>(
                  <div className="daily-cash-card-table-container">
                  <p>{r?.catName}</p>
                  <div className="daily-cash-card-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Unit</th>
                          <th>Qty</th>
                          <th>Rate</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          r?.list?.map((k,i)=>(
                            <tr>
                            <td>{k?.productName}</td>
                            <td>{k?.unit}</td>
                            <td>{k?.qty}</td>
                            <td>{k?.rate}</td>
                            <td>{k?.amount}</td>
                          </tr>
                       
                          ))
                        }
                        
                         
                        

                       
                      </tbody>
                    </table>
                  </div>
                </div>
                ))
              }
            
            </div>


        </div>
      </div>
    </div>
  );
};
