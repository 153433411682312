import React, { useEffect, useRef, useState } from 'react'
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import { CustomerReceiptReportAPIcall } from './GeneralReportAPI';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import Template from "./CustomerReceiptPrint"
import ReactToPrint from 'react-to-print';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../../Redux/store';

function CustomerReceiptReport() {
    const filtereDataOptions = useSelector((state) => state.listCategory);
    let navigate = useNavigate();
    let paymentsPrintNew = useRef();

    const tableListData = useSelector((state) => state?.generalReport?.customerReceiptReport)


    const totalPaidAmtKeys = tableListData !== undefined ? Object.keys(tableListData?.total?.totalPaidAmount) : [];


    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue)

        const [selectedBranch, setSelectedBranch] = useState(null);

    const [FormDataInfo, setFormDataInfo] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [symbol, setSymbol] = useState(null);

    const decimalPosition = localStorage.getItem("decimalPosition");

    const handleDataFromChild = (data) => {
        setFormDataInfo(data);
    };
    const handleBranchSelect = (selectedBranch) => {
        setSelectedBranch(selectedBranch); 
      
      };
    const handleRowClick = (row) => {
        store.dispatch( setFilterActive(window.location.pathname))
        localStorage.setItem("customerReceiptSingleViewId", row?._id);
        navigate("/userdashboard/sales/Report/CustomerReceiptReportSingleView", { state: { customerName: row?.customerName } })
        // navigate("/userdashboard/accounts/report/generalReport/InvoiceMarginReport/SingleView")
    };

    useEffect(() => {
        let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
        setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])
    useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
    }, [])

    // useEffect(() => {
    //     CustomerReceiptReportAPIcall()
    // }, [])

    useEffect(() => {
        if (FormDataInfo?.length !== 0) {
            let body = {
                fromDate: FormDataInfo?.fromDate === '' ? '' : `${FormDataInfo?.fromDate} 00:00:00`,
                toDate: FormDataInfo?.toDate === '' ? '' : `${FormDataInfo?.toDate} 23:59:59`,
                branchId: FormDataInfo?.branchList,
                search: FormDataInfo?.search,
            }
            let loadData=window.location.pathname===filtereDataOptions?.filterActive
            !loadData &&  CustomerReceiptReportAPIcall(body);
        }
    }, [FormDataInfo])
    return (
        <div className="in-or-out-container">
            <div className="in-or-out-report-wrapper">

                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}>
       

                    <CategorySearchandFilter
                        onData={handleDataFromChild}
                        onBranchSelect={handleBranchSelect}
                        // isCustomer={true}
                        // isJournal={true}
                    />
                     <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrintNew}
        />

        <Tooltip title="Download">
          <IconButton>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>
                </div>

                <div className="invoice-table-container">
                    <div className="invoice-main-div">
                        <h1>Customer Receipt Report</h1>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{textAlignLast:"center"}}>Customer</th>
                                    <th style={{textAlignLast:"center"}}>INV count</th>
                                    <th style={{textAlignLast:"center"}}>INV Amount</th>
                                    <th style={{textAlignLast:"center"}}>Paid Amount</th>
                                    {
                                        totalPaidAmtKeys?.map(r =>
                                            <th style={{textAlignLast:"center"}}>{r}</th>
                                        )
                                    }
                                    <th style={{textAlignLast:"center"}}>Credit</th>
                                </tr>
                            </thead>
                            <tbody>

                                {isLoading ? (
                                    [...Array(10)].map((r, i) => (
                                        <tr key={i}>
                                            <td colSpan={8}>
                                                <Skeleton
                                                    variant="rectangular"
                                                    animation="wave"
                                                    width={"100%"}
                                                    height={40}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                ) : tableListData?.report !== undefined && tableListData?.report?.length !== 0 ? (
                                    tableListData?.report
                                        ?.slice(0)
                                        ?.map((r, i) => (
                                            <tr key={i} onClick={() => handleRowClick(r)}>
                                                <td  style={{textAlignLast:"start"}}>{r?.customerName}</td>
                                                <td style={{textAlignLast:"end"}}>{r?.invoiceCount}</td>
                                                <td style={{textAlignLast:"end"}}>{symbol}&nbsp;{r?.invAmount?.toFixed(decimalPosition)}</td>
                                                <td style={{textAlignLast:"end"}}>{symbol}&nbsp;{r?.total?.toFixed(decimalPosition)}</td>
                                                {totalPaidAmtKeys?.map((key) => (

                                                    <td style={{textAlignLast:"end"}} key={key}>{symbol}&nbsp;{r?.totalPaidAmount[key]?.toFixed(decimalPosition) || '0.00'}</td>
                                                ))}


                                                <td style={{textAlignLast:"end"}}>{symbol}&nbsp;{r?.amountDue?.toFixed(decimalPosition)}</td>
                                            </tr>
                                        ))
                                ) : (
                                    <tr>
                                        <td style={{ textAlign: 'center' }} colSpan={8}>No Data</td>
                                    </tr>
                                )}

                            </tbody>
                            {tableListData?.total !== undefined &&
                                <tr>
                                    <th >Total</th>
                                    <th style={{textAlignLast:"end"}}>{tableListData?.total?.invoiceCount}</th>
                                    <th style={{textAlignLast:"end"}}>{symbol}&nbsp;{tableListData?.total?.invAmount?.toFixed(decimalPosition)}</th>
                                    <th style={{textAlignLast:"end"}}>{symbol}&nbsp;{tableListData?.total?.total?.toFixed(decimalPosition)}</th>
                                    {totalPaidAmtKeys.map((key) => (

                                        <th style={{textAlignLast:"end",paddingRight:"10px"}} key={key}>{tableListData?.total?.totalPaidAmount[key]?.toFixed(decimalPosition) || 0}</th>
                                    ))}
                                    <th style={{textAlignLast:"end",paddingRight:"10px"}}>{symbol}&nbsp;{tableListData?.total?.amountDue?.toFixed(decimalPosition)}</th>
                                </tr>
                            }
                            {/* <tr>
                                <th >Total</th>
                                <th >{tableListData?.total !== undefined && tableListData?.total?.invoiceCount}</th>
                                <th >{symbol}&nbsp;{tableListData?.total !== undefined && tableListData?.total?.invAmount?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{tableListData?.total !== undefined && tableListData?.total?.total?.toFixed(decimalPosition)}</th>
                                {totalPaidAmtKeys.map((key) => (

                                    <th key={key}>{tableListData?.total?.totalPaidAmount[key] || 0}</th>
                                ))}
                                <th >{symbol}&nbsp;{tableListData?.total !== undefined && tableListData?.total?.amountDue?.toFixed(decimalPosition)}</th>
                            </tr> */}
                        </table>
                    </div>
                </div>

            </div>
            {/* <LoadingForm loading={isLoading} /> */}
            <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
           
            <Template />
        
        </div>
      </div>
       
       </div>

    )
}

export default CustomerReceiptReport