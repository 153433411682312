import React, { useState, useRef, useEffect } from "react";
import "../../../../css/Report/dailyReport.css";
import {
  Select,
  IconButton,
  MenuItem,
  Autocomplete,
  TextField,
  Skeleton,
  Checkbox,
} from "@mui/material";
import ReactToPrint from "react-to-print";
import {
  viewAllBranchesAPICall,
  viewCompanyProfileInfoAPICall,
} from "../../../../API/Settings/Company Settings/companySettingsAPI";
import {
  today,
  currentTime,
  previousDate,
  convertDateFormat,
} from "../../../../Js/Date";
import { useSelector } from "react-redux";
import { dailyReportAPICall } from "../../../../API/Report/dailyReportAPI";
import { viewShiftAPICall } from "../../../../API/Settings/Shift/viewShiftAPI";
import { NoBackpackSharp } from "@mui/icons-material";
import { categoryListAPICall } from "../../Inventory/Configuration/Category/CategoryAPI";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const DailyReport = () => {
  let dailyReportPrint = useRef();
  let dailyReportFilterPrint = useRef;
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // all shift list
  const allShiftList = useSelector((state) => state.viewShiftSlice.value);

  // daily report data
  const dailyReportData = useSelector((state) => state.dailyReportSlice.value);
  //Company Profile Data
  const companyProfileData = useSelector(
    (state) => state.companyProfileSlice.value
  );
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const branchId = localStorage.getItem("branchId");
  const CategoryList = useSelector((state) => state.categorySlice.value);
  const userShiftStatus = localStorage.getItem("shiftStatus");
  const userShiftId = JSON.parse(userShiftStatus)?.shiftId;
  const userShiftpk = JSON.parse(userShiftStatus)?._id;
  const loginMeta=localStorage.getItem("login_meta")
  const branchPk=JSON.parse(loginMeta)?.branchPk;
  
  const [details, setDetails] = useState(false);
  const [expense, setExpense] = useState(false);
  const [shift, setShift] = useState();
  const [location, setLocation] = useState();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [isSubmitBtnClick,setIsSubmitBtnClick]=useState(false)
  const [shiftFilteredData,setShiftFilteredData]=useState([])
  const [time, setTime] = useState(null);
  const [isFilterClick,setIsFilterClick]=useState(false)

  // get current time fn
  useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }, 1000);
  }, [time]);


  const detailsChange = (e) => {
    const { checked } = e.target;
    setDetails(checked);
    // dailyReportAPICall(bodyToApi)
  };

  const expenseCheck = (e) => {
    const { checked } = e.target;
    setExpense(checked);
  };

  useEffect(() => {
    viewAllBranchesAPICall();
    viewShiftAPICall({branchId});
    viewCompanyProfileInfoAPICall();
    categoryListAPICall();
  }, []);

  // onChange functions
  const getShift = (e) => {
    setShift(e.target.value);
  };
  const getLocation = (e) => {
    setLocation(e.target.value);
  };
  const getFromDate = (e) => {
    setFromDate(e.target.value);
  };
  const getToDate = (e) => {
    setToDate(e.target.value);
  };

  const [categories,setCategories]=useState([])
  const checkCategories=(e)=>{
    const categoryId = e.target.value;

    // Check if the category is already selected
    if (categories.includes(categoryId)) {
      // If it is selected, remove it from the array
      setCategories((prevSelectedCategories) =>
        prevSelectedCategories.filter((id) => id !== categoryId)
      );
    } else {
      // If it is not selected, add it to the array
      setCategories((prevSelectedCategories) => [
        ...prevSelectedCategories,
        categoryId,
      ]);
    }
  }
  useEffect(() => {
    const defaultCategoryNames = ["material", "readymade","accessories",'Material','Accessory','Readymade'];
    const defaultCategoryIds = CategoryList
      ?.filter(category => defaultCategoryNames.includes(category?.categoryName))
      .map(category => category?._id)
      .filter(Boolean);
  
    if (defaultCategoryIds && defaultCategoryIds.length > 0) {
      setCategories(prevSelectedCategories => {
        // Create a new Set to ensure uniqueness
        const updatedCategoriesSet = new Set([...prevSelectedCategories, ...defaultCategoryIds]);
  
        // Convert the Set back to an array
        return Array.from(updatedCategoriesSet);
      });
    }
  }, [CategoryList]);


  const bodyToApi = {
    branchId: location !== "null" ? location : null,
    shiftNo: shift !== undefined ? shift : null,
    fromDate: fromDate !== "" ? `${fromDate} 00:00:00` : null,
    endDate: toDate !== "" ? `${toDate} 23:59:59` : null,
    isDetails: details !== false ? details : null,
    isExpense: expense !== false ? expense : null,
    categories :categories
  };

  const dailyReportSubmitBtnFn = () => {
    dailyReportAPICall(bodyToApi, setIsLoading);
    setIsSubmitBtnClick(true);
  };

  useEffect(() => {
    if (dailyReportData !== undefined) {
      dailyReportAPICall(bodyToApi, setIsLoading);
    }
  }, [details, shift, expense, toDate, fromDate]);

  useEffect(() => {
    // setLocation(branchId)
    if(allShiftList!==undefined)
    { 

    let filteredArray = allShiftList?.filter((obj) => {
      return obj.branchCode === branchId;
    });
    setShiftFilteredData(filteredArray);

    

  }
  }, [branchId, allShiftList]);

  // shift filtering
  useEffect(() => {
    if(userRole==='user' && allShiftList!==undefined)
    {
    let filteredArray = allShiftList?.filter((obj) => {
      return obj.branchId === location;
    });
    setShiftFilteredData(filteredArray);
  }
  if(userRole==='admin')
  {
    viewShiftAPICall({branchId:location});
  }
  }, [location]);

  useEffect(() => {
    setLocation(branchPk);
  }, []);

  // const inputRef = useRef(null);
  // const handleClickOutside = (event) => {
  //   if (inputRef.current && !inputRef.current.contains(event.target)) {
  //     // Clicked outside the input, set input state to false
  //     setIsFilterClick(false);
  //   }
  // };
  // useEffect(() => {
  //   document.addEventListener('click', handleClickOutside);

  //   // Cleanup the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, []);

 
 
  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">Report &gt; Daily Report</p>
      <div className="daily-report-top-container">
        <h3>Daily Report</h3>
        <hr className="global-hr" />
        <div className="daily-report-top-input-container">
          {userRole === "admin" && (
            <div className="global-single-input auto-complete">
              <p>Location</p>

              <Autocomplete
                sx={{ width: "100%" }}
                options={allBranchesList || [""]}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Branch" />
                )}
                onChange={(e, newValue) => setLocation(newValue?._id)}
              />
            </div>
          )}
          <div className="global-single-input auto-complete">
            <p>Shift</p>

            {/* <Autocomplete
              sx={{ width: "100%" }}
              options={allShiftList || [""]}
              getOptionLabel={(option) => option?.SHIFID}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Shift" />
              )}
              onChange={(e, newValue) => setShift(newValue?.shiftId)}
              value={shift}
              disabled={fromDate !== "" || toDate !== ""}
            /> */}
            <Autocomplete
              sx={{ width: "100%" }}
              options={userRole==='user'?shiftFilteredData?.slice(0)?.reverse() :allShiftList?.slice(0)?.reverse() || [{"SHIFID":'No Data'}]}
              getOptionLabel={(option) => option?.SHIFID}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Shift" />
              )}
              onChange={(e, newValue) => setShift(newValue?._id)}
              value={shift}
              disabled={fromDate !== "" || toDate !== ""}
            />
          </div>

          <div className="global-single-input">
            <p>From</p>
            <input
              type="date"
              defaultValue={fromDate}
              onChange={getFromDate}
              disabled={shift !== undefined}
            />
          </div>
          <div className="global-single-input">
            <p>To</p>
            <input
              type="date"
              defaultValue={toDate}
              onChange={getToDate}
              disabled={shift !== undefined}
            />
          </div>
          <button
            onClick={dailyReportSubmitBtnFn}
            className={
              (location === undefined ||
                location === null ||
                shift === undefined) &&
              (location === undefined ||
                location === null ||
                fromDate === "" ||
                toDate === "")
                ? "btn btn-primary-disabled report-submit-btn"
                : "btn btn-primary report-submit-btn"
            }
            disabled={
              (location === undefined ||
                location === null ||
                shift === undefined) &&
              (location === undefined ||
                location === null ||
                fromDate === "" ||
                toDate === "")
            }
          >
            Submit
          </button>
        </div>
        <div className="report-checkbox-container">
          <div className="report-single-checkbox-container">
            <input
              type="checkbox"
              id="reportDetails"
              value={details}
              onChange={detailsChange}
            />
            <label htmlFor="reportDetails">Details</label>
          </div>
          <div className="report-single-checkbox-container">
            <input
              type="checkbox"
              id="reportExpense"
              value={expense}
              onChange={expenseCheck}
            />
            <label htmlFor="reportExpense">Expense</label>
          </div>
          <div className="daily-repirt-filter-container" onClick={()=>setIsFilterClick(!isFilterClick)}>
              <i class="bi bi-funnel filter-icon"></i>
              {
                isFilterClick ?
                  <i class="bi bi-caret-up-fill aarow-down-filled"></i>
                  :
                  <i class="bi bi-caret-down-fill aarow-down-filled"></i>
              }
            
          </div>
               {/* <div className="global-single-input add-alteration-input auto-complete">

                  <Autocomplete
                    multiple
                    sx={{ width: "100%" }}
                    options={CategoryList||[""]}
                    getOptionLabel={(option) => option?.categoryName}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.categoryName}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Categories" />
                    )}
                    onChange={checkCategories}
                  />
                </div> */}
          <div>

          </div>
        
        </div>
           {
            isFilterClick && 
            <div className="daily-repirt-filter-content"> 
            {
              CategoryList?.map((r)=>(
                <div className="daily-repirt-filter-content-checkbox">
                <input 
                  type="checkbox"  
                  value={r?._id} 
                  onClick={checkCategories}
                  checked={categories.includes(r?._id)}
                
                  />
                <label htmlFor="">{r?.categoryName}</label>
                </div>
              ))
            } 
             
            
            </div>
           }   
      </div>

      <div className="daily-report-bottom-container">
        {shift !== undefined && isSubmitBtnClick === true ? (
          <>
            <div className="daily-report-bottom-date-location-container">
              <div className="daily-report-bottom-date-location-left-container">
                <div className="daily-report-bottom-date-location-left-single-container">
                  <p>Shift No</p>
                  <h4>:&nbsp; {dailyReportData?.shiftNo}</h4>
                </div>
                <div className="daily-report-bottom-date-location-left-single-container">
                  <p>Shift Open Time </p>
                  <h4>
                    :&nbsp;{" "}
                    {`${convertDateFormat(dailyReportData?.shiftopeningTime)} ${
                      dailyReportData?.shiftopeningTime?.split(" ")[1]
                    }`}
                  </h4>
                </div>
                <div className="daily-report-bottom-date-location-left-single-container">
                  <p>Shift Closing Time</p>
                  <h4>
                    :&nbsp;{" "}
                    {dailyReportData?.shiftClosingTime !== "Active Shift" && dailyReportData?.shiftClosingTime !==undefined
                      ? `${convertDateFormat(dailyReportData?.shiftClosingTime)} ${
                        dailyReportData?.shiftClosingTime.split(" ")[1]}`
                      : dailyReportData?.shiftClosingTime}
                  </h4>
                </div>
              </div>
              <div className="daily-report-bottom-date-location-right-container">
                <div className="daily-report-bottom-date-location-left-single-container">
                  <p>Location</p>
                  <h4>:&nbsp; {dailyReportData?.location}</h4>
                </div>
                <div className="report-print-download-icon-container">
                  <ReactToPrint
                    trigger={() => (
                      <IconButton className="print-icon-container">
                        <i class="bi bi-printer printer-icon"></i>
                      </IconButton>
                    )}
                    content={() => dailyReportPrint}
                    pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
                  />
                  <IconButton className="download-icon-container">
                    <i class="bi bi-arrow-down-circle download-icon"></i>
                  </IconButton>
                </div>
              </div>
            </div>

            {/* daily report table */}
            {details === false ? (
              <div className="global-table-container">
                <table className="global-table">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Bill No</th>
                      <th>Order No</th>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Discount</th>
                      <th>Paid Amount</th>
                      <th>Total Stitch Rate</th>
                      <th>Type</th>
                      <th>Commission</th>
                      <th>Status</th>
                      <th>Wallet</th>
                      <th>VAT / GST</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <tbody>
                      <tr>
                        <td colSpan={13}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={30}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={13}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={30}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {dailyReportData?.list?.length !== 0 ? (
                        dailyReportData?.list?.map((r, i) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{r?.billNo}</td>
                            <td>{r?.orderNo}</td>
                            <td>{convertDateFormat(r?.date)}</td>
                            <td>{r?.totalAmount?.toFixed(2)}</td>
                            <td>{r?.discount?.toFixed(2)}</td>
                            <td>{r?.paidAmount?.toFixed(2)}</td>
                            <td>{r?.totalStitchRate}</td>
                            <td>...</td>
                            <td>...</td>
                            <td>{r?.status}</td>
                            <td>{r?.usedWallet}</td>
                            <td>{r?.vatorgst}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={13}>No Data</td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            ) : details === true ? (
              <div className="global-table-container">
                <table className="global-table report-details-table-main">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Bill No</th>
                      <th>Order No</th>
                      <th>Product</th>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Discount</th>
                      <th>Paid Amount</th>
                      <th>Total Stitch Rate</th>
                      <th>Type</th>
                      <th>Commission</th>
                      <th>Status</th>
                      <th>Wallet</th>
                      <th>VAT / GST</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <tbody>
                      <tr>
                        <td colSpan={13}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={30}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={13}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={30}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={13}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={30}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={13}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={30}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={13}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={30}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={13}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={30}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {dailyReportData?.list?.length !== 0 ? (
                        dailyReportData?.list?.map((r, i) => (
                          <tr>
                            <td>
                              {i + 1}
                              {r?.paymenttypes?.map((n) => (
                                <tr className="report-detail-table-main-cell">
                                  <td></td>
                                </tr>
                              ))}

                              <hr className="global-hr" />
                              {r?.items?.map((k) => (
                                <tr className="report-details-table">
                                  <td></td>
                                </tr>
                              ))}
                            </td>

                            <td>
                              {r?.billNo}
                              {r?.paymenttypes?.map((n) => (
                                <tr className="report-detail-table-main-cell">
                                  <td></td>
                                </tr>
                              ))}
                              <hr className="global-hr" />
                              {r?.items?.map((k) => (
                                <tr className="report-details-table">
                                  <td></td>
                                </tr>
                              ))}
                            </td>
                            <td>
                              {r?.orderNo}
                              {r?.paymenttypes?.map((n) => (
                                <tr className="report-detail-table-main-cell">
                                  <td></td>
                                </tr>
                              ))}
                              <hr className="global-hr" />
                              {r?.items?.map((k) => (
                                <tr className="report-details-table">
                                  <td></td>
                                </tr>
                              ))}
                            </td>
                            <td>
                              &nbsp;
                              {r?.paymenttypes?.map((n) => (
                                <tr className="report-detail-table-main-cell">
                                  <td></td>
                                </tr>
                              ))}
                              <hr className="global-hr" />
                              {r?.items?.map((k) => (
                                <tr className="report-details-table">
                                  <td>{k?.itemName}</td>
                                </tr>
                              ))}
                            </td>

                            <td>
                              {convertDateFormat(r?.date)}
                              {r?.paymenttypes?.map((n) => (
                                <tr className="report-detail-table-main-cell">
                                  <td></td>
                                </tr>
                              ))}
                              <hr className="global-hr" />
                              {r?.items?.map((k) => (
                                <tr className="report-details-table">
                                  <td></td>
                                </tr>
                              ))}
                            </td>
                            <td>
                              {r?.totalAmount?.toFixed(2)}
                              {r?.paymenttypes?.map((n) => (
                                <tr className="report-detail-table-main-cell">
                                  <td></td>
                                </tr>
                              ))}
                              <hr className="global-hr" />
                              {r?.items?.map((k) => (
                                <tr className="report-details-table">
                                  <td>{k?.amount}</td>
                                </tr>
                              ))}
                            </td>
                            <td>
                              {r?.discount?.toFixed(2)}
                              {r?.paymenttypes?.map((n) => (
                                <tr className="report-detail-table-main-cell">
                                  <td></td>
                                </tr>
                              ))}
                              <hr className="global-hr" />
                              {r?.items?.map((k) => (
                                <tr className="report-details-table">
                                  <td></td>
                                </tr>
                              ))}
                            </td>

                            <td>
                              {r?.paidAmount?.toFixed(2)}

                              {r?.paymenttypes?.map((n) => (
                                <tr className="report-detail-table-main-cell">
                                  <td>{n?.amount?.toFixed(2)}</td>
                                </tr>
                              ))}
                              <hr className="global-hr" />
                              {r?.items?.map((k) => (
                                <tr className="report-details-table">
                                  <td></td>
                                </tr>
                              ))}
                            </td>
                            <td>
                              {r?.totalStitchRate?r?.totalStitchRate:0}
                              {r?.paymenttypes?.map((n) => (
                                <tr className="report-detail-table-main-cell">
                                  <td></td>
                                </tr>
                              ))}
                              <hr className="global-hr" />
                              {r?.items?.map((k) => (
                                <tr className="report-details-table">
                                  <td>{k?.stitchRate?k?.stitchRate:0}</td>
                                </tr>
                              ))}
                            </td>

                            <td>
                              ---
                              {r?.paymenttypes?.map((n) => (
                                <tr className="report-detail-table-main-cell">
                                  <td>{n?.vendor}</td>
                                </tr>
                              ))}
                              <hr className="global-hr" />
                              {r?.items?.map((k) => (
                                <tr className="report-details-table">
                                  <td></td>
                                </tr>
                              ))}
                            </td>
                            <td>
                              ---
                              {r?.paymenttypes?.map((n) => (
                                <tr className="report-detail-table-main-cell">
                                  <td>{n?.commission?.toFixed(2)}</td>
                                </tr>
                              ))}
                              <hr className="global-hr" />
                              {r?.items?.map((k) => (
                                <tr className="report-details-table">
                                  <td></td>
                                </tr>
                              ))}
                            </td>

                            <td>
                              {r?.status}
                              {r?.paymenttypes?.map((n) => (
                                <tr className="report-detail-table-main-cell">
                                  <td></td>
                                </tr>
                              ))}
                              <hr className="global-hr" />
                              {r?.items?.map((k) => (
                                <tr className="report-details-table">
                                  <td></td>
                                </tr>
                              ))}
                            </td>

                            <td>
                              {r?.usedWallet}
                              {r?.paymenttypes?.map((n) => (
                                <tr className="report-detail-table-main-cell">
                                  <td></td>
                                </tr>
                              ))}
                              <hr className="global-hr" />
                              {r?.items?.map((k) => (
                                <tr className="report-details-table">
                                  <td></td>
                                </tr>
                              ))}
                            </td>

                            <td>
                              {r?.vatorgst}
                              {r?.paymenttypes?.map((n) => (
                                <tr className="report-detail-table-main-cell">
                                  <td></td>
                                </tr>
                              ))}
                              <hr className="global-hr" />
                              {r?.items?.map((k) => (
                                <tr className="report-details-table">
                                  <td>{k?.vatorgst}</td>
                                </tr>
                              ))}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={13}>No Data</td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            ) : undefined}
          </>
        ) : //  filter table
        fromDate !== "" && toDate !== "" && isSubmitBtnClick === true ? (
          // <div></div>
          <div className="daily-report-date-filter-container">
            <div className="daily-report-date-filter-top-container">
              <p>Location : {dailyReportData?.location}</p>
              <div className="report-print-download-icon-container date-filter-icon">
                <ReactToPrint
                  trigger={() => (
                    <IconButton className="print-icon-container">
                      <i class="bi bi-printer printer-icon"></i>
                    </IconButton>
                  )}
                  content={() => dailyReportFilterPrint}
                  pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
                />

                <IconButton className="download-icon-container">
                  <i class="bi bi-arrow-down-circle download-icon"></i>
                </IconButton>
              </div>
            </div>
            {dailyReportData?.list?.length !== 0 ? (
              dailyReportData?.list?.map((r, i) => (
                <div>
                  <div className="daily-report-date-filter-middle-container">
                    <h4>Shift No : {r?.shiftId}</h4>
                    <div>
                      <p>Shift Starting Time : {`${convertDateFormat(r?.shiftopeningTime)} ${r?.shiftopeningTime?.split(' ')[1]}`} </p>
                      <p>Shift Closing Time : {r?.shiftClosingTime !== 'Active Shift'? `${convertDateFormat(r?.shiftClosingTime)} ${r?.shiftClosingTime?.split(' ')[1]}`:r?.shiftClosingTime}</p>
                    </div>
                  </div>

                  {details === false ? (
                    <div className="global-table-container">
                      <table className="global-table">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Bill No</th>
                            <th>Order No</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Discount</th>
                            <th>Paid Amount</th>
                            <th>Total Stitching Rate</th>
                            <th>Type</th>
                            <th>Commission</th>
                            <th>Status</th>
                            <th>Wallet</th>
                            <th>VAT / GST</th>
                          </tr>
                        </thead>
                        {isLoading ? (
                          <tbody>
                            <tr>
                              <td colSpan={13}>
                                <Skeleton
                                  variant="rectangular"
                                  animation="wave"
                                  width={"100%"}
                                  height={30}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={13}>
                                <Skeleton
                                  variant="rectangular"
                                  animation="wave"
                                  width={"100%"}
                                  height={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {r?.list?.map((k, i) => (
                              <tr>
                                <td>{i + 1}</td>
                                <td>{k?.billNo}</td>
                                <td>{k?.orderNo}</td>
                                <td>{convertDateFormat(k?.date)}</td>
                                <td>{k?.totalAmount.toFixed(2)}</td>
                                <td>{k?.discount?.toFixed(2)}</td>
                                <td>{k?.paidAmount.toFixed(2)}</td>
                                <td>{k?.totalStitchRate}</td>
                                <td>...</td>
                                <td>...</td>
                                <td>{k?.status}</td>
                                <td>{k?.usedWallet}</td>
                                <td>{k?.vatorgst}</td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  ) : details === true ? (
                    <div className="global-table-container">
                      <table className="global-table report-details-table-main">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Bill No</th>
                            <th>Order No</th>
                            <th>Product</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Discount</th>
                            <th>Paid Amount</th>
                            <th>Total Stitching Rate</th>
                            <th>Type</th>
                            <th>Commission</th>
                            <th>Status</th>
                            <th>Wallet</th>
                            <th>VAT / GST</th>
                          </tr>
                        </thead>
                        {isLoading ? (
                          <tbody>
                            <tr>
                              <td colSpan={13}>
                                <Skeleton
                                  variant="rectangular"
                                  animation="wave"
                                  width={"100%"}
                                  height={30}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={13}>
                                <Skeleton
                                  variant="rectangular"
                                  animation="wave"
                                  width={"100%"}
                                  height={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {r?.list?.map((k, i) => (
                              <tr>
                                <td>
                                  {i + 1}
                                  {k?.paymenttypes?.map((n) => (
                                    <tr className="report-detail-table-main-cell">
                                      <td></td>
                                    </tr>
                                  ))}
                                  <hr className="global-hr" />
                                  {k?.items?.map((m) => (
                                    <tr className="report-details-table">
                                      <td></td>
                                    </tr>
                                  ))}
                                </td>

                                <td>
                                  {k?.billNo}
                                  {k?.paymenttypes?.map((n) => (
                                    <tr className="report-detail-table-main-cell">
                                      <td></td>
                                    </tr>
                                  ))}
                                  <hr className="global-hr" />
                                  {k?.items?.map((m) => (
                                    <tr className="report-details-table">
                                      <td></td>
                                    </tr>
                                  ))}
                                </td>
                                <td>
                                  {k?.orderNo}
                                  {k?.paymenttypes?.map((n) => (
                                    <tr className="report-detail-table-main-cell">
                                      <td></td>
                                    </tr>
                                  ))}
                                  <hr className="global-hr" />
                                  {k?.items?.map((m) => (
                                    <tr className="report-details-table">
                                      <td></td>
                                    </tr>
                                  ))}
                                </td>
                                <td>
                                  &nbsp;
                                  {k?.paymenttypes?.map((n) => (
                                    <tr className="report-detail-table-main-cell">
                                      <td></td>
                                    </tr>
                                  ))}
                                  <hr className="global-hr" />
                                  {k?.items?.map((m) => (
                                    <tr className="report-details-table">
                                      <td>{m?.itemName}</td>
                                    </tr>
                                  ))}
                                </td>

                                <td>
                                  {convertDateFormat(k?.date)}
                                  {k?.paymenttypes?.map((n) => (
                                    <tr className="report-detail-table-main-cell">
                                      <td></td>
                                    </tr>
                                  ))}
                                  <hr className="global-hr" />
                                  {k?.items?.map((m) => (
                                    <tr className="report-details-table">
                                      <td></td>
                                    </tr>
                                  ))}
                                </td>
                                <td>
                                  {k?.totalAmount.toFixed(2)}
                                  {k?.paymenttypes?.map((n) => (
                                    <tr className="report-detail-table-main-cell">
                                      <td></td>
                                    </tr>
                                  ))}
                                  <hr className="global-hr" />
                                  {k?.items?.map((m) => (
                                    <tr className="report-details-table">
                                      <td>{m?.amount.toFixed(2)}</td>
                                    </tr>
                                  ))}
                                </td>
                                <td>
                                  {k?.discount?.toFixed(2)}
                                  {k?.paymenttypes?.map((n) => (
                                    <tr className="report-detail-table-main-cell">
                                      <td></td>
                                    </tr>
                                  ))}
                                  <hr className="global-hr" />
                                  {k?.items?.map((m) => (
                                    <tr className="report-details-table">
                                      <td></td>
                                    </tr>
                                  ))}
                                </td>

                                <td>
                                  {k?.paidAmount.toFixed(2)}

                                  {k?.paymenttypes?.map((n) => (
                                    <tr className="report-detail-table-main-cell">
                                      <td>{n?.amount!==null && n?.amount.toFixed(2)}</td>
                                    </tr>
                                  ))}
                                  <hr className="global-hr" />
                                  {k?.items?.map((m) => (
                                    <tr className="report-details-table">
                                      <td></td>
                                    </tr>
                                  ))}
                                </td>
                                <td>
                                  {k?.totalStitchRate?k?.totalStitchRate:0}
                                  {k?.paymenttypes?.map((n) => (
                                    <tr className="report-detail-table-main-cell">
                                      <td></td>
                                    </tr>
                                  ))}
                                  <hr className="global-hr" />
                                  {k?.items?.map((m) => (
                                    <tr className="report-details-table">
                                      <td>{m?.stitchRate?m?.stitchRate:0}</td>
                                    </tr>
                                  ))}
                                </td>

                                <td>
                                  ---
                                  {k?.paymenttypes?.map((n) => (
                                    <tr className="report-detail-table-main-cell">
                                      <td>{n?.vendor}</td>
                                    </tr>
                                  ))}
                                  <hr className="global-hr" />
                                  {k?.items?.map((m) => (
                                    <tr className="report-details-table">
                                      <td></td>
                                    </tr>
                                  ))}
                                </td>
                                <td>
                                  ---
                                  {k?.paymenttypes?.map((n) => (
                                    <tr className="report-detail-table-main-cell">
                                      <td>{n?.commission.toFixed(2)}</td>
                                    </tr>
                                  ))}
                                  <hr className="global-hr" />
                                  {k?.items?.map((m) => (
                                    <tr className="report-details-table">
                                      <td></td>
                                    </tr>
                                  ))}
                                </td>

                                <td>
                                  {k?.status}
                                  {k?.paymenttypes?.map((n) => (
                                    <tr className="report-detail-table-main-cell">
                                      <td></td>
                                    </tr>
                                  ))}
                                  <hr className="global-hr" />
                                  {k?.items?.map((m) => (
                                    <tr className="report-details-table">
                                      <td></td>
                                    </tr>
                                  ))}
                                </td>
                                <td>
                                  {k?.usedWallet}
                                  {k?.paymenttypes?.map((n) => (
                                    <tr className="report-detail-table-main-cell">
                                      <td></td>
                                    </tr>
                                  ))}
                                  <hr className="global-hr" />
                                  {k?.items?.map((m) => (
                                    <tr className="report-details-table">
                                      <td></td>
                                    </tr>
                                  ))}
                                </td>

                                <td>
                                  {k?.vatorgst}
                                  {k?.paymenttypes?.map((n) => (
                                    <tr className="report-detail-table-main-cell">
                                      <td></td>
                                    </tr>
                                  ))}
                                  <hr className="global-hr" />
                                  {k?.items?.map((m) => (
                                    <tr className="report-details-table">
                                      <td>{m?.vatorgst}</td>
                                    </tr>
                                  ))}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  ) : undefined}
                </div>
              ))
            ) : (
              <h3 className="no-data">No Data</h3>
            )}
          </div>
        ) : undefined}

        {/* expense table */}

        {expense === true && (
          <div className="daily-report-expense-table-container">
            <h4>Expense Table</h4>
            <div className="daily-report-expense-table">
              <table>
                <thead>
                  <tr>
                    <th>Doc No</th>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Note</th>
                    <th style={{ width: "20%" }}>&nbsp;</th>
                    <th>Credit</th>
                    <th>Debit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td></td>
                    <td>Opening Balance</td>
                    <td>&nbsp;</td>
                    <td>{dailyReportData?.expensetable?.openingBalance}</td>
                    <td>--</td>
                  </tr>
                  {dailyReportData?.expensetable?.expenseList?.map((j, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{convertDateFormat(j?.date)}</td>
                      <td>{j?.expenseName}</td>
                      <td>{j?.narration}</td>
                      <td>&nbsp;</td>
                      <td>{j?.credit}</td>
                      <td>{j?.debit}</td>
                    </tr>
                  ))}

                  <tr className="table-cell-border-none">
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>Total</td>
                    <td>{dailyReportData?.expensetable?.crTot}</td>
                    <td>{dailyReportData?.expensetable?.debTot}</td>
                  </tr>

                  <tr className="table-cell-border-none">
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>Balance</td>
                    <td>&nbsp;</td>
                    <td>{dailyReportData?.expensetable?.balance}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {(dailyReportData !== undefined && shift !== undefined) ||
        (dailyReportData !== undefined && fromDate !== "" && toDate !== "") ? (
          <>
            <div className="daily-report-amount-container">
              <div className="daily-report-amount-left-container">
                {/* total order amount */}
                <div className="daily-report-amount-left-first-container">
                  <div className="daily-report-amount-left-first-single-container">
                    <p>Total Order Amount</p>
                    <span>:</span>
                    <h4>
                      {dailyReportData?.order?.totalOrderAmount?.toFixed(2)}
                    </h4>
                  </div>
                  <div className="daily-report-amount-left-first-single-container">
                    <p>Total Paid Amount </p>:
                    <h4>{dailyReportData?.order?.totalBalance?.toFixed(2)}</h4>
                  </div>
                  <div className="daily-report-amount-left-first-single-container">
                    <p>Credit</p>:
                    <h4>{dailyReportData?.order?.totalCredit?.toFixed(2)}</h4>
                  </div>
                  <div className="daily-report-amount-left-first-single-container">
                    <p style={{fontSize:"0.9rem",fontWeight:600}}>Total Return</p>:
                    <h4>{dailyReportData?.order?.totalOrderReturn?.toFixed(2)}</h4>
                  </div>
                  <div className="daily-report-amount-left-first-single-container">
                    <p>Total VAT</p>:
                    <h4>{dailyReportData?.order?.totalVat?.toFixed(2)}</h4>
                  </div>
                  <div className="daily-report-amount-left-first-single-container">
                    <p>Total Wallet</p>:
                    <h4>{dailyReportData?.order?.totalWallet!==null ?(dailyReportData?.order?.totalWallet?.toFixed(2)):0}</h4>
                  </div>
                </div>
                {/* product */}
                <div className="daily-report-amount-left-first-container">
                  <h4 style={{margin:"1% 0"}}>Products</h4>
                  {
                    dailyReportData?.product?.map((r,i)=>(
                      <div className="daily-report-amount-left-first-single-container">
                      <p>{r?.name}</p>
                      <span>:</span>
                      <h4>
                        {r?.sale}
                      </h4>
                    </div>
                    ))
                    
                  }
                
                  {/* <div className="daily-report-amount-left-first-single-container">
                    <p>Total Material Sale</p>
                    <span>:</span>
                    <h4>
                      {dailyReportData?.product?.totalmaterialsale?.toFixed(2)}
                    </h4>
                  </div>
                  <div className="daily-report-amount-left-first-single-container">
                    <p>Total Accessories Sale</p>
                    <span>:</span>
                    <h4>
                      {dailyReportData?.product?.totalaccessorysale?.toFixed(2)}
                    </h4>
                  </div>
                  <div className="daily-report-amount-left-first-single-container">
                    <p>Total Outcloth Sale</p>
                    <span>:</span>
                    <h4>
                      {dailyReportData?.product?.totalOutClothSale?.toFixed(2)}
                    </h4>
                  </div>
                  <div className="daily-report-amount-left-first-single-container">
                    <p>Total Agal Sale</p>
                    <span>:</span>
                    <h4>
                      ----
                    </h4>
                  </div>
                  <div className="daily-report-amount-left-first-single-container">
                    <p>Total Stitch Sale</p>
                    <span>:</span>
                    <h4>
                    {dailyReportData?.product?.totalStitchRate?.toFixed(2)}
                    </h4>
                  </div> */}
                  {/* <div className="daily-report-amount-left-first-single-container">
                    <h3>Total Sale</h3>
                    <span>:</span>
                    <h4 style={{ fontSize: "1.3rem" }}>
                      {dailyReportData?.product?.totalCashExpense?.toFixed(2)}
                    </h4>
                  </div> */}
                </div>
                {/* expense */}
                <div className="daily-report-amount-left-first-container">
                  <h4 style={{margin:"1% 0"}}>Expense</h4>
                  <div className="daily-report-amount-left-first-single-container">
                    <p>Petty Voucher Expense</p>
                    <span>:</span>
                    <h4>
                      {dailyReportData?.expenses?.pettyvoucherexpense?.toFixed(
                        2
                      )}
                    </h4>
                  </div>
                  <div className="daily-report-amount-left-first-single-container">
                    <p>Cash Expense</p>
                    <span>:</span>
                    <h4>
                      {dailyReportData?.expenses?.cashexpense?.toFixed(2)}
                    </h4>
                  </div>
                  <div className="daily-report-amount-left-first-single-container">
                    <p style={{ fontSize: "1rem" }}>Total</p>
                    <span>:</span>
                    <h4 style={{ fontSize: "1rem" }}>
                      {dailyReportData?.expenses?.total?.toFixed(2)}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="daily-report-amount-right-container">
                <div className="daily-report-amount-right-first-container">
                  <div className="daily-report-amount-right-first-single-container">
                    <p style={{ fontSize: "1rem" }}>Total Amount</p>
                    <span>:</span>
                    <h4 style={{ fontSize: "1rem" }}>
                      {dailyReportData?.totalAmount?.toFixed(2)}
                    </h4>
                  </div>
                  <div className="daily-report-amount-right-first-single-container">
                    <p>Cash</p>
                    <span>:</span>
                    <h4>{dailyReportData?.payment?.totalCash?.toFixed(2)}</h4>
                  </div>
                  <div className="daily-report-amount-right-first-single-container">
                    <p>Cheque</p>
                    <span>:</span>
                    <h4>{dailyReportData?.payment?.totalCheque?.toFixed(2)}</h4>
                  </div>
                  <div className="daily-report-amount-right-first-single-container">
                    <p>UPI</p>
                    <span>:</span>
                    <h4>{dailyReportData?.payment?.totalUpi?.toFixed(2)}</h4>
                  </div>
                  <div className="daily-report-amount-right-first-single-container">
                    <p>Credit Card</p>
                    <span>:</span>
                    <h4>{dailyReportData?.payment?.totalCard?.toFixed(2)}</h4>
                  </div>
                  <div className="daily-report-amount-right-first-single-container">
                    <p>Return Amount</p>
                    <span>:</span>
                    <h4>{dailyReportData?.payment?.totalReturn?.toFixed(2)}</h4>
                  </div>
                </div>
                <div
                  className="daily-report-amount-right-first-container"
                  style={{ backgroundColor: "#fff" }}
                >
                  <div className="daily-report-amount-right-first-single-container">
                    <p style={{ fontSize: "1.1rem" }}>
                      Total Sale After Expense
                    </p>
                    <span>:</span>
                    <h4 style={{ fontSize: "1.1rem" }}>
                      {dailyReportData?.totalSalesAfterExpense?.toFixed(2)}
                    </h4>
                  </div>
                  <div className="daily-report-amount-right-first-single-container">
                    <p>Petty Voucher Expense</p>
                    <span>:</span>
                    <h4>
                      {dailyReportData?.expenses?.pettyvoucherexpense?.toFixed(
                        2
                      )}
                    </h4>
                  </div>
                  <div className="daily-report-amount-right-first-single-container">
                    <p
                      style={{
                        fontStyle: "italic",
                        fontSize: "1rem",
                        fontWeight: "500",
                      }}
                    >
                      Total Credit Card Commission
                    </p>
                    <span>:</span>
                    <h4>
                      {dailyReportData?.commission?.totalcardCommission?.toFixed(
                        2
                      )}
                    </h4>
                  </div>
                  <div className="daily-report-amount-right-first-single-container">
                    <p
                      style={{
                        fontStyle: "italic",
                        fontSize: "1rem",
                        fontWeight: "500",
                      }}
                    >
                      Total Credit Card After Commission
                    </p>
                    <span>:</span>
                    <h4>
                      {dailyReportData?.commission?.totalCardAfterCommission?.toFixed(
                        2
                      )}
                    </h4>
                  </div>
                  <div className="daily-report-amount-right-first-single-container">
                    <p
                      style={{
                        fontStyle: "italic",
                        fontSize: "1rem",
                        fontWeight: "500",
                      }}
                    >
                      Total Upi Commission
                    </p>
                    <span>:</span>
                    <h4>
                      {dailyReportData?.commission?.totalUpiCommission?.toFixed(
                        2
                      )}
                    </h4>
                  </div>
                  <div className="daily-report-amount-right-first-single-container">
                    <p
                      style={{
                        fontStyle: "italic",
                        fontSize: "1rem",
                        fontWeight: "500",
                      }}
                    >
                      Total Upi After Commission
                    </p>
                    <span>:</span>
                    <h4>
                      {dailyReportData?.commission?.totalUpiAfterCommission?.toFixed(
                        2
                      )}
                    </h4>
                  </div>
                </div>
                {/* cash in or cash out */}
                {
                  (dailyReportData?.cashOut?.entries?.length !==0 || dailyReportData?.cashIn?.entries?.length !==0) && 
                  <div className="daily-report-amount-left-first-container">
                  {
                    dailyReportData?.cashOut?.entries?.length !==0 && 
                    <div className="daily-report-cash-in-or-out">
                    <p>CASH OUT</p>
                       <table>
                       <thead>
                          <tr>
                             <th>Date</th>
                             <th>Time</th>
                             <th>Cash</th>
                             <th>Collected</th>
                          </tr>
                       </thead>
                       <tbody>
                         {
                           dailyReportData?.cashOut?.entries?.map((r)=>(
                             <tr>
                               <td>{r?.date}</td>
                               <td>{r?.time}</td>
                               <td>{r?.cash}</td>
                              <td>{r?.collectedBy}</td>
                           </tr>
                           ))
                         }
                       
                      
                       </tbody>
                    </table>
                    </div>
                  }
                  
                   {
                    dailyReportData?.cashIn?.entries?.length !==0 &&
                    <div className="daily-report-cash-in-or-out">
                    <p>CASH IN</p>
                       <table>
                       <thead>
                          <tr>
                             <th>Date</th>
                             <th>Time</th>
                             <th>Cash</th>
                             <th>Collected</th>
                          </tr>
                       </thead>
                       <tbody>
                       {
                           dailyReportData?.cashIn?.entries?.map((r)=>(
                             <tr>
                               <td>{r?.date}</td>
                               <td>{r?.time}</td>
                               <td>{r?.cash}</td>
                              <td>{r?.collectedBy}</td>
                           </tr>
                           ))
                         }
                       
                        
                       </tbody>
                    </table>
                    </div>
                   }
                 
                </div>
                 } 
               
              </div>
            </div>

            <div className="daily-report-grand-total-container">
              <h3 style={{ width: "30%" }}>Grand Total On Hand</h3>
              <span>:</span>
              <h3>{dailyReportData?.grandTotalOnHand?.toFixed(2)}</h3>
            </div>
          </>
        ) : (
          <h3 className="no-data">No Records</h3>
        )}
      </div>

      {/* daily report print view */}
      <div 
      style={{ display: "none" }}
      >
        <div ref={(el) => (dailyReportPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "33%" }}
            >
              <h6 style={{margin:"0 0 0 3%"}}>Printed On {convertDateFormat(today)}&nbsp;{time}</h6>
              <p>Shift No&nbsp;:&nbsp;{dailyReportData?.shiftNo}</p>
              <p>Location&nbsp;:&nbsp;{dailyReportData?.location}</p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "34%" }}
            >
              <h3>{loginResponse?.storeName}</h3>
              <p>DAILY REPORT</p>
              <h5 style={{margin:"0",textAlign:"center"}}>Report On {dailyReportData?.shiftClosingTime==="Active Shift"?convertDateFormat(today):`${convertDateFormat(dailyReportData?.shiftClosingTime)} ${dailyReportData?.shiftClosingTime?.split(" ")[1]}`}</h5>
            </div>
            <div
              className="print-view-top-right-container"
              style={{ width: "33%" }}
            >
              <p style={{ fontSize: "0.8rem" }}>
                Shift Open Time&nbsp;:&nbsp; {`${convertDateFormat(dailyReportData?.shiftopeningTime)} ${
                      dailyReportData?.shiftopeningTime?.split(" ")[1]
                    }`}
              </p>
              <p style={{ fontSize: "0.8rem" }}>
                Shift Closing Time&nbsp;:&nbsp;
                {dailyReportData?.shiftClosingTime !== "Active Shift"
                      ? `${convertDateFormat(
                          dailyReportData?.shiftClosingTime
                        )} ${dailyReportData?.shiftClosingTime?.split(" ")[1]}`
                      : dailyReportData?.shiftClosingTime}
              </p>
            </div>
          </div>

          {details === false ? (
            <div className="print-view-table-container ">
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Bill No</th>
                    <th>Order No</th>
                    <th>Date</th>
                    <th>Amt</th>
                    <th>Dis</th>
                    <th>Paid Amt</th>
                    <th>Type</th>
                    <th>Comm</th>
                    <th>Status</th>
                    <th>Wallet</th>
                    <th>VAT / GST</th>
                  </tr>
                </thead>
                <tbody>
                  {dailyReportData?.list?.map((r, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{r?.billNo}</td>
                      <td>{r?.orderNo}</td>
                      <td>{convertDateFormat(r?.date)}</td>
                      <td>{r?.totalAmount?.toFixed(2)}</td>
                      <td>{r?.discount?.toFixed(2)}</td>
                      <td>{r?.paidAmount?.toFixed(2)}</td>
                      <td>...</td>
                      <td>...</td>
                      <td>{r?.status}</td>
                      <td>{r?.usedWallet}</td>
                      <td>{r?.vatorgst}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : details === true ? (
            <div className="print-view-table-container ">
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Bill No</th>
                    <th>Order No</th>
                    <th>Product</th>
                    <th>Date</th>
                    <th>Amt</th>
                    <th>Dis</th>
                    <th>Paid Amt</th>
                    <th>Type</th>
                    <th>Comm</th>
                    <th>Status</th>
                    <th>Wallet</th>
                    <th>VAT / GST</th>
                  </tr>
                </thead>
                <tbody>
                  {dailyReportData?.list?.map((r, i) => (
                    <tr>
                      <td>
                        {i + 1}
                        {r?.paymenttypes?.map((n) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                        <hr
                          className="global-hr"
                          style={{ borderBottom: "1px solid #000" }}
                        />
                        {r?.items?.map((k) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                      </td>
                      {/* <hr
                          className="global-hr"
                          style={{ borderBottom: "1px solid #000" }}
                        /> */}
                      <td>
                        {r?.billNo}
                        {r?.paymenttypes?.map((n) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                        <hr
                          className="global-hr"
                          style={{ borderBottom: "1px solid #000" }}
                        />
                        {r?.items?.map((k) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                      </td>
                      <td>
                        {r?.orderNo}
                        {r?.paymenttypes?.map((n) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                        <hr
                          className="global-hr"
                          style={{ borderBottom: "1px solid #000" }}
                        />
                        {r?.items?.map((k) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                      </td>
                      <td>
                        &nbsp;
                        {r?.paymenttypes?.map((n) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                        <hr
                          className="global-hr"
                          style={{ borderBottom: "1px solid #000" }}
                        />
                        {r?.items?.map((k) => (
                          <tr className="print-table-report-expend-view">
                            <td>
                              {k?.itemName ? k?.itemName : <span>&nbsp;</span>}
                            </td>
                          </tr>
                        ))}
                      </td>
                      <td>
                        {convertDateFormat(r?.date)}
                        {r?.paymenttypes?.map((n) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                        <hr
                          className="global-hr"
                          style={{ borderBottom: "1px solid #000" }}
                        />
                        {r?.items?.map((k) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                      </td>
                      <td>
                        {r?.totalAmount?.toFixed(2)}
                        {r?.paymenttypes?.map((n) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                        <hr
                          className="global-hr"
                          style={{ borderBottom: "1px solid #000" }}
                        />
                        {r?.items?.map((k) => (
                          <tr className="print-table-report-expend-view">
                            <td>{k?.amount.toFixed(2)}</td>
                          </tr>
                        ))}
                      </td>
                      <td>
                        {r?.discount?.toFixed(2)}
                        {r?.paymenttypes?.map((n) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                        <hr
                          className="global-hr"
                          style={{ borderBottom: "1px solid #000" }}
                        />
                        {r?.items?.map((k) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                      </td>
                      <td>
                        {r?.paidAmount?.toFixed(2)}
                        {r?.paymenttypes?.map((n) => (
                          <tr className="print-table-report-expend-view">
                            <td>{n?.amount!==null && n?.amount.toFixed(2)}</td>
                          </tr>
                        ))}
                        <hr
                          className="global-hr"
                          style={{ borderBottom: "1px solid #000" }}
                        />
                        {r?.items?.map((k) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                      </td>
                      <td>
                        ---
                        {r?.paymenttypes?.map((n) => (
                          <tr className="print-table-report-expend-view">
                            <td>{n?.vendor}</td>
                          </tr>
                        ))}
                        <hr
                          className="global-hr"
                          style={{ borderBottom: "1px solid #000" }}
                        />
                        {r?.items?.map((k) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                      </td>
                      <td>
                        ---
                        {r?.paymenttypes?.map((n) => (
                          <tr className="print-table-report-expend-view">
                            <td>{n?.commission.toFixed(2)}</td>
                          </tr>
                        ))}
                        <hr
                          className="global-hr"
                          style={{ borderBottom: "1px solid #000" }}
                        />
                        {r?.items?.map((k) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                      </td>
                      <td>
                        {r?.status}
                        {r?.paymenttypes?.map((n) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                        <hr
                          className="global-hr"
                          style={{ borderBottom: "1px solid #000" }}
                        />
                        {r?.items?.map((k) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                      </td>
                      <td>
                        {r?.usedWallet}
                        {r?.paymenttypes?.map((n) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                        <hr
                          className="global-hr"
                          style={{ borderBottom: "1px solid #000" }}
                        />
                        {r?.items?.map((k) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                      </td>
                      <td>
                        &nbsp;
                        {r?.paymenttypes?.map((n) => (
                          <tr className="print-table-report-expend-view">
                            <td>&nbsp;</td>
                          </tr>
                        ))}
                        <hr
                          className="global-hr"
                          style={{ borderBottom: "1px solid #000" }}
                        />
                        {r?.items?.map((k) => (
                          <tr className="print-table-report-expend-view">
                            <td>
                              {k?.itemName ? k?.vatorgst : <span>&nbsp;</span>}
                            </td>
                          </tr>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : undefined}

          {expense === true && (
            <div
              className="daily-report-expense-table-container"
              style={{ padding: "2% 5%" }}
            >
              <h4>Expense Table</h4>
              <div
                className="daily-report-expense-table"
                style={{
                  backgroundColor: "#fff",
                  overflowY: "hidden",
                  maxHeight: "none",
                }}
              >
                <table style={{ backgroundColor: "#fff" }}>
                  <thead>
                    <tr>
                      <th>Doc No</th>
                      <th>Date</th>
                      <th>Note</th>
                      <th style={{ width: "20%" }}>&nbsp;</th>
                      <th>Credit</th>
                      <th>Debit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>Opening Balance</td>
                      <td>&nbsp;</td>
                      <td>{dailyReportData?.expensetable?.openingBalance}</td>
                      <td>--</td>
                    </tr>
                    {dailyReportData?.expensetable?.expenseList?.map((j, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{convertDateFormat(j.date)}</td>
                        <td>{j.expenseName}</td>
                        <td>&nbsp;</td>
                        <td>{j?.credit}</td>
                        <td>{j.debit}</td>
                      </tr>
                    ))}
                    <tr className="table-cell-border-none">
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>Total</td>
                      <td>{dailyReportData?.expensetable?.crTot}</td>
                      <td>{dailyReportData?.expensetable?.debTot}</td>
                    </tr>

                    <tr className="table-cell-border-none">
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>Balance</td>
                      <td>&nbsp;</td>
                      <td>{dailyReportData?.balance}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div
            className="daily-report-amount-container"
            style={{ padding: "2% 5%" }}
          >
            <div className="daily-report-amount-left-container">
              <div className="daily-report-amount-left-first-container">
                <div className="daily-report-amount-left-first-single-container">
                  <p>Total Order Amount</p>
                  <span>:</span>
                  <h4>
                    {dailyReportData?.order?.totalOrderAmount?.toFixed(2)}
                  </h4>
                </div>
                <div className="daily-report-amount-left-first-single-container">
                  <p>Total Paid Amount </p>:
                  <h4>{dailyReportData?.order?.totalBalance?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-left-first-single-container">
                  <p>Credit</p>:<h4>{dailyReportData?.order?.totalCredit?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-left-first-single-container">
                    <p style={{fontSize:"0.9rem",fontWeight:600}}>Total Return</p>:
                    <h4>{dailyReportData?.order?.totalOrderReturn?.toFixed(2)}</h4>
                  </div>
                  <div className="daily-report-amount-left-first-single-container">
                    <p>Total VAT</p>:
                    <h4>{dailyReportData?.order?.totalVat?.toFixed(2)}</h4>
                  </div>
                  <div className="daily-report-amount-left-first-single-container">
                    <p>Total Wallet</p>:
                    <h4>{dailyReportData?.order?.totalWallet!==null ?(dailyReportData?.order?.totalWallet?.toFixed(2)):0}</h4>
                  </div>
              </div>
              <div className="daily-report-amount-left-first-container">
                <h4>Products</h4>
                {
                    dailyReportData?.product?.map((r,i)=>(
                      <div className="daily-report-amount-left-first-single-container">
                      <p>{r?.name}</p>
                      <span>:</span>
                      <h4>
                        {r?.sale}
                      </h4>
                    </div>
                    ))
                  }
                {/* <div className="daily-report-amount-left-first-single-container">
                  <p>Total Readymade Sale</p>
                  <span>:</span>
                  <h4>{dailyReportData?.product?.totalreadymadesale?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-left-first-single-container">
                  <p>Total Material Sale</p>
                  <span>:</span>
                  <h4>{dailyReportData?.product?.totalmaterialsale?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-left-first-single-container">
                  <p>Total Accessories Sale</p>
                  <span>:</span>
                  <h4>{dailyReportData?.product?.totalaccessorysale?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-left-first-single-container">
                  <p>Total Outcloth Sale</p>
                  <span>:</span>
                  <h4>{dailyReportData?.product?.totalOutClothSale?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-left-first-single-container">
                  <h3>Total Sale</h3>
                  <span>:</span>
                  <h4 style={{ fontSize: "1.3rem" }}>
                    {dailyReportData?.product?.totalCashExpense?.toFixed(2)}
                  </h4>
                </div> */}
              </div>
              <div className="daily-report-amount-left-first-container">
                <h4>Expense</h4>
                <div className="daily-report-amount-left-first-single-container">
                  <p>Petty Voucher Expense</p>
                  <span>:</span>
                  <h4>{dailyReportData?.expenses?.pettyvoucherexpense?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-left-first-single-container">
                  <p>Cash Expense</p>
                  <span>:</span>
                  <h4>{dailyReportData?.expenses?.cashexpense?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-left-first-single-container">
                  <p style={{ fontSize: "1rem" }}>Total</p>
                  <span>:</span>
                  <h4 style={{ fontSize: "1rem" }}>
                    {dailyReportData?.expenses?.total?.toFixed(2)}
                  </h4>
                </div>
              </div>
            </div>
            <div className="daily-report-amount-right-container">
              <div className="daily-report-amount-right-first-container">
                <div className="daily-report-amount-right-first-single-container">
                  <p style={{ fontSize: "1rem" }}>Total Amount</p>
                  <span>:</span>
                  <h4 style={{ fontSize: "1rem" }}>
                    {dailyReportData?.totalAmount?.toFixed(2)}
                  </h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p>Cash</p>
                  <span>:</span>
                  <h4>{dailyReportData?.payment?.totalCash?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p>UPI</p>
                  <span>:</span>
                  <h4>{dailyReportData?.payment?.totalUpi?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p>Credit Card</p>
                  <span>:</span>
                  <h4>{dailyReportData?.payment?.totalCard?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p>Return Amount</p>
                  <span>:</span>
                  <h4>{dailyReportData?.payment?.totalReturn?.toFixed(2)}</h4>
                </div>
              </div>
              <div
                className="daily-report-amount-right-first-container"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="daily-report-amount-right-first-single-container">
                  <p style={{ fontSize: "1rem" }}>Total Sale After Expense</p>
                  <span>:</span>
                  <h4 style={{ fontSize: "1rem" }}>{dailyReportData?.totalSalesAfterExpense?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p>Petty Voucher Expense</p>
                  <span>:</span>
                  <h4>{dailyReportData?.expenses?.pettyvoucherexpense?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p
                    style={{
                      fontStyle: "italic",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    Total Credit Card Commission
                  </p>
                  <span>:</span>
                  <h4>{dailyReportData?.commission?.totalcardCommission?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p
                    style={{
                      fontStyle: "italic",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    Total Credit Card After Commission
                  </p>
                  <span>:</span>
                  <h4>
                    {dailyReportData?.commission?.totalCardAfterCommission?.toFixed(2)}
                  </h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p
                    style={{
                      fontStyle: "italic",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    Total Upi Commission
                  </p>
                  <span>:</span>
                  <h4>{dailyReportData?.commission?.totalUpiCommission?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p
                    style={{
                      fontStyle: "italic",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    Total Upi After Commission
                  </p>
                  <span>:</span>
                  <h4>
                    {dailyReportData?.commission?.totalUpiAfterCommission?.toFixed(2)}
                  </h4>
                </div>
              </div>
               {/* cash in or cash out */}
               {
                  (dailyReportData?.cashOut?.entries?.length !==0 || dailyReportData?.cashIn?.entries?.length !==0) && 
               
               <div className="daily-report-amount-left-first-container">
                  {
                    dailyReportData?.cashOut?.entries?.length !==0 && 
                    <div className="daily-report-cash-in-or-out">
                    <p>CASH OUT</p>
                       <table>
                       <thead>
                          <tr>
                             <th>Date</th>
                             <th>Time</th>
                             <th>Cash</th>
                             <th>Collected</th>
                          </tr>
                       </thead>
                       <tbody>
                         {
                           dailyReportData?.cashOut?.entries?.map((r)=>(
                             <tr>
                               <td>{r?.date}</td>
                               <td>{r?.time}</td>
                               <td>{r?.cash}</td>
                              <td>{r?.collectedBy}</td>
                           </tr>
                           ))
                         }
                       
                      
                       </tbody>
                    </table>
                    </div>
                  }
                  
                   {
                    dailyReportData?.cashIn?.entries?.length !==0 &&
                    <div className="daily-report-cash-in-or-out">
                    <p>CASH IN</p>
                       <table>
                       <thead>
                          <tr>
                             <th>Date</th>
                             <th>Time</th>
                             <th>Cash</th>
                             <th>Collected</th>
                          </tr>
                       </thead>
                       <tbody>
                       {
                           dailyReportData?.cashIn?.entries?.map((r)=>(
                             <tr>
                               <td>{r?.date}</td>
                               <td>{r?.time}</td>
                               <td>{r?.cash}</td>
                              <td>{r?.collectedBy}</td>
                           </tr>
                           ))
                         }
                       
                        
                       </tbody>
                    </table>
                    </div>
                   }
                 
                </div>
                 }
            </div>
          </div>

          <div
            className="daily-report-grand-total-container"
            style={{ margin: "2% 6%" }}
          >
            <h3 style={{ width: "30%" }}>Grand Total On Hand</h3>
            <span>:</span>
            <h3>{dailyReportData?.grandTotalOnHand?.toFixed(2)}</h3>
          </div>
        </div>
      </div>

      {/* daily report filter print view */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (dailyReportFilterPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "35%" }}
            >
               <h6 style={{margin:"0 0 0 3%"}}>Printed On {convertDateFormat(today)}&nbsp;{time}</h6>
              <p>Location&nbsp;:&nbsp;{dailyReportData?.location}</p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "30%" }}
            >
              <h3>{loginResponse?.storeName}</h3>
              <p>DAILY REPORT</p>
              <h5 style={{margin:"0",textAlign:"center"}}>{`(${convertDateFormat(fromDate)} - ${convertDateFormat(toDate)})`}</h5>
            </div>
            <div
              className="print-view-top-right-container"
              style={{ width: "35%" }}
            >
              <p style={{ fontSize: "0.8rem" }}></p>
              <p style={{ fontSize: "0.8rem" }}></p>
            </div>
          </div>
          {/* table */}
          {/* map  */}
          {dailyReportData?.list?.map((r, i) => (
            <div className="daily-report-date-filter-print-container">
              <div className="daily-report-date-filter-top-print-container">
                <p>Shift No : {r?.shiftId}</p>
                <div style={{ width: "50%" }}>
                  <p>Shift Opening Time : {`${convertDateFormat(r?.shiftopeningTime)} ${r?.shiftopeningTime?.split(' ')[1]}`}</p>
                  <p>Shift Closing Time : {r?.shiftClosingTime !== 'Active Shift'? `${convertDateFormat(r?.shiftClosingTime)} ${r?.shiftClosingTime?.split(' ')[1]}`:r?.shiftClosingTime}</p>
                </div>
              </div>

              {details === false ? (
                <div
                  className="print-view-table-container"
                  style={{ padding: "1%" }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Bill No</th>
                        <th>Order No</th>
                        <th>Date</th>
                        <th>Amt</th>
                        <th>Dis</th>
                        <th>Paid Amt</th>
                        <th>Type</th>
                        <th>Comm</th>
                        <th>Status</th>
                        <th>Wallet</th>
                        <th>VAT /GST</th>
                      </tr>
                    </thead>
                    <tbody>
                      {r?.list?.map((k) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{k?.billNo}</td>
                          <td>{k?.orderNo}</td>
                          <td>{convertDateFormat(k?.date)}</td>
                          <td>{k?.totalAmount?.toFixed(2)}</td>
                          <td>{k?.discount?.toFixed(2)}</td>
                          <td>{k?.paidAmount?.toFixed(2)}</td>
                          <td>...</td>
                          <td>...</td>
                          <td>{k?.status}</td>
                          <td>{k?.usedWallet}</td>
                          <td>{k?.vatorgst}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : details === true ? (
                <div
                  className="print-view-table-container"
                  style={{ padding: "1%" }}
                >
                  <table>
                    <thead>
                      <tr>
                        {/* <th>No</th> */}
                        <th>Bill No</th>
                        <th>Order No</th>
                        <th style={{ width: "15%" }}>Product</th>
                        <th style={{ width: "15%" }}>Date</th>
                        <th>Amt</th>
                        <th>Disc</th>
                        <th>Paid Amt</th>
                        <th style={{ width: "15%" }}>Type</th>
                        <th>Comm</th>
                        <th>Status</th>
                        <th>Wallet</th>
                        <th>VAT / GST</th>
                      </tr>
                    </thead>
                    <tbody>
                      {r?.list?.map((k, i) => (
                        <tr>
                          {/* <td>
                        {i + 1}
                              {k?.paymenttypes?.map((n) => (
                                <tr className="print-table-report-expend-view">
                                  <td>&nbsp;</td>
                                </tr>
                              ))}
                              <hr className="global-hr"
                                  style={{ borderBottom: "1px solid #000" }}
                              />
                              {k?.items?.map((m) => (
                                <tr className="print-table-report-expend-view">
                                  <td>&nbsp;</td>
                                </tr>
                              ))}
                        </td> */}
                          <td>
                            {k?.billNo}
                            {k?.paymenttypes?.map((n) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                            <hr
                              className="global-hr"
                              style={{ borderBottom: "1px solid #000" }}
                            />
                            {k?.items?.map((m) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                          </td>
                          <td>
                            {k?.orderNo}
                            {k?.paymenttypes?.map((n) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                            <hr
                              className="global-hr"
                              style={{ borderBottom: "1px solid #000" }}
                            />
                            {k?.items?.map((m) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                          </td>
                          <td>
                            &nbsp;
                            {k?.paymenttypes?.map((n) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                            <hr
                              className="global-hr"
                              style={{ borderBottom: "1px solid #000" }}
                            />
                            {k?.items?.map((m) => (
                              <tr className="print-table-report-expend-view">
                                <td>{m?.itemName}</td>
                              </tr>
                            ))}
                          </td>
                          <td>
                            {k?.date}
                            {k?.paymenttypes?.map((n) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                            <hr
                              className="global-hr"
                              style={{ borderBottom: "1px solid #000" }}
                            />
                            {k?.items?.map((m) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                          </td>
                          <td>
                            {k?.totalAmount?.toFixed(2)}
                            {k?.paymenttypes?.map((n) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                            <hr
                              className="global-hr"
                              style={{ borderBottom: "1px solid #000" }}
                            />
                            {k?.items?.map((m) => (
                              <tr className="print-table-report-expend-view">
                                <td>{m?.amount}</td>
                              </tr>
                            ))}
                          </td>
                          <td>
                            {k?.discount?.toFixed(2)}
                            {k?.paymenttypes?.map((n) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                            <hr
                              className="global-hr"
                              style={{ borderBottom: "1px solid #000" }}
                            />
                            {k?.items?.map((m) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                          </td>
                          <td>
                            {k?.paidAmount?.toFixed(2)}

                            {k?.paymenttypes?.map((n) => (
                              <tr className="print-table-report-expend-view">
                                <td>{n?.amount}</td>
                              </tr>
                            ))}
                            <hr
                              className="global-hr"
                              style={{ borderBottom: "1px solid #000" }}
                            />
                            {k?.items?.map((m) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                          </td>
                          <td>
                            ---
                            {k?.paymenttypes?.map((n) => (
                              <tr className="print-table-report-expend-view">
                                <td>{n?.vendor}</td>
                              </tr>
                            ))}
                            <hr
                              className="global-hr"
                              style={{ borderBottom: "1px solid #000" }}
                            />
                            {k?.items?.map((m) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                          </td>
                          <td>
                            ---
                            {k?.paymenttypes?.map((n) => (
                              <tr className="print-table-report-expend-view">
                                <td>{n?.commission?.toFixed(2)}</td>
                              </tr>
                            ))}
                            <hr
                              className="global-hr"
                              style={{ borderBottom: "1px solid #000" }}
                            />
                            {k?.items?.map((m) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                          </td>
                          <td>
                            {k?.status}
                            {k?.paymenttypes?.map((n) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                            <hr
                              className="global-hr"
                              style={{ borderBottom: "1px solid #000" }}
                            />
                            {k?.items?.map((m) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                          </td>
                          <td>
                            {k?.usedWallet}
                            {k?.paymenttypes?.map((n) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                            <hr
                              className="global-hr"
                              style={{ borderBottom: "1px solid #000" }}
                            />
                            {k?.items?.map((m) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                          </td>
                          <td>
                            &nbsp;
                            {k?.paymenttypes?.map((n) => (
                              <tr className="print-table-report-expend-view">
                                <td>&nbsp;</td>
                              </tr>
                            ))}
                            <hr
                              className="global-hr"
                              style={{ borderBottom: "1px solid #000" }}
                            />
                            {k?.items?.map((m) => (
                              <tr className="print-table-report-expend-view">
                                <td>{m?.vatorgst}</td>
                              </tr>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : undefined}
              {expense === true && (
                <div
                  className="daily-report-expense-table-container"
                  style={{ padding: "2% 5%" }}
                >
                  <h4>Expense Table</h4>
                  <div
                    className="daily-report-expense-table"
                    style={{
                      backgroundColor: "#fff",
                      overflowY: "hidden",
                      maxHeight: "none",
                    }}
                  >
                    <table style={{ backgroundColor: "#fff" }}>
                      <thead>
                        <tr>
                          <th>Doc No</th>
                          <th>Date</th>
                          <th>Note</th>
                          <th style={{ width: "20%" }}>&nbsp;</th>
                          <th>Credit</th>
                          <th>Debit</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>Opening Balance</td>
                          <td>&nbsp;</td>
                          <td>
                            {dailyReportData?.expensetable?.openingBalance}
                          </td>
                          <td>--</td>
                        </tr>
                        {dailyReportData?.expensetable?.expenseList?.map(
                          (j, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{j.date}</td>
                              <td>{j.expenseName}</td>
                              <td>&nbsp;</td>
                              <td>{j?.credit}</td>
                              <td>{j.debit}</td>
                            </tr>
                          )
                        )}
                        <tr className="table-cell-border-none">
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>Total</td>
                          <td>{dailyReportData?.expensetable?.crTot}</td>
                          <td>{dailyReportData?.expensetable?.debTot}</td>
                        </tr>

                        <tr className="table-cell-border-none">
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>Balance</td>
                          <td>&nbsp;</td>
                          <td>{dailyReportData?.balance}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          ))}
          <div
            className="daily-report-amount-container"
            style={{ padding: "2% 5%" }}
          >
            <div className="daily-report-amount-left-container">
              <div className="daily-report-amount-left-first-container">
                <div className="daily-report-amount-left-first-single-container">
                  <p>Total Order Amount</p>
                  <span>:</span>
                  <h4>
                    {dailyReportData?.order?.totalOrderAmount?.toFixed(2)}
                  </h4>
                </div>
                <div className="daily-report-amount-left-first-single-container">
                  <p>Total Paid Amount </p>:
                  <h4>{dailyReportData?.order?.totalBalance?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-left-first-single-container">
                  <p>Credit</p>:<h4>{dailyReportData?.order?.totalCredit?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-left-first-single-container">
                    <p style={{fontSize:"0.9rem",fontWeight:600}}>Total Return</p>:
                    <h4>{dailyReportData?.order?.totalOrderReturn?.toFixed(2)}</h4>
                  </div>
                  <div className="daily-report-amount-left-first-single-container">
                    <p>Total VAT</p>:
                    <h4>{dailyReportData?.order?.totalVat?.toFixed(2)}</h4>
                  </div>
                  <div className="daily-report-amount-left-first-single-container">
                    <p>Total Wallet</p>:
                    <h4>{dailyReportData?.order?.totalWallet!==null ?(dailyReportData?.order?.totalWallet?.toFixed(2)):0}</h4>
                  </div>
              </div>
              <div className="daily-report-amount-left-first-container">
                <h4>Products</h4>
                {
                    dailyReportData?.product?.map((r,i)=>(
                      <div className="daily-report-amount-left-first-single-container">
                        <p>{r?.name}</p>
                        <span>:</span>
                       <h4>
                        {r?.sale}
                       </h4>
                    </div>
                    ))
                }
              
                {/* <div className="daily-report-amount-left-first-single-container">
                  <p>Total Material Sale</p>
                  <span>:</span>
                  <h4>{dailyReportData?.product?.totalmaterialsale?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-left-first-single-container">
                  <p>Total Accessories Sale</p>
                  <span>:</span>
                  <h4>{dailyReportData?.product?.totalaccessorysale?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-left-first-single-container">
                  <p>Total Outcloth Sale</p>
                  <span>:</span>
                  <h4>{dailyReportData?.product?.totalOutClothSale?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-left-first-single-container">
                  <h3>Total Sale</h3>
                  <span>:</span>
                  <h4 style={{ fontSize: "1.3rem" }}>
                    {dailyReportData?.product?.totalCashExpense?.toFixed(2)}
                  </h4>
                </div> */}
              </div>
              <div className="daily-report-amount-left-first-container">
                <h4>Expense</h4>
                <div className="daily-report-amount-left-first-single-container">
                  <p>Petty Voucher Expense</p>
                  <span>:</span>
                  <h4>{dailyReportData?.expenses?.pettyvoucherexpense?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-left-first-single-container">
                  <p>Cash Expense</p>
                  <span>:</span>
                  <h4>{dailyReportData?.expenses?.cashexpense?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-left-first-single-container">
                  <h3>Total</h3>
                  <span>:</span>
                  <h4 style={{ fontSize: "1.3rem" }}>
                    {dailyReportData?.expenses?.total?.toFixed(2)}
                  </h4>
                </div>
              </div>
            </div>
            <div className="daily-report-amount-right-container">
              <div className="daily-report-amount-right-first-container">
                <div className="daily-report-amount-right-first-single-container">
                  <p style={{ fontSize: "1.3rem" }}>Total Amount</p>
                  <span>:</span>
                  <h4 style={{ fontSize: "1.3rem" }}>
                    {dailyReportData?.totalAmount?.toFixed(2)}
                  </h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p>Cash</p>
                  <span>:</span>
                  <h4>{dailyReportData?.payment?.totalCash?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p>UPI</p>
                  <span>:</span>
                  <h4>{dailyReportData?.payment?.totalUpi?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p>Credit Card</p>
                  <span>:</span>
                  <h4>{dailyReportData?.payment?.totalCard?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p>Return Amount</p>
                  <span>:</span>
                  <h4>{dailyReportData?.payment?.totalReturn?.toFixed(2)}</h4>
                </div>
              </div>
              <div
                className="daily-report-amount-right-first-container"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="daily-report-amount-right-first-single-container">
                  <p style={{ fontSize: "1.3rem" }}>Total Sale After Expense</p>
                  <span>:</span>
                  <h4 style={{ fontSize: "1.3rem" }}>{dailyReportData?.totalSalesAfterExpense?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p>Petty Voucher Expense</p>
                  <span>:</span>
                  <h4>{dailyReportData?.expenses?.pettyvoucherexpense?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p
                    style={{
                      fontStyle: "italic",
                      fontSize: "1.1rem",
                      fontWeight: "500",
                    }}
                  >
                    Total Credit Card Commission
                  </p>
                  <span>:</span>
                  <h4>{dailyReportData?.commission?.totalcardCommission?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p
                    style={{
                      fontStyle: "italic",
                      fontSize: "1.1rem",
                      fontWeight: "500",
                    }}
                  >
                    Total Credit Card After Commission
                  </p>
                  <span>:</span>
                  <h4>
                    {dailyReportData?.commission?.totalCardAfterCommission?.toFixed(2)}
                  </h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p
                    style={{
                      fontStyle: "italic",
                      fontSize: "1.1rem",
                      fontWeight: "500",
                    }}
                  >
                    Total Upi Commission
                  </p>
                  <span>:</span>
                  <h4>{dailyReportData?.commission?.totalUpiCommission?.toFixed(2)}</h4>
                </div>
                <div className="daily-report-amount-right-first-single-container">
                  <p
                    style={{
                      fontStyle: "italic",
                      fontSize: "1.1rem",
                      fontWeight: "500",
                    }}
                  >
                    Total Upi After Commission
                  </p>
                  <span>:</span>
                  <h4>
                    {dailyReportData?.commission?.totalUpiAfterCommission?.toFixed(2)}
                  </h4>
                </div>
              </div>
                {/* cash in or cash out */}
                {
                  (dailyReportData?.cashOut?.entries?.length !==0 || dailyReportData?.cashIn?.entries?.length !==0) && 
                  <div className="daily-report-amount-left-first-container">
                  {
                    dailyReportData?.cashOut?.entries?.length !==0 && 
                    <div className="daily-report-cash-in-or-out">
                    <p>CASH OUT</p>
                       <table>
                       <thead>
                          <tr>
                             <th>Date</th>
                             <th>Time</th>
                             <th>Cash</th>
                             <th>Collected</th>
                          </tr>
                       </thead>
                       <tbody>
                         {
                           dailyReportData?.cashOut?.entries?.map((r)=>(
                             <tr>
                               <td>{r?.date}</td>
                               <td>{r?.time}</td>
                               <td>{r?.cash}</td>
                              <td>{r?.collectedBy}</td>
                           </tr>
                           ))
                         }
                       
                      
                       </tbody>
                    </table>
                    </div>
                  }
                  
                   {
                    dailyReportData?.cashIn?.entries?.length !==0 &&
                    <div className="daily-report-cash-in-or-out">
                    <p>CASH IN</p>
                       <table>
                       <thead>
                          <tr>
                             <th>Date</th>
                             <th>Time</th>
                             <th>Cash</th>
                             <th>Collected</th>
                          </tr>
                       </thead>
                       <tbody>
                       {
                           dailyReportData?.cashIn?.entries?.map((r)=>(
                             <tr>
                               <td>{r?.date}</td>
                               <td>{r?.time}</td>
                               <td>{r?.cash}</td>
                              <td>{r?.collectedBy}</td>
                           </tr>
                           ))
                         }
                       
                        
                       </tbody>
                    </table>
                    </div>
                   }
                 
                </div>
                 }
            </div>

          </div>
          <div
            className="daily-report-grand-total-container"
            style={{ margin: "2% 6%" }}
          >
            <h3 style={{ width: "30%" }}>Grand Total On Hand</h3>
            <span>:</span>
            <h3>{dailyReportData?.grandTotalOnHand?.toFixed(2)}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};
