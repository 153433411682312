import { createSlice } from "@reduxjs/toolkit";

export const posReceiptSlice = createSlice({
  name: "posReceiptSlice",
  initialState: {
    posTemplateSelected: null,
  },
  reducers: {
    setPosReceiptSlice: (state, action) => {
      state.posTemplateSelected = action.payload;
    },
  },
});
export const { setPosReceiptSlice } = posReceiptSlice?.actions;
export default posReceiptSlice.reducer;
