import axios from "axios";
import { update_delivery_filter_data } from "../../Redux/Orders/Delivery/deliveryFilterDataSlice";
import { update_delivery_id_list } from "../../Redux/Orders/Delivery/deliveryIdListslice";
import { update_delivery_id } from "../../Redux/Orders/Delivery/deliveryIdSlice";
import { update_delivery_old_balances } from "../../Redux/Orders/Delivery/deliveryOldBalanceSlice";
import { update_delivery_payment_details } from "../../Redux/Orders/Delivery/deliveryPaymentDetailsSlice";
import store from "../../Redux/store";
import { HEADERS } from "../UrlAndPaths";
import { generateInvoiceAPICall } from "./workOrderAPI";

//Generate Delivery Number
export const generateDeliveryIdAPICall = async () => {
  await axios
    .get("workorder/generateDeliveryNumber", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_delivery_id({ deliveryId: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_delivery_id({ deliveryId: undefined }));
      console.error(err.response);
    });
};
//get list of Delivery Numbers
export const getListOfDeliveryIdAPICall = async () => {
  await axios
    .get("workorder/getDeliveryNos", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_delivery_id_list({ deliveryIdList: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_delivery_id_list({ deliveryIdList: undefined }));
      console.error(err.response);
    });
};

//Get Delivery data for user
export const getDeliveryDataForUserAPICall = async (body) => {
  await axios
    .post("workorder/getDeliveryBranches", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_delivery_filter_data({ deliveryFilterData: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        update_delivery_filter_data({ deliveryFilterData: undefined })
      );
      console.error(err.response);
    });
};

//Get Payment Details of a customer in delivery
export const getPaymentDetailsOfCustomerInDeliveryAPICall = async (body) => {
  await axios
    .post("workorder/getDeliveryPaymentDetails", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_delivery_payment_details({ deliveryPaymentDetails: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        update_delivery_payment_details({ deliveryPaymentDetails: undefined })
      );
      console.error(err.response);
    });
};

//Get Old Balances of a customer in delivery
export const getOldBalancesOfCustomerAPICall = async (body) => {
  await axios
    .post("workorder/getOldbalances", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_delivery_old_balances({ deliveryOldBalances: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        update_delivery_old_balances({ deliveryOldBalances: undefined })
      );
      console.error(err.response);
    });
};

//Change Status to delivered
export const updateStatusToDeliveredAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("workorder/deliveryStatusUpdation", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Delivery updated 👍");
        setSuccess(true);
        updateListener();
        clearStates()
      }
    })
    .catch((err) => {
      switch (err.response.status) {
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 409:
          setSnackMsg("Conflit!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;

        default:
          setSnackMsg("Something wrong!!");
          break;
      }
      setError(true);
    });
};

//Delivery with payment API
export const deliveryWithBillingAPICall = async (
  body,
  setIsLoading,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates,
  openInvoiceDialog,
  clearBillingStates
) => {
  setIsLoading(true);
  await axios
    .post("workorder/deliveryBilling", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Delivery updated 👍");
        clearStates();
        updateListener();
        setSuccess(true);
        setIsLoading(false);
        clearBillingStates();
       generateInvoiceAPICall({ _id: res.data?._id }, openInvoiceDialog);
      }
    })
    .catch((err) => {
      switch (err.response.status) {
        case undefined:
          setSnackMsg("Network error!!");
          break;
        case 404:
          setSnackMsg("Shift is inactive!!");
          break;
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;

        default:
          setSnackMsg("Something wrong!!");
          break;
      }
      setIsLoading(false);
      setError(true);
      console.error(err.response);
    });
};
