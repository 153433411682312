import { Autocomplete, Dialog, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { viewAllCustomersAPICall } from "../../../API/Customer/customerAPI";
import {
  addSalesReturnAPCall,
  deleteSalesReturnAPICall,
  editSalesReturnAPICall,
  generateSalesReturnIdAPICall,
  getAllSalesReturnAPICall,
  getAllSalesReturnInvoiceNoAPICall,
  getInvoiceNoForBillReturnAPICall,
  getInvoiceNoForDeliveryAPICall,
  getInvoiceNoForWorkOrderAPICall,
  getOrderDetailsForSalesReturnAPICall,
  getWorkOrderDetailsForSalesReturnAPICall,
  salesReturnSingleViewAPICall,
} from "../../../API/Sales/salesReturnAPI";
import { viewAllBranchesAPICall } from "../../../API/Settings/Company Settings/companySettingsAPI";
import {
  convertDateFormat,
  currentTime,
  getCurrentTime,
  today,
} from "../../../Js/Date";
import { Datepicker } from "../../Single Components/Datepicker";
import "../../../css/Sales/salesReturn.css";
import {
  deleteAlertMessage,
  extractSingleObjectGeneral,
} from "../../../Js/generalFunctions";
import { PaymentsWO } from "../Orders/WorkOrder/PaymentsWO";
import SuccessSnackbar from "../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../Single Components/SnackBars/ErrorSnackbar";
import { EditPayment } from "../../Single Components/Payment/EditPayment";
import { getAllTaxSettingsListAPICall } from "../../../API/Settings/TaxSettings/taxSettingsAPI";
import { LoadingForm } from "../../Single Components/LoadingForm";

export const SalesReturn = () => {
  // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // get all sales return invoice no
  const getAllSalesReturnInvoiceNo = useSelector(
    (state) => state.getAllSalesReturnNo.value
  );
  //List of All Customers
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  // get all sales return data
  const allSalesReturnList = useSelector((state) => state.AllSalesReturn.value);
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  // generate sales return id
  const generateSalesReturnId = useSelector(
    (state) => state.generateSalesReturnId.value
  );
  //work order invoice no list
  const workOrderInvoiceNoList = useSelector(
    (state) => state.workOrderInvoiceNoList.value
  );
  // delivery invoice no list
  const deliveryInvoiceNoList = useSelector(
    (state) => state.deliveryInvoiceNoList.value
  );
  // bill return invoice no list
  const billReturnInvoiceNoList = useSelector(
    (state) => state.billReturnInvoiceNoList.value
  );
  // work order details
  const workOrderDetails = useSelector(
    (state) => state.workOrderDetailsForSalesReturn.value
  );
  // sales order details
  const orderDetails = useSelector(
    (state) => state.orderDetailsForSalesReturn.value
  );
  // sales return single view
  const salesReturnSingleViewData = useSelector(
    (state) => state.salesReturnSingleView.value
  );
// tax list
  const allTaxList = useSelector((state) => state.taxSettingsSlice.value);

  const [addSalesReturn, setAddSalesReturn] = useState(false);
  const [returnType, setReturnType] = useState(1);
  const [isModify, setIsModify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const filterInitialState = {
    branch: null,
    invoiceNo: null,
    customerId: null,
    contactNo: null,
  };
  const [filterState, setFilterState] = useState(filterInitialState);
  const addSalesReturnFirstFormInitialState = {
    transNo: "",
    branchName: null,
    invoice: null,
    customerName: "",
    customerPk: "",
    paidAmount: 0,
    balanceAmount: 0,
  };
  const [addSalesReturnFirstForm, setAddSalesReturnFirstForm] = useState(
    addSalesReturnFirstFormInitialState
  );
  const addSalesReturnSecondFormInitialState = {
    invoiceId: "",
    returnDate: "",
    itemType: null,
    product: null,
    productName: "",
    unit: "",
    qty: 0,
    rate: 0,
    amount: 0,
    returnQty: 0,
    returnRate: 0,
    returnAmount: 0,
    reason: "",
    reasonType: 0,
    uuid:"",
    stitchRate:0,
    ratePlusStitchRate:0
  };
  const [addSalesReturnSecondForm, setAddSalesReturnSecondForm] = useState(
    addSalesReturnSecondFormInitialState
  );
  const [workOrderSingleItemType, setWorkOrderSingleItemType] = useState([]);
  const [addSalesReturnTableData, setAddSalesReturnTableData] = useState([]);
  const [currentRow, setCurrentRow] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [editChange, setEditChange] = useState(false);
  const [tableDataForBackEnd, setTableDataForBackEnd] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [selectedTaxes, setSelectedTaxes] = useState([]);
  const [totalReturnAmount, setTotalReturnAmount] = useState();
  const [paymnetDialog, setPaymnetDialog] = useState(false);
  // sales return single view
  const [salesReturnSingleView, setSalesReturnSingleView] = useState(false);
  // edit sales return
  const [editSalesReturn, setEditSalesReturn] = useState(false);
  // EDIT SALES RETURN
  const editSalesRetutnInitialForm = {
    editedId: "",
    transactionId: "",
    invoiceNo: null,
    customerName: "",
    returnDate: "",
    editReturnType: "",
    paidAmount:"",
    balanceAmount:"",
    totalAmount:0,
    totalAmountWOBalance:0,
    branchCode:'',
    shipmentCharge:0,
    discount:0,
    total:0,
    totalReturnAmt:0
  };

  const [salseReturnEditForm, setSalesReturnEditForm] = useState(
    editSalesRetutnInitialForm
  );
  const [editTableTotalAmt,setEditTableTotalAmt]=useState()
  const [editSubTotal,setEditSubTotal]=useState(0)
  const [editSubTotalWObalance,setEditSubToTalWOBalance]=useState(0)
  const [editedProductArray, setEditedProductArray] = useState([]);
  const [currentEditArray, setCurrentEditArray] = useState();
  //Snackbar States
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("Error!!");
  const [exclusiveTaxes, setExclusiveTaxes] = useState(0);
  const [inclusiveTaxes, setInclusiveTaxes] = useState(0);

  //payment to
  const [paymentTo, setPaymentTo] = useState("no payment");

  // edit paymnet dialog
  const [editPaymentDialog,setEditPaymnetDialog]=useState(false)
  // previous payment details for edit payment
  const [prevPayments,setPrevPayments]=useState(null)
  // previous payment details object
  const [prePaymentObject,setPrevPaymentObject]=useState([])
  //edit sales return object
  const [editSalesReturnData,setEditSalesReturnData]=useState()
  // api call
  useEffect(() => {
    viewAllCustomersAPICall({branchId:loginResponse?.branchPk});
    viewAllBranchesAPICall();
    getAllSalesReturnInvoiceNoAPICall();
    getAllSalesReturnAPICall(
      {
        branchId:
          userRole === "user" ? loginResponse?.branchId : filterState?.branch,
      },
      setIsLoading
    );
    generateSalesReturnIdAPICall();
    getAllTaxSettingsListAPICall();
  }, [isModify]);


  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };

  // updatelistner
  const updateListner = () => {
    setIsModify(!isModify);
  };

  // onChange fn
  const getReturnType = (e) => {
    setReturnType(e.target.value);
  };
  // open add return dialog
  const openAddReturnFn = () => {
    setAddSalesReturn(true);
  };

  const filterBodyToApi = {
    branchId:
      userRole === "user" ? loginResponse?.branchId : filterState?.branch,
    customerId: filterState?.customerId,
    contactNo: filterState?.contactNo === "" ? null : filterState?.contactNo,
    invoiceNo: filterState?.invoiceNo,
  };
  // filter submit button function
  const filterSubmitBtnFn = () => {
    getAllSalesReturnAPICall(filterBodyToApi, setIsLoading);
  };
console.log(allSalesReturnList);
  //// add sales return
  // generate trans no
  useEffect(() => {
    setAddSalesReturnFirstForm({
      ...addSalesReturnFirstForm,
      transNo: `${generateSalesReturnId?.prefix}${generateSalesReturnId?.transNo}`,
    });
  }, [generateSalesReturnId]);

  // add sales return onChange functions
  const getAddSalesReturnFirstForm = (key) => (e, newValue) => {
    if (key === "branchName") {
      setAddSalesReturnFirstForm({
        ...addSalesReturnFirstForm,
        branchName: newValue?.storeCode,
      });
    }
    if (key === "invoice") {
      setAddSalesReturnFirstForm({
        ...addSalesReturnFirstForm,
        invoice: newValue,
      });
    }
  };

  // invoice no api call
  useEffect(() => {
    getInvoiceNoForWorkOrderAPICall({ storecode: userRole === "user" ? loginResponse?.branchId:addSalesReturnFirstForm?.branchName});
    getInvoiceNoForDeliveryAPICall({ storecode: userRole === "user" ? loginResponse?.branchId:addSalesReturnFirstForm?.branchName });
    getInvoiceNoForBillReturnAPICall({ storecode: userRole === "user" ? loginResponse?.branchId:addSalesReturnFirstForm?.branchName });
  }, [returnType,addSalesReturnFirstForm?.branchName]);

  // extract selected invoice object from all invoice list
  useEffect(() => {
    let singleWorkOrderInvoiceList = extractSingleObjectGeneral(
        workOrderInvoiceNoList,
      "_id",
      addSalesReturnFirstForm?.invoice?._id
    );
    let singleDeliveryInvoiceList = extractSingleObjectGeneral(
      deliveryInvoiceNoList,
      "_id",
      addSalesReturnFirstForm?.invoice?._id
    );
    let singleBillingInvoiceList = extractSingleObjectGeneral(
      billReturnInvoiceNoList,
      "_id",
      addSalesReturnFirstForm?.invoice?._id
    );

    if (returnType == 1) {
      if (singleWorkOrderInvoiceList !== null) {
        setAddSalesReturnFirstForm({
          ...addSalesReturnFirstForm,
          customerName: singleWorkOrderInvoiceList[0]?.cusName,
          customerPk: singleWorkOrderInvoiceList[0]?.cusId,
          paidAmount: singleWorkOrderInvoiceList[0]?.paidAmount,
          balanceAmount: singleWorkOrderInvoiceList[0]?.balance,
        });
      }
    } else if (returnType == 2) {
      if (singleDeliveryInvoiceList !== null) {
        setAddSalesReturnFirstForm({
          ...addSalesReturnFirstForm,
          customerName: singleDeliveryInvoiceList[0]?.cusName,
          customerPk: singleDeliveryInvoiceList[0]?.cusId,
          paidAmount: singleDeliveryInvoiceList[0]?.paidAmount,
          balanceAmount: singleDeliveryInvoiceList[0]?.balance,
        });
      }
    } else if (returnType == 3) {
      if (singleBillingInvoiceList !== null) {
        setAddSalesReturnFirstForm({
          ...addSalesReturnFirstForm,
          customerName: singleBillingInvoiceList[0]?.cusName,
          customerPk: singleBillingInvoiceList[0]?.cusId,
          paidAmount: singleBillingInvoiceList[0]?.paidAmount,
          balanceAmount: singleBillingInvoiceList[0]?.balance,
        });
      }
    } else {
      setAddSalesReturnFirstForm({ ...addSalesReturnFirstForm, paidAmount: 0 });
    }
  }, [addSalesReturnFirstForm?.invoice]);

  // order details and workOrder details api call
  useEffect(() => {
    getWorkOrderDetailsForSalesReturnAPICall({
      workorderId: addSalesReturnFirstForm?.invoice?._id,
      type:returnType
    });
    getOrderDetailsForSalesReturnAPICall({
      orderId: addSalesReturnFirstForm?.invoice?._id,
    });
  }, [addSalesReturnFirstForm?.invoice]);

  // assign value to second form
  useEffect(() => {
    setAddSalesReturnSecondForm({
      ...addSalesReturnSecondForm,
      invoiceId:
        returnType == 1 || returnType == 2
          ? workOrderDetails?.orderNo
          : returnType == 3
          ? orderDetails?.invoiceNo
          : undefined,
      returnDate: today,
    });
  }, [workOrderDetails, orderDetails]);

 

  // extract product list from itemType
  useEffect(() => {
    // workOrder item
    let singleItemType = extractSingleObjectGeneral(
      workOrderDetails?.items,
      "itemId",
      addSalesReturnSecondForm?.itemType?.itemId
    );
    setWorkOrderSingleItemType(singleItemType !== null && singleItemType[0]);
    // order product
    let singleProduct = extractSingleObjectGeneral(
      orderDetails?.productList,
      "productId",
      addSalesReturnSecondForm?.itemType?.productId
    );
    setAddSalesReturnSecondForm({
      ...addSalesReturnSecondForm,
      productName: singleProduct !== null ? singleProduct[0]?.productName : "",
      unit: singleProduct !== null ? singleProduct[0]?.dimension : "",
      qty: singleProduct !== null ? singleProduct[0]?.quantity : "",
      rate: singleProduct !== null ? singleProduct[0]?.rate : "",
      amount: singleProduct !== null ? singleProduct[0]?.amount : "",
      uuid:singleProduct !== null ? singleProduct[0]?.uuid : "",

      
    });
  }, [addSalesReturnSecondForm?.itemType]);

  // extract single product from product list
  useEffect(() => {
    let singleProduct = extractSingleObjectGeneral(
      workOrderSingleItemType?.productList,
      "uuid",
      addSalesReturnSecondForm.product?.uuid
    );
    setAddSalesReturnSecondForm({
      ...addSalesReturnSecondForm,
      qty: singleProduct !== null ? singleProduct[0]?.quantity : "",
      rate: singleProduct !== null ? singleProduct[0]?.itemRate : "",
      amount: singleProduct !== null ? singleProduct[0]?.Amount : "",
      stitchRate:singleProduct !== null ? singleProduct[0]?.stitchRate : "",
      uuid:singleProduct !== null ? singleProduct[0]?.uuid: "",
    });
  }, [addSalesReturnSecondForm.product]);

  // calculate rate 
  useEffect(()=>{
     var ratePlusStitchRate=0
     ratePlusStitchRate=parseFloat(addSalesReturnSecondForm?.rate)+parseFloat(addSalesReturnSecondForm?.stitchRate)
        setAddSalesReturnSecondForm({
      ...addSalesReturnSecondForm,
      ratePlusStitchRate:isNaN(ratePlusStitchRate)?"":ratePlusStitchRate
     })
  },[addSalesReturnSecondForm?.rate,addSalesReturnSecondForm?.stitchRate])

  // calculate amount
  useEffect(() => {
    var total = 0;
    total =
      parseFloat(addSalesReturnSecondForm?.returnQty) *
      parseFloat(addSalesReturnSecondForm?.returnRate);
    setAddSalesReturnSecondForm({
      ...addSalesReturnSecondForm,
      returnAmount: isNaN(total) ? "" : total,
    });
  }, [
    addSalesReturnSecondForm?.returnQty,
    addSalesReturnSecondForm?.returnRate,
  ]);

   // second form onChange function
   const getAddSalesReturnSecondForm = (key) => (e, newValue) => {
    const { value } = e.target;
    const limit = addSalesReturnSecondForm?.qty;
    const rateLimit = addSalesReturnSecondForm?.ratePlusStitchRate;
    if (key === "itemType") {
      setAddSalesReturnSecondForm({
        ...addSalesReturnSecondForm,
        itemType: newValue,
      });
    }
    if (key === "product") {
      setAddSalesReturnSecondForm({
        ...addSalesReturnSecondForm,
        product: newValue,
      });
      setSelectedTaxes(newValue?.taxes || [])
    }
    if (key === "returnQty") {
      if (value > limit) {
        setAddSalesReturnSecondForm({
          ...addSalesReturnSecondForm,
          returnQty: limit,
        });
      } else {
        setAddSalesReturnSecondForm({
          ...addSalesReturnSecondForm,
          returnQty: value,
        });
      }
    }
    if (key === "returnRate") {
      if (value > rateLimit) {
        setAddSalesReturnSecondForm({
          ...addSalesReturnSecondForm,
          returnRate: rateLimit,
        });
      } else {
        setAddSalesReturnSecondForm({
          ...addSalesReturnSecondForm,
          returnRate: value,
        });
      }
    }
    if (key === "reason") {
      setAddSalesReturnSecondForm({
        ...addSalesReturnSecondForm,
        reason: value,
      });
    }
    if (key === "reasonType") {
      setAddSalesReturnSecondForm({
        ...addSalesReturnSecondForm,
        reasonType: value,
      });
    }
  };

  // add sales return plus button function
  const addSalesRetunPlusBtnFn = () => {
    if(addSalesReturnTableData.some(obj=>
    //  ( returnType == 1 || returnType == 2?obj?.productId
    //   :returnType == 3?obj?.itemType
    //   :undefined) === 
    //  ( returnType == 1 || returnType ==2 ?
    //   addSalesReturnSecondForm.product?.productId
    //   : returnType == 3?
    //   addSalesReturnSecondForm?.itemType?.productId
    //   :undefined)
    //   ))
    obj?.uuid === addSalesReturnSecondForm?.uuid
    // ( returnType == 1 || returnType ==2 ?
    //     addSalesReturnSecondForm.product?.productId
    //     : returnType == 3?
    //     addSalesReturnSecondForm?.itemType?.productId
    //     :undefined)
    ))
      {
        alert("item already exist ")
      }
      else{
        if (addSalesReturnSecondForm?.itemType === null) {
          alert("Select item type");
        } else if (
          (returnType == 1 || returnType == 2) &&
          addSalesReturnSecondForm?.product === null
        ) {
          alert("Select Product");
        } else if (
          addSalesReturnSecondForm?.returnQty === 0 ||
          addSalesReturnSecondForm?.returnQty === ""
        ) {
          alert("Enter return qty");
        } else if (
          addSalesReturnSecondForm?.returnRate === 0 ||
          addSalesReturnSecondForm?.returnRate === ""
        ) {
          alert("Enter return rate");
        } else {
// adding taxes value 

      let inclusiveTax=0;
      let exclusiveTax=0;
      let totalAmt=Number(addSalesReturnSecondForm?.returnQty)*Number(addSalesReturnSecondForm?.returnRate)
      if(selectedTaxes?.length!==0 && selectedTaxes[0]?.includedPrice){
        let taxArray=selectedTaxes?.map((obj)=>obj?.amount)

        let totalOfTax=taxArray.reduce((sum,item)=>sum+item,0)
        let taxableAmount=(totalAmt/(totalOfTax+100))*100

        let totalInclTax = taxArray.reduce((total, taxPercentage) => {
          const discountAmount = (taxPercentage / 100) * taxableAmount;
          return total + discountAmount;
        }, 0);
        inclusiveTax=totalInclTax || 0
      }
      else if(selectedTaxes?.length!==0 && selectedTaxes[0]?.includedPrice===false){
        let totalTax=selectedTaxes?.reduce((sum,item)=>sum + item?.amount,0)
        let totalExclTax=(totalAmt*totalTax)/100
        exclusiveTax=totalExclTax
      }

          setAddSalesReturnTableData([
            ...addSalesReturnTableData,
            {
              invoiceNo: addSalesReturnSecondForm?.invoiceId,
              returnDate: addSalesReturnSecondForm?.returnDate,
              itemType:
                returnType == 1 || returnType == 2
                  ? addSalesReturnSecondForm?.itemType?.itemId
                  : returnType == 3
                  ? addSalesReturnSecondForm?.itemType?.productId
                  : undefined,
              productId:
                returnType == 1 || returnType == 2
                  ? addSalesReturnSecondForm.product?.productId
                  : undefined,
              productName:
                returnType == 1 || returnType == 2
                  ? addSalesReturnSecondForm.product?.productName
                  : returnType == 3
                  ? addSalesReturnSecondForm?.productName
                  : undefined,
              unit: addSalesReturnSecondForm?.unit,
              dimension:addSalesReturnSecondForm?.dimension,
              qty: addSalesReturnSecondForm?.qty,
              rate: addSalesReturnSecondForm?.rate,
              amount: addSalesReturnSecondForm?.amount,
              returnQty: parseFloat(addSalesReturnSecondForm?.returnQty),
              returnRate: parseFloat(addSalesReturnSecondForm?.returnRate),
              returnAmount: parseFloat(addSalesReturnSecondForm?.returnAmount),
              reason: addSalesReturnSecondForm?.reason,
              reasonType:
                addSalesReturnSecondForm?.reasonType == 0
                  ? "Old Sale"
                  : addSalesReturnSecondForm?.reasonType == 1
                  ? "Damaged Goods"
                  : addSalesReturnSecondForm?.reasonType == 2
                  ? "Stock"
                  : undefined,
              returnType,
              productUuid:
                returnType == 1 || returnType == 2
                  ? addSalesReturnSecondForm.product?.uuid
                  : undefined,
              productCode:
                returnType == 3
                  ? addSalesReturnSecondForm?.itemType?.productCode
                  : undefined,
              itemTypeOrder:
                returnType == 3
                  ? addSalesReturnSecondForm?.itemType?.itemType
                  : undefined,
              // uuid:returnType == 3 
              //     ? addSalesReturnSecondForm?.itemType?.uuid
              //     :undefined
              uuid:addSalesReturnSecondForm?.uuid,
              exclusiveTax,
              inclusiveTax
              //  uuid:uuid()
            },
          ]);
          setIsEdit(false);
          clearProductForm();
        }
      }
   
  };

  // console.log(addSalesReturnTableData);

  // console.log(addSalesReturnSecondForm);

  


  ///  delete table data
  const deletetabeData = (i) => {
    addSalesReturnTableData?.splice(i, 1);
    setAddSalesReturnTableData([...addSalesReturnTableData]);
  };

  const clearProductForm = () => {
    setAddSalesReturnSecondForm({
      ...addSalesReturnSecondForm,
      itemType: null,
      product: null,
      productName: "",
      unit: "",
      qty: 0,
      rate: 0,
      amount: 0,
      returnQty: 0,
      returnRate: 0,
      returnAmount: 0,
      reason: "",
      reasonType: 0,
    });
    setSelectedTaxes([])
  };

  // edit table Data
  const editTableData = (row, i) => {
    setIsEdit(true);
    addSalesReturnTableData?.splice(i, 1);
    setAddSalesReturnTableData([...addSalesReturnTableData]);
    let workOrderDetailsFilter = extractSingleObjectGeneral(
      workOrderDetails?.items,
      "itemId",
      row?.itemType
    );
    let orderDetailsFilter = extractSingleObjectGeneral(
      orderDetails?.productList,
      "productId",
      row?.itemType
    );
    setAddSalesReturnSecondForm({
      ...addSalesReturnSecondForm,
      itemType:
        returnType == 1 || returnType == 2
          ? workOrderDetailsFilter !== null && workOrderDetailsFilter[0]
          : returnType == 3
          ? orderDetailsFilter !== undefined && orderDetailsFilter[0]
          : undefined,
    });
    setCurrentRow(row);
    editListnerFn();
  };

  const editListnerFn = () => {
    setEditChange(!editChange);
  };

  useEffect(() => {
    if (isEdit === true) {
      let productListFilter = extractSingleObjectGeneral(
        workOrderSingleItemType?.productList,
        "productId",
        currentRow?.productId
      );
      setAddSalesReturnSecondForm({
        ...addSalesReturnSecondForm,
        product: productListFilter !== null && productListFilter[0],
        productName: currentRow?.productName,
        unit: currentRow?.unit,
        qty: currentRow?.qty,
        rate: currentRow?.rate,
        amount: currentRow?.amount,
        returnQty: currentRow?.returnQty,
        returnRate: currentRow?.returnRate,
        returnAmount: currentRow?.returnAmount,
        reason: currentRow?.reason,
        reasonType:
          currentRow?.reasonType === "Old Sale"
            ? 0
            : currentRow?.reasonType === "Damaged Goods"
            ? 1
            : currentRow?.reasonType === "Stock"
            ? 2
            : undefined,
      });
      setSelectedTaxes(productListFilter !== null && productListFilter[0]?.taxes || [])
    }
  }, [workOrderSingleItemType?.productList, editChange]);

  // table for backend
  useEffect(() => {

    if (returnType == 1 || returnType == 2) {
      setTableDataForBackEnd(
        addSalesReturnTableData?.map((r, i) => {
          return {
            invoiceNo: r?.invoiceNo,
            returnDate: r?.returnDate,
            typeId: r?.itemType,
            productId: r?.productUuid,
            returnQty: r?.returnQty,
            rate: r?.returnRate,
            amount: r?.returnAmount,
            reason: r?.reason,
            dimension: r?.unit,
            originalQty: r?.qty,
            originalRate: r?.rate,
            originalAmt: r?.amount,
            itemInfo: r?.productId,
            uuid:r?.uuid
          };
        })
      );
    }
    if (returnType == 3) {
      setTableDataForBackEnd(
        addSalesReturnTableData?.map((r, i) => {
          return {
            invoiceNo: r?.invoiceNo,
            returnDate: r?.returnDate,
            itemCode: r?.productCode,
            itemInfo: r?.itemType,
            itemName: r?.productName,
            dimension: r?.unit,
            itemType: r?.itemTypeOrder,
            returnQty: r?.returnQty,
            rate: r?.returnRate,
            amount: r?.returnAmount,
            reason: r?.reason,
            reasonType: r?.reasonType === "Old Sale"? 0
              :r?.reasonType === "Damaged Goods"?1
              :r?.reasonType === "Stock"?2
              :undefined
            ,
            originalQty: r?.qty,
            originalRate: r?.rate,
            originalAmt: r?.amount,
            uuid:r?.uuid
          };
        })
      );
    }

    
    if(addSalesReturnTableData?.length!==0){
      let totalIncl=addSalesReturnTableData?.reduce((sum,item)=>sum+ (item?.inclusiveTax || 0),0)
      let totalExcl=addSalesReturnTableData?.reduce((sum,item)=>sum+ (item?.exclusiveTax || 0),0)
   
      setExclusiveTaxes(totalExcl)
      setInclusiveTaxes(totalIncl)
    }
    else{
      setExclusiveTaxes(0)
      setInclusiveTaxes(0)
    }
  }, [addSalesReturnTableData]);

  const calculateTotalAmount = () => {
    let totalReturnAmount = 0;
    let TotalReturnAmtWOBalance = 0;
    addSalesReturnTableData?.forEach((r) => {
      totalReturnAmount += parseFloat(r?.returnAmount);
      TotalReturnAmtWOBalance =
        addSalesReturnFirstForm?.balanceAmount - totalReturnAmount;
    });

    setTotalAmount(TotalReturnAmtWOBalance);
    setTotalReturnAmount(totalReturnAmount);
  };
  useEffect(() => {
    calculateTotalAmount();
  }, [addSalesReturnTableData]);

  // paymnet dialog functions
  const openPaymentDialog = () => {
    setPaymnetDialog(true);
  };
  const closePyamentDialog = () => {
    setPaymnetDialog(false);
    setAddSalesReturn(true);
  };

  const clearState = () => {
    setAddSalesReturnFirstForm({
      ...addSalesReturnFirstForm,
      branchName: null,
      invoice: null,
    });
    setAddSalesReturnTableData([]);
    setTableDataForBackEnd([]);
  };

  // add sales return Submit button function
  const bodyToApi = {
    branchId:
      userRole === "user"
        ? loginResponse?.branchId
        : userRole === "admin"
        ? addSalesReturnFirstForm?.branchName
        : undefined,
    returnType: parseFloat(returnType),
    customerId: addSalesReturnFirstForm?.customerPk,
    invoiceNo: addSalesReturnFirstForm?.invoice?.orderNo,
    order_id: addSalesReturnFirstForm?.invoice?._id,
    date: `${addSalesReturnSecondForm?.returnDate} ${getCurrentTime()}`,
    returnInfo: tableDataForBackEnd,
    returnTotal: totalReturnAmount,
    total:
      returnType == 1 || returnType == 2
        ?parseFloat(workOrderDetails?.total)-parseFloat(totalReturnAmount)
        : returnType == 3
        ? parseFloat(orderDetails?.total)
        : undefined,
    shipmentCharge:
      returnType == 1 || returnType == 2
        ? workOrderDetails?.shipmentCharge
        : returnType == 3
        ? orderDetails?.shipmentCharge
        : undefined,
    discount:
      returnType == 1 || returnType == 2
        ? workOrderDetails?.discount
        : returnType == 3
        ? orderDetails?.discount
        : undefined,
  };

 

  const withOutPaymentBody = () => {
    if (bodyToApi !== undefined) {
      bodyToApi.paymentMethod = null;
      bodyToApi.billPaymentMethod = null;
      bodyToApi.isWallet = false;
      return bodyToApi;
    }
  };

  useEffect(() => {
    if (totalAmount < 0) {
      setPaymentTo("customer");
    }
    if (totalAmount > 0) {
      setPaymentTo("company");
    }
    if (totalAmount === 0) {
      setPaymentTo("no payment");
    }
  }, [totalAmount]);

  // add sales return submit button function
  const addSalesReturnSubmitBtnFn = () => {
    if(addSalesReturnTableData?.length !==0){

    if (paymentTo === "customer") {
      openPaymentDialog();
      setAddSalesReturn(false);
     
    }
    if (paymentTo === "no payment") {
      addSalesReturnAPCall(
        withOutPaymentBody(),
        setLoadingForm,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListner,
        clearState,
       
        // setAddSalesReturn,
        // setPaymnetDialog
      );
     
    }
    if (paymentTo === "company") {
      
      openPaymentDialog();
      setAddSalesReturn(false);
    }
  }
  else{
    alert("Add Product Table")
  }

    // if(totalAmount>0){
    //   openPaymentDialog()
    //   setAddSalesReturn(false)
    // }
    // else{
    //   addSalesReturnAPCall(
    //     bodyToApi,
    //     updateListner,
    //     setAddSalesReturn,
    //     setSuccessSnackOpen,
    //     setErrorSnackOpen,
    //     setSnackMsg,
    //     clearState,
    //     setPaymnetDialog
    //     )

    // }
  };
 

  // sales return simgle view
  const openSalesReturnSingleView = (id) => {
    setSalesReturnSingleView(true);
    salesReturnSingleViewAPICall({ returnId: id });
  };

  // delete sales return
  const deleteSalesReturn = (id) => {
    deleteSalesReturnAPICall(id, updateListner);
  };

  // edit sales return
  const editSalesReturnDialog = (editedObj) => {
    setEditSalesReturn(true);
    editSalesReturnForm(editedObj);
  };
  const editSalesReturnForm = (editedObj) => {
    setEditSalesReturnData(editedObj)
    let singleInvoice1 = extractSingleObjectGeneral(
      workOrderInvoiceNoList,
      "orderNo",
      editedObj?.orderNo
    );
    let singleInvoice2 = extractSingleObjectGeneral(
      deliveryInvoiceNoList,
      "orderNo",
      editedObj?.orderNo
    );
    let singleInvoice3 = extractSingleObjectGeneral(
      billReturnInvoiceNoList,
      "orderNo",
      editedObj?.orderNo
    );

    setReturnType(editedObj.type);
    if (editedObj.type == 1) {
      const [fObj] = singleInvoice1;
      setSalesReturnEditForm({
        ...salseReturnEditForm,
        invoiceNo: editedObj?.orderNo,
        transactionId: editedObj?.transNo,
        customerName: editedObj?.customer,
        returnDate: editedObj?.date,
        editedId: editedObj?._id,
        paidAmount:editedObj?.paidAmount,
        balanceAmount:editedObj?.balance,
        totalAmount:editedObj?.price,
        branchCode:editedObj?.branchCode,
        shipmentCharge:editedObj?.shipmentCharge,
        discount:editedObj?.discount,
        total:editedObj?.total
      });
    }
    if (editedObj.type == 2) {
      if (singleInvoice2 !== undefined) {
        const [fObj] = singleInvoice2;
        setSalesReturnEditForm({
          ...salseReturnEditForm,
          invoiceNo:editedObj?.orderNo,
          transactionId: editedObj?.transNo,
          customerName: editedObj?.customer,
          returnDate: editedObj?.date,
          editedId: editedObj?._id,
          paidAmount:editedObj?.paidAmount,
          balanceAmount:editedObj?.balance,
          totalAmount:editedObj?.price,
          branchCode:editedObj?.branchCode,
          shipmentCharge:editedObj?.shipmentCharge,
          discount:editedObj?.discount,
          total:editedObj?.total
        });
      }
    }
    if (editedObj.type == 3) {
      if (singleInvoice3 !== undefined) {
        const [fObj] = singleInvoice3;
        setSalesReturnEditForm({
          ...salseReturnEditForm,
          invoiceNo: editedObj?.orderNo,
          transactionId: editedObj?.transNo,
          customerName: editedObj?.customer,
          returnDate: editedObj?.date,
          editedId: editedObj?._id,
          paidAmount:editedObj?.paidAmount,
          balanceAmount:editedObj?.balance,
          totalAmount:editedObj?.price,
          branchCode:editedObj?.branchCode,
          shipmentCharge:editedObj?.shipmentCharge,
          discount:editedObj?.discount,
          total:editedObj?.total
        });
      }
    }
    setEditedProductArray(
      editedObj?.returnInfo.map((obj) => {
        return { ...obj };
      })
    );
  };

  //  store the original amount to a state
  useEffect(()=>{
   let editTableTotal = 0
   editSalesReturnData?.returnInfo?.forEach((r)=>{
      editTableTotal+=parseFloat(r?.amount)
    })
    setEditTableTotalAmt(editTableTotal)
    

  },[editedProductArray])
  // console.log(editTableTotalAmt);

  //  edit onChange
  const getReturnQty = (row) => (e) => {
    const { value } = e.target;
    const limit = row.originalQty;
    if (value > limit) {
      row.returnQty = isNaN(parseFloat(limit)) ? "" : parseFloat(limit);
    } else {
      row.returnQty = isNaN(parseFloat(value)) ? "" : parseFloat(value);
    }

    row.amount = parseFloat(row.returnQty) * parseFloat(row.rate);
    setEditedProductArray([...editedProductArray]);
  };
  const getreturnRate = (row) => (e) => {
    const { value } = e.target;
    const rateLimit = row.originalRate
    if(value > rateLimit ){
      row.rate = isNaN(parseFloat(rateLimit)) ? "" : parseFloat(rateLimit) ;
    }
    else{
      row.rate = isNaN(parseFloat(value))? "": parseFloat(value);
    }
    // row.rate = parseFloat(value);
    row.amount = parseFloat(row.returnQty) * parseFloat(row.rate);
    setEditedProductArray([...editedProductArray]);
  };

  // calculate edit total amount
   const calculateEditTotalAmount=()=>{
    let editToTalAmount=0;
    let editTotalReturnAmount=0;

    editedProductArray?.forEach((r)=>{
      editToTalAmount+=parseFloat(r?.amount)
    })
    editTotalReturnAmount=(salseReturnEditForm?.balanceAmount)-editToTalAmount;
    setSalesReturnEditForm({...salseReturnEditForm,
      totalAmountWOBalance:editTotalReturnAmount,
      totalReturnAmt:editToTalAmount
    })
  

   }
  //  console.log(salseReturnEditForm?.totalReturnAmt);


   useEffect(()=>{
    calculateEditTotalAmount()
   },[editedProductArray])
   
   // calculate subTotal in case of any changes in the amount 
   useEffect(()=>{
      let difference =0
      let subTotal=0
      let subTotalWoBalance=0
        // calculate difference
        if(editTableTotalAmt>salseReturnEditForm?.totalReturnAmt){
          difference=editTableTotalAmt-salseReturnEditForm?.totalReturnAmt
         }
        if(editTableTotalAmt<salseReturnEditForm?.totalReturnAmt){
          difference=salseReturnEditForm?.totalReturnAmt-editTableTotalAmt
         }

       // calculate subtotal
       if(editTableTotalAmt>salseReturnEditForm?.totalReturnAmt){
        subTotal=editSalesReturnData?.total+difference
       }
       else if(editTableTotalAmt<salseReturnEditForm?.totalReturnAmt){
        subTotal=editSalesReturnData?.total-difference
       }
       else{
        subTotal=editSalesReturnData?.total
       }
     
       subTotalWoBalance=parseFloat(editSalesReturnData?.balance)-parseFloat(difference)
       setEditSubTotal(subTotal)
       setEditSubToTalWOBalance(subTotalWoBalance)


   },[editTableTotalAmt,salseReturnEditForm?.totalReturnAmt])
  //  console.log(editSubTotal);

   // set payment to
  //  useEffect(()=>{
  //    if(salseReturnEditForm?.totalAmountWOBalance < 0){
  //     setPaymentTo("customer");
  //    }
  //    if(salseReturnEditForm?.totalAmountWOBalance > 0){
  //     setPaymentTo("company");
  //    }
  //    if(salseReturnEditForm?.totalAmountWOBalance === 0){
  //     setPaymentTo("no payment");
  //    }
  //  },[salseReturnEditForm?.totalAmountWOBalance])

  useEffect(()=>{
       if(editSubTotalWObalance < 0){
          setPaymentTo("customer");
         }
         if(editSubTotalWObalance > 0){
          setPaymentTo("company");
         }
         if(editSubTotalWObalance === 0){
          setPaymentTo("no payment");
         }
  },[editSubTotalWObalance])


  // edit sales return prev paymnet details
   useEffect(()=>{
     let paidAmountTotal=0
      if(editSalesReturnData !== undefined){
        editSalesReturnData?.paymentDetails?.forEach((r)=>{
          paidAmountTotal+=parseFloat(r?.paidAmount)
        })
        let prevPaymentForm = {
          shipmentCharge:editSalesReturnData?.shipmentCharge,
          prevTotal:editSalesReturnData?.total,
          usedPoints:0,
          usedWallet:0,
          discount:editSalesReturnData?.discount,
          paidAmount:
            editSalesReturnData?.paymentDetails !== null && editSalesReturnData?.paymentDetails?.length !==0
             ? editSalesReturnData?.paymentDetails?.length == 1?
               editSalesReturnData?.paymentDetails?.map((obj)=>({total:obj.paidAmount}))[0]
                :{total:paidAmountTotal}
                // editSalesReturnData?.paymentDetails?.reduce((accu,cur)=>(
                //   {total:accu.paidAmount + cur.paidAmount}
                // ))
                :0,
        }
        setPrevPayments(prevPaymentForm)
        setPrevPaymentObject(editSalesReturnData?.paymentDetails)
       
      }
   },[editSalesReturnData])
  //  console.log(prevPayments);

  const editedBodyToAPI = {
    returnId: salseReturnEditForm.editedId,
    date:`${salseReturnEditForm?.returnDate} ${getCurrentTime()}`,
    branchId:salseReturnEditForm?.branchCode,
    returnInfo:editedProductArray,
    returnTotal:salseReturnEditForm?.totalAmount,
    total:editSubTotal,
    shipmentCharge:salseReturnEditForm?.shipmentCharge,
    discount:salseReturnEditForm?.discount
  };


  const withOutPaymnetBody=()=>{
    if(editedBodyToAPI !== undefined){
      editedBodyToAPI.paymentMethod=null;
      editedBodyToAPI.billPaymentMethod=null;
      editedBodyToAPI.isWallet=false;
      return editedBodyToAPI
    }
  }



 

  //open edit payment dialog function
  const openEditPayment=()=>{
    setEditPaymnetDialog(true) 
  }
  // close edit payment dialog
  const closeEditPayment=()=>{
    setEditPaymnetDialog(false) 
  }

  const updateBtnFunction = () => {
    if(paymentTo === "customer"){
      openEditPayment()
      setEditSalesReturn(false)
    }
    if(paymentTo === "no payment"){
        editSalesReturnAPICall(
        withOutPaymnetBody(),
        setEditSalesReturn,
        updateListner,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg
     );
    }
    if(paymentTo === "company"){
      openEditPayment()
      setEditSalesReturn(false)
    }
   

    // editSalesReturnAPICall(
    //   editedBodyToAPI,
    //   setEditSalesReturn,
    //   updateListner,
    //   setSuccessSnackOpen,
    //   setErrorSnackOpen,
    //   setSnackMsg
    // );
    // console.log(editedBodyToAPI)
  };

  

  return (
    <div className="global-page-parent-container">

      <p className="breadcrumb">Sales &gt;Return</p>

      {/* top container */}
      <div className="global-white-bg-container sales-payments-container">
        <h3>Return</h3>


        <hr className="global-hr" />
        <div className="daily-report-top-input-container">
          {userRole === "admin" && (
            <div className="global-single-input auto-complete">
              <p>Branch</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={allBranchesList || [""]}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="select Branch..." />
                )}
                onChange={(e, newValue) =>
                  setFilterState({...filterState,branch:newValue?.storeCode})
                }
              />
            </div>
          )}

          <div className="global-single-input auto-complete">
            <p>Invoice</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={
                getAllSalesReturnInvoiceNo?.slice(0)?.reverse() || [
                  { prefix: "No", invoiceNo: "Data" },
                ]
              }
              getOptionLabel={(option) =>
                `${option?.prefix}${option?.invoiceNo}`
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="select InvoiceNo..." />
              )}
              onChange={(e, newValue) =>
                setFilterState({
                  ...filterState,
                  invoiceNo: newValue?.invoiceNo,
                })
              }
            />
          </div>
          <div className="global-single-input auto-complete">
            <p>Customer</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={customerList || []}
              getOptionLabel={(option) => `${option?.name}-${option?.cusId}`}
              renderInput={(params) => (
                <TextField {...params} placeholder="select Customer..." />
              )}
              onChange={(e, newValue) =>
                setFilterState({ ...filterState, customerId: newValue?._id })
              }
            />
          </div>
          <div className="global-single-input">
            <p>Contact</p>
            <input
              type="text"
              value={filterState?.contactNo}
              onChange={(e) =>
                setFilterState({ ...filterState, contactNo: e.target.value })
              }
            />
          </div>
          <button
            onClick={filterSubmitBtnFn}
            className="btn btn-primary"
            style={{ margin: "3% 1% 1% 1%" }}
          >
            Submit
          </button>
        </div>
      </div>

      {/* table container */}
      <div className="global-white-bg-container sales-payments-container">
        <div className="sales-payments-table-top-container">
          <div className="sales-payments-table-top-left-container">

            <h3>Return</h3>

          </div>
          <div className="stock-report-print-download-icon-container sales-return-button-date-container">
            <button onClick={openAddReturnFn} className="btn btn-primary">
              Add Return
            </button>
            <div style={{ width: "40%" }}>
              <Datepicker
                type="date"
                backgroundColor="#f1f2ff"
                height="40px"
                value={today}
              />
            </div>
          </div>
        </div>

        {/* table */}
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>No</th>
                {userRole === "admin" && (
                  <>
                    <th>Branch Code</th>
                    <th>Branch Name</th>
                  </>
                )}

                <th>Invoice No</th>
                <th>Customer</th>
                <th>Contact</th>
                <th>Date</th>
                <th>Price</th>
                <th>View</th>
                {/* <th>Edit</th>
                <th>Delete</th> */}
              </tr>
            </thead>
            <tbody>
              {(allSalesReturnList !== undefined && allSalesReturnList.length!==0) &&
                allSalesReturnList?.slice(0)?.reverse()?.map((r, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      {userRole === "admin" && (
                        <>
                          <td>{r?.branchCode}</td>
                          <td>{r?.branchName}</td>
                        </>
                      )}

                      <td>{r?.transNo}</td>
                      <td>{r?.customer}</td>
                      <td>{r?.contact}</td>
                      <td>{convertDateFormat(r?.date)}</td>
                      <td>{r?.price}</td>
                      <td>
                        <IconButton
                          onClick={() => openSalesReturnSingleView(r?._id)}
                        >
                          <i class="bi bi-eye visibility-icon"></i>
                        </IconButton>
                      </td>
                      {/* <td>
                        <IconButton onClick={() => editSalesReturnDialog(r)}>
                          <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton>
                      </td>
                      <td>
                        <IconButton
                          onClick={deleteAlertMessage(r._id, deleteSalesReturn)}
                        >
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td> */}
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* add sales return dialog  */}
      <Dialog
        open={addSalesReturn}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setAddSalesReturn(false)}
        sx={{ zIndex: 1210, marginTop: "2%" }}
      >
        <div className="sales-add-return-container">
          {/* first container */}
          <div className="sales-add-return-first-container">
            <div className="sales-add-return-first-heading-container">
              <h3>Add Return</h3>
              <div className="sales-add-return-first-heading-radio-container">
                <div className="add-sales-return-single-radio">
                  <input
                    type="radio"
                    id="salesReturnOrder"
                    name="salesReturnRadio"
                    value={1}
                    onChange={getReturnType}
                    checked={returnType == 1}
                    disabled={
                      addSalesReturnTableData[0]?.returnType == 2 ||
                      addSalesReturnTableData[0]?.returnType == 3
                    }
                  />
                  <label htmlFor="salesReturnOrder">Work Order</label>
                </div>
                <div className="add-sales-return-single-radio">
                  <input
                    type="radio"
                    id="salesReturnDelivery"
                    name="salesReturnRadio"
                    value={2}
                    checked={returnType == 2}
                    onChange={getReturnType}
                    disabled={
                      addSalesReturnTableData[0]?.returnType == 1 ||
                      addSalesReturnTableData[0]?.returnType == 3
                    }
                  />
                  <label htmlFor="salesReturnDelivery">Delivery</label>
                </div>
                <div className="add-sales-return-single-radio">
                  <input
                    type="radio"
                    id="salesReturnSales"
                    name="salesReturnRadio"
                    value={3}
                    checked={returnType == 3}
                    onChange={getReturnType}
                    disabled={
                      addSalesReturnTableData[0]?.returnType == 1 ||
                      addSalesReturnTableData[0]?.returnType == 2
                    }
                  />
                  <label htmlFor="salesReturnSales">Bill Return</label>
                </div>
              </div>
              <div className="sales-add-return-first-heading-checkbox-container">
                {/* <input type="checkbox" id="salesReturnCheckbox"/>
                    <label htmlFor="salesReturnCheckbox">Cutting Slip</label> */}
              </div>
            </div>
            <hr className="global-hr" />
            <div className="sales-add-return-first-input-container">
              <div className="global-single-input">
                <p>Transaction ID</p>
                <input
                  type="text"
                  disabled
                  value={addSalesReturnFirstForm?.transNo}
                />
              </div>
              {
                userRole === "admin" &&
                <div className="global-single-input auto-complete">
                <p>Branch</p>
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={allBranchesList || [""]}
                  getOptionLabel={(option) =>
                    `${option?.storeCode}-${option?.branchName}`
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Branch" />
                  )}
                  onChange={getAddSalesReturnFirstForm("branchName")}
                />
              </div>
              }

             

              <div
                className="global-single-input auto-complete sales-return-expand-auto-complete"
                style={{ position: "relative" }}
              >
                <p>Invoice</p>
                <Autocomplete
                  disabled={addSalesReturnTableData?.length > 0}
                  sx={{ width: "100%" }}
                  options={
                    returnType == 1
                      ? workOrderInvoiceNoList?.slice(0)?.reverse() || [
                          { orderNo: "No Data" },
                        ]
                      : returnType == 2
                      ? deliveryInvoiceNoList?.slice(0)?.reverse() || [
                          { orderNo: "No Data" },
                        ]
                      : returnType == 3
                      ? billReturnInvoiceNoList?.slice(0)?.reverse() || [
                          { orderNo: "No Data" },
                        ]
                      : undefined[""]
                  }
                  getOptionLabel={(option) => option?.orderNo}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="select Invoice..." />
                  )}
                  onChange={getAddSalesReturnFirstForm("invoice")}
                />
                <p className="suffix-text" style={{ color: "blue" }}>
                  Total Amount&nbsp;:&nbsp;--
                </p>
              </div>
              <div
                className="global-single-input disabled-input-container"
                style={{ width: "17%" }}
              >
                <p>Customer</p>
                <input
                  type="text"
                  value={
                    addSalesReturnFirstForm?.invoice !== null
                      ? addSalesReturnFirstForm?.customerName
                      : ""
                  }
                />
              </div>

              <div
                className="global-single-input disabled-input-container"
                style={{ width: "17%" }}
              >
                <p>Paid Amount</p>
                <input
                  type="text"
                  value={
                    addSalesReturnFirstForm?.invoice !== null
                      ? addSalesReturnFirstForm?.paidAmount
                      : 0
                  }
                />
              </div>
              <div
                className="global-single-input disabled-input-container"
                style={{ width: "17%" }}
              >
                <p>Balance Amount</p>
                <input
                  type="text"
                  value={
                    addSalesReturnFirstForm?.invoice !== null
                      ? addSalesReturnFirstForm?.balanceAmount
                      : 0
                  }
                />
              </div>
            </div>
          </div>
          {/* second container */}
          <div className="sales-add-return-second-container">
            <div className="global-single-input sales-return-invoice-id">
              <p>Invoice ID</p>
              <input type="text" value={addSalesReturnSecondForm?.invoiceId} />
            </div>
            <div className="global-single-input sales-return-invoice-id">
              <p>Return Date</p>
              <input type="date" value={addSalesReturnSecondForm?.returnDate} />
            </div>
            <div
              className="global-single-input  sales-return-invoice-id auto-complete"
              style={{ position: "relative" }}
            >
              <p>
                {returnType == 1 || returnType == 2
                  ? "Item Type"
                  : "Product Code"}
              </p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={
                  returnType == 1 || returnType == 2
                    ? workOrderDetails?.items || [
                        { item: "No", customerName: "Data" },
                      ]
                    : returnType == 3
                    ? orderDetails?.productList || [{ productCode: "No Data" }]
                    : undefined[""]
                }
                getOptionLabel={(option) =>
                  returnType == 1 || returnType == 2
                    ? `${option?.item}-${option?.customerName}`
                    : option?.productCode
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="select..." />
                )}
                value={addSalesReturnSecondForm?.itemType}
                onChange={getAddSalesReturnSecondForm("itemType")}
              />
            </div>
            <div
              className="global-single-input sales-return-product auto-complete"
              style={{ position: "relative" }}
            >
              <p>Product Name</p>
              {returnType == 1 || returnType == 2 ? (
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={workOrderSingleItemType?.productList || []}
                  getOptionLabel={(option) => option?.productName}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="select..." />
                  )}
                  value={addSalesReturnSecondForm.product}
                  onChange={getAddSalesReturnSecondForm("product")}
                />
              ) : (
                <input
                  type="text"
                  value={addSalesReturnSecondForm?.productName}
                  disabled
                />
              )}
            </div>
            <div className="global-single-input sales-return-unit disabled-input-container">
              <p>Unit</p>
              <input
                type="text"
                value={addSalesReturnSecondForm?.unit}
                disabled
              />
            </div>
            <div className="global-single-input sales-return-unit disabled-input-container">
              <p>Qty</p>
              <input
                type="text"
                value={addSalesReturnSecondForm?.qty}
                disabled
              />
            </div>
            <div className="global-single-input sales-return-rate disabled-input-container">
              <p>Rate</p>
              <input
                type="text"
                value={addSalesReturnSecondForm?.ratePlusStitchRate}
                disabled
              />
            </div>

            <div className="global-single-input sales-return-rate disabled-input-container">
              <p>Amount</p>
              <input
                type="text"
                value={addSalesReturnSecondForm?.amount}
                disabled
              />
            </div>
            <div
              className="global-single-input auto-complete-new"
              style={{ position: "relative",width:"20%" }}
            >

              <p>Tax</p>
              <Autocomplete
                  multiple
                  sx={{ width: "100%",cursor:"not-allowed" }}
                  options={allTaxList || []}
                  getOptionLabel={(option) => option?.taxName}
                  renderInput={(params) => (
                    <TextField 
                    {...params} 
                    />
                  )}
                  
                  disabled
                  value={selectedTaxes}
                 />
            </div>
            <div
              className="global-single-input sales-return-rate"
              style={{ position: "relative" }}
            >
              <p>Return Qty</p>
              <input
                type="text"
                value={addSalesReturnSecondForm?.returnQty}
                onChange={getAddSalesReturnSecondForm("returnQty")}
              />
            </div>
          
            <div
              className="global-single-input sales-return-amount"
              style={{ position: "relative" }}
            >
              <p>Rate</p>
              <input
                type="text"
                value={addSalesReturnSecondForm?.returnRate}
                onChange={getAddSalesReturnSecondForm("returnRate")}
              />
            </div>
            <div className="global-single-input sales-return-amount">
              <p>Amount</p>
              <input
                type="text"
                value={addSalesReturnSecondForm?.returnAmount}
              />
            </div>
            <div className="global-single-input sales-return-reason">
              <p>Reason</p>
              <textarea
                type="text"
                rows="3"
                value={addSalesReturnSecondForm?.reason}
                onChange={getAddSalesReturnSecondForm("reason")}
              />
            </div>
            {returnType == 3 && (
              <div className="sales-add-return-second-radio-container">
                <div className="sales-add-return-second-radio-single-container">
                  <input
                    type="radio"
                    id="salesReturnOldSale"
                    name="salesReturnSecondRadio"
                    value={0}
                    onChange={getAddSalesReturnSecondForm("reasonType")}
                    checked={addSalesReturnSecondForm?.reasonType == 0}
                  />
                  <label htmlFor="salesReturnOldSale">Old Stock</label>
                </div>
                <div className="sales-add-return-second-radio-red-single-container">
                  <input
                    type="radio"
                    id="salesReturnDamagedGoods"
                    name="salesReturnSecondRadio"
                    value={1}
                    onChange={getAddSalesReturnSecondForm("reasonType")}
                    checked={addSalesReturnSecondForm?.reasonType == 1}
                  />
                  <label htmlFor="salesReturnDamagedGoods">Damaged Goods</label>
                </div>
                <div className="sales-add-return-second-radio-single-container">
                  <input
                    value={2}
                    type="radio"
                    id="salesReturnStock"
                    name="salesReturnSecondRadio"
                    onChange={getAddSalesReturnSecondForm("reasonType")}
                    checked={addSalesReturnSecondForm?.reasonType == 2}
                  />
                  <label htmlFor="salesReturnStock">Stock</label>
                </div>
              </div>
            )}

            <button
              onClick={addSalesRetunPlusBtnFn}
              className="add-sales-return-plus-button"
            >
              +
            </button>
          </div>
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Invoice</th>
                  <th>Return Date</th>
                  <th>Product Name</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Return Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  {/* <th>Tax</th> */}
                  <th>Reason</th>
                  {returnType == 3 && <th>Type</th>}

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {addSalesReturnTableData?.length !== 0 ? (
                  addSalesReturnTableData?.map((r, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{r?.invoiceNo}</td>
                      <td>{r?.returnDate}</td>
                      <td>{r?.productName}</td>
                      <td>{r?.qty}</td>
                      <td>{r?.rate}</td>
                      <td>{r?.amount}</td>
                      <td>{r?.returnQty}</td>
                      <td>{r?.returnRate}</td>
                      <td>{r?.returnAmount}</td>
                      {/* <td>---</td> */}
                      <td>{r?.reason}</td>
                      {returnType == 3 && <td>{r?.reasonType}</td>}

                      <td>
                        <IconButton
                          onClick={() => editTableData(r, i)}
                          //  onClick={()=>editTableProduct(r)}
                        >
                          <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton>
                        <IconButton onClick={() => deletetabeData(i)}>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={13}>No Data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              onClick={() => setAddSalesReturn(false)}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button
              onClick={addSalesReturnSubmitBtnFn}
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>

      {/* add sales return paymnet dialog */}
      {paymnetDialog === true && (
        <PaymentsWO
          closePayments={closePyamentDialog}
          parentWidth="84%"
          right="6%"
          boxShadow="0 0 10px 2px #eee"
          subTotal={Math.abs(totalAmount)+exclusiveTaxes}
          clearWOState={clearState}
          bodyWOPayment={bodyToApi}
          // bodyWOPaymnetCustomer={bodyToApi}
          setOpenSuccessSnack={setSuccessSnackOpen}
          setOpenErrorSnack={setErrorSnackOpen}
          setSnackMsg={setSnackMsg}
          finalAPI={addSalesReturnAPCall}
          updateListenerProp={updateListner}
          cusId={addSalesReturnFirstForm?.customerPk}
          paymentSection="salesReturn"
          branchId={loginResponse?.branchId}
          returnPaymnetType={paymentTo}
          setPaymnetDialog={setPaymnetDialog}
          exclusiveTax={exclusiveTaxes}
          inclusiveTax={inclusiveTaxes}
        />
      )}

      {/* sales return single view */}
      <Dialog
        maxWidth="lg"
        open={salesReturnSingleView}
        onKeyDown={(e) => e.key === "Escape" && setSalesReturnSingleView(false)}
      >
        <div className="sales-add-return-container">
          {/* first container */}
          <div className="sales-add-return-first-container">
            <div className="sales-add-return-first-heading-container">
              <h3>View Return</h3>
              <div className="sales-add-return-first-heading-radio-container">
                <div className="add-sales-return-single-radio">
                  <input
                    type="radio"
                    id="salesReturnOrder"
                    name="salesReturnRadio"
                    value={salesReturnSingleViewData?.returnType}
                    checked={
                      salesReturnSingleViewData?.returnType == 1 ? true : false
                    }
                    // onChange={getReturnType}
                    // defaultChecked
                    // disabled={tableDataForFrontEnd[0]?.returnType==2 ||tableDataForFrontEnd[0]?.returnType==3}
                  />
                  <label htmlFor="salesReturnOrder">Work Order</label>
                </div>
                <div className="add-sales-return-single-radio">
                  <input
                    type="radio"
                    id="salesReturnDelivery"
                    name="salesReturnRadio"
                    value={salesReturnSingleViewData?.returnType}
                    checked={
                      salesReturnSingleViewData?.returnType == 2 ? true : false
                    }
                    // onChange={getReturnType}
                    // disabled={tableDataForFrontEnd[0]?.returnType==1 ||tableDataForFrontEnd[0]?.returnType==3 }
                  />
                  <label htmlFor="salesReturnDelivery">Delivery</label>
                </div>
                <div className="add-sales-return-single-radio">
                  <input
                    type="radio"
                    id="salesReturnSales"
                    name="salesReturnRadio"
                    value={salesReturnSingleViewData?.returnType}
                    checked={
                      salesReturnSingleViewData?.returnType == 3 ? true : false
                    }
                    // onChange={getReturnType}
                    // disabled={tableDataForFrontEnd[0]?.returnType==1||tableDataForFrontEnd[0]?.returnType==2}
                  />
                  <label htmlFor="salesReturnSales">Bill Return</label>
                </div>
              </div>
            </div>
            <hr className="global-hr" />
            <div className="sales-add-return-first-input-container">
              <div className="global-single-input disabled-input-container">
                <p>Transaction ID</p>
                <input
                  type="text"
                  disabled
                  value={salesReturnSingleViewData?.transNo}
                />
              </div>
              <div className="global-single-input disabled-input-container">
                <p>Invoice</p>
                <input type="text" value={salesReturnSingleViewData?.orderId} />
                <p className="suffix-text">
                  Total Amount&nbsp;:&nbsp;5000.00 USD
                </p>
              </div>
              <div className="global-single-input disabled-input-container">
                <p>Customer</p>
                <input
                  type="text"
                  disabled
                  value={salesReturnSingleViewData?.customerName}
                />
              </div>
              <div
                className="global-single-input disabled-input-container"
                style={{ width: "17%" }}
              >
                <p>Paid Amount</p>
                <input
                  type="text"
                  disabled
                  value={salesReturnSingleViewData?.paidAmount}
                />
              </div>
              <div
                className="global-single-input disabled-input-container"
                style={{ width: "17%" }}
              >
                <p>Balance Amount</p>
                <input
                  type="text"
                  disabled
                  value={salesReturnSingleViewData?.balance}
                />
              </div>
              <div
                className="global-single-input disabled-input-container"
                style={{ width: "17%" }}
              >
                <p>Return Date</p>
                <input
                  type="text"
                  disabled
                  value={salesReturnSingleViewData?.date}
                />
              </div>
            </div>
          </div>
          {/* table container */}
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Product</th>
                  <th>Return Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {salesReturnSingleViewData?.returnInfo?.map((r, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{r?.productName}</td>
                    <td>{r?.returnQty}</td>
                    <td>{r?.rate}</td>
                    <td>{r?.amount}</td>
                    <td>{r?.type}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              onClick={() => setSalesReturnSingleView(false)}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button className="btn btn-primary">Submit</button>
          </div>
        </div>
      </Dialog>

      {/* edit sales return */}
      <Dialog
        open={editSalesReturn}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setEditSalesReturn(false)}
        sx={{ zIndex: 1210, marginTop: "2%" }}
      >
        <div className="sales-add-return-container">
          {/* first container */}
          <div className="sales-add-return-first-container">
            <div className="sales-add-return-first-heading-container">
              <h3>Edit Return</h3>
              <div className="sales-add-return-first-heading-radio-container">
                <div className="add-sales-return-single-radio">
                  <input
                    disabled
                    type="radio"
                    id="salesReturnOrder"
                    name="salesReturnRadio"
                    value={1}
                    onChange={getReturnType}
                    checked={returnType == 1 ? true : false}
                  />
                  <label htmlFor="salesReturnOrder">Work Order</label>
                </div>
                <div className="add-sales-return-single-radio">
                  <input
                    disabled
                    type="radio"
                    id="salesReturnDelivery"
                    name="salesReturnRadio"
                    value={2}
                    onChange={getReturnType}
                    checked={returnType == 2 ? true : false}
                  />
                  <label htmlFor="salesReturnDelivery">Delivery</label>
                </div>
                <div className="add-sales-return-single-radio">
                  <input
                    disabled
                    type="radio"
                    id="salesReturnSales"
                    name="salesReturnRadio"
                    value={3}
                    onChange={getReturnType}
                    checked={returnType == 3 ? true : false}
                  />
                  <label htmlFor="salesReturnSales">Bill Return</label>
                </div>
              </div>
            </div>
            <hr className="global-hr" />
            <div className="sales-add-return-first-input-container">
              <div className="global-single-input disabled-input-container">
                <p>Transaction ID</p>
                <input
                  type="text"
                  disabled
                  value={salseReturnEditForm?.transactionId}
                />
              </div>

              <div className="global-single-input disabled-input-container auto-complete">
                <p>Invoice</p>
                <input type=""
                 value={salseReturnEditForm?.invoiceNo}
                 disabled
                />
                {/* <Autocomplete
                  sx={{ width: "100%" }}
                  options={
                    returnType == 1
                      ? workOrderInvoiceNoList?.slice(0)?.reverse() || [" "]
                      : returnType == 2
                      ? deliveryInvoiceNoList?.slice(0)?.reverse() || [" "]
                      : returnType == 3
                      ? billReturnInvoiceNoList?.slice(0)?.reverse() || [" "]
                      : undefined || [""]
                  }
                  getOptionLabel={(option) => option?.orderNo}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="select Invoice..." />
                  )}
                  onChange={(e, newValue) =>
                    setSalesReturnEditForm({
                      ...salseReturnEditForm,
                      invoiceNo: newValue,
                    })
                  }
                  value={salseReturnEditForm?.invoiceNo}
                  disabled
                /> */}

                {/* <p className="suffix-text">Total Amount&nbsp;:&nbsp;5000.00 USD</p> */}
              </div>
              <div className="global-single-input disabled-input-container">
                <p>Customer</p>
                <input
                  type="text"
                  value={salseReturnEditForm?.customerName}
                  disabled
                />
              </div>
              <div
                className="global-single-input disabled-input-container"
                style={{ width: "17%" }}
              >
                <p>Paid Amount</p>
                <input type="text" disabled 
                  value={salseReturnEditForm?.paidAmount}
                />
              </div>
              <div
                className="global-single-input disabled-input-container"
                style={{ width: "17%" }}
              >
                <p>Balance Amount</p>
                <input type="text" disabled 
                value={salseReturnEditForm?.balanceAmount}
                />
              </div>
            </div>
          </div>

          {/* table container */}
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Invoice No</th>
                  <th>Product Name</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Return Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {editedProductArray?.map((r, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{r?.invoiceNo}</td>
                    <td>{r?.itemName}</td>
                    <td>{r?.originalQty}</td>
                    <td>{r?.originalRate}</td>
                    <td>{r?.originalAmt}</td>

                    <td>
                      <input
                        type="text"
                        className="grn-table-input"
                        style={{ width: "40%" }}
                        value={r?.returnQty}
                        onChange={getReturnQty(r)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="grn-table-input"
                        style={{ width: "40%" }}
                        value={r?.rate}
                        onChange={getreturnRate(r)}
                      />
                    </td>
                    <td>{r?.amount}</td>

                    <td>
                      {r?.reasonType == 0
                        ? "OldSale"
                        : r?.reasonType == 1
                        ? "Damaged Goods"
                        : r?.reasonType == 2
                        ? "Stock"
                        : "Nill"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              onClick={() => setEditSalesReturn(false)}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button onClick={updateBtnFunction} className="btn btn-primary">
              Update
            </button>
          </div>
        </div>
      </Dialog>
      {/* edit sales return paymnet Dialog */}
      {
        editPaymentDialog === true && (
          <EditPayment
          right="23%"
          closePayments={closeEditPayment}
          subTotal={Math.abs(editSubTotalWObalance)}
          prevPaymentForm={prevPayments}
          // prevPaymentObject={prePaymentObject}
          bodyWoPayment={editedBodyToAPI}
          finalAPICall={editSalesReturnAPICall}
          setIsLoading={setIsLoading}
          // setOpenSuccessSnack={setSuccessSnackOpen}
          // setOpenErrorSnack={setErrorSnackOpen}
          // setSnackMsg={setSnackMsg}
          updateListener={updateListner}
          paymentSection="salesReturn"
          returnPaymnetType={paymentTo}
          
          />
        )
      }

      {/* snackbar */}
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
      <LoadingForm
      loading={loadingForm}
      />
    </div>
    
  );
};
//helloo
