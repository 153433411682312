import { Autocomplete, Checkbox, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { viewAllMaterialProductsWithStockAPICall } from "../../../../API/Product List/productListAPI";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { editSingleJobCompletionAPICall, getUtilityJobCompletionWorkOrderAPICall, utilityJobCompletionWorkOrderListAPICall } from "../../../../API/Settings/Utility/JobCompletionutility/jobCompletionUtilityAPICall";
import { getAllActiveEmployeesAPICall } from "../../../../API/Staff/HRM/employeeAPI";
import  "../../../../css/Settings/Utility/utilityJobCompletion.css"
import { convertDateFormat, today,getCurrentTime } from "../../../../Js/Date";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const UtilityJobCompletion=()=>{
    // list of Branches
    const listOfBranchesStockOut = useSelector(
        (state)=>state.allBranchesSlice.value
    );
    const woNosForJobCompletion = useSelector(
        (state) => state.jobCompletionWorkorderListSlice.value
      );
    const utilityJobCompletionlist = useSelector(
        (state) => state.utilityJobCompletionListSlice.value
      );
    const materialProductList = useSelector(
        (state) => state.productsWithStockSlice.value
    );
    //Active Employee List
    const allActiveEmpList = useSelector(
        (state) => state.allActiveEmployeeSlice.value
    );
    const dateToday={today}
    const [snackMsg, setSnackMsg] = useState("oops!!!");
    const [errorSnackOpen, setErrorSnackOpen] = useState(false);
    const [successSnackOpen, setSuccessSnackOpen] = useState(false);
    const [tdDisableClass,setTdDisableClass]=useState("disabled-td-job-completion")
    const [branchId,setBranchId]=useState(null)
    const [orderId,setOrderId]=useState(null)
    const [editIconDisableClass,setEditIconDisableClass]=useState("")
    const [editEnabled,setEditEnabled]=useState(false)
    // const editJobCompletionBody=utilityJobCompletionlist!==undefined ? [...utilityJobCompletionlist] :[]
    
    const [isChanged,setIsChanged]=useState(false)
    const [isModify,setIsModify]=useState(false)
    const [itemModify,setItemModify]=useState(false)

    const [editJobCompletionBody,setEditJobCompletionBody]=useState([])
    //*Filtered List by designation
    //filtered by 'TAILOR
    const [tailorList, setTailorList] = useState([]);
    //filtered by 'CUTTING MASTER'
    const [cutterList, setCutterList] = useState([]);
    
    const [tailor,setTailor]=useState([])
    const [cutter,setCutter]=useState([])
    const [tailorIdArray,setTailorIdArray]=useState([])
    const [cutterIdArray,setCutterIdArray]=useState([])
    const [tailorSelected,setTailorSelected]=useState([])
    const [cutterSelected,setCutterSelected]=useState([])
    // const [stockBalance,setStockBalance]=useState()

    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    const editJobCompletionPayloadInitial={
        orderId:"",
        rack:"",
        items:[
            {
                id: "",
                itemId: "",
                uuid: "",
                completedQty: "",
                tailor: [
                    "",
                    "",
                    ""
                ],
                cuttingMaster: [
                    ""
                ],
                tailorCommission:"" ,
                materialQty: "",
                wastage:"" ,
                totalQtyUsed: "",
                jobCompletionDate:`${dateToday.today} ${getCurrentTime()}`
            }
        ]
    }
    const [editJobCompletionPayload,setEditJobCompletionPayload]=useState(editJobCompletionPayloadInitial)

    console.log(editJobCompletionPayload);
    
    const closeSuccessSnack = () => {
        setSuccessSnackOpen(false);
    };
    const closeErrorSnack = () => {
        setErrorSnackOpen(false);
    };
    const editJobCompletionClick=(index)=>()=>{
        setTdDisableClass("")
        setEditIconDisableClass("disabled-edit-job-completion")
        setEditEnabled(true)
        // setEditJobCompletionBody([...editJobCompletionBody])
        setSelectedRowIndex(index);
        if(utilityJobCompletionlist!==undefined){
            createSingleDataFrontEnd(utilityJobCompletionlist);
        }
        else{
            setEditJobCompletionBody([])
        }        
        
        // console.log(index);
        if(index!==null){
            let qtyUsed=editJobCompletionBody[index].qtyUsed
            let itemQty=editJobCompletionBody[index].completedQty
            let wastage=editJobCompletionBody[index].wastage
            let stockFixed=editJobCompletionBody[index].stock
            setEditJobCompletionBody(prevData => prevData.map(obj => ({ ...obj, totalQtyUsedFixed: qtyUsed*itemQty +wastage,stockFixed:stockFixed })))
            // setStockBalance(calc.toFixed(2))
        }
        setIsChanged(!isChanged)

    }
      
    const submitJobCompletionClick=()=>{
        setEditJobCompletionBody(utilityJobCompletionlist)
        clearStates()
    }

    const jobCompletionOrderSubmit=()=>{
        utilityJobCompletionWorkOrderListAPICall({orderId})
        viewAllMaterialProductsWithStockAPICall({ itemType: 2 ,branchId:branchId?.storeCode});
        if (allActiveEmpList !== undefined) {
            filterTailorByDesignation(allActiveEmpList);
            filterCutterByDesignation(allActiveEmpList);
          }
          clearStates()
    }
    const getEditJobCompletionForm=(index,key)=>(e,newValue)=>{
        const{value}=e.target;
        if(key==="quantity"){

            let limit=null;
            let newQty=null;
            if(utilityJobCompletionlist!==undefined){
                limit=utilityJobCompletionlist[index].qty;

                if(value > limit){
                    newQty =Number(limit);
                    setSnackMsg("Quantity Mismatch")
                    setErrorSnackOpen(true);
                }
                else {
                    newQty=Number(value);
                }
            }
            editJobCompletionBody[index].completedQty= Number(newQty)
            editJobCompletionBody[index].totalQtyUsed =
                Number(newQty) * Number(editJobCompletionBody[index].qtyUsed) +
                Number(editJobCompletionBody[index].wastage);

            if(editJobCompletionBody[index].totalQtyUsed<=editJobCompletionBody[index].totalQtyUsedFixed){
                editJobCompletionBody[index].stock=editJobCompletionBody[index].stockFixed+(editJobCompletionBody[index].totalQtyUsedFixed-editJobCompletionBody[index].totalQtyUsed)                
            }
                
            setEditJobCompletionBody([...editJobCompletionBody])
            setIsChanged(!isChanged)
        }
        if(key==="tailorCommission"){
            editJobCompletionBody[index].tailorCommission= Number(value)
            setEditJobCompletionBody([...editJobCompletionBody])
            setIsChanged(!isChanged)
        }
        if(key==="qtyUsed"){
            editJobCompletionBody[index].qtyUsed= Number(value)
            editJobCompletionBody[index].totalQtyUsed =
                Number(value) * Number(editJobCompletionBody[index].qty) +
                Number(editJobCompletionBody[index].wastage);

                if (
                    editJobCompletionBody[index].productName?.toLowerCase() !==
                    "out cloth"
                  ) {
                    if (
                      editJobCompletionBody[index].totalQtyUsed >
                      editJobCompletionBody[index].stock
                    ) {
                      editJobCompletionBody[index].qtyUsed = 0;
                      editJobCompletionBody[index].totalQtyUsed = 0;
                      editJobCompletionBody[index].stockBalance =
                        Number(editJobCompletionBody[index].stock) -
                        Number(editJobCompletionBody[index].totalQtyUsed);
                      alert("Used Qty is greater than stock!!");
                      if(editJobCompletionBody[index].totalQtyUsed<=editJobCompletionBody[index].totalQtyUsedFixed){
                        editJobCompletionBody[index].stock=editJobCompletionBody[index].stockFixed+(editJobCompletionBody[index].totalQtyUsedFixed-editJobCompletionBody[index].totalQtyUsed)                
                    }
                    } else {
                      editJobCompletionBody[index].stockBalance =
                        Number(editJobCompletionBody[index].stock) -
                        Number(editJobCompletionBody[index].totalQtyUsed);
                        if(editJobCompletionBody[index].totalQtyUsed<=editJobCompletionBody[index].totalQtyUsedFixed){
                            editJobCompletionBody[index].stock=editJobCompletionBody[index].stockFixed+(editJobCompletionBody[index].totalQtyUsedFixed-editJobCompletionBody[index].totalQtyUsed)                
                        }
                    }
                  } else {
                    editJobCompletionBody[index].stockBalance =
                      Number(editJobCompletionBody[index].stock) -
                      Number(editJobCompletionBody[index].totalQtyUsed);
                      if(editJobCompletionBody[index].totalQtyUsed<=editJobCompletionBody[index].totalQtyUsedFixed){
                        editJobCompletionBody[index].stock=editJobCompletionBody[index].stockFixed+(editJobCompletionBody[index].totalQtyUsedFixed-editJobCompletionBody[index].totalQtyUsed)                
                    }
                  }
            setEditJobCompletionBody([...editJobCompletionBody])
            setIsChanged(!isChanged)
        }
        if(key==="wastage"){
            editJobCompletionBody[index].wastage= Number(value)
            editJobCompletionBody[index].totalQtyUsed =
                Number(editJobCompletionBody[index].qtyUsed) * Number(editJobCompletionBody[index].qty) +
                Number(value);
                if (
                    editJobCompletionBody[index].productName?.toLowerCase() !==
                    "out cloth"
                  ) {
                    if (
                      editJobCompletionBody[index].totalQtyUsed >
                      editJobCompletionBody[index].stock
                    ) {
                      editJobCompletionBody[index].wastage = 0;
                      editJobCompletionBody[index].totalQtyUsed = 0;
                      editJobCompletionBody[index].stockBalance =
                        Number(editJobCompletionBody[index].stock) -
                        Number(editJobCompletionBody[index].totalQtyUsed);
                      alert("Total qty is greater than stock!!");
                      if(editJobCompletionBody[index].totalQtyUsed<=editJobCompletionBody[index].totalQtyUsedFixed){
                        editJobCompletionBody[index].stock=editJobCompletionBody[index].stockFixed+(editJobCompletionBody[index].totalQtyUsedFixed-editJobCompletionBody[index].totalQtyUsed)                
                    }
                    } else {
                      editJobCompletionBody[index].stockBalance =
                        Number(editJobCompletionBody[index].stock) -
                        Number(editJobCompletionBody[index].totalQtyUsed);
                        if(editJobCompletionBody[index].totalQtyUsed<=editJobCompletionBody[index].totalQtyUsedFixed){
                            editJobCompletionBody[index].stock=editJobCompletionBody[index].stockFixed+(editJobCompletionBody[index].totalQtyUsedFixed-editJobCompletionBody[index].totalQtyUsed)                
                        }
                    }
                  } else {
                    editJobCompletionBody[index].stockBalance =
                      Number(editJobCompletionBody[index].stock) -
                      Number(editJobCompletionBody[index].totalQtyUsed);
                      if(editJobCompletionBody[index].totalQtyUsed<=editJobCompletionBody[index].totalQtyUsedFixed){
                        editJobCompletionBody[index].stock=editJobCompletionBody[index].stockFixed+(editJobCompletionBody[index].totalQtyUsedFixed-editJobCompletionBody[index].totalQtyUsed)                
                    }
                  }
            setEditJobCompletionBody([...editJobCompletionBody])
            setIsChanged(!isChanged)
        }
        if(key==="itemName"){
            editJobCompletionBody[index].productName= newValue.productName
            editJobCompletionBody[index].prodId= newValue._id
            setEditJobCompletionBody([...editJobCompletionBody])
            setIsChanged(!isChanged)
            setItemModify(!itemModify)
        }
        if(key==="cuttingMaster"){
            setCutterSelected(newValue)
            setCutterIdArray(newValue.map((r)=>r._id))
            setIsChanged(!isChanged)
        }
        if(key==="tailor"){
            setTailorSelected(newValue)
            setTailorIdArray(newValue.map((r)=>r._id))
            setIsChanged(!isChanged)
        }
    }
    
    //Filter Tailor by designation (TAILOR)
    const filterTailorByDesignation = (fullList) => {
        let _tailors = fullList.filter(
        (obj) =>
        obj?.branchId ===branchId?.storeCode && obj?.designationName?.toLowerCase() === "TAILOR".toLowerCase()
        );
        setTailorList(_tailors);
    };

    //Filter cuttingMaster by designation (CUTTING MASTER)
    const filterCutterByDesignation = (fullList) => {
        let _cutter = fullList.filter(
        (obj) =>
        obj?.branchId ===branchId?.storeCode && obj?.designationName?.toLowerCase() === "CUTTING MASTER".toLowerCase()
        
         );  
        setCutterList(_cutter);
    };
    

    const createSingleDataFrontEnd = (response) => {
        if (response !== undefined) {
          let newArray = response?.map((obj, i) => {
            return {
              ...obj
            };
          });
          console.count("front end array created");
          setEditJobCompletionBody(newArray);
        //   setEditJobCompletionBody(prevData => prevData.map(obj => ({ ...obj, totalQtyUsed: 0 })))
        }
      };


    const submitEditJobCompletionClick=()=>{
        editSingleJobCompletionAPICall(
            editJobCompletionPayload,
            updateListener,
            clearStates,
            setSuccessSnackOpen,
            setErrorSnackOpen,
            setSnackMsg
            )
        console.log(editJobCompletionPayload);
    }

    const updateListener=()=>{
        setIsModify(!isModify)
    }

    const clearStates=()=>{
        setEditJobCompletionPayload(editJobCompletionPayloadInitial)
        setTdDisableClass("disabled-td-job-completion")
        setEditIconDisableClass("")
        setEditEnabled(false)
        setSelectedRowIndex(null)
    }

    console.log(editJobCompletionBody);
    useEffect(()=>{
        if(selectedRowIndex!==null){

            let stockQty=editJobCompletionBody[selectedRowIndex].stock
            let qtyUsed=editJobCompletionBody[selectedRowIndex].qtyUsed
            let itemQty=editJobCompletionBody[selectedRowIndex].completedQty
            let wastage=editJobCompletionBody[selectedRowIndex].wastage

            let calc=stockQty-(qtyUsed*itemQty +wastage)
            setEditJobCompletionBody(prevData => prevData.map(obj => ({ ...obj, totalQtyUsed: qtyUsed*itemQty +wastage })))
            setEditJobCompletionBody(prevData => prevData.map(obj => ({ ...obj, stockBalance: calc })))
            // setStockBalance(calc.toFixed(2))
        }
    },[editJobCompletionPayload,selectedRowIndex])
    
    useEffect(()=>{
        if(editJobCompletionBody[selectedRowIndex]!==undefined &&editJobCompletionBody[selectedRowIndex].tailor !==null){
            let filterArray=editJobCompletionBody[selectedRowIndex].tailor.map((staffName) =>{
                return { staff_name: staffName };
              });
            setTailor(filterArray)
        }
        if(editJobCompletionBody[selectedRowIndex]!==undefined && editJobCompletionBody[selectedRowIndex].cuttingMaster !==null){
            let filterArray=editJobCompletionBody[selectedRowIndex].cuttingMaster.map((staffName) =>{
                return { staff_name: staffName };
              });
            setCutter(filterArray)
        }
    },[selectedRowIndex])

    useEffect(()=>{
        if(utilityJobCompletionlist!==undefined){
            createSingleDataFrontEnd(utilityJobCompletionlist);
        }
        else{
            setEditJobCompletionBody([])
        }
    },[utilityJobCompletionlist])

    useEffect(() => {
        viewAllBranchesAPICall();
        getAllActiveEmployeesAPICall();
        setEditJobCompletionBody([])
      },[]);

    useEffect(()=>{
        if(branchId!==null){
            getUtilityJobCompletionWorkOrderAPICall({branchId:branchId?._id})
        }
    },[branchId])
    useEffect(()=>{
        if(orderId!==null){
            jobCompletionOrderSubmit()
        }
    },[isModify])

    useEffect(()=>{
        let filterTailorArray=[]
        let filterCutterArray=[]
        if(tailorList!==null ){
            filterTailorArray=tailorList.filter((t)=>{
                return tailor.some((tl)=>t.staff_name?.toLowerCase()===tl.staff_name?.toLowerCase())
            }
            )
            setTailorSelected(filterTailorArray)
            setTailorIdArray(filterTailorArray.map((r)=>r._id))
        }
        if(cutterList!==null){
            filterCutterArray=cutterList.filter((t)=>{
                return cutter.some((tl)=>t.staff_name?.toLowerCase()===tl.staff_name?.toLowerCase())
            }
            )
            setCutterSelected(filterCutterArray)
            setCutterIdArray(filterCutterArray.map((r)=>r._id))
        }
    },[tailor,cutter])

    // useEffect(()=>{

    //     if(selectedRowIndex!==null){
    //         let filteredArray=[...editJobCompletionBody]
    //         filteredArray[selectedRowIndex].tailor= tailorIdArray
    //         setEditJobCompletionBody(filteredArray)
    //     }
    //     if(selectedRowIndex!==null){
    //         let filteredArray=[...editJobCompletionBody]
    //         filteredArray[selectedRowIndex].cuttingMaster= cutterIdArray
    //         setEditJobCompletionBody(filteredArray)
    //     }
    // },[tailorIdArray,cutterIdArray])

    useEffect(()=>{
        if(selectedRowIndex!==null){
        setEditJobCompletionPayload({...editJobCompletionPayload,orderId:editJobCompletionBody[selectedRowIndex].workorderId,
            rack:editJobCompletionBody[selectedRowIndex].rack,
            items:[
                {
                    id:editJobCompletionBody[selectedRowIndex].itemId,
                    itemId: editJobCompletionBody[selectedRowIndex].prodId,
                    uuid: editJobCompletionBody[selectedRowIndex].uuid,
                    completedQty: editJobCompletionBody[selectedRowIndex].completedQty,
                    tailor: tailorIdArray,
                    cuttingMaster: cutterIdArray,
                    tailorCommission:editJobCompletionBody[selectedRowIndex].tailorCommission ,
                    materialQty: editJobCompletionBody[selectedRowIndex].qtyUsed,
                    wastage:editJobCompletionBody[selectedRowIndex].wastage,
                    totalQtyUsed:editJobCompletionBody[selectedRowIndex].totalQtyUsed,
                    jobCompletionDate:`${dateToday.today} ${getCurrentTime()}`
                }
            ],
            branchId:branchId?._id,
            date:`${dateToday.today} ${getCurrentTime()}`        
        })}
    },[selectedRowIndex,isChanged,tailorIdArray])

    useEffect(()=>{
        if(editJobCompletionPayload?.orderId!==""){
            // editSingleJobCompletionAPICall(editJobCompletionPayload,updateListener)
            console.log(editJobCompletionPayload);
            let filterStock=materialProductList.filter((obj)=>
                obj._id===editJobCompletionBody[selectedRowIndex].prodId
            )

            editJobCompletionBody[selectedRowIndex].stock= filterStock[0].closingStock
            editJobCompletionBody[selectedRowIndex].stockFixed= editJobCompletionBody[selectedRowIndex].stock
            setEditJobCompletionBody([...editJobCompletionBody])

            if(editJobCompletionBody[selectedRowIndex].totalQtyUsed>filterStock[0].closingStock){
                editJobCompletionBody[selectedRowIndex].wastage = 0;
                editJobCompletionBody[selectedRowIndex].totalQtyUsed = 0;
                editJobCompletionBody[selectedRowIndex].qtyUsed = 0;
                editJobCompletionBody[selectedRowIndex].stockBalance =
                  filterStock[0].closingStock -
                  Number(editJobCompletionBody[selectedRowIndex].totalQtyUsed);
                alert("Total qty is greater than stock!!");
            }
        }
    },[itemModify])
    console.log(editJobCompletionPayload);
    return(
        <>
        <div className="utility-job-completion-container" id="utility-job-completion">
            <div className="utility-job-completion-container-top">
              <div className="utility-job-completion-container-top-left">
                <div className="global-single-input auto-complete">
                    <p>Branch Name</p>
                    <Autocomplete
                    sx={{width:"100%"}}
                    options={listOfBranchesStockOut || [{branchName:"No Branches Available"}]}
                    getOptionLabel={(option) => option?.branchName}  
                    renderInput={(params) => <TextField {...params}
                    placeholder="Select Branch"
                    />}
                    onChange={(e,newValue)=>setBranchId(newValue)}
                    />
                </div>
                <div className="global-single-input auto-complete">
                    <p>Order ID</p>
                    <Autocomplete
                    sx={{width:"100%"}}
                    options={woNosForJobCompletion ||[{orderNo:"No data"}]}
                    getOptionLabel={(option) => option?.orderNo}  
                    renderInput={(params) => <TextField {...params}
                    placeholder="Select ID"
                    />}
                    onChange={(e,newValue)=>setOrderId(newValue?._id)}
                    />
                </div>
              </div> 
              <button className="btn btn-primary" onClick={jobCompletionOrderSubmit}>Submit</button> 
            </div>
        </div>
            <div className="global-table-container job-completion-table">
                <table className="global-table">
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Type</th>
                            <th>Item</th>
                            <th>Item Qty</th>
                            <th>Completed Qty</th>
                            <th>Model</th>
                            <th>Length</th>
                            <th>Amount</th>
                            <th>Tailor Com.</th>
                            <th>Tailor</th>
                            <th>Cutter</th>
                            <th>Stock</th>
                            <th>Qty used</th>
                            <th>Wastage</th>
                            <th>Stock Balance</th>
                            <th>Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            editJobCompletionBody?.length!==0?
                            editJobCompletionBody?.map((r,i)=>
                            <tr className={i === selectedRowIndex ? '' : 'disabled-td-job-completion'} key={i}>
                            <td>{i+1}</td>
                            <td>{r?.type}</td>
                            {
                            selectedRowIndex===i?                            
                            <td className="job-completion-table-select auto-complete">
                                <Autocomplete 
                                    sx={{width:"100%"}}
                                    defaultValue={{productName:r.productName}}
                                    options={materialProductList || [{productName:"No items"}]}
                                    getOptionLabel={(option) => option?.productName}  
                                    renderInput={(params) => <TextField {...params}
                                    placeholder="Select item ..."
                                    />}
                                    onChange={getEditJobCompletionForm(i,"itemName")}
                                    />
                            </td>
                            :
                            <td>{r?.productName}</td>
                                }                            
                            <td>{r?.qty}</td>
                            {
                                selectedRowIndex===i?
                                <td className="job-completion-table-used-qty">
                                    <input 
                                    name="quantity"
                                    type="number"
                                    min={"0"}
                                    value={r?.completedQty}
                                    onChange={getEditJobCompletionForm(i,"quantity")}
                                    />
                                </td>:
                                <td>{r?.completedQty}</td>
                            }
                            <td>{r?.model}</td>
                            <td>{r?.length}</td>
                            <td>{r?.amount}</td>
                            {
                                selectedRowIndex===i?
                                <td className="job-completion-table-used-qty">
                                    <input type="number" 
                                    value={r?.tailorCommission}
                                    onChange={getEditJobCompletionForm(i,"tailorCommission")}
                                    />
                                </td>:
                                <td>{r?.tailorCommission}</td>
                            }
                            {
                                selectedRowIndex===i?
                                <td className="job-completion-table-select auto-complete">
                                    <Autocomplete 
                                        multiple
                                        size="medium"
                                        sx={{width:"100%",marginLeft:'8%'}}
                                        options={tailorList || [{ staff_name: "No data" }]}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option?.staff_name}  
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                              <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                              />
                                              {option?.staff_name}
                                            </li>
                                          )}
                                        renderInput={(params) => <TextField {...params}
                                        />}
                                        value={tailorSelected}
                                        onChange={getEditJobCompletionForm(i,"tailor")}
                                        />
                                </td>:
                                <td>{
                                    r?.tailor!==null&&
                                    r?.tailor.map((r,i)=>
                                    <p>{r}</p>
                                    )}
                                </td>
                            }
                            {
                                selectedRowIndex===i?
                                <td className="job-completion-table-select auto-complete">
                                    <Autocomplete 
                                     multiple
                                     size="medium"
                                     sx={{width:"100%"}}
                                     options={cutterList || [{staff_name:"No data"}]}
                                     disableCloseOnSelect
                                     getOptionLabel={(option) => option?.staff_name}
                                     renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option?.staff_name}
                                        </li>
                                      )}  
                                     renderInput={(params) => <TextField {...params}
                                     />}
                                     value={cutterSelected}
                                     onChange={getEditJobCompletionForm(i,"cuttingMaster")}
                                     />
                                </td>:
                                <td>{r?.cuttingMaster!==null && r?.cuttingMaster.map((r,i)=>
                                        <p>{r}</p>
                                    )}
                                </td>
                            }
                                <td>{r?.stock.toFixed(2)}</td>
                            {
                                selectedRowIndex===i?
                                <td className="job-completion-table-used-qty">
                                    <input type="number" 
                                    value={r?.qtyUsed}
                                    onChange={getEditJobCompletionForm(i,"qtyUsed")}
                                    />
                                </td>:
                                <td>{r?.qtyUsed}</td>
                            }
                            {
                                selectedRowIndex===i?
                                <td className="job-completion-table-used-qty">
                                    <input type="number" 
                                    value={r?.wastage}
                                    onChange={getEditJobCompletionForm(i,"wastage")}
                                    />
                                </td>:
                                <td>{r?.wastage}</td>
                            }
                            {
                                selectedRowIndex===i?
                                <td className="job-completion-table-used-qty">
                                <input type="number" 
                                        value={r?.stockBalance}
                                        disabled
                                        />
                                </td>:
                                <td>{r?.stock.toFixed(2)}</td>
                                    }
                            <td>{convertDateFormat(r?.date)}</td>
                            <td>{      
                                selectedRowIndex===i?                          
                                <IconButton onClick={submitEditJobCompletionClick}>
                                    <i className={`bi bi-check2-square edit-icon`}></i>
                                </IconButton>:
                                <IconButton onClick={editJobCompletionClick(i)} disabled={editEnabled?true:false}>
                                    <i className={`bi bi-pencil-square  edit-icon ${editIconDisableClass}`}></i>
                                </IconButton>
                                }
                            </td>
                        </tr> )
                        : <tr>
                            <td colSpan={17}>No Data</td>
                        </tr>
                        }
                    </tbody>
                </table>

                <div className="utility-job-completion-btn-ctn">
                    <button className="btn btn-secondary-outlined"onClick={submitJobCompletionClick}>Cancel</button>
                    {/* <button className="btn btn-primary" onClick={submitJobCompletionClick}>Submit</button> */}
                </div>
            </div>
            <SuccessSnackbar
            open={successSnackOpen}
            handleClose={closeSuccessSnack}
            message={snackMsg}
            />
            <ErrorSnackbar
            open={errorSnackOpen}
            handleClose={closeErrorSnack}
            message={snackMsg}
            />
            </>
    )
}



// autocomplete mui



// const [cusName, setCusName] = useState('');
// const [suggestions, setSuggestions] = useState([]);
// const [showSuggestions, setShowSuggestions] = useState(false);

// const handleCusNameChange = (event, newValue) => {
//     console.log(newValue);
//     if(newValue!==null){
//         setShowSuggestions(false)
//         setCusName(newValue.branchName);
//     }
//     else{
//         setCusName(event.target.value)
//         setShowSuggestions(true)
//     }
//   };

// const handleTextFieldChange=(e)=>{
//     setCusName(e.target.value)
//     if(e.target.value==="" || suggestions.length===0){
//         setShowSuggestions(false)
//     }
//   }
// const handleInputChange=(e,newValue)=>{
//         console.log(newValue);
//         let filteredSuggestions=listOfBranchesStockOut.filter((obj)=>
//             obj.branchName.toLowerCase().includes(newValue.toLowerCase()));
//         setSuggestions(filteredSuggestions);
        
//         setShowSuggestions(newValue !=='');
    
// }
// <div style={{width:"200px"}}>
//             <Autocomplete
//                 // id="customer-name"
//                 options={suggestions || [{branchName:"nOO"}]}
//                 getOptionLabel={(option) => option?.branchName}
//                 // inputValue={cusName}
//                 onInputChange={handleInputChange}
//                 open={showSuggestions}
//                 onChange={handleCusNameChange}
//                 getOptionSelected={(option, value) => option.branchName === value.branchName}
//                 renderInput={(params) => (
//                 <TextField
//                     {...params}
//                     label="enter"
//                     variant="outlined"
//                     size="small"
//                     value={cusName}
//                     onChange={handleTextFieldChange}
//                     style={{ marginBottom: 16 }}
//                 />
//                 )}
//             />

//             </div>