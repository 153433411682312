import { IconButton, MenuItem, Select, Skeleton } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllDepartmentAPICall } from "../../../../../API/Staff/HRM/departmentAndDesignationAPI";
import { getAllActiveEmployeesAPICall } from "../../../../../API/Staff/HRM/employeeAPI";
import "../../../../../css/Staff/HRM/ManageEmployee/manageEmployee.css";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import { convertDateFormat } from "../../../../../Js/Date";
export const ManageEmployee = () => {
  //*Redux State
  //Department List
  const allDepartmentList = useSelector(
    (state) => state.allDepartmentSlice.value
  );
  //Active Employee List
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );

  const navigate = useNavigate();
  const [selectDpt, setSelectDpt] = useState("0");
  const [searchKeyword, setSearchKeyword] = useState("");
  //Loading State
  const [isLoading, setIsLoading] = useState(false);
  //Filtered Employee State
  const [filteredEmployeeList, setFilteredEmployeeList] = useState([]);
  const [searchEmployeeList, setSearchEmployeeList] = useState([]);
  const [errorSnackbar,setErrorSnackbar]=useState(false)
  const [snackMsg,setSnackMsg]=useState('Error!!')
  //Navigate to Employee Dashboard
  const employeeSingleView = (empId) => () => {
    localStorage.setItem("EMP_ID", empId);
    navigate("/userdashboard/staff/hrm/manageEmployee/viewEmployee");
  };
  const closeErrorSnackbar=()=>{
    setErrorSnackbar(false)
  }
  //Get search keyword
  const getSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  };

  // search function
  const filterEmployeeBySearch=(chars,listToFilter) =>{
    let searchResult= [];
    let filteredArray= [];

    if(chars !== "") {
       searchResult =listToFilter?.filter(
          (obj) =>
          obj?.staff_name?.toLowerCase()?.includes(chars?.toLowerCase()) ||
          obj?.emp_id?.toLowerCase()?.includes(chars?.toLowerCase()) ||
          obj?.branchId?.toLowerCase()?.includes(chars?.toLowerCase()) ||
          obj?.contactnumber?.toLowerCase()?.includes(chars?.toLowerCase()) ||
          obj?.branchName?.toLowerCase()?.includes(chars?.toLowerCase()) 
       );
       filteredArray =searchResult;
    } else {
       filteredArray =listToFilter;
    }
    return filteredArray;
 }
  //Initial API Calls
  useEffect(() => {
    getAllDepartmentAPICall();
    getAllActiveEmployeesAPICall(
      setIsLoading,
      setErrorSnackbar,
      setSnackMsg
    );
  },[]);

  //Call Filter by dpt fn
  useEffect(() => {
    filterEmployeeByDpt();
  }, [selectDpt]);

  //assign emp response to state
  useEffect(() => {
    setFilteredEmployeeList(allActiveEmpList);
  }, [allActiveEmpList]);

  //filter function
  const filterEmployeeByDpt = () => {
    let filteredEmpList = [];
    if (selectDpt !== "0") {
      filteredEmpList = allActiveEmpList?.filter(
        (obj) => obj?.department === selectDpt
      );
      setFilteredEmployeeList(filteredEmpList);
    } else {
      if (allActiveEmpList !== undefined) {
        setFilteredEmployeeList(allActiveEmpList);
      }
    }
  };

  useEffect(()=>{
    if( filteredEmployeeList!==undefined &&filteredEmployeeList.length!==0){
      setSearchEmployeeList(filteredEmployeeList)
      let filteredArray=filterEmployeeBySearch(searchKeyword,filteredEmployeeList)
      setSearchEmployeeList(filteredArray)
    } else {
      setSearchEmployeeList([])
    }
  },[searchKeyword,filteredEmployeeList])
  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">Staff &gt; HRM &gt; Manage Employee </p>
      <div className="manage-employee-container">
        <div className="manage-employee-top-container" style={{alignItems:"center"}}>
          <h3>Employee List</h3>
          <div style={{width:"60%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Select
              sx={{
                "& .MuiSvgIcon-root": {
                  color: "#fff",
                },
              }}
              value={selectDpt}
              onChange={(e) => setSelectDpt(e.target.value)}
              style={{width:"30%"}}
            >
              <MenuItem value="0">All Department</MenuItem>
              {allDepartmentList?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.departmentName}
                </MenuItem>
              ))}
            </Select>
            <div className="view-order-search-container view-order-admin-search-container" style={{width:"60%"}}>
              <input type="text" 
                placeholder="Search Employee name,employee id,mobile.."
                value={searchKeyword}
                onChange={getSearchKeyword}
              />
              <IconButton>
                <i className="bi bi-search search-icon-order"></i>
              </IconButton>
            </div>
          </div>
        </div>
        <div className="global-table-container manage-employee-table">
          <table className="global-table ">
            <thead>
              <tr>
                <th>No</th>
                <th>Employee ID</th>
                <th>Branch</th>
                <th>Name</th>
                <th>Image</th>
                <th>Phone Number</th>
                <th>Joining Date</th>
                <th>Designation</th>
                <th>Department</th>
                <th>Default Doc</th>
                <th>Expiry Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            {
              isLoading?
              <tbody>
                <tr>
                  <td colSpan={15}>
                      {/* <Loader /> */}
                      <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                      />
                  </td>
                  </tr>
                  <tr>
                  <td colSpan={15}>
                      {/* <Loader /> */}
                      <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                      />
                  </td>
                  </tr>
                  <tr>
                  <td colSpan={15}>
                      {/* <Loader /> */}
                      <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                      />
                  </td>
                  </tr>
                  <tr>
                  <td colSpan={15}>
                      {/* <Loader /> */}
                      <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                      />
                  </td>
                  </tr>
                  <tr>
                  <td colSpan={15}>
                      {/* <Loader /> */}
                      <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                      />
                  </td>
                  </tr>
                  <tr>
                  <td colSpan={15}>
                      {/* <Loader /> */}
                      <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                      />
                  </td>
                  </tr>
                  <tr>
                  <td colSpan={15}>
                      {/* <Loader /> */}
                      <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                      />
                  </td>
                  </tr>
                  <tr>
                  <td colSpan={15}>
                      {/* <Loader /> */}
                      <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                      />
                  </td>
                  </tr>
                  <tr>
                  <td colSpan={15}>
                      {/* <Loader /> */}
                      <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                      />
                  </td>
                  </tr>
                  <tr>
                  <td colSpan={15}>
                      {/* <Loader /> */}
                      <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                      />
                  </td>
                  </tr>

              </tbody>
              :
              <tbody>
                {searchEmployeeList?.length > 0 ? (
                  searchEmployeeList?.map((r, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{r?.emp_id}</td>
                      <td>{`${r?.branchId}-${r?.branchName}`}</td>
                      <td>{r?.staff_name}</td>
                      <td>
                        <img
                          src={r?.imageUrl}
                          alt="emp image"
                          className="emp-table-img"
                          />
                      </td>
                      <td>{r?.contactnumber}</td>
                      <td>{convertDateFormat(r?.date_of_join)}</td>
                      <td>{r?.designationName}</td>
                      <td>{r?.departmentName}</td>
                      <td>{r?.documents===null?"-NIL-":r?.documents[0].documentType}</td>
                      <td>{r?.documents===null?"-NIL-":convertDateFormat(r?.documents[0].expiryDate)}</td>
                      <td>
                        {r?.status ?<p className="manage-employee-table-active-emp">Active</p>:<p className="manage-employee-table-inactive-emp">Inactive</p>}
                      </td>
                      <td>
                        <IconButton onClick={employeeSingleView(r?._id)}>
                          <i class="bi bi-eye visibility-icon"></i>
                        </IconButton>
                     
                        <IconButton onClick={employeeSingleView(r?._id)}>
                          <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton>
                      
                        <IconButton>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  ))
                  ) : (
                    <tr>
                    <td colSpan={13}>No Employee</td>
                  </tr>
                )}
              </tbody>
            }
          </table>
        </div>
      </div>

      <ErrorSnackbar
      open={errorSnackbar}
      handleClose={closeErrorSnackbar}
      message={snackMsg}
      />
    </div>
  );
};
