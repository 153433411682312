import React from 'react'

const BankAndCashPrint = (props) => {
    const { data, formValues } = props
  const decimalPosition = localStorage.getItem("decimalPosition");
 console.log(data);
  return (
    <div className="print-main-div">
      <div className='header-div'>
        <div>
          <img src={formValues?.companyLogo} alt="" style={{height:"62px"}}/>
        </div>
        <div>
          <h1 style={{ fontSize: "larger", margin: "0" }}>{formValues?.label}</h1>
          {/* <p style={{margin:"0"}}>aaaaaaa</p> */}
        </div>
        <div>
          <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1>
          <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyName}</p>
          <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyAddress}</p>
          <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyContact}</p>
        </div>
      </div>

      <div className="global-report-table-container" style={{ overflow: "visible" }} >
        <table>
          <thead>
            <tr>
              <th>customer</th>
              <th style={{ width: "22%" }}>Debit</th>
              <th style={{ width: "22%" }}>Credit</th>
              <th style={{ width: "22%" }}>Balance</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index} >
                <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.accountName}</td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.debit }</td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.credit}</td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.balance}</td>
              </tr>
            ))}
          </tbody>
          
         
        </table>
         <div className='div-table-total'>
            
              <p style={{width:"32%"}}>Total</p>
              <p>{formValues?.totalDebit}</p>
              <p>{formValues?.totalCredit}</p>
              <p>{formValues?.totalBalance}</p>
           
          </div>
      </div>
    </div>
  )
}

export default BankAndCashPrint