import { createSlice } from "@reduxjs/toolkit";

export const getDiscountSlice=createSlice({
    name:'discountSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_discount:(state,action)=>{
            state.value=action.payload.getDiscountData
        }
    }
})
export const {get_discount}=getDiscountSlice.actions;
export default getDiscountSlice.reducer;
