import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { BranchTransferFilterAPICall, viewAllBranchTransferAPICall } from './BranchTransferAPI';
import store from '../../../../../Redux/store';
import { get_branch_transfer_single_view } from './BranchTransferSlice';
import { Skeleton } from '@mui/material';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import CustomPagination from '../../../../Single Components/CustomPagination';
import { getCurrentTime } from '../../../../../Js/Date';
import axios from 'axios';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';

export const BranchTransfer = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  let navigate = useNavigate();
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const branchTransferList = useSelector((state) => state.branchTransferSlice.value)
  const createBranchTransfer = () => {
    navigate("/userdashboard/inventory/operation/branchtransfer/create")
  }
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = branchTransferList?.pages;
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [selectedBranch, setSelectedBranch] = useState(null);
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  useEffect(() => {
    // viewAllBranchTransferAPICall({ branchId: loginResponse?.data?.branchId }, setIsLoading)
    store.dispatch(get_branch_transfer_single_view({ singleBranchTransferData: undefined }))
    localStorage.removeItem("singleViewById");
  }, [])

  const singleViewFn = (data) => {
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem('singleViewById', data._id);
    navigate("/userdashboard/inventory/operation/branchtransfer/create")
    // store.dispatch(get_branch_transfer_single_view({singleBranchTransferData:data}))
  }
  useEffect(() => {
  
    if(FormDataInfo.length!==0){ 
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&  BranchTransferFilterAPICall({
    // fromDate: `${FormDataInfo.fromDate} ${getCurrentTime()}`,
    // toDate: `${FormDataInfo.toDate} ${getCurrentTime()}`,
    sourcebranchId: FormDataInfo.fromBranch,
    destinationBranchId: FormDataInfo.destinationBranch,
    status: FormDataInfo.status,
    search: FormDataInfo.search,
    index: currentPage-1,
  },setIsLoading);
}

}, [FormDataInfo, currentPage]);
  return (
    <div className='global-page-parent-container'>
      {/* table */}
      <div className='global-white-bg-container'>
        <div className="justify-space-between"
          style={{ gap: "0 12px", justifyContent: "flex-end" }}
        >
          <CategorySearchandFilter
            statusOptions={['Completed', 'Drafted','INPROGRESS','Cancelled','REQUESTED','APPROVED']}
            onData={handleDataFromChild}
            isStatus={true}
            isFromBranch={true}
            isDestinationBranch={true}
            isBranch={false}
            isDate={false}
            onBranchSelect={handleBranchSelect}
          />
          <button onClick={createBranchTransfer} className='create-button-blue'>Create</button>

        </div>
        <div className='create-button-blue-container'>
          <h3>Branch Transfer</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
          {/* <button onClick={createBranchTransfer}  className='create-button-blue'>Create</button> */}
        </div>
        <div className='new-global-table-container'>
          <table>
            <thead>
              <tr>

                <th>Name</th>
                <th>From</th>
                <th>To</th>
                <th>Contact Person</th>
                <th>GRN</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody >
              {

                isLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={8}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) :

                  branchTransferList?.list?.length !== 0 ? branchTransferList?.list?.slice(0)?.map((r, i) => {
                    if (r?.status !== "Available") {
                      return (
                        <tr onClick={() => singleViewFn(r)}>

                          <td>{r?.transNo}</td>
                          <td>{r?.sourcename}</td>
                          <td>{r?.destinationname}</td>
                          <td>{r?.contactPerson}</td>
                          <td>{r?.grnNumber}</td>
                          <td className='table-data-box'>
                            {r?.status === "Completed" ? (
                              <span className='post'> POST</span>
                            ) : r?.status === "Drafted" ? (
                              <span className='draft'> DRAFT</span>
                            ) : r?.status === "INPROGRESS" ? (
                              <span className="inprogress"> IN PROGRESS</span>
                            ) : r?.status === "Cancelled" ? (
                              <span className="cancel"> CANCEL</span>
                            ) : r?.status === "REQUESTED" ? (
                              <span className="requested"> REQUESTED</span>
                            ) : r?.status === "APPROVED" ? (
                              <span className="granted"> GRANTED</span>
                            )
                              :
                              (
                                <span>{r?.status}</span>
                              )}
                          </td>
                        </tr>
                      )
                    }

                  })
                    :
                    <tr>
                      <td colSpan={7}>No Data</td>
                    </tr>
              }

            </tbody>

          </table>
        </div>
      </div>

    </div>
  )
}
