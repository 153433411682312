import {
  Dialog,
  IconButton,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { viewAllCustomersAPICall } from "../../../../API/Customer/customerAPI";
import {
  verifyPasswordAPICall,
  verifyPasswordForShiftAPICall,
} from "../../../../API/Login/VerifyPasswordAPI";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { viewShiftAPICall } from "../../../../API/Settings/Shift/viewShiftAPI";
import {
    addShiftTransferAPICall,
  addShiftTransferPaymentAPICall,
  shiftTransferOrderListAPICall,
  shiftTransferOrdersInvoiceNoAPICall,
} from "../../../../API/Settings/Utility/ShiftTransfer/shiftTransferAPI";
import "../../../../css/Settings/Utility/utilityShifttransfer.css";
import { getCurrentTime, today } from "../../../../Js/Date";
import { get_shiftTransfer_dialog } from "../../../../Redux/Settings/Utility/shiftTransferReopenDialogSlice";
import Store from "../../../../Redux/store";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";

export const UtilityShiftTransfer = () => {
  // open password dialog
  const shiftTransferPasswordDialog = useSelector(
    (state) => state.shiftTransferDialog.value
  );
  // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // get all order list bu order type
  const allOrderList = useSelector(
    (state) => state.shiftTransferOrderList.value
  );
  //List of All Customers
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  // get all invoice number of all orders
  const InvoiceNoList = useSelector(
    (state) => state.shiftTransferOrderListInvoiceNo.value
  );
  // all shift list
  const allShiftList = useSelector((state) => state.viewShiftSlice.value);

  //Store code
  const storeCode=localStorage.getItem('branchId')

  // const [shiftPassword,setShiftPassword]=useState(true)
  const [shiftTransfer, setShiftTransfer] = useState(false);
  const [searchDocument, setSearchDocument] = useState(false);
  const [searchShift, setSearchShift] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  // snackbar state
  const [errorSnack, setErrorSnack] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error!!");

  // shift transfer initial form
  const shiftTransferInitialForm = {
    branch: null,
    type: null,
    transferDoc: null,
    trnsferTo: null,
  };
  const [shiftTransferForm, setShiftTransferForm] = useState(
    shiftTransferInitialForm
  );
  const filterFormInitialState = {
    customerName: null,
    contactNo: "",
    date: "",
    invoiceNo: null,
  };
  const [filterForm, setFilterForm] = useState(filterFormInitialState);
  // map order list response to state
  const [orderListArray, setOrderListArray] = useState([]);
  const [allCheckState, setAllCheckState] = useState(false);
  const [filteredArray, setFilteredArray] = useState([]);
  const [filteredArrayPayment,setFilteredArrayPayment]=useState([])

  // map shift response to state
  const [shiftArray, setShiftArray] = useState([]);
  const [shiftRadio, setShiftRadio] = useState();
  const [isModify,setIsModify]=useState(false)

  const updateListner=()=>{
    setIsModify(!isModify)
  }

  const orderTypes = [
    {
      orderType: "WorkOrder",
      value: 0,
    },
    {
      orderType: "Alteration",
      value: 1,
    },
    {
      orderType: "Sales Order",
      value: 2,
    },
    {
      orderType: "Delivery",
      value: 3,
    },
    {
      orderType: "Payments",
      value: 4,
    }
  ];

  // snackbar function
  const errorSnackbarClose = () => {
    setErrorSnack(false);
  };

  // api call
  useEffect(() => {
    viewAllBranchesAPICall();
    viewAllCustomersAPICall({branchId:storeCode});
  }, []);

  // close shift transfer password dialog
  const closePasswordDialog = () => {
    Store.dispatch(
      get_shiftTransfer_dialog({ shiftTransferDialogData: false })
    );
  };

  const ClickShiftPasswordSubmitBtn = () => {
    verifyPasswordForShiftAPICall(
      { password: passwordInput },
      setErrorSnack,
      setErrorMsg,
      passwordSubmitBtnStateFn
    );
    // Store.dispatch(get_shiftTransfer_dialog({shiftTransferDialogData:false}))
    // setShiftTransfer(true)
  };
  // password dialog close function when submit btn click
  const passwordSubmitBtnStateFn = () => {
    Store.dispatch(
      get_shiftTransfer_dialog({ shiftTransferDialogData: false })
    );
    setShiftTransfer(true);
  };

  // password dialog close fn
  const passwordDialogCloseFn = () => {
    Store.dispatch(
      get_shiftTransfer_dialog({ shiftTransferDialogData: false })
    );
  };
  // shift transfer submit btn fn
  const ClickShiftTransfer = () => {
    Store.dispatch(
      get_shiftTransfer_dialog({ shiftTransferDialogData: false })
    );
    setShiftTransfer(false);
  };
  // close shift transfer
  const cancelShiftTransfer = () => {
    setShiftTransfer(false);
    Store.dispatch(
      get_shiftTransfer_dialog({ shiftTransferDialogData: false })
    );
  };

  // search document dialog open function
  const openSearchDocumentDialog = () => {
    setSearchDocument(true);
  };
  // search document dialog close function
  const closeSearchDocument = () => {
    setSearchDocument(false);
  };

  // OPEN search shift dialog
  const openSearchShiftDialog = () => {
    setSearchShift(true);
  };

  // search shift cancel btn fn
  const closeSearchShiftDialog = () => {
    setSearchShift(false);
  };

  // onChange function
  const getPassword = (e) => {
    setPasswordInput(e.target.value);
  };
  // visibility function
  const visibilityFunction = () => {
    setIsVisible(!isVisible);
  };

  // order list api call
  useEffect(() => {
    shiftTransferOrderListAPICall({
      type: shiftTransferForm.type?.value,
      storeCode: shiftTransferForm.branch?.storeCode,
    });
    shiftTransferOrdersInvoiceNoAPICall({
      type: shiftTransferForm.type?.value,
      storeCode: shiftTransferForm.branch?.storeCode,
    });
  }, [shiftTransferForm.type, shiftTransferForm.branch]);

  // search document search button function
  const shiftOrderListSearchBtnFn = () => {
    shiftTransferOrderListAPICall({
      type: shiftTransferForm.type?.value,
      storeCode: shiftTransferForm.branch?.storeCode,
      customer: filterForm.customerName,
      mobileNo: filterForm.contactNo === "" ? null : filterForm.contactNo,
      date: filterForm.date === "" ? null : filterForm.date,
      invoiceNo: filterForm.invoiceNo,
    });
  };

  // view shift api call
  useEffect(() => {
    viewShiftAPICall({ branchId: shiftTransferForm.branch?.storeCode });
  }, [shiftTransferForm.branch]);

  // map order list response to state
  useEffect(() => {
    if (allOrderList !== undefined) {
      setOrderListArray(
        allOrderList?.map((obj) => {
          return {
            ...obj,
            isChecked: false,
          };
        })
      );
    }
  }, [allOrderList]);

  // checkbox click function
  const checkBoxCheckfn = (index) => () => {
    orderListArray[index].isChecked = !orderListArray[index].isChecked;
    setOrderListArray([...orderListArray]);
    filterFunction();
  };
  // check all check box function
  const checkAllFunction = () => {
    orderListArray?.forEach((obj) => (obj.isChecked = !obj.isChecked));
    setOrderListArray([...orderListArray]);
    setAllCheckState(!allCheckState);
  };

 
  

  const filterFunction = () => {
    let filteredData = orderListArray?.filter((obj) => obj.isChecked === true);
    if (filteredData?.length === orderListArray?.length) setAllCheckState(true);
    else setAllCheckState(false);

    setFilteredArray([...filteredData]);
  };
  //  search document submit button function

  const searchDocumentSubmitBtnFn = () => {
    setSearchDocument(false);
  };

  // search shift .. map response to state
  useEffect(() => {
    if (allShiftList !== undefined) {
      setShiftArray(
        allShiftList?.map((obj) => {
          return { ...obj, isChecked: false };
        })
      );
    }
  }, [allShiftList]);

  
const radioChechedFn=(e)=>{
  if(e.target.checked){
    setShiftRadio(e.target.value)
  }
}

//   // submit button function
  const searchShiftSubmitBtnFn = () => {
    setSearchShift(false);
  };


  useEffect(()=>{
      setFilteredArrayPayment(
        filteredArray?.map((r)=>{
          return{
            _id:r?._id,
            uuid:r?.uuid
          }
        })
      )
  },[filteredArray])


  const bodyToApi = {
    branchId:shiftTransferForm.branch?.storeCode,
    orderType:shiftTransferForm.type?.value,
    orderId:filteredArray.map((obj=>obj._id)),
    transferedShift:parseFloat(shiftRadio),
    date:`${today} ${getCurrentTime()}`,
  };

  // shift transfer payment body
  const bodyToApiPayment={
    paymentInfo:filteredArrayPayment,
    shiftId:parseFloat(shiftRadio)
  }
  // final submit function
  const ClickShiftTransferFinalSubmit = () => {
    // console.log(bodyToApi);
    // addShiftTransferAPICall(bodyToApi,updateListner,clearState,setShiftTransfer)
    // console.log(bodyToApiPayment);

    filteredArray?.map((obj=>{
      if(obj.uuid === "no uuid"){
        return (
          // console.log(bodyToApi)
          addShiftTransferAPICall(bodyToApi,updateListner,clearState,setShiftTransfer)
        )
      }
      else{
        // console.log(bodyToApiPayment);
        addShiftTransferPaymentAPICall(bodyToApiPayment,updateListner,clearState,setShiftTransfer)
      }
    }))

    }

  const clearState=()=>{
    setShiftRadio()
    setFilteredArray([])
    setShiftTransferForm({...shiftTransferForm,branch:null,type:null})
    setAllCheckState(false)
  }

  console.log(filteredArray);



  return (
    <>
      <Dialog
        open={shiftTransferPasswordDialog}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && closePasswordDialog()}
      >
        <div className="utility-shift-password-container">
          <h4>Enter Password</h4>
          <div className="global-single-input utility-shift-password-input outlet-expense-password-input">
            <p>Password</p>
            <input
              type={isVisible ? "text" : "password"}
              value={passwordInput}
              onChange={getPassword}
              onKeyDown={(e) =>
                e.key === "Enter"
                  ? ClickShiftPasswordSubmitBtn()
                  : e.key === "Escape"
                  ? () => passwordDialogCloseFn()
                  : undefined
              }
            />

            <div className="password-visibility-icon">
              <IconButton onClick={visibilityFunction}>
                {isVisible ? (
                  <i class="bi bi-eye-slash"></i>
                ) : (
                  <i class="bi bi-eye"></i>
                )}
              </IconButton>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              className="btn btn-secondary-outlined utility-shift-button"
              onClick={passwordDialogCloseFn}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary utility-shift-button"
              onClick={ClickShiftPasswordSubmitBtn}
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={shiftTransfer}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setShiftTransfer(false)}
      >
        <div className="utility-shift-transfer-container">
          <h4>Shift Transfer</h4>
          <div style={{ display: "flex" }}>
            <div className="global-single-input utility-shift-transfer-input auto-complete">
              <p>Branch</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={allBranchesList || [""]}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Branch" />
                )}
                value={shiftTransferForm?.branch}
                onChange={(e, newValue) =>
                  setShiftTransferForm({
                    ...shiftTransferForm,
                    branch: newValue,
                  })
                }
              />
            </div>
            <div className="global-single-input utility-shift-transfer-input auto-complete">
              <p>Type</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={orderTypes || [""]}
                getOptionLabel={(option) => option?.orderType}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Order Type" />
                )}
                value={shiftTransferForm.type}
                onChange={(e, newValue) =>
                  setShiftTransferForm({
                    ...shiftTransferForm,
                    type: newValue,
                  })
                }
              />
            </div>
          </div>

          <div className="global-single-input utility-shift-transfer-input">
            <p>Transfer Document</p>
            <input 
            type="text" 
            value={filteredArray.map((obj=>obj.invoiceNo))}
            />
            <p></p>
            <button
              disabled={(shiftTransferForm.branch === null || shiftTransferForm.branch === undefined) || (shiftTransferForm.type === null || shiftTransferForm.type === undefined)  }
              onClick={openSearchDocumentDialog}
              className="btn btn-primary transfer-document-button"
            >
              Search Document
            </button>
          </div>
          <div className="global-single-input utility-shift-transfer-input">
            <p>Transfer To</p>
            <input 
               type="text" 
               value={ shiftRadio !== undefined ? `SHIFT${shiftRadio}`:''}   
            />
            <button
              onClick={openSearchShiftDialog}
              className="btn btn-primary transfer-to-button"
            >
              Search Shift
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              className="btn btn-secondary-outlined utility-shift-button"
              onClick={cancelShiftTransfer}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary utility-shift-button"
              onClick={ClickShiftTransferFinalSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>

      {/* search document dialog */}
      <Dialog
        open={searchDocument}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setSearchDocument(false)}
      >
        <div className="utility-shift-document-container">
          <h4>Document Details</h4>
          <div className="utility-shift-document-container-top">
            <div className="utility-shift-document-container-top-left">
              <div className="global-single-input auto-complete">
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={InvoiceNoList || [""]}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Invoice" />
                  )}
                  onChange={(e, newValue) =>
                    setFilterForm({ ...filterForm, invoiceNo: newValue })
                  }
                />
              </div>
              <div className="global-single-input">
                <input
                  type="date"
                  placeholder="Date"
                  onChange={(e) =>
                    setFilterForm({ ...filterForm, date: e.target.value })
                  }
                />
              </div>
              <div className="global-single-input auto-complete">
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={customerList || [""]}
                  getOptionLabel={(option) =>
                    `${option?.cusId}-${option?.name}`
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Customer" />
                  )}
                  onChange={(e, newValue) =>
                    setFilterForm({
                      ...filterForm,
                      customerName: newValue?.name,
                    })
                  }
                />
              </div>
              <div className="global-single-input">
                <input
                  type="text"
                  placeholder="Mobile" maxLength="15"
                  value={filterForm?.contactNo}
                  onChange={(e) =>
                    setFilterForm({ ...filterForm, contactNo: e.target.value })
                  }
                />
              </div>
            </div>
            <button
              onClick={shiftOrderListSearchBtnFn}
              className="btn btn-primary"
            >
              Search
            </button>
          </div>

          <div className="global-table-container" style={{maxHeight:"65vh"}}>
            <table className="global-table utility-shift-document-table">
              <thead>
                <tr>
                  <th>
                    All{" "}
                    <input
                      type="checkbox"
                      onClick={() => checkAllFunction()}
                      checked={allCheckState}
                    />
                  </th>
                  <th>Invoice No</th>
                  <th>Date</th>
                  <th>Customer</th>
                  <th>Mobile</th>
                  {
                    shiftTransferForm?.type?.value === 4 &&
                    <th>Paid Amount</th>
                  }
                </tr>
              </thead>
              <tbody>
                {orderListArray?.length !== 0 ? (
                  orderListArray?.map((r, i) => (
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          checked={r?.isChecked}
                          onClick={checkBoxCheckfn(i)}
                        />
                      </td>
                      <td>{r?.invoiceNo}</td>
                      <td>{r?.date}</td>
                      <td>{r?.customer}</td>
                      <td>{r?.mobileNo}</td>
                      {
                       shiftTransferForm?.type?.value === 4 &&
                          <td>{r?.paidAmount}</td>
                       }
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5}>No Data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="utility-shift-document-container-bottom">
            <button className="btn btn-red" onClick={closeSearchDocument}>
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={searchDocumentSubmitBtnFn}
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>

      {/* search shift */}
      <Dialog
        open={searchShift}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setSearchShift(false)}
      >
        <div className="utility-shift-document-container">
          <div className="global-table-container ">
            <table className="global-table utility-shift-document-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Shift No</th>
                  <th>Shift Start Date</th>
                  <th>Shift Start Time</th>
                  <th>Shift End Date</th>
                  <th>Shift End Time</th>
                  {/* <th>Shift User</th> */}
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {shiftArray?.map((r, i) => (
                  <tr>
                    <td>
                      <input
                        type="radio"
                        name="shiftRadio"
                        style={{ accentColor: "black", cursor: "pointer" }}
                        value={r?.shiftId}
                        checked={shiftRadio==r?.shiftId?true:false}
                        onChange={radioChechedFn}
                       
                      />
                    </td>
                    <td>{r?.SHIFID}</td>
                    <td>{r?.startDate}</td>
                    <td>{r?.startTime}</td>
                    <td>{r?.endDate}</td>
                    <td>{r?.endTime}</td>
                    <td>
                      <IconButton>
                        <i class="bi bi-trash3 delete-icon"></i>
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="utility-shift-document-container-bottom">
            <button className="btn btn-red" onClick={closeSearchShiftDialog}>
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={searchShiftSubmitBtnFn}
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>

      {/* error snackbar */}
      <ErrorSnackbar
        open={errorSnack}
        handleClose={errorSnackbarClose}
        message={errorMsg}
      />
    </>
  );
};
