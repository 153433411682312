import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { StockMoveAgingReportAPIcall } from './ReportAPI';

const StockAgingReportPrint = (props) => {
    const { formValues } = props;
    const decimalPosition = localStorage.getItem("decimalPosition");
    const tableListData = useSelector((state) => state?.ReportSlice?.StockMoveAgingReport);

    useEffect(()=>{
        StockMoveAgingReportAPIcall()
    },[])
    return (
        <div className="print-main-div">
            <div className='header-div'>
                <div>
                    <img src={formValues?.companyLogo} alt="" style={{ height: "62px" }} />
                </div>
                <div>
                    <h1 style={{ fontSize: "larger", margin: "0" }}>Purchase Aging Report</h1>
                    {/* <p style={{margin:"0"}}>aaaaaaa</p> */}
                </div>
                <div>
                    <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyName}</p>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyAddress}</p>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyContact}</p>
                </div>
            </div>
            <div className="invoice-table-container">
                    <div className="invoice-main-div" style={{overflow:"visible"}}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: "98%"
                        }}>
                            {/* <h3>Purchase Ageing Report</h3> */}

                        </div>
                        {tableListData?.list !== undefined &&
                            tableListData?.list?.length !== 0 &&
                            tableListData?.list
                                ?.slice(0)
                                ?.map((r, i) => (

                                    <div key={i}>
                                        <h3 className='branch-head'>{r?.branchName}</h3>
                                        <table >

                                            <thead>
                                                <tr>
                                                    <th rowSpan="2">Code</th>
                                                    <th rowSpan="2">Product</th>
                                                    <th rowSpan="2">UOM</th>
                                                    <th style={{ textAlign: "center" }} colSpan="2">0-30</th>
                                                    <th style={{ textAlign: "center" }} colSpan="2">31-60</th>
                                                    <th style={{ textAlign: "center" }} colSpan="2">61-90</th>
                                                    <th style={{ textAlign: "center" }} colSpan="2">91-120 </th>
                                                    <th style={{ textAlign: "center" }} colSpan="2">121-150</th>
                                                    <th style={{ textAlign: "center" }} colSpan="2">151 Above</th>
                                                    <th style={{ textAlign: "center" }} colSpan="2">Total</th>
                                                </tr>
                                                <tr className="invoice-sub-head">
                                                    <td>QTY</td>
                                                    <td>Value</td>
                                                    <td>QTY</td>
                                                    <td>Value</td>
                                                    <td>QTY</td>
                                                    <td>Value</td>
                                                    <td>QTY</td>
                                                    <td>Value</td>
                                                    <td>QTY</td>
                                                    <td>Value</td>
                                                    <td>QTY</td>
                                                    <td>Value</td>
                                                    <td>QTY</td>
                                                    <td>Value</td>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {r?.productList.map((stockMove, idx) => (
                                                    <tr key={idx}>
                                                        <td>{stockMove?.productCode}</td>
                                                        <td>{stockMove?.productName}</td>
                                                        <td>{stockMove?.uomName}</td>
                                                        <td style={{textAlign:"end"}}>{stockMove?.categories?.upto30daysQty?.toFixed(decimalPosition)}</td>
                                                        <td style={{textAlign:"end"}}>{stockMove?.categories?.upto30daysValue?.toFixed(decimalPosition)}</td>
                                                        <td style={{textAlign:"end"}}>{stockMove?.categories?.upto60daysQty?.toFixed(decimalPosition)}</td>
                                                        <td style={{textAlign:"end"}}>{stockMove?.categories?.upto60daysValue?.toFixed(decimalPosition)}</td>
                                                        <td style={{textAlign:"end"}}>{stockMove?.categories?.upto90daysQty?.toFixed(decimalPosition)}</td>
                                                        <td style={{textAlign:"end"}}>{stockMove?.categories?.upto90daysValue?.toFixed(decimalPosition)}</td>
                                                        <td style={{textAlign:"end"}}>{stockMove?.categories?.upto120daysQty?.toFixed(decimalPosition)}</td>
                                                        <td style={{textAlign:"end"}}>{stockMove?.categories?.upto120daysValue?.toFixed(decimalPosition)}</td>
                                                        <td style={{textAlign:"end"}}>{stockMove?.categories?.upto150daysQty?.toFixed(decimalPosition)}</td>
                                                        <td style={{textAlign:"end"}}>{stockMove?.categories?.upto150daysValue?.toFixed(decimalPosition)}</td>
                                                        <td style={{textAlign:"end"}}>{stockMove?.categories?.morethan150daysQty?.toFixed(decimalPosition)}</td>
                                                        <td style={{textAlign:"end"}}>{stockMove?.categories?.morethan150daysValue?.toFixed(decimalPosition)}</td>
                                                        <td style={{textAlign:"end"}}>{stockMove?.sumOfTotalQty?.toFixed(decimalPosition)}</td>
                                                        <td style={{textAlign:"end"}}>{stockMove?.sumOfTotalValue?.toFixed(decimalPosition)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tr style={{ textAlign: "end", position: "sticky", bottom: "0" }}>

                                                <th style={{ textAlign: "end" }} colSpan={3}>Total</th>
                                                <th style={{textAlign:"end"}}> {r?.totalUpto30daysQty?.toFixed(decimalPosition)}</th>
                                                <th style={{textAlign:"end"}}> {r?.totalUpto30daysValue?.toFixed(decimalPosition)}</th>
                                                <th style={{textAlign:"end"}}>{r?.totalUpto60daysQty?.toFixed(decimalPosition)}</th>
                                                <th style={{textAlign:"end"}}>{r?.totalUpto60daysValue?.toFixed(decimalPosition)}</th>
                                                <th style={{textAlign:"end"}}> {r?.totalUpto90daysQty?.toFixed(decimalPosition)}</th>
                                                 <th style={{textAlign:"end"}}>{r?.totalUpto90daysValue?.toFixed(decimalPosition)}</th>
                                                <th style={{textAlign:"end"}}> {r?.totalUpto120daysQty?.toFixed(decimalPosition)}</th>
                                                <th style={{textAlign:"end"}}> {r?.totalUpto120daysValue?.toFixed(decimalPosition)}</th>
                                                <th style={{textAlign:"end"}}> {r?.totalUpto150daysQty?.toFixed(decimalPosition)}</th>
                                                <th style={{textAlign:"end"}}> {r?.totalUpto150daysValue?.toFixed(decimalPosition)}</th>
                                                <th style={{textAlign:"end"}}> {r?.totalMorethan150daysQty?.toFixed(decimalPosition)}</th>
                                                <th style={{textAlign:"end"}}> {r?.totalMorethan150daysValue?.toFixed(decimalPosition)}</th>
                                                <th style={{textAlign:"end"}}> {r?.totalQty?.toFixed(decimalPosition)}</th>
                                                <th style={{textAlign:"end"}}> {r?.totalValue?.toFixed(decimalPosition)}</th>
                                            </tr>
                                        </table>
                                    </div>

                                ))}


                        {/* <table style={{ width: "100%" }}>
                       
                                 <tr className='grand-row' style={{ textAlign: "end", position: "sticky", bottom: "0" }}  >

                                    <th style={{ textAlign: "end",width:'20%' }} colSpan={3} >Grand Total</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf30Qty?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf30Value?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf60Qty?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf60Value?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf90Qty?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf90Value?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf120Qty?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf120Value?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf150Qty?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf150Value?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOfAbove150Qty?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOfAbove150Value?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOfTotalQty?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOfTotalValue?.toFixed(decimalPosition)}</th>
                                </tr>

                        </table> */}

                        {tableListData?.list !== undefined &&
                            tableListData?.list?.length !== 0 &&
                            tableListData?.list
                                ?.slice(0)
                                ?.map((r, i) => (

                                    <div key={i}>
                                        {i === 0 && (
                                            <table >

                                                <thead style={{ backgroundColor: 'white', visibility: 'collapse' }}>
                                                    <tr>
                                                        <th rowSpan="2">Code</th>
                                                        <th rowSpan="2">Product</th>
                                                        <th rowSpan="2">UOM</th>
                                                        <th style={{ textAlign: "center" }} colSpan="2">0-30</th>
                                                        <th style={{ textAlign: "center" }} colSpan="2">31-60</th>
                                                        <th style={{ textAlign: "center" }} colSpan="2">61-90</th>
                                                        <th style={{ textAlign: "center" }} colSpan="2">91-120 </th>
                                                        <th style={{ textAlign: "center" }} colSpan="2">121-150</th>
                                                        <th style={{ textAlign: "center" }} colSpan="2">151 Above</th>
                                                        <th style={{ textAlign: "center" }} colSpan="2">Total</th>
                                                    </tr>
                                                    <tr className="invoice-sub-head">
                                                        <td>QTY</td>
                                                        <td>Value</td>
                                                        <td>QTY</td>
                                                        <td>Value</td>
                                                        <td>QTY</td>
                                                        <td>Value</td>
                                                        <td>QTY</td>
                                                        <td>Value</td>
                                                        <td>QTY</td>
                                                        <td>Value</td>
                                                        <td>QTY</td>
                                                        <td>Value</td>
                                                        <td>QTY</td>
                                                        <td>Value</td>

                                                    </tr>
                                                </thead>
                                                <tbody style={{ backgroundColor: 'white', visibility: 'collapse' }}>
                                                    {r?.productList.map((stockMove, idx) => (
                                                        <tr key={idx}>
                                                            <td>{stockMove?.productCode}</td>
                                                            <td>{stockMove?.productName}</td>
                                                            <td>{stockMove?.uomName}</td>
                                                            <td>{stockMove?.categories?.upto30daysQty?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.categories?.upto30daysValue?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.categories?.upto60daysQty?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.categories?.upto60daysValue?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.categories?.upto90daysQty?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.categories?.upto90daysValue?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.categories?.upto120daysQty?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.categories?.upto120daysValue?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.categories?.upto150daysQty?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.categories?.upto150daysValue?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.categories?.morethan150daysQty?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.categories?.morethan150daysValue?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.sumOfTotalQty?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.sumOfTotalValue?.toFixed(decimalPosition)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tr style={{ textAlign: "end", position: "sticky", bottom: "0", backgroundColor: 'white', visibility: 'collapse' }}>

                                                    <th style={{ textAlign: "end" }} colSpan={3}>Total</th>
                                                    <th> {r?.totalUpto30daysQty?.toFixed(decimalPosition)}</th>
                                                    <th> {r?.totalUpto30daysValue?.toFixed(decimalPosition)}</th>
                                                    <th> {r?.totalUpto60daysQty?.toFixed(decimalPosition)}</th>
                                                    <th> {r?.totalUpto60daysValue?.toFixed(decimalPosition)}</th>
                                                    <th> {r?.totalUpto90daysQty?.toFixed(decimalPosition)}</th>
                                                    <th>{r?.totalUpto90daysValue?.toFixed(decimalPosition)}</th>
                                                    <th>{r?.totalUpto120daysQty?.toFixed(decimalPosition)}</th>
                                                    <th> {r?.totalUpto120daysValue?.toFixed(decimalPosition)}</th>
                                                    <th> {r?.totalUpto150daysQty?.toFixed(decimalPosition)}</th>
                                                    <th>{r?.totalUpto150daysValue?.toFixed(decimalPosition)}</th>
                                                    <th> {r?.totalMorethan150daysQty?.toFixed(decimalPosition)}</th>
                                                    <th>{r?.totalMorethan150daysValue?.toFixed(decimalPosition)}</th>
                                                    <th>{r?.totalQty?.toFixed(decimalPosition)}</th>
                                                    <th> {r?.totalValue?.toFixed(decimalPosition)}</th>
                                                </tr>
                                                <tr className='grand-row' style={{ textAlign: "end", position: "sticky", bottom: "0" }}  >

                                                    <th style={{ textAlign: "end", }} colSpan={3} >Grand Total</th>
                                                    <th>{tableListData?.grandSumOf30Qty?.toFixed(decimalPosition)}</th>
                                                    <th>{tableListData?.grandSumOf30Value?.toFixed(decimalPosition)}</th>
                                                    <th>{tableListData?.grandSumOf60Qty?.toFixed(decimalPosition)}</th>
                                                    <th>{tableListData?.grandSumOf60Value?.toFixed(decimalPosition)}</th>
                                                    <th>{tableListData?.grandSumOf90Qty?.toFixed(decimalPosition)}</th>
                                                    <th>{tableListData?.grandSumOf90Value?.toFixed(decimalPosition)}</th>
                                                    <th>{tableListData?.grandSumOf120Qty?.toFixed(decimalPosition)}</th>
                                                    <th>{tableListData?.grandSumOf120Value?.toFixed(decimalPosition)}</th>
                                                    <th>{tableListData?.grandSumOf150Qty?.toFixed(decimalPosition)}</th>
                                                    <th>;{tableListData?.grandSumOf150Value?.toFixed(decimalPosition)}</th>
                                                    <th>{tableListData?.grandSumOfAbove150Qty?.toFixed(decimalPosition)}</th>
                                                    <th>{tableListData?.grandSumOfAbove150Value?.toFixed(decimalPosition)}</th>
                                                    <th>{tableListData?.grandSumOfTotalQty?.toFixed(decimalPosition)}</th>
                                                    <th>&nbsp;{tableListData?.grandSumOfTotalValue?.toFixed(decimalPosition)}</th>
                                                </tr>
                                            </table>
                                        )}
                                    </div>

                                ))}
                    </div>
                </div>
        </div>
    );
};

export default StockAgingReportPrint;
