import React, { useEffect, useState } from 'react'
import "./Catelog.css"
import SearchIcon from "@mui/icons-material/Search";
import { Dialog, Icon, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import uploadImage from '../../../Assets/Images/upload-image.png'
import { addCatalogCategoryAPICall, viewCatalogCategoriesAPICall } from './catalogCategoryAPI';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoadingForm } from '../../Single Components/LoadingForm';

export const Catelog = () => {
  const navigateTo=useNavigate()
   const categoryList=useSelector((state)=>state.catalogCategorySlice.apiResList)
    const [addCategory,setAddCategory]=useState(false)
    const [uploadedImage,setUploadedImage]=useState()
    const [imageUrl,setImageUrl]=useState()
    const [categoryName,setCategoryName]=useState("")
    const [loadingForm,setLoadingForm]=useState(false)
    const [isModify,setIsModify]=useState(false)
    const [searchInput,setSearchInput]=useState("")

    const updateListner=()=>{
      setIsModify(!isModify)
    }
   
    // useEffect(()=>{
    //     let image = undefined;
    //     if(typeof uploadedImage ==="string"){
    //       image = uploadedImage
    //     }
    //     else if(uploadedImage !== undefined && uploadedImage !== null){
    //       image=URL.createObjectURL(uploadedImage)
    //     }
    //     setImageUrl(image)
    // },[uploadedImage])
    
    
    
      
      const imageUploadFn=(e)=>{
        setUploadedImage(e.target.files[0])
        setImageUrl(URL.createObjectURL(e.target.files[0]));
      }
      const removeImage=()=>{
        setImageUrl(undefined)
        setUploadedImage(undefined)
      }
    const changeInput=(e)=>{
      setCategoryName(e.target.value)
    }

    const clearFn=()=>{
      setCategoryName("")
      setUploadedImage(undefined)
    }
    const formData=new FormData()
    formData.append("name",categoryName)
    formData.append("file",uploadedImage)
    const saveFunction=()=>{
      addCatalogCategoryAPICall(formData,updateListner,clearFn)
    }
    const handleClickRow=(row)=>{
      localStorage.setItem("catId",row?._id)
      localStorage.setItem("catName",row?.name)
      navigateTo("/userdashboard/catalog/subCategory")
    }

    const searchFunction=()=>{
      viewCatalogCategoriesAPICall({search:searchInput},setLoadingForm)
    }

    useEffect(()=>{
      searchFunction()

    },[searchInput,isModify])

      // useEffect(()=>{
      //   viewCatalogCategoriesAPICall({},setLoadingForm)
      // },[isModify])

      const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        for (let i = 0; i < bytes.byteLength; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
      };
   
    return (
    <div>
        <div className='catelog-header-container'>
            <p>Catalog</p>
        </div>
        <div className='catelog-heading-button'>
            <p>Auto Parts Categories</p>
            <div className='catelog-buttom-container'>
           
            <TextField 
            value={searchInput}
            onChange={(e)=>setSearchInput(e.target.value)}
            label="Search" 
            color="primary" 
            focused 
            InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                       <IconButton onClick={searchFunction}>
                     <SearchIcon
      className="billing-search-icon"
      style={{ color: "#736ff3" }}
     
    /> 
    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

 
 
                {/* <button onClick={()=>setAddCategory(true)} className='catalog-button create' style={{width:"33%"}}>Create</button> */}
            </div>
        </div>
        <div className='catelog-category-list'>
         {
           categoryList?.map((r,i)=>(
             <div onClick={()=>handleClickRow(r)} className='catelog-category-list-single'>
                  <img src={`data:image/png;base64,${arrayBufferToBase64(r?.imageUrl?.data?.data)}`} alt="" />
                  <p>{r?.name}</p>
             </div>
            
           ))
         }
        </div>

        {/* create category */}
        <Dialog open={addCategory} maxWidth="lg">
           <div className='create-category-container'>
             <div style={{padding:"4%"}}>
               <div className='create-category-icon-container'>
            
             <IconButton onClick={()=>setAddCategory(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                   <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                   <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                </svg>
             </IconButton>
              <IconButton onClick={saveFunction}>
               <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="blue" class="bi bi-floppy" viewBox="0 0 16 16">
                      <path d="M11 2H9v3h2z"/>
                      <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
              </svg>
              </IconButton>
               </div>
                <hr />
               <p className='create-category-name'>Create Category</p>
                <div className='create-category-input-field'>
                  <div className='new-global-single-input' style={{width:"48%"}}>
                   <TextField
                      label="category Name"
                      focused
                      value={categoryName}
                      onChange={changeInput}
                   />
                </div>
                <div className='global-single-input add-employee-input'>
           
            {
                    imageUrl === undefined &&
                  <div className='image-upload-file-conatiner'>
                  <input type="file" id="upload-image" onChange={imageUploadFn} />
                  <label htmlFor="upload-image">
                    <img src={uploadImage} alt="" />
                  </label>
                  </div>
                  }
                  {
                    imageUrl !== undefined &&
                    <div className='image-preview'>
                        <IconButton className='image-close-icon' onClick={removeImage}>
                           <i class="bi bi-x"></i>
                        </IconButton>
                        <img src={imageUrl} alt="" />
                    </div>
                    
                  }
                   <p>Upload Image</p>
        </div>
              </div>
             </div>
           </div>
        </Dialog>

        <LoadingForm loading={loadingForm}/>
    </div>
  )
}
