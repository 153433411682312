import React, { useEffect, useRef } from "react";
import "../../../../../css/Report/TrailBalanace.css";
import { Datepicker } from "../../../../Single Components/Datepicker";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { GeneralLedgerListAPI, TrailBalananceListAPI } from "./TrailBalanceAPI";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentTime, previousDate, today } from "../../../../../Js/Date";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
// import filterObjFromList from "../../../../../Js/generalFunctions"
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import ReactToPrint from "react-to-print";

export const GeneralLedgerNw = () => {
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  let componentRef = useRef();

  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );

  let navigate = useNavigate();
  // const [selectedHeading, setSelectedHeading] = useState("General Ledger Report");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [symbol, setSymbol] = useState(null);
  const trailBalanceList = useSelector(
    (state) => state.trailbalance.generalLedgerData
  );
  console.log(trailBalanceList);
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );

  console.log(currencyListUpdated);
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  // console.log(companyProfileData);
  const handleClickRow = (row) => {
    localStorage.setItem("singleViewById", row?._id);
    localStorage.setItem("fromDate", fromDate);
    localStorage.setItem("toDate", toDate);
    localStorage.setItem("accName", row?.account);

    // setSelectedHeading("Ledger Balance");

    navigate(
      "/userdashboard/accounts/report/financialReport/trialbalance/ledgerBalance"
    );
  };

  const getFromDate = (e) => {
    setFromDate(e.target.value);
  };
  const getToDate = (e) => {
    setToDate(e.target.value);
  };
  useEffect(() => {
    if (configurationList !== undefined) {
      setToDate(configurationList?.yearEndDate);
      setFromDate(configurationList?.yearStartDate);
    }
  }, [configurationList]);
  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);
  // const changeColor = (heading) => {
  //   setSelectedHeading(heading);
  // };

  useEffect(() => {
    if (toDate !== undefined && fromDate !== undefined) {
      const startDate = fromDate === "" ? "" : `${fromDate} 00:00:00`;
      const endDate = toDate === "" ? today : `${toDate} 23:59:59`;

      GeneralLedgerListAPI({
        startDate: startDate,
        endDate: endDate,
      });
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  return (
    <div className="top-main-container">
      {/* <div className="first-main-container-div">
      <div className="first-main-heading-div" id="headingDiv">
          <p
            style={{
              marginLeft: "14px",
              color: selectedHeading === "General Ledger Report" ? "#0e2493" : "#c2cbf9",
            }}
            onClick={() => changeColor("General Ledger Report")}
          >
            General Ledger Report
          </p>
          <p
            style={{
              color: selectedHeading === "Ledger Balance" ? "#0e2493" : "#c2cbf9",
            }}
           // onClick={() => changeColor("Ledger Balance")}
          >
            Ledger Balance
          </p>
          <p
            style={{
              color: selectedHeading === "General Entry" ? "#0e2493" : "#c2cbf9",
            }}
            onClick={() => changeColor("General Entry")}
          >
            General Entry
          </p>
        </div>
      </div> */}
      <div className="secound-main-container-div">
        <div className="first-div-container action-btns-container">
          <div>
            <Tooltip title="Share">
              <IconButton>
                <i
                  class="bi bi-share"
                  style={{
                    backgroundColor: "#9797e9",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <ReactToPrint
              trigger={() => (
                <Tooltip title="Print">
                  <IconButton
                    className="printer-div"
                    style={{ padding: "1px" }}
                  >
                    <i
                      style={{
                        color: "white",
                        backgroundColor: " #d787e7",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                      class="bi bi-printer"
                    ></i>
                  </IconButton>
                </Tooltip>
              )}
              content={() => componentRef}
            />
          </div>
          <div>
            <Tooltip title="Download">
              <IconButton>
                <i
                  class="bi bi-arrow-down-circle"
                  style={{
                    backgroundColor: "rgb(255 118 133 / 69%)",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div className="date-con-div">
          <div className="new-global-single-input" style={{ width: "41%" }}>
            <TextField
              value={fromDate}
              onChange={getFromDate}
              id="outlined-basic"
              label="From"
              variant="outlined"
              type="date"
              focused
              disabled={""}
              InputProps={{
                inputProps: {
                  max: today, // Assuming you want to set a max date
                },
                inputProps: {
                  style: { textAlign: "center" }, // Adjust the text alignment
                },
              }}
            />
            <p className="doc-validation-alert">{""}</p>
          </div>

          <div className="new-global-single-input" style={{ width: "41%" }}>
            <TextField
              value={toDate}
              onChange={getToDate}
              id="outlined-basic"
              label="To"
              variant="outlined"
              type="date"
              focused
              disabled={""}
              InputProps={{
                inputProps: {
                  max: today, // Assuming you want to set a max date
                },
                inputProps: {
                  style: { textAlign: "center" }, // Adjust the text alignment
                },
              }}
            />
            <p className="doc-validation-alert">{""}</p>
          </div>
        </div>
      </div>
      <div>
        <p style={{ fontWeight: 600, fontSize: "1em" }}>
          General Ledger Report
        </p>

        <div className="gl-table-div">
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: "55%",
                    textAlign: "start",
                    paddingLeft: "12px",
                  }}
                >
                  Particular
                </th>
                <th style={{ width: "15%" }}>Debit</th>
                <th style={{ width: "15%" }}>Credit</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {trailBalanceList?.journalEntryList?.map((item, index) => (
                <tr key={index} onClick={() => handleClickRow(item)}>
                  <td style={{ textAlign: "start", paddingLeft: "12px" }}>
                    {item.account}
                  </td>
                  <td>
                    {symbol}&nbsp;{item.debitTotal.toFixed(2)}
                  </td>
                  <td>
                    {symbol}&nbsp;{item.creditTotal.toFixed(2)}
                  </td>
                  <td
                    style={{
                      color: item.openingBalance < 0 ? "#e31212" : "black",
                    }}
                  >
                    {symbol}&nbsp;{item.openingBalance.toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
            <tr style={{ textAlign: "end", position: "sticky", bottom: "0" }}>
              <th
                style={{
                  width: "55%",
                  paddingLeft: "12px",
                  textAlign: "start",
                  fontSize: "initial",
                }}
              >
                Total
              </th>
              <th>
                {symbol}&nbsp;{trailBalanceList?.debitSubtotal.toFixed(2)}
              </th>
              <th>
                {symbol}&nbsp;{trailBalanceList?.creditSubtotal.toFixed(2)}
              </th>
              <th>
                {symbol}&nbsp;
                {trailBalanceList?.openingBalanceSubtotal.toFixed(2)}
              </th>
            </tr>
          </table>
        </div>
      </div>

      {/* print container */}
      <div style={{ display: "none" }}>
        <div
          ref={(el) => (componentRef = el)}
          className="print-container-trail-balance"
        >
          <div className="trailbalnce-print-header">
            <div className="company-logo-container">
              <img
                height={100}
                width={100}
                src="http://placehold.it/100x100"
                alt=""
              />
            </div>
            <div className="trail-balance-print-title">
              <span>General Ledger Report</span>
              <span style={{ fontSize: "13px" }}> As Off {toDate}</span>
            </div>
            <div className="company-details-container">
              <span>{userInfo?.data?.profile?.companyName} </span>
              <span>{userInfo?.data?.profile?.companyAddress} </span>
              <span>{userInfo?.data?.profile?.companyEmail}</span>
            </div>
          </div>
          <div className="print-general-ledger-container">
            <div className="gl-table-div ">
              <table className="general-ledger-table">
                <thead>
                  <tr>
                    <th>Particular</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {trailBalanceList?.journalEntryList?.map((item, index) => (
                    <tr key={index} onClick={() => handleClickRow(item)}>
                      <td style={{ textAlign: "start", paddingLeft: "12px" }}>
                        {item.account}
                      </td>
                      <td>
                        {symbol}&nbsp;{item.debitTotal.toFixed(2)}
                      </td>
                      <td>
                        {symbol}&nbsp;{item.creditTotal.toFixed(2)}
                      </td>
                      <td
                        style={{
                          color: item.openingBalance < 0 ? "#e31212" : "black",
                        }}
                      >
                        {symbol}&nbsp;{item.openingBalance.toFixed(2)}
                      </td>
                    </tr>
                  ))}
                  <tr className="total-row-footer">
                    <td>Total</td>
                    <td>
                      {symbol}&nbsp;{trailBalanceList?.debitSubtotal.toFixed(2)}
                    </td>
                    <td>
                      {symbol}&nbsp;
                      {trailBalanceList?.creditSubtotal.toFixed(2)}
                    </td>
                    <td>
                      {symbol}&nbsp;
                      {trailBalanceList?.openingBalanceSubtotal.toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
