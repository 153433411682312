import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { useSelector } from 'react-redux';
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { filterObjFromList } from '../../../../Js/generalFunctions';
import { StockMoveAPIcall } from './ReportAPI';
import { getCurrentTime } from '../../../../Js/Date';

import { setFilterActive } from '../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../Redux/store';

import { IconButton, Tooltip } from '@mui/material';
import Template from "./StockMovePrint"
import ReactToPrint from 'react-to-print';


export const StockMove = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  let navigate = useNavigate();
  let paymentsPrintNew = useRef();
  const [symbol, setSymbol] = useState(null);
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [selectedBranch, setSelectedBranch] = useState(null);
  const decimalPosition = localStorage.getItem("decimalPosition");
  const decimalPositionStock = localStorage.getItem("decimalPositionStock");
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  const stockMove = useSelector((state) => state?.ReportSlice?.StockMove)
  // console.log(stockMove);

  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );

  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };

  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("vendor", row?.productName)
    localStorage.setItem("stock_id", row?._id);
    navigate("/userdashboard/inventory/reports/stockMove/singleView")
  }
  function calculateColumnTotal(data, field) {
    return data?.reduce((acc, item) => {
      const value = parseFloat(item[field] || 0);
      return acc + (isNaN(value) ? 0 : value);
    }, 0)?.toFixed(decimalPosition);
  }
  
  

  // console.log(stockMove?._id);

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    // console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  useEffect(() => {
    if (FormDataInfo?.length !== 0) {
      localStorage.setItem("branch", (FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0]))
      localStorage.setItem("location", FormDataInfo?.location?.length === 0 ? '' : FormDataInfo?.location[0])
      localStorage.setItem("fromDate", FormDataInfo?.fromDate)
      localStorage.setItem("ToDate", FormDataInfo?.toDate)

      let body = {
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        endDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        location: FormDataInfo?.location?.length === 0 ? '' : FormDataInfo?.location[0],
        branchId: FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0],

        search: FormDataInfo?.search,
      }
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData && StockMoveAPIcall(body)
    }
  }, [FormDataInfo])



  return (
    <div className="global-page-parent-container" style={{ margin: "0" }}>
      <div className="global-white-bg-container">
        <div className="justify-space-between" >
          <div>
            <div className="sharp-arrow" style={{ width: "147Px" }}> <p>Stock Move Report</p></div>

            <Tooltip title="Share" style={{ marginLeft: "24px" }}>
              <IconButton>
                <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
              </IconButton>
            </Tooltip>

            <ReactToPrint
              trigger={() => (
                <IconButton className="printer-div" style={{ padding: "1px" }}>
                  <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
                </IconButton>
              )}
              content={() => paymentsPrintNew}
            />

            <Tooltip title="Download">
              <IconButton>
                <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <CategorySearchandFilter
              statusOptions={['PURCHASE ORDER', 'DONE', 'CANCEL']}
              onData={handleDataFromChild}
              isLocation={true}
              onBranchSelect={handleBranchSelect}
            />

          </div>
        </div>
        {/* <h1 style={{ margin: "1px", fontSize: "large" }}>Stock Move Report</h1> */}

        <div className="global-report-table-container">
          <table>
            <thead>
              <tr >
                <th>Item</th>
                <th>UOM</th>
                <th>Opening</th>
                <th>In</th>
                <th>Out</th>
                <th>Closing</th>
                <th>Cost</th>
                <th>Values</th>
              </tr>
            </thead>
            <tbody>
            {stockMove
  ?.slice()
  ?.sort((a, b) => a.productName.localeCompare(b.productName))
  ?.map((item, index) => (
    <tr key={index} onClick={() => handleClickRow(item)}>
      <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.productName}</td>
      <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.uom}</td>
      <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.openingBalance}</td>
      <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalInDate?.toFixed(decimalPositionStock)}</td>
      <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalOutDate?.toFixed(decimalPositionStock)}</td>
      <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.closingBalance?.toFixed(decimalPosition)}</td>
      <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
        {item && item.cost !== null ? ` ${item.cost.toFixed(decimalPosition)}` : ` 0.${'0'.repeat(decimalPosition)}`}
      </td>
      <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.value?.toFixed(decimalPosition)}</td>
    </tr>
  ))}
            </tbody>

            <tfoot>

            <tr>
    <th colSpan="2">Total</th>
    <th>{calculateColumnTotal(stockMove, 'openingBalance')}</th>
    <th>{calculateColumnTotal(stockMove, 'totalInDate')}</th>
    <th>{calculateColumnTotal(stockMove, 'totalOutDate')}</th>
    <th>{calculateColumnTotal(stockMove, 'closingBalance')}</th>
    <th>{calculateColumnTotal(stockMove, 'cost')}</th>
    <th>{calculateColumnTotal(stockMove, 'value')}</th>
  </tr>

            </tfoot>
          </table>
        </div>
      </div>
      <div style={{display:"none"}} >
        <div ref={(el) => (paymentsPrintNew = el)}>
          <Template stockMove={stockMove} symbol={symbol} />
        </div>
      </div>
    </div>
  )
}