import React, { useLayoutEffect, useState } from 'react'
import { convertDateFormat } from '../../../../Js/Date';
import barcode from '../../../../Assets/Images/barcode.png'
import Barcode from 'react-barcode';

export const JalabiaCuttingSlip1 = (props) => {
    const { qr, cuttingSlipData, printingObject, copy } = props;
    const [totalQty,setTotalQty]=useState()

    useLayoutEffect(()=>{
       let total=0;
       printingObject?.materials?.forEach((r,i)=>{
        total +=r?.qty
       })
       setTotalQty(total)

    },[printingObject])
   
  return (
    <div className="thob-cutting-slip-container">
      <div className="thob-cutting-slip-top-container">
        <div className="thob-cutting-slip-top-first-content">
          <p></p>
          <p className="main-heading-thob-cutting-slip1">JALABIA CUTTING SLIP</p> 
          <h3></h3>
        </div>
        <div className="thob-cutting-slip-top-second-content">
          <div className='thob1-cutting-slip-top-second-content-left'>
          {
              cuttingSlipData?.isCode === false && 
              <Barcode
              value={cuttingSlipData?.barcodeNumber}
              width={1}
              fontSize={16}
              height={30}
              margin={"2px"}
            />
            }
            <p>CUSTOMER:&nbsp;
              {printingObject?.relationName === "null"
              ? cuttingSlipData?.cusName
              : printingObject?.relationName
              ||printingObject === undefined? "ABC":undefined
              }
             </p>
            <p>{cuttingSlipData?.woNo || "WO1234"}</p>
            <p>EMPLOYEE:&nbsp;{cuttingSlipData?.employee||'ABC'}</p>
          </div>
          <div className='thob1-cutting-slip-top-second-content-right'>
            <div></div>
            <div style={{display:"flex",justifyContent:"space-between",height:"25%"}}>
            <p>ORDER DATE:</p>
            <p>{convertDateFormat(cuttingSlipData?.ordDate) || "-NIL-"}</p>
            </div>
            <div style={{display:"flex",justifyContent:"space-between",height:"25%"}}>
            <p>DELIVERY DATE:</p>
            <p>{cuttingSlipData?.delDate !== null ? convertDateFormat(cuttingSlipData?.delDate) : "-NIL-"}</p>
            </div>
          
            
          </div>
        </div>
      </div>
      <hr className='thob1-cutting-slip-border'/>
      <div className="thob-cutting-slip-middle-container">
      <div className="jalabia1-cutting-slip-middle-top-container">
        <div className='jalabia1-cutting-slip-middle-top-single-container'>
          <p className='jalabia1-cutting-slip-single-left-p'>Length</p>
          {/* length */}
          <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{printingObject?.length || ""}</p>
        </div>
        <div className='jalabia1-cutting-slip-middle-top-single-container'>
          <p className='jalabia1-cutting-slip-single-left-p jalabia1-shoulder'>Shoulder</p>
           {/* shoulder */}
           <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{printingObject?.regalSize || ""}</p>
        </div>  
         <div className='jalabia1-cutting-slip-middle-top-single-container'>
         <p className='jalabia1-cutting-slip-single-left-p'>Sleeve</p>
         {/* sleeve */}
         <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{printingObject?.shoulder || ""}</p>
         </div>
          <div className='jalabia1-cutting-slip-middle-top-single-container'>
          <p className='jalabia1-cutting-slip-single-left-p'>Sada</p>
          <p style={{fontSize:"0.9rem",fontWeight:"600"}}>---</p>
          </div>
         
       </div>
       <div className="jalabia1-cutting-slip-middle-top-container jalabia1-two">
           <div className='jalabia1-cutting-slip-middle-top-single-container' style={{width:'18%'}}>
            <p className='jalabia1-cutting-slip-single-left-p jalabia1-regal'>Regal</p>
            <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{printingObject?.chest || ''}</p>
           </div>
           <div className='jalabia1-cutting-slip-middle-top-single-container' style={{width:'18%'}}>
             <p className='jalabia1-cutting-slip-single-left-p jalabia1-regal'>Body</p>
             <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{printingObject?.stomach || ''}</p>
           </div>
          <div className='jalabia1-cutting-slip-middle-top-single-container' style={{width:'18%'}}>
            <p className='jalabia1-cutting-slip-single-left-p jalabia1-regal'>Side</p>
            <div style={{display:"flex",flexDirection:"column"}}>
             {printingObject?.materials?.map((r)=>(
               <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{r?.fpName}</p>
              ))}
            </div>
          </div>
          <div className='jalabia1-cutting-slip-middle-top-single-container' style={{width:'18%'}}>
            <p className='jalabia1-cutting-slip-single-left-p jalabia1-regal'>Centre</p>
            <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{printingObject?.materials[0]?.fpSize1}</p>
          </div>
          <div className='jalabia1-cutting-slip-middle-top-single-container' style={{width:'18%'}}>
            <p className='jalabia1-cutting-slip-single-left-p jalabia1-regal'>Mar</p>
            <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{printingObject?.slChest || ""}</p>
          </div>
        </div>
       <div className="jalabia1-cutting-slip-middle-top-container jalabia1-three">
         <div className='jalabia1-cutting-slip-middle-top-single-container' style={{width:'47%'}}>
          <p className='jalabia1-cutting-slip-single-left-p jalabia1-regal' >PT</p>
          <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{printingObject?.slSto || ""}</p>
         </div>
         <div className='jalabia1-cutting-slip-middle-top-single-container' style={{width:'47%'}}>
          <p className='jalabia1-cutting-slip-single-left-p jalabia1-regal' >PT</p>
          <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{printingObject?.bottom || ""}</p>
         </div>
         
       </div>
       <div className="jalabia1-cutting-slip-middle-top-container">
         <div className='jalabia1-cutting-slip-middle-top-single-container' style={{width:'40%'}}>
           <p className='jalabia1-cutting-slip-single-left-p jalabia1-regal'>Neck</p>
           {/* neck */}
           <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{printingObject?.neck || ""}</p>
         </div>
        
       </div>
       <div className="jalabia1-cutting-slip-middle-top-container">
        <div className='jalabia1-cutting-slip-middle-top-single-container' style={{width:'20%'}}>
          <p className='jalabia1-cutting-slip-single-left-p'>Bottom</p>
           {/* bottom */}
           <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{printingObject?.sleeveSize || ""}</p>
        </div>
       
       
       </div>
       <div className='jalabia1-cutting-slip-middle-top-container jalabia1-four'>
        <div className='jalabia1-cutting-slip-middle-top-single-container'>
          <p className='jalabia1-cutting-slip-single-left-p jalabia1-regal'>QTY</p>
          <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{totalQty}</p>
        </div>
        
       </div>
       <div className="jalabia1-cutting-slip-middle-top-container jalabia-five">
         <div className='jalabia1-cutting-slip-middle-top-single-container' style={{width:'48%'}}>
            <p className='jalabia1-cutting-slip-single-left-p jalabia1-shoulder'>Stitching</p>
            {/* stitching */}
            <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{printingObject?.materials[0]?.remark || ""}</p>
         </div>
        <div className='jalabia1-cutting-slip-middle-top-single-container' style={{width:'48%'}}>
          <p className='jalabia1-cutting-slip-single-left-p jalabia1-shoulder'>Button</p>
          <div style={{display:"flex",flexDirection:"column"}}>
            {printingObject?.materials?.map((r)=>(
               <p style={{fontSize:"0.9rem",fontWeight:"600"}}>{r?.stitchingName}</p>
            ))}
          </div>
        </div>
       
       </div>
       <div style={{display:"flex",width:"100%",justifyContent:"space-between",margin:"1% 0"}}> 
        <div className="thob-cutting-slip-middle-table-container" style={{width:"80%"}}>
          <table style={{margin:0}}>
            <thead>
              <tr>
                <th>Description</th>
                <th>Qty</th>
                <th>Type</th>
                <th>Model</th>
                <th>MTR</th>
                <th>MTR Used</th>
               </tr>
            </thead>
            <tbody>
              {printingObject?.materials?.map((r, i) => (
                <tr key={i}>
                  <td>{r?.mName?.toUpperCase()}</td>
                  <td>{r?.qty}</td>
                  <td>{r?.ModelName}</td>
                  <td>{r?.jalabiaModelName}</td>
                  <td></td>
                  <td></td>
                 
                  
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {
          cuttingSlipData?.isCode === true && 
          <div className="thob-cutting-slip-table-qrCode-qrcode" style={{width:"18%"}}>
          <img src={qr} alt="qrCode" />
      </div>
        }
       
        </div> 
       {/* <div className="thob-cutting-slip-middle-table-container">
        <table>
          <thead>
            <tr>
              <th>Selected Clothes</th>
              <th>Qty</th>
              <th>Model</th>
              <th>Neck Model</th>
              <th>Mar Model</th>
              <th>FP-Model</th>
              <th>FP-Size</th>
              <th>Stitching</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
           
          {printingObject?.materials?.map((r, i) => (
              <tr key={i}>
                <td>{r?.mName}</td>
                <td>{r?.qty}</td>
                <td>{r?.jalabiaModelName}</td>
                <td>{r?.neckName}</td>
                <td>{r?.ModelName || "--"}</td>
                <td>{r?.fpName}</td>
                <td>{`${r?.fpSize1}, ${r?.fpSize2}`}</td>
                <td>{r?.stitchingName}</td>
                <td>{r?.remark}</td>

              
              </tr>
            ))}
           
          </tbody>
        </table>
      </div> */}
      <div className='thob1-cutting-slip-middle-top-nine-container'>
            <p>Cutter:
              {
                printingObject?.cuttingMasters?.map((cMaster, i)=>(
                  <span key={i}>{i ===printingObject?.cuttingMasters?.length-1? cMaster : cMaster +","}</span>
                ))
              } 
              </p>
             
        </div>
      </div>
    </div>
  )
}
