import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { chartOfAccountListApiCall } from '../../../../API/AccountUpdated/chartOfAccountAPI'
import { useSelector } from 'react-redux'
import { setInventoryConfigSettingsAPICall, viewInventoryConfigSettingsAPICall } from './inventoryConfigurationSettingsAPI'
import SuccessSnackbar from '../../../Single Components/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar'
import { filterObjFromList } from '../../../../Js/generalFunctions'
import { LoadingForm } from '../../../Single Components/LoadingForm'

export const SettingsProductVariant = () => {

  const chartOfAccountList = useSelector((state) => state.chartOfAccountsSlice.value)
  const configurationList = useSelector((state) => state.inventoryConfigSettingSlice.apiResList)

  const controlAndExpenseAccList = chartOfAccountList?.filter(item => item.type === "Cost of revenue" || item.type === "Expenses");
  const incomeAccList = chartOfAccountList?.filter(item => item.type === "Income");

  const [validationAlert,setValidationAlert]=useState({})
  const [formValues,setFormValues]=useState({
    productVariant:false,
    multiLocation:false,
    multiWareHouse:false,
    transferMargin:false,
    inComeAccount:null,
    expenseAccount:null,
  })
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
  })
  const [loadingForm,setLoadingForm]=useState(false)



  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates, success: false,
      error: false,
      message: "",
    })
  }
  const getFormInfo=(key)=>(e,newValue)=>{
    setValidationAlert({})
    setFormValues({...formValues,[key]:newValue===undefined?e.target.checked:newValue})
  }
  const submitApiCall=()=>{
   if(formValues?.transferMargin && formValues?.inComeAccount===null){
    setValidationAlert({...validationAlert,inComeAccount:"Select an account !!"})
  }else if(formValues?.transferMargin && formValues?.expenseAccount===null){
     setValidationAlert({...validationAlert,expenseAccount:"Select an account !!"})
   }
   else{
    let payload={
      productVarient:formValues?.productVariant,
      multiLocation:formValues?.multiLocation,
      multiWarehouse:formValues?.multiWareHouse,
      transferMargin:formValues?.transferMargin,
      incomeAccount:formValues?.transferMargin?formValues?.inComeAccount?._id:undefined,
      expenseAccount:formValues?.transferMargin?formValues?.expenseAccount?._id:undefined,
    }
    setInventoryConfigSettingsAPICall(payload,setSnackBarStates,snackBarStates,setLoadingForm)
   }
  }

  useEffect(()=>{
    configurationList !== undefined &&
        setFormValues({...formValues,
          productVariant:configurationList?.productVarient,
          multiLocation:configurationList?.multiLocation,
          multiWareHouse:configurationList?.multiWarehouse,
          transferMargin:configurationList?.transferMargin,
          inComeAccount:filterObjFromList("_id", chartOfAccountList, "incomeAccount", configurationList),
          expenseAccount:filterObjFromList("_id", chartOfAccountList, "expenseAccount", configurationList),
        })
  },[configurationList,chartOfAccountList])
  useEffect(()=>{
    chartOfAccountListApiCall()
    viewInventoryConfigSettingsAPICall()
  },[])
  return (
    <div className="global-page-parent-container">

        <div className='global-white-bg-container' style={{paddingLeft:"3%"}}>
          <Tooltip title={"Save"}>
            <IconButton
              onClick={submitApiCall}>
              <i class="bi bi-floppy"></i>
            </IconButton>
          </Tooltip>
        </div>
        <div className='global-white-bg-container' style={{height:"50vh"}}>
        
        <h4 style={{fontSize:"1rem",marginLeft:"3%",marginTop:"0"}}>Inventory Configuration</h4>
        {/* <div className='discount-config-checkbox-container' style={{margin:"3%",alignItems:"center",gap:"5px"}}>
         <input 
          type="checkbox" 
          id="productVariant"
          style={{height:"20px",marginLeft:"0"}}
          onChange={getFormInfo("productVariant")}
          checked={formValues?.productVariant}
          />
        <label htmlFor="productVariant" style={{fontSize:"0.8rem"}}>Product Variant</label>
      </div> */}
      <div className='discount-config-checkbox-container' style={{margin:"3%",alignItems:"center",gap:"5px"}}>
         <input 
          type="checkbox" 
          id="multiLocation"
          style={{height:"20px",marginLeft:"0"}}
            onChange={getFormInfo("multiLocation")}
            checked={formValues?.multiLocation}
          />
        <label htmlFor="multiLocation" style={{fontSize:"0.8rem"}}>Multi Location</label>
      </div>
      <div className='discount-config-checkbox-container' style={{margin:"3%",alignItems:"center",gap:"5px"}}>
         <input 
          type="checkbox" 
          id="multiWareHouse"
          style={{height:"20px",marginLeft:"0"}}
          onChange={getFormInfo("multiWareHouse")}
          checked={formValues?.multiWareHouse}
          />
        <label htmlFor="multiWareHouse" style={{fontSize:"0.8rem"}}>Multi Warehouse</label>
      </div>
      <div className='discount-config-checkbox-container' style={{margin:"3%",alignItems:"center",gap:"5px"}}>
         <input 
          type="checkbox" 
          id="transferMargin"
          style={{height:"20px",marginLeft:"0"}}
          onChange={getFormInfo("transferMargin")}
          checked={formValues?.transferMargin}
          />
        <label htmlFor="transferMargin" style={{fontSize:"0.8rem"}}>Transfer Margin</label>
      </div>
      {
        formValues?.transferMargin &&
      <div style={{display:"flex"}}>
      <div className='global-single-input create-joutnal-input auto-complete' style={{marginLeft:"3%",width:"25%"}}>
        <p>Income Account</p>
        <Autocomplete
             sx={{ width: "100%" }}
             options={incomeAccList || []}
             getOptionLabel={(option) =>option?.accountName}
             renderInput={(params) => (
               <TextField {...params} placeholder="Select Income Account" />
             )}
              onChange={getFormInfo("inComeAccount")}
              value={formValues?.inComeAccount}
          />
            <p className="doc-validation-alert">{validationAlert?.inComeAccount}</p>
      </div>
      <div className='global-single-input create-joutnal-input auto-complete' style={{marginLeft:"0%",width:"25%"}}>
        <p>Expense Account</p>
        <Autocomplete
             sx={{ width: "100%" }}
             options={ controlAndExpenseAccList ||[]}
             getOptionLabel={(option) =>option?.accountName}
             renderInput={(params) => (
               <TextField {...params} placeholder="Select Expense Account" />
             )}
             onChange={getFormInfo("expenseAccount")}
            value={formValues?.expenseAccount}
          />
            <p className="doc-validation-alert">{validationAlert?.expenseAccount}</p>
      </div>
      </div>}
        </div>
        <SuccessSnackbar open={snackBarStates.success} handleClose={closeSnackbar} message={snackBarStates.message}/>
        <ErrorSnackbar open={snackBarStates.error} handleClose={closeSnackbar} message={snackBarStates.message}/>
        <LoadingForm loading={loadingForm}/>

        </div>
  )
}
